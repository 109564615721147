import { render, staticRenderFns } from "./AgentDialog.vue?vue&type=template&id=584c05f7&scoped=true&"
import script from "./AgentDialog.vue?vue&type=script&lang=js&"
export * from "./AgentDialog.vue?vue&type=script&lang=js&"
import style0 from "./AgentDialog.vue?vue&type=style&index=0&id=584c05f7&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "584c05f7",
  null
  
)

export default component.exports