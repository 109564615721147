<template>
  <div>
    <template v-if="noQuotesAvailable || allQuotesIneligible">
      <v-card class="pa-8 mb-8 text-center">
        <img src="/images/logos/amwins_logo--access.svg" alt="Amwins Access"
          style="display: inline-block; width: 200px; height: auto; margin-bottom: 20px; position: relative; left: 10px;" />
        <div style="margin-bottom: 20px;" v-html="noQuotesAvailableMessage"></div>
      </v-card>
    </template>
    <div ref="compareContainer" v-if="!noQuotesAvailable" >
      <div style="width: 100%; overflow-x: auto;" class="d-flex flex-row">
        <div class="d-flex flex-column compare-labels" ref="compareLabels">
          <div class="compare-carrier"><span>&nbsp;</span></div>
          <div class="compare-premium text-bold">
            <h3 class="text-bold text-right annual-premium">Annual Premium</h3>
          </div>
          <template v-if="!allQuotesIneligible">
            <div class="compare-documents ">
              <span class="text-bold text-right">
                <span>Documents</span>
              </span>
            </div>
            <div class="compare-subheading" v-if="inspectionsFlagEnabled && coverageOption != 'flood'"><span>&nbsp;</span></div>
            <div class="compare-item" v-if="inspectionsFlagEnabled && coverageOption != 'flood'">
              <span class="text-bold text-right">
                <span>Inspection Required</span>
              </span>
            </div>
            <div class="compare-subheading"><span>&nbsp;</span></div>
            <div v-if="showCovRow('FormType')" class="compare-item">
              <span class="text-bold text-right">
                <span>Coverage Form</span>
              </span>
            </div>
            <div class="compare-item" v-if="showCovRow('A')">
              <span class="text-bold text-right">
                <span v-if="coverageOption !== 'flood'">Coverage A: Dwelling</span><span v-else>Dwelling Coverage</span>
              </span>
            </div>
            <div class="compare-item" v-if="showCovRow('B')">
              <span class="text-bold text-right">
                <span v-if="coverageOption !== 'flood'">
                  Coverage B: Other
                  Structures
                </span><span v-else>Other Structures Coverage</span>
              </span>
            </div>
            <div class="compare-item" v-if="showCovRow('C')">
              <span class="text-bold text-right">
                <span v-if="coverageOption !== 'flood'">
                  Coverage C: Personal
                  Property
                </span><span v-else>Personal Property Coverage</span>
              </span>
            </div>
            <div class="compare-item" v-if="showCovRow('C') && coverageOption == 'flood'">
              <span class="text-bold text-right"><span>Personal Property Valuation</span></span>
            </div>
            <div class="compare-item" v-if="showCovRow('D')">
              <span class="text-bold text-right">
                <template v-if="coverageOption == 'dp3'">
                  Coverage D&amp;E: Loss of Use &amp; Additional Living
                  Expense
                </template>
                <template v-else>
                  <span class="text-bold text-right">
                    <span v-if="coverageOption !== 'flood'">
                      Coverage D:
                      Loss of Use
                    </span><span v-else>Loss of Use Coverage</span>
                  </span>
                </template>
              </span>
            </div>
            <div class="compare-item" v-if="showCovRow('Liability')">
              <span class="text-bold text-right">Personal Liability</span>
            </div>
            <div class="compare-item" v-if="showCovRow('MedPay')">
              <span class="text-bold text-right">MedPay</span>
            </div>
            <div class="compare-item" v-if="showCovRow('PersonalInjury')">
              <span class="text-bold text-right">Personal Injury</span>
            </div>
            <div class="compare-item" v-if="showCovRow('ReplacementCostDwelling')">
              <span class="text-bold text-right">Replacement Cost Dwelling</span>
            </div>
            <div class="compare-item" v-if="showCovRow('ReplacementCostContents')">
              <span class="text-bold text-right">Replacement Cost Contents</span>
            </div>
            <div class="compare-item" v-if="showCovRow('ReplacementCostStructures')">
              <span class="text-bold text-right">Extended Replacement Cost - Dwelling</span>
            </div>
            <div class="compare-item" v-if="showCovRow('LossAssessment')">
              <span class="text-bold text-right">Loss Assessment</span>
            </div>
            <div class="compare-item" v-if="showCovRow('InflationGuard')">
              <span class="text-bold text-right">Inflation Guard</span>
            </div>
            <div class="compare-item" v-if="showCovRow('BuildingOrdinanceOrLaw')">
              <span class="text-bold text-right">Building Ordinance or Law</span>
            </div>
            <div class="compare-item" v-if="showCovRow('Theft')">
              <span class="text-bold text-right">Theft</span>
            </div>
            <div class="compare-item" v-if="showCovRow('Mold')">
              <span class="text-bold text-right">Mold</span>
            </div>
            <div class="compare-item" v-if="showCovRow('WaterBackup')">
              <span class="text-bold text-right">Water Backup</span>
            </div>
            <div class="compare-item" v-if="showCovRow('WaterDamageSublimit')">
              <span class="text-bold text-right">Water Damage Sublimit</span>
            </div>
            <div class="compare-subheading"><span>&nbsp;</span></div>
            <div class="compare-item" v-if="showCovRow('TotalPeril')">
              <span class="text-bold text-right">All Other Peril</span>
            </div>
            <div class="compare-item" v-if="showCovRow('WindHailPeril')">
              <span class="text-bold text-right">Wind/Hail</span>
            </div>
            <div class="compare-item" v-if="showCovRow('EarthquakePeril')">
              <span class="text-bold text-right">Earthquake</span>
            </div>
            <div class="compare-item" v-if="showCovRow('FloodPeril')">
              <span class="text-bold text-right">Flood</span>
            </div>
            <div class="compare-item" v-if="showCovRow('DifferenceInConditions')">
              <span class="text-bold text-right">DIC</span>
            </div>
          </template>
        </div>
        <div class="compare-chart d-flex" style="width: 100%; overflow-x: visible;" ref="compareColumns" :class="{ cursorMove: cursorMove }">
          <div v-for="response in gateTheSubmissionResponse.filter(r => r.Include)" :key="response.SalesChannel"
            class="d-flex flex-column">

            <template v-if="getQuoteBySalesChannel(response.SalesChannel)">
              <!--Populated Quote-->
              <div class="carrier-column d-flex flex-column"
                v-for="quote in [getQuoteBySalesChannel(response.SalesChannel)]" :key="quote?.id">

                <div class="compare-carrier text-center">
                  <div class="carrier-logo"
                    :style="{ 'background-image': 'url(\'' + getSalesChannelLogo(quote.SalesChannel, coverageOption, floodTFIAFeatureFlagEnabled) + '\')' }">
                    &nbsp;
                  </div>
                  <v-btn :disabled="quoteDisabled(quote)" color="secondary" x-large @click="selectQuote(quote)"
                         type="button">
                    {{
                      quote.Status == "Error"
                      ? "Error"
                      : quote.SalesChannel == "Human"
                        ? getHumanButtonText()
                        : quote.SalesChannel == "TFIA"
                          ? "Request to Bind"
                          : "Bind Quote Now"
                    }}
                  </v-btn>
                  <template v-if="quote.ErrorText || quote.DebugText || quoteAgedOut(quote)">
                    <v-tooltip right>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on">
                          <v-icon size="24" class="preview-icon">fas fa-exclamation-triangle</v-icon>
                        </v-btn>
                      </template>
                      <span style="display: block;" v-html="quote.ErrorText"></span>
                      <span v-if="showDebugText" style="display: block;" v-html="quote.DebugText"></span>
                      <span v-if="quoteAgedOut(quote)" style="display: block;" v-html="quoteAgedOutMessage(quote)"></span>
                    </v-tooltip>
                  </template>
                </div>
                <div class="compare-premium text-center d-flex align-center justify-center">

                  <div class="compare-premium-content">

                    <h2 class="text-center">
                      <template v-if="quote.Status == 'Ok'">
                        <template v-if="quote.SalesChannel == 'Human'">Underwriter Quote
                          <h5 v-if="isSpocAndWeekend(quote)"><br /><i>Referral currently unavailable</i></h5>
                        </template>
                        <template v-else-if="quotePriceStatus(quote) == 'Firm'">{{ formatMoney(quote.Total,
                          true) }}</template>
                        <template v-else-if="quotePriceStatus(quote) == 'Pending'">Quote Pending</template>
                        <template v-else-if="quotePriceStatus(quote) == 'Indication'">
                          <span class="quote-price-indication-estimate">Estimate:</span>
                          <span class="quote-price-indication">{{ formatMoney(quote.Total, true) }}</span>
                        </template>
                      </template>
                      <template v-else-if="quote.Status == 'Error'">
                        Try Again Later
                      </template>
                      <template v-else-if="quote.Status == 'NotAvailable'">
                        <template v-if="quote.DeclineType == 'Sold_Out'">Sold Out</template>
                        <template v-else-if="quote.DeclineType == 'Unavailable'">Unavailable</template>
                        <template v-else>Not Eligible</template>
                      </template>
                    </h2>
                    <div class="premium-buttons">
                      <v-btn color="secondary" small v-if="showRefineButton(quote)" type="button" :disabled="disabled"
                        @click="refine(quote)">Refine</v-btn>
                    </div>
                    <div class="expiration">
                      <span>
                        <template v-if="quote.Expiration && quote.Status == 'Ok'">
                          <template v-if="quotePriceStatus(quote) == 'Firm'">
                            Offer Valid Through {{ formatDateForDisplay(quote.Expiration) }}
                          </template>
                          <template v-if="quote.PriceExpiration && quotePriceStatus(quote) == 'Indication'">
                            Retry after {{ formatDateForDisplay(quote.PriceExpiration) }} to Bind
                          </template>
                        </template>
                        &nbsp;
                      </span>
                    </div>


                  </div>

                </div>
                <template v-if="quote.Status == 'Ok' && !allQuotesIneligible">
                  <div class="compare-documents  text-center">
                    <span v-if="showSummaryButton(quote) ||
                          (quote.SalesChannel == 'Digital' || quote.SalesChannel == 'Munich' || quote.SalesChannel == 'TestV0' || quote.FormBundleFileRef || quote.QuoteEstimateFileRef) ||
                          (coverageOption === 'flood' && showStatePacket())">
                      <v-menu :disabled="disabled"
                              offset-y
                              transition="slide-y-transition"
                              bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn color="secondary"
                                 v-bind="attrs"
                                 v-on="on">
                            Documents
                          </v-btn>
                        </template>
                        <v-list>
                          <!--Quote Application-->
                          <v-list-item v-if="showSummaryButton(quote)">
                            <a @click="viewSummary(quote)">View Quote/Application</a>
                          </v-list-item>
                          <!--TFIA Estimate-->
                          <v-list-item v-if="showTfiaEstimateButton(quote)">
                            <a @click="viewEstimate(quote)">View Estimate</a>
                          </v-list-item>
                          <!--Sample Specimen Files-->
                          <v-list-item v-if="quote.SalesChannel == 'Digital' || quote.SalesChannel == 'Munich' || quote.SalesChannel == 'TestV0' || quote.FormBundleFileRef">
                            <a v-if="quote.SalesChannel == 'Digital'" :href="getSamplePolicyDoc(quote)" target="_blank" style="text-decoration: none;">
                              View Sample Specimen Forms
                            </a>
                            <a v-if="quote.SalesChannel == 'Munich'" @click="getMunichSamplePolicyDoc(quote)" style="text-decoration: none;">
                              View Sample Specimen Forms
                            </a>
                            <a v-if="quote.SalesChannel == 'TestV0'" @click="getSamplePolicyDoc(quote)" style="text-decoration: none;">Sample Specimen Files</a>
                            <a v-if="quote.FormBundleFileRef" @click="getSamplePolicyDocFileRef(quote.FormBundleFileRef)" style="text-decoration: none;">
                              View Sample Specimen Forms
                            </a>
                          </v-list-item>
                          <!--State Packet-->
                          <v-list-item v-if="coverageOption === 'flood' && showStatePacket()">
                            <a style="text-decoration: none;" :href="getStatePacket()" target="_blank">View State Packet</a>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </span>
                    <span v-else>
                      &nbsp;
                    </span>
                  </div>
                  <div class="compare-subheading" v-if="inspectionsFlagEnabled && coverageOption != 'flood'">
                    <span class="text-center">Additional Requirements</span>
                  </div>
                  <div class="compare-item highlighted" v-if="inspectionsFlagEnabled && coverageOption != 'flood' && quote.SalesChannel != 'Human'">
                    <span v-html="quote.QuoteBindRequirements?.RequiresInspection ? 'Yes' : 'No'"></span>
                  </div>
                  <div class="compare-item" v-if="inspectionsFlagEnabled && coverageOption != 'flood' && quote.SalesChannel === 'Human'">
                    <span v-html="'Varies by Market'"></span>
                  </div>
                  <div class="compare-subheading">
                    <span class="text-center">Coverages</span>
                  </div>
                  <div v-if="showCovRow('FormType')" class="compare-item" :class="{ highlighted: quoteValueIsAltered(quote, 'FormType') }">
                    <span v-html="quote.FormType?.value" :class="{ small: isLongText(quote.FormType?.value) }"></span>
                  </div>
                  <div class="compare-item" v-if="showCovRow('A')"
                       :class="{ highlighted: quoteValueIsAltered(quote, 'CovA') }">
                    <span v-html="quoteValue(quote.CovA, true)" :class="{ small: isLongText(quote.CovA) }"></span>
                  </div>
                  <div class="compare-item" v-if="showCovRow('B')"
                       :class="{ highlighted: quoteValueIsAltered(quote, 'CovB') }">
                    <span v-html="quoteValue(quote.CovB, true)" :class="{ small: isLongText(quote.CovB) }"></span>
                  </div>
                  <div class="compare-item" v-if="showCovRow('C')"
                       :class="{ highlighted: quoteValueIsAltered(quote, 'CovC') }">
                    <span v-html="quoteValue(quote.CovC, true)" :class="{ small: isLongText(quote.CovC) }"></span>
                  </div>
                  <div class="compare-item" v-if="showCovRow('C') && coverageOption == 'flood'">
                    <span v-html="getValuationType()"></span>
                  </div>
                  <div class="compare-item" v-if="showCovRow('D')"
                       :class="{ highlighted: quoteValueIsAltered(quote, 'CovD') }">
                    <span v-html="quoteValue(quote.CovD, true)" :class="{ small: isLongText(quote.CovD) }"></span>
                  </div>
                  <div class="compare-item" v-if="showCovRow('Liability')"
                       :class="{ highlighted: quoteValueIsAltered(quote, 'Liability') }">
                    <span v-html="quoteValue(quote.Liability)" :class="{ small: isLongText(quote.Liability) }"></span>
                  </div>
                  <div class="compare-item" v-if="showCovRow('MedPay')"
                       :class="{ highlighted: quoteValueIsAltered(quote, 'MedPay') }">
                    <span v-html="quoteValue(quote.MedPay)" :class="{ small: isLongText(quote.MedPay) }"></span>
                  </div>
                  <div class="compare-item" v-if="showCovRow('PersonalInjury')"
                       :class="{ highlighted: quoteValueIsAltered(quote, 'PersonalInjury') }">
                    <span v-html="quoteValue(quote.PersonalInjury)"
                          :class="{ small: isLongText(quote.PersonalInjury) }"></span>
                  </div>
                  <div class="compare-item" v-if="showCovRow('ReplacementCostDwelling')"
                       :class="{ highlighted: quoteValueIsAltered(quote, 'ReplacementCostDwelling') }">
                    <span v-html="quoteValue(quote.ReplacementCostDwelling)"
                          :class="{ small: isLongText(quote.ReplacementCostDwelling) }"></span>
                  </div>
                  <div class="compare-item" v-if="showCovRow('ReplacementCostContents')"
                       :class="{ highlighted: quoteValueIsAltered(quote, 'ReplacementCostContents') }">
                    <span v-if="response.SalesChannel === 'VavePersonalLines'" v-html="replacementCostContentsValue(quote.ReplacementCostContents)"
                          :class="{ small: isLongText(quote.ReplacementCostContents) }"></span>
                    <span v-else v-html="quoteValue(quote.ReplacementCostContents)"
                          :class="{ small: isLongText(quote.ReplacementCostContents) }"></span>
                  </div>
                  <div class="compare-item" v-if="showCovRow('ReplacementCostStructures')"
                       :class="{ highlighted: quoteValueIsAltered(quote, 'ReplacementCostStructures') }">
                    <span v-if="response.SalesChannel === 'VavePersonalLines'" v-html="replacementCostStructuresValue(quote.ReplacementCostStructures)"></span>
                    <span v-else v-html="quoteValue(quote.ReplacementCostStructures)"></span>
                  </div>
                  <div class="compare-item" v-if="showCovRow('LossAssessment')"
                       :class="{ highlighted: quoteValueIsAltered(quote, 'LossAssessment') }">
                    <span v-html="quoteValue(quote.LossAssessment)"
                          :class="{ small: isLongText(quote.LossAssessment) }"></span>
                  </div>
                  <div class="compare-item" v-if="showCovRow('InflationGuard')"
                       :class="{ highlighted: quoteValueIsAltered(quote, 'InflationGuard') }">
                    <span v-html="quoteValue(quote.InflationGuard)"
                          :class="{ small: isLongText(quote.InflationGuard) }"></span>
                  </div>
                  <div class="compare-item" v-if="showCovRow('BuildingOrdinanceOrLaw')"
                       :class="{ highlighted: quoteValueIsAltered(quote, 'BuildingOrdinanceOrLaw') }">
                    <span v-if="response.SalesChannel === 'VavePersonalLines'" v-html="percentValue(quote.BuildingOrdinanceOrLaw, false)"
                          :class="{ small: isLongText(quote.BuildingOrdinanceOrLaw) }"></span>
                    <span v-else v-html="quoteValue(quote.BuildingOrdinanceOrLaw)"
                          :class="{ small: isLongText(quote.BuildingOrdinanceOrLaw) }"></span>
                  </div>

                  <div class="compare-item" v-if="showCovRow('Theft')"
                       :class="{ highlighted: quoteValueIsAltered(quote, 'Theft') }">
                    <span v-html="quoteValue(quote.Theft)"
                          :class="{ small: isLongText(quote.Theft) }"></span>
                  </div>
                  <div class="compare-item" v-if="showCovRow('Mold')"
                       :class="{ highlighted: quoteValueIsAltered(quote, 'Mold') }">
                    <span v-html="quoteValue(quote.Mold)"
                          :class="{ small: isLongText(quote.Mold) }"></span>
                  </div>

                  <div class="compare-item" v-if="showCovRow('WaterBackup')"
                       :class="{ highlighted: quoteValueIsAltered(quote, 'WaterBackup') }">
                    <span v-html="quoteValue(quote.WaterBackup)" :class="{ small: isLongText(quote.WaterBackup) }"></span>
                  </div>
                  <div class="compare-item" v-if="showCovRow('WaterDamageSublimit')"
                       :class="{ highlighted: quoteValueIsAltered(quote, 'WaterDamageSublimit') }">
                    <span v-html="quoteValue(quote.WaterDamageSublimit)"
                          :class="{ small: isLongText(quote.WaterDamageSublimit) }"></span>
                  </div>
                  <div class="compare-subheading"> <span class="text-center">Deductibles</span></div>
                  <div class="compare-item" v-if="showCovRow('TotalPeril')"
                       :class="{ highlighted: quoteValueIsAltered(quote, 'TotalPeril') }">
                    <span v-html="quoteValue(quote.TotalPeril)" :class="{ small: isLongText(quote.TotalPeril) }"></span>
                  </div>
                  <div class="compare-item" v-if="showCovRow('WindHailPeril')"
                       :class="{ highlighted: quoteValueIsAltered(quote, 'WindHailPeril') }">
                    <span v-html="deductibleValue(quote.WindHailPeril, quote.WindHailFlatAmount)" :class="{ small: isLongText(quote.WindHailPeril) }"></span>
                  </div>
                  <div class="compare-item" v-if="showCovRow('EarthquakePeril')"
                       :class="{ highlighted: quoteValueIsAltered(quote, 'EarthquakePeril') }">
                    <span v-html="deductibleValue(quote.EarthquakePeril, quote.EarthquakeFlatAmount)"
                          :class="{ small: isLongText(quote.EarthquakePeril) }"></span>
                  </div>
                  <div class="compare-item"
                       v-if="showCovRow('FloodPeril') && !(quote.SalesChannel == 'Human' && coverageOption == 'flood')"
                       :class="{ highlighted: quoteValueIsAltered(quote, 'FloodPeril') }">
                    <span v-html="deductibleValue(quote.FloodPeril, quote.FloodFlatAmount)" :class="{ small: isLongText(quote.FloodPeril) }"></span>
                  </div>
                  <div class="compare-item" v-else :class="{ highlighted: quoteValueIsAltered(quote, 'FloodPeril') }">
                    <span v-html="quoteValue(quote.FloodPeril)" :class="{ small: isLongText(quote.FloodPeril) }"></span>
                  </div>
                  <div class="compare-item" v-if="showCovRow('DifferenceInConditions')" :class="{ highlighted: quoteValueIsAltered(quote, 'DifferenceInConditions') }">
                    <span v-html="quote.DifferenceInConditions?.value ?? 'No Coverage'" :class="{ small: isLongText(quote.DifferenceInConditions) }"></span>
                  </div>
                </template>
              </div>
            </template>

            <template v-else-if="stopLoadingQuotes">
              <!--Failed Quote-->
              <div class="carrier-column d-flex flex-column"
                   v-for="quote in failedQuotes" :key="quote.id">

                <div class="compare-carrier text-center">
                  <div class="carrier-logo"
                       :style="{ 'background-image': 'url(\'' + getSalesChannelLogo(quote.SalesChannel, coverageOption, floodTFIAFeatureFlagEnabled) + '\')' }">
                    &nbsp;
                  </div>
                  <v-btn disabled color="secondary" x-large @click="selectQuote(quote)"
                         type="button">
                        {{ quote.Status == "Error"
                            ? "Error"
                            : quote.SalesChannel == "Human"
                              ? getHumanButtonText()
                              : quote.SalesChannel == "TFIA"
                                ? "Request to Bind"
                                : "Bind Quote Now" }}
                  </v-btn>
                  <template v-if="quote.ErrorText || quote.DebugText || quoteAgedOut(quote)">
                    <v-tooltip right>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on">
                          <v-icon size="24" class="preview-icon">fas fa-exclamation-triangle</v-icon>
                        </v-btn>
                      </template>
                      <span style="display: block;" v-html="quote.ErrorText"></span>
                      <span v-if="showDebugText" style="display: block;" v-html="quote.DebugText"></span>
                      <span v-if="quoteAgedOut(quote)" style="display: block;" v-html="quoteAgedOutMessage(quote)"></span>
                    </v-tooltip>
                  </template>
                </div>
                <div class="compare-premium text-center d-flex align-center justify-center">

                  <div class="compare-premium-content">
                    <h3 class="text-center">
                      Quote Taking <br /> Longer Than Expected <br />
                      <Loading :size="24" />
                    </h3>
                  </div>

                </div>
              </div>
            </template>

            <template v-else-if="!stopLoadingQuotes">
              <!--Skeleton-->
              <div class="carrier-column d-flex flex-column">
                <div class="compare-carrier text-center">
                  <!-- <v-skeleton-loader type="text" class="image-skeleton" /> -->
                  <div class="carrier-logo"
                       :style="{ 'background-image': 'url(\'' + getSalesChannelLogo(response.SalesChannel, coverageOption) + '\')' }">
                    &nbsp;
                  </div>
                  <v-btn :disabled="true" color="secondary" x-large type="button">Loading...</v-btn>
                </div>
                <div class="compare-premium"><v-skeleton-loader type="text" /></div>
                <div class="compare-documents "><v-skeleton-loader type="text" /></div> <!--documents-->
                <div v-if="inspectionsFlagEnabled && coverageOption != 'flood'" class="compare-subheading"><v-skeleton-loader type="text" /></div> <!--inspections text-->
                <div v-if="inspectionsFlagEnabled && coverageOption != 'flood'" class="compare-item"><v-skeleton-loader type="text" /></div> <!--inspections value-->
                <div class="compare-subheading"><v-skeleton-loader type="text" /></div> <!--coverages text-->
                <div class="compare-item" v-if="showCovRow('FormType')"><v-skeleton-loader type="text" /></div>
                <div class="compare-item" v-if="showCovRow('A')"><v-skeleton-loader type="text" /></div>
                <div class="compare-item" v-if="showCovRow('B')"><v-skeleton-loader type="text" /></div>
                <div class="compare-item" v-if="showCovRow('C')"><v-skeleton-loader type="text" /></div>
                <div class="compare-item" v-if="showCovRow('C') && coverageOption === 'flood'"><v-skeleton-loader type="text" /></div>
                <div class="compare-item" v-if="showCovRow('D')"><v-skeleton-loader type="text" /></div>
                <div class="compare-item" v-if="showCovRow('Liability')"><v-skeleton-loader type="text" /></div>
                <div class="compare-item" v-if="showCovRow('MedPay')"><v-skeleton-loader type="text" /></div>
                <div class="compare-item" v-if="showCovRow('PersonalInjury')"><v-skeleton-loader type="text" /></div>
                <div class="compare-item" v-if="showCovRow('ReplacementCostDwelling')">
                  <v-skeleton-loader type="text" />
                </div>
                <div class="compare-item" v-if="showCovRow('ReplacementCostContents')">
                  <v-skeleton-loader type="text" />
                </div>
                <div class="compare-item" v-if="showCovRow('ReplacementCostStructures')">
                  <v-skeleton-loader type="text" />
                </div>
                <div class="compare-item" v-if="showCovRow('LossAssessment')"><v-skeleton-loader type="text" /></div>

                <div class="compare-item" v-if="showCovRow('InflationGuard')"><v-skeleton-loader type="text" /></div>
                <div class="compare-item" v-if="showCovRow('BuildingOrdinanceOrLaw')">
                  <v-skeleton-loader type="text" />
                </div>
                <div class="compare-item" v-if="showCovRow('Theft')"><v-skeleton-loader type="text" /></div>
                <div class="compare-item" v-if="showCovRow('Mold')"><v-skeleton-loader type="text" /></div>
                <div class="compare-item" v-if="showCovRow('WaterBackup')"><v-skeleton-loader type="text" /></div>
                <div class="compare-item" v-if="showCovRow('WaterDamageSublimit')"><v-skeleton-loader type="text" /></div>

                <div class="compare-subheading">
                  <v-skeleton-loader type="text" />
                </div>
                <div class="compare-item" v-if="showCovRow('TotalPeril')"><v-skeleton-loader type="text" /></div>
                <div class="compare-item" v-if="showCovRow('WindHailPeril')"><v-skeleton-loader type="text" /></div>
                <div class="compare-item" v-if="showCovRow('EarthquakePeril')"><v-skeleton-loader type="text" /></div>
                <div class="compare-item" v-if="showCovRow('FloodPeril')"><v-skeleton-loader type="text" /></div>
                <div class="compare-item" v-if="showCovRow('DifferenceInConditions')"><v-skeleton-loader type="text" /></div>

              </div>

            </template>

          </div>



        </div>
      </div>
      <br><br>

      <Dialog :title="fileTitle" :value="showDocument" width="1000px" @close-dialog="showDocument = false;">
        <template v-slot:card-text>
          <DocumentPreview v-bind="previewConfig" />
        </template>
        <template v-slot:card-actions>
          <v-btn color="secondary" text type="button" @click="showDocument = false">Close</v-btn>
        </template>
      </Dialog>

      <RefineDialogDigital v-if="showRefineDialogDigital" :policyKey="refiningQuotePolicyKey"
        :salesChannel="refiningQuoteSalesChannel" :submissionId="submissionId" @close="showRefineDialogDigital = false">
      </RefineDialogDigital>

      <GenericDialog :value="floodHumanQuoteSubmittedDialog" :title="`Submission Submitted to Underwriter`"
        :width="`600px`" persistent>

        <template v-slot:card-text>
          <v-row>
            <v-col cols="12">
              <p class="mb-0">
                We've escalated your submission request to one of our experienced internal underwriters at Amwins. We
                appreciate your trust in us. Our team will begin working on it immediately, and we anticipate providing you
                with a quote within the next 24 hours. Thank you for considering Amwins for your insurance needs.
              </p>
            </v-col>
          </v-row>
        </template>

        <template v-slot:card-actions>
          <div>
            <v-btn color="secondary" type="submit" @click="floodHumanQuoteSubmittedDialog = false">
              OK
            </v-btn>
          </div>
        </template>

      </GenericDialog>

      <GenericDialog :value="floodTfiaRequestSubmittedDialog" :title="`Success! Your Request to Bind Has Been Received`"
        :width="`600px`" persistent>

        <template v-slot:card-text>
          <v-row>
            <v-col cols="12">
              <p class="mb-0">
                Congratulations! Your request to bind with TFIA has been successfully received. Our team will review the details and get back to you shortly. 
                In the meantime, feel free to explore more options on our portal. Thank you for choosing TFIA for your flood insurance needs!
              </p>
            </v-col>
          </v-row>
        </template>

        <template v-slot:card-actions>
          <div>
            <v-btn color="secondary" type="submit" @click="floodTfiaRequestSubmittedDialog = false">
              OK
            </v-btn>
          </div>
        </template>

      </GenericDialog>

      <GenericDialog :value="floodTfiaRequestFailedDialog" :title="`Oops! Something Went Wrong`"
        :width="`600px`" persistent>

        <template v-slot:card-text>
          <v-row >
            <v-col cols="12">
              <p class="mb-0">
                We apologize, but it seems there was an issue processing your request to bind. Our underwriting team has been notified, and we'll handle this for you. 
                If you have any urgent concerns or need immediate assistance, please contact our support team. Thank you for your patience as we work to resolve this.
              </p>
            </v-col>
          </v-row>
        </template>

        <template v-slot:card-actions>
          <div>
            <v-btn color="secondary" type="submit" @click="floodTfiaRequestFailedDialog = false">
              OK
            </v-btn>
          </div>
        </template>

      </GenericDialog>

    </div>
  </div>
</template>

<script>
import { QuoteStatus as PortalQuoteStatus } from "@/api/index.defs";
import Environment from '@/scripts/environment';
import Dialog from '@/components/shared/Dialog.vue'
import DocumentPreview from '@/components/views/Quote/DocumentPreview.vue'
import RefineDialogDigital from '@/components/views/Quote/RefineDialogDigital.vue'
import { formatMoney, formatDateForDisplay, getSalesChannelLogo } from '@/scripts/helper'
import GenericDialog from '@/components/shared/Dialog.vue'
import Loading from '@/components/shared/Loading.vue'
import { QuotesService } from '@/api/QuotesService'
import { SubmissionService } from '@/api/SubmissionService'
import { FeatureFlagsService } from '@/api/FeatureFlagsService'
import { SalesChannel } from '@/api/index.defs'

export default {
  name: "QuoteComparisonGrid",
  components: {
    Dialog,
    DocumentPreview,
    RefineDialogDigital,
    GenericDialog,
    Loading
  },
  data() {
    return {
      isMounted: false,
      cursorMove: false,
      showRefineDialogDigital: false,
      refiningQuotePolicyKey: null,
      refiningQuoteSalesChannel: null,
      showDocument: false,
      fileTitle: '',
      previewConfig: {},
      floodHumanQuoteSubmittedDialog: false,
      floodTfiaRequestSubmittedDialog: false,
      floodTfiaRequestFailedDialog: false,
      submittedToTfia: false,
      floodTFIAFeatureFlagEnabled: false,
      inspectionsFlagEnabled: false,
      tfiaEstimateDocFeatureFlagEnabled: false,
      dicEnabledStates: ['CA'],
      statePacketEnabledStates: ['CA', 'MA', 'NJ'],
      stopLoadingQuotes: false
    };
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    submissionId: {
      type: String,
      default: null
    },
    coverageOption: {
      type: String,
      default: null
    },
    state: {
      type: String,
      default: null
    },
    submission: {
      type: Object,
      default: null
    },
  },
  emits: [
    'change',
    'getSubmission'
  ],
  computed: {
    defaultUnderwriter() {
      return this.$store.getters.getDefaultUnderwriter;
    },
    showDebugText() {
      return (this.$store.getters.userHasRole('support') ||
        this.$store.getters.userHasRole('admin') ||
        this.$store.getters.userHasRole('employee'));
    },
    noQuotesAvailable() {
      if (!this.gateTheSubmissionResponse.length) return false;
      if (this.gateTheSubmissionResponse.find(i => i.Include)) return false;
      return true;
    },
    noQuotesAvailableMessage() {
      const human = this.gateTheSubmissionResponse.find(i => i.SalesChannel == "Human");
      if (human && human.ExclusionMessage) return human.ExclusionMessage;
      else return "No Quotes Available at this time.";
    },
    allQuotesIneligible() {
      const quotes = this.getQuotes;
      if (this.gateTheSubmissionResponse.filter(i => i.Include).length > quotes.length) return false;
      if (quotes.find(i => i.Status == 'Ok')) return false;
      return true;
    },
    getQuotes() {
      const quotes = this.$store.getters['quoteForm/getQuotes'];
      return quotes || [];
    },
    gateTheSubmissionResponse() {
      let response = this.$store.getters['quoteForm/getGateTheSubmissionResponse'];
      if (this.getQuotes.length > 0) {
      // Sort the quotes
        const haveQuotes = response.filter(x => this.getQuotes.filter(y => y.SalesChannel === x.SalesChannel).length > 0 && x.SalesChannel != 'Human');
        const pricedQuotes = haveQuotes.filter(x => this.getQuotes.find(y => y.SalesChannel === x.SalesChannel).ErrorText == null)
        .map(x => {
          return {
            ...x,
            Total: this.getQuotes.find(z => z.SalesChannel === x.SalesChannel).Total
          }
        }).sort((a, b) => a.Total - b.Total)
        const declinedQuotes = haveQuotes.filter(x => this.getQuotes.find(y => y.SalesChannel === x.SalesChannel).ErrorText != null)
        const uwResponse = response.filter(x => x.SalesChannel === 'Human')
        const doNotHaveQuotes = response.filter(x => haveQuotes.filter(y => y.SalesChannel === x.SalesChannel).length === 0 && x.SalesChannel != 'Human')
        response = pricedQuotes.concat(doNotHaveQuotes).concat(uwResponse).concat(declinedQuotes)
      }

      return response || [];
    },
    humanReferralDate() {
      return this.submission?.UnderwriterReferralDate
    },
    failedQuotes() {
      let response = this.$store.getters['quoteForm/getGateTheSubmissionResponse'];
      if (this.getQuotes.length > 0 && this.stopLoadingQuotes) {
        const haveQuotes = response.filter(x => this.getQuotes.filter(y => y.SalesChannel === x.SalesChannel).length > 0 && x.SalesChannel != 'Human');
        return response.filter(x => haveQuotes.filter(y => y.SalesChannel === x.SalesChannel).length === 0 && x.SalesChannel != 'Human').filter(r => r.Include)
      }

      return []
    }
  },
  async created() {
    // clear out the additional form when attempting to start a new submission
    this.$store.commit('additionalForm/clearAdditionalForm');

    await FeatureFlagsService.isEnabled({ featureName: "FloodTFIA" })
      .then((response) => {
        this.floodTFIAFeatureFlagEnabled = response;
      });      

    FeatureFlagsService.isEnabled({ featureName: "TfiaEstimateDoc" })
      .then((response) => {
        this.tfiaEstimateDocFeatureFlagEnabled = response;
      });

    FeatureFlagsService.isEnabled({ featureName: "Inspections" })
      .then((response) => {
        this.inspectionsFlagEnabled = response;
      });

    if (this.floodTFIAFeatureFlagEnabled && this.coverageOption == 'flood') {
      await this.getSubmission()
        .then((submission) => {
          this.submittedToTfia = submission.SubmittedToTfia
        })
    }

    // Wait 60 seconds and then display 'Quote Taking Longer Than Expected' for quotes still in flight
    setTimeout(() => {
      this.stopLoadingQuotes = true
    }, 60000)
  },
  methods: {
    formatMoney,
    formatDateForDisplay,
    getSalesChannelLogo,
    refine(quote) {
      this.refiningQuotePolicyKey = quote.id;
      this.refiningQuoteSalesChannel = quote.SalesChannel;

      if (quote.SalesChannel == 'Digital' || quote.SalesChannel == 'Munich' || quote.SalesChannel == 'TestV0' || quote.SalesChannel == SalesChannel.VavePersonalLines) {
        this.showRefineDialogDigital = true;
      }

    },
    getQuoteBySalesChannel(salesChannel) {
      const quotes = this.$store.getters['quoteForm/getQuotes'];
      return quotes.find(i => i.SalesChannel == salesChannel);
    },
    getValuationType() {
      const valuationType = this.$store.getters.getControlValue("quoteForm", 'ValuationType');
      return valuationType ?? 'N/A'
    },
    quoteDisabled(quote) {
      if (Environment.environment == 'localhost' || Environment.environment == 'development') {
        return this.disabled || this.quoteAgedOut(quote) || this.quoteHumanFloodSubmitted(quote) || this.isSpocAndWeekend(quote) || this.quoteTfiaFloodSubmitted(quote);
      } else {

        return quote.Status != 'Ok' || (this.quotePriceStatus(quote) != 'Firm' && this.quotePriceStatus(quote) != 'Indication')  || this.quoteAgedOut(quote) || this.quoteHumanFloodSubmitted(quote) || this.isSpocAndWeekend(quote) || this.quoteTfiaFloodSubmitted(quote) || this.disabled;
      }
    },
    isSpocAndWeekend(quote) {
      // Only do this check for submissions using the SPOC UW and a non-flood product onm the human sales channel
      if (this.defaultUnderwriter.Email !== 'referralunderwritinggroup.access@amwins.com' || this.coverageOption === 'flood' || quote.SalesChannel != "Human") return false;

      const todayEst = new Date().toLocaleString("en-US", { timeZone: "America/New_York" });
      const today = new Date(todayEst);
      const day = today.getDay();
      const hour = today.getHours();
      const isWeekend = ((day === 6 && hour > 2) || day === 0 || (day === 1 && hour < 3));

      if (isWeekend) return true;

      return false;
    },
    quoteAgedOut(quote) {
      if (quote.SalesChannel == "Human") {
        var today = new Date();
        let effectiveDate = quote.EffectiveDate ? new Date(quote.EffectiveDate.value) : null;
        if (!effectiveDate) {
          const effDate = this.$store.getters.getControlValue('quoteForm', 'EffectiveDate');
          effectiveDate = new Date(effDate);
        }
        effectiveDate.setDate(effectiveDate.getDate() + 25);
        return (today > effectiveDate)
      }

      return false;
    },
    quoteAgedOutMessage(quote) {
      if (quote.SalesChannel == "Human") {
        return "Cannot Submit with Effective Date over 25 days in past.";
      }
      return "";
    },
    quoteValue(obj, showExcluded) {
      if (this.coverageOption === 'flood' && showExcluded && obj && obj.value === 0) {
        return 'Excluded'
      }
      return formatMoney(obj && obj.value ? obj.value : 0) + '&nbsp;';
    },
    deductibleValue(obj, flatObj) {
      return obj.value + (flatObj?.value ? ` (${formatMoney(flatObj && flatObj.value ? flatObj.value : 0)})` : '') ;
    },
    percentValue(obj, isDecimal = true) {
      if (obj && obj.value != null && isDecimal) {
        return (obj.value * 100) + '%'
      }
      else if (obj && obj.value != null && !isDecimal) {
        return obj.value + '%'
      } 

      return '';
    },
    replacementCostStructuresValue(obj) {
      if (obj && obj.value != null) {
        switch (obj.value) {
          case 0:
            return 'No Extended Coverage'
          case 25:
            return '25% Extended Coverage A'
          case 50:
            return '50% Extended Coverage A'
          default:
            return 'No Extended Coverage'
        }
      }

      return 'No Extended Coverage';
    },
    replacementCostContentsValue(obj) {
      if (obj && obj.value != null) {
        return obj.value ? 'Included' : 'Excluded'
      }

      return 'Excluded';
    },
    quoteValueIsAltered(quote, name) {
      if (quote.SalesChannel == 'Human') return false;

      try {
        const quotes = this.$store.getters['quoteForm/getQuotes'];
        const human = quotes.find(q => q.SalesChannel == 'Human')
        if (!human) return false;

        const value = quote[name].value;
        if (!human[name]) return false;
        if (human[name].value == 'Optional') return false;
        if (human[name].value === null && value === 0) return false;
        if (human[name].value === value) return false;
        return true;
      } catch (err) {
        return false;
      }

    },
    quotePriceStatus(quote) {
      if (!quote.PriceStatus) return 'Firm';
      else return quote.PriceStatus;
    },
    quoteHumanFloodSubmitted(quote) {
      return quote.SalesChannel == 'Human' && this.humanReferralDate != null
    },
    quoteTfiaFloodSubmitted(quote) {
      return quote.SalesChannel == 'TFIA' &&  this.submittedToTfia
    },
    isLongText(val) {
      if (val === null || val === undefined) return false;
      if (val.value === null || val.value === undefined) return false;
      const text = val.value + '';
      if (text.length > 20) {
        return true;
      }
      return false;
    },
    showSummaryButton(quote) {
      const canViewSummary = ['Digital', 'Munich', 'TestV0', 'Vave', 'Hiscox', 'Chubb', SalesChannel.VavePersonalLines];
      const ignoreRefId = ['Digital', 'Munich', 'TestV0'];
      var viewable = canViewSummary.indexOf(quote.SalesChannel) > -1 && quote.Status == 'Ok';
      viewable = viewable && (ignoreRefId.indexOf(quote.SalesChannel) > -1 || quote.QuoteApplicationFileRef != null);
      return viewable;
    },
    showTfiaEstimateButton(quote) {
      if (!this.tfiaEstimateDocFeatureFlagEnabled) return false

      const canViewEstiamte = [SalesChannel.TFIA];
      var viewable = canViewEstiamte.indexOf(quote.SalesChannel) > -1 && quote.Status == 'Ok';
      return viewable;
    },
    showRefineButton(quote) {
      const canRefine = ['Digital', 'Munich', 'TestV0', SalesChannel.VavePersonalLines];
      return canRefine.indexOf(quote.SalesChannel) > -1 && quote.Status == 'Ok' && quote.PriceStatus == 'Firm';
    },
    viewSummary(quote) {
      this.previewConfig = {
        mode: quote.SalesChannel
      };

      const needsPolicyKey = ['Digital', 'Munich', 'TestV0'];
      if (needsPolicyKey.indexOf(quote.SalesChannel) > -1) {
        this.previewConfig.policyKey = quote.id;
      }
      else {
        this.previewConfig.fileRefId = quote.QuoteApplicationFileRef;
      }


      this.fileTitle = 'Quote Summary'
      this.showDocument = true;
    },
    viewEstimate(quote) {
      this.previewConfig = {
        mode: quote.SalesChannel
      };

      this.previewConfig.fileRefId = quote.QuoteEstimateFileRef;

      this.fileTitle = 'Quote Estimate'
      this.showDocument = true;
    },
    calcCursorMove() {
      if (this.isMounted) {
        let containerW = this.$refs.compareContainer.scrollWidth;
        let labelsW = this.$refs.compareLabels.scrollWidth;
        let columnsW = this.$refs.compareColumns.scrollWidth;

        this.cursorMove = containerW < labelsW + columnsW;
      } else this.cursorMove = false;
    },
    addSlider() {
      // method from https://discourse.wicg.io/t/drag-to-scroll-a-simple-way-to-scroll-sideways-on-desktop/3627
      const slider = document.querySelector(".compare-chart");
      let isDown = false;
      let startX;
      let scrollLeft;

      slider.addEventListener("mousedown", (e) => {
        isDown = true;
        startX = e.pageX - slider.offsetLeft;
        scrollLeft = slider.scrollLeft;
      });

      slider.addEventListener("mouseleave", () => {
        isDown = false;
      });

      slider.addEventListener("mouseup", () => {
        isDown = false;
      });

      slider.addEventListener("mousemove", (e) => {
        if (!isDown) return;
        e.preventDefault();
        const x = e.pageX - slider.offsetLeft;
        const walk = x - startX;
        slider.scrollLeft = scrollLeft - walk;
      });
    },
    getHumanButtonText() {
      return this.humanReferralDate == null ? "Submit to Underwriter" : "Submitted"
    },
    async getSubmission() {
      return await SubmissionService.get({ id: this.submissionId })
    },
    selectQuote(quote) {
      if (this.coverageOption === 'flood' && quote.SalesChannel == "Human") {
        this.disabled = true
        QuotesService.floodHumanSubmission({ id: quote.id })
          .then((submittedFloodHumanQuote) => {
            if (submittedFloodHumanQuote) {
              this.floodHumanQuoteSubmittedDialog = true
              this.$store.commit('quoteForm/deleteQuote', submittedFloodHumanQuote.SalesChannel);
              this.$store.commit('quoteForm/addQuote', submittedFloodHumanQuote);
              this.$emit('getSubmission', this.submissionId)
            }
          })
          .catch((e) => {
            console.log(e)
          })
          .finally(() => {
            this.disabled = false
          })
      }
      else if (this.coverageOption === 'flood' && quote.SalesChannel == "TFIA") {
        this.disabled = true
        QuotesService.submitTfiaRequest({ id: this.submissionId })
          .then((res) => {
            if (res) {
              // Always assuming success if we get a response back. Failures will be handled on the backend through the message reponse.
              this.floodTfiaRequestSubmittedDialog = true
              this.submittedToTfia = res.SubmittedToTfia
            }
          })
          .catch((e) => {
            console.log(e)
            this.floodTfiaRequestFailedDialog = true
          })
          .finally(() => {
            this.disabled = false
          })
      }
      else {
        const event = {
          value: quote
        }
        this.$emit('selectQuote', event);
      }
    },
    showCovRow(type) {
      if (this.coverageOption == 'flood' && type !== 'FormType') {
        return ['A', 'B', 'C', 'D', 'FloodPeril'].indexOf(type) > -1;
      }

      if (type === 'A') {
        return ['ho3', 'ho5', 'ho6', 'ho3_windonly', 'ho6_windonly', 'dp3'].indexOf(this.coverageOption) > -1
      } else if (type === 'B') {
        return ['ho3', 'ho5', 'ho3_windonly', 'dp3'].indexOf(this.coverageOption) > -1
      } else if (type === 'C' || type === 'D') {
        return ['ho3', 'ho5', 'ho4', 'ho6', 'ho3_windonly', 'ho6_windonly', 'dp3'].indexOf(this.coverageOption) > -1
      } else if (type === 'Theft') {
        return this.coverageOption != 'dp3';
      }

      if (type === 'DifferenceInConditions') {
        return this.dicEnabledStates.indexOf(this.state) > -1 && ['ho3', 'ho4', 'ho6', 'dp3'].indexOf(this.coverageOption) > -1
      }

      if (type === 'FormType') {
        return this.coverageOption != 'flood'
      }

      return true
    },
    getSamplePolicyDoc(quote) {
      const api = Environment.API_HOST;
      var effectiveDate = new Date(quote.EffectiveDate.value) ?? new Date();
      
      switch (this.coverageOption.toLowerCase()) {
        case "dp3":
        case 'dp3_windonly':
          if(effectiveDate <= new Date("2024-01-01")) {
            return api + '/documents/sample-policy-wordings/Sample_W-ISO_DwellingThree_2022-01_REV2.pdf'
          } else {
            return api + '/documents/sample-policy-wordings/Sample_W-ISO_DwellingThree_2024-01.pdf'
          }
        case "ho3":
        case 'ho3_windonly':
        if(effectiveDate <= new Date("2024-01-01")) {
          return api + '/documents/sample-policy-wordings/Sample_W-ISO_HomeThree_2022-01_REV2.pdf'
        } else {
          return api + '/documents/sample-policy-wordings/Sample_W-ISO_HomeThree_2024-01.pdf'
        }
        case "ho4":
          return api + '/documents/sample-policy-wordings/Sample_W-ISO_TenantFour_2022-01_REV2.pdf'
        case "ho6":
        case 'ho6_windonly':
        if(effectiveDate <= new Date("2024-01-01")) {
          return api + '/documents/sample-policy-wordings/Sample_W-ISO_CondoSix_2022-01_REV2.pdf'
        } else {
          return api + '/documents/sample-policy-wordings/Sample_W-ISO_CondoSix_2024-01.pdf'
        }
        default: return ''
      }
    },
    showStatePacket() {
      if (!this.state) return false;
      return this.statePacketEnabledStates.indexOf(this.state) > -1;
    },
    getStatePacket() {
      if (this.state) {
        const api = Environment.API_HOST;
        return api + `/documents/state/State Packet - ${this.state.toUpperCase()}.pdf`;
      }
      return ''
    },
    getSamplePolicyDocFileRef(fileRef) {
      this.$http.get(`/api/File/${fileRef}`, { responseType: 'arraybuffer' })
        .then((result) => {
          if (result) {
            const blob = new Blob([result.data], { type: result.headers['content-type'] })
            const url = window.URL.createObjectURL(blob);
            var link = document.createElement('a');
            link.href = url;
            link.target = '_blank';
            link.click();
          } else {
            this.$store.dispatch('toastError', { Message: 'Failed to Open File' })
          }
        })
    },
    async getMunichSamplePolicyDoc(quote) {
      this.$httpSBIX.post(`api/forms/sample?WebApplicationID=${quote.Number}`, null, { responseType: 'arraybuffer' })
        .then((result) => {
          if (result) {
            const blob = new Blob([result.data], { type: result.headers['content-type'] })
            const url = window.URL.createObjectURL(blob);
            var link = document.createElement('a');
            link.href = url;
            link.target = '_blank';
            link.click();
          } else {
            this.$store.dispatch('toastError', { Message: 'Failed to Open File' })
          }
        })
    },
    mounted() {
      this.QuoteStatus = PortalQuoteStatus;

      this.$nextTick(() => {
        this.isMounted = true;
        this.addSlider();
        this.calcCursorMove();
        try {
          window.addEventListener("resize", this.calcCursorMove);
        } catch (err) {
          console.error(err);
        }
      });
    },
    destroyed() {
      try {
        window.removeEventListener("resize", this.calcCursorMove, false);
      } catch (err) {
        console.error(err);
      }
    }
  }
}
</script>

<style lang="scss">
@import "@/style.scss";

$compare-labels-width: 280px;

.underline {
  text-decoration: underline;
}

.cursorMove,
.cursorMove .v-skeleton-loader {
  cursor: move !important;
}

.compare-labels {
  width: $compare-labels-width;
  min-width: $compare-labels-width;
  box-shadow: 8px 0 4px -2px rgba(0, 0, 0, 0.25);
  overflow: hidden;
  position: relative;
  z-index: 3;

  .compare-item {
    padding-top: 0;
    padding-bottom: 0;
    height: 41px;
    display: flex;
    align-items: center;
    justify-content: right;


    span {
      white-space: normal;
      display: block;
      font-size: 16px;
      line-height: 18px;
    }
  }
}

.compare-carrier {
  height: 200px;
  border-bottom: 1px solid transparent;

  .carrier-logo {
    display: block;
    width: 60%;
    height: 100px;
    margin: 0 auto;
    margin-top: 20px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }

  .image-skeleton .v-skeleton-loader__text {
    height: 60px;
    width: 60%;
    margin: 0 auto;
    margin-top: 40px;
    margin-bottom: 20px;
  }

  .button-skeleton .v-skeleton-loader__text {
    height: 52px;
    width: 80%;
    margin: 0 auto;
  }
}

.compare-premium {
  padding: 10px;
  // line-height: 60px;
  border-bottom: 1px solid transparent;
  height: 145px;

  .annual-premium {
    line-height: 115px;
  }

  h2 {
    margin-top: 48px;
  }

  * {
    // line-height: 100px;
    vertical-align: middle;
    white-space: nowrap;
  }

  .v-skeleton-loader__text {
    height: 40px;
    margin: 40px 0;
  }

  .carrier-column & {
    background-color: #ebeae9;
  }

  .expiration {
    padding-top: 10px;
  }

  .expiration span {
    font-size: 12px;
    line-height: 15px;
    font-style: italic;
    display: block;
    text-align: center;
  }
}

.compare-subheading {
  padding: 10px;
  border-bottom: 1px solid transparent;

  span {
    display: block;
    font-style: italic;
    opacity: 0.8;
  }

  * {
    line-height: 40px;
    white-space: nowrap;
  }

  .v-skeleton-loader__text {
    height: 30px;
    margin: 5px 0;
  }

  .carrier-column & {
    border-color: black;
  }
}

.carrier-column {
  height: 100%;

  .compare-item {
    text-align: center;
  }
}

.compare-documents {
  padding: 10px;
  border-bottom: 1px solid transparent;

  span {
    display: block;
  }

  * {
    line-height: 40px;
    white-space: nowrap;
  }

  .v-skeleton-loader__text {
    height: 30px;
    margin: 5px 0;
  }

  .carrier-column & {
    border-color: black;
  }
}

.carrier-column {
  height: 100%;

  .compare-item {
    text-align: center;
  }
}

.compare-item {
  padding: 10px;
  border-bottom: 1px solid transparent;

  span {
    display: block;
    padding-left: 10px;
  }

  span.small {
    font-size: 10px;
  }

  * {
    line-height: 20px;
    white-space: nowrap;
  }

  .v-skeleton-loader__text {
    height: 20px;
    margin: 0;
  }

  .carrier-column & {
    border-color: black;
  }

  &.highlighted {
    color: $tertiary-red;
    background-color: lightyellow;
  }
}

.compare-chart {
  overflow-x: scroll;
  overflow-y: visible;
  position: relative;
  z-index: 2;

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.carrier-column {
  width: 275px;
  min-width: 250px;
  border-right: 1px solid black;
}

.premium-buttons {
  display: block;
  height: 28px;
}

.premium-buttons .v-btn {
  margin: 0 2px;
}

.carrier-column {
  position: relative;
}

.quote-footnote {
  border-bottom: 1px solid black;
  padding: 10px;

  .v-skeleton-loader__text {
    height: 36px;
    margin: 0;
  }
}

.quote-footnote span {
  font-size: 12px;
  font-style: italic;
  text-align: center;
  display: block;
}

.quote-footnote a {
  padding: 10px;
  text-align: center;
  display: block;
  width: 100%;

  &:hover {
    opacity: 0.8;
  }
}

.quote-price-indication-estimate {
  color: $gray3;
  font-style: italic;
  display: block;

  font-size: 16px;
  line-height: 18px;
  height: 18px;
  margin-top: -18px;
}

.quote-price-indication {
  color: $gray3;
}</style>
