<template>

  <v-container>
    <v-row v-if="accountId">
        <v-col cols="12" class="pb-0">
            <v-btn v-if="accountId" outlined class="go-back mb-0" @click="goBack">
              <i class="fas fa-chevron-left"></i>&nbsp;Go Back
            </v-btn>
        </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <h1>Requirements</h1>
      </v-col>
    </v-row>

    <div v-if="!showMessage && !processing" key="loading">
        <Loading :size="64" class="text-center" /> 
    </div>

    <template v-if="processing">
      <v-alert type="success" class="text-left">
        The submitted requirements are under review.
      </v-alert>
    </template>
    
    <v-card class="pb-8 px-8 pt-4" v-if="account && underwritingItemList && underwritingItemList.length > 0">
      <ValidationObserver tag="span" v-slot="{}" ref="observer">
        <form @submit.prevent="validate()">
          <v-row class="py-0">
            <v-col cols="12" class="py-0">
              
                  <DynamicFormControl
                    v-if="underwritingItemList"
                    :control="{
                      name: 'Requirements',
                      type: 'Requirements',
                      value: value,
                      config: {
                        short: true,
                        underwritingItemList,
                        mode: 'OPTIONAL',
                        account,
                        disabled: submitting
                      }
                    }"
                    @change="updateValue"
                  />
                
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="text-right pt-0">
              <v-btn color="secondary" type="submit" large :disabled="submitting || !canSubmit">
                <Loading v-if="submitting" :size="24" class="mr-2" />
                Submit
              </v-btn>
            </v-col>
          </v-row>
        </form>
      </ValidationObserver>
    </v-card>

    <template v-else>
      <v-alert type="info" class="text-left" v-if="showMessage">
        There are no requirements for this policy at this time.
      </v-alert>
    </template>
   
  </v-container>
  
</template>

<script>
import DynamicFormControl from '@/components/form/DynamicFormControl.vue'
import { SbixService } from '@/api/SbixService';
import { AccountService } from '@/api/AccountService'
import requirementsMetadata from '@/scripts/requirements.json'

export default {
  name: 'AccountRequirements',
  components: {
    DynamicFormControl
  },
  data() {
    return {
      accountId: null,
      policyKey: null,
      account: null,
      value: null,
      underwritingItemList: null,
      showMessage: false,
      submitting: false,
      canSubmit: false,
      processing: false
    }
  },
  methods: {
    updateValue(event) {
      this.value = event.value;
      this.updateCanSubmit();
    },
    updateCanSubmit() {
      this.canSubmit = false;
      if (this.value) {
        Object.keys(this.value).forEach(k => {
          const item = this.value[k];
          if (item.Type == 'File') {
            if (item.Files && item.Files.length > 0) this.canSubmit = true;
          } else if (item.Type == 'Attestation') {
            if (item.Value) this.canSubmit = true;
          }
        }) 
      }
    },
    validate() {
      this.$refs.observer.validate()
      .then((valid) => {
        if (valid) {
          this.submitting = true;
          SbixService.requirements({
            accountId: this.account.id,
            policyKey: this.policyKey,
            body: this.value
          }).then(() => {
            this.submitting = false;
            this.underwritingItemList = null;
            this.value = null;
            this.showMessage = false;
            this.canSubmit = false;
            this.getUnderwritingItemList(true);
          })
        }
      });
    },
    async getUnderwritingItemList(redirectIfEmpty = false) {
      if (!this.policyKey) return;

      this.processing = false;
      this.account = await AccountService.get({id: this.accountId})
      const pendingTags = this.account.PendingTags
      if (pendingTags && pendingTags.includes("Processing_Requirement")) {
        this.processing = true;
        return;
      }

      SbixService.underwritingItemList({
        policyKey: this.policyKey
      }).then((data) => {

        const requiredTypeArray = [-2, -1, 0, 2, 3, 4];
        const uil = data.filter(u => 
          requirementsMetadata.find(i => i.Key == u.UnderwritingCode)
          && requiredTypeArray.indexOf(u.UnderwritingCodeStatus) > -1
        );

        this.underwritingItemList = uil;
        this.showMessage = true;


        if (!this.underwritingItemList.length && redirectIfEmpty) {
          this.goBack();
        }
      })
    },
    goBack() {
      this.$router.push({ path: '/account', query: { id: this.accountId } })
    }
  },
  async mounted() {
    
    this.accountId = this.$route.query.id;
    this.policyKey = this.$route.query.policy;

    this.getUnderwritingItemList();


    //Testing
    // this.underwritingItemList = [
    //   {
    //     "UnderwritingCode": "Photo",
    //     "UnderwritingCodeStatus": 0
    //   },
    //   {
    //     "UnderwritingCode": "Decl-W",
    //     "UnderwritingCodeStatus": 0
    //   },
    //   {
    //     "UnderwritingCode": "UW-R-WD",
    //     "UnderwritingCodeStatus": 1
    //   },
    //   {
    //     "UnderwritingCode": "UW-R-PC",
    //     "UnderwritingCodeStatus": 1
    //   },
    //   {
    //     "UnderwritingCode": "CR-R-OFAC",
    //     "UnderwritingCodeStatus": 1
    //   }
    // ];
    // this.showMessage = true;
  }
}
</script>
