<template>
  <v-card-text class="pt-4">
    <div v-if="!loaded" key="loading">
      <Loading :size="64" class="text-center" /> 
    </div>
    <div v-else key="form">
      <ValidationObserver tag="span" v-slot="{}" ref="observer">
        <form @submit.prevent="refineValidate()" v-if="open">

            <v-alert type="error" dense v-if="validationMessages" :icon="false" class="validationMessages">
              <span v-html="validationMessages"></span>
            </v-alert>

            <v-row v-if="showRatePlanEffectiveDate">
              <v-col cols="6" class="py-0">
                <DynamicFormControl 
                    :control="{
                        name: 'Ext_Algo_RatePlanEffectiveDate',
                        type: 'DateInput',
                        value: newQuote.Ext_Algo_RatePlanEffectiveDate,
                        config: {
                            label: 'Rate Plan Effective Date',
                            rules: 'required|validDate',
                            col: 12,
                            short: true,
                            disabled: calcing,
                            clearable: false,
                            default: '$TodaysDate'
                        }
                    }"
                    @change="updateValue" 
                />
              </v-col>
            </v-row>

            <v-row v-if="getOptions('CovLossAssessment_NonHO6').length > 0">
              <v-col cols="12" class="py-0">
                <h4 class="mb-2 mt-2">Property Coverages</h4>
              </v-col>
            </v-row>

            <v-row>
              <v-col md="6" cols="12" class="py-0">
                <DynamicFormControl 
                    :control="{
                        name: 'CovI',
                        type: 'TextInput',
                        value: newQuote.CovI,
                        config: {
                            label: getLabel('CovI'),
                            rules: CovIRules,
                            col: 12,
                            short: true,
                            currency: true,
                            prefix: '$',
                            disabled: calcing
                        }
                    }"
                    @change="updateValue" 
                />
              </v-col>
              <v-col md="6" cols="12" class="py-0">
                <DynamicFormControl 
                    :control="{
                        name: 'CovII',
                        type: 'Dropdown',
                        value: newQuote.CovII,
                        config: {
                            label: getLabel('CovII'),
                            rules: 'required',
                            col: 12,
                            short: true,
                            clearable: false,
                            options: getOptions('CovII'),
                            disabled: calcing || hasSingleOption('CovII')
                        }
                    }"
                    @change="updateValue" 
                />
              </v-col>
              <v-col md="6" cols="12" class="py-0">
                <DynamicFormControl 
                    :control="{
                        name: 'CovIII_LU',
                        type: 'Dropdown',
                        value: newQuote.CovIII_LU,
                        config: {
                            label: getLabel('CovIII_LU'),
                            rules: 'required',
                            col: 12,
                            short: true,
                            clearable: false,
                            options: getOptions('CovIII_LU'),
                            disabled: calcing || hasSingleOption('CovIII_LU')
                        }
                    }"
                    @change="updateValue" 
                />
              </v-col>
              <v-col md="6" cols="12" class="py-0">
                <DynamicFormControl 
                    :control="{
                        name: 'CovIV',
                        type: 'Dropdown',
                        value: newQuote.CovIV,
                        config: {
                            label: getLabel('CovIV'),
                            rules: 'required',
                            col: 12,
                            short: true,
                            clearable: false,
                            options: getOptions('CovIV'),
                            disabled: calcing || hasSingleOption('CovIV')
                        }
                    }"
                    @change="updateValue" 
                />
              </v-col>
              <v-col md="6" cols="12" class="py-0" v-if="getOptions('CovLossAssessment_NonHO6').length > 0">
                <DynamicFormControl 
                    :control="{
                        name: 'CovLossAssessment_NonHO6',
                        type: 'Dropdown',
                        value: newQuote.CovLossAssessment_NonHO6,
                        config: {
                            label: 'Loss Assessment',
                            rules: 'required',
                            col: 12,
                            short: true,
                            clearable: false,
                            options: getOptions('CovLossAssessment_NonHO6'),
                            disabled: calcing || hasSingleOption('CovLossAssessment_NonHO6')
                        }
                    }"
                    @change="updateValue" 
                />
              </v-col>
            </v-row>

            <hr>

            <v-row>
              <v-col cols="12" class="py-0">
                <h4 class="mb-2 mt-2">Liability Coverages</h4>
              </v-col>
            </v-row>

            <v-row>
              <v-col md="6" cols="12" class="py-0">
                <DynamicFormControl 
                    :control="{
                        name: 'CovEPersonalLiability',
                        type: 'Dropdown',
                        value: newQuote.CovEPersonalLiability,
                        config: {
                            label: 'Personal Liability',
                            rules: 'required',
                            col: 12,
                            short: true,
                            clearable: false,
                            options: getOptions('CovEPersonalLiability'),
                            disabled: calcing || hasSingleOption('CovEPersonalLiability')
                        }
                    }"
                    @change="updateValue" 
                />
              </v-col>
              <v-col md="6" cols="12" class="py-0">
                <DynamicFormControl 
                    :control="{
                        name: 'CovFMedPay',
                        type: 'Dropdown',
                        value: newQuote.CovFMedPay,
                        config: {
                            label: 'Medical Payments',
                            rules: 'required',
                            col: 12,
                            short: true,
                            clearable: false,
                            options: getOptions('CovFMedPay'),
                            disabled: calcing || hasSingleOption('CovFMedPay')
                        }
                    }"
                    @change="updateValue" 
                />
              </v-col>
              <v-col md="6" cols="12" class="py-0">
                <DynamicFormControl 
                    :control="{
                        name: 'CovPersonalInjury',
                        type: 'Dropdown',
                        value: newQuote.CovPersonalInjury,
                        config: {
                            label: 'Personal Injury',
                            rules: 'required',
                            col: 12,
                            short: true,
                            clearable: false,
                            options: getOptions('CovPersonalInjury'),
                            disabled: calcing || hasSingleOption('CovPersonalInjury')
                        }
                    }"
                    @change="updateValue" 
                />
              </v-col>
            </v-row>

            <hr>

            <v-row>
              <v-col cols="12" class="py-0">
                <h4 class="mb-2 mt-2">Add-On/Optional Coverages</h4>
              </v-col>
            </v-row>

            <v-row>
              <v-col md="6" cols="12" class="py-0">
                <DynamicFormControl 
                    :control="{
                        name: 'CovReplacementCostStructures',
                        type: 'Dropdown',
                        value: newQuote.CovReplacementCostStructures,
                        config: {
                            label: 'Replacement Cost (Dwelling)',
                            rules: 'required',
                            col: 12,
                            short: true,
                            clearable: false,
                            options: getOptions('CovReplacementCostStructures'),
                            disabled: calcing|| hasSingleOption('CovReplacementCostStructures')
                        }
                    }"
                    @change="updateValue" 
                />
              </v-col>
              <v-col md="6" cols="12" class="py-0">
                <DynamicFormControl 
                    :control="{
                        name: 'CovReplacementCostContents',
                        type: 'Dropdown',
                        value: newQuote.CovReplacementCostContents,
                        config: {
                            label: 'Replacement Cost (Contents)',
                            rules: 'required',
                            col: 12,
                            short: true,
                            clearable: false,
                            options: getOptions('CovReplacementCostContents'),
                            disabled: calcing || hasSingleOption('CovReplacementCostContents')
                        }
                    }"
                    @change="updateValue" 
                />
              </v-col>
              <v-col md="6" cols="12" class="py-0">
                <DynamicFormControl 
                    :control="{
                        name: 'CovInflationGuard',
                        type: 'Dropdown',
                        value: newQuote.CovInflationGuard,
                        config: {
                            label: 'Inflation Guard',
                            rules: 'required',
                            col: 12,
                            short: true,
                            clearable: false,
                            options: getOptions('CovInflationGuard'),
                            disabled: calcing || hasSingleOption('CovInflationGuard')
                        }
                    }"
                    @change="updateValue" 
                />
              </v-col>
              <v-col md="6" cols="12" class="py-0">
                <DynamicFormControl 
                    :control="{
                        name: 'CovOrdinanceLaw',
                        type: 'Dropdown',
                        value: newQuote.CovOrdinanceLaw,
                        config: {
                            label: 'Building Ordinance Or Law',
                            rules: 'required',
                            col: 12,
                            short: true,
                            clearable: false,
                            options: getOptions('CovOrdinanceLaw'),
                            disabled: calcing || hasSingleOption('CovOrdinanceLaw')
                        }
                    }"
                    @change="updateValue" 
                />
              </v-col>
              <v-col md="6" cols="12" class="py-0">
                <DynamicFormControl 
                    :control="{
                        name: 'CovWDR',
                        type: 'Dropdown',
                        value: newQuote.CovWDR,
                        config: {
                            label: 'Wind Driven Rain',
                            rules: 'required',
                            col: 12,
                            short: true,
                            clearable: false,
                            options: getOptions('CovWDR'),
                            disabled: calcing || hasSingleOption('CovWDR')
                        }
                    }"
                    @change="updateValue" 
                />
              </v-col>
              <v-col md="6" cols="12" class="py-0">
                <DynamicFormControl 
                    :control="{
                        name: 'CovTheftOnPremises',
                        type: 'Dropdown',
                        value: newQuote.CovTheftOnPremises,
                        config: {
                            label: 'Theft',
                            rules: 'required',
                            col: 12,
                            short: true,
                            clearable: false,
                            options: getOptions('CovTheftOnPremises'),
                            disabled: calcing || hasSingleOption('CovTheftOnPremises')
                        }
                    }"
                    @change="updateValue" 
                />
              </v-col>
              <v-col md="6" cols="12" class="py-0">
                <DynamicFormControl 
                    :control="{
                        name: 'CovAnimalLiability',
                        type: 'Dropdown',
                        value: newQuote.CovAnimalLiability,
                        config: {
                            label: 'Domestic Animal Liability',
                            rules: 'required',
                            col: 12,
                            short: true,
                            clearable: false,
                            options: getOptions('CovAnimalLiability'),
                            disabled: calcing || hasSingleOption('CovAnimalLiability')
                        }
                    }"
                    @change="updateValue" 
                />
              </v-col>
              <v-col md="6" cols="12" class="py-0">
                <DynamicFormControl 
                    :control="{
                        name: 'CovWaterBackUp',
                        type: 'Dropdown',
                        value: newQuote.CovWaterBackUp,
                        config: {
                            label: 'Water Backup',
                            rules: 'required',
                            col: 12,
                            short: true,
                            clearable: false,
                            options: getOptions('CovWaterBackUp'),
                            disabled: calcing || hasSingleOption('CovWaterBackUp')
                        }
                    }"
                    @change="updateValue" 
                />
              </v-col>
            </v-row>

            <hr>

            <v-row>
              <v-col cols="12" class="py-0">
                <h4 class="mb-2 mt-2">Deductibles</h4>
              </v-col>
            </v-row>

            <v-row>
              <v-col md="6" cols="12" class="py-0">
                <DynamicFormControl 
                    :control="{
                        name: 'DedAOP',
                        type: 'Dropdown',
                        value: newQuote.DedAOP,
                        config: {
                            label: 'All Perils',
                            rules: 'required',
                            col: 12,
                            short: true,
                            clearable: false,
                            options: getOptions('DedAOP'),
                            disabled: calcing || hasSingleOption('DedAOP')
                        }
                    }"
                    @change="updateValue" 
                />
              </v-col>
              <v-col md="6" cols="12" class="py-0">
                <DynamicFormControl 
                    :control="{
                        name: 'DedWind',
                        type: 'Dropdown',
                        value: newQuote.DedWind,
                        config: {
                            label: 'Wind/Hail',
                            rules: 'required',
                            col: 12,
                            short: true,
                            clearable: false,
                            options: getOptions('DedWind'),
                            disabled: calcing || hasSingleOption('DedWind')
                        }
                    }"
                    @change="updateValue" 
                />
              </v-col>
              <v-col md="6" cols="12" class="py-0">
                <DynamicFormControl 
                    :control="{
                        name: 'DedEQ',
                        type: 'Dropdown',
                        value: newQuote.DedEQ,
                        config: {
                            label: 'Earthquake',
                            rules: 'required',
                            col: 12,
                            short: true,
                            clearable: false,
                            options: getOptions('DedEQ'),
                            disabled: calcing || hasSingleOption('DedEQ')
                        }
                    }"
                    @change="updateValue" 
                />
              </v-col>
              <v-col md="6" cols="12" class="py-0">
                <DynamicFormControl 
                    :control="{
                        name: 'DedFlood',
                        type: 'Dropdown',
                        value: newQuote.DedFlood,
                        config: {
                            label: 'Flood',
                            rules: 'required',
                            col: 12,
                            short: true,
                            clearable: false,
                            options: getOptions('DedFlood'),
                            disabled: calcing || hasSingleOption('DedFlood')
                        }
                    }"
                    @change="updateValue" 
                />
              </v-col>
              <v-col md="6" cols="12" class="py-0">
                <DynamicFormControl 
                    :control="{
                        name: 'DedWaterDamage',
                        type: 'Dropdown',
                        value: newQuote.DedWaterDamage,
                        config: {
                            label: 'Water Damage',
                            rules: 'required',
                            col: 12,
                            short: true,
                            clearable: false,
                            options: getOptions('DedWaterDamage'),
                            disabled: calcing || hasSingleOption('DedWaterDamage')
                        }
                    }"
                    @change="updateValue" 
                />
              </v-col>
                <v-col md="6" cols="12" class="py-0">
                <DynamicFormControl 
                    :control="{
                        name: 'CovWaterDamage',
                        type: 'Dropdown',
                        value: newQuote.CovWaterDamage,
                        config: {
                            label: 'Water Damage Sublimit',
                            rules: 'required',
                            col: 12,
                            short: true,
                            clearable: false,
                            options: getOptions('CovWaterDamage'),
                            disabled: calcing || hasSingleOption('CovWaterDamage')
                        }
                    }"
                    @change="updateValue" 
                />
              </v-col>
              <v-col md="6" cols="12" class="py-0">
                <DynamicFormControl 
                    :control="{
                        name: 'CovMold',
                        type: 'Dropdown',
                        value: newQuote.CovMold,
                        config: {
                            label: 'Mold Sublimit',
                            rules: 'required',
                            col: 12,
                            short: true,
                            clearable: false,
                            options: getOptions('CovMold'),
                            disabled: calcing || hasSingleOption('CovMold')
                        }
                    }"
                    @change="updateValue" 
                />
              </v-col>
            </v-row>


            <div class="py-6 actions">
              <v-row no-gutters>
                <v-col sm="6" cols="12" class="py-0">

                  <v-btn color="secondary" type="submit" large
                          :disabled="calcing || !cvcLoaded || (validationMessages)" class="mr-4"
                          key="save">
                    <Loading v-if="calcing" :size="24" class="mr-2" />
                    Calculate
                  </v-btn>

                  <h2 v-if="premium" class="premium">{{premium}}</h2>

                </v-col>
                <v-col sm="6" cols="12" class="py-0 text-right">
                  <v-btn color="secondary" type="button" text large class="mr-2"
                          :disabled="calcing || !cvcLoaded" @click="close">
                    Cancel
                  </v-btn>
                  <v-btn color="secondary" type="button" large v-if="premium"
                          :disabled="!calcResponse || calcing || !cvcLoaded || (validationMessages)" @click="save">
                    Save
                  </v-btn>
                </v-col>

              </v-row>
            </div>
            
        </form>
      </ValidationObserver>
    </div>
      
  </v-card-text>
</template>

<script>
import DynamicFormControl from '@/components/form/DynamicFormControl.vue'
import Vue from 'vue'
import { SalesChannel } from '@/api/index.defs';
import { formatMoney } from '@/scripts/helper'
import { validate } from 'vee-validate';
import { SbixService } from '@/api/SbixService';
import { FormStateService } from '@/api/FormStateService';
import { FormStateEvent } from '@/api/index.defs'
import { errorHandler } from '@/plugins/axios'

export default {
  name: "LegacyRefineDialogDigital",
  components: {
    DynamicFormControl
  },
  props: {
    policyKey: String,
    salesChannel: String,
    submissionId: String
  },
  data() {
    return {
      open: false,
      loaded: false,
      newQuote: null,
      cvcResponse: [],
      calcResponse: null,
      calcing: false,
      labels: {},
      options: {},
      mins: {},
      maxes: {},
      steps: {},
      timeout: null,
      premium: null,
      validationMessages: null
    }
  },
  methods: {
    async refineValidate() {
      const valid = await this.$refs.observer.validate()
      if (valid) {
        this.calcing = true;
        this.premium = null;

        const validateTabCoverage = await this.cvc('QQ_Validate_TabCoverage');

        if (!validateTabCoverage.find(c => c.ItemType == '4_Validate')) {

          if (this.salesChannel == SalesChannel.Munich) {
            this.newQuote.AvailableContractString = "038";
          }
    
          let data = null;

          try {

            data = await this.$httpSBIX.post('seasys/Application/calc', this.newQuote);
            this.calcResponse = data?.data;

          } catch(err) {
            if (err.response && err.response.status == 424) {
              this.validationMessages = "Invalid Calculate Parameters.";
              this.scrollToValidationMessages();
            } else if (err.response && err.response.status == 422) {
              this.validationMessages = "Ineligible Option Selected.";
              this.scrollToValidationMessages();
            } else {
              errorHandler(err);
            }
          }

          if (this.calcResponse) {
            Object.assign(this.newQuote, this.calcResponse);
            this.premium = formatMoney(this.calcResponse.PremiumTotal);
            await this.cvc('QQ_CovI');
            await this.cvc('QQ_CovEPersonalLiability');
            await this.cvc('QQ_DedAOP');            
          }
          
          this.calcing = false;
          
        } else {
          this.calcing = false;
          this.scrollToFirstError();
        }

      } else {
        this.calcing = false;
        this.scrollToFirstError();
      }
    },
    close() {
      this.$emit('close');
    },
    async save() {
      if(!this.calcResponse)
      {
        console.error("Unable to Save, invalid calcResponse");
        return;
      }
      const response = await this.$httpSBIX.patch('seasys/WebApplication', this.calcResponse); //directly update state in seasys
      const application = response.data; //accept response to pickup any backend overrides
      this.clearQuote(this.salesChannel); //this wipes the local quote state
      SbixService.saveWebApplication({body:application}); //this commits the updated quote to cosmos and triggers the message back to the ui state
      this.updateFormStateWithWebApplication(this.salesChannel, application); //this pushes the refined values back into form state in-case we navigate back   
      this.close();
    },
    clearQuote(salesChannel, quote) {
        this.$store.commit('quoteForm/deleteQuote', salesChannel);
        this.$forceUpdate();
    },
    updateFormStateWithWebApplication(salesChannel, webApplication) {
      
        const coverageOption = this.getSubmissionValue('CoverageOption');

        if (salesChannel == 'Digital' || salesChannel == 'Munich' || salesChannel == 'TestV0') {
            if (['ho6', 'ho4'].indexOf(coverageOption) > -1) {
                this.updateSubmissionValue({name: 'CovC', value: webApplication.CovI});
                this.updateSubmissionValue({name: 'CovA', value: webApplication.CovII});
                this.updateSubmissionValue({name: 'LossAssessmentLimit', value: webApplication.CovIV});
                this.updateSubmissionValue({name: 'CovD', value: webApplication.CovIII_LU});
            } else if (['ho3', 'dp3'].indexOf(coverageOption) > -1) {
                this.updateSubmissionValue({name: 'CovA', value: webApplication.CovI});
                this.updateSubmissionValue({name: 'CovC', value: webApplication.CovII});
                this.updateSubmissionValue({name: 'CovB', value: webApplication.CovIV});
                this.updateSubmissionValue({name: 'CovD', value: webApplication.CovIII_LU});
            }

            if (['ho3', 'ho4', 'ho6'].indexOf(coverageOption) > -1) {
                this.updateSubmissionValue({name: 'CovE', value: webApplication.CovEPersonalLiability});
                this.updateSubmissionValue({name: 'CovF', value: webApplication.CovFMedPay});
            } else if (['dp3'].indexOf(coverageOption) > -1) {
                this.updateSubmissionValue({name: 'CovLdp3', value: webApplication.CovEPersonalLiability});
                this.updateSubmissionValue({name: 'CovMdp3', value: webApplication.CovFMedPay});
            }
        }       

    },
    saveQuoteForm() {
            const form = JSON.parse(JSON.stringify(this.$store.getters.getForm('quoteForm')));
            if (form.id && this.mode != 'Admin') {
                return FormStateService.patch({
                    eventType: FormStateEvent.Save,
                    body: form
                });
            } else {
                return Promise.resolve();
            }
        },
    updateSubmissionValue(data) {
        if (typeof(data.value) !== 'object' && data.value === this.getSubmissionValue(data.name)) return;    

        this.$store.commit('updateFormControl', {
            module: 'quoteForm',
            name: data.name,
            value: data.value
        });
    },
    getSubmissionValue(name) {
        return this.$store.getters.getControlValue('quoteForm', name);
    },
    async cvc(control) {
      this.cvcResponse = [];
      const data = await this.$httpSBIX.post('seasys/WebApplication/cvc/' + control, this.newQuote);
      this.cvcResponse = data.data;
      this.parseCVCResponse();
      this.loaded = true;

      if (control == 'QQ_Init_TabCoverage') {
        setTimeout(() => {
          this.premium = formatMoney(this.newQuote.PremiumTotal);
        }, 100)
      }

      return this.cvcResponse;
    },
    async getWebApplication(policyKey) {
      const data = await this.$httpSBIX.get('seasys/WebApplication/' + policyKey);
      return data.data;
    },
    parseCVCResponse() {
      this.cvcResponse.forEach(c => {
        if (c.ItemType == '3_Option') {
          this.options[c.CoverageName] = [];
        }
      })

      this.cvcResponse.forEach(c => {
        if (c.ItemType == '2_Value') {
          if(this.newQuote[c.CoverageName] != c.CoverageValue)
          {
            console.debug(`[CVC] 2_Value set ${c.CoverageName} from '${this.newQuote[c.CoverageName]}' to '${c.CoverageValue}'`);
            this.calcResponse = null; //unset calc value if altering 
            Vue.set(this.newQuote, c.CoverageName, c.CoverageValue);
          }
        } else if (c.ItemType == '3_Option') {
          this.options[c.CoverageName].push({text: c.ReturnText, value: c.CoverageValue})
        } else if (c.ItemType == '4_Validate') {
          if ((c.ReturnText || "").includes('NoMrktCtrl')) {
            try {
              setTimeout(() => {
                this.$refs.observer.refs[c.CoverageName].setErrors(['No Market Available']);
              }, 100)            
            } catch (err) {console.error(err)}
          } else {
            let returnText = c.ReturnText;
            if (!c.CoverageName == 'AvailableContractString' && !returnText) returnText = "No Contract Available";
            if (!this.validationMessages) this.validationMessages = returnText;
            else this.validationMessages += '<br>' + returnText;
          }
        } else if (c.ItemType == '6_Label') {
          Vue.set(this.labels, c.CoverageName, c.CoverageValue.split(':').join(''))
        } else if (c.ItemType == '7_Min') {
          Vue.set(this.mins, c.CoverageName, c.CoverageValue);
        } else if (c.ItemType == '7_Max') {
          Vue.set(this.maxes, c.CoverageName, c.CoverageValue);
        } else if (c.ItemType == '7_Step') {
          Vue.set(this.steps, c.CoverageName, c.CoverageValue);
        }
      })

      Object.keys(this.options).forEach(o => {
        if (this.options[o].length == 1 && this.newQuote[o] != this.options[o][0].value) { //if we have one option and it's different thent he current value
          
          console.debug(`[CVC] SINGLE OPTION set ${o} from '${this.newQuote[o]}' to '${this.options[o][0].value}'`);
          
          this.calcResponse = null; //unset calc value if altering 

          Vue.set(this.newQuote, o, this.options[o][0].value);
        }
      })

      if (this.validationMessages) this.scrollToValidationMessages();

    },
    getLabel(name) {
      if (!name || !this.cvcResponse) return '';
      return this.labels[name];
    },
    getOptions(name) {
      if (!name || !this.cvcResponse) return [];
      if (this.options[name]) return this.options[name];
      return [];
    },
    hasSingleOption(name) {
      if (!name || !this.cvcResponse) return [];
      if (this.options[name] && this.options[name].length == 1) return true;
      return false;
    },
    updateValue(event) {
      this.premium = null;

      if (this.loaded) this.validationMessages = null;

      const triggerCVCDropdown = [
        'CovII',
        'CovIII_LU',
        'CovIV',
        'CovEPersonalLiability',
        'DedAOP',
        'CovWaterDamage'
      ];

      const triggerCVCTextInput = [
        'CovI',
      ];

      let previous = null;

      previous = this.newQuote[event.name];
      Vue.set(this.newQuote, event.name, event.value);

      if (previous != event.value) {
        if (triggerCVCDropdown.indexOf(event.name) > -1) {
          this.cvc('QQ_' + event.name);
        }
        else if (triggerCVCTextInput.indexOf(event.name) > -1) {
          if (this.timeout) clearTimeout(this.timeout)
          this.timeout = setTimeout(() => {

            //right now only CovI uses this CVC method so the rules are hardcoded
            validate(event.value, this.CovIRules).then(result => {
              if (result.valid) {
                this.cvc('QQ_' + event.name);
              }
            });

            
          }, 300)
        }
      }
    },
    scrollToValidationMessages() {
      setTimeout(() => {
        const item = document.getElementsByClassName("validationMessages")[0];
        const viewportOffset = item.getBoundingClientRect();

        document.getElementsByClassName('v-card__text')[0].scrollTo({
            top: viewportOffset.top + window.scrollY,
            left: 0,
            behavior: 'smooth'
        });
      }, 100)
    },
    scrollToFirstError() {
      setTimeout(() => {
        const item = document.getElementsByClassName("hasError")[0];
        const viewportOffset = item.getBoundingClientRect();

        document.getElementsByClassName('v-card__text')[0].scrollTo({
            top: viewportOffset.top + window.scrollY,
            left: 0,
            behavior: 'smooth'
        });
      }, 100)
    }
  },
  computed: {
    showRatePlanEffectiveDate() {
      if (this.salesChannel != SalesChannel.Digital) return false;
      if (!this.newQuote) return false;
      if (!this.$store.getters.userHasRestrictedFeature('DigitalRefine_RatePlanEffectiveDate')) return false;
      return true;
    },
    cvcLoaded() {
      return this.cvcResponse.length > 0;
    },
    CovIRules() {
      let rules = 'required';
      if (this.mins['CovI']) rules += '|min_value:' + this.mins['CovI'];
      if (this.maxes['CovI']) rules += '|max_value:' + this.maxes['CovI'];
      if (this.steps['CovI']) rules += '|step_value:' + this.steps['CovI'];
      return rules;
    }
  },
  async mounted() {
    this.open = true;
    this.newQuote = await this.getWebApplication(this.policyKey);

    if (!this.newQuote.Market) {
      if (this.salesChannel == SalesChannel.Munich) {
        this.newQuote.Market = "S_GL";
      } else if (this.salesChannel == SalesChannel.Digital) {
        this.newQuote.Market = "S_TM";
      } else if (this.salesChannel == SalesChannel.TestV0) {
        this.newQuote.Market = "S_XT";
      }
    }

    if (!this.newQuote.AccountReference) {
      this.newQuote.AccountReference = this.submissionId;
    }
    
    this.cvc('QQ_Init_TabCoverage');
  }
}
</script>

<style scoped lang="scss">
.actions {
  position: absolute;
  bottom: 0px;
  background-color: white;
  width: 90%;
}

.sticky {
  position: sticky;
}

.close-btn {
  position: absolute;
  right: 40px;
}

h3 {
  font-weight: bold;
}

h4 {
    color: rgba(0, 0, 0, 0.87);
    font-weight: bold;
}

hr {
  margin: 20px 0;
}


.premium {
  color: rgba(0, 0, 0, 0.87);
  font-weight: bold; 
  margin-bottom: 0; 
  display: inline-block;
  vertical-align: middle; 
}

::v-deep .dfc {
  margin-top: 4px;
  margin-bottom: 4px;
}

form {
  padding-bottom: 80px;
}

@media screen and (max-width: 768px) {
  form {
    padding-bottom: 150px;
  }
}

</style>