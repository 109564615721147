
import Vue from 'vue'
import ResultsTable from '@/components/shared/ResultsTable.vue'
import { FormTemplateType } from '@/api/index.defs'

export default Vue.extend({
  name: 'FormTemplates',
  components: {
    ResultsTable
  },
  data() {
    return {
      tableHeaders: [
          {text: "Form Template", value: "TemplateName", sortable: true, filterable: true},
          {text: "", value: "Actions", sortable: false, filterable: false},
      ],
      results: [] as any,
    }
  },
  mounted() {
    for (let item in FormTemplateType) {
      this.results.push({
        TemplateName: item,
        id: item
      });
    }
  }
});
