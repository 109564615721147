<template>
  <v-dialog v-if="genericDialog"
            v-model="genericDialog"
            :width="width || `500px`"
            :persistent="persistent"
            :scrollable="scrollable">
    <v-card class="pa-2"
            :loading="loading">
      <v-card-title :style="cardTitleStyle">
        <h3 :style="{ fontWeight: titleFontWeight, fontSize: titleFontSize }">{{ title }}</h3>
        <v-spacer />

        <v-btn color="secondary" type="button" icon small @click="cancel" v-show="!persistent">
          <v-icon>fa-times</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text >
        <slot name="card-text" />
      </v-card-text>

      <v-card-actions v-if="$slots['card-actions']">
        <v-spacer />
        <slot name="card-actions" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    props: {
      title: String,
      value: Boolean,
      persistent: {
        type: Boolean,
        default: false
      },
      scrollable: {
        type: Boolean,
        default: false
      },
      loading: {
        type: Boolean,
        default: false
      },
      width: String,
      cardTitleStyle: {
        type: String,
        default: null
      },
      titleFontSize: {
        type: String,
        default: null
      },
      titleFontWeight: {
        type: String,
        default: null
      }
    },
    emits: [
      'close-dialog'
    ],
    data() {
      return {
      }
    },
    computed: {
      genericDialog: {
        get: function () {
          return this.value
        },
        set: function (val) {
          if (!val) {
              this.cancel()
          }
        }
      }
    },
    methods: {
      cancel() {
        this.$emit('close-dialog')
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "@/style.scss";

  ::v-deep .v-dialog > .v-card > .v-card__text {
    padding-top: 10px;

    *:not(.monaco_editor_container *) {
      color: #333333;
    }

    .v-btn--active > span > div > .label-primary {
      color: white !important;
    }

    .v-btn.secondary span {
      color: white!important;

      * {
        color:white!important;
      }
    }
    
  }
   
</style>
