<template>
  <div v-if="submission">
    <MonacoEditor
      height="700px"
      language="json"
      :value="submissionString"
      :options="monacoOptions"
    ></MonacoEditor>
  </div>
</template>

<script>
import MonacoEditor from 'monaco-editor-vue';

export default {
  name: 'SubmissionJson',
  components: {
    MonacoEditor
  },
  data() {
    return {
      monacoOptions: {
        colorDecorators: true,
        readOnly: true
      },
    }
  },
  props: {
    submission: Object
  },
  computed: {
    submissionString() {
      if (!this.submission) return "";
      else return JSON.stringify(this.submission, null, 2);
    }
  }
}
</script>
