import {
  IList,
  List,
  IListResult,
  ListResultDto,
  IPagedResult,
  PagedResultDto,
  Dictionary,
  IDictionary,
  IRequestOptions,
  IRequestConfig,
  getConfigs,
  axios,
  basePath
} from './index.defs';

export class MessageHubService {
  /** Generate by swagger-axios-codegen */
  // @ts-nocheck
  /* eslint-disable */

  /**
   *
   */
  static attach(
    params: {
      /**  */
      topic: string;
      /**  */
      path: string;
      /**  */
      connectionId?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/MessageHub/Attach/{topic}/{path}';
      url = url.replace('{topic}', params['topic'] + '');
      url = url.replace('{path}', params['path'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { connectionId: params['connectionId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static detach(
    params: {
      /**  */
      topic: string;
      /**  */
      path: string;
      /**  */
      connectionId?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/MessageHub/Detach/{topic}/{path}';
      url = url.replace('{topic}', params['topic'] + '');
      url = url.replace('{path}', params['path'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { connectionId: params['connectionId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}
