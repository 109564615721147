<template>
  <div>
    <v-container>
      <h1>Partition Management</h1>

      <v-card class="mb-4"
              :loading="accountLoading">
        <v-card-title>
          Sync Account
        </v-card-title>
        <v-card-text>
          <ValidationObserver tag="span" v-slot="{}" ref="observer1">
            <form @submit.prevent="syncAccount">
              <v-row>
                <v-col md="6"
                       lg="4">
                  <DynamicFormControl :control="{
                                        name: 'Account',
                                        type: 'TextInput',
                                        config: {
                                            label: 'AccountId',
                                            rules: accountIdRules,
                                            col: 12,
                                            short: true,
                                            clearable: true
                                        }
                                      }"
                                      style="margin-top: 0px;"
                                      @change="accountId = $event.value" />
                </v-col>
                <v-col md="6"
                       lg="8">
                  <FormButton label="Submit" size="x-large" @click="syncAccount" :disabled="accountLoading" />
                </v-col>
              </v-row>
            </form>
          </ValidationObserver>
        </v-card-text>
      </v-card>

       <v-card class="mb-4"
              :loading="agentLoading">
        <v-card-title>
          Sync Amlink User
        </v-card-title>
        <v-card-text>
          <ValidationObserver tag="span" v-slot="{}" ref="observer2">
            <form @submit.prevent="syncAgent">
              <v-row>
                <v-col md="6"
                       lg="4">
                  <DynamicFormControl :control="{
                                        name: 'Agent',
                                        type: 'TextInput',
                                        config: {
                                            label: 'Amlink Human ID',
                                            rules: idRules,
                                            col: 12,
                                            short: true,
                                            clearable: true,
                                            type: 'number',
                                            min: 1
                                        }
                                      }"
                                      style="margin-top: 0px;"
                                      @change="humanId = $event.value" />
                </v-col>
                <v-col md="6"
                       lg="8">
                  <FormButton label="Submit" size="x-large" @click="syncAgent" :disabled="agentLoading" />
                </v-col>
              </v-row>
            </form>
          </ValidationObserver>
        </v-card-text>
      </v-card>

    </v-container>
  </div>
</template>

<script>
  import DynamicFormControl from '@/components/form/DynamicFormControl.vue'
  import FormButton from '@/components/form/FormButton.vue'
  import { AccountService } from '@/api/AccountService'
  import { AgentService } from '@/api/AgentService'

  export default {
    name: 'PartitionManagement',
    components: {
      DynamicFormControl,
      FormButton
    },
    data() {
      return {
        accountId: "",
        idRules: 'required|min_value:1',
        accountIdRules: 'required',
        accountLoading: false,
        agentLoading: false
      }
    },
    methods: {
      syncAccount() {
        this.$refs.observer1.validate()
          .then((valid) => {
            if (valid) {
              this.accountLoading = true
              AccountService.sync({ accountId: this.accountId })
                .then((response) => {
                  if (response) {
                    this.$store.dispatch('toastSuccess', { Message: 'Account Sync Request Complete' })
                  } else {
                    this.$store.dispatch('toastError', { Message: 'Error Syncing Account' })
                  }
                })
                .finally(() => {
                  this.accountLoading = false
                })
            }
          });
      },
      syncAgent() {
        this.$refs.observer2.validate()
          .then((valid) => {
            if (valid) {
              this.agentLoading = true
              AgentService.sync({ humanId: this.humanId })
                .then((response) => {
                  if (response) {
                    this.$store.dispatch('toastSuccess', { Message: 'Agent Sync Request Complete' })
                  } else {
                    this.$store.dispatch('toastError', { Message: 'Error Syncing Agent' })
                  }
                })
                .finally(() => {
                  this.agentLoading = false
                })
            }
          });
      }
    }
  }
</script>
