const configurationBreakout = [
  {
    salesChannel: 'VavePersonalLines',
    coverageOptions: ['HO3', 'HO5', 'DP3'],
    controlName: 'CovA',
    config: {
      rules: 'required|min_value:100000|max_value:1650000',
      short: true,
      col: 12,
      prefix: '$',
      default: 0,
      currency: true,
      min: 0,
      allowNegative: false,
      defaultValue: 0,
      hideDataServiceValue: true,
      ignoreDataServiceValue: true
    }
  },
  {
    salesChannel: 'VavePersonalLines',
    coverageOptions: ['HO6'],
    controlName: 'CovA',
    config: {
      rules: 'required|min_value:20000|max_value:1650000',
      short: true,
      col: 12,
      prefix: '$',
      default: 0,
      currency: true,
      min: 0,
      allowNegative: false,
      defaultValue: 0,
      hideDataServiceValue: true,
      ignoreDataServiceValue: true
    }
  },
  {
    salesChannel: 'VavePersonalLines',
    coverageOptions: ['HO3', 'HO5', 'DP3'],
    controlName: 'CovB',
    config: {
      label: 'Other Structures',
      rules: 'required|min_value:0|max_value:99999999|percentBetweenRangeForValue:0,20,@CovA',
      short: true,
      col: 12,
      prefix: '$',
      currency: true,
      min: 0,
      allowNegative: false,
      defaultValue: 0
    }
  },
  {
    salesChannel: 'VavePersonalLines',
    coverageOptions: ['HO3', 'HO5', 'HO6', 'DP3'],
    controlName: 'CovC',
    config: {
      label: 'Pesonal Property',
      rules: 'required|min_value:0|max_value:99999999|percentBetweenRangeForValue:0,80,@CovA',
      short: true,
      col: 12,
      prefix: '$',
      currency: true,
      min: 0,
      allowNegative: false,
      defaultValue: 0
    }
  },
  {
    salesChannel: 'VavePersonalLines',
    coverageOptions: ['HO3', 'HO5', 'HO6', 'DP3'],
    controlName: 'CovD',
    config: {
      label: 'Loss Of Use/Fair Rental',
      rules: 'required|min_value:0|max_value:99999999|percentBetweenRangeForValue:0,30,@CovA',
      short: true,
      col: 12,
      prefix: '$',
      currency: true,
      min: 0,
      allowNegative: false,
      defaultValue: 0
    }
  },
  {
    salesChannel: 'VavePersonalLines',
    coverageOptions: ['DP3'],
    controlName: 'CovEdp3',
    config: {
      label: 'Coverage E: Additional Living Expense',
      rules: 'required|min_value:0',
      short: true,
      col: 8,
      prefix: '$',
      default: 0,
      currency: true,
      min: 0,
      allowNegative: false,
      defaultValue: 0,
      hideDataServiceValue: true,
      ignoreDataServiceValue: true,
    }
  },
  {
    salesChannel: 'VavePersonalLines',
    coverageOptions: ['HO3', 'HO5', 'HO6', 'DP3'],
    controlName: 'LossAssessment',
    config: {
      label: 'Loss Assessment',
      rules: 'required|min_value:0|max_value:50000',
      short: true,
      col: 12,
      prefix: '$',
      currency: true
    }
  },
  {
    salesChannel: 'VavePersonalLines',
    coverageOptions: ['HO3', 'HO5', 'HO6', 'DP3'],
    controlName: 'LossAssessmentEQ',
    config: {
      label: 'Loss Assessment EQ',
      rules: 'required|min_value:0|max_value:20000',
      short: true,
      col: 12,
      prefix: '$',
      currency: true
    }
  },
  {
    salesChannel: 'VavePersonalLines',
    coverageOptions: ['HO3', 'HO5', 'HO6', 'DP3'],
    controlName: 'Liability',
    config: {
      label: 'Personal Liability',
      rules: 'required',
      short: true,
      col: 12,
      options: [
        {
          value: 0,
          text: '$0'
        },
        {
          value: 100000,
          text: '$100,000'
        },
        {
          value: 300000,
          text: '$300,000'
        },
        {
          value: 500000,
          text: '$500,000'
        }
      ]
    }
  },
  {
    salesChannel: 'VavePersonalLines',
    coverageOptions: ['HO3', 'HO5', 'HO6', 'DP3'],
    controlName: 'MedPay',
    config: {
      label: 'Medical Payments',
      rules: 'required',
      short: true,
      col: 12,
      options: [
        {
          value: 0,
          text: '$0'
        },
        {
          value: 1000,
          text: '$1,000'
        },
        {
          value: 2500,
          text: '$2,500'
        },
        {
          value: 5000,
          text: '$5,000'
        }
      ]
    }
  },
  {
    salesChannel: 'VavePersonalLines',
    coverageOptions: ['HO3', 'HO5', 'HO6', 'DP3'],
    controlName: 'PersonalInjury',
    config: {
      label: 'Personal Injury',
      rules: 'required',
      short: true,
      col: 12,
      options: [
        {
          value: 'Included',
          text: 'Included'
        },
        {
          value: 'Excluded',
          text: 'Excluded'
        }
      ]
    }
  },
  {
    salesChannel: 'VavePersonalLines',
    coverageOptions: ['HO3', 'HO5'],
    controlName: 'ReplacementCostStructures',
    config: {
      label: 'Extended Replacement Cost - Dwelling',
      rules: 'required',
      short: true,
      col: 12,
      options: [
        {
          value: 0,
          text: 'No Extended Coverage'
        },
        {
          value: 25,
          text: '25% Extended Coverage A'
        },
        {
          value: 50,
          text: '50% Extended Coverage A'
        }
      ]
    }
  },
  {
    salesChannel: 'VavePersonalLines',
    coverageOptions: ['HO3', 'HO5', 'HO6'],
    controlName: 'ReplacementCostContents',
    config: {
      label: 'Replacement Cost (Contents)',
      rules: 'required',
      short: true,
      col: 12,
      options: [
        {
          value: true,
          text: 'Included'
        },
        {
          value: false,
          text: 'Excluded'
        }
      ]
    }
  },
  {
    salesChannel: 'VavePersonalLines',
    coverageOptions: ['HO3', 'HO5', 'HO6', 'DP3'],
    controlName: 'InflationGuard',
    config: {
      label: 'Inflation Guard',
      rules: 'required|min_value:0|max_value:20|invalidValues:1,2',
      short: true,
      col: 12,
      compact: true,
      exclusionValues: [1, 2]
    }
  },
  {
    salesChannel: 'VavePersonalLines',
    coverageOptions: ['HO3', 'HO5', 'HO6', 'DP3'],
    controlName: 'BuildingOrdinanceOrLaw',
    config: {
      label: 'Building Ordinance & Law',
      rules: 'required',
      short: true,
      col: 12,
      options: [
        {
          value: 10,
          text: '10%'
        },
        {
          value: 15,
          text: '15%'
        },
        {
          value: 25,
          text: '25%'
        }
      ]
    }
  },
  {
    salesChannel: 'VavePersonalLines',
    coverageOptions: ['HO3', 'HO5', 'HO6'],
    controlName: 'Theft',
    config: {
      label: 'Theft',
      rules: 'required',
      short: true,
      col: 12,
      prefix: '$',
      currency: true
    }
  },
  {
    salesChannel: 'VavePersonalLines',
    coverageOptions: ['HO3', 'HO5', 'HO6'],
    controlName: 'TheftExclusion',
    config: {
      label: 'Theft',
      rules: 'required',
      short: true,
      col: 12,
      disabled: true,
    }
  },
  {
    salesChannel: 'VavePersonalLines',
    coverageOptions: ['HO3', 'HO5', 'HO6'],
    controlName: 'AdditionalTheft',
    config: {
      label: 'Additional Theft',
      rules: 'required',
      short: true,
      col: 12,
      options: [
        {
          value: 'Included',
          text: 'Included'
        },
        {
          value: 'Excluded',
          text: 'Excluded'
        }
      ]
    }
  },
  {
    salesChannel: 'VavePersonalLines',
    coverageOptions: ['DP3'],
    controlName: 'LimitedTheft',
    config: {
      label: 'Limited Theft',
      rules: 'required',
      short: true,
      col: 12,
      options: [
        {
          value: 'Included',
          text: 'Included'
        },
        {
          value: 'Excluded',
          text: 'Excluded'
        }
      ]
    }
  },
  {
    salesChannel: 'VavePersonalLines',
    coverageOptions: ['DP3'],
    controlName: 'BroadTheft',
    config: {
      label: 'Broad Theft',
      rules: 'required',
      short: true,
      col: 12,
      options: [
        {
          value: 'Included',
          text: 'Included'
        },
        {
          value: 'Excluded',
          text: 'Excluded'
        }
      ]
    }
  },
  {
    salesChannel: 'VavePersonalLines',
    coverageOptions: ['HO3', 'HO5', 'DP3'],
    controlName: 'WaterBackup',
    config: {
      label: 'Water Backup',
      rules: 'required|min_value:0|max_value:25000',
      short: true,
      col: 12,
      prefix: '$',
      currency: true,
    }
  },
  {
    salesChannel: 'VavePersonalLines',
    coverageOptions: ['HO3', 'HO5', 'HO6', 'DP3'],
    controlName: 'GolfCartPhysicalDamage',
    config: {
      label: 'Golf Cart Physical Damage',
      rules: 'required',
      short: true,
      col: 12,
      options: [
        {
          value: 'Included',
          text: 'Included'
        },
        {
          value: 'Excluded',
          text: 'Excluded'
        }
      ]
    }
  },
  {
    salesChannel: 'VavePersonalLines',
    coverageOptions: ['HO6'],
    controlName: 'HO6ExtensionCoverage',
    config: {
      label: 'Unit-Owner\'s Special Coverage',
      rules: 'required',
      short: true,
      col: 12,
      options: [
        {
          value: 'No Coverage',
          text: 'No Coverage'
        },
        {
          value: 'Extended Coverage A',
          text: 'Extended Coverage A'
        },
        {
          value: 'Extended Coverage C',
          text: 'Extended Coverage C'
        },
        {
          value: 'Extended Coverage A & C',
          text: 'Extended Coverage A & C'
        },
      ]
    }
  },
  {
    salesChannel: 'VavePersonalLines',
    coverageOptions: ['HO3', 'HO5', 'HO6'],
    controlName: 'GreenUpgrades',
    config: {
      label: 'Green Upgrades',
      rules: 'required',
      short: true,
      col: 12,
      options: [
        {
          value: 'Included',
          text: 'Included'
        },
        {
          value: 'Excluded',
          text: 'Excluded'
        }
      ]
    }
  },
  {
    salesChannel: 'VavePersonalLines',
    coverageOptions: ['HO3', 'HO5', 'HO6'],
    controlName: 'IdentityFraudExp',
    config: {
      label: 'Identity Fraud',
      rules: 'required',
      short: true,
      col: 12,
      options: [
        {
          value: 'Included',
          text: 'Included'
        },
        {
          value: 'Excluded',
          text: 'Excluded'
        }
      ]
    }
  },
  {
    salesChannel: 'VavePersonalLines',
    coverageOptions: ['HO3', 'HO5', 'HO6'],
    controlName: 'RefrigeratedGoods',
    config: {
      label: 'Refrigerated Goods',
      rules: 'required',
      short: true,
      col: 12,
      options: [
        {
          value: 'Included',
          text: 'Included'
        },
        {
          value: 'Excluded',
          text: 'Excluded'
        }
      ]
    }
  },
  {
    salesChannel: 'VavePersonalLines',
    coverageOptions: ['HO3', 'HO6'],
    controlName: 'SpecialComputerCover',
    config: {
      label: 'Special Computer Coverage',
      rules: 'required',
      short: true,
      col: 12,
      options: [
        {
          value: 'Included',
          text: 'Included'
        },
        {
          value: 'Excluded',
          text: 'Excluded'
        }
      ]
    }
  },
  {
    salesChannel: 'VavePersonalLines',
    coverageOptions: ['HO3', 'HO5', 'HO6', 'DP3'],
    controlName: 'MechanicalBreakdownCover',
    config: {
      label: 'Mechanical Breakdown',
      rules: 'required',
      short: true,
      col: 12,
      options: [
        {
          value: 'Included',
          text: 'Included'
        },
        {
          value: 'Excluded',
          text: 'Excluded'
        }
      ]
    }
  },
  {
    salesChannel: 'VavePersonalLines',
    coverageOptions: ['HO3', 'HO5', 'HO6', 'DP3'],
    controlName: 'AllPerilDeductible',
    config: {
      label: 'All Perils',
      rules: 'required|min_value:1000|max_value:50000',
      short: true,
      col: 12,
      prefix: '$',
      currency: true
    }
  },
  {
    salesChannel: 'VavePersonalLines',
    coverageOptions: ['HO3', 'HO5', 'HO6', 'DP3'],
    controlName: 'WindHailPerilIncrementer',
    config: {
      label: 'Wind/Hail Percentage',
      rules: 'required|min_value:0|max_value:20',
      short: true,
      col: 12,
      compact: true
    }
  },
  {
    salesChannel: 'VavePersonalLines',
    coverageOptions: ['HO3', 'HO5', 'HO6', 'DP3'],
    controlName: 'WindHailFlatAmount',
    config: {
      label: 'Wind/Hail',
      rules: 'required|min_value:0|max_value:@TenPercentTIV,true',
      short: true,
      col: 12,
      prefix: '$',
      currency: true,
      min: 0,
      allowNegative: false,
      defaultValue: 0,
      disabled: true
    }
  },
  {
    salesChannel: 'VavePersonalLines',
    coverageOptions: ['HO3', 'HO5', 'HO6', 'DP3'],
    controlName: 'FloodPerilIncrementer',
    config: {
      label: 'Flood Percentage',
      rules: 'required',
      short: true,
      col: 12,
      compact: true
    }
  },
  {
    salesChannel: 'VavePersonalLines',
    coverageOptions: ['HO3', 'HO5', 'HO6', 'DP3'],
    controlName: 'FloodFlatAmount',
    config: {
      label: 'Flood',
      rules: 'required',
      short: true,
      col: 12,
      prefix: '$',
      currency: true,
      min: 0,
      allowNegative: false,
      defaultValue: 0,
      disabled: true
    }
  },
  {
    salesChannel: 'VavePersonalLines',
    coverageOptions: ['HO3', 'HO5', 'HO6', 'DP3'],
    controlName: 'EarthquakePerilIncrementer',
    config: {
      label: 'Earthquake Percentage',
      short: true,
      col: 12,
      compact: true,
      exclusionValues: [1, 2, 3, 4]
    }
  },
  {
    salesChannel: 'VavePersonalLines',
    coverageOptions: ['HO3', 'HO5', 'HO6', 'DP3'],
    controlName: 'EarthquakeFlatAmount',
    config: {
      label: 'Earthquake',
      rules: 'required|max_value:@CovA,true',
      short: true,
      col: 12,
      prefix: '$',
      currency: true,
      min: 0,
      allowNegative: false,
      defaultValue: 0,
      disabled: true
    }
  },
  {
    salesChannel: 'VavePersonalLines',
    coverageOptions: ['HO3', 'HO5', 'HO6', 'DP3'],
    controlName: 'WaterDamagePeril',
    config: {
      label: 'Water Damage',
      rules: 'required|min_value:0|max_value:@CovA',
      short: true,
      col: 12,
      prefix: '$',
      currency: true
    }
  },
  {
    salesChannel: 'VavePersonalLines',
    coverageOptions: ['HO3', 'HO5', 'HO6', 'DP3'],
    controlName: 'WaterDamageSublimit',
    config: {
      label: 'Water Damage Sublimit',
      rules: 'required',
      short: true,
      col: 12,
      prefix: '$',
      currency: true
    }
  },
  {
    salesChannel: 'VavePersonalLines',
    coverageOptions: ['HO3', 'HO5', 'HO6', 'DP3'],
    controlName: 'Mold',
    config: {
      label: 'Mold Sublimit',
      rules: 'required|min_value:0|max_value:25000',
      short: true,
      col: 12,
      prefix: '$',
      currency: true
    }
  },
  {
    salesChannel: 'VavePersonalLines',
    coverageOptions: ['HO3', 'HO5', 'HO6', 'DP3'],
    controlName: 'ServiceFee',
    config: {
      label: 'Amwins Service Fee',
      short: true,
      col: 12,
      prefix: '$',
      currency: true
    }
  },
  {
    salesChannel: 'VavePersonalLines',
    coverageOptions: ['HO3', 'HO5', 'HO6', 'DP3'],
    controlName: 'InspectionFee',
    config: {
      label: 'Amwins Inspection Fee',
      rules: 'required',
      short: true,
      col: 12,
      disabled: true,
      prefix: '$',
      currency: true
    }
  },
  {
    salesChannel: 'VavePersonalLines',
    coverageOptions: ["HO3", "HO5", "HO6", "DP3"],
    controlName: "WaterDamagePeril",
    config: {
      hidden: true,
      short: true,
      col: 12,
      prefix: '$',
      currency: true
    }
  },
  {
    salesChannel: 'VavePersonalLines',
    coverageOptions: ["HO3", "HO5", "HO6", "DP3"],
    controlName: "EarthquakePerilDeductible",
    config: {
      hidden: true,
      short: true,
      col: 12,
      options: [{}]
    }
  }
]

export function getControlConfig(salesChannel, coverageOption, controlName) {
  if (!salesChannel || !coverageOption || !controlName) return null

  return configurationBreakout.find(x => x.salesChannel === salesChannel && x.coverageOptions.includes(coverageOption.toUpperCase()) && x.controlName === controlName)?.config ?? null
}

export function showControlByCoverageOption(salesChannel, coverageOption, controlName) {
  if (!salesChannel || !coverageOption || !controlName) return false

  return configurationBreakout.find(x => x.salesChannel === salesChannel && x.controlName === controlName).coverageOptions.includes(coverageOption)
}

export function showControlBySalesChannel(salesChannel, coverageOption, controlName) {
  if (!salesChannel || !coverageOption || !controlName) return false

  return configurationBreakout.find(x => x.coverageOptions.includes(coverageOption.toUpperCase()) && x.controlName === controlName).salesChannel === salesChannel
}