import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr'
import store from '@/vuex/store';
import Environment from '@/scripts/environment';
import { downloadTempFile } from '@/scripts/helper';
import { MessageHubService } from '@/api/MessageHubService';
import { FeatureFlagsService } from '@/api/FeatureFlagsService'
import { getTokenAsync } from '@/plugins/auth';
import {Mutex} from 'async-mutex';

const DEBUG = true
const VERBOSE = false
let connectionId = null
let disconnectionCount = 0
const attachedTopics = [] //store all attached topics here
const mutex = new Mutex()

//list of data services not yet refactored to use distrbuted dataservice design
const _legacyDataServiceTypeLowered = ["tigercq_rms", "tigercq_air", "corelogic_riskmeter"];

const messageHubMessageReceived = (data) => {

  if(!(data?.MessageHubMessageId)) return false;

  //check if message already received to prevent duplicate actions
  const received = store.getters.getMessageHubMessageAlreadyReceived(data.MessageHubMessageId);
  if (received) return true;

  //message not yet received..
  store.commit('addMessageHubMessageId', data.MessageHubMessageId);
  return false;
}

async function waitForCondition(callback, action) {
  while(!callback())
  {
    if(DEBUG) console.log(`[MessageHub] waiting on ${action}...`)
    await new Promise(r => setTimeout(r, 1000));
  }
}


export default {
  async install (vue, options) {
    if(DEBUG) console.log("[MessageHub] install...")
    if (connectionId) {
      console.error("[MessageHub] connection already established...")
    } 

    this.start();    
  },
    
  isDisabled() {
    return sessionStorage.getItem('disableSignalR');
  },

  createConnection() {
    const connection = new HubConnectionBuilder()
    .withUrl(Environment.API_HOST + '/hub', { accessTokenFactory: async () => {
      if(VERBOSE) console.log("[MessageHub](accessTokenFactory) waiting...");
      const token = await getTokenAsync()
      if(VERBOSE) console.log("[MessageHub](accessTokenFactory) token:", token);
      return token;
    }
    })
    .configureLogging(LogLevel.Information)
    .build();

    connection.onclose(async () => {
      connectionId = null

      disconnectionCount++;
      if (disconnectionCount == 5) {
        store.dispatch('toastError', {
          Message: "Degraded network connection detected."
        });
      }
      
      setTimeout(() => this.start(), disconnectionCount > 5 ? 30000 : 5000)
    })

    connection.on('Toast', (data) => {
      if(DEBUG) console.log("[MessageHub](Toast)", data);
      if (messageHubMessageReceived(data)) return;

      if (data.message) {
        data.Message = data.message;
        data.Link = data.link;
      }
      store.dispatch('toast', data);
    })

    connection.on('Quote', (data) => {
      if(DEBUG) console.log("[MessageHub](Quote)", data);
      if (messageHubMessageReceived(data)) return;

      store.commit('quoteForm/addQuote', data);

      let floodRefinementFeatureFlagEnabled = false;
      FeatureFlagsService.isEnabled({ featureName: "FloodRefinement" })
        .then((response) => {
          floodRefinementFeatureFlagEnabled = response;
          if (floodRefinementFeatureFlagEnabled && data.IsLatestQuote) {
            store.commit('additionalForm/setSelectedQuote', data);
          }
        }); 


    })

    connection.on('Account', (data) => {
      if (DEBUG) console.log("[MessageHub](Account)", data);
      if (messageHubMessageReceived(data)) return;

      store.commit('account/updateAccount', data);
    })

    connection.on('Endorsement', (data) => {
      if (DEBUG) console.log("[MessageHub](Endorsement)", data);
      if (messageHubMessageReceived(data)) return;
      store.commit('account/updateEndorsement', data);
    })

    connection.on('LockContest', (data) => {
      if(DEBUG) console.log("[MessageHub](LockContest)", data);
      if (messageHubMessageReceived(data)) return;

      if (data.contested) {
        if (store.getters.getRejectedLockRequest) {
          store.commit('setLockReleaseRequestRejected', true);
        } else {
          store.dispatch('checkLockReleaseRequest', data);
        }
      } else {
        store.commit('setRejectedLockRequest', null);
        store.commit('setActiveLock', data);
      }
    })

    connection.on('DataService', (data) => {
      try
      {
        if (messageHubMessageReceived(data))
        {
          console.debug("[MessageHub](DataService) Already Received", data);
          return;
        }

        if (!data?.Data)
        {
          console.error("[MessageHub](DataService) Invalid Message", data);
          return;
        }
        
        //inforce state version matching
        if(
          store.getters.formStateEventIsActive(data.FormStateEventContextId, data.FormStateEventType, data.FormStateEventId) //only allow matching state versions to apply
          || _legacyDataServiceTypeLowered.indexOf(data.DataServiceType.toLowerCase()) >= 0 //unless we have an older implementation
          )
        {
          console.debug("[MessageHub](DataService) Commit:", data);
          store.commit("updateFormControlDataServiceValues", data);
        } else {
          console.debug("[MessageHub](DataService) Ignored:", data);
        }
      }
      catch(ex)
      {
        console.error("[MessageHub](DataService) Error", ex, data);
      }
    })

    connection.on('FileDownload', (data) => {
      if(DEBUG) console.log("[MessageHub](FileDownload)", data);
      if (messageHubMessageReceived(data)) return;


      downloadTempFile(data);
    })

    connection.on('Health', (data) => {
      if (DEBUG) console.log("[MessageHub](Health)", data);
      if (messageHubMessageReceived(data)) return;
      if (store.getters['user/getUserRole'] === 'admin' || store.getters['user/getUserRole'] === 'support')
      store.dispatch('health/fetchAppHealthChecks', data);

    })

    return connection;
  },

  async start()
  {
    if(DEBUG) console.log("[MessageHub] start...")
    
    if(this.isDisabled())
    {
      console.warn("[MessageHub] SignalR Disabled")
      return
    }
    
    const connection = this.createConnection();

    try {
      await connection.start().then(() => {
        connectionId = connection.connectionId
        if(DEBUG) console.log('[MessageHub] Connected To SignalR - connectionId:', connectionId)
        this.reAttachAll()
      })      
    } catch (err) {
      console.error('[MessageHub] Error', err)
      connectionId = null
      setTimeout(() => this.start(), 5000)
    }
  },

  //attach to a topic and listen for messages
  async attach(topic, path)
  {
    await waitForCondition(() => connectionId != null, "SignalR Connection")
    if(DEBUG) console.log(`[MessageHub] Attach: ${topic}/${path}`)
    const data = {topic:topic,path:path,connectionId:connectionId};
    MessageHubService.attach(data)
    .then(r => mutex.runExclusive(() => attachedTopics.push(data)));
  },

  //detach to a topic and listen for messages
  async detach(topic, path)
  {
    await waitForCondition(() => connectionId != null, "SignalR Connection")
    if(DEBUG) console.log(`[MessageHub] Detach: ${topic}/${path}`)
    const data = {topic:topic,path:path,connectionId:connectionId};
    MessageHubService.detach(data);
  },

  //drop all topic attachments - call me when we nav to a new UI context
  async detachAll()
  {
    await waitForCondition(() => connectionId != null, "SignalR Connection")
    mutex.runExclusive(() => {
      const detachQueue = [...attachedTopics]
      attachedTopics.length = 0;
      return detachQueue
    }).then((detachQueue) => 
      detachQueue.map( data =>  {
        if(DEBUG) console.log(`[MessageHub] Detach: ${data.topic}/${data.path}`)
        MessageHubService.detach(data)
      })
     )
  },

  //if we startup with active topics, reattach
  async reAttachAll()
  {
    mutex.runExclusive(() => {
      const reAttachQueue = [...attachedTopics]
      attachedTopics.length = 0;
      return reAttachQueue
    }).then((reAttachQueue) => 
      reAttachQueue.map( data =>  {
        this.attach(data.topic, data.path)
      })
     )
  }

}
