<template>
    <div>
        <span v-for="(error, index) in errors" :key="index" v-html="error" :style="styles"></span>
    </div>
</template>

<script>

export default {
    name: 'ErrorMessages',
    props: {
        errors: Array,
        alignment:  {
            type: String,
            default: 'right'
        }
    },
    computed: {
        styles() {
            return {
                '--text-align': this.alignment
            };
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/style.scss';
    span {
        color: $tertiary-red!important;
        text-align: var(--text-align);
        display: block;
        font-size: 12px;
        line-height: 14px;
        margin-top: 2px;
    }
</style>
