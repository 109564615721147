import { render, staticRenderFns } from "./LegacyRefineDialogDigital.vue?vue&type=template&id=03f5f882&scoped=true&"
import script from "./LegacyRefineDialogDigital.vue?vue&type=script&lang=js&"
export * from "./LegacyRefineDialogDigital.vue?vue&type=script&lang=js&"
import style0 from "./LegacyRefineDialogDigital.vue?vue&type=style&index=0&id=03f5f882&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "03f5f882",
  null
  
)

export default component.exports