<template>
  <div>
    <v-container>
      <h1>Renewal Processing</h1>

      <v-card class="mb-4">
        <v-card-title>
          Run Renewals
        </v-card-title>
        <v-card-text>
          <ValidationObserver tag="span" v-slot="{}" ref="observer1">
            <v-form ref="form">
              <v-row>
                <v-col class="py-0">
                  <DynamicFormControl :control="{
                                        name: 'PolicyKey',
                                        type: 'TextInput',
                                        config: {
                                            label: 'Policy Key',
                                            rules: policyKeyRules,
                                            col: 12,
                                            short: true,
                                            clearable: true,
                                            disabled: disablePolicyKey,
                                            maxlength: null // unlimited to support many, many keys in a CSV
                                        }
                                      }"
                                      class="pt-3"
                                      @change="policyKey = $event.value" />
                </v-col>
                <v-col class="py-0">
                  <DynamicFormControl :control="{
                                        name: 'BeginDate',
                                        type: 'DateInput',
                                        value: beginDate,
                                        config: {
                                            label: 'Begin Date',
                                            col: 12,
                                            rules: beginDateRules,
                                            clearable: true,
                                            allowedDates: beginDateAllowedDates,
                                            disabled: disableBeginEndDates
                                        }
                                      }"
                                      @change="beginDate = $event.value" />
                </v-col>
                <v-col class="py-0">
                  <DynamicFormControl :control="{
                                        name: 'EndDate',
                                        type: 'DateInput',
                                        value: endDate,
                                        config: {
                                            label: 'End Date',
                                            col: 12,
                                            rules: endDateRules,
                                            clearable: true,
                                            allowedDates: endDateAllowedDates,
                                            disabled: disableBeginEndDates
                                        }
                                      }"
                                      @change="endDate = $event.value" />
                </v-col>
                <v-col class="py-0">
                  <DynamicFormControl :control="{
                                        name: 'RatePlanEffectiveDate',
                                        type: 'DateInput',
                                        value: ratePlanEffectiveDate,
                                        config: {
                                            label: 'Rate Plan Effective Date',
                                            col: 12,
                                            clearable: true
                                        }
                                      }"
                                      @change="ratePlanEffectiveDate = $event.value" />
                </v-col>
                </v-row>
                <v-row>
                                <v-col class="py-0">
                  <DynamicFormControl :control="{
                                        name: 'IncludeContractCodes',
                                        type: 'TextInput',
                                        value: includeContractCodeList,
                                        config: {
                                            label: 'Include these Contract Codes (in comma delimited list) default: All Contracts',
                                            col: 12,
                                            short: true,
                                            clearable: true
                                        }
                                      }"
                                      class="pt-3"
                                      @change="includeContractCodeList = $event.value" />
                </v-col>
                <v-col class="py-0">
                  <DynamicFormControl :control="{
                                        name: 'ExcludeContractCodes',
                                        type: 'TextInput',
                                        value: excludeContractCodeList,
                                        config: {
                                            label: 'Exclude these Contract Codes (in comma delimited list) default: All Contracts',
                                            col: 12,
                                            short: true,
                                            clearable: true
                                        }
                                      }"
                                      class="pt-3"
                                      @change="excludeContractCodeList = $event.value" />
                </v-col>

                                <v-col class="py-0">
                  <DynamicFormControl :control="{
                                        type: 'Checkbox',
                                        name: 'ForceOverwrite',
                                        value: forceOverwrite,
                                        config: {
                                          label: 'ForceOverwrite?',
                                          short: true
                                        }
                                      }"
                                      class="pt-5"
                                      @change="forceOverwrite = $event.value" />
                </v-col>
                <v-col class="py-0">
                  <DynamicFormControl :control="{
                                        type: 'Checkbox',
                                        name: 'Recalc',
                                        value: recalc,
                                        config: {
                                          label: 'Recalc?',
                                          short: true
                                        }
                                      }"
                                      class="pt-5"
                                      @change="recalc = $event.value" />
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pt-0">
                  <FormButton label="Begin" size="x-large" @click="runRenewals" :disabled="loading"></FormButton>
                </v-col>
              </v-row>
            </v-form>
          </ValidationObserver>
        </v-card-text>
      </v-card>

      <v-row>
        <v-col cols="3">
          <v-card class="mb-4">
            <v-card-text class="text-center">
              <h2 class="mb-2">Scheduled Renewal Offer Jobs:</h2>
              <h1 class="mb-0">
                {{ scheduledJobsCount }}
              </h1>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <p>
        <FormButton label="Refresh Data" size="x-large" @click="getData" :disabled="loading"></FormButton>
      </p>

      <v-card class="mb-4">
        <v-card-title>
          Failed Renewal Offer Jobs ({{ failedJobs.length }})
        </v-card-title>
        <v-card-text>
          <ResultsTable :tableHeaders="failedJobsHeaders"
                        :results="failedJobs"
                        :loading="loading">

            <template v-slot:FailedAt="{item}">
              <span>
                {{ formatDateTimeForDisplay(item.FailedAt) }}
              </span>
            </template>

            <template v-slot:Name="{item}">
              <span>
                {{ item.Name }}
              </span>
            </template>

            <template v-slot:Parameters="{item}">
              <template v-for="(param, index) in item.Parameters">
                <div :key="index">
                  <strong>{{ methodParameters[index] }}:</strong>
                  <span v-if="methodParameters[index] == 'Rate Plan Effective Date'">
                    {{ formatDateForDisplay(param) }}
                  </span>
                  <span v-else>
                    {{ param }}
                  </span>
                </div>
              </template>
            </template>

            <template v-slot:Exception="{item}">
              <span>
                {{ item.Exception }}
              </span>
            </template>

            <template v-slot:Actions="{item}">
              <FormButton label="Reprocess" size="medium" @click="reprocessFailedJob(item.Key)" :disabled="loading"></FormButton>
            </template>

          </ResultsTable>
        </v-card-text>
      </v-card>

    </v-container>
  </div>
</template>

<script>
  import DynamicFormControl from '@/components/form/DynamicFormControl.vue'
  import FormButton from '@/components/form/FormButton.vue'
  import ResultsTable from '@/components/shared/ResultsTable.vue'
  import { formatDateTimeForDisplay, formatDateForDisplay } from '@/scripts/helper'
  import { HangfireService } from '@/api/HangfireService'
  import { RenewalOfferService } from '@/api/RenewalOfferService'

  export default {
    name: 'RenewalProcessing',
    components: {
      DynamicFormControl,
      FormButton,
      ResultsTable
    },
    data() {
      return {
        loading: false,
        methodParameters: ['Policy Key', 'Submission Id', 'Rate Plan Effective Date', 'Ext_Algo_PricingKey', 'Force Overwrite', 'Recalc', 'Use Full Fitting'],
        failedJobsHeaders: [
          { text: "Failed At", value: "FailedAt", sortable: true, filterable: false },
          { text: "Job", value: "Name", sortable: true, filterable: true },
          { text: "Parameters", value: "Parameters", sortable: false, filterable: true },
          { text: "Exception", value: "Exception", sortable: true, filterable: true },
          { text: "", value: "Actions", sortable: false, filterable: false },
        ],
        failedJobs: [],
        scheduledJobsCount: 0,
        forceOverwrite: false,
        recalc: false,
        policyKey: null,
        includeContractCodeList: null,
        excludeContractCodeList: null,
        beginDate: null,
        endDate: null,
        ratePlanEffectiveDate: null,
        beginDateAllowedDates: (val) => {
          return (this.endDate === '' || this.endDate == null || new Date(val).getUTCDate() <= new Date(this.endDate).getUTCDate())
        },
        endDateAllowedDates: (val) => {
          return (this.beginDate === '' || this.beginDate == null || new Date(val).getUTCDate() >= new Date(this.beginDate).getUTCDate())
        }
      }
    },
    created() {
      this.getData()
    },
    computed: {
      disableBeginEndDates() {
        return this.policyKey && this.policyKey.trim() !== ''
      },
      disablePolicyKey() {
        return (this.beginDate != null && this.beginDate !== '') || (this.endDate != null && this.endDate !== '')
      },
      policyKeyRules() {
        return (!this.policyKey || this.policyKey.trim() === '') && (!this.beginDate || this.beginDate === '') && (!this.endDate || this.endDate === '') ? 'required' : ''
      },
      beginDateRules() {
        return (!this.policyKey || this.policyKey.trim() === '') && (!this.beginDate || this.beginDate === '') ? 'required' : ''
      },
      endDateRules() {
        return (!this.policyKey || this.policyKey.trim() === '') && (!this.endDate ||  this.endDate === '') ?'required' : ''
      }
    },
    methods: {
      formatDateTimeForDisplay,
      formatDateForDisplay,
      getData() {
        this.loading = true
        HangfireService.getJobs()
          .then((result) => {
            this.failedJobs = result.failedJobsResult
            this.scheduledJobsCount = result.renewalsScheduledCount
          })
          .finally(() => {
            this.loading = false
          })
      },
      reprocessFailedJob(key) {
        HangfireService.reprocessJob({ key })
          .then(() => {
            this.$store.dispatch('toastSuccess', { Message: 'Job Requeued' })
            this.getData()
          })
      },
      buildRequestParams() {
        return {
          body: {
          policyKey: this.policyKey,
          begin: this.beginDate,
          end: this.endDate,
          ratePlanEffectiveDate: this.ratePlanEffectiveDate,
          includeContractCodeList: this.includeContractCodeList,
          excludeContractCodeList: this.excludeContractCodeList,
          forceOverwrite: this.forceOverwrite,
          recalc: this.recalc
          }
        }
      },
      runRenewals() {
        this.$refs.observer1.validate()
          .then((valid) => {
            if (valid) {
              this.loading = true
              const requestObj = this.buildRequestParams()
              RenewalOfferService.run(requestObj)
                .then(() => {
                  this.$store.dispatch('toastSuccess', { Message: 'Running Renewals' })
                  this.getData()
                })
                .catch((err) => {
                  this.getData()
                })
                .finally(() => {
                  this.loading = false
                })
            }
          });
      }
    }
  }
</script>
