<template>
  <div>
    <Dialog :title="jsonModalTitle" :value="showModalRequeue" @close-dialog="closeModal" width="70%">
      <template v-slot:card-text>
        <span style="text-align: center;">
            <pre>{{ modalData }}</pre>
      </span>
       
      </template>
      <template v-slot:card-actions>
          <v-btn color="secondary" text type="button" @click="closeModalRequeue">No</v-btn>
          <v-btn color="secondary" text type="button" @click="requeueAll()">Yes</v-btn>
      </template>
    </Dialog>

    <Dialog :title="jsonModalTitle" :value="showModalRemove" @close-dialog="closeModal" width="70%">
      <template v-slot:card-text>
        <span style="text-align: center;">
            <pre>{{ modalData }}</pre>
      </span>
       
      </template>
      <template v-slot:card-actions>
          <v-btn color="secondary" text type="button" @click="closeModalRemove">No</v-btn>
          <v-btn color="secondary" text type="button" @click="removeAll()">Yes</v-btn>
      </template>
    </Dialog>


    <v-container>
      <h1>Message Queue Admin</h1>
      <h2>Dead Letter Queue Messages</h2>
      <h3>Application: <em>{{ this.subscription }}</em></h3>
      <h3>Topic: <em>{{ this.topic }}</em></h3>
      


      <span>

                    <v-btn color="secondary" @click="openModalWithDataRequeue()" :disabled="deadLetterQueueMessages.length === 0  || selectedItems.length > 0 || deadLetterQueueMessagesLoading">Requeue All</v-btn>
                    <v-btn color="secondary" @click="requeueSelected()" :disabled="selectedItems.length === 0  || deadLetterQueueMessagesLoading">Requeue Selected</v-btn>
                    <v-btn color="secondary" @click="removeSelected()" :disabled="selectedItems.length === 0  || deadLetterQueueMessagesLoading">Clear Selected</v-btn>
                    <v-btn color="secondary" @click="openModalWithDataRemove()" :disabled="deadLetterQueueMessages.length === 0  || selectedItems.length > 0  || deadLetterQueueMessagesLoading">Clear Queue</v-btn>
                </span>

      <MessageQueueDeadLettersTable v-if="deadLetterQueueMessages" :messageQueueDeadLetters="deadLetterQueueMessages"
        :messageQueueDeadLettersLoading="deadLetterQueueMessagesLoading"
        @requeueEvent="handleRequeueEvent($event)"
        @removeEvent="handleRemoveEvent($event)"
        @update:selectedItems="handleSelectedItemsUpdate" />
    </v-container>
  </div>
</template>

<script>
import { MessageQueueAdminService } from '@/api/MessageQueueAdminService'
import Environment from '@/scripts/environment'
import AxoisInstances from '@/plugins/axios'
import MessageQueueDeadLettersTable from './MessageQueueDeadLettersTable.vue'
import Dialog from '@/components/shared/Dialog.vue'

export default {
    name: "MessageQueueDeadLetters",
    components: {
        MessageQueueDeadLettersTable,
        Dialog
    },
    data() {
        return {
            deadLetterQueueMessages: [],
            deadLetterQueueMessagesLoading: false,
            topic: this.$route.params.topic,
            subscription: this.$route.params.application,
            selectedItems: [],
            modalData: null,
            jsonModalTitle: "",
            showModalRequeue: false,
            showModalRemove: false
        }
        
    },
    methods: {
        initialize() {

            this.getDeadLetterQueueMessages();
        },
        getDeadLetterQueueMessages() {
            this.deadLetterQueueMessages = [];
            this.deadLetterQueueMessagesLoading = true;

            MessageQueueAdminService.deadLetterQueueMessages({
                topic: this.topic,
                subscription: this.subscription
            })
            .then((response) => {
                this.deadLetterQueueMessages = response;
            })
            .finally(() => {
                this.deadLetterQueueMessagesLoading = false
            })
        },
        handleRequeueEvent(messageData) {
            MessageQueueAdminService.requeueMessage({
                topic: this.topic,
                subscription: this.subscription,
                messageId: messageData.Id,
                sequenceNumber: messageData.SequenceNumber
            })
            .then((response) => {
                this.deadLetterQueueMessages = this.deadLetterQueueMessages.filter(function(value, index, dlq) {
                    return value.Id != messageData.Id;
                });
            });
        },
        handleRemoveEvent(messageData) {
            MessageQueueAdminService.removeMessage({
                topic: this.topic,
                subscription: this.subscription,
                messageId: messageData.Id,
                sequenceNumber: messageData.SequenceNumber
            })
            .then((response) => {
                this.deadLetterQueueMessages = this.deadLetterQueueMessages.filter(function(value, index, dlq) {
                    return value.Id != messageData.Id;
                });
            });
        },
        removeAll() {
            this.deadLetterQueueMessagesLoading = true;
            this.showModalRemove = false;
            this.modalMode = null;
            this.modalData = null;
            MessageQueueAdminService.removeAll({
                topic: this.topic,
                subscription: this.subscription
               
            })
            .then((response) => {
                
                

                // Set a timeout to turn off loading after 10 seconds
                setTimeout(() => {
            // Call getDeadLetterQueueMessages after 10 seconds
            this.getDeadLetterQueueMessages();

            // Turn off loading indicator after 10 seconds
            this.deadLetterQueueMessagesLoading = false;
        }, 10000); // 10000 milliseconds = 10 seconds

        
                
            });
        },
        removeSelected() {
            this.deadLetterQueueMessagesLoading = true;
            MessageQueueAdminService.removeSelected({
                topic: this.topic,
                subscription: this.subscription,
                body:  this.selectedItems
               
            })
            .then((response) => {
                this.selectedItems = [];
                setTimeout(() => {
            // Call getDeadLetterQueueMessages after 10 seconds
            this.getDeadLetterQueueMessages();

            // Turn off loading indicator after 10 seconds
            this.deadLetterQueueMessagesLoading = false;
        }, 10000); // 10000 milliseconds = 10 seconds
                
            })
            .catch((error) =>{
                console.log('Error removing selected items, Please Try again', error);
                this.getDeadLetterQueueMessages();
                this.deadLetterQueueMessagesLoading = false; 

            });
        
        },
        requeueSelected() {
            this.deadLetterQueueMessagesLoading = true;
            MessageQueueAdminService.requeueSelected({
                topic: this.topic,
                subscription: this.subscription,
                body:  this.selectedItems
               
            })
            .then((response) => {
                this.selectedItems = [];
                setTimeout(() => {
            // Call getDeadLetterQueueMessages after 10 seconds
            this.getDeadLetterQueueMessages();

            // Turn off loading indicator after 10 seconds
            this.deadLetterQueueMessagesLoading = false;
        }, 10000); // 10000 milliseconds = 10 seconds
                
            })
            .catch((error) =>{
                console.log('Error removing selected items, Please Try again', error);
                this.getDeadLetterQueueMessages();
                this.deadLetterQueueMessagesLoading = false; 

            });
        
        },
        handleSelectedItemsUpdate(selectedItems) {
      // Update parent's selectedItems when child emits an update
      this.selectedItems = selectedItems;
    },
        requeueAll() {
            this.deadLetterQueueMessagesLoading = true;
            this.showModalRequeue = false;
            this.modalMode = null;
            this.modalData = null;
            MessageQueueAdminService.requeueAll({
                topic: this.topic,
                subscription: this.subscription
               
            })
            .then((response) => {
                 
                setTimeout(() => {
            // Call getDeadLetterQueueMessages after 10 seconds
            this.getDeadLetterQueueMessages();

            // Turn off loading indicator after 10 seconds
            this.deadLetterQueueMessagesLoading = false;
        }, 10000); // 10000 milliseconds = 10 seconds
            })
            .catch((error) =>{
                console.log('Error removing selected items, Please Try again', error);
                this.getDeadLetterQueueMessages();
                this.deadLetterQueueMessagesLoading = false; 

            });
        },
        openModalWithDataRequeue() {
      this.modalData = "Are you sure you want to requeue all messages in queue? This will commence background job untill all messages are requeued"
      this.showModalRequeue = true;
      this.jsonModalTitle = `Message Queue Admin Service - Requeue All - ${this.topic} - ${this.subscription}`
    },
    openModalWithDataRemove() {
      this.modalData = "Are you sure you want to remove all messages in queue? This will commence background job untill all messages are removed"
      this.showModalRemove = true;
      this.jsonModalTitle = `Message Queue Admin Service - Remove All - ${this.topic} - ${this.subscription}`
    },
    closeModalRequeue() {
      this.showModalRequeue = false;
      this.modalMode = null;
      this.modalData = null;
      
    },
    closeModalRemove() {
      this.showModalRemove = false;
      this.modalMode = null;
      this.modalData = null;
      
    }
        
    },
    mounted() {
        this.initialize()
    }
}
</script>
