<template>
  <Dialog :title="title" :persistent="true" :value="open">
    <template v-slot:card-text>

      <p class="mb-0" v-if="!rejected && !waiting" key="1">Would you like to request access to this record from the other user?</p>

      <div v-else-if="waiting">
        <Loading :size="32" class="text-center" /> 
        <p class="mb-0 text-center pt-2">Waiting on a response from the other user...</p>
        <p class="mb-0 text-center" v-show="remainingSeconds">Time remaining: {{remainingSeconds}} seconds</p>
      </div>

      <p class="mb-0" v-else-if="rejected">Your request for access was rejected by the other user.</p>
      
    </template>
    <template v-slot:card-actions>
      <template v-if="!rejected">
        <v-btn color="secondary" text type="button" @click="close" class="mr-2" :disabled="waiting">No</v-btn>
        <v-btn color="secondary" type="button" @click="contestLock" :disabled="waiting">Yes</v-btn>
      </template>
      <template v-else>
        <v-btn color="secondary" text type="button" @click="close">Close</v-btn>
      </template>
      
    </template>
  </Dialog>
  
</template>

<script>
import Dialog from '@/components/shared/Dialog.vue'

export default {
  name: 'LockContestDialog',
  components: {
    Dialog
  },
  data() {
    return {
      title: "Record Locked",
      open: true,
      waiting: false,
      rejected: false,
      remainingSeconds: 0,
    }
  },
  props: {

  },
  methods: {
    contestLock() {
      this.waiting = true;
      this.$store.dispatch('contestLock');

      const exp = this.$store.getters.getLockReleaseRequestExpirationTime;
      setInterval(() => {
        if (exp) {
          const now = new Date();
          this.remainingSeconds = Math.round((exp - now) / 1000); 
        } else {
          this.remainingSeconds = 0;
        }
      }, 1000);

    },
    close() {
      this.$store.commit('setRejectedLockRequest', null);
      this.$store.commit('setLockReleaseRequestRejected', false);
      this.$router.push({ path: '/dashboard'})
    }
  },
  mounted() {

    this.$store.watch(() => {
      this.rejected = this.$store.getters.getLockReleaseRequestRejected;
      if (this.rejected) this.waiting = false;
    }, () => {},
    {
      immediate: true
    })
  }
}
</script>
