<template>
  <div style="display: inline-block; min-height: 60px">

    <span style="display: block; font-size: 16px; line-height: 18px;" v-if="coverageOption || policyNumber">
      <span v-if="coverageOption">{{coverageOption}}</span>
      <span v-if="coverageOption && policyNumber">&nbsp;&nbsp;|&nbsp;&nbsp;</span>
      <span v-if="policyNumber">{{policyNumber}}</span>
    </span>

    <span style="display: block; font-size: 12px; line-height: 14px;" v-if="agency">
      <span v-if="agency">{{agency}}</span>
    </span>

    <span style="display: block; font-size: 12px; line-height: 14px;" v-if="insuredName || entityName">
      <span v-if="insuredName">{{insuredName}}</span>
      <span v-if="insuredName && entityName">&nbsp;-&nbsp;</span>
      <span v-if="entityName">{{entityName}}</span>
    </span>

    <span style="display: block; font-size: 12px; line-height: 14px;" v-if="riskAddress">
      <span v-if="riskAddress">{{riskAddress}}</span> <span v-if="riskCounty">({{riskCounty}}&nbsp;County)</span>
    </span>

    <span style="display: block; font-size: 12px; line-height: 14px;" v-if="effectiveDate || expirationDate">
      <span v-if="effectiveDate">{{effectiveDate}}</span>
      <span v-if="effectiveDate && expirationDate">&nbsp;-&nbsp;</span>
      <span v-if="expirationDate">{{expirationDate}}</span>
    </span>

  </div>
</template>

<script>
  import { formatDateForDisplay } from '@/scripts/helper'
  import { PolicyService } from '@/api/PolicyService'

  export default {
    name: 'PolicyServicingHeader',
    data() {
      return {
        policy: null
      }
    },
    props: {
      account: {
        type: Object,
        default: null
      },
      initalPolicy: {
        type: Object,
        default: null
      }
    },
    computed: {
      coverageOption() {
        if (!this.policy) return ''

        if (this.policy.Policy.Header?.ProductType?.Value) {
          return this.policy.Policy.Header.ProductType.Value.toUpperCase();
        } else if (this.policy.Policy?.ProductType?.Value) {
          return this.policy.Policy.ProductType.Value.toUpperCase();
        }

        return ''
      },
      policyNumber() {
        if (!this.policy) return ''

        if (this.policy.Policy.Header?.SubmissionNumber) {
          return this.policy.Policy.Header.SubmissionNumber.toUpperCase();
        } else if (this.policy.Policy?.PolicyNumber) {
          return this.policy.Policy.PolicyNumber;
        }

        return '';
      },
      insuredName() {
        if (!this.policy) return ''

        if (this.policy.Policy?.Insured?.PrimaryInsured?.FirstName && this.policy.Policy?.Insured?.PrimaryInsured?.LastName) {
          return (this.policy.Policy.Insured.PrimaryInsured.FirstName + ' ' + this.policy.Policy.Insured.PrimaryInsured.LastName).trim();
        }

        return (this.policy.Policy?.Risk?.FirstName?.value + ' ' + this.policy.Policy?.Risk?.LastName?.value).trim();
      },
      effectiveDate() {
        if (!this.policy) return ''

        if (this.policy.Policy.Header?.EffectiveDate) {
          return formatDateForDisplay(this.policy.Policy.Header.EffectiveDate, true);
        } else if (this.policy.Policy?.TermBegin) {
          return formatDateForDisplay(this.policy.Policy.TermBegin, true);
        }

        return '';
      },
      expirationDate() {
        if (!this.policy) return ''

        if (this.policy.Policy.Header?.ExpirationDate) {
          return formatDateForDisplay(this.policy.Policy.Header.ExpirationDate, true);
        } else if (this.policy.Policy?.TermEnd) {
          return formatDateForDisplay(this.policy.Policy.TermEnd, true);
        }

        return '';
      },
      agency() {
        if (!this.account) return ''
        return this.agencyDisplay(this.account.Agency, this.account.Agent);
      },
      entityName() {
        if (!this.account) return ''
        return this.account?.Insured?.OrgName;
      },
      riskAddress() {
        if (!this.account) return ''
        return this.addressDisplay(this.account.Risk);
      },
      riskCounty() {
        if (!this.account) return ''
        return this.account.Risk.County;
      }
    },
    methods: {
      agencyDisplay(agency, agent) {
        return agency?.OrgName + ' (' + agency?.City + ', ' + agency?.State + ') - ' + agent?.Name;
      },
      addressDisplay(risk) {
        if (!risk) return '';

        return `${risk.Street1}, ${risk.City}, ${risk.State} ${risk.Postal}, ${risk.Country}`;
      }
    },
    mounted() {
      this.$watch('account', () => {
        if (this.account.Product.toLowerCase() === "flood") {
          PolicyService.get({ submissionId: this.account.id })
          .then((response) => {
            if (response) {
              this.policy = response
            }
          })
          .catch(() => {
            this.$store.dispatch('toastError', { Message: 'Failed to retrieve policy data' })
          })
        } else if (!this.initalPolicy){
          PolicyService.getV2({ submissionId: this.account.id })
          .then((response) => {
            if (response) {
              this.policy = response
            }
          })
          .catch(() => {
            this.$store.dispatch('toastError', { Message: 'Failed to retrieve policy data' })
          })
        } else {
          this.policy = this.initalPolicy;
        }
      })
    }
  }
</script>
