var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-container',[_c('h1',[_vm._v(" Cancellation ")]),_c('v-row',[_c('v-col',{staticClass:"text-right",attrs:{"cols":"12","md":"8"}},[_c('PolicyServicingHeader',{attrs:{"account":_vm.account,"initalPolicy":_vm.policy}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('v-card',{staticClass:"py-8 px-8 mb-8"},[_c('ValidationObserver',{ref:"observer",attrs:{"tag":"span"}},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.validate()}}},[_c('DynamicFormControl',{attrs:{"control":{
                                    name: 'CancellationReason',
                                    type: 'Dropdown',
                                    value: _vm.cancellationReason,
                                    config: {
                                        label:'What is the primary reason for canceling the policy?',
                                        col: 12,
                                        small: true,
                                        options: _vm.compiledCancelationReasons,
                                        rules: 'required',
                                        itemText: 'text',
                                        itemValue: 'value',
                                    }
                                  }},on:{"change":function($event){_vm.cancellationReason = $event.value}}}),(_vm.compiledCancelationSubReasons && _vm.compiledCancelationSubReasons.length > 0)?_c('DynamicFormControl',{attrs:{"control":{
                                    name: 'subreason',
                                    type: 'Dropdown',
                                    value: _vm.cancellationSubReason,
                                    config: {
                                        label:'Please Select?',
                                        col: 12,
                                        small: true,
                                        options: _vm.compiledCancelationSubReasons,
                                        rules: 'required',
                                        short: true,
                                        itemText: 'text',
                                        itemValue: 'value',
                                        indented: true
                                    }
                                  }},on:{"change":function($event){_vm.cancellationSubReason = $event.value}}}):_vm._e(),(_vm.cancellationReason)?_c('DynamicFormControl',{attrs:{"control":{
                                    name: 'CancellationDate',
                                    type: 'DateInput',
                                    value: _vm.cancellationDate,
                                    indented: true,
                                    config: {
                                        label:'Cancellation Date',
                                        col: 12,
                                        rules: 'required|validDate',
                                        small: true,
                                        short: true,
                                        allowedDates: _vm.allowedDates
                                    }
                                  }},on:{"change":function($event){_vm.cancellationDate = $event.value}}}):_vm._e(),_c('DynamicFormControl',{attrs:{"control":{
                                    name: 'MailingAddressChanged',
                                    type: 'RadioButtonGroup',
                                    value: _vm.mailingAddressChanged,
                                    name: 'MailingAddressChanged',
                                    config: {
                                        options: [
                                            {value: true, text: 'Yes'},
                                            {value: false, text: 'No'}
                                        ],
                                        label: 'Has the Mailing Address changed?',
                                        small: true,
                                        short: true,
                                        disabled: _vm.loading,
                                        mandatory: true,
                                        required: true,
                                        rules: 'required',
                                        description: _vm.mailingAddressText,
                                        footnote: _vm.mailingAddressText,
                                        footnoteClasses: 'footnote-bold'
                                    }
                                }},on:{"change":function($event){_vm.mailingAddressChanged = $event.value}}}),(_vm.mailingAddressChanged)?_c('DynamicFormControl',{attrs:{"control":{
                                    name: 'MailingAddress',
                                    type: 'Address',
                                    value: _vm.mailingAddress,
                                    indented: true,
                                    config: {
                                        label: 'Mailing Address',
                                        col: 12,
                                        rules: 'required',
                                        short: true,
                                        addressType: 'MAILING'
                                    }
                                  }},on:{"change":function($event){_vm.mailingAddress = $event.value}}}):_vm._e(),_c('div',{staticClass:"footnote-bold"},[_c('span',[_vm._v("Please note that we will be mailing a copy of the cancellation document to the insured and (if applicable) any mortgagees listed on the policy.")])]),_c('DynamicFormControl',{attrs:{"control":{
                                    name: 'ElectronicSignature',
                                    type: 'Attestation',
                                    value: _vm.attestation,
                                    config: {
                                        label:'',
                                        col: 12,
                                        useGlobalAgentAttestation: true,
                                        rules: 'required',
                                    }
                                  }},on:{"change":function($event){_vm.attestation = $event.value}}}),_c('div',{staticClass:"text-right"},[_c('v-btn',{staticClass:"ml-4",attrs:{"color":"white","large":"","disabled":_vm.loading},on:{"click":_vm.returnToAccount}},[(_vm.loading)?_c('Loading',{staticClass:"mr-2",attrs:{"size":24}}):_vm._e(),_vm._v(" Cancel ")],1),_c('v-btn',{staticClass:"ml-4",attrs:{"color":"secondary","large":"","type":"submit","disabled":_vm.loading}},[(_vm.loading)?_c('Loading',{staticClass:"mr-2",attrs:{"size":24}}):_vm._e(),_vm._v(" Submit ")],1)],1)],1)])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }