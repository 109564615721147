<template>
  <v-card class="d-flex align-content-stretch flex-grow-1">
      <div class="insured-details pa-6 pb-4 flex-grow-1">
          <h3 class="mb-3 text-bold">Insured Name &amp; Mailing Address</h3>
          <p class="mb-1">{{selectedInsured.Name}}</p>
          <template v-if="selectedInsured.Street1">
              <p class="mb-1">{{selectedInsured.Street1}} {{selectedInsured.Street2}}</p>
              <p class="mb-1">{{selectedInsured.City}}, {{selectedInsured.State}} {{selectedInsured.Postal}} {{selectedInsured.Country}}</p>
          </template>
          <template v-if="selectedInsured.Email">
              <p class="mb-1" v-if="selectedInsured.Email"><i class="fal fa-envelope"></i> <a :href="'mailto:' + selectedInsured.Email" class="blue-link">{{selectedInsured.Email}}</a></p>
          </template>
          
          
      </div>
      <div class="risk-details pa-6 pb-4 flex-grow-1">
          <h3 class="mb-3 text-bold">Risk Address</h3>
          <template>
              <p class="mb-1">{{selectedRisk.Street1}} {{selectedRisk.Street2}}</p>
              <p class="mb-1">{{selectedRisk.City}}, {{selectedRisk.State}} {{selectedRisk.Postal}} {{selectedRisk.Country}}</p>
              <p class="mb-1" v-if="selectedRisk.County">{{selectedRisk.County + (selectedRisk.County ? ' County' : '')}}</p>
              <!-- <p class="mb-1 risk-type">
                  <span v-if="selectedRisk.Type == 'Home'"><i class="fal fa-home"></i> Home</span>
                  <span v-if="selectedRisk.Type == 'Condo'"><i class="fal fa-building"></i> Condo</span>
              </p> -->
          </template>
      </div>
  </v-card>
</template>

<script>
export default {
  name: 'AccountCard',
  data() {
    return {}
  },
  props: {
    account: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    selectedInsured() {
        if (this.account && this.account.Insured) return this.account.Insured;
        return {};
    },
    selectedRisk() {
        if (this.account && this.account.Risk) return this.account.Risk;
        return {};
    },
  }
}
</script>

<style scoped lang="scss">
@import '@/style.scss';

.risk-type {
    color: $amwins-blue;
    font-weight: bold;
}

.insured-details, .risk-details {
    h3 { color: $amwins-blue;}
}
</style>
