import { Module } from 'vuex';
import { RootState, LockState, LockRequestPayload } from './types';
import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { LockService } from '@/api/LockService'
import { Amwins_Portal_Api_Services_LockService_UserLock } from '@/api/index.defs';
import Vue from 'vue';

let lockReleaseTimeout: any = null;
let lockExtendInterval: any = null;

export const state: LockState = {
    activeLock: null,
    rejectedLockRequest: null,
    contestedLock: null,
    lockReleaseRequest: null,
    lockReleaseRequestExpirationTime: null,
    lockReleaseRequestRejected: false
}

const mutations: MutationTree<LockState> = {
    setActiveLock(state, data) {
        Vue.set(state, 'activeLock', data);    
    },
    setRejectedLockRequest(state, data) {
        Vue.set(state, 'rejectedLockRequest', data); 
    },
    setContestedLock(state, data) {
        Vue.set(state, 'contestedLock', data);    
    },
    setLockReleaseRequest(state: LockState, data: Amwins_Portal_Api_Services_LockService_UserLock) {
        Vue.set(state, 'lockReleaseRequestRejected', false)
        Vue.set(state, 'lockReleaseRequest', data)
    },
    setLockReleaseRequestExpirationTime(state: LockState, data: Date) {
        Vue.set(state, 'lockReleaseRequestExpirationTime', data)
    },
    setLockReleaseRequestRejected(state: LockState, data: boolean) {
        Vue.set(state, 'lockReleaseRequestRejected', data)
    }
}

const getters: GetterTree<LockState, RootState> = {
    getActiveLock: (state: any) => {
        return state.activeLock;
    },
    getRejectedLockRequest: (state: any) => {
        //alert('here');
        return state.rejectedLockRequest;
    },
    getLockReleaseRequest: (state: any) => {
        return state.lockReleaseRequest;
    },
    getLockReleaseRequestExpirationTime: (state: any) => {
        return state.lockReleaseRequestExpirationTime;
    },
    getLockReleaseRequestRejected: (state: any) => {
        return state.lockReleaseRequestRejected;
    }
}

const actions: ActionTree<LockState, RootState> = {

    requestLock(context, data: LockRequestPayload) {
        LockService.put({
            domain: data.domain,
            key: data.key,
        }).then((data: Amwins_Portal_Api_Services_LockService_UserLock) => {
            context.commit('setActiveLock', data);
            lockExtendInterval = setInterval(() => {
                context.dispatch('extendActiveLock');
            }, 300000) //5 mins
        }).catch((err) => {
            if (err && err.response && err.response.data && err.response.data.status == 423) {
                context.commit('setRejectedLockRequest', {
                    domain: data.domain,
                    key: data.key
                });
            }
        });
    },
    contestLock(context) {
        const exp = new Date();
        exp.setSeconds(exp.getSeconds() + 30);
        context.commit('setLockReleaseRequestExpirationTime', exp)

        lockReleaseTimeout = setTimeout(() => { 
            context.commit('setContestedLock', null);
            context.commit('setLockReleaseRequestExpirationTime', null)
            context.commit('setLockReleaseRequestRejected', true);
        }, 30000);

        LockService.put({
            domain: context.state.rejectedLockRequest.domain,
            key: context.state.rejectedLockRequest.key,
            contest: true
        }).then((data: Amwins_Portal_Api_Services_LockService_UserLock) => {
            //alert(JSON.stringify(data));
            context.commit('setContestedLock', data);
        })
    },
    checkLockReleaseRequest(context, data: Amwins_Portal_Api_Services_LockService_UserLock) {
        if (!state.activeLock || state.activeLock.key != data.key || state.activeLock.domain != data.domain) {
            LockService.delete({
                domain: data.domain,
                key: data.key
            })
        } else {
            context.commit('setLockReleaseRequest', data);

            const exp = new Date();
            exp.setSeconds(exp.getSeconds() + 30);
            context.commit('setLockReleaseRequestExpirationTime', exp)

            lockReleaseTimeout = setTimeout(() => { 
                context.dispatch('deleteActiveLock');
            }, 30000);
        }
    },
    extendActiveLock(context) {
        if (!state.activeLock) return;
        if (lockReleaseTimeout) clearTimeout(lockReleaseTimeout);
        context.commit('setLockReleaseRequestExpirationTime', null)
        LockService.put({
            domain: context.state.activeLock.domain,
            key: context.state.activeLock.key,
            contest: false
        }).then((data: Amwins_Portal_Api_Services_LockService_UserLock) => {
            context.commit('setActiveLock', data);
            context.commit('setLockReleaseRequest', null);
        })
    },
    deleteActiveLock(context) {
        if (!state.activeLock) return;
        if (lockReleaseTimeout) clearTimeout(lockReleaseTimeout);
        if (lockExtendInterval) clearInterval(lockExtendInterval);
        context.commit('setLockReleaseRequestExpirationTime', null)
        return LockService.delete({
            domain: state.activeLock.domain,
            key: state.activeLock.key
        }).then((data: any) => {
            context.commit('setActiveLock', null);
            context.commit('setLockReleaseRequest', null);
        })
    }
}

const namespaced: boolean = false;

export const lock: Module<LockState, RootState> = {
    namespaced,
    state,
    getters,
    actions,
    mutations
}