import { createStateForm } from '@/scripts/helper'
import { Module } from 'vuex';
import { EndorsementFormState, RootState } from './types';
import Vue from 'vue';

export const state: EndorsementFormState = {
  form: null,
  unmodifiedForm: null
}

const mutations = {
  clearEndorsementForm(state: any) {
    Vue.set(state, 'form', null);
    Vue.set(state, 'unmodifiedForm', null);
  },
  initEndorsementForm(state: any, form: any) {
    const newForm = createStateForm({ ...form });
    Vue.set(state, 'form', newForm);
  },
  initUnmodifiedEndorsementForm(state: any, form: any) {
    const unmodifiedForm = createStateForm({ ...form });
    Vue.set(state, 'unmodifiedForm', unmodifiedForm);
  }
}

const getters = {
  getEndorsementForm(state: any) {
    return state.form;
  },
  getUnmodifiedEndorsementForm(state: any) {
    return state.unmodifiedForm;
  }
}

export const actions = {

}

const namespaced: boolean = true;

export const endorsementForm: Module<EndorsementFormState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
}