<template>
  <div>
    <v-dialog
        v-model="open"
        persistent
        max-width="600px"
      >
        <v-card class="pa-4">

          <v-card-title>
            <h3>Edit Effective Date</h3>
            <v-spacer></v-spacer>

            <v-btn color="secondary" type="button" icon small @click="close">
              <v-icon>fa-times</v-icon>
            </v-btn>

          </v-card-title>

          <v-card-text>
            <br>
            <ValidationObserver tag="span" v-slot="{}" ref="editObserver">
              <form @submit.prevent="validate()" v-if="open">

                <v-row>
                  <v-col cols="12" class="py-0">
                    <DynamicFormControl key="EditSubmission_EffectiveDate"
                        :control="{
                          ...EffectiveDateControl,
                          value: EffectiveDate
                        }"
                        @change="EffectiveDate = $event.value; updateExpDate()" 
                    />
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" class="py-0">
                    <DynamicFormControl key="EditSubmission_ExpirationDate"
                        :control="{
                          ...ExpirationDateControl,
                          value: ExpirationDate
                        }"
                        @change="ExpirationDate = $event.value" 
                    />
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" class="text-right">
                    <v-btn color="secondary" type="button" large text class="mr-2" @click="close">Cancel</v-btn>
                    <v-btn color="secondary" type="submit" large>Requote</v-btn>
                  </v-col>
                </v-row>
                
              </form>
            </ValidationObserver>
          </v-card-text>

          
        </v-card>
      </v-dialog>

      <QuoteComparisonGridAreYouSureDialog
          v-if="showQuoteComparisonGridAreYouSureModal"
          @agree="continueQuoteComparisonGridAreYouSureModal"
          @close="closeQuoteComparisonGridAreYouSureModal">
      </QuoteComparisonGridAreYouSureDialog>
    </div>

</template>

<script>
import DynamicFormControl from '@/components/form/DynamicFormControl.vue'
import dateformat from 'dateformat'
import QuoteComparisonGridAreYouSureDialog from '@/components/views/Quote/QuoteComparisonGridAreYouSureDialog.vue'

export default {
  name: "EditSubmissionDialog",
  components: {
    DynamicFormControl,
    QuoteComparisonGridAreYouSureDialog
  },
  data() {
    return {
      open: false,
      EffectiveDate: null,
      EffectiveDateControl: null,
      ExpirationDate: null,
      ExpirationDateControl: null,
      showQuoteComparisonGridAreYouSureModal: false
    }
  },
  props: {
  },
  emits: [
    'requote',
    'close'
  ],
  methods: {
    validate() {
      this.$refs.editObserver.validate()
      .then((valid) => {
        if (valid) {
          this.showQuoteComparisonGridAreYouSureModal = true;
        }
      });
    },
    continueQuoteComparisonGridAreYouSureModal() {
      this.showQuoteComparisonGridAreYouSureModal = false;
      this.saveValue('EffectiveDate', this.EffectiveDate);
      this.saveValue('ExpirationDate', this.ExpirationDate);
      this.requote();
      this.close();
    },
    closeQuoteComparisonGridAreYouSureModal() {
      this.showQuoteComparisonGridAreYouSureModal = false;
      this.close();
    },
    updateExpDate() {
      const effectiveDate = new Date(this.EffectiveDate);
      const expirationDate = new Date(effectiveDate.setFullYear(effectiveDate.getFullYear() + 1));
      this.ExpirationDate = dateformat(expirationDate, 'mm/dd/yyyy');
    },
    saveValue(name, value) {
      this.$store.commit('updateFormControl', {
          module: 'quoteForm',
          name: name,
          value: value
      });
      this.$store.commit('updateFormControl', {
          module: 'additionalForm',
          name: name,
          value: value
      });
    },
    requote() {
      this.$emit('requote');
    },
    close() {
      this.$emit('close');
    }
  },
  computed: {

  },
  mounted() {
    let EffectiveDateControl = {...this.$store.getters.getControl('quoteForm', 'EffectiveDate')};
    EffectiveDateControl.config.col = 12;
    this.EffectiveDateControl = EffectiveDateControl;
    this.EffectiveDate = EffectiveDateControl.value;

    let ExpirationDateControl = { ...this.$store.getters.getControl('quoteForm', 'ExpirationDate') };
    ExpirationDateControl.config.col = 12;
    this.ExpirationDateControl = ExpirationDateControl;
    this.ExpirationDate = ExpirationDateControl.value;

    this.open = true;
  }
}
</script>

<style scoped>

</style>