import {
  Amwins_Api123_Client_Models_Etc_Iso3166_Iso31661,
  IList,
  List,
  IListResult,
  ListResultDto,
  IPagedResult,
  PagedResultDto,
  Dictionary,
  IDictionary,
  IRequestOptions,
  IRequestConfig,
  getConfigs,
  axios,
  basePath
} from './index.defs';

export class EtcService {
  /** Generate by swagger-axios-codegen */
  // @ts-nocheck
  /* eslint-disable */

  /**
   *
   */
  static iso3166(options: IRequestOptions = {}): Promise<Amwins_Api123_Client_Models_Etc_Iso3166_Iso31661[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/Etc/Iso3166';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}
