<template>
  <div>
    <v-container>
      <h1>Munich Lifecycle Support Tools</h1>

      <v-card class="mb-4">
        <v-card-title>
          Log BSL Deltas Sequentially
        </v-card-title>
        <v-card-text>
          <ValidationObserver tag="span" v-slot="{}" ref="observer1">
            <v-form ref="form">
              <v-row>
                <v-col md="6"
                       lg="4">
                  <DynamicFormControl :control="{
                                        name: 'SubmissionId',
                                        type: 'TextInput',
                                        config: {
                                            label: 'Submission Id',
                                            rules: idRules,
                                            col: 12,
                                            short: true,
                                            clearable: true
                                        }
                                      }"
                                      style="margin-top: 0px;"
                                      @change="submissionId = $event.value"/>
                </v-col>
                <v-col md="6"
                       kg="8">
                  <FormButton label="Submit" size="x-large" @click="executeBslDeltas" :disabled="loading"/>
                </v-col>
              </v-row>
            </v-form>
          </ValidationObserver>
        </v-card-text>
      </v-card>

      <v-card class="mb-4">
        <v-card-title>
          Backfill BSL by Engine Quote Enquiry Id
        </v-card-title>
        <v-card-text>
          <ValidationObserver tag="span" v-slot="{}" ref="observer2">
            <v-form ref="form">
              <v-row>
                <v-col md="6"
                       lg="4">
                  <DynamicFormControl :control="{
                                        name: 'EngineQuoteEnquiryId',
                                        type: 'TextInput',
                                        config: {
                                            label: 'EngineQuoteEnquiryId',
                                            rules: idRules,
                                            col: 12,
                                            short: true,
                                            clearable: true
                                        }
                                      }"
                                      style="margin-top: 0px;"
                                      @change="engineQuoteEnquiryId = $event.value"/>
                </v-col>
                <v-col md="6"
                       kg="8">
                  <FormButton label="Submit" size="x-large" @click="executeBslBackfill" :disabled="loading"/>
                </v-col>
              </v-row>
            </v-form>
          </ValidationObserver>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import DynamicFormControl from '@/components/form/DynamicFormControl.vue'
import FormButton from '@/components/form/FormButton.vue'
import {MunichReService} from "@/api/MunichReService";

export default {
  name: 'MunichLifecycle',
  components: {
    DynamicFormControl,
    FormButton
  },
  data() {
    return {
      loading: false,
      idRules: 'required',
      submissionId: "0",
      engineQuoteEnquiryId: "0"
    }
  },
  methods: {
    executeBslDeltas() {
      this.$refs.observer1.validate()
          .then((valid) => {
            if (valid) {
              this.loading = true
              MunichReService.compareSequentially({submissionId: this.submissionId})
                  .then(() => {
                    this.$store.dispatch('toastSuccess', {Message: 'Successfully Logged to Seq'})
                  })
                  .finally(() => {
                    this.loading = false
                  })
            }
          });
    },
    executeBslBackfill() {
      this.$refs.observer2.validate()
          .then((valid) => {
            if (valid) {
              this.loading = true
              MunichReService.postExistingQuoteToBsl({engineQuoteEnquiryId: this.engineQuoteEnquiryId})
                  .then(() => {
                    this.$store.dispatch('toastSuccess', {Message: 'Successfully Backfilled'})
                  })
                  .finally(() => {
                    this.loading = false
                  })
            }
          });
    },
  }
}
</script>
