//@ts-nocheck
export default {
  data() {
    return {
      isMobile: false
    }
  },
  methods: {
    checkIsMobile() {
      if (window.innerWidth <= 900) {
          this.isMobile = true;
      }
      else {
          this.isMobile = false;
      }
    }
  },
  mounted() {
    this.checkIsMobile();

    window.addEventListener('resize', () => {
      this.checkIsMobile();
    });
  }

}