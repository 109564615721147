import {
  Amwins_Portal_Api_ApiClients_Munich_Models_MunichQuoteRequest,
  Amwins_Portal_Api_ApiClients_Munich_Models_PolicyClass,
  Amwins_Portal_Api_ApiClients_Munich_Models_MunichRenewalReference,
  Amwins_Portal_Api_ApiClients_Munich_Models_EndorsementReference,
  Amwins_Portal_Api_ApiClients_Munich_Models_PropertyConstructionType,
  Amwins_Portal_Api_ApiClients_Munich_Models_PropertyRoofShapeType,
  Amwins_Portal_Api_ApiClients_Munich_Models_PropertyRoofConstructionType,
  Amwins_Portal_Api_ApiClients_Munich_Models_UpdateType,
  Amwins_Portal_Api_ApiClients_Munich_Models_PropertyProtectionClass,
  Amwins_Portal_Api_ApiClients_Munich_Models_PropertyHeatSourceAlternate,
  Amwins_Portal_Api_ApiClients_Munich_Models_RiskOccupancyType,
  Amwins_Portal_Api_ApiClients_Munich_Models_CoverageDwellingValuation,
  Amwins_Portal_Api_ApiClients_Munich_Models_CoveragePersonalPropertyValuation,
  Amwins_Portal_Api_ApiClients_Munich_Models_MunichClaim,
  Amwins_Portal_Api_ApiClients_Munich_Models_MunichClaimTypeOfLoss,
  Amwins_Portal_Api_ApiClients_Munich_Models_PolicyInsuredType,
  IList,
  List,
  IListResult,
  ListResultDto,
  IPagedResult,
  PagedResultDto,
  Dictionary,
  IDictionary,
  IRequestOptions,
  IRequestConfig,
  getConfigs,
  axios,
  basePath
} from './index.defs';

export class MunichReService {
  /** Generate by swagger-axios-codegen */
  // @ts-nocheck
  /* eslint-disable */

  /**
   *
   */
  static postExistingQuoteToBsl(
    params: {
      /**  */
      engineQuoteEnquiryId?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/MunichRE/PostExistingQuoteToBSL';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { engineQuoteEnquiryId: params['engineQuoteEnquiryId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static compareSequentially(
    params: {
      /**  */
      submissionId?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/MunichRE/CompareSequentially';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { submissionId: params['submissionId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static compareRequestToBslEntry(
    params: {
      /**  */
      submissionId?: string;
      /**  */
      bslId?: string;
      /**  */
      body?: Amwins_Portal_Api_ApiClients_Munich_Models_MunichQuoteRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/MunichRE/CompareRequestToBslEntry';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { submissionId: params['submissionId'], bslId: params['bslId'] };
      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}
