<template>
  <div>
    <v-container>
      <h1>Quote Errors</h1>

      <v-card class="mb-4" :loading="quotesLoading">
        <v-card-title>
          Active Errors
        </v-card-title>
        <v-card-text>
          <ResultsTable :tableHeaders="quoteTableHeaders" :results="quoteResults" :loading="quotesLoading">

            <template v-slot:UpdatedAt="{ item }">
              <span>
                {{ formatDateTimeForDisplay(item.UpdatedAt) }}
              </span>
            </template>

            <template v-slot:SubmissionId="{ item }">
              <span>
                {{ item.SubmissionId }}
              </span>
            </template>

            <template v-slot:SalesChannel="{ item }">
              <span>
                {{ item.SalesChannel }}
              </span>
            </template>

            <template v-slot:QuoteId="{ item }">
              <span>
                {{ item.QuoteId }}
              </span>
            </template>


            <template v-slot:ErrorText="{ item }">
              <span>
                {{ item.ErrorText }}
              </span>
            </template>


            <template v-slot:DebugText="{ item }">
              <span>
                {{ item.DebugText }}
              </span>
            </template>

            <template v-slot:Actions="{ item }">
              <ViewAccount :account="item.Account" target="_blank" />
            </template>

          </ResultsTable>
        </v-card-text>
      </v-card>

    </v-container>
  </div>
</template>

<script>
import { QuotesService } from '@/api/QuotesService'
import ViewAccount from '@/components/shared/ViewAccount.vue'
import ResultsTable from '@/components/shared/ResultsTable.vue'
import { makeFilterableText, formatDateTimeForDisplay, nonDefaultDate } from '@/scripts/helper'
import Environment from '@/scripts/environment'
import AxoisInstances from '@/plugins/axios'



export default {
  name: 'QuoteErrors',
  components: {
    ResultsTable,
    ViewAccount
  },
  data() {
    return {
      quotesLoading: false,
      quoteResults: [],
      quoteTableHeaders: [
        { text: "UpdatedAt", value: "UpdatedAt", sortable: true, filterable: false },
        { text: "SubmissionId", value: "SubmissionId", sortable: true, filterable: true },
        { text: "Channel", value: "SalesChannel", sortable: true, filterable: true },
        { text: "QuoteId", value: "QuoteId", sortable: true, filterable: false },
        { text: "Error Text", value: "ErrorText", sortable: true, filterable: true },
        { text: "Debug Text", value: "DebugText", sortable: true, filterable: true },
        { text: "Actions", value: "Actions", sortable: false, filterable: false },
      ],
      quotesPending: true,
    }
  },
  methods: {
    makeFilterableText,
    formatDateTimeForDisplay,
    nonDefaultDate,
    initialize() {
      //mock when in local host mode
      if (Environment.USEMOCK === true) {
        var MockAdapter = require("axios-mock-adapter");


        var mock = new MockAdapter(AxoisInstances.bff);
        mock.onGet("/api/Quotes/Errors")
          .reply(200, [
            {
              "Number": null,
              "SubmissionId": "SUB220002477",
              "SalesChannel": "Munich",
              "UpdatedAt": "2023-05-08T17:53:58+00:00",
              "Status": "Error",
              "DeclineType": null,
              "ErrorText": "Failed to get quote from MunichRECompactApiDataService",
              "DebugText": "Patch operation failed. Unable to determine the executed request hash",
              "Premium": null,
              "Fees": null,
              "Taxes": null,
              "PriceStatus": null,
              "PriceExpiration": null,
              "Expiration": null,
              "Total": null,
              "EffectiveDate": null,
              "ExpirationDate": null,
              "CovA": null,
              "CovB": null,
              "CovC": null,
              "CovD": null,
              "LossAssessment": null,
              "Liability": null,
              "MedPay": null,
              "PersonalInjury": null,
              "ReplacementCostDwelling": null,
              "ReplacementCostStructures": null,
              "ReplacementCostContents": null,
              "InflationGuard": null,
              "BuildingOrdinanceOrLaw": null,
              "WindDrivenRain": null,
              "Theft": null,
              "Mold": null,
              "DomesticAnimalLiability": null,
              "WaterBackup": null,
              "WaterDamageSublimit": null,
              "TotalPeril": null,
              "WindHailPeril": null,
              "EarthquakePeril": null,
              "FloodPeril": null,
              "WaterDamagePeril": null,
              "Footnote": null,
              "id": "SUB220002477:Munich"
            },
            {
              "Number": null,
              "SubmissionId": "SUB220002006",
              "SalesChannel": "Munich",
              "UpdatedAt": "2023-04-26T20:43:05+00:00",
              "Status": "Error",
              "DeclineType": null,
              "ErrorText": "Failed to get quote from WebApplicationPatch",
              "DebugText": "Response status code does not indicate success: 400 (Bad Request).",
              "Premium": null,
              "Fees": null,
              "Taxes": null,
              "PriceStatus": null,
              "PriceExpiration": null,
              "Expiration": null,
              "Total": null,
              "EffectiveDate": null,
              "ExpirationDate": null,
              "CovA": null,
              "CovB": null,
              "CovC": null,
              "CovD": null,
              "LossAssessment": null,
              "Liability": null,
              "MedPay": null,
              "PersonalInjury": null,
              "ReplacementCostDwelling": null,
              "ReplacementCostStructures": null,
              "ReplacementCostContents": null,
              "InflationGuard": null,
              "BuildingOrdinanceOrLaw": null,
              "WindDrivenRain": null,
              "Theft": null,
              "Mold": null,
              "DomesticAnimalLiability": null,
              "WaterBackup": null,
              "WaterDamageSublimit": null,
              "TotalPeril": null,
              "WindHailPeril": null,
              "EarthquakePeril": null,
              "FloodPeril": null,
              "WaterDamagePeril": null,
              "Footnote": null,
              "id": "SUB220002006:Munich"
            },
            {
              "Number": null,
              "SubmissionId": "SUB220002009",
              "SalesChannel": "Munich",
              "UpdatedAt": "2023-04-25T12:55:40+00:00",
              "Status": "Error",
              "DeclineType": null,
              "ErrorText": "Failed to get quote from WebApplicationPatch",
              "DebugText": "Response status code does not indicate success: 400 (Bad Request).",
              "Premium": null,
              "Fees": null,
              "Taxes": null,
              "PriceStatus": null,
              "PriceExpiration": null,
              "Expiration": null,
              "Total": null,
              "EffectiveDate": null,
              "ExpirationDate": null,
              "CovA": null,
              "CovB": null,
              "CovC": null,
              "CovD": null,
              "LossAssessment": null,
              "Liability": null,
              "MedPay": null,
              "PersonalInjury": null,
              "ReplacementCostDwelling": null,
              "ReplacementCostStructures": null,
              "ReplacementCostContents": null,
              "InflationGuard": null,
              "BuildingOrdinanceOrLaw": null,
              "WindDrivenRain": null,
              "Theft": null,
              "Mold": null,
              "DomesticAnimalLiability": null,
              "WaterBackup": null,
              "WaterDamageSublimit": null,
              "TotalPeril": null,
              "WindHailPeril": null,
              "EarthquakePeril": null,
              "FloodPeril": null,
              "WaterDamagePeril": null,
              "Footnote": null,
              "id": "SUB220002009:Munich"
            }
          ]);

      }

      this.getQuoteData()
    },
    getQuoteData() {
      this.quoteResults = []

      this.quotesLoading = true

      QuotesService.errors()
        .then((response) => {
          response.forEach(item => {
            
            //pass though item.UpdatedAt = item.UpdatedAt;
            item.SubmissionId = this.makeFilterableText(item, 'SubmissionId');
            item.SalesChannel = this.makeFilterableText(item, 'SalesChannel');
            item.QuoteId = item.id;
            item.ErrorText = this.makeFilterableText(item, 'ErrorText');
            item.DebugText = this.makeFilterableText(item, 'DebugText');
            item.Account = { id: item.SubmissionId, AccountStatus: 'Incomplete' };
            this.quoteResults.push(item)
            
          })
        })
        .finally(() => {
          this.quotesLoading = false
        })
    },
  },
  mounted() {
    this.initialize()
  }
}
</script>
