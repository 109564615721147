var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"6","md":"8"}},[_c('DynamicFormControl',{attrs:{"control":{
                              name: 'DateRange',
                              type: 'RadioButtonGroup',
                              value: _vm.dateRange,
                              config: {
                                  col: 12,
                                  small: true,
                                  options: _vm.dateRangeOptions,
                                  short: true
                              }
                            }},on:{"change":function($event){_vm.dateRange = $event.value}}})],1),_c('v-col',{attrs:{"cols":"12","lg":"3","md":"2"}},[(this.dateRange === 999)?_c('DynamicFormControl',{attrs:{"control":{
                              name: 'BeginDate',
                              type: 'DateInput',
                              value: _vm.beginDate,
                              config: {
                                  label: 'Begin Date',
                                  col: 12,
                                  clearable: true,
                                  allowedDates: _vm.beginDateAllowedDates,
                                  short: true
                              }
                            }},on:{"change":function($event){_vm.beginDate = $event.value}}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","lg":"3","md":"2"}},[(this.dateRange === 999)?_c('DynamicFormControl',{attrs:{"control":{
                              name: 'EndDate',
                              type: 'DateInput',
                              value: _vm.endDate,
                              config: {
                                  label: 'End Date',
                                  col: 12,
                                  clearable: true,
                                  allowedDates: _vm.endDateAllowedDates,
                                  short: true
                              }
                            }},on:{"change":function($event){_vm.endDate = $event.value}}}):_vm._e()],1)],1),_c('v-card',{staticClass:"mb-4"},[_c('ResultsTable',{attrs:{"tableHeaders":_vm.tableHeaders,"results":_vm.results,"loading":_vm.loading,"sortBy":"RecordInsert"},scopedSlots:_vm._u([{key:"RecordInsert",fn:function({item}){return [_vm._v(" "+_vm._s(_vm.formatDateForDisplay(item.RecordInsert))+" ")]}},{key:"FileType",fn:function({item}){return [_vm._v(" "+_vm._s(item.Type.Name)+" ")]}},{key:"fileId",fn:function({item}){return [_c('FormButton',{staticClass:"mr-4",attrs:{"label":"View","size":"large"},on:{"click":function($event){return _vm.view(item.Key)}}}),_c('FormButton',{attrs:{"label":"Download","size":"large"},on:{"click":function($event){return _vm.download(item.Key)}}})]}}])})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }