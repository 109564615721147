<template>
  <div style="display: block; width: 100%;">
    <v-hover>
      <template v-slot:default="{ hover }">
        <div>
          <v-img
              :src="getUserAvatarPath"
              :alt="alt"
              :width="size"
              contain
              max-width="100%"
              aspect-ratio="1"
              @click="avatarClick()"
              style="postion: relative;"
              class="avatar card-box-shadow" 
          >
              <template v-slot:placeholder>
                  <v-img
                      :src="defaultImage"
                      :alt="alt"
                      :width="size"
                      contain
                      max-width="100%"
                      aspect-ratio="1"
                  />
              </template>
              <v-fade-transition>
              <v-overlay
                v-if="hover"
                absolute
                color="#333333"
              >
                <v-btn text><i class="fas fa-camera"></i>&nbsp;Change Avatar</v-btn>
              </v-overlay>
            </v-fade-transition>
          </v-img>
          
        </div>
      </template>
    </v-hover>

    <input type="file" style="display: none;"
      id="avatarFileInput"
      ref="file"
      @change="onChange" 
      accept="image/*" />

    <CropperDialog 
      v-if="showCropperDialog"
      :file="file"
      :aspectRatio="1"
      :autoCropArea="0.9"
      @close="showCropperDialog = false"
      @emitPhoto="uploadPhoto"
    />
  </div>
  
</template>

<script>
import CropperDialog from '@/components/shared/CropperDialog.vue'
import { resizeImage } from '@/scripts/helper'
import { errorHandler } from '@/plugins/axios'

export default {
  name: 'AvatarUpload',
  components: {
    CropperDialog,
  },
  data() {
    return {
      alt: 'Your Avatar',
      size: 300,
      file: null,
      defaultImage: '/images/placeholder-profile.png',
      showCropperDialog: false,
      overlay: false
    }
  },
  computed: {
    getUserAvatarPath() {
      return this.$store.getters.getUserAvatarPath
    }
  },
  methods: {
    onChange() {
      const file = this.$refs.file.files[0];

      this.file = null;
      this.file = file;
      
      if (this.file) {
        this.showCropperDialog = true;
      }
    },
    avatarClick() {
      document.getElementById('avatarFileInput').click();
    },
    async uploadPhoto(file) {

      this.showCropperDialog = false;
      if (file) {
        file = await resizeImage(file);
        const requestURL = '/api/Avatar/create';
        
        try {
          await this.$httpIdentity.post(requestURL, file, { handleError: false });
          this.$store.commit('setUserAvatarPath');
        } catch (err) {
          if ( err.response && err.response.status == 403) {
            this.$store.dispatch('toastError', {Message: 'Corrupted File. Please resave the file and try again.'});
          } else {
            errorHandler(err);
          }
        }

      }
    },
  }

}
</script>

<style scoped>
.avatar {
  cursor: pointer;
  border-radius: 100%;
  border: 4px solid white;
}

.avatar:hover {
  opacity: 0.8;
}

::v-deep .avatar .v-overlay__content {
  margin-top: 0!important;
}

</style>
