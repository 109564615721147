<template>
  <div>

    <v-container>
      <h1>News Article</h1>

      <!--Not using the grid system here so that the text will wrap around the article image-->
      <div>
        <div>
          <v-img :src="article.ImageUrl"
                 height="500"
                 class="article-image" />
          <p class="article-author" v-html="article.AuthorInfo"></p>
        </div>
        <h5 class="text-bold pt-3">
          {{ formatDateForDisplay(article.Date) }}
        </h5>
        <br />
        <span class="article-title">
          {{ article.Title }}
        </span>
        <br />
        <br />
        <p class="article-body" v-html="article.Body"></p>
      </div>

      <v-container v-if="loading">
        <v-row>
          <v-col class="text-center">
            <Loading :size="300" />
          </v-col>
        </v-row>
      </v-container>

    </v-container>

  </div>
</template>

<script>
  import { formatDateForDisplay } from '@/scripts/helper'
  import { NewsFeedService } from '@/api/NewsFeedService'
  import Loading from '@/components/shared/Loading.vue'

  export default {
    name: 'NewsArticle',
    components: {
      Loading
    },
    data() {
      return {
        loading: false,
        article: {}
      }
    },
    created() {
      this.initialize()
    },
    methods: {
      formatDateForDisplay,
      initialize() {
        this.loading = true
        const articleKey = this.$route.query.key
        NewsFeedService.getAmwinsArticle({
          key: articleKey
        })
          .then((response) => {
            let localArticle = response
            NewsFeedService.getAmwinsArticleImage({ key: response.Id })
              .then((imageResponse) => {
                localArticle.ImageUrl = imageResponse.value[0].Url
                this.article = localArticle
              })
          })
          .finally(() => {
            this.loading = false
          })
      }
    }
  }
</script>

<style scoped lang="scss">
  @import '@/style.scss';

  .article-title {
    font-size: 50px;
    line-height: 65px
  }

  .article-body {
    text-align: justify;
  }

  @media (max-width:899px) {
    .article-image {
      margin-bottom: 10px;
    }
  }

  @media (min-width:900px) {
    .article-image {
      float: right;
      margin-left: 40px;
      margin-bottom: 10px;
      width: 50%;
    }

    .article-author {
      float: right;
      width: 50%;
      margin-left: 40px
    }
  }
</style>
