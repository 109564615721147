var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('h1',[_vm._v("Agent Underwriter Reassignment")])])],1),(_vm.showAgentUnderwriterReassignment)?_c('div',[_c('v-card',{staticClass:"mb-6"},[_c('v-card-text',[_c('v-row',[_c('v-col',{staticClass:"pb-4",staticStyle:{"padding-top":"20px"},attrs:{"cols":"12"}},[_c('v-row',{staticClass:"pb-2"},[_c('v-col',{staticClass:"d-flex align-center py-0",attrs:{"cols":"12"}},[_c('label',{staticClass:"main-label mb-0"},[_vm._v("Generate CSV Template")])])],1),_c('p',{staticClass:"description mb-0"},[_vm._v("Enter an Underwriter's Email Address to generate an Agent Underwriter Reassignment CSV template")]),_c('DynamicFormControl',{attrs:{"control":{
                    type: 'TextInput',
                    name: 'Underwriter',
                    value: _vm.underwriterEmail,
                    config: {
                        label:'Enter an Amwins Underwriter Email Address',
                        short: true,
                        rules: 'email',
                        clearable: true,
                        type: 'email'
                    }
                  }},on:{"change":_vm.updateUnderwriterEmail}})],1),(_vm.showGenerateCSV && !_vm.csvRequested)?_c('v-col',{staticClass:"py-0 mb-6 text-center",attrs:{"cols":"12"}},[_c('v-btn',{key:"download",attrs:{"color":"secondary","type":"button","large":"","disabled":_vm.disableGenerateCSV},on:{"click":_vm.generateCSV}},[(_vm.disableGenerateCSV)?_c('Loading',{staticClass:"mr-2",attrs:{"size":24}}):_vm._e(),_c('i',{staticClass:"fas fa-download"}),_vm._v(" Generate CSV ")],1)],1):_vm._e(),(_vm.csvRequested)?_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('v-alert',{staticClass:"text-left",attrs:{"type":"info","dense":""}},[_vm._v(" CSV Template is being generated and will download automatically. ")])],1):_vm._e()],1)],1)],1),_c('v-card',{staticClass:"mb-6"},[_c('v-card-text',[_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('DynamicFormControl',{attrs:{"control":{
                    type: 'FileUpload',
                    name: 'File',
                    value: _vm.files,
                    config: {
                        label:'Reassign Agent Underwriters',
                        description: 'Upload a CSV with the following headings: AgentEmail, OldUnderwriterEmail, NewUnderwriterEmail',
                        accept: '.csv',
                        max: 1,
                        rules: ''
                    }
                  }},on:{"change":_vm.updateFiles}})],1),(_vm.files && _vm.files.length)?_c('v-col',{staticClass:"py-0 mb-6 text-center",attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"color":"secondary","type":"button","large":""},on:{"click":_vm.upload}},[_c('i',{staticClass:"fas fa-upload"}),_vm._v(" Submit ")])],1):_vm._e(),(_vm.uploadSubmitted)?_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('v-alert',{staticClass:"text-left",attrs:{"type":"info","dense":""}},[_vm._v(" "+_vm._s(_vm.uploadSubmittedCount)+" Agent Underwriter Reassignments Queued ")])],1):_vm._e()],1)],1)],1)],1):_c('v-alert',{staticClass:"mt-4",attrs:{"type":"warning","dense":"","icon":false}},[_vm._v("You do not have access to this feature.")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }