<template>
  <div style="display: inline-block; min-height: 60px">

    <span style="display: block; font-size: 16px; line-height: 18px;" v-if="coverageOption || submissionId">
      <span v-if="coverageOption">{{coverageOption}}</span>
      <span v-if="coverageOption && submissionId">&nbsp;&nbsp;|&nbsp;&nbsp;</span>
      <span v-if="submissionId">{{submissionId}}</span>
    </span>

    <span style="display: block; font-size: 12px; line-height: 14px;" v-if="agency">
      <span v-if="agency">{{agency}}</span>
    </span>

    <span style="display: block; font-size: 12px; line-height: 14px;" v-if="insuredName || entityName">
      <span v-if="insuredName">{{insuredName}}</span>
      <span v-if="insuredName && entityName">&nbsp;-&nbsp;</span>
      <span v-if="entityName">{{entityName}}</span>
    </span>

    <span style="display: block; font-size: 12px; line-height: 14px;" v-if="riskAddress">
      <span v-if="riskAddress">{{riskAddress}}</span> <span v-if="riskCounty">({{riskCounty}}&nbsp;County)</span>
    </span>

    <span style="display: block; font-size: 12px; line-height: 14px;" v-if="effectiveDate || expirationDate">
      <span v-if="effectiveDate">{{effectiveDate}}</span>
      <span v-if="effectiveDate && expirationDate">&nbsp;-&nbsp;</span>
      <span v-if="expirationDate">{{expirationDate}}</span>
    </span>
    
    
    
  </div>  
</template>

<script>
import { formatDateForDisplay } from '@/scripts/helper'

export default {
  name: 'QuoteProductHeader',
  data() {
    return {
      coverageOption: null,
      submissionId: null,
      agency: null,
      insuredName: null,
      entityName: null,
      riskAddress: null,
      riskCounty: null,
      effectiveDate: null,
      expirationDate: null
    }
  },
  props: {
    submission: Object,
    form: {
      type: String,
      default: null
    }
  },
  methods: {
    agencyDisplay(agency, agent) {
      return agency.OrgName + ' (' + agency.City + ', ' + agency.State + ') - ' + agent.Name;
    }
  },
    mounted() {
    if (this.submission) {
      //todo - get from Account instead, for SubmissionConfirmation?
      this.coverageOption = this.submission.Data.CoverageOption.value.toUpperCase();
      this.submissionId = this.submission.id;
      this.agency = this.agencyDisplay(this.submission.Data.Agency.value, this.submission.Data.Agent.value);
      this.insuredName = (this.submission.Data.FirstName.value + ' ' + this.submission.Data.LastName.value).trim();
      this.entityName = this.submission.Data.EntityName.value;
      this.riskAddress = this.submission.Data.Risk.value.Formatted;
      this.riskCounty = this.submission.Data.Risk.value.County;
      this.effectiveDate = formatDateForDisplay(this.submission.Data.EffectiveDate.value);
      this.expirationDate = formatDateForDisplay(this.submission.Data.ExpirationDate.value);
      return;
    } 

    this.$store.watch(() => {

      const coverageOption = this.$store.getters.getControlValue('quoteForm','CoverageOption');
      if (coverageOption) {
        this.coverageOption = coverageOption.toUpperCase();
      } else {
        this.coverageOption = null;
      }

      const quoteForm = this.$store.getters.getForm('quoteForm');
      if (quoteForm && quoteForm.data && quoteForm.ResultId) {
        this.submissionId = quoteForm.ResultId
      }

      const agent = this.$store.getters.getControlValue('quoteForm','Agent');
      const agency = this.$store.getters.getControlValue('quoteForm','Agency');
      if (agent && agency) {
        this.agency = this.agencyDisplay(agency, agent);
      }

      const firstName = this.$store.getters.getControlValue('quoteForm','FirstName');
      const lastName = this.$store.getters.getControlValue('quoteForm','LastName');
      if (firstName || lastName) {
        this.insuredName = ((firstName || '') + ' ' + (lastName || '')).trim();   
      } else {
        this.insuredName = null;
      }

      const entityName = this.$store.getters.getControlValue('quoteForm','EntityName');
      this.entityName = entityName || null;

      const risk = this.$store.getters.getControlValue('quoteForm','Risk');
      if (risk && risk.Formatted) {
        this.riskAddress = risk.Formatted;
        this.riskCounty = risk.County;
      } else {
        this.riskAddress = null;
        this.riskCounty = null;
      }

      if (this.form === 'Quote') {
        const effectiveDate = this.$store.getters.getControlValue('quoteForm', 'EffectiveDate');
        const expirationDate = this.$store.getters.getControlValue('quoteForm', 'ExpirationDate');
        this.effectiveDate = effectiveDate || null;
        this.expirationDate = expirationDate || null;
      } else {
        const addlEffectiveDate = this.$store.getters.getControlValue('additionalForm', 'EffectiveDate');
        const addlExpirationDate = this.$store.getters.getControlValue('additionalForm', 'ExpirationDate');

        if (addlEffectiveDate) {
          this.effectiveDate = addlEffectiveDate || null;

          // update quote form to keep data in sync
          this.$store.commit('updateFormControl', {
            module: 'quoteForm',
            name: 'EffectiveDate',
            value: addlEffectiveDate
          });
        }
        if (addlExpirationDate) {
          this.expirationDate = addlExpirationDate || null;

          // update quote form to keep data in sync
          this.$store.commit('updateFormControl', {
            module: 'quoteForm',
            name: 'ExpirationDate',
            value: addlExpirationDate
          });
        }

      }

      
    }, () => {},
    {
      immediate: true
    })
  }
}
</script>
