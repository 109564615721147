//@ts-nocheck
import Environment from '@/scripts/environment'
import Vue from 'vue';
import { showSection } from '@/scripts/helper'

export default {
    data() {
        return {
            module: null,
            activeSectionNumber: 0,
            random: Math.random(),
            Environment,
            saving: false,
            loading: false,
        }
    },
    computed: {
        form() {
            return this.$store.getters.getForm(this.module);
        },
        steps() {
            return this.form.steps;
        },
        step() {
            return this.$store.getters.getCurrentStep(this.module);
        },
        currentStepNumber() {
            return this.$store.getters.getCurrentStepNumber(this.module);
        },
        visibleStepIds() {
            return this.steps.filter(step => {
                return this.$store.getters.showStep(this.module, step);
            }).map(step => {
                return step.id;
            })
        },
        formId() {
            const form = this.$store.getters.getForm(this.module)
            if (form) {
                return form.id;
            } else {
                return "123";
            }
        },
        currentStepHasDataServiceValue() {
            let hasDataServiceValue = false;

            this.step.sections.forEach(section => {
                section.controls.forEach(control => {
                    if (control.DataServiceValue !== undefined && control.DataServiceValue !== null && control.type != 'Hidden') {
                        if (!control.config || !control.config.hideDataServiceValue) {
                            hasDataServiceValue = true;
                        }
                    }
                })
            });

            return hasDataServiceValue;
        },
        showPrefillButton() {
            return Environment.environment == 'localhost' || Environment.environment == 'development' || Environment.environment == 'staging';
        },
        status() {
            if (!this.form.status) return 'Active';
            else return this.form.status;
        }
    },
    methods: {
        proceedToNextStep() {
            this.stepClick(this.currentStepNumber + 1);
            if (!this.showStep() && this.currentStepNumber < this.form.steps.length) this.proceedToNextStep();
        },
        backToPreviousStep() {
            this.stepClick(Math.max(this.currentStepNumber - 1, 0));
            if (!this.showStep() && this.currentStepNumber != 0) this.backToPreviousStep();
        },
        currentStepIsFirstVisibleStep() {
            return this.visibleStepIds.indexOf(this.step.id) === 0;
        },
        currentStepIsLastVisibleStep() {
            return this.visibleStepIds.indexOf(this.step.id) === this.visibleStepIds.length - 1;
        },
        updateValue(data) {
            if (typeof(data.value) !== 'object' && data.value === this.getControlValue(data.name)) return;    

            this.$store.commit('updateFormControl', {
                module: this.module,
                name: data.name,
                value: data.value
            });
            this.afterUpdateValue(data.name);
        },
        disableControl(name) {
            const control = this.$store.getters.getControl(this.module, name);
            const config = control.config;
            this.$nextTick(() => {
                Vue.set(config, 'disabled', true);
            })          
        },
        enableControl(name) {
            const control = this.$store.getters.getControl(this.module, name);
            const config = control.config;
            this.$nextTick(() => {
                Vue.set(config, 'disabled', false);
            });
        },
        disableControlOption(name, value) {
            const control = this.$store.getters.getControl(this.module, name);
            const config = control.config;
            const options = config.options;
            if (config && options && options.length) {
                this.$nextTick(() => {
                    Vue.set(options.find(o => o.value == value), 'disabled', true);
                })
            }
        },
        enableControlOption(name, value) {
            const control = this.$store.getters.getControl(this.module, name);
            const config = control.config;
            const options = config.options;
            if (config && options && options.length) {
                this.$nextTick(() => {
                    Vue.set(options.find(o => o.value == value), 'disabled', false);
                })
            }
        },
        afterUpdateValue(name) {
            //placeholder for form-specific functionality after a control is updated, ie fetch data services
        },
        beforeStepChange() {
            //placeholder for form-specific functionality when a step is initialized, ie fetch data services
            this.loading = false;
        },
        showStep() {
            const show = this.$store.getters.showStep(this.module, this.step);
            return show;
        },
        getControlValue(name) {
            return this.$store.getters.getControlValue(this.module, name);
        },
        showControl(name) {
            const show =  this.$store.getters.showControl(this.module, name);
            const control = this.$store.getters.getControl(this.module, name);
            const aliasValue = this.$store.getters.getNonNullAliasValue(this.module, name);
            if (!show && control?.value !== null && !control.alias) {
                this.updateValue({name: name, value: aliasValue ?? null});
            }
            return show;
        },
        showSection(section) {
            return showSection(this.module, section);
        },
        stepClick(stepNumber) {
            this.beforeStepChange(stepNumber);

            this.$store.commit('setCurrentStepNumber', {
                module: this.module,
                stepNumber: stepNumber
            })
            this.setStepHash();         
            this.scrollToTop();
            
        },
        setStepHash() {
            let fullPath = this.$route.fullPath;
            if (fullPath.indexOf('#') > -1) {
                fullPath = fullPath.split('#')[0];
            }

            history.replaceState(
                {},
                null,
                fullPath + '#' + this.step.id
            )

            this.$store.state.currentStep = this.step.id;
        },
        scrollToFirstError() {
            const item = this.$el.getElementsByClassName("hasError")[0];
            const viewportOffset = item.getBoundingClientRect();

            const header = document.getElementsByTagName("header")[0];
            const headerHeight = header.offsetHeight;

            window.scrollTo({
                top: viewportOffset.top + window.scrollY - (60 + headerHeight),
                left: 0,
                behavior: 'smooth'
            });
        },
        scrollToTop() {
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'auto'
            });
        },
        validate() {
            this.$refs.observer.validate()
            .then(valid => {

                if (valid) {
                    this.onSubmit();
                } else {
                    this.scrollToFirstError();    
                }
            });
        }
    }
}