import {
  MessageQueuesMessageQueueTopology,
  MessageQueuesSubscribingApplication,
  MessageQueuesSubscriptionTopic,
  MessageQueuesMessageQueueMessage,
  IList,
  List,
  IListResult,
  ListResultDto,
  IPagedResult,
  PagedResultDto,
  Dictionary,
  IDictionary,
  IRequestOptions,
  IRequestConfig,
  getConfigs,
  axios,
  basePath
} from './index.defs';

export class MessageQueueAdminService {
  /** Generate by swagger-axios-codegen */
  // @ts-nocheck
  /* eslint-disable */

  /**
   *
   */
  static messageQueueTopology(options: IRequestOptions = {}): Promise<MessageQueuesMessageQueueTopology> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/MessageQueueAdmin/MessageQueueTopology';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static deadLetterQueueMessages(
    params: {
      /**  */
      topic?: string;
      /**  */
      subscription?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<MessageQueuesMessageQueueMessage[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/MessageQueueAdmin/DeadLetterQueueMessages';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { topic: params['topic'], subscription: params['subscription'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static requeueAll(
    params: {
      /**  */
      topic?: string;
      /**  */
      subscription?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/MessageQueueAdmin/RequeueAllMessages';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { topic: params['topic'], subscription: params['subscription'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static removeAll(
    params: {
      /**  */
      topic?: string;
      /**  */
      subscription?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/MessageQueueAdmin/RemoveAllMessages';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { topic: params['topic'], subscription: params['subscription'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static removeSelected(
    params: {
      /**  */
      topic?: string;
      /**  */
      subscription?: string;
      /**  */
      body?: any | null[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/MessageQueueAdmin/RemoveSelected';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { topic: params['topic'], subscription: params['subscription'] };
      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static requeueSelected(
    params: {
      /**  */
      topic?: string;
      /**  */
      subscription?: string;
      /**  */
      body?: any | null[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/MessageQueueAdmin/RequeueSelected';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { topic: params['topic'], subscription: params['subscription'] };
      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static clearAllDeadLetters(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/MessageQueueAdmin/ClearAllDeadLetters';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static refreshCache(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/MessageQueueAdmin/RefreshCache';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}
