<template>
  <div>
    <span v-html="Message"></span>

    <a :href="Link" v-if="Link">
      <v-btn x-small text color="secondary">View</v-btn>
    </a>

  </div>
</template>

<script>
export default {
  name: 'ToastContent',
  props: {
    Message: String,
    Link: String
  }
}
</script>


