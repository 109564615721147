<template>
    <v-footer v-if="initialOidcUserLoaded" color="#002B49" min-height="104" :key="currentStep">
        <p>&copy; {{ new Date().getFullYear() }} Amwins, Inc. All Rights Reserved. | <a href="https://www.amwins.com/privacy" target="_blank">Privacy</a></p>
        <v-spacer></v-spacer>
        <div class="help-links">

            <span @click="openFive9()" style="cursor: pointer;">
                <i class="fas fa-comment-alt-lines"></i>&nbsp;&nbsp;Chat
            </span>
            <span>
                <a href="mailto:digital.customerservice@amwins.com">
                    <i class="fas fa-envelope"></i>&nbsp;&nbsp;Email
                </a>
            </span>
            <span>
                <a href="tel:+14047514400">
                    <i class="fas fa-phone"></i>&nbsp;&nbsp;404-751-4400
                </a>
            </span>
            <span>
                <a href="#" @click="$store.dispatch('logout')">
                    Logout
                </a>    
            </span>
            
        </div>
      
    </v-footer>
</template>

<script>

export default {
    name: 'TheFooter',
    data() {
        return {
            routeName: null,
            currentStep: null
        }
    },
    computed: {
        initialOidcUserLoaded() {
            return this.$store.getters.getUser && this.$route.path !== '/callback'
        },
    },
    methods: {
        openFive9() {
            this.$store.dispatch('openFive9');
        },
    },
    watch: {
        '$store.state.currentStep': function() {
            this.currentStep = this.$store.state.currentStep;
        }
    },
    created() {
        this.routeName = this.$router.currentRoute.name;

        this.$router.afterEach((to) => {
            this.routeName = to.name;
        }); 
    }
}

</script>

<style scoped lang="scss">
    .v-footer {
        padding: 0 40px;

        * {
            color: white;
        }

        p {
            font-size: 12px;
            margin-bottom: 0;

            a {
                text-decoration: none;
            }
        }

        .help-links {

            span {
                
                font-size: 15px;
                padding: 0 10px;
                display: inline-block;
            }

            a {
                text-decoration: none;
            }
        }
    }

</style>

