<template>
  <v-dialog v-model="open" persistent scrollable max-width="800px" >
    <v-card class="pa-4">
        <v-card-title class="sticky">
          <h3>{{titleSalesChannel}} - Refine Quote</h3>
          <v-spacer></v-spacer>
          <v-btn v-if="salesChannel != salesChannelConst.VavePersonalLines" class="close-btn" color="secondary" type="button" icon small @click="close">
            <v-icon>fa-times</v-icon>
          </v-btn>
        </v-card-title>
        <br />
        <V2RefineDialogDigital v-if="salesChannel === salesChannelConst.VavePersonalLines" :salesChannel="salesChannel" :submissionId="submissionId" :policyKey="policyKey" @close="close"/>
        <LegacyRefineDialogDigital v-else :policyKey="policyKey" :salesChannel="salesChannel" :submissionId="submissionId" @close="close" />
      </v-card>
  </v-dialog>
</template>

<script>
import { SalesChannel } from '@/api/index.defs';
import LegacyRefineDialogDigital from '@/components/views/Quote/LegacyRefineDialogDigital.vue'
import V2RefineDialogDigital from '@/components/views/Quote/V2RefineDialogDigital.vue'

export default {
  name: "RefineDialogDigital",
  components: {
    LegacyRefineDialogDigital,
    V2RefineDialogDigital
  },
  props: {
    policyKey: String,
    salesChannel: String,
    submissionId: String
  },
  emits: [
    'close'
  ],
  data() {
    return {
      open: false,
      salesChannelConst: SalesChannel
    }
  },
  methods: {
    close() {
      this.$emit('close');
    }
  },
  computed: {
    titleSalesChannel() {
      switch(this.salesChannel) {
        case SalesChannel.Digital: return "Amwins Digital";
        case SalesChannel.Munich: return "Munich RE";
        case SalesChannel.VavePersonalLines: return "Vave Personal Lines";
        default: return "";
      }
    }
  },
  async mounted() {
    this.open = true;
  }
}
</script>

<style scoped lang="scss">
.actions {
  position: absolute;
  bottom: 0px;
  background-color: white;
  width: 90%;
}

.sticky {
  position: sticky;
}

.close-btn {
  position: absolute;
  right: 40px;
}

h3 {
  font-weight: bold;
}

h4 {
    color: rgba(0, 0, 0, 0.87);
    font-weight: bold;
}

hr {
  margin: 20px 0;
}


.premium {
  color: rgba(0, 0, 0, 0.87);
  font-weight: bold; 
  margin-bottom: 0; 
  display: inline-block;
  vertical-align: middle; 
}

::v-deep .dfc {
  margin-top: 4px;
  margin-bottom: 4px;
}

form {
  padding-bottom: 80px;
}

@media screen and (max-width: 768px) {
  form {
    padding-bottom: 150px;
  }
}

</style>