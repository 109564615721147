<template>
  <div>
    <v-container class="px-0">

      <v-row>
        <v-col cols="12" class="text-right">
          <PolicyServicingHeader :account="account" />
        </v-col>
      </v-row>
      <v-row class="d-flex align-center">
        <v-col cols="12" sm="9">

          <v-row>
            <v-col cols="12" sm="3" lg="2" class="d-flex align-center">
              <v-img :alt="account.SalesChannel"
                     class="shrink"
                     contain
                     :src="getSalesChannelLogo(account.SalesChannel, account.Market)"
                     width="150">
              </v-img>
            </v-col>

            <v-col class="d-none d-sm-flex justify-center" cols="1">
              <v-divider vertical></v-divider>
            </v-col>

            <v-col v-if="page == 1" cols="12" sm="3" lg="2">
              <ReadOnlyDisplay label="Premium Including Taxes & Fees" :val="premiumDisplay" />
            </v-col>

            <v-col v-if="page == 1" class="d-none d-sm-flex justify-center" cols="1">
              <v-divider vertical></v-divider>
            </v-col>

            <v-col v-if="page == 1" cols="12" sm="3">
              <ReadOnlyDisplay label="Term" :val="term" />
            </v-col>

            <v-col v-if="page == 2" cols="12" sm="8" lg="9">
              Please review the endorsement summary and updated premium then pay using one of the available payment
              methods. Your endorsement will not be in effect until the endorsement is submitted.
            </v-col>
          </v-row>

        </v-col>

        <v-col cols="12" sm="3" :align="documentButtonAlignment">
          <v-btn v-if="page == 2 && endorsement?.Endorsement?.EndorsementQuoteDocument?.FileReferenceId"
                 color="secondary"
                 type="button"
                 @click="openDocument"
                 outlined>
            <i class="fa-sharp fa-light fa-file-pdf"></i>&nbsp;&nbsp;&nbsp;Document
          </v-btn>
        </v-col>
      </v-row>

    </v-container>
  </div>
</template>

<script>
  import { getSalesChannelLogo, formatDateForDisplay, formatMoney } from '@/scripts/helper'
  import ReadOnlyDisplay from '@/components/form/ReadOnlyDisplay.vue'
  import PolicyServicingHeader from '@/components/views/Account/PolicyServicingHeader.vue'

  export default {
    name: 'EndorsementHeader',
    components: {
      ReadOnlyDisplay,
      PolicyServicingHeader
    },
    props: {
      account: {
        type: Object,
        default: null
      },
      policy: {
        type: Object,
        default: null
      },
      page: {
        type: Number,
        default: 1
      },
      premium: {
        type: Number,
        default: null
      },
    },
    data() {
      return {
      }
    },
    computed: {
      documentButtonAlignment() {
        switch (this.$vuetify.breakpoint.name) {
          case 'xs': return 'left'
          default: return 'right'
        }
      },
      term() {
        if (!this.policy || !this.policy.Policy || (!(this.policy.Policy.Header && this.policy.Policy.Header.EffectiveDate && this.policy.Policy.Header.ExpirationDate) &&
          !(this.policy.Policy.TermBegin != null && this.policy.Policy.TermEnd != null))) return ''

        if (this.policy.Policy.Header && this.policy.Policy.Header.EffectiveDate && this.policy.Policy.Header.ExpirationDate) {
          return formatDateForDisplay(this.policy.Policy.Header.EffectiveDate, true) + ' - ' + formatDateForDisplay(this.policy.Policy.Header.ExpirationDate, true);
        } else if (this.policy.Policy.TermBegin != null && this.policy.Policy.TermEnd != null) {
          return formatDateForDisplay(this.policy.Policy.TermBegin, true) + ' - ' + formatDateForDisplay(this.policy.Policy.TermEnd, true);
        } else {
          return ''
        }
      },    
      premiumDisplay() {
        if (!this.premium) return ''
        return this.formatMoney(this.premium, true) + ' / year'
      },
      endorsement() {
        return this.$store.getters['account/getEndorsement'];
      },
    },
    methods: {
      formatMoney,
      getSalesChannelLogo,
      formatDateForDisplay,
      openDocument() {
        this.$emit('document')
      }
    }
  }
</script>

<style scoped lang="scss">
  @import '@/style.scss';
</style>