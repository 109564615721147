<template>
  <div>
    <v-container :key="formId">
      <v-row v-if="form && !step.submitToGate">
        <v-col md="3" cols="12">
          <Timeline
            v-bind="{
              form,
              currentStepNumber,
              activeSectionNumber,
              module: this.module,
            }"
            @stepClick="stepClick"
          />
        </v-col>
        <v-col md="9" cols="12">
          <v-alert type="info" class="text-left" v-if="pending" :icon="false">
            <Loading
              :size="24"
              color="white"
              style="display: inline-block; margin-right: 10px"
            />
            Your submission is processing. You'll receive a notification when
            it's ready to fill out!
          </v-alert>

          <v-row>
            <v-col md="4" cols="12">
              <v-btn
                outlined
                v-if="!currentStepIsFirstVisibleStep()"
                class="go-back"
                @click="backToPreviousStep"
              >
                <i class="fas fa-chevron-left"></i>&nbsp;Go Back
              </v-btn>
            </v-col>
            <v-col md="8" cols="12" class="text-right">
              <slot name="admin"></slot>
              <QuoteProductHeader form="Quote" />
            </v-col>
          </v-row>

          <h1>
            {{ step.title }}
          </h1>
          <p>
            Required *
            <v-btn
              x-small
              color="secondary"
              @click="prefill"
              v-if="showPrefillButton"
              class="ml-2"
            >
              Prefill
            </v-btn>
            <SubmissionJson
              :submissionId="form.ResultId"
              class="ml-2"
              style="display: inline"
            />
          </p>

          <v-alert
            outlined
            type="success"
            text
            v-if="currentStepHasDataServiceValue"
          >
            By the way... to save us some time, we search through public records
            for info we need, and when found, it's outlined in a green box
            below. Please verify the info below and change anything if needed.
          </v-alert>

          <ValidationObserver tag="span" ref="observer">
            <form @submit.prevent="validate()">
              <div
                v-for="(section, index) in step.sections"
                :key="'section' + index"
              >
                <intersect
                  @enter="activeSectionNumber = index"
                  :key="'intersect' + index"
                  :threshold="[0.0]"
                  rootMargin="-50% 0px -50% 0px"
                  v-if="$store.getters.showSection('quoteForm', section)"
                >
                  <v-card
                    class="py-8 px-8 mb-8"
                    :class="{
                      'active-section-card': activeSectionNumber == index,
                    }"
                  >
                    <h3 v-if="section.title">{{ section.title }}</h3>
                    <p
                      v-if="section.subtitle"
                      class="pt-2"
                      v-html="section.subtitle"
                    ></p>
                    <span
                      v-for="(control) in section.controls"
                      :key="control.name + formId"
                    >
                      <DynamicFormControl
                        :control="control"
                        :module="module"
                        :disabled="disabled || pending"
                        v-if="showControl(control.name)"
                        @change="updateValue"
                      />
                    </span>
                  </v-card>
                </intersect>
              </div>
              <v-row v-if="badRiskAddress">
                <v-col cols="12">
                  <v-alert type="error" dense>
                    Please ensure you have entered a valid Risk Address.
                  </v-alert>
                </v-col>
              </v-row>
              <div class="text-right">
                <FormButton
                  type="button"
                  label="Save"
                  color="white"
                  @click="saveForLater"
                  :disabled="disabled || pending || loading || saving"
                ></FormButton>

                <v-btn
                  color="secondary"
                  large
                  type="submit"
                  :disabled="disabled || pending || loading || saving"
                  key="continue"
                  class="ml-4"
                >
                  <Loading v-if="loading" :size="24" class="mr-2" />
                  Continue
                </v-btn>
              </div>
              <br /><br /><br />
            </form>
          </ValidationObserver>
        </v-col>
      </v-row>

      <v-row v-if="form && step.submitToGate">
        <v-col cols="12">
          <v-row>
            <v-col md="4" cols="12">
              <v-btn
                outlined
                class="go-back mb-2"
                @click="showQuoteComparisonGridAreYouSureModal = true"
              >
                <i class="fas fa-chevron-left"></i>&nbsp;Go Back
              </v-btn>
              <SubmissionJson :submissionId="form.ResultId" class="mt-2" />
            </v-col>
            <v-col md="8" cols="12" class="text-right">
              <QuoteProductHeader form="Quote" /><br />
              <v-btn
                color="white"
                x-small
                type="button"
                :disabled="disabled"
                @click="editSubmission()"
              >
                <i class="fas fa-pen"></i>&nbsp; Edit Effective Date
              </v-btn>
            </v-col>
          </v-row>

          <!-- <h1 v-if="$store.getters.getControlValue('quoteForm', 'Risk')">Quotes for {{$store.getters.getControlValue('quoteForm', 'Risk').Formatted}}</h1> -->
          <br />
          <div v-if="!showQuoteComparisonGrid" key="loading">
            <br /><br />
            <Loading :size="64" class="text-center" />
          </div>
          <template v-else>
            <QuoteComparisonGrid
              :disabled="disabled || pending"
              :submissionId="form.ResultId"
              :coverageOption="getControlValue('CoverageOption')"
              :state="state"
              :submission="submission"
              @selectQuote="selectQuote"
              @getSubmission="getSubmission"
            />
          </template>

          <br /><br />
        </v-col>
      </v-row>
    </v-container>

    <EditSubmissionDialog
      v-if="showEditSubmissionDialog"
      @requote="requote"
      @close="showEditSubmissionDialog = false"
    >
    </EditSubmissionDialog>

    <AgentDialog
      v-if="showAgentDialog"
      :inactiveProducer="inactiveProducer"
      :inactiveProducerOrgBranchId="inactiveProducerOrgBranchId"
      @agent="updateAgent"
    >
    </AgentDialog>

    <QuoteComparisonGridAreYouSureDialog
      v-if="showQuoteComparisonGridAreYouSureModal"
      @agree="continueQuoteComparisonGridAreYouSureModal"
      @close="closeQuoteComparisonGridAreYouSureModal"
    >
    </QuoteComparisonGridAreYouSureDialog>
  </div>
</template>

<script>
import DynamicFormControl from "@/components/form/DynamicFormControl.vue";
import FormButton from "@/components/form/FormButton.vue";
import Intersect from "vue-intersect";
import Timeline from "@/components/shared/Timeline.vue";
import formMixin from "@/scripts/form-mixin";
import { GateService } from "@/api/GateService";
import { FormStateService } from "@/api/FormStateService";
import { SubmissionService } from "@/api/SubmissionService";
import { AgencyService } from "@/api/AgencyService";
import { AgentService } from "@/api/AgentService";
import QuoteComparisonGrid from "@/components/views/Quote/QuoteComparisonGrid.vue";
import QuoteProductHeader from "@/components/views/Quote/QuoteProductHeader.vue";
import {
  newGuid,
  HighProfileOccupations,
  setControlDataServiceValue,
getFormStateJSON,
} from "@/scripts/helper";
import { FormStateEvent, FormTemplateType } from "@/api/index.defs";
import { prefillQuoteForm, prefillFloodForm } from "@/scripts/prefills";
import EditSubmissionDialog from "@/components/views/Quote/EditSubmissionDialog.vue";
import AgentDialog from "@/components/views/Quote/AgentDialog.vue";
import SubmissionJson from "@/components/shared/SubmissionJson.vue";
import QuoteComparisonGridAreYouSureDialog from "@/components/views/Quote/QuoteComparisonGridAreYouSureDialog.vue";
import Environment from "@/scripts/environment";
import { agentAttestation } from '@/scripts/attestation-constants.js' 

export default {
  name: "Quote",
  emits: ["getSubmission"],
  components: {
    DynamicFormControl,
    FormButton,
    Intersect,
    Timeline,
    QuoteComparisonGrid,
    QuoteProductHeader,
    EditSubmissionDialog,
    AgentDialog,
    SubmissionJson,
    QuoteComparisonGridAreYouSureDialog,
  },
  mixins: [formMixin],
  data() {
    return {
      module: "quoteForm",
      showQuoteComparisonGrid: false,
      showEditSubmissionDialog: false,
      showAgentDialog: false,
      badRiskAddress: false,
      inactiveProducer: null,
      inactiveProducerOrgBranchId: null,
      showQuoteComparisonGridAreYouSureModal: false,
    };
  },
  props: {
    formInit: Object,
    mode: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    submission: {
      type: Object,
      default: null,
    },
  },
  computed: {
    pending() {
      return this.status == "Pending";
    },
    isDevMode() {
      return (
        Environment.environment == "localhost" ||
        Environment.environment == "development"
      );
    },
    state() {
      const risk = this.getControlValue("Risk");
      return risk?.State;
    },
  },
  methods: {
    getSubmission(ev) {
      this.$emit("getSubmission", ev);
    },
    newGuid() {
      return newGuid();
    },
    makeKey(name) {
      return name + this.newGuid();
    },
    clearAllDataServiceValues() {
      this.form.steps.forEach((step) => {
        if (!step.sections) return;
        step.sections.forEach((section) => {
          if (!section.controls) return;
          section.controls.forEach((control) => {
            setControlDataServiceValue(this.module, control.name, null, null);
          });
        });
      });
    },
    afterUpdateValue(name) {
      if (name == "Risk") {
        this.badRiskAddress = false;
        const risk = this.getControlValue("Risk");
        if (risk && risk.Locked) {
          this.clearAllDataServiceValues();
          this.requestDataService(FormStateEvent.RiskAddress);

          this.$store.commit("clearFormControlMetadata", {
            module: this.module,
            name: "CovA",
          });

          this.$store.commit("clearFormControlMetadata", {
            module: this.module,
            name: "CovAAttestation",
          });
        }
      } 
      else if (name == 'ConstructionType') {
        // clear out any previous CladdingType when the ConstructionType is changed
        this.updateValue({ 
          module: this.module,
          name: 'CladdingType', 
          value: null 
        });
        this.updateValue({ 
          module: this.module,
          name: 'CladdingType2', 
          value: null 
        });
        this.updateValue({ 
          module: this.module,
          name: 'CladdingType3', 
          value: null 
        });
      }
      else if (name == 'RoofMaterial' || name == "RoofMaterial2" || name == "RoofMaterial3") {
        // clear out any previous RoofMaterialDetails when the RoofMaterial is changed
        this.updateValue({ 
          module: this.module,
          name: 'RoofMaterialDetails', 
          value: null 
        });
        this.updateValue({ 
          module: this.module,
          name: 'RoofMaterialDetails2', 
          value: null 
        });
      }
      else if (name == "EffectiveYearBuilt") {
        const effectiveYearBuilt = this.getControlValue("EffectiveYearBuilt");
        if (!effectiveYearBuilt || effectiveYearBuilt === "") {
          this.enableControl("UtilityWiring");
          this.enableControl("UtilityWiringPartialOrComplete");
          this.enableControl("UtilityPlumbing");
          this.enableControl("UtilityPlumbingPartialOrComplete");
          this.enableControl("UtilityHVAC");
          this.enableControl("UtilityHVACPartialOrComplete");
        } else {
          this.updateValue({ name: "UtilityWiring", value: true });
          this.disableControl("UtilityWiring");
          const utilityWiringYearUpdated = this.getControlValue(
            "UtilityWiringYearUpdated"
          );
          if (
            !utilityWiringYearUpdated ||
            parseInt(effectiveYearBuilt) > parseInt(utilityWiringYearUpdated)
          ) {
            this.updateValue({
              name: "UtilityWiringYearUpdated",
              value: effectiveYearBuilt,
            });
            this.updateValue({
              name: "UtilityWiringPartialOrComplete",
              value: "Complete",
            });
            //this.disableControl('UtilityWiringPartialOrComplete');
            this.disableControlOption(
              "UtilityWiringPartialOrComplete",
              "Partial"
            );
          } else {
            this.enableControlOption(
              "UtilityWiringPartialOrComplete",
              "Partial"
            );
          }

          this.updateValue({ name: "UtilityPlumbing", value: true });
          this.disableControl("UtilityPlumbing");
          const utilityPlumbingYearUpdated = this.getControlValue(
            "UtilityPlumbingYearUpdated"
          );
          if (
            !utilityPlumbingYearUpdated ||
            parseInt(effectiveYearBuilt) > parseInt(utilityPlumbingYearUpdated)
          ) {
            this.updateValue({
              name: "UtilityPlumbingYearUpdated",
              value: effectiveYearBuilt,
            });
            this.updateValue({
              name: "UtilityPlumbingPartialOrComplete",
              value: "Complete",
            });
            //this.disableControl('UtilityPlumbingPartialOrComplete');
            this.disableControlOption(
              "UtilityPlumbingPartialOrComplete",
              "Partial"
            );
          } else {
            this.enableControlOption(
              "UtilityPlumbingPartialOrComplete",
              "Partial"
            );
          }
          this.updateValue({ name: "UtilityHVAC", value: true });
          this.disableControl("UtilityHVAC");
          const utilityHVACYearUpdated = this.getControlValue(
            "UtilityHVACYearUpdated"
          );
          if (
            !utilityHVACYearUpdated ||
            parseInt(effectiveYearBuilt) > parseInt(utilityHVACYearUpdated)
          ) {
            this.updateValue({
              name: "UtilityHVACYearUpdated",
              value: effectiveYearBuilt,
            });
            this.updateValue({
              name: "UtilityHVACPartialOrComplete",
              value: "Complete",
            });
            //this.disableControl('UtilityPlumbingPartialOrComplete');
            this.disableControlOption(
              "UtilityHVACPartialOrComplete",
              "Partial"
            );
          } else {
            this.enableControlOption(
              "UtilityHVACPartialOrComplete",
              "Partial"
            );
          }
        }
      } else if (name == "UtilityWiringYearUpdated") {
        setTimeout(() => {
          const effectiveYearBuilt = this.getControlValue("EffectiveYearBuilt");
          const utilityWiringYearUpdated = this.getControlValue(
            "UtilityWiringYearUpdated"
          );
          if (
            !utilityWiringYearUpdated ||
            !effectiveYearBuilt ||
            parseInt(effectiveYearBuilt) < parseInt(utilityWiringYearUpdated)
          ) {
            //this.enableControl('UtilityWiringPartialOrComplete');
            this.enableControlOption(
              "UtilityWiringPartialOrComplete",
              "Partial"
            );
          } else {
            this.updateValue({
              name: "UtilityWiringPartialOrComplete",
              value: "Complete",
            });
            //this.disableControl('UtilityWiringPartialOrComplete');
            this.disableControlOption(
              "UtilityWiringPartialOrComplete",
              "Partial"
            );
          } 
        }, 100);
      } else if (name == "UtilityPlumbingYearUpdated") {
        setTimeout(() => {
          const effectiveYearBuilt = this.getControlValue("EffectiveYearBuilt");
          const utilityPlumbingYearUpdated = this.getControlValue(
            "UtilityPlumbingYearUpdated"
          );
          if (
            !utilityPlumbingYearUpdated ||
            !effectiveYearBuilt ||
            parseInt(effectiveYearBuilt) < parseInt(utilityPlumbingYearUpdated)
          ) {
            //this.enableControl('UtilityPlumbingPartialOrComplete');
            this.enableControlOption(
              "UtilityPlumbingPartialOrComplete",
              "Partial"
            );
          } else {
            this.updateValue({
              name: "UtilityPlumbingPartialOrComplete",
              value: "Complete",
            });
            //this.disableControl('UtilityPlumbingPartialOrComplete');
            this.disableControlOption(
              "UtilityPlumbingPartialOrComplete",
              "Partial"
            );
          }
        }, 100);
      } else if (name == "UtilityHVACYearUpdated") {
        setTimeout(() => {
          const effectiveYearBuilt = this.getControlValue("EffectiveYearBuilt");
          const utilityHVACYearUpdated = this.getControlValue(
            "UtilityHVACYearUpdated"
          );
          if (
            !utilityHVACYearUpdated ||
            !effectiveYearBuilt ||
            parseInt(effectiveYearBuilt) < parseInt(utilityHVACYearUpdated)
          ) {
            //this.enableControl('UtilityPlumbingPartialOrComplete');
            this.enableControlOption(
              "UtilityHVACPartialOrComplete",
              "Partial"
            );
          } else {
            this.updateValue({
              name: "UtilityHVACPartialOrComplete",
              value: "Complete",
            });
            //this.disableControl('UtilityPlumbingPartialOrComplete');
            this.disableControlOption(
              "UtilityHVACPartialOrComplete",
              "Partial"
            );
          }
        }, 100);
      } else if (name == "Occupation") {
        const occupation = this.getControlValue("Occupation");
        if (
          occupation &&
          HighProfileOccupations.find(
            (o) => o.toLowerCase() == occupation.toLowerCase()
          )
        ) {
          this.updateValue({ name: "AnyOtherExposures", value: true });
          this.disableControl("AnyOtherExposures");
        } else {
          this.enableControl("AnyOtherExposures");
        }
      }
    },
    hasValidRisk() {
      const risk = this.getControlValue("Risk");
      if (!risk || !risk.RiskKey || !risk.State) {
        this.badRiskAddress = true;
        this.loading = false;
        return false;
      }
      return true;
    },
    validateStep() {
      if (
        this.getControlValue("CoverageOption")?.toLowerCase() != "flood" &&
        this.step.id == "insured"
      ) {
        return this.hasValidRisk();
      }
      return true;
    },
    async beforeStepChange(stepNumber) {
      const skipDataServices = this.form.steps[stepNumber].submitToGate;
      if (this.submission && this.submission.Complete != true) {
        if (this.step.id == "insured" && !skipDataServices) {
          await this.requestDataService(FormStateEvent.InsuredStep);
        } else if (this.step.id == "risk" && !skipDataServices) {
          await this.requestDataService(FormStateEvent.RiskStep);
        }
      }
      this.loading = false;
    },
    async requestDataService(eventType) {
      var eventId = newGuid();
      this.$store.commit("registerFormStateEvent", { contextId: this.submission.guid, eventType: eventType, eventId: eventId })
      await FormStateService.patch({
        eventType: eventType,
        eventId: eventId, //pass this to the server to have end-to-end tie out of state version
        body: this.form,
      })
    },
    onSubmit() {
      this.loading = true;
      this.saveForm().then(() => {
        this.afterSave();
      });
    },
    saveForLater() {
      this.saving = true;
      this.saveForm().then(() => {
        this.saving = false;
      });
    },
    saveForm() {
      const form = JSON.parse(
        JSON.stringify(this.$store.getters.getForm("quoteForm"))
      );
      if (form.id && this.mode != "Admin") {
        return FormStateService.patch({
          eventType: FormStateEvent.Save,
          body: form,
        });
      } else {
        return Promise.resolve();
      }
    },
    afterSave() {
      if (!this.step.submitToGate) {
        if (this.validateStep()) {
          this.proceedToNextStep();
        }
      }

      if (this.step.submitToGate) {
        this.submitToGate();
      }
    },
    submitToGate() {
      this.loading = true;
      this.$store.commit("quoteForm/clearQuotes");
      this.$store.commit("quoteForm/setGateTheSubmissionResponse", []);
      this.showQuoteComparisonGrid = false;

      FormStateService.patch({
        eventType: FormStateEvent.PreGate,
        body: this.form,
      }).then(() => {
        SubmissionService.get({
          id: this.form.ResultId,
        }).then((submission) => {
          this.gateTheSubmission(submission);
        });
      });
    },
    gateTheSubmission(submission) {
      GateService.gateTheSubmission({
        body: submission,
      })
        .then((data) => {
          this.loading = false;
          this.$store.commit("quoteForm/setGateTheSubmissionResponse", data);
          this.showQuoteComparisonGrid = true;
        })
        .catch(() => {
          this.loading = false;
          this.$store.commit("quoteForm/setGateTheSubmissionResponse", []);
          this.showQuoteComparisonGrid = true;
        });
    },
    selectQuote(event) {
      let templateType = null;
      if (event.value.SalesChannel) {
        switch (event.value.SalesChannel) {
          case "Vave":
          case "Hiscox":
          case "Chubb":
            templateType = "BindV1";
            break;
          case "VavePersonalLines":
            templateType = "BindV2";
            break;
          default:
            templateType = FormTemplateType[event.value.SalesChannel];
        }
      } else {
        return;
      }

      SubmissionService.selectQuote({
        submissionId: this.form.ResultId,
        quoteId: event.value.id,
      }).then((sub) => {
        if (!this.isDevMode) {
          if (
            !sub ||
            !sub.SelectedQuoteId ||
            sub.SelectedQuoteId != event.value.id
          ) {
            this.$store.dispatch("toastError", { Message: "Quote Not Found" });
            return;
          }
        }

        FormStateService.post({
          resultId: this.form.ResultId,
          body: { 
            FormState: getFormStateJSON(templateType)
          },
        }).then(() => {
          this.redirectToAdditionalForm();
        });
      });
    },
    prefill() {
      if (this.getControlValue("CoverageOption")?.toLowerCase() == "flood") {
        prefillFloodForm();
      } else {
        prefillQuoteForm();
      }
    },
    redirectToAdditionalForm() {
      this.$router.push({
        path: "/quote/additional",
        query: { id: this.form.ResultId },
      });
    },
    editSubmission() {
      this.showEditSubmissionDialog = true;
    },
    requote() {
      this.onSubmit();
    },
    async checkAgent() {
      const agent = this.getControlValue("Agent");
      const agency = this.getControlValue("Agency");

      let agentObj, agencyObj;

      //verify that agency is still active
      if (agency) {
        agencyObj = await AgencyService.get({ id: agency.id });
        if (!agencyObj || !agencyObj.Active) {
          this.inactiveProducer = agency.SeekDisplay;
        }
      }

      //verify that agent is still active
      if (agent) {
        agentObj = await AgentService.get({ id: agent.id });
        if (!agentObj || !agentObj.Active) {
          this.inactiveProducer = agent.SeekDisplay;
          if (agencyObj && agencyObj.Active) {
            this.inactiveProducerOrgBranchId = agencyObj.OrgBranchId;
          }
        }
      }

      //verify that agent is still assigned to the agency/orgbranch
      if (agentObj && agencyObj) {
        if (
          agent.OrgBranchId != agentObj.OrgBranchId ||
          agentObj.OrgBranchId != agencyObj.OrgBranchId
        ) {
          this.inactiveProducer = agent.SeekDisplay;
          this.inactiveProducerOrgBranchId = agency.OrgBranchId;
        }
      }

      if (!agent || !agency || this.inactiveProducer) {
        if (this.$store.getters.userHasRole("agent")) {
          if (this.inactiveProducerOrgBranchId) {
            this.showAgentDialog = true;
          } else {
            const human_id = this.$store.getters.getUserHumanId;
            const newAgent = await AgentService.get({ id: human_id });
            const newAgency = await AgencyService.get({
              id: newAgent.OrgBranchId,
            });
            if (!agency || newAgent.OrgBranchId == agency.OrgBranchId) {
              //if there is no agency, or if the agent user belongs to the agency, autofill
              this.updateValue({ name: "Agent", value: newAgent });
              this.updateValue({ name: "Agency", value: newAgency });
              this.saveForLater();
            } else {
              //if there is an agency but the agent user does not belong to it, show modal with dropdown
              if (agent) this.inactiveProducer = agent.SeekDisplay;
              this.inactiveProducerOrgBranchId = agency.OrgBranchId;
              this.showAgentDialog;
            }
          }
        } else {
          this.showAgentDialog = true;
        }
      }

      //If agent on submission is still active and has not been reappointed, update/fill/heal the SBIX node if necessary
      try {
        if (agent && agentObj && agent.Active && agentObj.Active) {
          if (
            (!agent.SBIX || !agent.SBIX.AgentKey || !agent.SBIX.AgencyKey) &&
            agentObj.SBIX &&
            agentObj.SBIX.AgentKey
          ) {
            if (
              agent.OrgBranchId == agentObj.OrgBranchId &&
              agent.OrgId == agentObj.OrgId
            ) {
              this.updateValue({ name: "Agent", value: agentObj });
              this.saveForLater();
            }
          }
        }
      } catch (err) {
        console.error(err);
      }
    },
    async updateAgent(agent) {
      const newAgency = await AgencyService.get({ id: agent.OrgBranchId });
      this.updateValue({ name: "Agent", value: agent });
      this.updateValue({ name: "Agency", value: newAgency });
      this.showAgentDialog = false;
      this.inactiveProducer = null;
      this.inactiveProducerOrgBranchId = null;
      this.saveForLater();
    },
    continueQuoteComparisonGridAreYouSureModal() {
      this.backToPreviousStep();
      this.showQuoteComparisonGridAreYouSureModal = false;
    },
    closeQuoteComparisonGridAreYouSureModal() {
      this.showQuoteComparisonGridAreYouSureModal = false;
    },
  },
  async mounted() {
    this.$store.commit("quoteForm/initQuoteForm", { ...this.formInit });
    this.setStepHash();

    if (this.formInit.data && this.formInit.ResultId) {
      await this.checkAgent();

      const sub = await SubmissionService.get({ id: this.formInit.ResultId });

      this.$store.commit("quoteForm/setGateTheSubmissionResponse", []);

      const quotes = sub.Quotes || [];

      // populate the hidden field for the agent attestation, which lives on the additional forms, but is needed for the quote document
      this.$store.commit('updateFormControl', {
        module: this.module,
        name: 'AgentAttestationText',
        value: agentAttestation
      });

      //Only land on Comparison Grid if a Human quote exists, to avoid doing so for imported Seasys records
      if (
        quotes &&
        quotes.length > 0
        //&& data.Quotes.find(q => q.SalesChannel == 'Human')
      ) {
        if (
          sub.SalesChannelGateResponses &&
          sub.SalesChannelGateResponses.length
        ) {
          this.$store.commit(
            "quoteForm/setGateTheSubmissionResponse",
            sub.SalesChannelGateResponses
          );
        } else {
          //Heal old Submissions that do not have SalesChannelGateResponses property by constructing off of Quotes
          const responses = quotes.map((i) => {
            return {
              SubmissionId: sub.id,
              SalesChannel: i.SalesChannel,
              Include: true,
            };
          });
          this.$store.commit(
            "quoteForm/setGateTheSubmissionResponse",
            responses
          );
        }

        this.stepClick(this.formInit.steps.length - 1);
        quotes.forEach((quote) => {
          this.$store.commit("quoteForm/addQuote", quote);
        });
        this.showQuoteComparisonGrid = true;
      }

      // handle pending logic grid job without request status?
      // if (this.formInit.CorrelationId && this.formInit.status == 'Pending') {
      //     RequestStatusService.get({
      //         correlationId: this.formInit.CorrelationId
      //     }).then((data) => {
      //         if (data.Status == LogicGridStepStatus.Complete || data.Status == LogicGridStepStatus.Error) {
      //             const form = {...this.formInit}
      //             form.status = 'Active';
      //             FormStateService.put({
      //                 body: form
      //             }).then(() => {
      //                 window.location.reload();
      //             })
      //         }
      //     })
      // }
    }

    if (this.$route.query.requote) {
      this.requote();
    }
  },
};
</script>

<style lang="scss">
</style>
