import {
  FormStateApiRequest,
  FormState,
  FormTemplateType,
  FormStateStatus,
  Any,
  FormStateRequest,
  IList,
  List,
  IListResult,
  ListResultDto,
  IPagedResult,
  PagedResultDto,
  Dictionary,
  IDictionary,
  IRequestOptions,
  IRequestConfig,
  getConfigs,
  axios,
  basePath
} from './index.defs';

export class FormStateService {
  /** Generate by swagger-axios-codegen */
  // @ts-nocheck
  /* eslint-disable */

  /**
   *
   */
  static post(
    params: {
      /**  */
      resultId?: string;
      /**  */
      body?: FormStateApiRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FormState> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/FormState';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { resultId: params['resultId'] };
      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static put(
    params: {
      /**  */
      accountId?: string;
      /**  */
      templateType?: any | null;
      /**  */
      formStateEvent?: any | null;
      /**  */
      body?: Any;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FormState> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/FormState';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);
      configs.params = {
        accountId: params['accountId'],
        templateType: params['templateType'],
        formStateEvent: params['formStateEvent']
      };
      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static patch(
    params: {
      /**  */
      eventType?: any | null;
      /**  */
      eventId?: string;
      /**  */
      body?: FormState;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/FormState';

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);
      configs.params = { eventType: params['eventType'], eventId: params['eventId'] };
      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delete(
    params: {
      /**  */
      formStateId?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/FormState';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { formStateId: params['formStateId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      resultId?: string;
      /**  */
      formStateId?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/FormState';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { resultId: params['resultId'], formStateId: params['formStateId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}
