import {
  Amwins_Portal_Api_Controllers_IssuedClaimsObj,
  System_Security_Claims_Claim,
  System_Security_Claims_ClaimsIdentity,
  Amwins_Portal_Api_Controllers_ImpersonationRequest,
  Amwins_Portal_Api_Controllers_UserInfoObj,
  IList,
  List,
  IListResult,
  ListResultDto,
  IPagedResult,
  PagedResultDto,
  Dictionary,
  IDictionary,
  IRequestOptions,
  IRequestConfig,
  getConfigs,
  axios,
  basePath
} from './index.defs';

export class IdentityService {
  /** Generate by swagger-axios-codegen */
  // @ts-nocheck
  /* eslint-disable */

  /**
   *
   */
  static authenticate(
    params: {
      /**  */
      body?: Amwins_Portal_Api_Controllers_IssuedClaimsObj;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<System_Security_Claims_Claim[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Identity/issuedClaims';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static impersonation(
    params: {
      /**  */
      body?: Amwins_Portal_Api_Controllers_ImpersonationRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Identity/impersonation';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static isActive(
    params: {
      /**  */
      email?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Amwins_Portal_Api_Controllers_UserInfoObj> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Identity/isActive';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { email: params['email'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}
