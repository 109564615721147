<template>
    <v-expansion-panels :readonly="readonly">
        <v-expansion-panel>
            <v-expansion-panel-header :hide-actions="readonly">
                <div class="d-flex align-center">
                    <Avatar 
                        :src="image" 
                        color="white" 
                        :alt="name" 
                        class="avatar mr-4" 
                        :defaultImage="label == 'Agency' ? '/images/placeholder-store.png' : null"
                    />
                    <div class="details">
                        <label>{{label}}</label>
                        <p class="ma-0">{{name}}</p>
                    </div>
                </div>
                
            </v-expansion-panel-header>
            <v-expansion-panel-content>
                <div class="expanded-details">
                    <slot></slot>
                </div>
            </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>
</template>

<script>
import Avatar from '@/components/shared/Avatar.vue'

export default {
    name: 'AccountMetaCard',
    components: {
        Avatar
    },
    data() {
        return {}
    },
    props: {
        image: String,
        label: String,
        name: String,
        readonly: {
            Type: Boolean,
            Default: false
        }
    }
}
</script>

<style scoped lang="scss">
@import '@/style.scss';

.details {
    label {
        color: $amwins-blue;
        font-weight: bold;
        font-size: 90%;
    }
}

::v-deep .expanded-details {
    p { margin-bottom: 0}
    
}
</style>
