<template>

  <Dialog :title="title" :persistent="true" :value="value" :scrollable="true" :width="`800px`" :loading="loading">
    <template v-slot:card-text>

      <v-alert v-if="isImpersonatingUser" dense class="impersonation-alert">
        Impersonating {{ currentUser.profile.sub }} |
        <a @click="stopUserImpersonation">Stop</a>
      </v-alert>
      <!--This seems pointless, but the rest of the dialog will not load without this v-else div. There must be some vuetify bug.-->
      <div v-else></div>

      <v-alert dense class="amlink-alert mb-4">
        {{ bannerMsg }}
      </v-alert>

      <ValidationObserver tag="div"
                          v-slot="{}"
                          ref="validator"
                          v-if="!amlinkRequestSent && !loading">
        <form @submit.prevent="submit">
          <h3 class="mb-4">
            We were unable to identify your account in our agency management system.
            Please provide additional information, so that we can get this resolved quickly.
          </h3>
          <div class="form-body relative grid grid-cols-1 md:grid-cols-3 gap-4">
            <div class="col-span-1 md:col-span-3">
              <v-row>
                <v-col cols="2">
                  <DynamicFormControl :control="{
                                  name: 'UserEmail',
                                  type: 'FormLabel',
                                  config: {
                                      label: 'Email:',
                                      col: 12,
                                      short: true
                                  }
                              }" />
                </v-col>
                <v-col cols="10">
                  <DynamicFormControl :control="{
                                  name: 'UserEmail',
                                  type: 'FormLabel',
                                  config: {
                                      label: userEmail,
                                      col: 12,
                                      short: true
                                  }
                              }" />
                </v-col>
              </v-row>
            </div>
            <div v-if="step === 1" class="col-span-1 md:col-span-3">
              <label class="mb-2 mt-2">Have you used another email while doing business with Amwins:</label>
              <DynamicFormControl :control="{
                                name: 'HasAmlinkEmail',
                                type: 'RadioButtonGroup',
                                value: hasAmlinkEmail,
                                config: {
                                    col: 12,
                                    small: true,
                                    options: hasAmlinkEmailOptions
                                }
                              }"
                                  @change="hasAmlinkEmail = $event.value" />
            </div>
            <div v-if="hasAmlinkEmail === 1 && step === 1" class="col-span-1 md:col-span-3">
              <label class="mb-2 mt-2">Please enter up to 5 of the emails you have used:</label>
              <template v-for="(item, index) in emailList">
                <v-row :key="index">
                  <v-col cols="11">
                    <DynamicFormControl :control="{
                                        name: 'EmailList' + index,
                                        type: 'TextInput',
                                        value: emailList[index],
                                        config: {
                                            label: 'Email',
                                            rules: 'email|required',
                                            col: 12,
                                            short: true
                                        }
                                    }"
                                        @change="emailList[index] = $event.value" />
                  </v-col>
                  <v-col cols="1">
                    <v-btn v-if="index != 0" class="mt-4" color="#9F2242" type="button" icon @click="removeEmailListItem(index)" title="Remove Item">
                      <v-icon>fa-times</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </template>
              <div v-if="emailList && emailList.length < 5" class="inline-flex flex-row-reverse relative mt-3 w-full h-full ">
                <v-btn color="secondary" @click="addEmailListItem">Add Another Email</v-btn>
              </div>
            </div>

            <div v-if="step === 2" class="col-span-1 md:col-span-3 mt3">
              <DynamicFormControl v-if="orgListOptions.length > 0"
                                  :control="{
                                  name: 'OrgList',
                                  type: 'RadioButtonGroup',
                                  value: selectedOrganization,
                                  config: {
                                      label: 'Organization',
                                      rules: 'required',
                                      options: orgListOptions,
                                      col: 12,
                                      required: true,
                                      short: true
                                  }
                              }"
                                  @change="selectedOrganizationChanged($event.value)" />
            </div>

            <div v-if="step === 2" class="col-span-1 md:col-span-3 mt3">
              <DynamicFormControl v-if="!otherOrganizationSelected && computedBranchListOptions.length > 0"
                                  :control="{
                                  name: 'BranchList',
                                  type: 'RadioButtonGroup',
                                  value: selectedBranch,
                                  config: {
                                      label: 'Branch',
                                      rules: 'required',
                                      options: computedBranchListOptions,
                                      col: 12,
                                      required: true,
                                      short: true
                                  }
                              }"
                                  @change="selectedBranch = $event.value" />
            </div>

            <div v-if="step === 2 && (orgListOptions.length < 1 || otherOrganizationSelected)" class="col-span-1 md:col-span-3 mt3">
              <DynamicFormControl :control="{
                                  name: 'OtherOrganizationName',
                                  type: 'TextInput',
                                  value: otherOrganization,
                                  config: {
                                      label: 'Organization Name',
                                      rules: 'required',
                                      col: 12,
                                      required: true,
                                      short: true
                                  }
                              }"
                                  @change="otherOrganization = $event.value" />
            </div>

            <div v-if="step === 2 && (orgListOptions.length < 1 || otherOrganizationSelected || otherBranchSelected)" class="col-span-1 md:col-span-3 mt3">
              <DynamicFormControl :control="{
                                  name: 'Address',
                                  type: 'Address',
                                  value: otherBranchAddress,
                                  config: {
                                      label: 'Branch Address',
                                      rules: 'required',
                                      required: true,
                                      short: true,
                                      addressType: 'MAILING'
                                  }
                              }"
                                  @change="otherBranchAddress = $event.value" />
            </div>

            <div v-if="step === 2">
              <DynamicFormControl :control="{
                                  name: 'Phone',
                                  type: 'TextInput',
                                  value: userPhone,
                                  config: {
                                      label: 'Phone Number',
                                      rules: 'phone|required',
                                      col: 12,
                                      short: true
                                  }
                              }"
                                  @change="userPhone = $event.value" />
            </div>

            <div v-if="step === 3">
              <v-row>
                <v-col cols="2">
                  <DynamicFormControl :control="{
                                  name: 'OrganizationLabel',
                                  type: 'FormLabel',
                                  config: {
                                      label: 'Organization:',
                                      col: 12,
                                      short: true
                                  }
                              }" />
                </v-col>
                <v-col cols="10">
                  <DynamicFormControl :control="{
                                  name: 'OrganizationLabelValue',
                                  type: 'FormLabel',
                                  config: {
                                      label: getUserOrgName(),
                                      col: 12,
                                      short: true
                                  }
                              }" />
                </v-col>
              </v-row>
            </div>

            <div v-if="step === 3">
              <v-row>
                <v-col cols="2">
                  <DynamicFormControl :control="{
                                  name: 'BranchLabel',
                                  type: 'FormLabel',
                                  config: {
                                      label: 'Branch:',
                                      col: 12,
                                      short: true
                                  }
                              }" />
                </v-col>
                <v-col cols="10">
                  <DynamicFormControl :control="{
                                  name: 'BranchLabelValue',
                                  type: 'FormLabel',
                                  config: {
                                      label: getUserBranchAddress(),
                                      col: 12,
                                      short: true
                                  }
                              }" />
                </v-col>
              </v-row>
            </div>

            <div v-if="step === 3">
              <v-row>
                <v-col cols="2">
                  <DynamicFormControl :control="{
                                  name: 'PhoneLabel',
                                  type: 'FormLabel',
                                  config: {
                                      label: 'Phone:',
                                      col: 12,
                                      short: true
                                  }
                              }" />
                </v-col>
                <v-col cols="10">
                  <DynamicFormControl :control="{
                                  name: 'PhoneLabelValue',
                                  type: 'FormLabel',
                                  config: {
                                      label: userPhone,
                                      col: 12,
                                      short: true
                                  }
                              }" />
                </v-col>
              </v-row>
            </div>

            <div v-if="step === 3">
              <DynamicFormControl :control="{
                                  name: 'UWNameLabel',
                                  type: 'TextInput',
                                  value: underwriterName,
                                  config: {
                                      label: 'Underwriter Name',
                                      col: 12,
                                      short: true
                                  }
                              }"
                                  @change="underwriterName = $event.value" />
            </div>

            <div v-if="step === 3">
              <DynamicFormControl :control="{
                                  name: 'UWNameLabelValue',
                                  type: 'TextInput',
                                  value: underwriterEmail,
                                  config: {
                                      label: 'Underwriter Email',
                                      rules: 'email',
                                      col: 12,
                                      short: true
                                  }
                              }"
                                  @change="underwriterEmail = $event.value" />
            </div>

            <div v-if="step === 3">
              <label class="mb-2 mt-2">Please provide an agency contact who can confirm your request:</label>
            </div>

            <div v-if="step === 3">
              <DynamicFormControl :control="{
                                  name: 'AgencyContactName',
                                  type: 'TextInput',
                                  value: agencyContactName,
                                  config: {
                                      label: 'Name',
                                      rules: 'required',
                                      col: 12,
                                      short: true
                                  }
                              }"
                                  @change="agencyContactName = $event.value" />
            </div>

            <div v-if="step === 3">
              <DynamicFormControl :control="{
                                  name: 'AgencyContactEmail',
                                  type: 'TextInput',
                                  value: agencyContactEmail,
                                  config: {
                                      label: 'Email',
                                      rules: 'required|email',
                                      col: 12,
                                      short: true
                                  }
                              }"
                                  @change="agencyContactEmail = $event.value" />
            </div>

            <div v-if="step === 3">
              <DynamicFormControl :control="{
                                  name: 'AgencyContactPhone',
                                  type: 'TextInput',
                                  value: agencyContactPhone,
                                  config: {
                                      label: 'Phone',
                                      rules: 'phone|required',
                                      col: 12,
                                      short: true
                                  }
                              }"
                                  @change="agencyContactPhone = $event.value" />
            </div>

            <div class="col-span-1 md:col-span-3 mt3">
              <v-row>
                <v-col v-if="step > 1" :cols="step > 1 ? 6 : 12" class="text-left">
                  <v-btn color="secondary" @click="previousStep">Back</v-btn>
                </v-col>
                <v-col v-if="step < 3" :cols="step > 1 && step < 3 ? 6 : 12" class="text-right">
                  <v-btn color="secondary" @click="nextStep">Next</v-btn>
                </v-col>
                <v-col v-if="step === 3" cols="6" class="text-right">
                  <v-btn color="secondary" @click="submit">Submit</v-btn>
                </v-col>
              </v-row>
            </div>

          </div>
        </form>
      </ValidationObserver>
      <div v-if="amlinkRequestSent && !loading" class="grid relative justify-center content-center text-center">
        <h3 class="text-base font-bold mb-3">{{ requestText }}</h3>
        <p class="text-sm">Please check back later</p>
      </div>
      <div v-if="loading">
        <Loading :size="100" class="text-center" />
      </div>
    </template>
  </Dialog>
  
</template>

<script>
  import Dialog from '@/components/shared/Dialog.vue'
  import { ValidationObserver } from 'vee-validate'
  import DynamicFormControl from '@/components/form/DynamicFormControl.vue'
  import { UserProfileService } from '@/api/UserProfileService';
  import Loading from '@/components/shared/Loading.vue'
  import Environment from '@/scripts/environment'
  import {endImpersonation} from '@/plugins/auth'

  export default {
    components: {
      DynamicFormControl,
      ValidationObserver,
      Dialog,
      Loading
    },
    props: {
      value: Boolean
    },
    name: 'AmlinkSignup',
    data() {
      return {
        title: "Amlink Signup Form",
        requestText: 'Your request is currently being reviewed',
        step: 1,
        bannerMsg: 'Due to high volume, requests may take up to 72 hours to be fulfilled. Please do not submit this form more than once and be assured that your request will be fulfilled as soon as we are able. We appreciate your patience.',
        hasAmlinkRequest: false,
        userPhone: '',
        emailList: [],
        hasAmlinkEmail: 0,
        hasAmlinkEmailOptions: [{
          value: 0,
          text: 'No'
        },
        {
          value: 1,
          text: 'Yes'
        }
        ],
        selectedOrganization: 0,
        fullOrgList: [],
        orgListOptions: [],
        selectedBranch: 0,
        branchListOptions: [],
        otherOrganization: '',
        otherOrganizationSelected: false,
        otherBranchSelected: false,
        otherBranchAddress: null,
        underwriterName: '',
        underwriterEmail: '',
        agencyContactName: '',
        agencyContactEmail: '',
        agencyContactPhone: '',
        loading: false,
      }
    },
    async mounted() {
      await this.getAmlinkRequest()
    },
    computed: {
      amlinkRequestSent() {
        return this.hasAmlinkRequest
      },
      userEmail() {
        return this.$store.getters.getUserEmail
      },
      computedBranchListOptions() {
        return this.branchListOptions
      },
      userLastName() {
        return this.$store.getters.getUserLastName
      },
      isImpersonatingUser() {
        return this.$store.getters.isImpersonatingUser ?? false
      },
      currentUser() {
        return this.$store.getters.getUser
      }
    },
    watch: {
      hasAmlinkEmail(val) {
        if (val === 1 && this.emailList.length === 0) {
          this.addEmailListItem()
        }
      },
      selectedBranch(val) {
        this.otherBranchSelected = this.branchListOptions.find(x => x.value === val)?.text === 'Other'
      }
    },
    methods: {
      async getAmlinkRequest() {
        this.loading = true
        await UserProfileService.getAmlinkSupportRequest()
          .then(result => {
            if (result && result?.id) {
              this.hasAmlinkRequest = true
            }
          }).finally(() => {
            this.loading = false
          })
      },
      async sendAmlinkRequest() {
        this.loading = true
        await UserProfileService.sendAmlinkSupportRequest({
          body: this.createSupportRequest()
        })
          .then(result => {
            if (result) {
              this.requestText = 'Thank you for submitting a request'
              this.hasAmlinkRequest = true
            }
          }).finally(() => {
            this.loading = false
          })
      },
      async submit() {
        const request = this.createSupportRequest()
        const requestValid = request.id && request.userPhone && request.userOrgName && request.userBranchAddress && request.agencyContactName && request.agencyContactEmail && request.agencyContactPhone
        const isValid = await this.isValid() && requestValid
        if (!isValid) {
          return
        }
        await this.sendAmlinkRequest()
      },
      async isValid() {
        return this.$refs.validator.validate()
      },
      createSupportRequest() {
        let request = {
          id: this.userEmail, // variable must be named id for storage in cosmos
          userPhone: this.userPhone,
          userOrgName: this.getUserOrgName(),
          userBranchAddress: this.getUserBranchAddress(),
          userPreviousEmails: this.getUserPreviousEmails(),
          underwriterName: this.underwriterName,
          underwriterEmail: this.underwriterEmail,
          agencyContactName: this.agencyContactName,
          agencyContactEmail: this.agencyContactEmail,
          agencyContactPhone: this.agencyContactPhone,
          userFirstName: this.$store.getters.getUserGivenName,
          userLastName: this.$store.getters.getUserLastName
        }
        return request
      },
      addEmailListItem() {
        if (this.emailList.length > 4) {
          return
        }
        this.emailList.push('')
      },
      removeEmailListItem(index) {
        this.emailList.splice(index, 1)
      },
      selectedOrganizationChanged(val) {
        this.selectedOrganization = val
        this.otherOrganizationSelected = this.orgListOptions.find(x => x.value === val)?.text === 'Other'

        // fetch updated branch list for the selected org
        this.getBranchList()
      },
      getBranchList() {
        this.branchListOptions = []
        const selectedOrgName = this.orgListOptions.find(x => x.value === this.selectedOrganization)?.text
        const filteredOrgList = this.fullOrgList.filter(x => x.OrgName === selectedOrgName)
        filteredOrgList.forEach((item) => {
          this.branchListOptions.push({
            value: this.branchListOptions.length,
            text: item.BranchName + ` [${item.BranchEnabled && item.OrgBranchEnabled ? 'Active' : 'Inactive'}]`
          })
        })

        if (this.branchListOptions.length > 0) {
          this.branchListOptions.push({
            value: this.branchListOptions.length,
            text: 'Other'
          })
          this.selectedBranch = 0
        }
      },
      getUserOrgName() {
        return this.otherOrganizationSelected || this.orgListOptions.length < 1 ? this.otherOrganization : this.orgListOptions.find(x => x.value === this.selectedOrganization)?.text
      },
      getUserPreviousEmails() {
        return this.fullOrgList.filter(x => x.OrgEnabled).map(x => x.HumanEmail)
      },
      getUserBranchAddress() {
        if (this.otherBranchSelected || this.otherOrganizationSelected || this.orgListOptions.length < 1) {
          return this.otherBranchAddress.Formatted
        }

        const branchName = this.branchListOptions.find(x => x.value === this.selectedBranch).text.replace(' [Active]', '').replace(' [Inactive]', '')
        if (branchName) {
          const amlinkRecord = this.fullOrgList.find(x => x.OrgName === this.getUserOrgName() && x.BranchName === branchName)
          if (amlinkRecord) {
            return this.formatAddress(amlinkRecord)
          }
        }

        return ''
      },
      formatAddress(address) {
        if (!address) {
          return ''
        }

        let a = '';
        a += address.BranchPhysicalAddress1;
        if (address.BranchPhysicalAddress2) a += ' ' + address.BranchPhysicalAddress2;
        if (address.BranchPhysicalAddress3) a += ' ' + address.BranchPhysicalAddress3;
        if (address.BranchPhysicalAddress4) a += ' ' + address.BranchPhysicalAddress4;
        a += ', ';
        a += (address.BranchPhysicalCity ? address.BranchPhysicalCity + ', ' : '') + address.BranchPhysicalStateCd + ' ' + address.BranchPhysicalZipCode + ' ' + address.BranchPhysicalCountryCode;
        return a;
      },
      async getAmlinkDataForEmails() {
        // reset arrays
        this.fullOrgList = []
        this.orgListOptions = []
        let promises = []

        if (this.hasAmlinkEmail) {
          this.loading = true
          this.emailList.forEach((email) => {
            promises.push(UserProfileService.amlinkHistory({
              email: email
            })
              .then(async (result) => {
                if (result) {
                  result.forEach((amlinkRecord) => {
                    // only display amlink orgs that are active and where the amlink record match the user's last name
                    if (this.userLastName === amlinkRecord.HumanLastName) {
                      // add amlink record to this array for use in populating branches
                      this.fullOrgList.push(amlinkRecord)
                      if (amlinkRecord.OrgEnabled) {
                        this.orgListOptions.push({
                          value: this.orgListOptions.length,
                          text: amlinkRecord.OrgName
                        })
                      }
                    }
                  })
                }
              }))
          })
          await Promise.all(promises);

          const otherOrgExists = this.orgListOptions.find(x => x.text === 'Other')
          if (!otherOrgExists && this.hasAmlinkEmail && this.emailList.length === this.orgListOptions.length) {
            this.orgListOptions.push({
              value: this.orgListOptions.length,
              text: 'Other'
            })
          }
        }

        // get amlink branch records based on orgs
        this.getBranchList()

        this.loading = false

      },
      async previousStep() {
        if (this.step > 1) {
          this.step--
        }
      },
      async nextStep() {
        const isValid = await this.isValid()
        if (!isValid) {
          return
        }

        if (this.step < 3) {
          this.step++
        }

        if (this.step === 2) {
          await this.getAmlinkDataForEmails()
        }
      },
      stopUserImpersonation() {
        endImpersonation();
        const redirectUrl = sessionStorage.getItem('returnPath') ? `${Environment.UI_HOST}${sessionStorage.getItem('returnPath')}` : `${Environment.UI_HOST}/dashboard`
        window.location = redirectUrl
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "@/style.scss";

  .impersonation-alert.v-alert a {
    text-decoration: underline;
  }

  .impersonation-alert.v-alert {
    margin-bottom: 0;
    border-radius: 0;
    color: $gray1;
    text-align: center;
    font-weight: bold;
    background-color: $tertiary-orange;
    white-space: nowrap;
    width: 100%;
    display: block;
  }

  .amlink-alert.v-alert {
    margin-bottom: 0;
    background-color: $tertiary-yellow;
    color: $gray1;
    font-weight: bold;
    width: 100%;
    display: block;
  }

  ::v-deep button > span {
    color: white !important;
  }


</style>
