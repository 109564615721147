var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Dialog',{attrs:{"title":_vm.jsonModalTitle,"value":_vm.showModal,"width":"90%"},on:{"close-dialog":_vm.closeModal},scopedSlots:_vm._u([{key:"card-text",fn:function(){return [(_vm.messageDetail)?_c('div',[(_vm.modalMode == 'Exception')?_c('div',[_c('h4',[_vm._v("An error occured in the euler app while indexing this message")]),_c('p',{staticClass:"pt-4"},[_vm._v(_vm._s(_vm.messageDetail))])]):(_vm.messageName == 'ExternalIntegrationEventV1' || _vm.messageName == 'ExternalIntegrationEvent')?_c('ExternalIntegrationMessage',{attrs:{"message":_vm.messageDetail}}):_c('SubmissionMonaco',{attrs:{"submission":_vm.messageDetail}})],1):_c('div',[_c('Loading',{staticClass:"text-center",attrs:{"size":64}})],1)]},proxy:true},{key:"card-actions",fn:function(){return [_c('v-btn',{attrs:{"color":"secondary","text":"","type":"button"},on:{"click":_vm.closeModal}},[_vm._v("Close")])]},proxy:true}])}),_c('v-container',[_c('h1',[_vm._v("Message Tracing")]),_c('v-card',{staticClass:"mb-4"},[_c('v-card-title',[_vm._v(" Lookup ")]),_c('v-card-text',[_c('ValidationObserver',{ref:"observer1",attrs:{"tag":"span"},scopedSlots:_vm._u([{key:"default",fn:function({}){return [_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.lookupSubmission.apply(null, arguments)}}},[_c('v-row',[_c('v-col',{attrs:{"md":"6","lg":"4"}},[_c('DynamicFormControl',{staticStyle:{"margin-top":"0px"},attrs:{"control":{
                                                name: 'Submission Number',
                                                type: 'TextInput',
                                                value: _vm.submissionNumber,
                                                config: {
                                                    label: 'Submission Number',
                                                    rules: 'required',
                                                    col: 12,
                                                    short: true,
                                                    clearable: true,
                                                    ignoreDataServiceValue: true
                                                }
                                              }},on:{"change":function($event){_vm.submissionNumber = $event.value},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.lookupSubmission.apply(null, arguments)}}})],1),_c('v-col',{attrs:{"md":"6","kg":"8"}},[_c('FormButton',{attrs:{"label":"Submit","size":"x-large","disabled":_vm.loading},on:{"click":_vm.lookupSubmission}})],1)],1)],1)]}}])})],1)],1),_c('v-card',{staticClass:"mb-4",attrs:{"loading":_vm.loading}},[_c('v-card-title',[_vm._v(" Trace Results ")]),_c('v-card-text',[_c('ResultsTable',{attrs:{"tableHeaders":_vm.indexTable,"results":_vm.indexData,"loading":_vm.loading,"sortBy":"IngestedAt","sortDesc":true},scopedSlots:_vm._u([{key:"IngestedAt",fn:function({ item }){return [_c('span',[_vm._v(" "+_vm._s(_vm.formatDateTimeForDisplay(item.IngestedAt))+" ")])]}},{key:"CreatedDate",fn:function({ item }){return [_c('span',[_vm._v(" "+_vm._s(_vm.formatDateTimeForDisplay(item.CreatedDate, true))+" ")])]}},{key:"MessageName",fn:function({ item }){return [_c('span',[_vm._v(" "+_vm._s(item.MessageName)+" ")])]}},{key:"Channel",fn:function({ item }){return [_c('span',[_vm._v(" "+_vm._s(item.Channel)+" ")])]}},{key:"Integration",fn:function({ item }){return [_c('span',[_vm._v(" "+_vm._s(item.Integration)+" ")])]}},{key:"Endpoint",fn:function({ item }){return [_c('span',[_vm._v(" "+_vm._s(item.Endpoint)+" ")])]}},{key:"MessageId",fn:function({ item }){return [_c('span',[_vm._v(" "+_vm._s(item.MessageId)+" ")])]}},{key:"Actions",fn:function({ item }){return [(item.HadException)?_c('v-btn',{attrs:{"x-small":"","color":"red"},on:{"click":function($event){return _vm.openExceptionModal(item)}}},[_vm._v(" Read Exception ")]):_c('v-btn',{attrs:{"x-small":"","color":"secondary"},on:{"click":function($event){return _vm.openJsonModal(item)}}},[_vm._v(" Message JSON ")])]}}])})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }