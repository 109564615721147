var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-container',[_c('h1',[_vm._v(" Flood Reinstatment ")]),_c('v-row',[_c('v-col',{staticClass:"text-right",attrs:{"cols":"12","md":"8"}},[_c('PolicyServicingHeader',{attrs:{"account":_vm.account}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('v-card',{staticClass:"py-8 px-8 mb-8"},[_c('ValidationObserver',{ref:"observer",attrs:{"tag":"span"}},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.validate()}}},[_c('DynamicFormControl',{attrs:{"control":{
                                    name: 'ReinstatementReason',
                                    type: 'Dropdown',
                                    value: _vm.reinstatementReason,
                                    config: {
                                        label:'What is the reason for reinstating the policy?',
                                        col: 12,
                                        small: true,
                                        options: _vm.reinstatementReasons,
                                        rules: 'required',
                                        short: true,
                                        itemText: 'text',
                                        itemValue: 'value',
                                    }
                                  }},on:{"change":function($event){_vm.reinstatementReason = $event.value}}}),(_vm.reinstatementReason === 'Other')?_c('DynamicFormControl',{attrs:{"control":{
                                      name: 'ReinstatementReasonOther',
                                      value: _vm.reinstatementReasonOther,
                                      type: 'FormTextArea',
                                      config: {
                                          label: 'Other Reinstatement Reason',
                                          rules: 'required',
                                          short: true
                                      }
                                  }},on:{"change":function($event){_vm.reinstatementReasonOther = $event.value}}}):_vm._e(),_c('DynamicFormControl',{attrs:{"control":{
                                      name: 'TermEndDate',
                                      value: _vm.termEndDate,
                                      type: 'TextInput',
                                      config: {
                                          label: 'Term End Date',
                                          short: true,
                                          disabled: true
                                      }
                                  }}}),_c('div',{staticClass:"text-right"},[_c('v-btn',{staticClass:"ml-4",attrs:{"color":"white","large":"","disabled":_vm.loading},on:{"click":_vm.returnToAccount}},[(_vm.loading)?_c('Loading',{staticClass:"mr-2",attrs:{"size":24}}):_vm._e(),_vm._v(" Cancel ")],1),_c('v-btn',{staticClass:"ml-4",attrs:{"color":"secondary","large":"","type":"submit","disabled":_vm.loading}},[(_vm.loading)?_c('Loading',{staticClass:"mr-2",attrs:{"size":24}}):_vm._e(),_vm._v(" Submit ")],1)],1)],1)])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }