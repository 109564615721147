const v2PersonalLinesEndorsementConfig = {
  "editableFields": [
    "EndorsementEffectiveDate",
    "RiskHOA",
    "RiskHOACondo",
    "MailingAddressSame",
    "MailingAddress",
    "CustomerType",
    "DomicileCountry",
    "FirstName",
    "LastName",
    "DateOfBirth",
    "Occupation",
    "CoApplicant",
    "CoApplicantFirstName",
    "CoApplicantLastName",
    "CoApplicantDateOfBirth",
    "CoApplicantRelationship",
    "Occupancy",
    "OccasionallyRented",
    "PropertyManagementCompanyInvolved",
    "RentalTerm",
    "RentalPeriodInMonths",
    "RentalPeriodInWeeks",
    "RentalPeriodInDays",
    "DwellingForSale",
    "DwellingForSale12Months",
    "NumberOfFamilies",
    "BuildingType",
    "BuildingType2",
    "BuildingTypeCondo",
    "MultiplexCount",
    "FoundationType",
    "ConstructionType",
    "CladdingType",
    "CladdingType2",
    "CladdingType3",
    "UnitStory",
    "DwellingStories",
    "SquareFeet",
    "YearBuilt",
    "EffectiveYearBuilt",
    "UtilityWiring",
    "UtilityWiringYearUpdated",
    "UtilityWiringPartialOrComplete",
    "UtilityPlumbing",
    "UtilityPlumbingYearUpdated",
    "UtilityPlumbingPartialOrComplete",
    "UtilityHVAC",
    "UtilityHVACYearUpdated",
    "UtilityHVACPartialOrComplete",
    "FrameFoundationConnection",
    "ArchitecturalElements",
    "RoofShape",
    "RoofMaterial",
    "RoofMaterial2",
    "RoofMaterial3",
    "RoofMaterialDetails",
    "RoofMaterialDetails2",
    "RoofUpdated",
    "RoofYearUpdated",
    "RoofPartialOrComplete",
    "RoofModifiers",
    "RoofAnchor",
    "RoofDeckAttachment",
    "SecondaryWaterResistance",
    "ProtectionClass",
    "Within1kFtOfWaterSource",
    "FireDeptWithin10Min",
    "FireDeptResponseTime",
    "AccessibleByRoadYearRound",
    "OccupiedOrCheckedDaily",
    "VisibleToNeighborsOrCheckedDaily",
    "Protections",
    "Protections2",
    "BurglarAlarmType",
    "FireAlarmType",
    "SprinklersMonitoredByCentralStation",
    "SprinklersMonitoredByCentralStation2",
    "AutomaticWaterShutOffType",
    "HurricaneProtectionType",
    "GlazedOpeningProtection",
    "NonGlazedOpeningProtection",
    "LossesInPast5Years",
    "LossesInPast5YearsList",
    "CommonlyOccurringLabel",
    "ExposureSwimmingPool",
    "ExposureSwimmingPoolLocation",
    "ExposureSwimmingPoolDivingBoardOrSlide",
    "ExposureTrampoline",
    "ExposureTrampolineProtection",
    "ExposureAnimals",
    "ExposureAnimalsBiteHistory",
    "ExposureLand",
    "ExposureLandAcres",
    "AnyOtherExposures",
    "OtherRisksAssociated",
    "ValuationAttestation",
    "Coverages",
    "CovE",
    "CovLdp3",
    "CovF",
    "CovMdp3",
    "Liability",
    "WindPeril",
    "WindPerilApply",
    "AllPerilDeductible",
    "WindPerilDeductible",
    "FloodPeril",
    "FloodPerilDeductible",
    "EarthquakePeril",
    "EarthquakePerilDeductible",
    "EarthquakeTuckUnderParking",
    "EarthquakeYearRetrofittedToCode",
    "EarthquakeMasonryVeneer",
    "WaterDamagePeril",
    "DifferenceInConditions",
    "LossAssessmentLimit",
    "Mortgagee1",
    "Mortgagee1Company",
    "Mortgagee1Suffix",
    "Mortgagee1LoanNumber",
    "Mortgagee1Invoice",
    "Mortgagee2",
    "Mortgagee2Company",
    "Mortgagee2Suffix",
    "Mortgagee2LoanNumber",
    "AdditionalInterests",
    "AdditionalInterestsList"
  ],
  "agentEditableFields": [
    "EndorsementEffectiveDate",
    "RiskHOA",
    "RiskHOACondo",
    "MailingAddressSame",
    "MailingAddress",
    "CoApplicant",
    "CoApplicantFirstName",
    "CoApplicantLastName",
    "CoApplicantDateOfBirth",
    "CoApplicantRelationship",
    "Occupancy",
    "OccasionallyRented",
    "PropertyManagementCompanyInvolved",
    "RentalTerm",
    "RentalPeriodInMonths",
    "RentalPeriodInWeeks",
    "RentalPeriodInDays",
    "DwellingForSale",
    "DwellingForSale12Months",
    "Coverages",
    "CovE",
    "CovLdp3",
    "CovF",
    "CovMdp3",
    "Liability",
    "AllPerilDeductible",
    "WindPerilDeductible",
    "FloodPerilDeductible",
    "EarthquakePerilDeductible",
    "WaterDamagePeril",
    "LossAssessmentLimit",
    "Mortgagee1",
    "Mortgagee1Company",
    "Mortgagee1Suffix",
    "Mortgagee1LoanNumber",
    "Mortgagee1Invoice",
    "Mortgagee2",
    "Mortgagee2Company",
    "Mortgagee2Suffix",
    "Mortgagee2LoanNumber",
    "AdditionalInterests",
    "AdditionalInterestsList"
  ],
  "fieldsToHide": [
    "WaterDistance",
    "WindPoolEligible",
    "FloodZone",
    "RiskGeolocation",
    "LavaZone",
    "InspectionContactPhone",
    "OccupancyDetails",
    "Vacant12Months",
    "DwellingUnderRenovation",
    "GroundUpCOC",
    "EarthquakeTuckUnderParking",
    "EarthquakeYearRetrofittedToCode",
    "EarthquakeMasonryVeneer",
    "WindPerilDeductible"
  ],
  "sectionsToRemove": [
    "Disclosure",
    "Optional Coverages Made Easy",
    "Diligent Effort",
    "Inspections",
    "Payment",
    "Underwriter",
    "Agent Attestation",
    "Communication"
  ],
  "sectionsToRemoveByToggle": [
    {
      "label": "Insured Portal",
      "toggle": "ePayPaymentOptions"
    }
  ],
  "sectionsWithNoAlert": [
    "Additional Interests"
  ],
  "postGateFields": [
    {
      "salesChannel": "VavePersonalLines",
      "controls": [
        {
          "name": "Theft",
          "type": "TextInput",
          "v2DataPath": "Coverages.Theft.value",
          "coverageOptions": ['HO3', 'HO5', 'HO6']
        },
        {
          "name": "AdditionalTheft",
          "type": "Dropdown",
          "v2DataPath": "Coverages.AdditionalTheft.value",
          "coverageOptions": ["HO3", "HO5", "HO6"]
        },
        {
          "name": "BroadTheft",
          "type": "Dropdown",
          "v2DataPath": "Coverages.BroadTheft.value",
          "coverageOptions": ["DP3"]
        },
        {
          "name": "LimitedTheft",
          "type": "Dropdown",
          "v2DataPath": "Coverages.LimitedTheft.value",
          "coverageOptions": [ "DP3" ]
        },
        {
          "name": "TheftExclusion",
          "type": "TextInput",
          "v2DataPath": "Coverages.TheftExclusion.value",
          "coverageOptions": ["HO3", "HO5", "HO6"]
        },
        {
          "name": "GolfCartPhysicalDamage",
          "type": "Dropdown",
          "v2DataPath": "Coverages.GolfCartPhysicalDamage.value",
          "coverageOptions": [ "HO3", "HO5", "HO6", "DP3" ]
        },
        {
          "name": "GreenUpgrades",
          "type": "Dropdown",
          "v2DataPath": "Coverages.GreenUpgrades.value",
          "coverageOptions": [ "HO3", "HO5", "HO6" ]
        },
        {
          "name": "HO6ExtensionCoverage",
          "type": "Dropdown",
          "v2DataPath": "Coverages.HO6ExtensionCoverage.value",
          "coverageOptions": [ "HO6" ]
        },
        {
          "name": "IdentityFraudExp",
          "type": "Dropdown",
          "v2DataPath": "Coverages.IdentityFraudExp.value",
          "coverageOptions": [ "HO3", "HO5", "HO6" ]
        },
        {
          "name": "InflationGuard",
          "type": "PercentIncrementer",
          "v2DataPath": "Coverages.InflationGuard.value",
          "coverageOptions": [ "HO3", "HO5", "HO6", "DP3" ],
          "value": 0
        },
        {
          "name": "BuildingOrdinanceOrLaw",
          "type": "Dropdown",
          "v2DataPath": "Coverages.BuildingOrdinanceOrLaw.value",
          "coverageOptions": [ "HO3", "HO5", "HO6", "DP3" ]
        },
        {
          "name": "RefrigeratedGoods",
          "type": "Dropdown",
          "v2DataPath": "Coverages.RefrigeratedGoods.value",
          "coverageOptions": [ "HO3", "HO5", "HO6" ]
        },
        {
          "name": "SpecialComputerCover",
          "type": "Dropdown",
          "v2DataPath": "Coverages.SpecialComputerCover.value",
          "coverageOptions": [ "HO3", "HO6" ]
        },
        {
          "name": "ReplacementCostStructures",
          "type": "Dropdown",
          "v2DataPath": "Coverages.ReplacementCostStructures.value",
          "coverageOptions": [ "HO3", "HO5" ]
        },
        {
          "name": "ReplacementCostContents",
          "type": "Dropdown",
          "v2DataPath": "Coverages.ReplacementCostContents.value",
          "coverageOptions": [ "HO3", "HO5", "HO6" ]
        },
        {
          "name": "WaterBackup",
          "type": "TextInput",
          "v2DataPath": "Coverages.WaterBackup.value",
          "coverageOptions": [ "HO3", "HO5", "DP3" ]
        },
        {
          "name": "MechanicalBreakdownCover",
          "type": "Dropdown",
          "v2DataPath": "Coverages.MechanicalBreakdownCover.value",
          "coverageOptions": [ "HO3", "HO5", "HO6", "DP3" ]
        },
      ]
    }
  ]
}

const v2FloodEndorsementConfig = {}

export const v2CoverageModelTypeMapping = {
  "SubmissionCoverageV0": v2PersonalLinesEndorsementConfig,
  "CoverageV1": v2FloodEndorsementConfig
}