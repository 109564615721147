<template>
    <div>

      <v-app-bar v-if="initialOidcUserLoaded" app color="#00B3E3" :height="headerHeight">

        <div class="d-flex flex-column align-baseline" style="width: 100%;">

          <v-alert v-if="isTestEnvironment" dense class="news-alert" style="background-color: #FFA001;">
            Test Environment 
            <v-tooltip bottom :max-width="300" color="#555555" style="vertical-align: top;">
              <template v-slot:activator="{ on, attrs }">
                  <v-btn style="height: 20px; width: 20px; color: #333333; position: relative; top: -2px;"
                      class="tooltip"
                      dark
                      icon
                      v-bind="attrs"
                      v-on="on"
                  >
                      <i class="fas fa-question-circle" style="font-size: 16px;"></i>
                  </v-btn>
              </template>
              <span>All transactions done on this website are for testing/development purposes only. Any submitted apps, endorsements, cancellations, etc. are not actual submissions and will not be honored or processed.</span>
            </v-tooltip>
          </v-alert>

          <v-alert v-if="isCurrentMoratorium" dense class="news-alert">
            Moratoriums -  {{currentMoratoriums }} |
            <router-link to="/resources/catastrophe-closings" style="color: inherit;">Get Details</router-link>
          </v-alert>

          <v-alert v-if="isImpersonatingUser" dense class="impersonation-alert">
            Impersonating {{ currentUser.profile.sub }} |
            <a @click="stopUserImpersonation">Stop</a>
          </v-alert>

          <v-container style="padding: 0;">
            <div class="d-flex align-center header-content">

              <v-app-bar-nav-icon v-if="isMobile"
                                  @click.stop="showMobileDrawer = !showMobileDrawer">
              </v-app-bar-nav-icon>




              <v-btn text x-large router-link to="/dashboard" exact>
                <v-img alt="Amwins Access"
                       class="shrink"
                       contain
                       src="@/assets/images/logo-white.png"
                       width="130" />
              </v-btn>

              <div v-if="!isMobile">

                <v-btn text router-link to="/dashboard">
                  <i class="fas fa-th-large"></i>&nbsp;
                  Dashboard
                </v-btn>

                <v-menu close-on-content-click open-on-click offset-y right v-model="showMenuResources">
                  <template v-slot:activator="{on,attrs}">
                    <v-btn text v-bind="attrs" v-on="on">
                      <i class="far fa-lightbulb"></i>&nbsp;
                      Resources
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item>
                      <router-link to="/resources/news">News</router-link>
                    </v-list-item>
                    <v-list-item>
                      <router-link to="/resources/catastrophe-closings">Catastrophe Closings</router-link>
                    </v-list-item>
                    <v-list-item>
                      <router-link to="/resources/documents">Documents</router-link>
                    </v-list-item>
                    <!--Commented out per Jerome. We may bring this back later-->
                    <!--<v-list-item>
              <a href="#">Knowledge Base</a>
            </v-list-item>-->
                  </v-list>
                </v-menu>

                <v-btn text to="/search">
                  <i class="fas fa-search"></i>&nbsp;
                  Search
                </v-btn>

                <v-menu close-on-content-click open-on-click offset-y right v-model="showMenuAdmin" v-if="isAdmin">
                  <template v-slot:activator="{on,attrs}">
                    <v-btn text v-bind="attrs" v-on="on">
                      <i class="fas fa-tools"></i>&nbsp;
                      Admin
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item>
                      <router-link to="/admin/gates">Gate Rules</router-link>
                    </v-list-item>
                    <v-list-item>
                      <router-link to="/admin/form-templates">Form Templates</router-link>
                    </v-list-item>
                    <v-list-item>
                      <a @click.prevent="launchHangfire()" href="#">Hangfire Dashboard</a>
                    </v-list-item>
                    <v-list-item>
                      <router-link to="/admin/renewal-processing">Renewal Processing</router-link>
                    </v-list-item>
                  </v-list>
                </v-menu>
                
                <v-menu v-if="isAdminOrSupport" close-on-content-click open-on-click offset-y right v-model="showSupportTools">
                  <template v-slot:activator="{on,attrs}">
                    <v-btn text v-bind="attrs" v-on="on">
                      <i class="fas fa-toolbox"></i>&nbsp;
                      Support Tools 
                      <span class="health-check-icon" :class="getHealthCheckIconClass"></span>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item>
                      <router-link to="/support-tools/lookup">Lookup <BetaFeatureTag class="d-inline-block pb-4" /></router-link>
                    </v-list-item>
                    <v-list-item v-if="isTestEnvironment">
                      <router-link to="/support-tools/agency-nonprod-access">Agency Non-Prod Access</router-link>
                    </v-list-item>
                    <v-list-item v-if="isTestEnvironment">
                      <router-link to="/support-tools/account-teleporter">Account Teleporter</router-link>
                    </v-list-item>
                    <v-list-item v-if="isTestEnvironment">
                      <router-link to="/support-tools/munich-lifecycle">Munich Lifecycle</router-link>
                    </v-list-item>
                    <v-list-item v-if="showRestrictedFeatureAdmin">
                      <router-link to="/support-tools/restricted-features">Restricted Features</router-link>
                    </v-list-item>
                    <v-list-item v-if="showAgentUnderwriterReassignment">
                      <router-link to="/support-tools/agent-underwriter-reassignment">Agent Underwriter Reassignment</router-link>
                    </v-list-item>
                    <v-list-item>
                      <router-link to="/support-tools/partition-management">Partition Management</router-link>
                    </v-list-item>
                    <v-list-item>
                      <router-link to="/support-tools/quote-errors">Quote Errors</router-link>
                    </v-list-item>
                    <v-list-item>
                      <router-link to="/support-tools/message-tracing">Message Tracing</router-link>
                    </v-list-item>
                    <v-list-item>
                      <router-link to="/support-tools/message-queue-admin">Message Queue Admin</router-link>
                    </v-list-item>
                    <v-list-item>
                      <router-link to="/support-tools/graph-demo">Graph Demo</router-link>
                    </v-list-item>
                    <v-list-item>
                      <router-link to="/support-tools/health-checks">
                        Health Checks
                        <span class="health-check-icon" :class="getHealthCheckIconClass"></span>
                      </router-link>
                    </v-list-item>
                  </v-list>
                </v-menu>

              </div>

              <v-spacer></v-spacer>

              <router-link to="/quote/start" class="new-submission-button">
                <v-btn class="ml-4" color="white" x-large>New Submission</v-btn>
              </router-link>

              <div class="ml-6" v-if="showUWAvatar">
                <UnderwriterMenu />
              </div>

              <div class="ml-6">
                <v-menu close-on-content-click open-on-click offset-y left v-model="showMenuUser" nudge-bottom="10">
                  <template v-slot:activator="{on,attrs}">
                    <v-btn v-bind="attrs" v-on="on" icon width="56" height="56">
                      <Avatar :src="$store.getters.getUserAvatarPath"
                              :alt="$store.getters.getUserName" class="avatar" />
                    </v-btn>
                  </template>

                  <v-list style="width: 300px;">
                    <v-list-item style="width: 100%;">
                      <div class="d-flex mb-2" style="width: 100%;">
                        <h4>{{$store.getters.getUserName}}</h4>
                        <v-spacer></v-spacer>
                        <v-btn icon @click="showMenuUser=false" style="float: right;" x-small height="20" width="20">
                          <v-icon color="black">fa-times</v-icon>
                        </v-btn>
                      </div>
                    </v-list-item>
                    <v-list-item v-if="isAdminOrSupport || isAgent">
                      <router-link to="/agency" style="width: 100%">My Agency</router-link>
                    </v-list-item>
                    <v-list-item>
                      <router-link to="/profile" style="width: 100%">My Profile</router-link>
                    </v-list-item>
                    <v-list-item v-if="!isImpersonatingUser && !isExternalUser">
                      <a :href="'https://'+changePasswordLink" style="width: 100%">Change Password</a>
                    </v-list-item>
                    <v-list-item v-if="isAdminOrSupport && !isImpersonatingUser">
                      <a @click="showUserImpersonationDialog = true" style="width: 100%">User Impersonation</a>
                    </v-list-item>
                    <v-list-item v-if="isImpersonatingUser">
                      <a @click="stopUserImpersonation" style="width: 100%">Stop Impersonating User</a>
                    </v-list-item>
                    <v-list-item>
                      <a href="#" @click="$store.dispatch('logout')" style="width: 100%">Logout</a>
                    </v-list-item>

                    <!-- <div id="nav">
                      <router-link to="/">Dashboard</router-link> |
                      <router-link to="/quote/start">Start Quote</router-link> |
                      <router-link to="/quote/quote">Quote</router-link> |
                      <router-link to="/claims/claim">Claim</router-link> |
                      <router-link to="/design">Design</router-link>
                    </div> -->
                  </v-list>

                </v-menu>
              </div>
            </div>
          </v-container>

        </div>
      </v-app-bar>

      <v-navigation-drawer v-if="isMobile"
        v-model="showMobileDrawer"
        fixed
        left
        style="z-index: 200;"
        class="mobile-menu"
      >
        <div class="text-center">
          <v-btn text x-large router-link to="/dashboard" exact color="white" class="mt-2">
            <v-img
              alt="Amwins Access"
              class="shrink"
              contain
              src="@/assets/images/logo-white.png"
              width="130"
            />
          </v-btn>
        </div>

        <v-list>
          <v-list-item>
            <router-link to="/dashboard">
              <i class="fas fa-th-large"></i>&nbsp;Dashboard
            </router-link>
          </v-list-item>

          <v-list-item>
            <i class="far fa-lightbulb"></i>&nbsp;Resources
          </v-list-item>

          <v-list-item class="mobile-menu-indented">
            <router-link to="/resources/news">News</router-link>
          </v-list-item>

          <v-list-item class="mobile-menu-indented">
            <router-link to="/resources/catastrophe-closings">Catastrophe Closings</router-link>
          </v-list-item>

          <v-list-item class="mobile-menu-indented">
            <router-link to="/resources/documents">Documents</router-link>
          </v-list-item>

          <v-list-item>
            <router-link to="/search">
              <i class="fas fa-search"></i>&nbsp;Search
            </router-link>
          </v-list-item>

          <template v-if="isAdmin">
            <v-list-item>
              <i class="fas fa-tools"></i>&nbsp;Admin
            </v-list-item>

            <v-list-item class="mobile-menu-indented">
              <router-link to="/admin/gates">Gate Rules</router-link>
            </v-list-item>

            <v-list-item class="mobile-menu-indented">
              <router-link to="/admin/form-templates">Form Templates</router-link>
            </v-list-item>

            <v-list-item class="mobile-menu-indented">
              <a @click.prevent="launchHangfire()" href="#">Hangfire Dashboard</a>
            </v-list-item>

            <v-list-item class="mobile-menu-indented">
              <router-link to="/admin/renewal-processing">Renewal Processing</router-link>
            </v-list-item>
          </template>
          
          <template v-if="isAdminOrSupport">
            <v-list-item>
              <i class="fas fa-toolbox"></i>&nbsp;Support Tools
            </v-list-item>

            <v-list-item class="mobile-menu-indented">
              <router-link to="/support-tools/lookup">Lookup <BetaFeatureTag class="d-inline-block pb-4" /></router-link>
            </v-list-item>

            <v-list-item class="mobile-menu-indented" v-if="isTestEnvironment">
              <router-link to="/support-tools/agency-nonprod-access">Agency Non-Prod Access</router-link>
            </v-list-item>

            <v-list-item class="mobile-menu-indented" v-if="isTestEnvironment">
              <router-link to="/support-tools/account-teleporter">Account Teleporter</router-link>
            </v-list-item>
            
            <v-list-item class="mobile-menu-indented" v-if="isTestEnvironment">
              <router-link to="/support-tools/munich-lifecycle">Munich Lifecycle</router-link>
            </v-list-item>

            <v-list-item class="mobile-menu-indented" v-if="showRestrictedFeatureAdmin">
              <router-link to="/support-tools/restricted-features">Restricted Features</router-link>
            </v-list-item>

            <v-list-item class="mobile-menu-indented" v-if="showAgentUnderwriterReassignment">
              <router-link to="/support-tools/agent-underwriter-reassignment">Agent Underwriter Reassignment</router-link>
            </v-list-item>

            <v-list-item class="mobile-menu-indented">
              <router-link to="/support-tools/partition-management">Partition Management</router-link>
            </v-list-item>
            <v-list-item class="mobile-menu-indented">
              <router-link to="/support-tools/quote-errors">Quote Errors</router-link>
            </v-list-item>
            <v-list-item class="mobile-menu-indented">
              <router-link to="/support-tools/message-tracing">Message Tracing</router-link>
            </v-list-item>
            <v-list-item class="mobile-menu-indented">
              <router-link to="/support-tools/message-queue-admin">Message Queue Admin</router-link>
            </v-list-item>

            <v-list-item class="mobile-menu-indented">
                      <router-link to="/support-tools/graph-demo">Graph Demo</router-link>
            </v-list-item>
            <v-list-item class="mobile-menu-indented">
                      <router-link to="/support-tools/health-checks">Health Checks</router-link>
            </v-list-item>
          </template>

        </v-list>

        <div class="text-center">
          <router-link to="/quote/start">
            <v-btn color="white" x-large>New Submission</v-btn>
          </router-link>
        </div>

        

      </v-navigation-drawer>

      <UserImpersonation :value="showUserImpersonationDialog"
                         @close-dialog="showUserImpersonationDialog = false"/>
    </div>
    
</template>

<script>
  import Avatar from '@/components/shared/Avatar.vue'
  import isMobileMixin from '@/scripts/is-mobile-mixin'
  import UserImpersonation from '@/components/views/Admin/UserImpersonationDialog.vue'
  import UnderwriterMenu from '@/components/shared/UnderwriterMenu.vue'
  import BetaFeatureTag from '@/components/shared/BetaFeatureTag.vue'
  import { endImpersonation } from '@/plugins/auth';
  import { IdentityService } from '@/api/IdentityService'
  import { MoratoriumService } from '@/api/MoratoriumService'
  import Environment from '@/scripts/environment'
  import { parseJwt, jwtToUser } from '@/scripts/helper'
  import { FeatureFlagsService } from '@/api/FeatureFlagsService'
  import AxoisInstances from '@/plugins/axios'
  import { getTokenAsync } from '@/plugins/auth'


  export default {
    name: 'TheHeader',
    components: {
      Avatar,
      UserImpersonation,
      UnderwriterMenu,
      BetaFeatureTag
    },
    mixins: [
      isMobileMixin
    ],
    data() {
      return {
        showMenuUser: false,
        showMenuResources: false,
        showMenuAdmin: false,
        showUWAvatar: false,
        showMobileDrawer: false,
        showUserImpersonationDialog: false,
        showSupportTools: false
       
      }
    },
    computed: {
      getHealthCheckIconClass() {
        //  getter for OverallHealthStatus in health store
        const overallHealthStatus = this.$store.getters['health/overallHealthStatus'];

        // logic to determine the appropriate class based on OverallHealthStatus
        if (overallHealthStatus === 'Healthy') {
          return 'good-health-icon';
        } else if (overallHealthStatus === 'Degraded') {
          return 'warning-health-icon';
        } else {
          return 'critical-health-icon';
        }
      },
    
      headerHeight() {
        let height = 64;
        if (this.isTestEnvironment) height += 40;
        if (this.isCurrentClosing) height += 40;
        if (this.isCurrentMoratorium) height += 40;
        if (this.isImpersonatingUser) height += 40;
        return height;
      },
      isTestEnvironment() {
        return Environment.environment != 'prod';
      },
      currentCatastropheClosings() {
        const closings = this.$store.getters['resource/currentClosings'];

        //only return unique catastrophe names for display
        var closingText = closings.map(x => x.CatastropheName)
          .filter((value, index, self) => { return self.indexOf(value) === index})
          .join(", ");

        return closingText
      },
      currentMoratoriums() {
        const bannerDisplayText = this.$store.getters['resource/moratoriumBannerDisplayText'];
        return bannerDisplayText;
      },
      isCurrentMoratorium() {
        const isClosing = this.$store.getters['resource/isCurrentMoratorium'];
        return isClosing;
      },
      isImpersonatingUser() {
        return this.$store.getters.isImpersonatingUser
      },
      isExternalUser() {
        return this.$store.getters.isExternalUser
      },
      currentUser() {
        return this.$store.getters.getUser
      },
      isAdminOrSupport() {
        return (this.$store.getters.getUserRole == 'admin' || this.$store.getters.getUserRole == 'support');
      },
      isAdmin() {
        return this.$store.getters.getUserRole == 'admin';
      },
      isAgent() {
        return this.$store.getters.getUserRole == 'agent';
      },
      showRestrictedFeatureAdmin() {
        return this.$store.getters.userHasRestrictedFeature('RestrictedFeatures_ManageUsers');
      },
      showAgentUnderwriterReassignment() {
        return this.$store.getters.userHasRestrictedFeature('AgentUnderwriterReassignment');
      },

      changePasswordLink()
      {
        return `${Environment.STS_DOMAIN.replace('https://', '')}/Account/ResetPassword`;
      },
      initialOidcUserLoaded() {
        return this.$store.getters.getUser && this.$route.path !== '/callback'
      }
    },
    created() {
      if (this.$router.currentRoute.name == 'Dashboard')
        this.showUWAvatar = false;
      else
        this.showUWAvatar = true;

      this.$router.afterEach((to) => {
        this.showMenuAdmin = this.showMenuUW = this.showMenuResources = this.showMenuUser = this.showSupportTools = false;

        if (to.name == 'Dashboard')
          this.showUWAvatar = false;
        else
          this.showUWAvatar = true;
      }); 

    },
    watch: {
    },
    methods: {
      stopUserImpersonation() {
        endImpersonation();
      },
      async launchHangfire(){

        const url = `${Environment.API_HOST}/hangfire?access_token=${await getTokenAsync()}`;
        window.location.href  = url;
      }

    }
}
</script>

<style scoped lang="scss">
@import '@/style.scss';

.health-check-icon {
    width: 10px;
    height: 10px;
    display: inline-block;
    margin-right: 8px;
    border-radius: 50%;
  }

  .good-health-icon {
    background-color: green;
  }

  .warning-health-icon {
    background-color: yellow;
  }

  .critical-health-icon {
    background-color: red;
  }

.news-alert.v-alert {
  margin-bottom: 0;
  border-radius: 0;
  color: $gray1;
  text-align: center;
  font-weight: bold;
  background-color: $tertiary-yellow;
  white-space: nowrap;
  width: 100%;
  display: block;
}

.news-alert.v-alert a, .impersonation-alert.v-alert a {
  text-decoration: underline;
}

.impersonation-alert.v-alert {
  margin-bottom: 0;
  border-radius: 0;
  color: $gray1;
  text-align: center;
  font-weight: bold;
  background-color: $tertiary-orange;
  white-space: nowrap;
  width: 100%;
  display: block;
}

::v-deep .v-toolbar__content {
  padding: 0;
}

.header-content {
  padding: 4px 8px;
  width: 100%;
  height: 64px;
}

::v-deep .v-toolbar__content, .v-toolbar__extension {
  align-items: baseline;
}

.header-content .v-btn--active::before {
  opacity: 0;
}

a {
  text-decoration: none;
}

.v-menu__content a {
  color: $amwins-secondary-medium-blue;
}

.mobile-menu-indented {
  padding-left: 30px;
}

.mobile-menu svg {
  width: 20px;
  text-align: center;
  display: inline-block;
}

@media screen and (max-width: 500px) {
  .new-submission-button {display: none;}
}

@media screen and (max-width: 1264px) {
  .header-content .v-btn:not(.v-btn--round).v-size--default {
    height: 24px;
    padding: 0 5px;
  }
}

</style>