import {
  Amwins_Portal_Api_Services_AggregateHealthChecks,
  Amwins_Portal_Api_Services_AppHealthCheck,
  Amwins_Portal_Api_Services_OrbitsUIHealthReport,
  Amwins_Portal_Api_Services_OrbitsUIHealthReportEntry,
  IList,
  List,
  IListResult,
  ListResultDto,
  IPagedResult,
  PagedResultDto,
  Dictionary,
  IDictionary,
  IRequestOptions,
  IRequestConfig,
  getConfigs,
  axios,
  basePath
} from './index.defs';

export class HealthCheckService {
  /** Generate by swagger-axios-codegen */
  // @ts-nocheck
  /* eslint-disable */

  /**
   *
   */
  static healthChecks(options: IRequestOptions = {}): Promise<Amwins_Portal_Api_Services_AggregateHealthChecks> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/HealthChecks';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updateCache(options: IRequestOptions = {}): Promise<Amwins_Portal_Api_Services_AggregateHealthChecks> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/UpdateCache';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static removeApp(
    params: {
      /**  */
      appName?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Amwins_Portal_Api_Services_AggregateHealthChecks> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/RemoveApp';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { appName: params['appName'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}
