// @ts-nocheck
import Vue from 'vue'
import VueRouter, {RouteConfig} from 'vue-router'
import store from '@/vuex/store';
import axios from 'axios'
import {waitForAuthenticationAsync} from '@/plugins/auth'
import {DEFAULT_TITLE} from '@/scripts/helper'
import Quote from '@/components/views/Quote/Quote.vue'
import Additional from '@/components/views/Quote/Additional.vue'
import SubmissionConfirmation from '@/components/views/Quote/SubmissionConfirmation.vue'
import Start from '@/components/views/Quote/Start.vue'
import Design from '@/components/views/Design/Design.vue'
import Dashboard from '@/components/views/Dashboard.vue'
import Search from '@/components/views/Search/Search.vue'
import Account from '@/components/views/Account/Account.vue'
import Endorsement from '@/components/views/Account/Endorsement.vue'
import Cancellation from '@/components/views/Account/Cancellation.vue'
import Reinstatement from '@/components/views/Account/Reinstatement.vue'
import FloodCancellation from '@/components/views/Account/FloodCancellation.vue'
import FloodReinstatement from '@/components/views/Account/FloodReinstatement.vue'
import Gates from '@/components/views/Admin/Gates.vue'
import Gate from '@/components/views/Admin/Gate.vue'
import FormTemplates from '@/components/views/Admin/FormTemplates.vue'
import FormTemplate from '@/components/views/Admin/FormTemplate.vue'
import PartitionManagement from '@/components/views/SupportTools/PartitionManagement.vue'
import RenewalProcessing from '@/components/views/Admin/RenewalProcessing.vue'
import Lookup from '@/components/views/SupportTools/Lookup.vue'
import AgencyNonprodAccess from '@/components/views/SupportTools/AgencyNonprodAccess.vue'
import AccountTeleporter from '@/components/views/SupportTools/AccountTeleporter.vue'
import MunichLifecycle from '@/components/views/SupportTools/MunichLifecycle.vue'
import RestrictedFeatures from '@/components/views/SupportTools/RestrictedFeatures.vue'
import QuoteErrors from '@/components/views/SupportTools/QuoteErrors.vue'
import MessageTracing from '@/components/views/SupportTools/MessageTracing.vue'
import AgentUnderwriterReassignment from '@/components/views/SupportTools/AgentUnderwriterReassignment.vue'
import CatastropheClosings from '@/components/views/Resources/CatastropheClosings.vue'
import Agency from '@/components/views/Agency/Agency.vue'
import NewsList from '@/components/views/Resources/NewsList.vue'
import NewsArticle from '@/components/views/Resources/NewsArticle.vue'
import Callback from '@/components/oidc/Callback.vue'
import Unauthorized from '@/components/views/Unauthorized.vue'
import Profile from '@/components/views/Profile/Profile.vue'
import Documents from '@/components/views/Resources/Documents.vue'
import AccountRequirements from '@/components/views/Account/AccountRequirements.vue'
import AccountServicing from '@/components/views/Account/AccountServicing.vue'
import Epay from '@/components/form/Epay.vue'
import {UserProfileService} from '@/api/UserProfileService'
import hub from '@/plugins/webapp-hub.js'
import MessageQueueAdmin from '@/components/views/SupportTools/MessageQueueAdmin.vue'
import MessageQueueDeadLetters from '@/components/views/SupportTools/MessageQueueDeadLetters.vue'
import GraphDemo from '@/components/views/SupportTools/GraphDemo.vue'
import AmlinkSubmissionFile from '@/components/views/SupportTools/AmlinkSubmissionFile.vue'
import HealthCheck from '@/components/views/SupportTools/HealthCheck.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    redirect: '/dashboard'
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    meta: {
      title: 'Dashboard',
      requiresAuth: true,
      confirmProfile: true
    }
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile,
    meta: {
      title: 'Profile',
      requiresAuth: true
    }
  },
  {
    path: '/search',
    name: 'Search',
    component: Search,
    meta: {
      title: 'Search',
      requiresAuth: true,
      confirmProfile: true
    }
  },
  {
    path: '/account',
    name: 'Account',
    component: Account,
    meta: {
      title: 'Account',
      requiresAuth: true,
      confirmProfile: true
    }
  },
  {
    path: '/account/endorsement',
    name: 'Endorsement',
    component: Endorsement,
    meta: {
      title: 'Endorsement',
      requiresAuth: true,
      confirmProfile: true
    }
  },
  {
    path: '/account/cancellation',
    name: 'Cancellation',
    component: Cancellation,
    meta: {
      title: 'Cancellation',
      requiresAuth: true,
      confirmProfile: true
    }
  },
  {
    path: '/account/reinstatement',
    name: 'Reinstatement',
    component: Reinstatement,
    meta: {
      title: 'Reinstatement',
      requiresAuth: true,
      confirmProfile: true
    }
  },
  {
    path: '/account/flood-cancellation',
    name: 'FloodCancellation',
    component: FloodCancellation,
    meta: {
      title: 'FloodCancellation',
      requiresAuth: true,
      confirmProfile: true
    }
  },
  {
    path: '/account/flood-reinstatement',
    name: 'FloodReinstatement',
    component: FloodReinstatement,
    meta: {
      title: 'FloodReinstatement',
      requiresAuth: true,
      confirmProfile: true
    }
  },
  {
    path: '/account/requirements',
    name: 'AccountRequirements',
    component: AccountRequirements,
    meta: {
      title: 'Requirements',
      requiresAuth: true,
      confirmProfile: true
    }
  },
  {
    path: '/account/servicing',
    name: 'AccountServicing',
    component: AccountServicing,
    meta: {
      title: 'Servicing',
      requiresAuth: true,
      confirmProfile: true
    }
  },
  {
    path: '/quote/start',
    name: 'StartQuote',
    component: Start,
    meta: {
      title: 'New Submission',
      requiresAuth: true,
      confirmProfile: true
    },
  },
  {
    path: '/quote/quote',
    name: 'Quote',
    component: Quote,
    meta: {
      title: 'Submission',
      requiresAuth: true,
      confirmProfile: true
    },
  },
  {
    path: '/quote/additional',
    name: 'Additional',
    component: Additional,
    meta: {
      title: 'Submission Additional Questions',
      requiresAuth: true,
      confirmProfile: true
    }
  },
  {
    path: '/quote/confirmation',
    name: 'Confirmation',
    component: SubmissionConfirmation,
    meta: {
      title: 'Submission Confirmation',
      requiresAuth: true,
      confirmProfile: true
    }
  },
  {
    path: '/admin/gates',
    name: 'Gates',
    component: Gates,
    meta: {
      title: 'Gates',
      requiresAuth: true,
      confirmProfile: true,
      roles: ['admin']
    }
  },
  {
    path: '/admin/gate',
    name: 'Gate',
    component: Gate,
    meta: {
      title: 'Gate',
      requiresAuth: true,
      confirmProfile: true,
      roles: ['admin']
    }
  },
  {
    path: '/admin/form-templates',
    name: 'Form Templates',
    component: FormTemplates,
    meta: {
      title: 'Form Templates',
      requiresAuth: true,
      confirmProfile: true,
      roles: ['admin']
    }
  },
  {
    path: '/admin/form-template',
    name: 'Form Template',
    component: FormTemplate,
    meta: {
      title: 'Form Template',
      requiresAuth: true,
      confirmProfile: true,
      roles: ['admin']
    }
  },
  {
    path: '/support-tools/partition-management',
    name: 'PartitionManagement',
    component: PartitionManagement,
    meta: {
      title: 'Partition Management',
      requiresAuth: true,
      confirmProfile: true,
      roles: ['admin', 'support']
    }
  },
  {
    path: '/admin/renewal-processing',
    name: 'RenewalProcessing',
    component: RenewalProcessing,
    meta: {
      title: 'Renewal Processing',
      requiresAuth: true,
      confirmProfile: true,
      roles: ['admin']
    }
  },
  {
    path: '/support-tools/lookup',
    name: 'Lookup',
    component: Lookup,
    meta: {
      title: 'Lookup',
      requiresAuth: true,
      confirmProfile: true,
      roles: ['admin', 'support']
    }
  },
  {
    path: '/support-tools/agency-nonprod-access',
    name: 'AgencyNonprodAccess',
    component: AgencyNonprodAccess,
    meta: {
      title: 'AgencyNonprodAccess',
      requiresAuth: true,
      confirmProfile: true,
      roles: ['admin', 'support']
    }
  },
  {
    path: '/support-tools/account-teleporter',
    name: 'AccountTeleporter',
    component: AccountTeleporter,
    meta: {
      title: 'AccountTeleporter',
      requiresAuth: true,
      confirmProfile: true,
      roles: ['admin', 'support']
    }
  },
  {
    path: '/support-tools/munich-lifecycle',
    name: 'MunichLifecycle',
    component: MunichLifecycle,
    meta: {
      title: 'MunichLifecycle',
      requiresAuth: true,
      confirmProfile: true,
      roles: ['admin', 'support']
    }
  },
  {
    path: '/epay/:version',
    name: 'Epay',
    component: Epay,
    meta: {
      title: 'Epay',
      requiresAuth: false,
      confirmProfile: true,
      isCallback: true
    },
    props: route => ({ version: route.params.version })
  },
  {
    path: '/support-tools/restricted-features',
    name: 'RestrictedFeatures',
    component: RestrictedFeatures,
    meta: {
      title: 'Restricted Features',
      requiresAuth: true,
      confirmProfile: true,
    }
  },
  {
    path: '/support-tools/quote-errors',
    name: 'QuoteErrors',
    component: QuoteErrors,
    meta: {
      title: 'Quote Errors',
      requiresAuth: true,
      confirmProfile: true,
    }
  },
  {
    path: '/support-tools/message-tracing',
    name: 'MessageTracing',
    component: MessageTracing,
    meta: {
      title: 'Message Tracing',
      requiresAuth: true,
      confirmProfile: true,
      props: true,
      roles: ['admin', 'support']
    }
  },
  {
    path: '/support-tools/message-tracing/:submissionId',
    name: 'MessageTracing_Submission',
    component: MessageTracing,
    props: true,
    meta: {
      title: 'Message Tracing',
      requiresAuth: true,
      confirmProfile: true,
      roles: ['admin', 'support']
    }
  },
  {
    path: '/support-tools/message-tracing/:submissionId/:messageId',
    name: 'MessageTracing_MessageId',
    component: MessageTracing,
    props: true,
    meta: {
      title: 'Message Tracing',
      requiresAuth: true,
      confirmProfile: true,

      roles: ['admin', 'support']
    }
  },
  {
    path: '/support-tools/message-queue-admin',
    name: 'MessageQueueAdmin',
    component: MessageQueueAdmin,
    meta: {
      title: 'Message Queue Admin',
      requiresAuth: true,
      confirmProfile: true,
      roles: ['admin', 'support']
    }
  },
  {
    path: '/support-tools/graph-demo',
    name: 'GraphDemo',
    component: GraphDemo,
    meta: {
      title: 'Graph Demo',
      requiresAuth: true,
      confirmProfile: true,
      roles: ['admin', 'support']
    }
  },
  {
    path: '/support-tools/health-checks',
    name: 'HealthChecks',
    component: HealthCheck,
    meta: {
      title: 'Health Checks',
      requiresAuth: true,
      confirmProfile: true,
      roles: ['admin', 'support']
    }
  },
  {
    path: '/support-tools/amlink-submission-file/:submissionId',
    name: 'AmlinkSubmissionFile',
    component: AmlinkSubmissionFile,
    props: true,
    meta: {
      title: 'Amlink Submission File',
      requiresAuth: true,
      confirmProfile: false,
      roles: ['admin', 'support', 'employee']
    }
  },
  {
    path: '/support-tools/message-queue-deadletters/:application/:topic',
    name: 'MessageQueueDeadLetters',
    component: MessageQueueDeadLetters,
    meta: {
      title: 'Message Queue Admin',
      requiresAuth: true,
      confirmProfile: true,
      roles: ['admin', 'support']
    }
  },
  {
    path: '/support-tools/agent-underwriter-reassignment',
    name: 'AgentUnderwriterReassignment',
    component: AgentUnderwriterReassignment,
    meta: {
      title: 'Agent Underwriter Reassignment',
      requiresAuth: true,
      confirmProfile: true,
    }
  },
  {
    path: '/resources/catastrophe-closings',
    name: 'CatastropheClosings',
    component: CatastropheClosings,
    meta: {
      title: 'Catastrophe Closings',
      requiresAuth: true,
      confirmProfile: true
    }
  },
  {
    path: '/resources/documents',
    name: 'Documents',
    component: Documents,
    meta: {
      title: 'Documents',
      requiresAuth: true,
      confirmProfile: true
    }
  },
  {
    path: '/agency',
    name: 'Agency',
    component: Agency,
    meta: {
      title: 'Agency',
      requiresAuth: true,
      confirmProfile: true
    }
  },
  {
    path: '/resources/news',
    name: 'NewsList',
    component: NewsList,
    meta: {
      title: 'News',
      requiresAuth: true,
      confirmProfile: true
    }
  },
  {
    path: '/resources/news/article',
    name: 'NewsArticle',
    component: NewsArticle,
    meta: {
      title: 'News Article',
      requiresAuth: true,
      confirmProfile: true
    }
  },
  {
    path: '/callback',
    name: 'Callback',
    component: Callback,
    meta: {
      title: 'Sign-in In Progress',
      requiresAuth: false,      
      isCallback: true
    }
  },
  {
    path: '/Unauthorized',
    name: 'Unauthorized',
    component: Unauthorized,
    meta: {
      title: 'Unauthorized',
      requiresAuth: false
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.afterEach((to, from) => {
  Vue.nextTick(() => {
    if (store.getters.getUserIsLoggedIn) {
      store.commit('blockComplete')
    }
    document.title = DEFAULT_TITLE + " | " + to.meta.title  || DEFAULT_TITLE;

    Vue.prototype.$gtag.pageview({
      page_title: to.meta.title,
      page_location: location.protocol + '//' + location.host,
      page_path: to.path
    });

    // TODO: may need to expire connections instead of detaching
    //hub.detachAll();        

    Vue.$toast.clear();

  });
});

const redirectToProfile = (from: any): void => {
  if (store.getters.getUserIsLoggedIn) {
    store.commit('blockComplete')
  }
  if (from.name != 'Profile') {
    router.push({path: '/profile'});
  }
}

const checkConfirmedProfile = (to: any, from: any, next: any): void => {
  if (to.meta.confirmProfile) {
    const profile = store.getters.getUserProfile;
    if (!profile) {
      UserProfileService.get().then((data: any) => {
        if (data.Confirmed && data.DefaultUnderwriterKey) next();
        else redirectToProfile(from);
      })
    } else {
      if (profile.Confirmed && profile.DefaultUnderwriterKey) next();
      else redirectToProfile(from);
    }
  } else {
    next();
  }
}

const isAuthorized = async (to: any): boolean => {
  // catch unauthenticated users that are accessing the portal for the first time
  await waitForAuthenticationAsync();

  if (to.meta.roles) {
    const roles = to.meta.roles;
    let authorized = false;
    roles.forEach((role: string) => {
      if (store.getters.userHasRole(role)) authorized = true;
    });

    if (!authorized) {
      router.push({path: '/unauthorized'});
      return false;
    }
    return true;
  }
  return true;
}

router.beforeEach(async (to, from, next) => {
  const currentlyStoredVersionHash = window.localStorage.getItem('versionHash')
  const indexHtml = await axios.get(`${window.location.origin}?t=${new Date()}`)
  const regex = /app.[a-zA-Z0-9]{20}.bundle.js/g;
  const appBundleIndex = indexHtml.data.search(regex);
  if (indexHtml && !currentlyStoredVersionHash) {
    const versionHash = indexHtml.data.substring(appBundleIndex + 4, appBundleIndex + 24)
    window.localStorage.setItem('versionHash', versionHash)
  } else {
    const versionHash = indexHtml.data.substring(appBundleIndex + 4, appBundleIndex + 24)
    if (currentlyStoredVersionHash !== versionHash) {
      window.localStorage.setItem('versionHash', versionHash)
      window.location.reload()
      return
    }
  }

  sessionStorage.setItem('returnPath', to.fullPath);
  if (store.getters.getUserIsLoggedIn) {
    checkConfirmedProfile(to, from, next);
  } else if (to.matched.some(record => record.meta.requiresAuth)) {
    store.commit('blockStart')
    store.dispatch('getUser', to.fullPath).then(() => {
      store.commit('blockComplete')
      if (isAuthorized(to)) checkConfirmedProfile(to, from, next);
    });
  } else {
    if (isAuthorized(to)){
      if (to.matched.some(route => route.meta.isCallback)) {
        next();
        return;
      }
      else {
        next('/');
        return;
      }
    }
  }
});

export default router
