export default {
  namespaced: true,
  state: {
    moratortiumsTestData: [
      {
        "id": 6,
        "externalId": "",
        "area": {
          "states": ["NC"],
          "postalCodes": ["28208"],
          "countyFIPS": ["37119"]
        },
        "beginDate": "2023-05-11T00:00:00+00:00",
        "endDate": "2023-08-11T00:00:00+00:00",
        "perilType": "Wind",
        "description": "v windy",
        "active": false,
        "confirmations": null
      },
      {
        "id": 1002,
        "externalId": "",
        "area": {
          "states": ["SC"],
          "postalCodes": ["18902"],
          "countyFIPS": ["12345"]
        },
        "beginDate": "2023-04-11T00:00:00+00:00",
        "endDate": "2023-07-11T00:00:00+00:00",
        "perilType": "Hurricane",
        "description": "v hurricaney",
        "active": false,
        "confirmations": null
      },
      {
        "id": 19203,
        "externalId": "",
        "area": {
          "states": ["CO"],
          "postalCodes": ["80228"],
          "countyFIPS": ["08059"]
        },
        "beginDate": "2023-05-11T00:00:00+00:00",
        "endDate": "2023-08-11T00:00:00+00:00",
        "perilType": "Wind",
        "description": "v windy",
        "active": false,
        "confirmations": null
      },
      {
        "id": 6,
        "externalId": "",
        "area": {
          "states": ["NC"],
          "postalCodes": ["28208"],
          "countyFIPS": ["37119"]
        },
        "beginDate": "2023-05-11T00:00:00+00:00",
        "endDate": "2023-08-11T00:00:00+00:00",
        "perilType": "Wind",
        "description": "v windy",
        "active": false,
        "confirmations": null
      },
      {
        "id": 6,
        "externalId": "",
        "area": {
          "states": [],
          "postalCodes": ["28208"],
          "countyFIPS": []
        },
        "beginDate": "2023-05-11T00:00:00+00:00",
        "endDate": "2023-08-11T00:00:00+00:00",
        "perilType": "Wind",
        "description": "v windy",
        "active": false,
        "confirmations": null
      }
    ],
    moratoriumResponseTestData:
    {
      "IsUnderMoratorium": false,
      "ConfirmationCode": 1234,
      "Moratoriums": [
        {
          "id": 6,
          "externalId": "",
          "area": {
            "states": [
              "NC"
            ],
            "postalCodes": [
              "28208"
            ],
            "countyFIPS": [
              "12345"
            ]
          },
          "beginDate": "2023-05-11T00:00:00+00:00",
          "endDate": "2023-09-11T00:00:00+00:00",
          "perilType": "Wind",
          "description": "v windy",
          "active": true,
          "confirmations": null
        },
        {
          "id": 1002,
          "externalId": "",
          "area": {
            "states": [
              "PA"
            ],
            "postalCodes": [
              "18902"
            ],
            "countyFIPS": [
              "12345"
            ]
          },
          "beginDate": "2023-04-11T00:00:00+00:00",
          "endDate": "2023-09-11T00:00:00+00:00",
          "perilType": "Hurricane",
          "description": "v hurricaney",
          "active": true,
          "confirmations": null
        }
      ]
    },
    moratoriumTestBanner: {
      MoratoriumInEffect: false,
      BannerDisplayText: "Test banner display text"
    },
    appHealthTestData: [
      {
            "AppName": "pl-portal-api",
            "StatusGrade": 3,
            "HealthReport": {
              "Status": "Healthy",
              "StatusGrade": 3,
              "Entries": [
                {
                  "HealthCheckName": "Seq Health Check",
                  "Description": null,
                  "Duration": "00:00:00.1399467",
                  "Exception": null,
                  "Status": "Healthy",
                  "Tags": []
                },
                {
                  "HealthCheckName": "Cosmos DB Health Check",
                  "Description": null,
                  "Duration": "00:00:00.1291152",
                  "Exception": null,
                  "Status": "Healthy",
                  "Tags": [
                    "Persistence - PL Portal"
                  ]
                },
                {
                  "HealthCheckName": "API 123 Health Check",
                  "Description": null,
                  "Duration": "00:00:00.4045525",
                  "Exception": null,
                  "Status": "Healthy",
                  "Tags": [
                    "API123 - PL Portal"
                  ]
                },
                {
                  "HealthCheckName": "Service Bus Health Check",
                  "Description": null,
                  "Duration": "00:00:00.2251765",
                  "Exception": null,
                  "Status": "Healthy",
                  "Tags": []
                },
                {
                  "HealthCheckName": "Munich API Health Check",
                  "Description": null,
                  "Duration": "00:00:01.0326899",
                  "Exception": null,
                  "Status": "Healthy",
                  "Tags": []
                },
                {
                  "HealthCheckName": "Redis Health Check",
                  "Description": null,
                  "Duration": "00:00:00.1349172",
                  "Exception": null,
                  "Status": "Healthy",
                  "Tags": [
                    "Redis - PL Portal"
                  ]
                },
                {
                  "HealthCheckName": "Identity Server Health Check",
                  "Description": null,
                  "Duration": "00:00:00.1314129",
                  "Exception": null,
                  "Status": "Healthy",
                  "Tags": []
                }
              ],
              "TotalDuration": "00:00:01.0345514"
            }
          },
          {
            "AppName": "orbits-bridge-amlink-api",
            "StatusGrade": 3,
            "HealthReport": {
              "Status": "Healthy",
              "StatusGrade": 3,
              "Entries": [
                {
                  "HealthCheckName": "Seq Health Check",
                  "Description": null,
                  "Duration": "00:00:00.0909368",
                  "Exception": null,
                  "Status": "Healthy",
                  "Tags": []
                },
                {
                  "HealthCheckName": "Amlink Tax Calculator Health Check",
                  "Description": null,
                  "Duration": "00:00:00.1704982",
                  "Exception": null,
                  "Status": "Healthy",
                  "Tags": []
                },
                {
                  "HealthCheckName": "NJ Transaction ID API Health Check",
                  "Description": null,
                  "Duration": "00:00:00.5423682",
                  "Exception": null,
                  "Status": "Healthy",
                  "Tags": []
                }
              ],
              "TotalDuration": "00:00:00.5424383"
            }
          },
          {
            "AppName": "orbits-core-api",
            "StatusGrade": 3,
            "HealthReport": {
              "Status": "Healthy",
              "StatusGrade": 3,
              "Entries": [
                {
                  "HealthCheckName": "API 123 Health Check",
                  "Description": null,
                  "Duration": "00:00:00.2459978",
                  "Exception": null,
                  "Status": "Healthy",
                  "Tags": [
                    "API123 - Core"
                  ]
                },
                {
                  "HealthCheckName": "Orbits Core Database Health Check",
                  "Description": null,
                  "Duration": "00:00:00.0038087",
                  "Exception": null,
                  "Status": "Healthy",
                  "Tags": [
                    "SQL Server"
                  ]
                }
              ],
              "TotalDuration": "00:00:00.2461018"
            }
          },
          {
            "AppName": "orbits-channel-flood-hiscox-api",
            "StatusGrade": 3,
            "HealthReport": {
              "Status": "Healthy",
              "StatusGrade": 3,
              "Entries": [
                {
                  "HealthCheckName": "Hiscox API Health Check",
                  "Description": null,
                  "Duration": "00:00:00.2289533",
                  "Exception": null,
                  "Status": "Healthy",
                  "Tags": []
                }
              ],
              "TotalDuration": "00:00:00.2290743"
            }
          },
          {
            "AppName": "orbits-bridge-seasys-api",
            "StatusGrade": 3,
            "HealthReport": {
              "Status": "Healthy",
              "StatusGrade": 3,
              "Entries": [
                {
                  "HealthCheckName": "Seasys Bridge Redis Health Check",
                  "Description": null,
                  "Duration": "00:00:00.0035580",
                  "Exception": null,
                  "Status": "Healthy",
                  "Tags": []
                },
                {
                  "HealthCheckName": "Moratorium API Health Check",
                  "Description": null,
                  "Duration": "00:00:00.1251988",
                  "Exception": null,
                  "Status": "Healthy",
                  "Tags": []
                }
              ],
              "TotalDuration": "00:00:00.1253367"
            }
          },
          {
            "AppName": "orbits-dataservice-usps-api",
            "StatusGrade": 3,
            "HealthReport": {
              "Status": "Healthy",
              "StatusGrade": 3,
              "Entries": [
                {
                  "HealthCheckName": "USPS API Health Check",
                  "Description": null,
                  "Duration": "00:00:00.2305430",
                  "Exception": null,
                  "Status": "Healthy",
                  "Tags": []
                },
                {
                  "HealthCheckName": "USPS Table Storage Health Check",
                  "Description": null,
                  "Duration": "00:00:00.2171733",
                  "Exception": null,
                  "Status": "Healthy",
                  "Tags": [
                    "Azure Table Storage"
                  ]
                }
              ],
              "TotalDuration": "00:00:00.2308234"
            }
          },
          {
            "AppName": "orbits-dataservice-ecopia-api",
            "StatusGrade": 1,
            "HealthReport": {
              "Status": "Unhealthy",
              "StatusGrade": 1,
              "Entries": [
                {
                  "HealthCheckName": "Ecopia API Health Check",
                  "Description": null,
                  "Duration": "00:00:00.4473173",
                  "Exception": null,
                  "Status": "Unhealthy",
                  "Tags": []
                }
              ],
              "TotalDuration": "00:00:00.4475857"
            }
          },
          {
            "AppName": "orbits-data-mart-api",
            "StatusGrade": 3,
            "HealthReport": {
              "Status": "Healthy",
              "StatusGrade": 3,
              "Entries": [
                {
                  "HealthCheckName": "Orbits Data Mart Database Health Check",
                  "Description": null,
                  "Duration": "00:00:00.0017487",
                  "Exception": null,
                  "Status": "Healthy",
                  "Tags": [
                    "SQL Server"
                  ]
                }
              ],
              "TotalDuration": "00:00:00.0018333"
            }
          },
          {
            "AppName": "orbits-channel-digital-api",
            "StatusGrade": 3,
            "HealthReport": {
              "Status": "Healthy",
              "StatusGrade": 3,
              "Entries": [
                {
                  "HealthCheckName": "Channel Digital Redis Health Check",
                  "Description": null,
                  "Duration": "00:00:00.0021504",
                  "Exception": null,
                  "Status": "Healthy",
                  "Tags": [
                    "Redis"
                  ]
                },
                {
                  "HealthCheckName": "OpterrixSyncHealthCheck",
                  "Description": "Opterrix PolicyStateUpdatedV0 data feed",
                  "Duration": "00:00:00.0250869",
                  "Exception": null,
                  "Status": "Healthy",
                  "Tags": []
                }
              ],
              "TotalDuration": "00:00:00.0252244"
            }
          },
          {
            "AppName": "orbits-channel-flood-vave-api",
            "StatusGrade": 2,
            "HealthReport": {
              "Status": "Degraded",
              "StatusGrade": 2,
              "Entries": [
                {
                  "HealthCheckName": "Vave API Health Check",
                  "Description": null,
                  "Duration": "00:00:00.4546509",
                  "Exception": null,
                  "Status": "Degraded"
                }
              ],
              "TotalDuration": "00:00:00.4547355"
            }
          }
    ]
  }
};