<template>
  <Dialog :title="title" :persistent="true" :value="open">
    <template v-slot:card-text>
      <p class="mb-0">Another user has requested access to the record you're viewing. Would you like to hand over the record?</p>
      <p class="mb-0 text-center pt-2" v-show="remainingSeconds">Time remaining: {{remainingSeconds}} seconds</p>
    </template>
    <template v-slot:card-actions>
      <v-btn color="secondary" text type="button" @click="keepLock" class="mr-2">No</v-btn>
      <v-btn color="secondary" type="button" @click="releaseLock">Yes</v-btn>
    </template>
  </Dialog>
</template>

<script>
import Dialog from '@/components/shared/Dialog.vue'

export default {
  name: 'LockReleaseDialog',
  components: {
    Dialog
  },
  data() {
    return {
      title: "Record Request",
      open: true,
      remainingSeconds: 0
    }
  },
  props: {

  },
  methods: {
    releaseLock() {
      this.$store.dispatch('deleteActiveLock');
      this.$router.push({ path: '/dashboard'})
      //this.open = false;
    },
    keepLock() {
      this.$store.dispatch('extendActiveLock');
      //this.open = false;
    }
  },
  watch: {
    remainingSeconds(newVal) {
      if (newVal <= 0) {
        this.releaseLock();
      }
    }
  },
  mounted() {
    const exp = this.$store.getters.getLockReleaseRequestExpirationTime;
    setInterval(() => {
      if (exp) {
        const now = new Date();
        this.remainingSeconds = Math.round((exp - now) / 1000); 
      } else {
        this.remainingSeconds = 0;
      }
    }, 1000);
    
  }
}
</script>
