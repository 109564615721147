<template>

<Dialog title="Submission Expired" :persistent="true" :value="showModal" @close-dialog="closeModal">
      <template v-slot:card-text>
        <div class="text-center">
          <p>This Submission has expired.<br>To requote this Risk, click Clone Submission.</p>
          <v-btn color="secondary" type="button" key="clone" large @click="clone">
              <i class="far fa-copy"></i>&nbsp;Clone Submission
          </v-btn>
        </div>
      </template>
      <template v-slot:card-actions>
          <v-btn color="secondary" text type="button" @click="closeModal">Close</v-btn>
      </template>
    </Dialog>
  
</template>

<script>
import Dialog from '@/components/shared/Dialog.vue'
import { submissionIsExpired } from '@/scripts/helper'

export default {
  name: 'SubmissionExpirationDialog',
  components: {
    Dialog
  },
  props: {
    submission: Object
  },
  data() {
    return {
      showModal: false
    }
  },
  methods: {
    checkEffectiveDate() {
      this.showModal = submissionIsExpired(this.submission);
    },
    closeModal() {
      this.$router.push({ path: '/dashboard'});
    },
    clone() {
      this.$router.push({ path: '/quote/start', query: { clone: this.submission.id } })
    }
  },
  mounted() {
    this.checkEffectiveDate();
  }
}
</script>

<style scoped>

</style>
