<template>
  <div>
    <v-container>
      <h1>
        Lookup 
        <BetaFeatureTag class="d-inline-block" />
      </h1>

      <v-card class="mb-4">
        <v-card-title>
          Agent
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" class="py-0 mb-3">
              <DynamicFormControl :control="{
                                      type: 'AgentAutocomplete',
                                      name: 'Agent',
                                      config: {
                                          label:'Retail Agent'
                                      }
                                  }" />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-card class="mb-4">
        <v-card-title>
          Underwriter
        </v-card-title>
        <v-card-text>
          <v-row >
            <v-col cols="12" class="py-0 mb-3">
              <DynamicFormControl :control="{
                                      name: 'Underwriter',
                                      type: 'UnderwriterAutocomplete',
                                      value: underwriter,
                                      config: {
                                          label: 'Principal Amwins Underwriter',
                                          simple: true,
                                          short: true,
                                          clearable: false
                                      }
                                  }" />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-card class="mb-4">
        <v-card-title>
          Mortgagee
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" class="py-0 mb-3">
              <DynamicFormControl :control="{
                                      name: 'MortgageeOrg',
                                      type: 'MortgageeOrgAutocomplete',
                                      value: mortgageeOrg,
                                      config: {
                                          label: 'Mortgagee Organization',
                                          short: true
                                      }
                                  }"
                                  @change="updateMortgageeOrg" />
            </v-col>
            <v-col cols="12" class="py-0 mb-2" v-if="mortgageeOrg">
              <div key="addressSearch">
                <DynamicFormControl :control="{
                                        name: 'Mortgagee',
                                        type: 'MortgageeAutocomplete',
                                        value: mortgageeOrgBranch,
                                        config: {
                                            label: 'Mortgagee Location Address',
                                            short: true,
                                            MortgageeOrgID: mortgageeOrg.ID
                                        }
                                    }" />
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

    </v-container>
  </div>
</template>

<script>
  import DynamicFormControl from '@/components/form/DynamicFormControl.vue'
  import BetaFeatureTag from '@/components/shared/BetaFeatureTag.vue'

  export default {
    name: 'Lookup',
    components: {
      DynamicFormControl,
      BetaFeatureTag
    },
    data() {
      return {
        mortgageeOrg: null,
        mortgageeOrgBranch: null,
        underwriter: null
      }
    },
    methods: {
      updateMortgageeOrg(event) {
        if (event && event.value) {
          this.mortgageeOrg = event.value
        } else {
          this.mortgageeOrg = null
        }

        this.mortgageeOrgBranch = null
      },
    }
  }
</script>
