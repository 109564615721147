var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card-text',[(_vm.loading)?_c('div',[_c('Loading',{staticClass:"text-center",attrs:{"size":64}})],1):_c('div',[(_vm.salesChannel === _vm.salesChannelConst.VavePersonalLines)?_c('div',[_c('ValidationObserver',{ref:"observer",attrs:{"tag":"span"}},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.refineVavePersonalLinesValidate()}}},[_c('DynamicFormControl',{attrs:{"control":{
                                   name: 'TIV',
                                   type: 'Hidden',
                                   value: _vm.tiv,
                               }}}),_c('DynamicFormControl',{attrs:{"control":{
                                   name: 'TenPercentTIV',
                                   type: 'Hidden',
                                   value: _vm.tenPercentTIV,
                               }}}),_c('DynamicFormControl',{attrs:{"control":{
                                   name: 'AllPerilDeductible',
                                   type: 'Hidden',
                                   value: _vm.allPerilDeductible,
                               }}}),_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12"}},[_c('h4',{staticClass:"mb-2 mt-2"},[_vm._v("Property Coverages")])])],1),_c('v-row',{staticClass:"mb-2"},[(_vm.showControlByCoverageOption(_vm.salesChannel, _vm.newQuote?.FormType?.value, 'CovA'))?_c('v-col',{staticClass:"py-0",attrs:{"md":"6","cols":"12"}},[_c('DynamicFormControl',{attrs:{"control":{
                                      name: 'CovA',
                                      type: 'TextInput',
                                      value: _vm.getQuoteValue(_vm.newQuote?.CovA?.value),
                                      config: {
                                        ..._vm.getControlConfig(_vm.salesChannel, _vm.newQuote?.FormType?.value, 'CovA'),
                                        label: _vm.covAlabel,     
                                        disabled: _vm.loading || _vm.calcing,                                        
                                      }
                                  }},on:{"change":_vm.updateValue}})],1):_vm._e(),(_vm.showControlByCoverageOption(_vm.salesChannel, _vm.newQuote?.FormType?.value, 'CovB'))?_c('v-col',{staticClass:"py-0",attrs:{"md":"6","cols":"12"}},[_c('DynamicFormControl',{attrs:{"control":{
                                      name: 'CovB',
                                      type: 'TextInput',
                                      value: _vm.getQuoteValue(_vm.newQuote?.CovB?.value),
                                      config: {
                                        ..._vm.getControlConfig(_vm.salesChannel, _vm.newQuote?.FormType?.value, 'CovB'),
                                        disabled: _vm.loading || _vm.calcing,
                                     }
                                  }},on:{"change":_vm.updateValue}})],1):_vm._e(),(_vm.showControlByCoverageOption(_vm.salesChannel, _vm.newQuote?.FormType?.value, 'CovC'))?_c('v-col',{staticClass:"py-0",attrs:{"md":"6","cols":"12"}},[_c('DynamicFormControl',{attrs:{"control":{
                                      name: 'CovC',
                                      type: 'TextInput',
                                      value: _vm.getQuoteValue(_vm.newQuote?.CovC?.value),
                                       config: {
                                        ..._vm.getControlConfig(_vm.salesChannel, _vm.newQuote?.FormType?.value, 'CovC'),
                                        disabled: _vm.loading || _vm.calcing,
                                      }
                                  }},on:{"change":_vm.updateValue}})],1):_vm._e(),(_vm.showControlByCoverageOption(_vm.salesChannel, _vm.newQuote?.FormType?.value, 'CovD'))?_c('v-col',{staticClass:"py-0",attrs:{"md":"6","cols":"12"}},[_c('DynamicFormControl',{attrs:{"control":{
                                      name: 'CovD',
                                      type: 'TextInput',
                                      value: _vm.getQuoteValue(_vm.newQuote?.CovD?.value),
                                      config: {
                                        ..._vm.getControlConfig(_vm.salesChannel, _vm.newQuote?.FormType?.value, 'CovD'),
                                        disabled: _vm.loading || _vm.calcing,
                                     }
                                  }},on:{"change":_vm.updateValue}})],1):_vm._e(),(_vm.showControlByCoverageOption(_vm.salesChannel, _vm.newQuote?.FormType?.value, 'LossAssessment'))?_c('v-col',{staticClass:"py-0",attrs:{"md":"6","cols":"12"}},[_c('DynamicFormControl',{attrs:{"control":{
                                      name: 'LossAssessment',
                                      type: 'TextInput',
                                      value: _vm.getQuoteValue(_vm.newQuote?.LossAssessment?.value),
                                      config: {
                                        ..._vm.getControlConfig(_vm.salesChannel, _vm.newQuote?.FormType?.value, 'LossAssessment'),
                                        disabled: _vm.loading || _vm.calcing,
                                      }
                                  }},on:{"change":_vm.updateValue}})],1):_vm._e(),(_vm.showControlByCoverageOption(_vm.salesChannel, _vm.newQuote?.FormType?.value, 'LossAssessmentEQ') && _vm.showLossAssessmentEQ)?_c('v-col',{staticClass:"py-0",attrs:{"md":"6","cols":"12"}},[_c('DynamicFormControl',{attrs:{"control":{
                                      name: 'LossAssessmentEQ',
                                      type: 'TextInput',
                                      value: _vm.getQuoteValue(_vm.newQuote?.LossAssessmentEQ?.value),
                                       config: {
                                        ..._vm.getControlConfig(_vm.salesChannel, _vm.newQuote?.FormType?.value, 'LossAssessmentEQ'),
                                        disabled: _vm.loading || _vm.calcing,
                                      }
                                  }},on:{"change":_vm.updateValue}})],1):_vm._e()],1),_c('hr'),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('h4',{staticClass:"mb-2 mt-2"},[_vm._v("Liability Coverages")])])],1),_c('v-row',{staticClass:"mb-2"},[(_vm.showControlByCoverageOption(_vm.salesChannel, _vm.newQuote?.FormType?.value, 'Liability'))?_c('v-col',{staticClass:"py-0",attrs:{"md":"6","cols":"12"}},[_c('DynamicFormControl',{attrs:{"control":{
                                      name: 'Liability',
                                      type: 'Dropdown',
                                      value: _vm.getQuoteValue(_vm.newQuote?.Liability?.value),
                                      config: {
                                       ..._vm.getControlConfig(_vm.salesChannel, _vm.newQuote?.FormType?.value, 'Liability'),
                                       disabled: _vm.loading || _vm.calcing,
                                      }
                                  }},on:{"change":_vm.updateValue}})],1):_vm._e(),(_vm.showControlByCoverageOption(_vm.salesChannel, _vm.newQuote?.FormType?.value, 'MedPay'))?_c('v-col',{staticClass:"py-0",attrs:{"md":"6","cols":"12"}},[_c('DynamicFormControl',{attrs:{"control":{
                                     name: 'MedPay',
                                     type: 'Dropdown',
                                     value: _vm.getQuoteValue(_vm.newQuote?.MedPay?.value),
                                     config: {
                                       ..._vm.getControlConfig(_vm.salesChannel, _vm.newQuote?.FormType?.value, 'MedPay'),
                                       disabled: _vm.loading || _vm.calcing || !_vm.liabilityCoveragesEnabled,
                                     }
                                 }},on:{"change":_vm.updateValue}})],1):_vm._e(),(_vm.showControlByCoverageOption(_vm.salesChannel, _vm.newQuote?.FormType?.value, 'PersonalInjury'))?_c('v-col',{staticClass:"py-0",attrs:{"md":"6","cols":"12"}},[_c('DynamicFormControl',{attrs:{"control":{
                                      name: 'PersonalInjury',
                                      type: 'Dropdown',
                                      value: _vm.getQuoteValue(_vm.newQuote?.PersonalInjury?.value),
                                       config: {
                                        ..._vm.getControlConfig(_vm.salesChannel, _vm.newQuote?.FormType?.value, 'PersonalInjury'),
                                        disabled: _vm.loading || _vm.calcing || !_vm.liabilityCoveragesEnabled,
                                      }
                                  }},on:{"change":_vm.updateValue}})],1):_vm._e()],1),_c('hr'),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('h4',{staticClass:"mb-2 mt-2"},[_vm._v("Add-On/Optional Coverages")])])],1),_c('v-row',{staticClass:"mb-2"},[(_vm.showControlByCoverageOption(_vm.salesChannel, _vm.newQuote?.FormType?.value, 'ReplacementCostStructures') && _vm.showControlBySalesChannel(_vm.salesChannel, _vm.newQuote?.FormType?.value, 'ReplacementCostStructures'))?_c('v-col',{staticClass:"py-0",attrs:{"md":"6","cols":"12"}},[_c('DynamicFormControl',{attrs:{"control":{
                                      name: 'ReplacementCostStructures',
                                      type: 'Dropdown',
                                      value: _vm.getQuoteValue(_vm.newQuote?.ReplacementCostStructures?.value),
                                      config: {
                                        ..._vm.getControlConfig(_vm.salesChannel, _vm.newQuote?.FormType?.value, 'ReplacementCostStructures'),
                                        disabled: _vm.loading || _vm.calcing,
                                      }
                                  }},on:{"change":_vm.updateValue}})],1):_vm._e(),(_vm.showControlByCoverageOption(_vm.salesChannel, _vm.newQuote?.FormType?.value, 'ReplacementCostContents') && _vm.showControlBySalesChannel(_vm.salesChannel, _vm.newQuote?.FormType?.value, 'ReplacementCostContents'))?_c('v-col',{staticClass:"py-0",attrs:{"md":"6","cols":"12"}},[_c('DynamicFormControl',{attrs:{"control":{
                                      name: 'ReplacementCostContents',
                                      type: 'Dropdown',
                                      value: _vm.getQuoteValue(_vm.newQuote?.ReplacementCostContents?.value),
                                       config: {
                                        ..._vm.getControlConfig(_vm.salesChannel, _vm.newQuote?.FormType?.value, 'ReplacementCostContents'),
                                        disabled: _vm.loading || _vm.calcing,
                                      }
                                  }},on:{"change":_vm.updateValue}})],1):_vm._e(),(_vm.showControlByCoverageOption(_vm.salesChannel, _vm.newQuote?.FormType?.value, 'InflationGuard') && _vm.showControlBySalesChannel(_vm.salesChannel, _vm.newQuote?.FormType?.value, 'InflationGuard'))?_c('v-col',{staticClass:"py-0",attrs:{"md":"6","cols":"12"}},[_c('DynamicFormControl',{attrs:{"control":{
                                      name: 'InflationGuard',
                                      type: 'PercentIncrementer',
                                      value: _vm.getQuoteValue(_vm.newQuote?.InflationGuard?.value),
                                      config: {
                                        ..._vm.getControlConfig(_vm.salesChannel, _vm.newQuote?.FormType?.value, 'InflationGuard'),
                                        disabled: _vm.loading || _vm.calcing,
                                      }
                                  }},on:{"change":_vm.updateValue}})],1):_vm._e(),(_vm.showControlByCoverageOption(_vm.salesChannel, _vm.newQuote?.FormType?.value, 'BuildingOrdinanceOrLaw') && _vm.showControlBySalesChannel(_vm.salesChannel, _vm.newQuote?.FormType?.value, 'BuildingOrdinanceOrLaw'))?_c('v-col',{staticClass:"py-0",attrs:{"md":"6","cols":"12"}},[_c('DynamicFormControl',{attrs:{"control":{
                                      name: 'BuildingOrdinanceOrLaw',
                                      type: 'Dropdown',
                                      value: _vm.getQuoteValue(_vm.newQuote?.BuildingOrdinanceOrLaw?.value),
                                      config: {
                                        ..._vm.getControlConfig(_vm.salesChannel, _vm.newQuote?.FormType?.value, 'BuildingOrdinanceOrLaw'),
                                        disabled: _vm.loading || _vm.calcing,
                                      }
                                  }},on:{"change":_vm.updateValue}})],1):_vm._e(),(_vm.showControlByCoverageOption(_vm.salesChannel, _vm.newQuote?.FormType?.value, 'Theft') && _vm.showControlBySalesChannel(_vm.salesChannel, _vm.newQuote?.FormType?.value, 'Theft'))?_c('v-col',{staticClass:"py-0",attrs:{"md":"6","cols":"12"}},[(_vm.theftDefaultValue != 'Excluded')?_c('DynamicFormControl',{attrs:{"control":{
                                        name: 'Theft',
                                        type: 'TextInput',
                                        value: _vm.getQuoteValue(_vm.newQuote?.Theft?.value),
                                        config: {
                                          ..._vm.getControlConfig(_vm.salesChannel, _vm.newQuote?.FormType?.value, 'Theft'),
                                          disabled: _vm.loading || _vm.calcing || _vm.theftDefaultValue === 'Excluded',
                                        }
                                    }},on:{"change":_vm.updateValue}}):_c('DynamicFormControl',{attrs:{"control":{
                                        name: 'TheftExclusion',
                                        type: 'TextInput',
                                        value: 'No Coverage',
                                        config: {
                                          ..._vm.getControlConfig(_vm.salesChannel, _vm.newQuote?.FormType?.value, 'TheftExclusion'),
                                        }
                                    }}})],1):_vm._e(),(_vm.showControlByCoverageOption(_vm.salesChannel, _vm.newQuote?.FormType?.value, 'AdditionalTheft') && _vm.showControlBySalesChannel(_vm.salesChannel, _vm.newQuote?.FormType?.value, 'AdditionalTheft'))?_c('v-col',{staticClass:"py-0",attrs:{"md":"6","cols":"12"}},[_c('DynamicFormControl',{attrs:{"control":{
                                        name: 'AdditionalTheft',
                                        type: 'Dropdown',
                                        value: _vm.getQuoteValue(_vm.newQuote?.AdditionalTheft?.value),
                                        config: {
                                          ..._vm.getControlConfig(_vm.salesChannel, _vm.newQuote?.FormType?.value, 'AdditionalTheft'),
                                          disabled: _vm.loading || _vm.calcing || _vm.theftDefaultValue == 'Excluded',
                                        }
                                    }},on:{"change":_vm.updateValue}})],1):_vm._e(),(_vm.showControlByCoverageOption(_vm.salesChannel, _vm.newQuote?.FormType?.value, 'LimitedTheft') && _vm.showControlBySalesChannel(_vm.salesChannel, _vm.newQuote?.FormType?.value, 'LimitedTheft'))?_c('v-col',{staticClass:"py-0",attrs:{"md":"6","cols":"12"}},[_c('DynamicFormControl',{attrs:{"control":{
                                      name: 'LimitedTheft',
                                      type: 'Dropdown',
                                      value: _vm.getQuoteValue(_vm.newQuote?.LimitedTheft?.value),
                                      config: {
                                        ..._vm.getControlConfig(_vm.salesChannel, _vm.newQuote?.FormType?.value, 'LimitedTheft'),
                                        disabled: _vm.loading || _vm.calcing,
                                      }
                                  }},on:{"change":_vm.updateValue}})],1):_vm._e(),(_vm.showControlByCoverageOption(_vm.salesChannel, _vm.newQuote?.FormType?.value, 'BroadTheft') && _vm.showControlBySalesChannel(_vm.salesChannel, _vm.newQuote?.FormType?.value, 'BroadTheft'))?_c('v-col',{staticClass:"py-0",attrs:{"md":"6","cols":"12"}},[_c('DynamicFormControl',{attrs:{"control":{
                                      name: 'BroadTheft',
                                      type: 'Dropdown',
                                      value: _vm.getQuoteValue(_vm.newQuote?.BroadTheft?.value),
                                      config: {
                                        ..._vm.getControlConfig(_vm.salesChannel, _vm.newQuote?.FormType?.value, 'BroadTheft'),
                                        disabled: _vm.loading || _vm.calcing,
                                      }
                                  }},on:{"change":_vm.updateValue}})],1):_vm._e(),(_vm.showControlByCoverageOption(_vm.salesChannel, _vm.newQuote?.FormType?.value, 'WaterBackup') && _vm.showControlBySalesChannel(_vm.salesChannel, _vm.newQuote?.FormType?.value, 'WaterBackup'))?_c('v-col',{staticClass:"py-0",attrs:{"md":"6","cols":"12"}},[_c('DynamicFormControl',{attrs:{"control":{
                                      name: 'WaterBackup',
                                      type: 'TextInput',
                                      value: _vm.getQuoteValue(_vm.newQuote?.WaterBackup?.value),
                                      config: {
                                        ..._vm.getControlConfig(_vm.salesChannel, _vm.newQuote?.FormType?.value, 'WaterBackup'),
                                        disabled: _vm.loading || _vm.calcing,
                                      }
                                  }},on:{"change":_vm.updateValue}})],1):_vm._e(),(_vm.showControlByCoverageOption(_vm.salesChannel, _vm.newQuote?.FormType?.value, 'GolfCartPhysicalDamage') && _vm.showControlBySalesChannel(_vm.salesChannel, _vm.newQuote?.FormType?.value, 'GolfCartPhysicalDamage'))?_c('v-col',{staticClass:"py-0",attrs:{"md":"6","cols":"12"}},[_c('DynamicFormControl',{attrs:{"control":{
                                      name: 'GolfCartPhysicalDamage',
                                      type: 'Dropdown',
                                      value: _vm.getQuoteValue(_vm.newQuote?.GolfCartPhysicalDamage?.value),
                                      config: {
                                        ..._vm.getControlConfig(_vm.salesChannel, _vm.newQuote?.FormType?.value, 'GolfCartPhysicalDamage'),
                                        disabled: _vm.loading || _vm.calcing,
                                      }
                                  }},on:{"change":_vm.updateValue}})],1):_vm._e(),(_vm.showControlByCoverageOption(_vm.salesChannel, _vm.newQuote?.FormType?.value, 'HO6ExtensionCoverage') && _vm.showControlBySalesChannel(_vm.salesChannel, _vm.newQuote?.FormType?.value, 'HO6ExtensionCoverage'))?_c('v-col',{staticClass:"py-0",attrs:{"md":"6","cols":"12"}},[_c('DynamicFormControl',{attrs:{"control":{
                                      name: 'HO6ExtensionCoverage',
                                      type: 'Dropdown',
                                      value: _vm.getQuoteValue(_vm.newQuote?.HO6ExtensionCoverage?.value),
                                      config: {
                                        ..._vm.getControlConfig(_vm.salesChannel, _vm.newQuote?.FormType?.value, 'HO6ExtensionCoverage'),
                                        disabled: _vm.loading || _vm.calcing,
                                      }
                                  }},on:{"change":_vm.updateValue}})],1):_vm._e(),(_vm.showControlByCoverageOption(_vm.salesChannel, _vm.newQuote?.FormType?.value, 'GreenUpgrades') && _vm.showControlBySalesChannel(_vm.salesChannel, _vm.newQuote?.FormType?.value, 'GreenUpgrades'))?_c('v-col',{staticClass:"py-0",attrs:{"md":"6","cols":"12"}},[_c('DynamicFormControl',{attrs:{"control":{
                                      name: 'GreenUpgrades',
                                      type: 'Dropdown',
                                      value: _vm.getQuoteValue(_vm.newQuote?.GreenUpgrades?.value),
                                      config: {
                                        ..._vm.getControlConfig(_vm.salesChannel, _vm.newQuote?.FormType?.value, 'GreenUpgrades'),
                                        disabled: _vm.loading || _vm.calcing,
                                      }
                                  }},on:{"change":_vm.updateValue}})],1):_vm._e(),(_vm.showControlByCoverageOption(_vm.salesChannel, _vm.newQuote?.FormType?.value, 'IdentityFraudExp') && _vm.showControlBySalesChannel(_vm.salesChannel, _vm.newQuote?.FormType?.value, 'IdentityFraudExp'))?_c('v-col',{staticClass:"py-0",attrs:{"md":"6","cols":"12"}},[_c('DynamicFormControl',{attrs:{"control":{
                                      name: 'IdentityFraudExp',
                                      type: 'Dropdown',
                                      value: _vm.getQuoteValue(_vm.newQuote?.IdentityFraudExp?.value),
                                      config: {
                                        ..._vm.getControlConfig(_vm.salesChannel, _vm.newQuote?.FormType?.value, 'IdentityFraudExp'),
                                        disabled: _vm.loading || _vm.calcing,
                                      }
                                  }},on:{"change":_vm.updateValue}})],1):_vm._e(),(_vm.showControlByCoverageOption(_vm.salesChannel, _vm.newQuote?.FormType?.value, 'RefrigeratedGoods') && _vm.showControlBySalesChannel(_vm.salesChannel, _vm.newQuote?.FormType?.value, 'RefrigeratedGoods'))?_c('v-col',{staticClass:"py-0",attrs:{"md":"6","cols":"12"}},[_c('DynamicFormControl',{attrs:{"control":{
                                      name: 'RefrigeratedGoods',
                                      type: 'Dropdown',
                                      value: _vm.getQuoteValue(_vm.newQuote?.RefrigeratedGoods?.value),
                                      config: {
                                        ..._vm.getControlConfig(_vm.salesChannel, _vm.newQuote?.FormType?.value, 'RefrigeratedGoods'),
                                        disabled: _vm.loading || _vm.calcing,
                                      }
                                  }},on:{"change":_vm.updateValue}})],1):_vm._e(),(_vm.showControlByCoverageOption(_vm.salesChannel, _vm.newQuote?.FormType?.value, 'SpecialComputerCover') && _vm.showControlBySalesChannel(_vm.salesChannel, _vm.newQuote?.FormType?.value, 'SpecialComputerCover'))?_c('v-col',{staticClass:"py-0",attrs:{"md":"6","cols":"12"}},[_c('DynamicFormControl',{attrs:{"control":{
                                      name: 'SpecialComputerCover',
                                      type: 'Dropdown',
                                      value: _vm.getQuoteValue(_vm.newQuote?.SpecialComputerCover?.value),
                                      config: {
                                        ..._vm.getControlConfig(_vm.salesChannel, _vm.newQuote?.FormType?.value, 'SpecialComputerCover'),
                                        disabled: _vm.loading || _vm.calcing,
                                      }
                                  }},on:{"change":_vm.updateValue}})],1):_vm._e(),(_vm.showControlByCoverageOption(_vm.salesChannel, _vm.newQuote?.FormType?.value, 'MechanicalBreakdownCover') && _vm.showControlBySalesChannel(_vm.salesChannel, _vm.newQuote?.FormType?.value, 'MechanicalBreakdownCover'))?_c('v-col',{staticClass:"py-0",attrs:{"md":"6","cols":"12"}},[_c('DynamicFormControl',{attrs:{"control":{
                                      name: 'MechanicalBreakdownCover',
                                      type: 'Dropdown',
                                      value: _vm.getQuoteValue(_vm.newQuote?.MechanicalBreakdownCover?.value),
                                      config: {
                                        ..._vm.getControlConfig(_vm.salesChannel, _vm.newQuote?.FormType?.value, 'MechanicalBreakdownCover'),
                                        disabled: _vm.loading || _vm.calcing,
                                      }
                                  }},on:{"change":_vm.updateValue}})],1):_vm._e()],1),_c('hr'),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('h4',{staticClass:"mb-2 mt-2"},[_vm._v("Deductibles")])])],1),_c('v-row',[(_vm.showControlByCoverageOption(_vm.salesChannel, _vm.newQuote?.FormType?.value, 'AllPerilDeductible'))?_c('v-col',{staticClass:"py-0",attrs:{"md":"6","cols":"12"}},[_c('DynamicFormControl',{attrs:{"control":{
                                      name: 'TotalPeril',
                                      type: 'TextInput',
                                      value: _vm.getQuoteValue(_vm.newQuote?.TotalPeril?.value),
                                      config: {
                                        ..._vm.getControlConfig(_vm.salesChannel, _vm.newQuote?.FormType?.value, 'AllPerilDeductible'),
                                        disabled: _vm.loading || _vm.calcing,
                                      }
                                  }},on:{"change":_vm.updateValue}})],1):_vm._e(),(_vm.showControlByCoverageOption(_vm.salesChannel, _vm.newQuote?.FormType?.value, 'WindHailPerilIncrementer'))?_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{attrs:{"md":"6","cols":"12"}},[_c('DynamicFormControl',{attrs:{"control":{
                                          name: 'WindHailPerilIncrementer',
                                          type: 'PercentIncrementer',
                                          value: _vm.deductibleValues.windHailPerilValues.percent,
                                          config: {
                                            ..._vm.getControlConfig(_vm.salesChannel, _vm.newQuote?.FormType?.value, 'WindHailPerilIncrementer'),
                                            disabled: _vm.loading || _vm.calcing,
                                          }
                                      }},on:{"change":function($event){return _vm.updateDeductibles('windHail', 'percent', $event)}}})],1),_c('v-col',{attrs:{"md":"6","cols":"12"}},[_c('DynamicFormControl',{attrs:{"control":{
                                          name: 'WindHailFlatAmount',
                                          type: 'TextInput',
                                          value: _vm.deductibleValues.windHailPerilValues.flat,
                                          config: {
                                            ..._vm.getControlConfig(_vm.salesChannel, _vm.newQuote?.FormType?.value, 'WindHailFlatAmount')
                                          }
                                      }},on:{"change":function($event){return _vm.updateDeductibles('windHail', 'flat', $event)}}})],1)],1)],1):_vm._e(),(_vm.showControlByCoverageOption(_vm.salesChannel, _vm.newQuote?.FormType?.value, 'FloodPerilIncrementer'))?_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{attrs:{"md":"6","cols":"12"}},[_c('DynamicFormControl',{attrs:{"control":{
                                          name: 'FloodPerilIncrementer',
                                          type: 'PercentIncrementer',
                                          value: _vm.deductibleValues.floodPerilValues.percent,
                                          config: {
                                            ..._vm.getControlConfig(_vm.salesChannel, _vm.newQuote?.FormType?.value, 'FloodPerilIncrementer'),
                                            disabled: _vm.loading || _vm.calcing || true,
                                          }
                                      }},on:{"change":function($event){return _vm.updateDeductibles('flood', 'percent', $event)}}})],1),_c('v-col',{attrs:{"md":"6","cols":"12"}},[_c('DynamicFormControl',{attrs:{"control":{
                                          name: 'FloodFlatAmount',
                                          type: 'TextInput',
                                          value: _vm.deductibleValues.floodPerilValues.flat,
                                          config: {
                                            ..._vm.getControlConfig(_vm.salesChannel, _vm.newQuote?.FormType?.value, 'FloodFlatAmount')
                                          }
                                      }},on:{"change":function($event){return _vm.updateDeductibles('flood', 'flat', $event)}}})],1)],1)],1):_vm._e(),(_vm.showControlByCoverageOption(_vm.salesChannel, _vm.newQuote?.FormType?.value, 'EarthquakePerilIncrementer'))?_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{attrs:{"md":"6","cols":"12"}},[_c('DynamicFormControl',{attrs:{"control":{
                                          name: 'EarthquakePerilIncrementer',
                                          type: 'PercentIncrementer',
                                          value: _vm.deductibleValues.earthquakePerilValues.percent,
                                          config: {
                                            ..._vm.getControlConfig(_vm.salesChannel, _vm.newQuote?.FormType?.value, 'EarthquakePerilIncrementer'),
                                            rules: _vm.earthquakePercentageRules,
                                            disabled: _vm.loading || _vm.calcing,
                                          }
                                      }},on:{"change":function($event){return _vm.updateDeductibles('earthquake', 'percent', $event)}}})],1),_c('v-col',{attrs:{"md":"6","cols":"12"}},[_c('DynamicFormControl',{attrs:{"control":{
                                          name: 'EarthquakeFlatAmount',
                                          type: 'TextInput',
                                          value: _vm.deductibleValues.earthquakePerilValues.flat,
                                          config: {
                                            ..._vm.getControlConfig(_vm.salesChannel, _vm.newQuote?.FormType?.value, 'EarthquakeFlatAmount')
                                          }
                                      }},on:{"change":function($event){return _vm.updateDeductibles('earthquake', 'flat', $event)}}})],1)],1)],1):_vm._e(),(_vm.showControlByCoverageOption(_vm.salesChannel, _vm.newQuote?.FormType?.value, 'WaterDamagePeril'))?_c('v-col',{staticClass:"py-0",attrs:{"md":"6","cols":"12"}},[_c('DynamicFormControl',{attrs:{"control":{
                                        name: 'WaterDamagePeril',
                                        type: 'TextInput',
                                        value: _vm.getQuoteValue(_vm.newQuote?.WaterDamagePeril?.value),
                                        config: {
                                          ..._vm.getControlConfig(_vm.salesChannel, _vm.newQuote?.FormType?.value, 'WaterDamagePeril')
                                        }
                                    }},on:{"change":_vm.updateValue}})],1):_vm._e(),(_vm.showControlByCoverageOption(_vm.salesChannel, _vm.newQuote?.FormType?.value, 'WaterDamageSublimit'))?_c('v-col',{staticClass:"py-0",attrs:{"md":"6","cols":"12"}},[_c('DynamicFormControl',{attrs:{"control":{
                                        name: 'WaterDamageSublimit',
                                        type: 'TextInput',
                                        value: _vm.getQuoteValue(_vm.newQuote?.WaterDamageSublimit?.value),
                                        config: {
                                          ..._vm.getControlConfig(_vm.salesChannel, _vm.newQuote?.FormType?.value, 'WaterDamageSublimit'),
                                        }
                                    }}})],1):_vm._e(),(_vm.showControlByCoverageOption(_vm.salesChannel, _vm.newQuote?.FormType?.value, 'Mold'))?_c('v-col',{staticClass:"py-0",attrs:{"md":"6","cols":"12"}},[_c('DynamicFormControl',{attrs:{"control":{
                                      name: 'Mold',
                                      type: 'TextInput',
                                      value: _vm.getQuoteValue(_vm.newQuote?.Mold?.value),
                                      config: {
                                        ..._vm.getControlConfig(_vm.salesChannel, _vm.newQuote?.FormType?.value, 'Mold'),
                                        disabled: _vm.loading || _vm.calcing,
                                      }
                                  }},on:{"change":_vm.updateValue}})],1):_vm._e()],1),_c('hr'),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('h4',{staticClass:"mb-2 mt-2"},[_vm._v("Fees")])])],1),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"md":"6","cols":"12"}},[_c('DynamicFormControl',{attrs:{"control":{
                                       name: 'ServiceFee',
                                       type: 'TextInput',
                                       value: _vm.getQuoteValue(_vm.newQuote?.ServiceFee),
                                       config: {
                                         ..._vm.getControlConfig(_vm.salesChannel, _vm.newQuote?.FormType?.value, 'ServiceFee'),
                                         rules: _vm.amwinsServiceFeeRules,
                                         disabled: _vm.loading || _vm.calcing || !_vm.amwinsServiceFeeEnabled,
                                       }
                                   }},on:{"change":_vm.updateServiceFee}})],1),_c('v-col',{staticClass:"py-0",attrs:{"md":"6","cols":"12"}},[_c('DynamicFormControl',{attrs:{"control":{
                                      name: 'InspectionFee',
                                      type: 'TextInput',
                                      value: _vm.getQuoteValue(_vm.newQuote?.InspectionFee),
                                      config: {
                                        ..._vm.getControlConfig(_vm.salesChannel, _vm.newQuote?.FormType?.value, 'InspectionFee'),
                                      }
                                  }},on:{"change":_vm.updateInspectionFee}})],1)],1),_c('div',{staticClass:"py-6 actions"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"py-0",attrs:{"sm":"6","cols":"12"}},[_c('v-btn',{key:"save",staticClass:"mr-4",attrs:{"color":"secondary","type":"submit","large":"","disabled":_vm.calcing}},[(_vm.calcing)?_c('Loading',{staticClass:"mr-2",attrs:{"size":24}}):_vm._e(),_vm._v(" Calculate ")],1),(!_vm.calcing)?_c('h2',{staticClass:"premium"},[_vm._v(_vm._s(_vm.premium))]):_vm._e()],1),_c('v-col',{staticClass:"py-0 text-right",attrs:{"sm":"6","cols":"12"}},[_c('v-btn',{staticClass:"mr-2",attrs:{"color":"secondary","type":"button","text":"","large":"","disabled":_vm.calcing},on:{"click":_vm.cancel}},[_vm._v(" Cancel ")]),(_vm.refinementCalled)?_c('v-btn',{attrs:{"color":"secondary","type":"button","large":"","disabled":!_vm.refinementCalled || _vm.calcing},on:{"click":_vm.save}},[_vm._v(" Save ")]):_vm._e()],1)],1)],1)],1)])],1):_c('div')])])
}
var staticRenderFns = []

export { render, staticRenderFns }