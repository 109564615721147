<template>
  <v-container :key="$store.getters.getUserEmail">
    <v-row>
      <v-col cols="12">
        <h1>My Profile</h1>
      </v-col>
    </v-row>

    <v-alert type="info" dense v-if="profile && !profile.Confirmed">
      Please confirm your profile before using the Portal.
    </v-alert>

    <v-alert type="info" dense v-else-if="profile && !profile.DefaultUnderwriterKey">
      Your previous default underwriter no longer exists.
      Please select a new default underwriter before using the Portal.
    </v-alert>

    <div v-if="profile">
      <v-row>
        <v-col md="4" cols="12">
          <v-card class="pa-6 mb-6">
            <v-row>
              <v-col cols="12">
                <h3 class="mb-0">My Avatar</h3>
              </v-col>
            </v-row>
            <v-row class="mt-0">
              <v-col cols="12" class="text-center">
                <AvatarUpload />
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col md="8" cols="12">
          <ValidationObserver tag="span" v-slot="{}" ref="observer">
            <form @submit.prevent="validate()">

              <v-card class="pa-6 mb-6" v-if="amlinkProfile">
                <v-row>
                  <v-col md="10" cols="12">
                    <v-row class="d-flex align-center">
                      <v-col md="6" cols="12">
                        <h3>My Profile</h3>
                        <div class="mt-3">
                          <h2>{{amlinkProfile.HumanFirstName}} {{amlinkProfile.HumanLastName}}</h2>
                          <h4>{{amlinkProfile.HumanTitle}}</h4>
                        </div>
                        <div class="mt-3">
                          <p class="mb-0">{{amlinkProfile.HumanPhone}}</p>
                          <p class="mb-0">{{amlinkProfile.HumanEmail ? amlinkProfile.HumanEmail : profile.email}}</p>
                        </div>                      
                      </v-col>
                      <v-col md="6" cols="12">
                        <h3>My Company</h3>
                        <div class="mt-3">
                          <h2>{{amlinkProfile.OrgName}}</h2>
                          <h4>{{amlinkProfile.BranchName}}</h4>
                        </div>
                        <div class="mt-3">
                          <p class="mb-0">{{amlinkProfile.BranchPhysicalAddress1}} {{amlinkProfile.BranchPhysicalAddress2}}</p>
                          <p class="mb-0">{{amlinkProfile.BranchPhysicalCity}}, {{amlinkProfile.BranchPhysicalStateCd}} {{amlinkProfile.BranchPhysicalZipCode}}</p>
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>
                  <!-- <v-col md="2" cols="12">
                    <p style="font-size: 12px; line-height: 14px;" class="mb-0 text-right">
                      <i class="fas fa-info-circle"></i>&nbsp;To update, <a href="https://www.amwins.com/solutions/small-accounts/personal-lines/portal-sign-up" target="_blank">contact&nbsp;us</a>.
                    </p>
                  </v-col> -->
                </v-row>
              </v-card>

              <v-card class="pa-6 mb-6">
                <v-row>
                  <v-col cols="2" class="text-center" style="position: relative; z-index: 3">
                    <Avatar :src="personalLinesUnderwriter ? getUserAvatarPath(personalLinesUnderwriter.Email) : null" color="white" :alt="personalLinesUnderwriter ? personalLinesUnderwriter.Name : null" class="avatar " size="110" />
                  </v-col>
                  <v-col cols="10" style="position: relative; z-index: 4">
                    <v-row>
                      <v-col cols="12">
                        <h3 class="mb-0">Personal Lines Amwins Underwriter</h3>
                      </v-col>
                    </v-row>
                    <v-row class="mt-0">
                      <v-col cols="12">
                        <DynamicFormControl 
                            :control="{
                                name: 'PersonalLinesUnderwriter',
                                type: 'UnderwriterAutocomplete',
                                value: personalLinesUnderwriter,
                                config: {
                                    label: 'Personal Lines Amwins Underwriter',
                                    rules: 'required',
                                    short: true,
                                    showDefaultToggle: false,
                                    clearable: false
                                }
                            }"
                            @change="updatePersonalLinesUnderwriter" 
                        />
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card>

              <v-card v-if="floodFeatureEnabled && isAgent" class="pa-6 mb-6">
                <v-row>
                  <v-col cols="2" class="text-center" style="position: relative; z-index: 3">
                    <Avatar :src="floodUnderwriter ? getUserAvatarPath(floodUnderwriter.Email) : null" color="white" :alt="floodUnderwriter ? floodUnderwriter.Name : null" class="avatar " size="110" />
                  </v-col>
                  <v-col cols="10" style="position: relative; z-index: 4">
                    <v-row>
                      <v-col cols="12">
                        <h3 class="mb-0">Flood Amwins Underwriter</h3>
                      </v-col>
                    </v-row>
                    <v-row class="mt-0">
                      <v-col cols="12">
                        <DynamicFormControl 
                            :control="{
                                name: 'FloodUnderwriter',
                                type: 'UnderwriterAutocomplete',
                                value: floodUnderwriter,
                                config: {
                                    label: 'Flood Amwins Underwriter',
                                    short: true,
                                    showDefaultToggle: false,
                                    clearable: false,
                                    controlDisabled: true,
                                    mode: 'Underwriter'
                                }
                            }"
                            salesChannel="BindV1"
                            @change="updateFloodUnderwriter" 
                        />
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card>

              <v-card class="pa-6 mb-6" v-if="!isAgent">
                <v-row>
                  <v-col cols="12">
                    <h3 class="mb-0">Beta Features</h3>
                    <p style="font-size: 12px; line-height: 14px; padding-top:4px;" class="mb-0 text-left">
                      <i class="fas fa-info-circle"></i>&nbsp;Use features that are in beta testing. Features in beta testing will be marked with a blue tag.
                    </p>
                  </v-col>
                </v-row>

                <v-row class="mt-0">
                  <v-col md="12" cols="12" class="py-0">
                    <v-switch v-model="enableBetaFeatures"
                              label="Enable Beta Features"></v-switch>
                  </v-col>
                </v-row>
              </v-card>

              <v-card class="pa-6 mb-6" v-if="isUnderwriter">
                <v-row>
                  <v-col cols="12">
                    <h3 class="mb-0">Submissions Setup</h3>
                  </v-col>
                </v-row>
                <v-row class="mt-0">
                  <v-col cols="12" class="py-0">
                    <DynamicFormControl 
                        :control="{
                            name: 'Submissions_SendToMe',
                            type: 'RadioButtonGroup',
                            value: Submissions_SendToMe,
                            config: {
                              label: 'Would you like to receive submissions at your email address?',
                              col: 12,
                              rules: 'required',
                              default: true,
                              mandatory: true,
                              options: [
                                {
                                  text: 'Yes',
                                  value: true
                                },
                                {
                                  text: 'No',
                                  value: false
                                }
                              ]

                            }
                        }"
                        @change="updateValue" 
                    />
                  </v-col>

                  <!-- <v-col cols="12" class="py-0">
                    <DynamicFormControl 
                        :control="{
                            name: 'Submissions_UseCSTClearance',
                            type: 'RadioButtonGroup',
                            value: Submissions_UseCSTClearance,
                            config: {
                              label: 'Use CST Clearance?',
                              col: 12,
                              rules: 'required',
                              default: true,
                              options: [
                                {
                                  text: 'Yes',
                                  value: true
                                },
                                {
                                  text: 'No',
                                  value: false
                                }
                              ]

                            }
                        }"
                        @change="updateValue" 
                    />
                  </v-col> -->

                  <v-col cols="12" class="py-0">
                    <DynamicFormControl 
                        :control="{
                            name: 'Submissions_AlternateEmails',
                            type: 'TextInputList',
                            value: Submissions_AlternateEmails,
                            config: {
                              label: 'Enter other email addresses that you would like to receive your submissions.',
                              col: 12,
                              short: true,
                              rules: Submissions_AlternateEmailsRules,
                              addButtonText: 'Add Email Address',
                              inputConfig: {
                                label: 'Email Address',
                                rules: 'required|email|emailDomain:amwins.com|emailNotMyself',
                                short: true,
                              }
                            }
                        }"
                        @change="updateValue" 
                    />
                  </v-col>
                </v-row>
              </v-card>

              <v-card class="pa-6 mb-6" v-if="isUnderwriter">
                <v-row>
                  <v-col cols="12">
                    <h3 class="mb-0">My Underwriting Team</h3>
                  </v-col>
                </v-row>
                <v-row class="mt-0">
                  <v-col cols="12" class="py-0">
                    <DynamicFormControl 
                        :control="{
                            name: 'Submissions_VisibilityEmails',
                            type: 'TextInputList',
                            value: Submissions_VisibilityEmails,
                            config: {
                              label: 'Enter the email addresses of your team members that you would like to have access to your accounts and submissions.',
                              col: 12,
                              short: true,
                              rules: 'noDuplicateTextEntries',
                              max: 50,
                              addButtonText: 'Add User Email Address',
                              inputConfig: {
                                label: 'Email Address',
                                rules: 'required|email|emailDomain:amwins.com|emailNotMyself',
                                short: true,
                              }
                            }
                        }"
                        @change="updateValue" 
                    />
                  </v-col>
                </v-row>
              </v-card>



              <v-card class="pa-6 mb-6" v-if="isAgent">
                <v-row>
                  <v-col cols="12">
                    <h3 class="mb-0">State Licenses</h3>
                    <p style="font-size: 12px; line-height: 14px; padding-top:4px;" class="mb-0 text-left">
                      <i class="fas fa-info-circle"></i>&nbsp;P&amp;C License information can be used by other members of your organization when binding business.
                    </p>
                  </v-col>
                </v-row>

                <v-row class="mt-0">
                  <v-col md="12" cols="12" class="py-0">
                    <DynamicFormControl 
                        :control="{
                            name: 'StateLicenses',
                            type: 'StateLicenses',
                            value: StateLicenses,
                            config: {
                              short: true
                            }
                        }"
                        @change="updateStateLicenses" 
                    />
                  </v-col>
                </v-row>
              </v-card>

              

              <v-row>
                <v-col cols="12" class="text-right">
                  <v-btn color="secondary" type="submit" large :disabled="saving">
                    <template v-if="profile.Confirmed">Save</template>
                    <template v-else>Confirm</template>
                  </v-btn>
                  <br><br><br>
                </v-col>
              </v-row>

            </form>
          </ValidationObserver>
        </v-col>
        
      </v-row>
      
    </div>

  </v-container>
</template>

<script>
import DynamicFormControl from '@/components/form/DynamicFormControl.vue';
import { UserProfileService } from '@/api/UserProfileService';
import AvatarUpload from '@/components/views/Profile/AvatarUpload.vue'
import Avatar from '@/components/shared/Avatar.vue'
import { getUserAvatarPath } from '@/scripts/helper'
import { UnderwriterService } from '@/api/UnderwriterService';

export default {
  name: 'Profile',
  components: {
    DynamicFormControl,
    AvatarUpload,
    Avatar
  },
  data() {
    return {
      saving: false,
      profile: null,
      ProfilePicture: null,
      personalLinesUnderwriter: null,
      floodUnderwriter: null,
      StateLicenses: null,
      enableBetaFeatures: false,
      Submissions_SendToMe: null,
      //Submissions_UseCSTClearance: null,
      Submissions_AlternateEmails: null,
      Submissions_VisibilityEmails: null,
      isUnderwriter: false
    }
  },
  methods: {
    getUserAvatarPath,
    updateProfilePicture(event) {
      this.ProfilePicture = event.value;
    },
    updatePersonalLinesUnderwriter(event) {
      this.personalLinesUnderwriter = event.value;
    },
    updateFloodUnderwriter(event) {
      this.floodUnderwriter = event.value;
    },
    updateStateLicenses(event) {
      this.StateLicenses = event.value;
    },
    updateValue(event) {
      this[event.name] = event.value;
    },
    validate() {
      this.$refs.observer.validate()
      .then((valid) => {
        if (valid) {
          this.updateProfile();
        }
      });
    },
    updateProfile() {
      const redirect = !this.profile.Confirmed;

      let profile = { ...this.profile };
      profile.DefaultUnderwriterKey = (this.personalLinesUnderwriter && this.personalLinesUnderwriter.id ? this.personalLinesUnderwriter.id : null);
      profile.FloodUnderwriterKey = (this.floodUnderwriter && this.floodUnderwriter.id ? this.floodUnderwriter.id : null);
      profile.PreferredUnderwriterKeys = this.$store.getters.getPreferredUnderwriterKeys;
      profile.StateLicenses = this.StateLicenses;
      profile.EnableBetaFeatures = this.enableBetaFeatures;
      profile.Confirmed = true;

      profile.Submissions_SendToMe = this.Submissions_SendToMe;
      //profile.Submissions_UseCSTClearance = this.Submissions_UseCSTClearance;
      profile.Submissions_AlternateEmails = this.Submissions_AlternateEmails;
      profile.Submissions_VisibilityEmails = this.Submissions_VisibilityEmails;


      this.saving = true;
      UserProfileService.put({
        body: profile
      }).then((data) => {
        this.$store.commit('setUserProfile', data);
        this.$store.dispatch('loadDefaultUnderwriter');
        this.saving = false;
        this.profile = data;

        this.$forceUpdate();

        this.$store.dispatch('toastSuccess', {Message: 'Profile has been updated.'});

        if (redirect) {
          profile.Confirmed = false;
           const Firstname = this.$store.getters.getUserGivenName 
           const LastName = this.$store.getters.getUserLastName
           UserProfileService.newUser({
             firstName: Firstname,
             lastname: LastName,
            body: profile
          });
          this.$router.push({ path: '/dashboard'})
        }
        
      }).catch(() => {this.saving = false;})
    },
    getUserProfile() {
      UserProfileService.get().then((data) => {
        this.$store.commit('setUserProfile', data);
        this.profile = data;
        this.StateLicenses = data.StateLicenses;
        this.enableBetaFeatures = data.EnableBetaFeatures;
        this.Submissions_SendToMe = data.Submissions_SendToMe;
        //this.Submissions_UseCSTClearance = data.Submissions_UseCSTClearance;
        this.Submissions_AlternateEmails = data.Submissions_AlternateEmails;
        this.Submissions_VisibilityEmails = data.Submissions_VisibilityEmails;
      });

      UnderwriterService.userIsUnderwriter().then(result => this.isUnderwriter = result);

    }
  },
  computed: {
    profilePicturePath() {
      const baseURL = this.$http.defaults.baseURL;
      return baseURL + '/api/TempFile/' + this.ProfilePicture.FileName;
    },
    Submissions_AlternateEmailsRules() {
      if (this.Submissions_SendToMe === false) {
        return 'min_count:1|noDuplicateTextEntries';
      }
      return 'noDuplicateTextEntries';
    },
    isAgent() {
      return this.$store.getters.getUserRole == 'agent';
    },
    amlinkProfile() {
      return this.$store.getters.getAmlinkProfile;
    },
    user() {
      return this.$store.getters.getUser;
    },
    floodFeatureEnabled() {
      return this.$store.getters.isFeatureFlagEnabled("FloodForm")
    }
  },
  watch: {
    '$store.getters.getUserProfile'(val) {
      if (this.personalLinesUnderwriter && this.user?.profile?.sub !== this.profile?.id) {
        this.getUserProfile()
      }
    }
  },
  mounted() {
    this.getUserProfile()
  }
}
</script>

<style scoped>
  ::v-deep .avatar {
    display: block;
    margin: 0 auto;
  }
</style>

