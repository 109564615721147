<template>
  <div>
        <v-container v-if="$store.getters.getUserProfile">
            <v-row v-if="form">
                <v-col md="3" cols="12">

                    <Timeline
                        v-bind="{
                            form, 
                            currentStepNumber, 
                            activeSectionNumber,
                            module: this.module
                        }"
                        @stepClick="stepClick" />                

                </v-col>
                <v-col md="9" cols="12">
                    
                    <v-row>
                        <v-col md="4" cols="12">
                            <v-btn v-if="currentStepIsFirstVisibleStep()" outlined class="go-back"
                                @click="backToQuote">
                                <i class="fas fa-chevron-left"></i>&nbsp;Return To Quotes
                            </v-btn>
                            <v-btn v-else class="go-back" outlined @click="backToPreviousStep">
                                <i class="fas fa-chevron-left"></i>&nbsp;Go Back
                            </v-btn>
                        </v-col>
                        <v-col md="8" cols="12" class="text-right">
                            <slot name="admin"></slot>
                            <QuoteProductHeader form="Additional" />
                        </v-col>
                    </v-row>

                    <h1>
                        {{step.title}}
                    </h1>
                    <p>
                        Required *
                        
                        <v-btn x-small color="secondary" @click="prefill" v-if="showPrefillButton" class="ml-2">
                            Prefill
                        </v-btn> 

                        <SubmissionJson :submissionId="submissionId"  class="ml-2" style="display: inline;" />    
                    </p>
                    <ValidationObserver tag="span" ref="observer">
                        <form @submit.prevent="validate()" v-if="form">
                            <div v-for="(section, index) in step.sections" :key="index">
                                <intersect @enter="activeSectionNumber = index;" :key="index" :threshold="[0.0]" rootMargin="-50% 0px -50% 0px" v-if="showSection(section)">
                                    <v-card class="py-8 px-8 mb-8" :class="{'active-section-card': activeSectionNumber == index}">
                                        <h3 v-if="section.title">{{section.title}}</h3>
                                        <p v-if="section.subtitle" class="pt-2" v-html="section.subtitle"></p>
                                        <span v-for="(control) in section.controls" :key="control.name + formId">
                                          <DynamicFormControl :control="control"
                                                              :module="module"
                                                              :salesChannel="channel"
                                                              :disabled="disabled || loading"
                                                              v-if="showControl(control.name)"
                                                              @change="updateValue" />
                                        </span>
                                    </v-card>
                                </intersect>
                            </div>

                            <div class="text-right mb-4">
                                <FormButton 
                                    type="button" 
                                    label="Save"
                                    color="white"
                                    class="ml-4"
                                    @click="saveForLater"
                                    :disabled="disabled || loading || saving"
                                ></FormButton>

                                <FormButton 
                                    v-if="currentStepIsLastVisibleStep()"
                                    type="button" 
                                    label="Review Submission"
                                    color="white"
                                    class="ml-4"
                                    :disabled="loading || saving"
                                    @click="openDocument"
                                ></FormButton>

                                <v-tooltip right
                                        :disabled="!isMoratoriumForRisk">
                                    <template v-slot:activator="{ on }">
                                        <span v-on="on">
                                            <!-- <FormButton type="submit"
                                                        :label="submitLabel"
                                                        :disabled="isMoratoriumForRisk || disabled || loading"></FormButton> -->

                                            <v-btn color="secondary" type="submit"  large class="ml-4"
                                                :disabled="
                                                    isMoratoriumForRisk || 
                                                    disabled || loading || saving || 
                                                    ((expired || daysUntilMinimumSubmissionDate) && !canBackdate && currentStepIsLastVisibleStep()) || 
                                                    submissionIsComplete ||
                                                    priceStatus != 'Firm'
                                                " 
                                                key="save">
                                                    <Loading v-if="loading" :size="24" class="mr-2" />
                                                    {{submitLabel}}
                                            </v-btn>
                                        </span>
                                    </template>
                                    <span>This policy cannot be bound because there is a moratorium in the area of the risk.</span>
                                </v-tooltip>
                            </div>

                            <v-row v-if="(expirationDate || channel == 'Digital') && !submissionIsComplete">
                                <v-col md="6" offset-md="6" cols="12">
                                    <div v-if="expired">
                                        <v-alert type="warning" icon="fal fa-exclamation-triangle"  dense v-if="canBackdate">
                                            This offer is no longer valid. However, you have permission to submit past the expiration date.
                                        </v-alert>
                                        <v-alert type="error" dense v-else>
                                            This offer is no longer valid.
                                        </v-alert>
                                    </div>
                                    <v-alert type="info" dense v-else>
                                        Offer valid for <RemainingTime :date="expirationDate" v-if="expirationDate" :addDays="0" />.
                                    </v-alert>
                                </v-col>
                            </v-row>

                            <v-row v-if="daysUntilMinimumSubmissionDate">
                                <v-col md="6" offset-md="6" cols="12">
                                    <v-alert type="warning" dense icon="fal fa-exclamation-triangle">
                                        {{daysUntilMinimumSubmissionDate}}&nbsp;{{ daysUntilMinimumSubmissionDate > 1 ? 'days' : 'day'}} remaining until you can submit.
                                    </v-alert>
                                </v-col>
                            </v-row>

                            <br><br><br>
                        </form>
                    </ValidationObserver>
                </v-col>
            </v-row>


            <Dialog title="Review Submission" :value="showSummary" width="1000px" @close-dialog="showSummary = false;">
                <template v-slot:card-text>
                    <DocumentPreview v-bind="previewConfig" />
                </template>
                <template v-slot:card-actions>
                    <v-btn color="secondary" text type="button" @click="showSummary = false">Close</v-btn>
                </template>
            </Dialog>
          
            <QuoteComparisonGridAreYouSureDialog
                v-if="showQuoteComparisonGridAreYouSureModal"
                onAdditionalForm="true"
                @agree="continueQuoteComparisonGridAreYouSureModal"
                @close="closeQuoteComparisonGridAreYouSureModal">
            </QuoteComparisonGridAreYouSureDialog>

        </v-container> 
    </div>
</template>

<script>
import DynamicFormControl from '@/components/form/DynamicFormControl.vue'
import FormButton from '@/components/form/FormButton.vue'
import Intersect from 'vue-intersect'
import Timeline from '@/components/shared/Timeline.vue'
import Dialog from '@/components/shared/Dialog.vue'
import RemainingTime from '@/components/shared/RemainingTime.vue'
import DocumentPreview from '@/components/views/Quote/DocumentPreview.vue'
import QuoteProductHeader from '@/components/views/Quote/QuoteProductHeader.vue'
import formMixin from '@/scripts/form-mixin'
import { FormStateService } from '@/api/FormStateService'
import { SubmissionService } from '@/api/SubmissionService'
import { MappingService } from '@/api/MappingService'
import { QuotesService } from '@/api/QuotesService'
import { FormStateEvent } from '@/api/index.defs'
import { prefillDigitalForm, prefillHumanForm } from '@/scripts/prefills'
import { newGuid, getDaysBetweenDates, formatDateForDisplay, formatMoney } from '@/scripts/helper'
import { errorHandler } from '@/plugins/axios'
import SubmissionJson from '@/components/shared/SubmissionJson.vue'
import { FeatureFlagsService } from '@/api/FeatureFlagsService'
import QuoteComparisonGridAreYouSureDialog from '@/components/views/Quote/QuoteComparisonGridAreYouSureDialog.vue'
import { isRiskUnderMoratorium } from '@/scripts/moratoriums'

export default {
    name: 'AdditionalForm',
    components: { 
        DynamicFormControl, 
        FormButton,
        Intersect,
        Timeline,
        Dialog,
        DocumentPreview,
        QuoteProductHeader,
        RemainingTime,
        SubmissionJson,
        QuoteComparisonGridAreYouSureDialog
    },
    mixins: [
        formMixin
    ],
    data() {
        return {
            module: 'additionalForm',
            submissionId: null,
            selectedQuoteId: null,
            postalCode: 0,
            state: '',
            county: '',
            coverageOption: '',
            windPeril: false,
            vacant: false,
            program: '',
            showSummary: false,
            moratoriumFeatureFlagEnabled: false,
            isRiskUnderMoratorium: false,
            previewConfig: {},
            showQuoteComparisonGridAreYouSureModal: false,
            floodRefinementFeatureFlagEnabled: false,
            v1EndorsementsFeatureFlagEnabled: false
        }
    }, 
    props: {
        formInit: Object,
        mode: {
            type: String,
            default: null
        },
        disabled: {
            type: Boolean,
            default: false
        },
    },
    methods: {
        formatDateForDisplay,
        formatMoney,
        newGuid() {
            return newGuid();
        },
        getSubmission() {
            return {...this.$store.getters["additionalForm/getSubmission"]}
        },
        getSelectedQuote() {
            return {...this.$store.getters["additionalForm/getSelectedQuote"]}
        },
        coverageValue(val) {
            if (val === 0) {
                return 'Excluded'
            }

            return this.formatMoney(val)
        },
        onSubmit () {
            const form = JSON.parse(JSON.stringify(this.$store.getters.getForm('additionalForm')));
            if (form.id && this.mode != 'Admin') {
                this.loading = true;
                FormStateService.patch({
                    eventType: FormStateEvent.Save,
                    body: form
                })
                .then(() => {
                    this.afterSave();
                })
            } else {
                this.afterSave();
            }
        }, 
        afterSave() {
            if (this.currentStepNumber >= (this.form.steps.length - 1)) {
                this.submitForm();
            } else {
                const val = this.getControlValue('EffectiveDate');
                if (this.floodRefinementFeatureFlagEnabled && this.coverageOption == 'flood' && formatDateForDisplay(new Date(this.selectedQuote.EffectiveDate.value),true) !== val) {
                    let selectedQuote = this.getSelectedQuote(); 
                    selectedQuote.IsLatestQuote = false;
                    this.$store.commit('additionalForm/setSelectedQuote', selectedQuote);
                    QuotesService.refine({ body: this.submissionId});
                } 
                this.proceedToNextStep();
            }
        },
        saveForLater() {
            this.saving = true;
            const form = JSON.parse(JSON.stringify(this.$store.getters.getForm('additionalForm')));
            if (form.id && this.mode != 'Admin') {
                FormStateService.patch({
                    eventType: FormStateEvent.Save,
                    body: form
                }).then(() => {
                    this.saving = false;
                });
            }
        },
        async openDocument() {

            await FormStateService.patch({
                eventType: FormStateEvent.Save,
                body: this.form
            })

            const submission = await SubmissionService.get({id: this.submissionId});
            this.$store.commit('additionalForm/setSubmission', submission);

            this.previewConfig = {
                mode: this.channel,
                submission: submission,
                save: true
            };

            const selectedQuote = this.getSelectedQuote();
            if (!selectedQuote) return;

            if (selectedQuote && (selectedQuote.SalesChannel == "Digital" || selectedQuote.SalesChannel == "Munich")) {
                this.previewConfig.policyKey = selectedQuote.id;
            }
            if (selectedQuote && (selectedQuote.SalesChannel == "Vave" || selectedQuote.SalesChannel == "Hiscox")) {
                this.previewConfig.fileRefId = selectedQuote.QuoteApplicationFileRef;
            }

            this.showSummary = true;
        },
        async submitForm() {

            if (!this.isMoratoriumForRisk) {

                // let eventType = null;
                // switch(this.channel) {
                //     case "Human":
                //         eventType = FormStateEvent.Complete_Human; break;
                //     case "Digital":
                //         eventType = FormStateEvent.Complete_Digital; break;
                //     default: break;
                // }

                const eventType = FormStateEvent.Submit;

                this.loading = true;

                FormStateService.patch({
                    eventType: eventType,
                    body: this.form
                }, {
                    handleError: false,
                    timeout: 120000 //2 minutes to allow for delayed LogicApp response
                }).then(() => {
                    this.$router.push({ path: '/quote/confirmation', query: { id: this.submissionId } });
                    this.loading = false;
                }).catch((err) => {          
                    if (err.response && err.response.status == 400 && err.response.data.includes('Payment Error')) {
                        this.$refs.observer.setErrors({ Payment: [err.response.data] })
                        this.scrollToFirstError();
                        this.loading = false;
                    } else {
                        errorHandler(err);
                        this.loading = false;
                    }
                });
            }
        },
        backToQuote() {
            const val = this.getControlValue('EffectiveDate');

            if (this.floodRefinementFeatureFlagEnabled && this.coverageOption == 'flood' && formatDateForDisplay(new Date(this.selectedQuote.EffectiveDate.value),true) !== val) {
                this.showQuoteComparisonGridAreYouSureModal = true
            } else {
                FormStateService.patch({
                    eventType: FormStateEvent.Save,
                    body: this.form
                })
                .then(() => {
                    this.$router.push({ path: '/quote/quote', query: { id: this.submissionId } })
                })
            }
        },
        prefill() {
            if (this.formInit.channel == 'Digital') {
                prefillDigitalForm();
            } else if (this.formInit.channel == 'Human') {
                prefillHumanForm();
            }
        },
        continueQuoteComparisonGridAreYouSureModal() {
            this.$router.push({ path: '/quote/quote', query: { id: this.submissionId, requote: true } })
        },
        closeQuoteComparisonGridAreYouSureModal() {
            this.showQuoteComparisonGridAreYouSureModal = false;
        }
    },
    computed: {
        channel() {
            const submission = { ...this.$store.getters["additionalForm/getSubmission"] }
            if (submission) return submission.CurrentForm;
            return null;
        },
        submitLabel() {
            if (this.currentStepIsLastVisibleStep()) return 'Submit';
            else return 'Continue';
        },
        isMoratoriumEnabled() {
            return  !(this.channel === 'Human');
        },
        isMoratoriumForRisk() {
            if (this.channel === 'Human') return false;
            // if moratorium feature flag enabled and if the product is flood, use moratorium api
            if (this.isMoratoriumEnabled && this.coverageOption == "flood")
            {
                return this.isRiskUnderMoratorium && (this.submitLabel === 'Submit');
            }
            else
            {
                if (this.postalCode && this.state && this.county && this.program) {
                const moratoriumsForRisk = this.$store.getters['resource/currentClosingsForRisk'](this.postalCode, this.state, this.county, this.program)
                return moratoriumsForRisk && moratoriumsForRisk.length > 0 && this.submitLabel === 'Submit'
              }
            }
            return false;
        },
        expired() {
            const now = new Date();
            if (this.expirationDate) {
                return now > this.expirationDate;
            } else if (this.channel == 'Digital') {
                //Handle old imported Seacoast quotes lacking Expiration/TermRateCalcValidThru
                return true;
            }
            return false;
        },
        expirationDate() {
            const quote = this.getSelectedQuote();
            if (quote && quote.Expiration) {
                let exp = new Date(quote.Expiration);
                return exp;
            }
            return null;
        },
        priceStatus() {
            const quote = this.getSelectedQuote();
            if (!quote) return null;
            if (!quote.PriceStatus) return 'Firm';
            else return quote.PriceStatus;
        },
        daysUntilMinimumSubmissionDate() {
            if (this.channel == 'Digital' || this.channel == 'Munich' || this.channel == 'TestV0') {
                const today = new Date();
                today.setHours(0,0,0,0);
                //const submission = this.getSubmission();
                //const effectiveDate = submission.Data.EffectiveDate.value;
                const quote = this.getSelectedQuote();
                let effectiveDate = quote.EffectiveDate ? new Date(quote.EffectiveDate.value) : null;
                if (!effectiveDate) {
                    const effDate = this.getControlValue('EffectiveDate');
                    effectiveDate = new Date(effDate);
                }
                const minDate = effectiveDate;
                minDate.setHours(0,0,0,0);
                minDate.setDate(minDate.getDate() - 30)
                if (minDate > today) {
                    return getDaysBetweenDates(today, minDate);
                }
            }
            return null;
        },
        submissionIsComplete() {
            const submission = this.getSubmission();
            if (!submission) return false;
            return submission.Complete;
        },
        submission() {
            return this.getSubmission()
        },
        policyTerm() {
            const addlEffectiveDate = this.$store.getters.getControlValue('additionalForm', 'EffectiveDate');
            const addlExpirationDate = this.$store.getters.getControlValue('additionalForm', 'ExpirationDate');

            if (addlEffectiveDate && addlExpirationDate) {
              return this.formatDateForDisplay(addlEffectiveDate) + " - " + this.formatDateForDisplay(addlExpirationDate)
            } else {
              return this.formatDateForDisplay(this.submission?.Data?.EffectiveDate?.value) + " - " + formatDateForDisplay(this.submission?.Data?.ExpirationDate?.value)
            }
        },
        selectedQuote() {
            return this.getSelectedQuote();
        },
        canBackdate() {
            return this.$store.getters.userHasRestrictedFeature('Submission_BackdateEffectiveDate');
        },
        activeInsurancePolicy() {
            return this.$store.getters.getControlValue('additionalForm', 'ActiveInsurancePolicy');
        },
        loanClosingSituation() {
            return this.$store.getters.getControlValue('additionalForm', 'LoanClosingSituation');
        },
        newlyClassified() {
            return this.$store.getters.getControlValue('additionalForm', 'NewlyClassified');
        }
    },
    watch: {
        activeInsurancePolicy(val) {
            if (val !== undefined && this.$refs.observer) {
                this.$refs.observer.refs['EffectiveDate'].validate()
            }
        },
        loanClosingSituation(val) {
            if (val !== undefined && this.$refs.observer) {
                this.$refs.observer.refs['EffectiveDate'].validate()
            }
        },
        newlyClassified(val) {
            if (val !== undefined && this.$refs.observer) {
                this.$refs.observer.refs['EffectiveDate'].validate()
            }
        },
    },
    async mounted() {
        this.submissionId = this.formInit.ResultId;
        this.$store.commit('additionalForm/initAdditionalForm', { ...this.formInit });
        this.setStepHash();

        FeatureFlagsService.isEnabled({ featureName: "FloodRefinement" })
        .then((response) => {
          this.floodRefinementFeatureFlagEnabled = response;
        }); 

        await FeatureFlagsService.isEnabled({ featureName: "V1Endorsements" })
          .then((response) => {
            this.v1EndorsementsFeatureFlagEnabled = response;
          });
    
        if (!this.showStep(this.step)) {
            this.proceedToNextStep();
        }

        // set Requires Inspection hidden value
        if (this.selectedQuote.SalesChannel === "VavePersonalLines") {
            this.$store.commit('updateFormControl', {
              module: this.module,
              name: 'RequiresInspection',
              value: this.selectedQuote.QuoteBindRequirements.RequiresInspection
            });
        }

        this.$forceUpdate();

        // get fresh moratorium data to check risk before binding
        this.$store.dispatch('resource/getCatastropheClosings');

        this.coverageOption = this.getControlValue('CoverageOption');
        this.windPeril = this.getControlValue('WindPeril');
        this.vacant = this.getControlValue('Occupancy') ? this.getControlValue('Occupancy').includes('Vacant') : false;
        this.postalCode = this.getControlValue('Risk') ? this.getControlValue('Risk').PostalCode : null;
        this.state = this.getControlValue('Risk') ? this.getControlValue('Risk').State : null;
        this.county = this.getControlValue('Risk') ? this.getControlValue('Risk').County : null;

        MappingService.getProgramCode({
            coverageOption: this.coverageOption,
            windPeril: this.windPeril,
            vacant: this.vacant
        }).then((programCode) => {
            this.program = programCode
        });



        if (this.coverageOption == "flood") {
            this.isRiskUnderMoratorium = await isRiskUnderMoratorium(this.submissionId, this.getSelectedQuote().id);
        }
    }
}
</script>