<template>
    <v-container>
        <v-row>
            <v-col md="12" cols="12" class="text-center">
                <h1>What coverage do you need?</h1>
                <ValidationObserver tag="span" v-slot="{ handleSubmit }">
                    <form @submit.prevent="handleSubmit(onSubmit)">

                        <v-row>
                            <v-col cols="12"> 
                                <DynamicFormControl 
                                    :control="{
                                        type: 'RadioItemGroup',
                                        name: 'CoverageOption',
                                        value: CoverageOption,
                                        config: {
                                            label: null,
                                            rules: 'required',
                                            col: 12,
                                            itemCol: 2,
                                            iconSize: '86px',
                                            multiple: false,
                                            justify: 'center',
                                            showCheck: true,
                                            circle: true,
                                            containerSize: '100px',
                                            height: '200px',
                                            disabled: processing,
                                            options: programOptions
                                        }
                                    }"
                                    @change="updateCoverageOption" 
                                />
                            </v-col>
                        </v-row>

                        <v-row v-if="!CoverageOption">
                            <v-col cols="12" class="pb-0">
                              <label><a :href="professionalLinesUrl">Looking for Professional Lines Coverage?</a></label>
                            </v-col>
                        </v-row>

                        <v-row v-if="CoverageOption && prompt">
                            <v-col cols="12" class="pb-0">
                                <label><strong>{{prompt}}</strong></label>
                            </v-col>
                        </v-row>

                        <v-row v-if="CoverageOption == 'wind'">
                            <v-col cols="12" class="py-0">
                                <DynamicFormControl 
                                    :control="{
                                        type: 'RadioItemGroup',
                                        name: 'ExtraWind',
                                        value: ExtraWind,
                                        config: {
                                            label: null,
                                            rules: null,
                                            col: 12,
                                            itemCol: 3,
                                            iconSize: '40px',
                                            containerSize: '40px',
                                            multiple: false,
                                            justify: 'center',
                                            alignment: 'horizontal',
                                            options: extrasOptions,
                                            disabled: processing,
                                            short: true
                                        }
                                    }"
                                    @change="ExtraWind = $event.value" 
                                />
                            </v-col>
                        </v-row>

                        <v-row v-show="CoverageOption && CoverageOption != 'flood'">
                            <v-col md="12" cols="12" class="text-left pb-0">
                                <DynamicFormControl 
                                    :control="{
                                        type: 'UnderwriterAutocomplete',
                                        name: 'Underwriter',
                                        config: {
                                            label:'Choose your Amwins Underwriter',
                                            rules: 'required',
                                            col: 12,
                                            disabled: processing
                                        }
                                    }"
                                    @change="underwriter = $event.value" 
                                />
                            </v-col>
                        </v-row>

                        <v-row v-show="CoverageOption" v-if="!isAgent">
                            <v-col md="12" cols="12" class="pt-0">
                                <DynamicFormControl 
                                    :control="{
                                        type: 'AgentAutocomplete',
                                        name: 'Agent',
                                        config: {
                                            label:'Retail Agent',
                                            rules: 'required',
                                            col: 12,
                                            disabled: processing,
                                        }
                                    }"
                                    @change="agent = $event.value" 
                                />

                                <!-- <p class="mb-0 text-left" style="margin-top: -15px; font-size: 12px; line-height: 14px;">
                                    Can't find an agent? 
                                    <a href="https://www.amwins.com/solutions/small-accounts/personal-lines/portal-sign-up" target="_blank">Contact&nbsp;us</a>.
                                </p> -->
                            </v-col>
                        </v-row>

                        <div v-show="CoverageOption">
                            <v-row class="d-flex align-top">
                                <v-col md="12" cols="12" class="text-center py-0">
                                    <br>
                                    <label><strong>Prefill Submission (optional):</strong></label>
                                </v-col>
                                <v-col v-if="betaFeaturesEnabled" md="6" cols="12" class="text-left">
                                    <DynamicFormControl 
                                        :control="{
                                            type: 'FileUploadSingle',
                                            name: 'ACORDForm',
                                            value: ACORDForm,
                                            config: {
                                                label:'ACORD Form',
                                                rules: null,
                                                accept:'.pdf',
                                                disabled: processing || account != null,
                                                short: true                                           
                                            }
                                        }"
                                        isBetaFeature
                                        @change="ACORDForm = $event.value" 
                                    />
                                </v-col>
                                <!-- <v-col md="2" cols="12" class="px-0">
                                    <p class="mb-0" style="padding: 26px 0;">OR</p>
                                </v-col> -->
                                <v-col v-if="betaFeaturesEnabled" md="auto" cols="12" class="px-0 divider-col" style="width: 0;">
                                    <div class="divider"></div>
                                </v-col>
                                <v-col :md="betaFeaturesEnabled ? 6 : 12" cols="12" class="text-left">
                                    <DynamicFormControl 
                                        :control="{
                                            type: 'AccountAutocomplete',
                                            name: 'Account',
                                            value: account,
                                            config: {
                                                label:'Copy from prior Submission in the system',
                                                rules: '',
                                                col: 12,
                                                disabled: processing || ACORDForm != null,
                                                short: true,
                                                footnote: 'You have the option to Search for a previous risk you\'ve entered and copy (CLONE) that data into your new Submission.'
                                            }
                                        }"
                                        @change="account = $event.value" 
                                    />
                                    <AccountCard v-if="account" :account="account" class="text-left" /> 
                                </v-col>
                            </v-row>
                        </div>


                        <br>
                        <br>

                        <v-btn color="secondary" x-large type="submit" 
                            v-show="CoverageOption"
                            :disabled="!CoverageOption || (CoverageOption == 'wind' && !ExtraWind) || processing || loading" 
                            key="save">
                                <Loading v-if="loading" :size="24" class="mr-2" />
                                Start Submission
                        </v-btn>

                        <br>

                        <template v-if="processing">
                            <br><br>
                            <v-row v-show="CoverageOption">
                                <v-col md="6" offset-md="3" cols="12">
                                    <v-alert dense type="info" class="text-left">
                                        You will receive a notification when your submission is ready to start.
                                    </v-alert>
                                </v-col>
                            </v-row>
                        </template>
                    </form>
                </ValidationObserver>
                <br><br><br>
            </v-col>
        </v-row>
    </v-container> 
</template>

<script>
import DynamicFormControl from '@/components/form/DynamicFormControl.vue'
import AccountCard from '@/components/shared/AccountCard.vue'
import Environment from '@/scripts/environment'
import { FormStateService } from '@/api/FormStateService'
import { AgencyService } from '@/api/AgencyService'
import { AgentService } from '@/api/AgentService'
import { UnderwriterService } from '@/api/UnderwriterService'
import { formatDateForDisplay, getFormStateJSON } from '@/scripts/helper'
import { AccountService } from '@/api/AccountService'

export default {
    name: 'StartQuote',
    components: { 
        DynamicFormControl,
        AccountCard
    },
    data() {
        return {
            CoverageOption: null,
            Extras: [],
            ExtraWind: null,
            ACORDForm: null,
            processing: false,
            loading: false,
            agent: null,
            underwriter: null,
            account: null
        }
    },
    computed: {
        programOptions() {
            const programOptions = [
                {
                    value: 'ho3',
                    icon: 'fak fa-home',
                    text: 'Home (Form HO3/5)',
                },
                {
                    value: 'dp3',
                    icon: 'fak fa-dwelling',
                    text: 'Dwelling (Form DP1/3)',
                },
                {
                    value: 'ho6',
                    icon: 'fak fa-condo',
                    text: 'Condo (Form HO6)',
                },
                {
                    value: 'ho4',
                    icon: 'fak fa-renters',
                    text: 'Renters (Form HO4)',
                },
                {
                    value: 'wind',
                    icon: 'fak fa-wind',
                    text: 'Wind Only',
                }
            ];

            if (this.$store.getters.isFeatureFlagEnabled("FloodForm")) {
                programOptions.push({
                    value: 'flood',
                    icon: 'fak fa-flood',
                    text: 'Flood'
                });
            } else
            {
                programOptions.push({
                    value: 'flood',
                    icon: 'fak fa-flood',
                    text: 'Flood',
                    disabled: true,
                    comingsoon: true
                });
            }
            

            return programOptions;
        },
        prompt() {
            if (this.CoverageOption == 'wind') {
                return 'Please select which type of Wind Only coverage you need:*'
            } else {
                return '';
            }
        },
        professionalLinesUrl() {
            return Environment.CL_UI_HOST
        },
        extrasOptions() {
            if (this.CoverageOption == 'wind') {
                return [
                    {
                        value: 'ho3_windonly',
                        icon: 'fak fa-home',
                        text: 'Wind Only Home',
                    },
                    {
                        value: 'ho6_windonly',
                        icon: 'fak fa-condo',
                        text: 'Wind Only Condo',
                    },
                ];
            } else {
                return [];
            }
        },
        isAgent() {
            return this.$store.getters.userHasRole('agent');
        },
        betaFeaturesEnabled() {
            return !this.isAgent && this.$store.getters.getUserProfile?.EnableBetaFeatures
        }
    },
    methods: {
        updateCoverageOption($event) {
            this.Extras = [];
            this.ExtraWind = null;
            this.CoverageOption = $event.value;
        },

        async onSubmit () {
            this.loading = true;

            let agent = this.agent;
            if (this.isAgent) {
                const human_id = this.$store.getters.getUserHumanId;
                agent = await AgentService.get({id: human_id});
            }

            let agency = null;
            if (agent) {
                agency = await AgencyService.get({id: agent.OrgBranchId})
            }

            let templateType = 'HO';
            if (this.CoverageOption == 'flood')
            {
              templateType = 'Flood';

                if (agency) {
                    await UnderwriterService.getFlood({
                    state: agency.State
                    }, {
                    handleError: false
                    }).then((data) => {
                    if (data && data.Active) {
                        this.underwriter = data;
                    }
                    }).catch((e) => {
                    console.log('e', e)
                    })
                }
                else throw "PANIC: Missing Agency Object on Submission Create"
            }

            FormStateService.post({
                body: {
                    FormState: getFormStateJSON(templateType),
                    FormStateRequest: {
                        resultData: {
                            CoverageOption: {
                                value: this.CoverageOption == 'wind' ? this.ExtraWind : this.CoverageOption
                            },
                            Underwriter: {
                                value: this.underwriter
                            },
                            Agent: {
                                value: agent
                            },
                            Agency: {
                                value: agency
                            },
                            CreateDate: {
                                value: formatDateForDisplay(new Date())
                            },
                            OCRFile: this.ACORDForm ? {value: this.ACORDForm} : null
                        },
                        data: {
                            ocrFile: this.ACORDForm ? this.ACORDForm.FileName : null,
                            cloneAccountId: this.account ? this.account.id : null,
                        }
                    }
                }
                
            }).then((data) => {
                if (data.id) {
                    this.$router.push({ path: '/quote/quote', query: { id: data.ResultId } })
                } else {
                    this.processing = true;
                }
                this.loading = false;
            }) 
        },
    },
    watch: {
        ACORDForm(val) {
            console.log(val)
        },
    },
    mounted() {
        const clone = this.$route.query.clone
        if (clone) {
            AccountService.get({id: clone})
            .then((data) => {
                this.account = data;
            });
        }
    }
}
</script>

<style scoped lang="scss">
@import '@/style.scss';

.divider {
    // background-color: $gray4;
    background-color: #002B49;
    width: 2px;
    height: 58px;
    margin: 9px 0;
}

@media screen and (max-width: 960px) {
    .divider-col {
        display: none;
    }  
}

</style>
