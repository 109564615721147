import {
  Amwins_Orbits_Contracts_Models_Submissions_SubmissionCoveragesV0,
  System_Text_Json_JsonElement,
  Amwins_Orbits_Contracts_Models_v1_CoverageInformation_CoveragesV1,
  Amwins_Orbits_Contracts_Models_Submissions_SubmissionValueWithDataServiceV0_System_Nullable_System_Decimal,
  Amwins_Orbits_Contracts_Models_AdditionalInterestInformation_AdditionalInterest
} from "@/api/index.defs";
import { newGuid, formatDateForDisplay } from "@/scripts/helper";
import { PolicyV2, EndorsementRequestV2 } from "@/api/ContractCleanName";

export function createEndorsementRequestV2(policy: PolicyV2, formState: any, correctDiscrepencies: boolean = false, canNotEditRisk: boolean = false, canNotEditCoverages: boolean = false, isSupportOrHigher: boolean = false): EndorsementRequestV2 {
  let additionalInterests: Array<Amwins_Orbits_Contracts_Models_AdditionalInterestInformation_AdditionalInterest> = [];
  const additionalInterestsList = formState.data['AdditionalInterestsList']?.value ?? []
  for (const additionalInterest of additionalInterestsList) {
    additionalInterests.push({ Name: additionalInterest.Name, Address: additionalInterest.Address, AdditionalInterestType: additionalInterest.Type });
  }
  let requestedInsuredChanges = { ...policy.Insured, ...formState.data['Insured']?.value }
  if (formState.data['MailingAddressSame']?.value === false) {
    requestedInsuredChanges.MailingAddress = formState.data['MailingAddress']?.value
  } else {
    const riskAddress = formState.data['Risk']?.value
    const riskStreetAddress = riskAddress.StreetNumber + ' ' + riskAddress.StreetName
    const risk = {
      City: riskAddress.City,
      CountryCode: riskAddress.CountryCode,
      PostalCode: riskAddress.PostalCode,
      State: riskAddress.State,
      StreetAddress: riskStreetAddress,
    }
    requestedInsuredChanges.MailingAddress = risk
  }

  const risks = {
    ...policy.Risk,
    ...formState.data['Risks']?.value
  };
  risks.CoApplicant = formState.data['CoApplicant']
  risks.CoApplicantDateOfBirth.value = formState.data['CoApplicantDateOfBirth']?.value ? new Date(formatDateForDisplay(formState.data['CoApplicantDateOfBirth'].value, true)) : null
  risks.CoApplicantFirstName = formState.data['CoApplicantFirstName']
  risks.CoApplicantLastName = formState.data['CoApplicantLastName']
  risks.CoApplicantRelationship = formState.data['CoApplicantRelationship']
  risks.Occupation = formState.data['Occupation']

  const coverages = {
    ...policy.Coverages,
    ...formState.data['Coverages']?.value
  };
  // stringify and parse JsonElement Coverages to get inacessible properties
  const stringCoverages = JSON.stringify(policy.Coverages)
  const jsonCoverages = JSON.parse(stringCoverages)
  const rcv = jsonCoverages.CovA.DataServiceValue ?? 0
  const covA = formState.data['CovA'].value
  // if CovA is reduced less than RCV, set RCV to CovA
  coverages.CovA.DataServiceValue = (isSupportOrHigher && covA < rcv) ? covA : rcv

  const endorsement: EndorsementRequestV2 = {
    CoverageModelType: policy.CoverageModelType,
    RiskModelType: policy.RiskModelType,
    ShouldCompleteInspection: correctDiscrepencies,
    EndorsementId: newGuid(),
    ExternalPolicyId: policy.ExternalPolicyId,
    PolicyId: policy.PolicyId,
    SalesChannel: policy.SalesChannel,
    SubmissionNumber: policy.SubmissionNumber,
    EffectiveDate: new Date(formatDateForDisplay(formState.data['EndorsementEffectiveDate']?.value, true)),
    MessageId: newGuid(),
    SubmissionId: policy.SubmissionId,
    RequestedCoverageChanges: canNotEditCoverages ? null : coverages,
    RequestedInsuredChanges: requestedInsuredChanges,
    RequestedPrimaryMortgageeChanges: !(formState.data['PrimaryMortgagee']?.value) ? null : { ...policy.PrimaryMortgagee, ...formState.data['PrimaryMortgagee']?.value },
    RequestedSecondaryMortgageeChanges: !(formState.data['SecondaryMortgagee']?.value) ? null : { ...policy.SecondaryMortgagee, ...formState.data['SecondaryMortgagee']?.value },
    RequestedRiskChanges: canNotEditRisk ? null : risks,
    RequestedAdditionalInterestChanges: additionalInterests,
  }
  return endorsement;
}

function mapCoverages(policy: PolicyV2, formState: any): System_Text_Json_JsonElement {
  if (policy.CoverageModelType.Value === 'SubmissionCoverageV0') {
    return mapSubmissionCoverageV0(formState);
  } else if (policy.CoverageModelType.Value === 'CoverageV1') {
    return mapCoverageV1(formState);
  }

  return {} as System_Text_Json_JsonElement;
}

function mapSubmissionCoverageV0(formState: any): System_Text_Json_JsonElement {
  const hoCoverages: Amwins_Orbits_Contracts_Models_Submissions_SubmissionCoveragesV0 = {
    CovA: { value: getFormStateDataValue(formState, 'CovA') },
    CovB: { value: getFormStateDataValue(formState, 'CovB') } as Amwins_Orbits_Contracts_Models_Submissions_SubmissionValueWithDataServiceV0_System_Nullable_System_Decimal,
  }
  return hoCoverages as System_Text_Json_JsonElement
}

function mapCoverageV1(formState: any): System_Text_Json_JsonElement {
  const floodCoverages: Amwins_Orbits_Contracts_Models_v1_CoverageInformation_CoveragesV1 = {
    CovA: { FloodPeril: { Limit: { Amount: getFormStateDataValue(formState, 'CovA.FloodPeril.Limit.Amount') } } },
  }
  return floodCoverages as System_Text_Json_JsonElement
}

function getFormStateDataValue(formState: any, key: string): any {
  return formState.data[key].value;
}