<template>
  <div>

    <v-container>
      <h1>News</h1>

      <v-container>
        <template v-for="item in sortedNews">
          <v-row :key="item.Id"
                 class="px-0">
            <v-col cols="12"
                   class="px-0">
              <v-card class="news-card">
                <v-row class="news-row" align-content="center" no-gutters>
                  <v-col cols="12"
                         sm="2"
                         class="news-image"
                         :style="{'background-image': 'url(\''+item.ImageUrl+'\')'}">
                  </v-col>
                  <v-col cols="12"
                         sm="10"
                         style="height: 100%;">
                    <div class="pa-4" style="width: 100%; height: 100%; display: block;">
                      <h4 class="text-bold">{{item.Title}}</h4>
                      <p style="margin-bottom: 10px;">
                        {{ item.Abstract }}
                      </p>
                      <v-row>
                        <v-col align-center>
                          <h5 class="text-bold pt-3">
                            {{ formatDateForDisplay(item.Date) }}
                          </h5>
                        </v-col>
                        <v-col class="text-right">
                          <router-link :to="{ path: 'news/article', query: { key: item.Id}}">
                            <v-btn color="secondary" type="button" title="Read More" large>
                              Read More
                            </v-btn>
                          </router-link>
                        </v-col>
                      </v-row>
                    </div>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </template>
      </v-container>

      <v-container v-if="loading">
        <v-row>
          <v-col class="text-center">
            <Loading :size="300" />
          </v-col>
        </v-row>
      </v-container>

    </v-container>

  </div>
</template>

<script>
  import { formatDateForDisplay } from '@/scripts/helper'
  import { NewsFeedService } from '@/api/NewsFeedService'
  import Loading from '@/components/shared/Loading.vue'

  export default {
    name: 'NewsList',
    components: {
      Loading
    },
    data() {
      return {
        loading: false,
        news: []
      }
    },
    computed: {
      sortedNews() {
        let newsListLocal = this.news
        return newsListLocal.sort(function (a, b) {
          if (a.Date > b.Date) return -1
          return a.Date > b.Date ? 0 : 1
        })
      }
    },
    created() {
      this.initialize()
    },
    methods: {
      formatDateForDisplay,
      initialize() {
        this.loading = true
        NewsFeedService.getAmwinsArticles()
          .then((response) => {
            response.value.forEach((article) => {
              NewsFeedService.getAmwinsArticleImage({ key: article.Id })
                .then((imageResponse) => {
                  article.ImageUrl = imageResponse.value[0].Url
                  this.news.push(article)
                })
            })
          })
          .finally(() => {
            this.loading = false
          })
      }
    }
  }
</script>

<style scoped lang="scss">
  @import '@/style.scss';

  .news-card {
    overflow: hidden;
    height: 100%;
    margin: 0 4px;
  }

  .news-card * {
    -webkit-font-smoothing: antialiased;
  }

  .news-card h4 {
    margin-bottom: 5px;
  }

  @media (max-width:582px) {
    .news-image {
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      height: 200px
    }
  }

  @media (min-width:583px) {
    .news-row {
      height: 100%
    }

    .news-image {
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      height: 100%;
    }
  }
</style>
