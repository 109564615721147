var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Dialog',{attrs:{"title":_vm.title,"persistent":true,"value":_vm.value,"scrollable":true,"width":`800px`,"loading":_vm.loading},scopedSlots:_vm._u([{key:"card-text",fn:function(){return [(_vm.isImpersonatingUser)?_c('v-alert',{staticClass:"impersonation-alert",attrs:{"dense":""}},[_vm._v(" Impersonating "+_vm._s(_vm.currentUser.profile.sub)+" | "),_c('a',{on:{"click":_vm.stopUserImpersonation}},[_vm._v("Stop")])]):_c('div'),_c('v-alert',{staticClass:"amlink-alert mb-4",attrs:{"dense":""}},[_vm._v(" "+_vm._s(_vm.bannerMsg)+" ")]),(!_vm.amlinkRequestSent && !_vm.loading)?_c('ValidationObserver',{ref:"validator",attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function({}){return [_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('h3',{staticClass:"mb-4"},[_vm._v(" We were unable to identify your account in our agency management system. Please provide additional information, so that we can get this resolved quickly. ")]),_c('div',{staticClass:"form-body relative grid grid-cols-1 md:grid-cols-3 gap-4"},[_c('div',{staticClass:"col-span-1 md:col-span-3"},[_c('v-row',[_c('v-col',{attrs:{"cols":"2"}},[_c('DynamicFormControl',{attrs:{"control":{
                                name: 'UserEmail',
                                type: 'FormLabel',
                                config: {
                                    label: 'Email:',
                                    col: 12,
                                    short: true
                                }
                            }}})],1),_c('v-col',{attrs:{"cols":"10"}},[_c('DynamicFormControl',{attrs:{"control":{
                                name: 'UserEmail',
                                type: 'FormLabel',
                                config: {
                                    label: _vm.userEmail,
                                    col: 12,
                                    short: true
                                }
                            }}})],1)],1)],1),(_vm.step === 1)?_c('div',{staticClass:"col-span-1 md:col-span-3"},[_c('label',{staticClass:"mb-2 mt-2"},[_vm._v("Have you used another email while doing business with Amwins:")]),_c('DynamicFormControl',{attrs:{"control":{
                              name: 'HasAmlinkEmail',
                              type: 'RadioButtonGroup',
                              value: _vm.hasAmlinkEmail,
                              config: {
                                  col: 12,
                                  small: true,
                                  options: _vm.hasAmlinkEmailOptions
                              }
                            }},on:{"change":function($event){_vm.hasAmlinkEmail = $event.value}}})],1):_vm._e(),(_vm.hasAmlinkEmail === 1 && _vm.step === 1)?_c('div',{staticClass:"col-span-1 md:col-span-3"},[_c('label',{staticClass:"mb-2 mt-2"},[_vm._v("Please enter up to 5 of the emails you have used:")]),_vm._l((_vm.emailList),function(item,index){return [_c('v-row',{key:index},[_c('v-col',{attrs:{"cols":"11"}},[_c('DynamicFormControl',{attrs:{"control":{
                                      name: 'EmailList' + index,
                                      type: 'TextInput',
                                      value: _vm.emailList[index],
                                      config: {
                                          label: 'Email',
                                          rules: 'email|required',
                                          col: 12,
                                          short: true
                                      }
                                  }},on:{"change":function($event){_vm.emailList[index] = $event.value}}})],1),_c('v-col',{attrs:{"cols":"1"}},[(index != 0)?_c('v-btn',{staticClass:"mt-4",attrs:{"color":"#9F2242","type":"button","icon":"","title":"Remove Item"},on:{"click":function($event){return _vm.removeEmailListItem(index)}}},[_c('v-icon',[_vm._v("fa-times")])],1):_vm._e()],1)],1)]}),(_vm.emailList && _vm.emailList.length < 5)?_c('div',{staticClass:"inline-flex flex-row-reverse relative mt-3 w-full h-full"},[_c('v-btn',{attrs:{"color":"secondary"},on:{"click":_vm.addEmailListItem}},[_vm._v("Add Another Email")])],1):_vm._e()],2):_vm._e(),(_vm.step === 2)?_c('div',{staticClass:"col-span-1 md:col-span-3 mt3"},[(_vm.orgListOptions.length > 0)?_c('DynamicFormControl',{attrs:{"control":{
                                name: 'OrgList',
                                type: 'RadioButtonGroup',
                                value: _vm.selectedOrganization,
                                config: {
                                    label: 'Organization',
                                    rules: 'required',
                                    options: _vm.orgListOptions,
                                    col: 12,
                                    required: true,
                                    short: true
                                }
                            }},on:{"change":function($event){return _vm.selectedOrganizationChanged($event.value)}}}):_vm._e()],1):_vm._e(),(_vm.step === 2)?_c('div',{staticClass:"col-span-1 md:col-span-3 mt3"},[(!_vm.otherOrganizationSelected && _vm.computedBranchListOptions.length > 0)?_c('DynamicFormControl',{attrs:{"control":{
                                name: 'BranchList',
                                type: 'RadioButtonGroup',
                                value: _vm.selectedBranch,
                                config: {
                                    label: 'Branch',
                                    rules: 'required',
                                    options: _vm.computedBranchListOptions,
                                    col: 12,
                                    required: true,
                                    short: true
                                }
                            }},on:{"change":function($event){_vm.selectedBranch = $event.value}}}):_vm._e()],1):_vm._e(),(_vm.step === 2 && (_vm.orgListOptions.length < 1 || _vm.otherOrganizationSelected))?_c('div',{staticClass:"col-span-1 md:col-span-3 mt3"},[_c('DynamicFormControl',{attrs:{"control":{
                                name: 'OtherOrganizationName',
                                type: 'TextInput',
                                value: _vm.otherOrganization,
                                config: {
                                    label: 'Organization Name',
                                    rules: 'required',
                                    col: 12,
                                    required: true,
                                    short: true
                                }
                            }},on:{"change":function($event){_vm.otherOrganization = $event.value}}})],1):_vm._e(),(_vm.step === 2 && (_vm.orgListOptions.length < 1 || _vm.otherOrganizationSelected || _vm.otherBranchSelected))?_c('div',{staticClass:"col-span-1 md:col-span-3 mt3"},[_c('DynamicFormControl',{attrs:{"control":{
                                name: 'Address',
                                type: 'Address',
                                value: _vm.otherBranchAddress,
                                config: {
                                    label: 'Branch Address',
                                    rules: 'required',
                                    required: true,
                                    short: true,
                                    addressType: 'MAILING'
                                }
                            }},on:{"change":function($event){_vm.otherBranchAddress = $event.value}}})],1):_vm._e(),(_vm.step === 2)?_c('div',[_c('DynamicFormControl',{attrs:{"control":{
                                name: 'Phone',
                                type: 'TextInput',
                                value: _vm.userPhone,
                                config: {
                                    label: 'Phone Number',
                                    rules: 'phone|required',
                                    col: 12,
                                    short: true
                                }
                            }},on:{"change":function($event){_vm.userPhone = $event.value}}})],1):_vm._e(),(_vm.step === 3)?_c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"2"}},[_c('DynamicFormControl',{attrs:{"control":{
                                name: 'OrganizationLabel',
                                type: 'FormLabel',
                                config: {
                                    label: 'Organization:',
                                    col: 12,
                                    short: true
                                }
                            }}})],1),_c('v-col',{attrs:{"cols":"10"}},[_c('DynamicFormControl',{attrs:{"control":{
                                name: 'OrganizationLabelValue',
                                type: 'FormLabel',
                                config: {
                                    label: _vm.getUserOrgName(),
                                    col: 12,
                                    short: true
                                }
                            }}})],1)],1)],1):_vm._e(),(_vm.step === 3)?_c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"2"}},[_c('DynamicFormControl',{attrs:{"control":{
                                name: 'BranchLabel',
                                type: 'FormLabel',
                                config: {
                                    label: 'Branch:',
                                    col: 12,
                                    short: true
                                }
                            }}})],1),_c('v-col',{attrs:{"cols":"10"}},[_c('DynamicFormControl',{attrs:{"control":{
                                name: 'BranchLabelValue',
                                type: 'FormLabel',
                                config: {
                                    label: _vm.getUserBranchAddress(),
                                    col: 12,
                                    short: true
                                }
                            }}})],1)],1)],1):_vm._e(),(_vm.step === 3)?_c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"2"}},[_c('DynamicFormControl',{attrs:{"control":{
                                name: 'PhoneLabel',
                                type: 'FormLabel',
                                config: {
                                    label: 'Phone:',
                                    col: 12,
                                    short: true
                                }
                            }}})],1),_c('v-col',{attrs:{"cols":"10"}},[_c('DynamicFormControl',{attrs:{"control":{
                                name: 'PhoneLabelValue',
                                type: 'FormLabel',
                                config: {
                                    label: _vm.userPhone,
                                    col: 12,
                                    short: true
                                }
                            }}})],1)],1)],1):_vm._e(),(_vm.step === 3)?_c('div',[_c('DynamicFormControl',{attrs:{"control":{
                                name: 'UWNameLabel',
                                type: 'TextInput',
                                value: _vm.underwriterName,
                                config: {
                                    label: 'Underwriter Name',
                                    col: 12,
                                    short: true
                                }
                            }},on:{"change":function($event){_vm.underwriterName = $event.value}}})],1):_vm._e(),(_vm.step === 3)?_c('div',[_c('DynamicFormControl',{attrs:{"control":{
                                name: 'UWNameLabelValue',
                                type: 'TextInput',
                                value: _vm.underwriterEmail,
                                config: {
                                    label: 'Underwriter Email',
                                    rules: 'email',
                                    col: 12,
                                    short: true
                                }
                            }},on:{"change":function($event){_vm.underwriterEmail = $event.value}}})],1):_vm._e(),(_vm.step === 3)?_c('div',[_c('label',{staticClass:"mb-2 mt-2"},[_vm._v("Please provide an agency contact who can confirm your request:")])]):_vm._e(),(_vm.step === 3)?_c('div',[_c('DynamicFormControl',{attrs:{"control":{
                                name: 'AgencyContactName',
                                type: 'TextInput',
                                value: _vm.agencyContactName,
                                config: {
                                    label: 'Name',
                                    rules: 'required',
                                    col: 12,
                                    short: true
                                }
                            }},on:{"change":function($event){_vm.agencyContactName = $event.value}}})],1):_vm._e(),(_vm.step === 3)?_c('div',[_c('DynamicFormControl',{attrs:{"control":{
                                name: 'AgencyContactEmail',
                                type: 'TextInput',
                                value: _vm.agencyContactEmail,
                                config: {
                                    label: 'Email',
                                    rules: 'required|email',
                                    col: 12,
                                    short: true
                                }
                            }},on:{"change":function($event){_vm.agencyContactEmail = $event.value}}})],1):_vm._e(),(_vm.step === 3)?_c('div',[_c('DynamicFormControl',{attrs:{"control":{
                                name: 'AgencyContactPhone',
                                type: 'TextInput',
                                value: _vm.agencyContactPhone,
                                config: {
                                    label: 'Phone',
                                    rules: 'phone|required',
                                    col: 12,
                                    short: true
                                }
                            }},on:{"change":function($event){_vm.agencyContactPhone = $event.value}}})],1):_vm._e(),_c('div',{staticClass:"col-span-1 md:col-span-3 mt3"},[_c('v-row',[(_vm.step > 1)?_c('v-col',{staticClass:"text-left",attrs:{"cols":_vm.step > 1 ? 6 : 12}},[_c('v-btn',{attrs:{"color":"secondary"},on:{"click":_vm.previousStep}},[_vm._v("Back")])],1):_vm._e(),(_vm.step < 3)?_c('v-col',{staticClass:"text-right",attrs:{"cols":_vm.step > 1 && _vm.step < 3 ? 6 : 12}},[_c('v-btn',{attrs:{"color":"secondary"},on:{"click":_vm.nextStep}},[_vm._v("Next")])],1):_vm._e(),(_vm.step === 3)?_c('v-col',{staticClass:"text-right",attrs:{"cols":"6"}},[_c('v-btn',{attrs:{"color":"secondary"},on:{"click":_vm.submit}},[_vm._v("Submit")])],1):_vm._e()],1)],1)])])]}}],null,false,209252066)}):_vm._e(),(_vm.amlinkRequestSent && !_vm.loading)?_c('div',{staticClass:"grid relative justify-center content-center text-center"},[_c('h3',{staticClass:"text-base font-bold mb-3"},[_vm._v(_vm._s(_vm.requestText))]),_c('p',{staticClass:"text-sm"},[_vm._v("Please check back later")])]):_vm._e(),(_vm.loading)?_c('div',[_c('Loading',{staticClass:"text-center",attrs:{"size":100}})],1):_vm._e()]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }