<template>
  <v-container>
    <v-row>
      <v-col sm="6" cols="12">
        <h1 class="mb-0">Account Details</h1>
      </v-col>
      <v-col sm="6" cols="12" class="text-right">
        <v-btn color="white" type="button" v-show="storeAccount" key="clone" @click="clone">
          <i class="far fa-copy"></i>&nbsp;Clone Account
        </v-btn>
      </v-col>
    </v-row>
    <br>

    <v-alert type="warning" dense v-if="loaded && !storeAccount" :icon="false">Please select an account.</v-alert>

    <div class="text-center" v-if="!storeAccount && !loaded">
      <br><br>
      <Loading :size="60" />
    </div>

    <v-row v-if="storeAccount">
      <v-col md="4" cols="12">
        <AccountMetaCard :key="selectedAgent.id"
                         :image="getUserAvatarPath(selectedAgent.Email)"
                         label="Agent"
                         :readonly="!selectedAgent.Phone && !selectedAgent.Email"
                         :name="selectedAgent.Name">
          <p v-if="selectedAgent.Phone"><i class="fal fa-phone"></i> {{selectedAgent.Phone }}</p>
          <p v-if="selectedAgent.Email"><i class="fal fa-envelope"></i> <a :href="'mailto:' + selectedAgent.Email" class="blue-link">{{selectedAgent.Email}}</a></p>
        </AccountMetaCard>
      </v-col>
      <v-col md="4" cols="12">
        <AccountMetaCard :key="selectedAgency.id"
                         :image="null"
                         label="Agency"
                         :readonly="!selectedAgency.Street1"
                         :name="selectedAgency.OrgName">
          <template v-if="selectedAgency.Street1">
            <p><i class="fal fa-map-marker"></i> {{selectedAgency.Street1}} {{selectedAgency.Street2}}</p>
            <p><i class="fal fa-map-marker hidden"></i> {{selectedAgency.City}}, {{selectedAgency.State}} {{selectedAgency.Postal}} {{selectedAgency.Country}}</p>
          </template>
        </AccountMetaCard>
      </v-col>
      <v-col md="4" cols="12">
        <AccountMetaCard :key="selectedUnderwriter.id"
                         :image="getUserAvatarPath(selectedUnderwriter.Email)"
                         label="Underwriter"
                         :name="selectedUnderwriter.Name"
                         :readonly="!selectedUnderwriter.Name || selectedUnderwriter.Name === 'R.U.G.' || (!selectedUnderwriter.Phone && !selectedUnderwriter.Email)">
          <p v-if="selectedUnderwriter.Phone && selectedUnderwriter.Name !== 'R.U.G.'"><i class="fal fa-phone"></i> {{selectedUnderwriter.Phone}}</p>
          <p v-if="selectedUnderwriter.Email && selectedUnderwriter.Name !== 'R.U.G.'"><i class="fal fa-envelope"></i> <a :href="'mailto:' + selectedUnderwriter.Email" class="blue-link">{{selectedUnderwriter.Email}}</a></p>
        </AccountMetaCard>
      </v-col>
    </v-row>
    <br>
    <v-row class="d-flex align-content-stretch" v-if="storeAccount">
      <v-col class="d-flex align-content-stretch flex-grow-1">
        <AccountCard :account="storeAccount" />
      </v-col>
      <v-col clas="d-flex align-content-stretch">
        <v-card class="d-flex align-content-stretch flex-grow-1">
          <div class="policy-details pa-6 pb-4 flex-grow-1 flex-grow-1">
            <span v-if="storeAccount" class="policy-tag">
              <!--<TermTag :TermBegin="storeAccount.TermBegin"
                     :TermEnd="storeAccount.TermEnd"
                     :Type="selectedAccountType"
                     FontSize="24px"
                     AppendType="true">
            </TermTag>-->
              <!-- {{storeAccount.AccountStatus}} {{storeAccount.Type}} -->
              <PolicyStatus :Status="storeAccount.AccountStatus" :key="'AccountStatus-' + storeAccount.id + '-' + storeAccount.AccountStatus" />
            </span>
            <!--<span>{{storeAccount.id}}</span>-->
            <span><label>Product</label> {{storeAccount.Product ? storeAccount.Product.toUpperCase() : null}}</span>
            <span v-if="nonDefaultDate(storeAccount.TermEnd)"><label>Term</label> {{formatDateForDisplay(storeAccount.TermBegin, true)}} - {{formatDateForDisplay(storeAccount.TermEnd, true)}}</span>
            <span v-else><label>Term</label>{{storeAccount.TermBegin | formatDate}}</span>
            <span v-if="storeAccount.Balance"><label>Open Balance</label> <span class="balance">{{formatMoney(storeAccount.Balance)}}</span></span>
            <span v-if="paymentEnabled(storeAccount.Balance)">
              <v-btn color="secondary" type="submit" @click="openPaymentModal" :disabled="!storeAccount.Number">
                <v-icon left style="color: white; position: relative; top: -1px;">
                  mdi-credit-card-outline
                </v-icon>
                <span>Make a Payment</span>
              </v-btn>
            </span>
          </div>
          <div class="channel-details pa-6 pb-4 flex-grow-1">
            <template v-if="selectedChannel">
              <span><label>Channel</label></span>
              <v-img :alt="selectedChannel"
                     class="shrink mb-2"
                     contain
                     :src="selectedChannelImageUrl"
                     width="150">
                <template v-slot:placeholder>
                  <v-row class="fill-height ma-0"
                         align="center"
                         justify="center">
                    <v-skeleton-loader type="text" height="30px" width="100%" />
                  </v-row>
                </template>
              </v-img>
            </template>

          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="isFloodMarket && !v1EndorsementsFeatureFlagEnabled">
      <v-col cols="12" class="pb-0">
        <v-alert type="info" outlined class="text-left mb-0" dense :icon="false">
          If a change to the mailing address or mortgage company is needed, please send a request to <a href="mailto:floodendorsements.access@amwins.com">floodendorsements.access@amwins.com</a>. All other policy changes are not supported at this time.  If an additional change or correction needs to be made, please quote a new account and cancel this policy.
        </v-alert>
      </v-col>
    </v-row>
    <v-row v-if="storeAccount">
      <v-col :sm="6" cols="12">
        <div>
          <v-switch v-if="storeAccount && isEmployeeOrHigher"
                    v-model="allPolicyTerms"
                    label="All Policy Terms"
                    class="d-inline-block"
                    @change="allPolicyTermsChanged"></v-switch>
        </div>
      </v-col>
      <v-col :sm="6" cols="12" class="text-right">
        <v-btn color="white" type="button" v-if="storeAccount && storeAccount.Number && isDigitalChannel" @click="openInsuredPortalUserDialog" style="margin-top: 12px;" class="ml-2">
          <i class="far fa-browser"></i>&nbsp;Insured Portal User
        </v-btn>

        <v-btn color="white" type="button" v-if="storeAccount && storeAccount.Number && isEmployeeOrHigher" @click="showLossRun = true" style="margin-top: 12px;" class="ml-2">
          <i class="fas fa-file-alt"></i>&nbsp;Loss Run Report
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-if="storeAccount">
      <v-col cols="12">
        <v-card :loading="storeAccount.TermList.length > 0 && storePolicyTerms.length != storeAccount.TermList.length">
          <ResultsTable :tableHeaders="tableHeaders"
                        :results="storePolicyTerms"
                        rowkey="Index"
                        show-expand
                        @item-expanded="policyExpanded">
            <template v-slot:Term="{item}">
              {{item.TermBegin}} - {{item.TermEnd}}
            </template>
            <template v-slot:LastUpdated="{item}">
              {{ item.LastUpdatedDate }}
            </template>
            <template v-slot:SalesChannel="{item}">
              <v-img :alt="item.SalesChannel"
                     class="shrink"
                     contain
                     :src="getSalesChannelLogo(item.SalesChannel, item.Market)"
                     width="100"
                     height="36">
              </v-img>
            </template>
            <template v-slot:Status="{item}">
              <PolicyStatus :Status="item.PolicyTermStatus" />
            </template>
            <template v-slot:Type="{item}">
              <PolicyTag :Type="item.Type"/>
            </template>
            <template v-slot:Actions="{item}">
              <v-menu offset-y close-on-click v-if="item">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="secondary"
                         v-bind="attrs"
                         v-on="on"
                         :disabled="!enableActions(item)">
                    Actions&nbsp;&nbsp;<i class="fas fa-caret-down"></i>
                  </v-btn>
                </template>
                <v-list class="actions-menu">
                  <!--<v-list-item><a href="#"><i class="far fa-calendar-day"></i>&nbsp;Transaction History</a></v-list-item>
                <v-list-item><a href="#"><i class="far fa-eye"></i>&nbsp;Review</a></v-list-item>
                <v-list-item><a href="#"><i class="far fa-copy"></i>&nbsp;Clone</a></v-list-item>-->
                  <v-list-item v-if="enableRequirements(item)">
                    <router-link :to="{ path: 'account/requirements', query: { id: accountId, policy: item.Id}}"><i class="far fa-file-upload"></i>&nbsp;Requirements/Renewal</router-link>
                  </v-list-item>
                  <v-list-item v-if="item.Type === 'Policy'">
                    <a @click="claimsLink(item)"><i class="fas fa-stamp"></i>&nbsp;New Claim</a>
                  </v-list-item>
                  <v-list-item v-if="enableEndorsement(item)">
                    <a @click="endorsementLink(item)"><i class="fas fa-handshake"></i>&nbsp;Endorsement Request</a>
                  </v-list-item>
                  <v-list-item v-if="enableCancellation(item)">
                    <a @click="cancellationLink(item)"><i class="fas fa-times-circle"></i>&nbsp;Cancellation Request</a>
                  </v-list-item>
                  <v-list-item v-if="enableReinstatement(item)">
                    <a @click="reinstatementLink(item)"><i class="fa-solid fa-rotate-left"></i>&nbsp;Reinstate Request</a>
                  </v-list-item>
                  <v-list-item :key="item.id" v-if="showResolveInspection(item)">
                    <a @click.stop="enableResolveInspection()">
                      <i class="fa-regular fa-clipboard-list-check"></i>
                      &nbsp;Resolve Inspection
                      <i class="fa-regular" :class="computedResolveInspectionClicked ? 'fa-chevron-up' : 'fa-chevron-down'"></i>
                    </a>
                  </v-list-item>
                  <v-list-item v-if="computedResolveInspectionClicked && showResolveInspection(item)">
                    <a @click="setShowCompleteInspectionModal(item)" style="padding-left: 20px;"><i class="fa-sharp fa-regular fa-check"></i>&nbsp;All Checks Passed</a>
                  </v-list-item>
                  <v-list-item v-if="computedResolveInspectionClicked && showResolveInspection(item)">
                    <a @click="navigateToEndorsementToResolveDiscrepencies(item)" style="padding-left: 20px;"><i class="fa-sharp fa-solid fa-triangle-exclamation"></i>&nbsp;Correct Discrepencies</a>
                  </v-list-item>
                  <v-list-item v-if="item.Type === 'Policy' && selectedChannel == 'Human'">
                    <router-link :to="{ path: 'account/servicing', query: { id: accountId, policy: item.Id}}"><i class="fas fa-file-alt"></i>&nbsp;Servicing Request</router-link>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
            <template v-slot:expansion-panel="{item}">
              <v-tabs vertical>
                <v-tab>
                  Documents
                </v-tab>
                <v-tab v-if="item.Type === 'Policy'">
                  Claims
                </v-tab>
                <v-tab>
                  Transactions
                </v-tab>
                <v-tab v-if="isSupportOrHigher">
                  Submission JSON
                </v-tab>
                <v-tab-item>
                  <ResultsTable v-if="isFloodMarket || isV2"
                                :tableHeaders="policyDocumentsTableHeaders"
                                :results="item.PolicyDocs"
                                sortBy="RecordInsert"
                                sortDesc>
                    <template v-slot:Name="{item}">
                      {{ item.Name }}
                    </template>
                    <template v-slot:RecordInsert="{item}">
                      {{ formatDateForDisplay(item.RecordInsert) }}
                    </template>
                    <template v-slot:fileId="{item}">
                      <FormButton label="View"
                                  size="large"
                                  class="mr-4"
                                  @click="view(item.Key)" />
                      <FormButton label="Download"
                                  size="large"
                                  @click="download(item.Key)" />
                    </template>
                  </ResultsTable>
                  <ResultsTable v-else
                                :tableHeaders="policyDocumentsTableHeaders"
                                :results="item.PolicyDocs"
                                :loading="policyDocsGridLoading(item.Index)"
                                sortBy="RecordInsert"
                                sortDesc>
                    <template v-slot:Name="{item}">
                      {{ item.Type.Name }}
                    </template>
                    <template v-slot:RecordInsert="{item}">
                      {{ formatDateForDisplay(item.RecordInsert) }}
                    </template>
                    <template v-slot:fileId="{item}">
                      <FormButton label="View"
                                  size="large"
                                  class="mr-4"
                                  @click="view(item.Key)" />
                      <FormButton label="Download"
                                  size="large"
                                  @click="download(item.Key)" />
                    </template>
                  </ResultsTable>
                </v-tab-item>
                <v-tab-item v-if="item.Type === 'Policy'">
                  <ResultsTable :tableHeaders="claimsTableHeaders"
                                :results="item.Claims"
                                :loading="claimsGridLoading(item.Index)"
                                sortBy="DateOfLoss"
                                sortDesc>
                    <template v-slot:DateOfLoss="{item}">
                      {{ formatDateForDisplay(item.DateOfLoss) }}
                    </template>
                    <template v-slot:PrimaryCause="{item}">
                      {{ item.CausePrimary?.Name }}
                    </template>
                    <template v-slot:SecondaryCause="{item}">
                      {{ item.CauseSecondary?.Name }}
                    </template>
                    <template v-slot:ClaimNumber="{item}">
                      {{ item.Key }}
                    </template>
                    <template v-slot:DateReported="{item}">
                      {{ formatDateForDisplay(item.RecordInsert) }}
                    </template>
                  </ResultsTable>
                </v-tab-item>
                <v-tab-item>
                  <ResultsTable v-if="!isFloodMarket && !isV2"
                                :tableHeaders="transactionsTableHeaders"
                                :results="item.TransactionsHistory"
                                :loading="transactionsGridLoading(item.Index)"
                                sortBy="PolicyTransactionDate"
                                sortDesc>
                    <template v-slot:PolicyTransactionKey="{item}">
                      {{ item.PolicyTransactionKey }}
                    </template>
                    <template v-slot:PolicyTransactionDate="{item}">
                      {{ formatDateForDisplay(item.PolicyTransactionDate) }}
                    </template>
                    <template v-slot:PolicyTransactionTypeGroupName="{item}">
                      {{ item.PolicyTransactionTypeGroupName }}
                    </template>
                    <template v-slot:PolicyTransactionAmountTotal="{item}">
                      {{ formatMoney(item.PolicyTransactionAmountTotal) }}
                    </template>
                    <template v-slot:PolicyTransactionOpenBalance="{item}">
                      {{ formatMoney(item.PolicyTransactionOpenBalance) }}
                    </template>
                  </ResultsTable>
                  <ResultsTable v-else
                                :tableHeaders="pslTransactionsTableHeaders"
                                :results="item.TransactionsHistory"
                                :loading="transactionsGridLoading(item.Index)"
                                sortBy="PolicyTransactionDate"
                                sortDesc>
                    <template v-slot:PolicyTransactionDate="{item}">
                      {{ formatDateForDisplay(item.PolicyTransactionDate) }}
                    </template>
                    <template v-slot:PolicyTransactionTypeGroupName="{item}">
                      {{ formatTransactionType(item.PolicyTransactionTypeGroupName) }}
                    </template>
                    <template v-slot:PolicyTransactionAmountTotal="{item}">
                      {{ formatMoney(item.PolicyTransactionAmountTotal) }}
                    </template>
                  </ResultsTable>
                </v-tab-item>
                <v-tab-item>
                  <SubmissionMonaco v-if="item.Submission"
                                    :submission="item.Submission"></SubmissionMonaco>
                  <div v-else class="pt-2">
                    <Loading :size="64" class="text-center" />
                  </div>
                </v-tab-item>
              </v-tabs>
            </template>
          </ResultsTable>
          <GenericDialog :value="showCompleteInspectionModal"
                      title="Confirm No Inspection Discrepancies"
                      @close-dialog="showCompleteInspectionModal = false">

            <template v-slot:card-text>
              <div cols="12">
                <p>By clicking OK, you are confirming there were no discrepancies between the original information furnished and the inspection report we received.</p>
              </div>
            </template>

            <template v-slot:card-actions>
              <v-btn color="secondary" type="submit" outlined @click="showCompleteInspectionModal = false">
                Cancel
              </v-btn>&nbsp;
              <v-btn color="secondary" type="submit" @click="inspectionPassed()">
                Ok
              </v-btn>
            </template>
          </GenericDialog>
        </v-card>
      </v-col>
    </v-row>
    <br><br><br>

        <!--TODO: If payments can be made in multiple locations, we need to pull out this dialog into a reuseable component and import it wherever it'll be used-->
        <GenericDialog :value="paymentModal"
                       :title="paymentModalTitle"
                       @close-dialog="paymentModal = false">
          <template v-slot:card-text>
            <v-row>
                <v-col cols="6"
                       class="text-center">
                    <v-btn color="secondary" large type="button" class="ma-2" @click="makePayment('EFT')">
                        <v-icon left style="color: white;">
                              mdi-bank
                        </v-icon>
                        <span style="color: white;">
                            Pay by EFT/E-Check
                        </span>
                    </v-btn>
                    <span class="caption">
                        No additional fee
                    </span>
                </v-col>
                <v-col cols="6"
                       class="text-center">
                    <v-btn color="secondary" large type="button" class="ma-2" @click="makePayment('CC')">
                        <v-icon left style="color: white;">
                            mdi-credit-card-outline
                        </v-icon>
                        <span style="color: white;">
                            Pay by Credit/Debit
                        </span>
                    </v-btn>
                    <span class="caption">
                        Additional fee will apply
                    </span>
                </v-col>
            </v-row>
          </template>

          <template v-slot:card-actions>
            <div>
                <v-btn color="secondary" type="submit" text @click="paymentModal = false">
                    Close
                </v-btn>
            </div>
          </template>

    </GenericDialog>

        <GenericDialog :value="floodClaimModal"
                       title="Claims Information"
                       @close-dialog="floodClaimModal = false">
                       
          <template v-slot:card-text>
            <v-row>
              <v-col cols="12">
                If you need to submit a claim or have any questions regarding a claim, please reference your policy document for confirmation on who to reach out to and what information is needed when submitting a claim.
              </v-col>
            </v-row>
          </template>

          <template v-slot:card-actions>
            <div>
                <v-btn color="secondary" type="submit" text @click="floodClaimModal = false">
                    Close
                </v-btn>
            </div>
          </template>

    </GenericDialog>

    <GenericDialog :value="pendingEndorsementModal"
                   title="Pending Endorsement"
                   persistent
                   width="700px"
                   @close-dialog="pendingEndorsementModal = false">

      <template v-slot:card-text>
        <div v-if="!newEndorsmentConfirmation" cols="12">
          Would you like to resume the Endorsement request started on {{formatDateForDisplay(storeAccount.EndorsementStartDate, true)}}?
        </div>
        <div v-if="newEndorsmentConfirmation" cols="12">
          Are you sure you want to delete the Pending Endorsement and start a new Endorsement?
        </div>
      </template>

      <template v-slot:card-actions>
        <v-btn v-if="!newEndorsmentConfirmation" color="secondary" type="submit" outlined @click="pendingEndorsementModal = false">
          Cancel
        </v-btn>&nbsp;
        <v-btn v-if="!newEndorsmentConfirmation" color="secondary" type="submit" outlined @click="newEndorsement">
          Start New Endorsement
        </v-btn>&nbsp;
        <v-btn v-if="!newEndorsmentConfirmation" color="secondary" type="submit" @click="resumeEndorsement">
          Resume Endorsement
        </v-btn>

        <v-btn v-if="newEndorsmentConfirmation" color="secondary" type="submit" outlined @click="newEndorsmentConfirmationNo">
          No
        </v-btn>&nbsp;
        <v-btn v-if="newEndorsmentConfirmation" color="secondary" type="submit" @click="newEndorsmentConfirmationYes">
          Yes
        </v-btn>
      </template>

    </GenericDialog>

    <Dialog title="Loss Run Report" :value="showLossRun" width="1000px" @close-dialog="showLossRun = false;">
      <template v-slot:card-text>
        <DocumentPreview mode="LossRun" :customerRiskAccountKey="storeAccount.Number" />
      </template>
      <template v-slot:card-actions>
        <v-btn color="secondary" text type="button" @click="showLossRun = false">Close</v-btn>
      </template>
    </Dialog>
    <Dialog title="Insured Portal User" :value="showInsuredPortalUser" width="600px" @close-dialog="showInsuredPortalUser = false;">
      <template v-slot:card-text>
        <div v-if="selectedCustomerRiskAccount" class="text-center">
          <div v-if="selectedCustomerRiskAccount.Person">
            <h3 class="mb-2">{{selectedCustomerRiskAccount.Person.NameFull}}</h3>
            <h4 class="mb-2">{{selectedCustomerRiskAccount.Person.Email}}</h4>
            <v-menu :close-on-content-click="true"
                    offset-x>
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="white" type="button" v-if="isSupportOrHigher" class="mt-4"
                       v-bind="attrs"
                       v-on="on">
                  <i class="far fa-unlink"></i>&nbsp;Unlink User
                </v-btn>
              </template>

              <v-card class="pa-4">
                <p>Are you sure you want to unlink this user?</p>

                <v-card-actions class="pb-0">
                  <v-spacer></v-spacer>
                  <v-btn text>
                    Cancel
                  </v-btn>
                  <v-btn color="secondary" @click.stop="unlinkCustomerRiskAccount">
                    Yes
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-menu>
          </div>
          <div v-else class="text-center">
            <h4 class="mb-4">This account has not been linked in the Insured Portal.</h4>

            <p>When you invite a user by entering their email address below, their policy will already be linked to their account when they log in.</p>

            <ValidationObserver tag="span" v-slot="{ handleSubmit }" ref="insuredPortalInvitationObserver">
              <form @submit.prevent="handleSubmit(onInvite)">
                <DynamicFormControl :control="{
                                        type: 'TextInput',
                                        name: 'InsuredEmail',
                                        value: insuredInvitationEmail,
                                        config: {
                                            label: 'Email Address',
                                            rules: 'required|email',
                                            short: true,
                                            disabled: sendingInvitation
                                        }
                                    }"
                                    @change="insuredInvitationEmail = $event.value" />

                <v-btn color="secondary" type="submit" large class="send-invitation-button" :disabled="sendingInvitation">
                  <i class="fas fa-paper-plane" style="color: white;"></i>&nbsp;Send Invitation
                </v-btn>

              </form>
            </ValidationObserver>
          </div>

        </div>
        <div class="text-center" v-else>
          <Loading :size="40" />
        </div>
      </template>
      <template v-slot:card-actions>
        <v-btn color="secondary" text type="button" @click="showInsuredPortalUser = false">Close</v-btn>
      </template>
    </Dialog>

  </v-container>
</template>

<script>
  import AccountMetaCard from '@/components/views/Account/AccountMetaCard.vue'
  import ResultsTable from '@/components/shared/ResultsTable.vue'
  import PolicyTag from '@/components/shared/PolicyTag.vue'
  import FormButton from '@/components/form/FormButton.vue'
  import GenericDialog from '@/components/shared/Dialog.vue'
  import AccountCard from '@/components/shared/AccountCard.vue'
  import SubmissionMonaco from '@/components/shared/SubmissionMonaco.vue'
  import { AccountService } from '@/api/AccountService'
  import { PolicyService } from '@/api/PolicyService'
  import { SbixService } from '@/api/SbixService'
  import { makeStatusFilterable, formatDateForDisplay, formatMoney, getUserAvatarPath, nonDefaultDate, DEFAULT_TITLE, getSalesChannelLogo } from '@/scripts/helper'
  import Environment from '@/scripts/environment'
  import { EmailService } from '@/api/EmailService'
  import { SubmissionService } from '@/api/SubmissionService'
  import { FeatureFlagsService } from '@/api/FeatureFlagsService';
  import { FormStateService } from '@/api/FormStateService';
  import Dialog from '@/components/shared/Dialog.vue'
  import DocumentPreview from '@/components/views/Quote/DocumentPreview.vue'
  import DynamicFormControl from '@/components/form/DynamicFormControl.vue'
  import { acquireAccessToken, getTokenAsync } from '@/plugins/auth'
  import hub from '@/plugins/webapp-hub.js'
  import dateformat from 'dateformat'

  export default {
    name: 'Account',
    components: {
      AccountMetaCard,
      ResultsTable,
      PolicyTag,
      FormButton,
      GenericDialog,
      AccountCard,
      Dialog,
      DocumentPreview,
      DynamicFormControl,
      SubmissionMonaco
    },
    data() {
      return {
        selectedPolicyTerm: {},
        showCompleteInspectionModal: false,
        inspectionsReviewTeam: ['denise.read@amwins.com', 'missy.klor@amwins.com'],
        resolveInspectionClicked: false,
        accountId: null,
        claimsLoadingArray: [],
        policyDocsLoadingArray: [],
        transactionsLoadingArray: [],
        tableHeaders: [
          { text: "Channel", value: "SalesChannel", sortable: false },
          { text: "Policy/Quote Number", value: "Number", sortable: false },
          { text: "Term", value: "Term" },
          { text: "Last Updated", value: "LastUpdated" },
          { text: "Status", value: "Status" },
          { text: "Type", value: "Type" },
          { text: "Actions", value: "Actions", sortable: false },
        ],
        claimsTableHeaders: [
          { text: "Date Of Loss", value: "DateOfLoss" },
          { text: "Date Reported", value: "DateReported" },
          { text: "Primary Cause", value: "PrimaryCause", sortable: false },
          { text: "Secondary Cause", value: "SecondaryCause", sortable: false },
          { text: "Claim Reference Number", value: "ClaimNumber", sortable: false },
        ],
        policyDocumentsTableHeaders: [
          { text: "Document Name", value: "Name", sortable: true },
          { text: "Date", value: "RecordInsert" },
          { text: "View/Download", value: "fileId", sortable: false },
        ],
        transactionsTableHeaders: [
          /*{text: "Policy", value: "PolicyKey"},*/
          { text: "Transaction Key", value: "PolicyTransactionKey" },
          { text: "Transaction Date", value: "PolicyTransactionDate" },
          { text: "Group", value: "PolicyTransactionTypeGroupName", sortable: false },
          { text: "Amount", value: "PolicyTransactionAmountTotal", sortable: false },
          { text: "Open Balance", value: "PolicyTransactionOpenBalance", sortable: false },
        ],
        pslTransactionsTableHeaders: [
          { text: "Transaction Date", value: "PolicyTransactionDate" },
          { text: "Group", value: "PolicyTransactionTypeGroupName", sortable: false },
          { text: "Amount", value: "PolicyTransactionAmountTotal", sortable: false },
        ],
        paymentModal: false,
        paymentModalTitle: '',
        allPolicyTerms: false,
        showLossRun: false,
        showInsuredPortalUser: false,
        selectedCustomerRiskAccount: null,
        insuredInvitationEmail: null,
        sendingInvitation: false,
        loaded: false,
        accountRetryCount: 0,
        floodClaimModal: false,
        v1EndorsementsFeatureFlagEnabled: false,
        pendingEndorsementModal: false,
        newEndorsmentConfirmation: false
      }
    },
    computed: {  
      computedResolveInspectionClicked() {
        return this.resolveInspectionClicked;
      },    
      showReinstatementItem() {
        return this.$store.getters.userHasRestrictedFeature('Account_FloodReinstatements');
      },
      selectedUnderwriter() {
        if (this.storeAccount && this.storeAccount.Underwriter) return this.storeAccount.Underwriter;
        return {};
      },
      selectedAgency() {
        if (this.storeAccount && this.storeAccount.Agency) return this.storeAccount.Agency;
        return {};
      },
      selectedAgent() {
        if (this.storeAccount && this.storeAccount.Agent) return this.storeAccount.Agent;
        return {};
      },
      selectedInsured() {
        if (this.storeAccount && this.storeAccount.Insured) return this.storeAccount.Insured;
        return {};
      },
      selectedRisk() {
        if (this.storeAccount && this.storeAccount.Risk) return this.storeAccount.Risk;
        return {};
      },
      selectedChannel() {
        if (this.storeAccount && this.storeAccount.SalesChannel) return this.storeAccount.SalesChannel;
        return null;
      },
      selectedChannelImageUrl() {
        return getSalesChannelLogo(this.selectedChannel, this.storeAccount.Product);
      },
      selectedMarket() {
        if (this.storeAccount && this.storeAccount.Market) return this.storeAccount.Market;
        return {};
      },
      selectedAccountType() {
        if (this.storeAccount && this.storeAccount.Type) return this.storeAccount.Type;
        return 'Quote';
      },
      isAdminUser() {
        return this.$store.getters.userHasRole('admin');
      },
      isSupportOrHigher() {
        return this.$store.getters.userHasRole('support') ||
          this.$store.getters.userHasRole('admin');
      },
      isEmployeeOrHigher() {
        return this.$store.getters.userHasRole('employee') ||
          this.$store.getters.userHasRole('support') ||
          this.$store.getters.userHasRole('admin');
      },
      containsPolicy() {
        return this.storeAccount.TermList.filter(x => x.Type === 'Policy').length > 0
      },
      isDigitalChannel() {
        if (!this.selectedChannel) return false;
        const channels = ['Digital', 'Munich', 'TestV0'];
        return channels.indexOf(this.selectedChannel) > -1;
      },
      isFloodMarket() {
        if (!this.selectedChannel) return false;
        const channels = ['Hiscox', 'Chubb', 'Vave'];
        return channels.indexOf(this.selectedChannel) > -1;
      },
      isV2() {
        if (!this.selectedChannel) return false;
        const channels = ['VavePersonalLines'];
        return channels.indexOf(this.selectedChannel) > -1;
      },
      storeAccount() {
        return this.$store.getters['account/getAccount'](this.$route.query.id)
      },
      storePolicyTerms() {
        const policyTerms = []
        if (this.storeAccount) {
          this.storeAccount.TermList.forEach((p) => {
            policyTerms.push({
              Number: this.getNumberOrLink(p),
              TermBegin: this.formatDateForDisplay(p.TermBegin, true),
              TermEnd: this.formatDateForDisplay(p.TermEnd, true),
              Term: this.formatDateForDisplay(p.TermBegin, true) + ' - ' + this.formatDateForDisplay(p.TermEnd, true),
              LastUpdatedDate: this.formatDateForDisplay(p.Updated),
              LastUpdated: p.Updated,
              Status: p.PolicyTermStatus,
              PolicyTermStatus: p.PolicyTermStatus,
              Actions: '',
              Type: p.Type,
              Index: this.storeAccount.TermList.findIndex(x => x.id === p.id),
              Claims: [],
              PolicyDocs: p.PolicyDocuments,
              Id: p.id,
              QuoteKey: p.QuoteKey,
              SalesChannel: p.SalesChannel,
              SubmissionId: p.SubmissionId,
              Market: p?.Market?.Name ?? "Unknown",
              TPA: p.TPA,
              SalesChannelModel: p.SalesChannelModel,
              SubmissionIdGuid: p.SubmissionIdGuid,
              SubmissionNumber: p.SubmissionNumber,
              HasOpenInspection: p.HasOpenInspection,
              TransactionsHistory: p.TransactionsHistory ?? []
            })
          })

          policyTerms.sort((a, b) => (new Date(a.TermBegin) < new Date(b.TermBegin)) ? 1 : -1);
        }
        return policyTerms
      },
      role() {
        return this.$store.getters.getUserRole;
      },
    },
    async mounted() {
      await FeatureFlagsService.isEnabled({ featureName: "V1Endorsements" })
        .then((response) => {
          this.v1EndorsementsFeatureFlagEnabled = response;
        });

      await this.Initialize()
    },
    methods: {
      getSalesChannelLogo,
      makeStatusFilterable,
      formatDateForDisplay,
      formatMoney,
      getUserAvatarPath,
      nonDefaultDate,
      async Initialize() {
        await this.getAccount()
      },
      async getAccount(silent = false) {
        this.accountId = this.$route.query.id;
        if (this.storeAccount == null || silent) {

          if (!this.accountId || this.accountId === 'null') {
            this.loaded = true;
            return;
          }

          if (!silent) {
            this.loaded = false;
          }

          if (this.accountRetryCount >= 5) {
            this.$store.dispatch('toastError', { Message: 'Account not found.' });
            return;
          }

          await AccountService.get({
            id: this.accountId,
            fullPolicyTermHistory: this.allPolicyTerms
          }, {
            handleError: false,
            'Cache-Control': 'no-cache',
            'Pragma': 'no-cache',
            'Expires': '0',
          })
            .then((response) => {
              hub.attach('account', `${response.key.slice(1)}`);
              response.TermList.sort((a, b) => (new Date(a.TermBegin) < new Date(b.TermBegin)) ? 1 : -1);

              if (response && response.Insured && response.Insured.Name) {
                document.title = response.Insured.Name + " | " + DEFAULT_TITLE;
              }

              response.TermList.forEach((p) => {
                // Populate arrays used to determine if a specific nested grid is loading. Without
                // these nested grid loading arrays, all grids will appear as loading when one loads.
                this.claimsLoadingArray.push({
                  Index: response.TermList.findIndex(x => x.id === p.id),
                  loading: false
                })
                this.policyDocsLoadingArray.push({
                  Index: response.TermList.findIndex(x => x.id === p.id),
                  loading: false
                })
                this.transactionsLoadingArray.push({
                  Index: response.TermList.findIndex(x => x.id === p.id),
                  loading: false
                })
              })

              this.$store.commit('account/updateAccount', response);

              this.loaded = true;
            }).catch((err) => {
              this.accountRetryCount++;

              setTimeout(() => {
                SubmissionService.get({
                  id: this.accountId
                }, {
                  handleError: false
                })
                  .then((submission) => {
                    if (submission && submission.SubmittedAt) {
                      this.getAccount();
                    } else {
                      this.$store.dispatch('toastError', { Message: 'Account not found.' });
                    }
                  }).catch((err) => {
                    this.$store.dispatch('toastError', { Message: 'Account not found.' });
                  });
              }, 5000)

            })
        } else {
          this.loaded = true
          if (this.storeAccount && this.storeAccount.Insured && this.storeAccount.Insured.Name) {
            document.title = this.storeAccount.Insured.Name + " | " + DEFAULT_TITLE;
          }
          this.storeAccount.TermList.forEach((p) => {
            // Populate arrays used to determine if a specific nested grid is loading. Without
            // these nested grid loading arrays, all grids will appear as loading when one loads.
            this.claimsLoadingArray.push({
              Index: this.storeAccount.TermList.findIndex(x => x.id === p.id),
              loading: false
            })
            this.policyDocsLoadingArray.push({
              Index: this.storeAccount.TermList.findIndex(x => x.id === p.id),
              loading: false
            })
            this.transactionsLoadingArray.push({
              Index: this.storeAccount.TermList.findIndex(x => x.id === p.id),
              loading: false
            })
          })
        }
      },
      getPolicyClaims(policyId, policyTermIndex) {
        this.claimsLoadingArray[policyTermIndex].loading = true
        this.$httpSBIX.get(`api/claim?CustomerRiskAccountKey=${this.storeAccount.Number}&PolicyKey=${policyId}`)
          .then((claimsResponse) => {
            this.storePolicyTerms[policyTermIndex].Claims = claimsResponse.data
          })
          .finally(() => {
            this.claimsLoadingArray[policyTermIndex].loading = false
          })
      },
      getPolicyDocs(policyId, policyTermIndex) {
        this.policyDocsLoadingArray[policyTermIndex].loading = true
        this.$httpSBIX.get(`api/Efile?policyKey=${policyId}`)
          .then((result) => {
            this.storePolicyTerms[policyTermIndex].PolicyDocs = result.data
          })
          .finally(() => {
            this.policyDocsLoadingArray[policyTermIndex].loading = false
          })
      },
      getTermDocs(policyId, quoteKey, policyTermIndex) {
        this.policyDocsLoadingArray[policyTermIndex].loading = true
        let policyDocs = []
        let quoteDocs = []
        this.$httpSBIX.get(`api/Efile?policyKey=${policyId}`)
          .then((policyResult) => {
            policyDocs = policyResult.data
            this.$httpSBIX.get(`api/Efile?policyKey=${quoteKey}`)
              .then((quoteResult) => {
                quoteDocs = quoteResult.data
              })
              .finally(() => {
                var docKeys = new Set(policyDocs.map(d => d.Key))
                this.storePolicyTerms[policyTermIndex].PolicyDocs = [...policyDocs, ...quoteDocs.filter(d => !docKeys.has(d.Key))]
                this.policyDocsLoadingArray[policyTermIndex].loading = false
              })
          })
          .catch(() => {
            this.policyDocsLoadingArray[policyTermIndex].loading = false
          })
      },
      getTransactionHistory(policyId, policyTermIndex) {
        if (!this.isFloodMarket) {
          this.transactionsLoadingArray[policyTermIndex].loading = true
          SbixService.getPolicyTransactionHistory({ policyKey: policyId })
            .then((transactionHistoryResponse) => {
              this.storePolicyTerms[policyTermIndex].TransactionsHistory = transactionHistoryResponse
            })
            .finally(() => {
              this.transactionsLoadingArray[policyTermIndex].loading = false
            });
        }
      },
      getPolicyTermSubmission(policyTermIndex, submissionId) {
        if (this.storeAccount.SalesChannel == "Human") submissionId = this.storeAccount.id;

        SubmissionService.get({
          id: submissionId
        }, {
          handleError: false
        })
          .then((submission) => {
            this.storePolicyTerms[policyTermIndex].Submission = submission;
          }).catch(() => {
            this.storePolicyTerms[policyTermIndex].Submission = {};
          })
      },
      async view(id) {
        if (this.isFloodMarket || this.isV2) {
          // Doing a direct call instead of using the swaggergen service because the
          // generated service only returns the .data node and not the entire response onject, like the
          // direct call. the .headers node is part of the entire reponse object
          this.$http.get(`api/TempFile/${id}?createTempFileId=true`, { responseType: 'arraybuffer' })
            .then((result) => {
              if (result) {
                const blob = new Blob([result.data], { type: result.headers['content-type'] })
                const url = window.URL.createObjectURL(blob);
                var link = document.createElement('a');
                link.href = url;
                link.target = '_blank';
                link.click();

                window.URL.revokeObjectURL(url);
              } else {
                this.$store.dispatch('toastError', { Message: 'Failed to Open File' })
              }
            })
        } else {
          await this.$httpSBIX.get(`efile${id}`, { responseType: 'arraybuffer' })
            .then((result) => {
              if (result) {
                const blob = new Blob([result.data], { type: result.headers['content-type'] })
                const url = window.URL.createObjectURL(blob);
                var link = document.createElement('a');
                link.href = url;
                link.target = '_blank';
                link.click();

                window.URL.revokeObjectURL(url);
              } else {
                this.$store.dispatch('toastError', { Message: 'Failed to Open File' })
              }
            })
        }
      },
      async download(id) {
        this.$store.dispatch('toastSuccess', { Message: 'Download Beginning Momentarily' })
        if (this.isFloodMarket || this.isV2) {
          // Doing a direct call instead of using the swaggergen service because the
          // generated service only returns the .data node and not the entire response onject, like the
          // direct call. the .headers node is part of the entire reponse object
          this.$http.get(`api/TempFile/${id}?createTempFileId=true`, { responseType: 'arraybuffer' })
            .then((result) => {
              if (result) {
                const fileNameString = result.headers['content-disposition'].split(';')[1]
                const fileName = fileNameString.split('filename=')[1]
                const blob = new Blob([result.data], { type: result.headers['content-type'] })
                const url = window.URL.createObjectURL(blob);
                var link = document.createElement('a')
                link.href = url;
                link.download = fileName;
                link.click();

                window.URL.revokeObjectURL(url);
              } else {
                this.$store.dispatch('toastError', { Message: 'Download Failed' })
              }
            })
        } else {
          await this.$httpSBIX.get(`efile${id}`, { responseType: 'arraybuffer' })
            .then((result) => {
              if (result) {
                const blob = new Blob([result.data], { type: result.headers['content-type'] })
                const url = window.URL.createObjectURL(blob);
                var link = document.createElement('a')
                link.href = url;
                link.download = id;
                link.click();

                window.URL.revokeObjectURL(url);
              } else {
                this.$store.dispatch('toastError', { Message: 'Download Failed' })
              }
            })
        }
      },
      enableActions(item) {
        //return item.Type !== 'Policy' && !containsPolicy
        return item.Type == 'Policy' || item.PolicyTermStatus == 'RenewalOffered' || item.PolicyTermStatus == 'Quoted';
      },
      enableRequirements(item) {
        return this.isDigitalChannel && this.enableActions(item) && (item.PolicyTermStatus !== 'Canceled' || this.canceledPolicyWithin30Days(item));
      },
      canceledPolicyWithin30Days(item) {
        var today = new Date();
        var cancellationDatePlus30Days = new Date(item.TermEnd);
        cancellationDatePlus30Days.setDate(cancellationDatePlus30Days.getDate() + 30);

        return item.PolicyTermStatus == 'Canceled' && today <= cancellationDatePlus30Days
      },
      policyTermIsFloodMarket(salesChannel) {
        if (!salesChannel) return false;
        const channels = ['Hiscox', 'Chubb', 'Vave'];
        return channels.indexOf(salesChannel) > -1;
      },
      enableEndorsement(item) {
        if (this.storeAccount.Status == 'Canceled') return false;
        return (this.isDigitalChannel || (this.v1EndorsementsFeatureFlagEnabled && (this.isFloodMarket || this.isV2))) && (item.Type === 'Policy' || item.PolicyTermStatus === 'Quoted');
      },
      enableCancellation(item) {
        if (this.storeAccount.Status == 'Canceled') return false;
        return item.Type === 'Policy' && (item.Status === 'Issued' || item.Status === 'NonRenewed' || item.Status === 'Lapsed');
      },
      enableReinstatement(item) {
        const today = dateformat(new Date(), 'mm/dd/yyyy')
        const termEnd = dateformat(item.TermEnd, 'mm/dd/yyyy')
        return item.Type === 'Policy' && item.Status === 'Canceled' && (this.isFloodMarket || this.isV2) && this.showReinstatementItem;
      },
      userIsOnInspectionsReviewTeam() {
        const userEmail = this.$store.getters.getUserEmail
        return userEmail && this.inspectionsReviewTeam.includes(userEmail)
      },
      showResolveInspection(policyTerm) {
        return this.isV2 && policyTerm.HasOpenInspection && (this.userIsOnInspectionsReviewTeam() || this.isSupportOrHigher)
      },
      enableResolveInspection() {
        this.resolveInspectionClicked = !this.resolveInspectionClicked
      },
      setShowCompleteInspectionModal(selectedPolicyTerm) {
        this.selectedPolicyTerm = selectedPolicyTerm
        this.showCompleteInspectionModal = true
      },
      async inspectionPassed() {
        this.showCompleteInspectionModal = false;
        const inspectionCompletedV2 = {
          submissionNumber: this.selectedPolicyTerm.SubmissionNumber, // string
          submissionId: this.selectedPolicyTerm.SubmissionIdGuid, // Guid
          policyId: this.selectedPolicyTerm.Id, // Guid
          salesChannel: this.selectedPolicyTerm.SalesChannelModel // SalesChannel
        }
        await PolicyService.inspectionCompletedV2({ body: inspectionCompletedV2})
      },
      async navigateToEndorsementToResolveDiscrepencies(policyTerm) {
        await this.endorsementLink(policyTerm, true)
      },
      async claimsLink(policyTerm) {
        if (this.policyTermIsFloodMarket(policyTerm.SalesChannel)) {
          this.floodClaimModal = true
        } else if (policyTerm.TPA !== 'TCM') {
          this.floodClaimModal = true
        } else {
          this.$store.commit('blockStart')
          await acquireAccessToken()
            .then(async () => {
              const userToken = await getTokenAsync();
              this.$store.commit('blockComplete')
              window.location = `${Environment.SEASYS_PORTAL_HOST}/claims/claim?policy=${policyTerm.Id}&branding=amwins&Authorization=${userToken}&account=${this.accountId}`
            })
        }
      },
      async endorsementLink(policyTerm, correctDiscrepanciesClicked = false) {
        if (this.policyTermIsFloodMarket(policyTerm.SalesChannel) || this.isV2) {
          if (this.storeAccount.EndorsementFormStateId != null) {
            this.pendingEndorsementModal = true
          } else {
            const query = { accountId: this.storeAccount.id }
            if (correctDiscrepanciesClicked) {
              query.correctDiscrepancies = true
            }
            this.$router.push({ path: '/account/endorsement', query })
              .then(() => { window.scrollTo(0, 0); })
          }
        } else {
          this.$store.commit('blockStart')
          await acquireAccessToken()
            .then(async () => {
              const userToken = await getTokenAsync();
              this.$store.commit('blockComplete')
              window.location = `${Environment.SEASYS_PORTAL_HOST}/quotes/endorsement?policy=${policyTerm.Id}&branding=amwins&Authorization=${userToken}&account=${this.accountId}`
            })
        }
      },
      async cancellationLink(policyTerm) {
        if (this.policyTermIsFloodMarket(policyTerm.SalesChannel)) {
          this.$router.push({ path: '/account/flood-cancellation', query: { accountId: this.storeAccount.id } })
        } else if(this.isV2){
          this.$router.push({ path: '/account/cancellation', query: { accountId: this.storeAccount.id } })
        } else {
          this.$store.commit('blockStart')
          await acquireAccessToken()
            .then(async () => {
              const userToken = await getTokenAsync();
              this.$store.commit('blockComplete')
              window.location = `${Environment.SEASYS_PORTAL_HOST}/quotes/cancellation?policy=${policyTerm.Id}&branding=amwins&Authorization=${userToken}&account=${this.accountId}`
            })
        }
      },
      async reinstatementLink(policyTerm) {
        if (this.policyTermIsFloodMarket(policyTerm.SalesChannel)) {
          this.$router.push({ path: '/account/flood-reinstatement', query: { accountId: this.storeAccount.id } })
        } else if (this.isV2){
          this.$router.push({ path: '/account/reinstatement', query: { accountId: this.storeAccount.id } })
        } else {
          // TODO: Go to Seasys??

        }
      },
      policyExpanded(policyTerm) {
        const selectedPolicyTerm = this.storePolicyTerms[policyTerm.Index];
        if (selectedPolicyTerm.PolicyDocs.length === 0) {
          if (policyTerm.Type === 'Policy') {
            this.getTermDocs(selectedPolicyTerm.Id, selectedPolicyTerm.QuoteKey, selectedPolicyTerm.Index)
          } else {
            this.getPolicyDocs(selectedPolicyTerm.Id, selectedPolicyTerm.Index)
          }
        }
        if (selectedPolicyTerm.Claims.length === 0) {
          this.getPolicyClaims(selectedPolicyTerm.Id, selectedPolicyTerm.Index)
        }
        if (!selectedPolicyTerm.TransactionsHistory || selectedPolicyTerm.TransactionsHistory.length === 0) {
          this.getTransactionHistory(selectedPolicyTerm.Id, selectedPolicyTerm.Index)
        }
        if (!selectedPolicyTerm.Submission && this.isSupportOrHigher) {
          this.getPolicyTermSubmission(policyTerm.Index, selectedPolicyTerm.SubmissionId);
        }
      },
      claimsGridLoading(index) {
        if (this.claimsLoadingArray[index]?.loading) {
          return this.claimsLoadingArray[index].loading
        } else {
          return false
        }
      },
      policyDocsGridLoading(index) {
        if (this.policyDocsLoadingArray[index]?.loading) {
          return this.policyDocsLoadingArray[index].loading
        } else {
          return false
        }
      },
      transactionsGridLoading(index) {
        if (this.transactionsLoadingArray[index]?.loading) {
          return this.transactionsLoadingArray[index].loading
        } else {
          return false
        }
      },
      allPolicyTermsChanged() {
        this.getAccount(true)
      },
      paymentEnabled(balance) {
        if (!balance || balance <= 0) {
          return false
        }

        return true
      },
      openPaymentModal() {
        if (this.storeAccount.Balance > 0) {
          this.paymentModalTitle = `Pay Balance: ${this.formatMoney(this.storeAccount.Balance)}`
          this.paymentModal = true
        }
      },
      async makePayment(type) {
        this.$store.commit('blockStart')
        await acquireAccessToken()
          .then(async () => {
            const userToken = await getTokenAsync();
            this.$store.commit('blockComplete');
            let d = new Date();
            let url = `${Environment.SBIX_HOST}/Payment?cra=${this.storeAccount.Number}&type=${type}&amount=${this.storeAccount.Balance || 0}&Authorization=${userToken}&v=${d.getTime()}`
            this.openPaymentWindow(url);
          })
      },
      openPaymentWindow(url) {
        let height = window.innerHeight;
        let width = window.innerWidth;

        let top = Math.max((height - 1000) / 2, 0);
        let left = Math.max((width - 1000) / 2, 0);

        let windowHeight = Math.min(height, 1000);
        let windowWidth = Math.min(width, 1000);

        //The payment window is stored in a variable called 'w' so the method close() can be called upon it later
        let w = window.open(
          url,
          'payment',
          "menubar=1,location=no,resizable=1,width=" + windowWidth + ",height=" + windowHeight + ",top=" + top + ",left=" + left
        );

        var wInterval = setInterval(() => {
          if (w.closed) {
            clearInterval(wInterval);
          }
        }, 1000);

        //The app listens for a postMessage called 'message' from the popup window to know when a payment has been successfully submitted, to then close the window
        window.addEventListener('message', (event) => {

          clearInterval(wInterval);

          if (w.closed) { return; }

          let data = event.data;
          if (data == 'close') {
            // Instead of getting both the account and policyTerm calls, we may want to break them out into two separate calls and just update the
            // account to get the updated balance. Checking this in to verify the balance is updated after payment in dev, but this should be refactored.
            this.getAccount()
            this.paymentModal = false;
            w.close();
          }
          else if (data == 'cancel') {
            w.close();
          }

        }, false);
      },
      clone() {
        this.$router.push({ path: '/quote/start', query: { clone: this.storeAccount.id } })
      },
      async openInsuredPortalUserDialog() {
        this.showInsuredPortalUser = true;
        this.insuredInvitationEmail = null;
        if (!this.selectedCustomerRiskAccount) {
          const craKey = this.storeAccount.Number;
          const result = await this.$httpSBIX.get('api/CustomerRiskAccount/' + craKey, {
            'Cache-Control': 'no-cache',
            'Pragma': 'no-cache',
            'Expires': '0',
          });
          this.selectedCustomerRiskAccount = result.data;
          this.$forceUpdate();
        }
      },
      async unlinkCustomerRiskAccount() {
        const cra = { ...this.selectedCustomerRiskAccount };
        cra.Person = null;
        const result = await this.$httpSBIX.patch('api/CustomerRiskAccount/' + cra.Key, cra);
        this.selectedCustomerRiskAccount = result.data;
      },
      async onInvite() {
        const valid = this.$refs.insuredPortalInvitationObserver.validate()
        if (valid) {
          this.sendingInvitation = true;
          EmailService.insuredPortalInvitation({
            email: this.insuredInvitationEmail,
            customerRiskAccountKey: this.storeAccount.Number
          }).then(() => {
            this.selectedCustomerRiskAccount = null;
            this.$store.dispatch('toastSuccess', { Message: 'An email has been sent to ' + this.insuredInvitationEmail + ' with an invitation to the Insured Portal.' })
            this.openInsuredPortalUserDialog();
            this.sendingInvitation = false;
            this.$forceUpdate();
          }).catch(() => {
            this.sendingInvitation = false;
          })
        }

      },
      resumeEndorsement() {
        this.$router.push({ path: '/account/endorsement', query: { accountId: this.storeAccount.id } })
          .then(() => { window.scrollTo(0, 0); })
      },
      newEndorsement() {
        this.newEndorsmentConfirmation = true
      },
      newEndorsmentConfirmationNo() {
        this.newEndorsmentConfirmation = false
      },
      async newEndorsmentConfirmationYes() {
        await this.beginNewEndorsement()
      },
      async beginNewEndorsement() {
        this.loading = true
        this.pendingEndorsementModal = false
        this.newEndorsmentConfirmation = false
        await FormStateService.delete({ formStateId: this.storeAccount.EndorsementFormStateId })
          .then(async () => {
            this.$router.push({ path: '/account/endorsement', query: { accountId: this.storeAccount.id } })
              .then(() => { window.scrollTo(0, 0); })
          })
          .catch(() => {
            this.$store.dispatch('toastError', { Message: 'Failed to delete the Pending Endorsement' })
          }).finally(() => {
            this.loading = false
          })
      },
      calculateTotalPremium(pricing) {
        if (!pricing) return 0

        const annualPremium = pricing.Premium
        const fees = pricing.Fee
        let totalAssessments = 0;

        pricing.Assessments?.forEach(assessment => {
          totalAssessments += assessment.Amount
        })

        const totalPremium = annualPremium + fees + totalAssessments;
        return totalPremium
      },
      formatTransactionType(transactionType) {
        switch (transactionType) {
          case 'NewBusiness':
            return 'New Business'
          case 'Endorsement':
            return 'Endorsement'
          case 'Cancellation':
            return 'Cancellation'
          case 'Reinstatement':
            return 'Reinstatement'
          default:
            return ''
        }
      },
      getNumberOrLink (policyTerm) {
        if (!policyTerm || !policyTerm.Number) return null;
        if (this.isEmployeeOrHigher && policyTerm.AmlinkSubmissionFileDetailsUrl) {
          return `<a href="${policyTerm.AmlinkSubmissionFileDetailsUrl}" target="_blank">${policyTerm.Number}</a>`;
        }
        return policyTerm.Number;
      }
    }
  }
</script>
<style scoped lang="scss">
@import '@/style.scss';


.policy-details, .channel-details {
    label {
        font-weight: bold;
        color: $amwins-blue;
        margin-right: 5px;
    }
    > span {
        display: block;
        margin-bottom: 10px;
    }
    .balance {
        font-size: 32px;
    }
    .policy-tag {
        font-size: 24px;
        line-height: 26px;
        color: #00953B;
    }
}
.actions-menu a {
    text-decoration: none;
    &:hover {
        opacity: 0.8;
    }
    svg {
        display: inline-block;
        width: 20px;
    }
    .v-list-item {
        min-height: 40px;
    }
}
::v-deep .v-skeleton-loader__text {
    height: 30px;
}

.status-loading {
    display: inline-block; margin-right: 10px;
}

::v-deep .send-invitation-button * {
    color: white!important;
}
</style>