<template>
  <div>
    <v-container>

      <v-row>
        <v-col cols="12" lg="6" md="8">
          <DynamicFormControl :control="{
                                name: 'DateRange',
                                type: 'RadioButtonGroup',
                                value: dateRange,
                                config: {
                                    col: 12,
                                    small: true,
                                    options: dateRangeOptions,
                                    short: true
                                }
                              }"
                              @change="dateRange = $event.value" />
        </v-col>
        <v-col cols="12" lg="3" md="2">
          <DynamicFormControl v-if="this.dateRange === 999"
                              :control="{
                                name: 'BeginDate',
                                type: 'DateInput',
                                value: beginDate,
                                config: {
                                    label: 'Begin Date',
                                    col: 12,
                                    clearable: true,
                                    allowedDates: beginDateAllowedDates,
                                    short: true
                                }
                              }"
                              @change="beginDate = $event.value" />
        </v-col>
        <v-col cols="12" lg="3" md="2">
          <DynamicFormControl v-if="this.dateRange === 999"
                              :control="{
                                name: 'EndDate',
                                type: 'DateInput',
                                value: endDate,
                                config: {
                                    label: 'End Date',
                                    col: 12,
                                    clearable: true,
                                    allowedDates: endDateAllowedDates,
                                    short: true
                                }
                              }"
                              @change="endDate = $event.value" />
        </v-col>
      </v-row>

      <v-card class="mb-4">
        <ResultsTable :tableHeaders="tableHeaders"
                      :results="results"
                      :loading="loading"
                      sortBy="RecordInsert">

          <template v-slot:RecordInsert="{item}">
            {{ formatDateForDisplay(item.RecordInsert) }}
          </template>

          <template v-slot:FileType="{item}">
            {{ item.Type.Name }}
          </template>

          <template v-slot:fileId="{item}">
            <FormButton label="View"
                        size="large"
                        class="mr-4"
                        @click="view(item.Key)" />
            <FormButton label="Download"
                        size="large"
                        @click="download(item.Key)" />
          </template>

        </ResultsTable>
      </v-card>

    </v-container>
  </div>
</template>

<script>
  import ResultsTable from '@/components/shared/ResultsTable.vue'
  import FormButton from '@/components/form/FormButton.vue'
  import DynamicFormControl from '@/components/form/DynamicFormControl.vue'
  import { makeFilterableText, formatDateForDisplay } from '@/scripts/helper'

  export default {
    name: 'AgencyReports',
    components: {
      ResultsTable,
      FormButton,
      DynamicFormControl
    },
    props: {
      orgBranchId: Number
    },
    data() {
      return {
        tableHeaders: [
          { text: "Print Date", value: "RecordInsert", sortable: true, filterable: false },
          { text: "Report Type", value: "FileType", sortable: true, filterable: false },
          { text: "View/Download", value: "fileId", sortable: false, filterable: false },
        ],
        loading: false,
        results: [],
        dateRange: 30,
        dateRangeOptions: [
          {value: 7, text: '7 Days' },
          {value: 30, text: '30 Days'},
          {value: 60, text: '60 Days'},
          {value: 90, text: '90 Days'},
          {value: 999, text: 'Custom'}
        ],
        beginDate: '',
        endDate: '',
        beginDateAllowedDates: (val) => {
          return this.allowedDateRange(val) && (this.endDate === '' || this.endDate == null || new Date(val) < new Date(this.endDate))
        },
        endDateAllowedDates: (val) => {
          return this.allowedDateRange(val) && (this.beginDate === '' || this.beginDate == null || new Date(val) > new Date(this.beginDate))
        }
      }
    },
    computed: {
      isAgent() {
        return this.$store.getters.getUserRole == 'agent';
      },
      showCustomDatepickers() {
        return this.dateRange === 999
      },
      beginDateForRequest() {
        if (!this.dateRange) {
          return null
        }

        var d = new Date()
        if (this.dateRange < 999) {
          return new Date(d.getFullYear(), d.getMonth(), d.getDate() - this.dateRange)
        } else {
          if (!this.beginDate) {
            // If no begin date is set, default to a year ago
            return new Date(d.getFullYear() - 1, d.getMonth(), d.getDate())
          } else {
            return new Date(this.beginDate)
          }
        }
      },
      endDateForRequest() {
        if (!this.dateRange) {
          return null
        }

        if (this.dateRange < 999) {
          var d = new Date()
          return new Date(d.getFullYear(), d.getMonth(), d.getDate())
        } else {
          if (!this.endDate) {
            return null
          } else {
            return new Date(this.endDate)
          }
        }
      }
    },
    watch: {
      dateRange(val) {
        this.dateRangeChanged(val)
      },
      beginDate(val) {
        this.beginDateChanged(val)
      },
      endDate(val) {
        this.endDateChanged(val)
      },
      orgBranchId(val) {
        if (val && val > 0) {
          this.getReportData()
        }
      }
    },
    created() {
      this.initialize()
    },
    methods: {
      makeFilterableText,
      formatDateForDisplay,
      allowedDateRange(val) {
        // Dates input can only be within the last year
        var d = new Date()
        var aYearAgo = new Date(d.getFullYear() - 1, d.getMonth(), d.getDate())
        return new Date(val) <= new Date() && new Date(val) >= aYearAgo
      },
      initialize() {
        // Default custom data range's end date to the current date
        var d = new Date()
        this.endDate = this.formatDateForDisplay(d)
      },
      getReportData() {
        this.loading = true
        this.results = []

        this.$httpSBIX.get(`api/agency?orgBranchId=${this.orgBranchId}`, {
            handleError: false
          })
          .then((agencyResponse) => {
            if (agencyResponse && agencyResponse.data.Key) {
              const startDate = formatDateForDisplay(new Date(this.beginDateForRequest));
              const endDate = formatDateForDisplay(new Date(this.endDateForRequest));
              this.$httpSBIX.get(`api/Efile/GetAgencyReports?agencyKey=${agencyResponse.data.Key}&startDate=${startDate}&endDate=${endDate}`)
                .then((efileReponse) => {
                  this.results = efileReponse.data

                  // Add Sort ability for FileType
                  this.results.forEach(i => {
                    i.FileType = this.makeFilterableText(i.Type, 'Name');
                  });
                  })
                .finally(() => {
                  this.loading = false
                })
            }
          })
          .catch(() => {
            this.loading = false
          })
      },
      async view(id) {
        await this.$httpSBIX.get(`efile${id}`, { responseType: 'arraybuffer' })
          .then((result) => {
            if (result) {
              const blob = new Blob([result.data], { type: result.headers['content-type'] })
              const url = window.URL.createObjectURL(blob);
              var link = document.createElement('a');
              link.href = url;
              link.target = '_blank';
              link.click();
            } else {
              this.$store.dispatch('toastError', { Message: 'Failed to Open File' })
            }
          })
      },
      async download(id) {
        this.$store.dispatch('toastSuccess', { Message: 'Download Beginning Momentarily' })
        await this.$httpSBIX.get(`efile${id}`, { responseType: 'arraybuffer' })
          .then((result) => {
            if (result) {
              const blob = new Blob([result.data], { type: result.headers['content-type'] })
              const url = window.URL.createObjectURL(blob);
              var link = document.createElement('a')
              link.href = url;
              link.download = id;
              link.click();

              window.URL.revokeObjectURL(url);
            } else {
              this.$store.dispatch('toastError', { Message: 'Download Failed' })
            }
          })
      },
      dateRangeChanged(val) {
        this.dateRange = val
          this.getReportData()
      },
      beginDateChanged(val) {
        this.beginDate = val
        this.getReportData()
      },
      endDateChanged(val) {
        this.endDate = val
        this.getReportData()
      }
    }
  }
</script>

<style scoped lang="scss">
  @import '@/style.scss';
</style>
