import Vue from 'vue'
import axios from 'axios'
import store from '@/vuex/store';
import Environment from '@/scripts/environment'
import { getTokenAsync, isImpersonating } from '@/plugins/auth'

const timeout = 300000;
const baseURL = Environment.API_HOST;

const noToastErrorPaths = [
  "/api/SBIX/Catastrophe"
]

const instance = axios.create({
  baseURL: baseURL,
  timeout: timeout,
});

Vue.prototype.$http = instance;

export let cancelSource: any;
const controller = new AbortController();

import { serviceOptions } from '@/api/index.defs'

export function errorHandler(error: any) {

  const url = new URL(`${error.config.baseURL}${error.config.url}`);
  

  if(url && noToastErrorPaths.indexOf(url.pathname) >= 0)
  {
    console.log("[axios] Ignore Error to:", url.pathname);
    return;
  }
  

  let statusCode = 0;
  if (error.response && error.response.data && error.response.data.status) {
    statusCode = error.response.data.status;
  }

  if (error.message == 'canceled') return;

  if (statusCode == 423) return;

  if (error.message && error.message.indexOf(' 409') > -1) {
    store.dispatch('toastError', { Message: 'Your save has failed. Please try again.' });
    return;
  }

  if (error.response && error.response.data && typeof (error.response.data) == "string" && error.response.data.indexOf('Microsoft.') == -1) {
    store.dispatch('toastError', { Message: error.response.data });
  } else if (error.message) {
    store.dispatch('toastError', { Message: error.message });
  } else if (error.errors) {
    (Object.keys(error.errors)).forEach(e => {
      store.dispatch('toastError', { Message: error.errors[e] });
    });
  }

  //log anything else not handled
  console.error("[axios] Error", error);
}

//BFF API AXIOS INSTANCE
Vue.prototype.$http.interceptors.request.use(async (config: any) => {
  if (config.url.indexOf('Seek') > -1 || config.url.indexOf('Search') > -1) {
    if (cancelSource) cancelSource.cancel();
    cancelSource = axios.CancelToken.source();
    config.cancelToken = cancelSource.token
  }
  const token = await getTokenAsync();
  await isImpersonating().then((res) => {
    if (res) {
      config.headers['Cache-Control'] = 'no-cache, no-store';
    }
  })
  if (!config.headers['Content-Type']) config.headers['Content-Type'] = 'application/json';
  if (token) config.headers.Authorization = "Bearer " + token;
  return {
    ...config,
    signal: controller.signal
  };
})

Vue.prototype.$http.interceptors.response.use(
  (response: any) => {
    return response;
  }, (error: any) => {
    if (!error.config || (error.config && error.config.handleError !== false)) {
      errorHandler(error);
    }
    return Promise.reject(error);
  }, () => {
    cancelSource = null;
  }
);

serviceOptions.axios = instance

//SBIX API AXIOS INSTANCE
const instanceSBIX = axios.create({
  timeout: timeout,
  baseURL: Environment.SBIX_HOST
});

Vue.prototype.$httpSBIX = instanceSBIX;
Vue.prototype.$httpSBIX.interceptors.request.use(async (config: any) => {
  const tokenSBIX = await getTokenAsync();
  if (!config.headers['Content-Type']) config.headers['Content-Type'] = 'application/json';
  if (tokenSBIX) config.headers.Authorization = "Bearer " + tokenSBIX;
  config.headers['SystemsModuleKey'] = 'PL_PORTAL';
  return config;
})
Vue.prototype.$httpSBIX.interceptors.response.use(
  (response: any) => {
    return response;
  }, (error: any) => {
    if (!error.config || (error.config && error.config.handleError !== false)) {
      errorHandler(error);
    }
    return Promise.reject(error);
  }, () => {
  }
);


//Identity API AXIOS INSTANCE
const instanceIdentity = axios.create({
  timeout: timeout,
  baseURL: Environment.STS_DOMAIN
});

Vue.prototype.$httpIdentity = instanceIdentity;
Vue.prototype.$httpIdentity.interceptors.request.use(async (config: any) => {
  const token = await getTokenAsync();
  if (!config.headers['Content-Type']) config.headers['Content-Type'] = 'application/json';
  if (token) config.headers.Authorization = "Bearer " + token;
  return config;
})
Vue.prototype.$httpIdentity.interceptors.response.use(
  (response: any) => {
    return response;
  }, (error: any) => {
    if (!error.config || (error.config && error.config.handleError !== false)) {
      errorHandler(error);
    }
    return Promise.reject(error);
  }, () => {
  }
);



//EXTERNAL SERVICES AXIOS INSTANCE
const instanceExternal = axios.create({
  timeout: timeout,
});
Vue.prototype.$httpExternal = instanceExternal;
Vue.prototype.$httpExternal.interceptors.request.use((config: any) => {
  return config;
})
Vue.prototype.$httpExternal.interceptors.response.use((response: any) => {
  return response.data;
}, () => {
})

const AxoisInstances =
{
  bff: instance,
  sbix: instanceSBIX,
  externals: instanceExternal,
  identity: instanceIdentity
};

export default AxoisInstances;