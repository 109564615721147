<template>
  <div style="width: 100%; height: 800px; position: relative;" :style="{'max-height': maxHeight + 'px'}">
    <Loading :size="64" class="loading" />
    <template v-if="pdfPath">
      <object :data="pdfPath" height="800" width="100%" type="application/pdf" typemustmatch="false" style="max-height: 100%;"></object>
      <p class="mb-0 pt-1 text-center">
        <span @click="printDocument()" style="cursor: pointer;" class="px-4"><i class="fal fa-print"></i> Print</span>
        <a :href="pdfPath" :download="downloadName" style="text-decoration: none;" class="px-4" target="_blank"><i class="fal fa-download"></i> Download</a>
      </p>
    </template>
    
    
  </div>
</template>

<script>
import { DocumentService } from '@/api/DocumentService'
//import { TemplateType } from '@/api/index.defs'
import print from 'print-js'
import Environment from '@/scripts/environment'
import { SbixService } from '@/api/SbixService'
import { getTokenAsync } from '@/plugins/auth'
var contentDisposition = require('content-disposition')

export default {
  name: "DocumentPreview",
  data() {
    return {
      pdfPath: null,
      downloadName: '',
      maxHeight: window.innerHeight - 300
    }
  },
  props: {
    mode: {
      type: String,
      default: null
    },
    submission: {
      type: Object,
      default: () => {}
    },
    policyKey: {
      type: String,
      default: null
    },
    fileRefId: {
      type: String,
      default: null
    },
    customerRiskAccountKey: {
      type: String,
      default: null
    },
    save: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    printDocument() {
      print(this.pdfPath);
    },
    loadPreview_Human() {
      this.downloadName = this.submission.id + '.pdf';
      DocumentService.generatePdf({
        templateName: "SummaryHumanDocV1.docx",
        //transformType: TemplateType.SummaryHumanDocV1_From_SubmissionHumanV2,
        body: this.submission
      }, {
        responseType: 'blob' 
      }).then((data) => {
        const f = new File([data], 'review.pdf', {
          type: "application/pdf",
          lastModified: Date.now()
        });
        this.pdfPath = URL.createObjectURL(f); 
      })
    },
    async loadPreviewFileRef() {
      //get from header?    
      this.downloadName = this.fileRefId + '.pdf';

      this.$http.get(`/api/File/${this.fileRefId}`, {
        responseType: 'blob'
      }).then((result) => {
       try {
        this.downloadName = contentDisposition.parse(result.headers['content-disposition']).parameters.filename;
       }
      catch(e) {
        console.error("unable to parse content-disposition header", e);
       }

        const f = new File([result.data], this.downloadName, {
          type: result.headers['content-type'],
          lastModified: Date.now()
        });
        this.pdfPath = URL.createObjectURL(f); 
      })

    },
    async loadPreview_Digital() {
      this.downloadName = this.policyKey + '.pdf';
      
      if (this.save) {
        await SbixService.webApplication({
          body: this.submission,
          salesChannel: this.mode
        });
      }
      
      const token = await getTokenAsync();
      const path = Environment.SBIX_HOST + '/report/QuoteSummary?PolicyKey=' + this.policyKey + '&Authorization=' + token;

      this.$httpSBIX.get(path, {
        responseType: 'blob'
      }).then((data) => {
        const f = new File([data.data], 'review.pdf', {
          type: "application/pdf",
          lastModified: Date.now()
        });
        this.pdfPath = URL.createObjectURL(f); 
      })
    },
    async loadLossRun() {
      this.downloadName = "Loss Run - " + this.customerRiskAccountKey + '.pdf';
      
      const token = await getTokenAsync();
      const path = Environment.SBIX_HOST + '/report/ClaimLossRun?CustomerRiskAccountKey=' + this.customerRiskAccountKey + "&Authorization=" + token;

      this.$httpSBIX.get(path, {
        responseType: 'blob'
      }).then((data) => {
        const f = new File([data.data], 'LossRun.pdf', {
          type: "application/pdf",
          lastModified: Date.now()
        });
        this.pdfPath = URL.createObjectURL(f); 
      })
    }
  },
    mounted() {
    if(this.fileRefId)
    {
      this.loadPreviewFileRef();
    }
    else if (this.mode == 'Human') {
      this.loadPreview_Human();
    } else if (this.mode == 'Digital' || this.mode == 'Munich' || this.mode == 'TestV0') {
      this.loadPreview_Digital();
    } else if (this.mode == 'LossRun') {
      this.loadLossRun();
    }
  }
}
</script>

<style scoped>

  object {
    position: relative;
    z-index: 2;
  }

  .loading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 64px;
    width: 64px;
    z-index: 1;
  }

</style>
