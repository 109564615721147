<template>
    <div v-if="sortedNews.length">
        <splide :options="options" :slides="sortedNews" v-if="showSplide">
            <splide-slide v-for="item in sortedNews" :key="item.Id">
                <v-card class="news-card">
                    <v-row align-content="center" no-gutters style="height: 100%">
                        <v-col md="4" cols="12" class="news-image"
                               :style="{'background-image': 'url(\''+item.ImageUrl+'\')'}">
                        </v-col>
                        <v-col md="8" cols="12" style="height: 100%;">
                            <div class="pa-4" style="width: 100%; height: 100%; display: block;">
                                <h4 class="text-bold">{{item.Title}}</h4>
                                <p style="margin-bottom: 10px;">
                                    {{ item.AbstractForDisplay }}
                                </p>
                                <p style="height: 20px" class="text-right">
                                  <router-link :to="{ path: 'resources/news/article', query: { key: item.Id}}">
                                    <v-btn color="secondary" type="button" title="Read More" large>
                                      Read More
                                    </v-btn>
                                  </router-link>
                                </p>
                            </div>
                        </v-col>
                    </v-row>
                </v-card>
            </splide-slide>

        </splide>
    </div>
</template>

<script>
  import { Splide, SplideSlide } from '@splidejs/vue-splide';
  import '@splidejs/splide/dist/css/themes/splide-skyblue.min.css';
  import Environment from '@/scripts/environment'
  import AxoisInstances from '@/plugins/axios'


  export default {
    name: 'NewsSlider',
    components: {
      Splide,
      SplideSlide
    },
    data() {
      return {
        options: {
          type: 'loop',
          rewind: true,
          perPage: window.innerWidth > 768 ? 2 : 1,
          perMove: 1,
          gap: 20,
          focus: 0,
          autoplay: true,
          interval: 5000
        },
        showSplide: true
      };
    },
    computed: {
      sortedNews() {
        let newsListLocal = this.$store.getters['resource/getDashboardNews'];
        return newsListLocal.sort(function (a, b) {
          if (a.Date > b.Date) return -1
          return a.Date > b.Date ? 0 : 1
        })
      }
    },
    created() {
      this.initialize()

    },
    methods: {
      initialize() {

      //mock when in local host mode
      if (Environment.USEMOCK === true) {
        var MockAdapter = require("axios-mock-adapter");       

        var mock = new MockAdapter(AxoisInstances.bff);
        
        mock.onGet("/api/NewsFeed/articles")
          .reply(async (context) =>{
            console.warn("[AxiosMock](/api/NewsFeed/articles) Reponse", "204_NoContent");
            return [204, null];
          });
      }

        this.$store.dispatch('resource/getDashboardNews');
      }
    }
  }
</script>

<style lang="scss" scoped>
    @import '@/style.scss';

    .news-image {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        height: 100%;
    }

    .news-card {
        overflow: hidden;
        height: 100%;
        margin: 0 4px;
    }

    @media screen and (max-width: 768px) {
      .news-image {
        height: 200px;
      }

      .news-card {
          overflow: visible;
          height: auto;
          margin: 0 4px;
      }
    }

    .news-card * {
        -webkit-font-smoothing: antialiased;
    }

    .news-card h4 {
        margin-bottom: 5px;
    }

    .splide {
        padding: 1em 4em 2em;
    }

    .splide__slide {
        margin-bottom: 4px;
    }

    ::v-deep .splide__pagination__page {
        background-color: $gray6;
        border: 1px solid $gray2;
    }
    ::v-deep .splide__pagination__page.is-active {
        background-color: $gray2;
    }

    ::v-deep .splide__arrow svg {
        fill: $gray2;
    }

</style>
