<template>
  <span>{{display}}</span>
</template>

<script>
export default {
  name: 'RemainingTime',
  data() {
    return {
      display: null
    }
  },
  props: {
    date: [Object, String, Date],
    addDays: {
      type: Number,
      default: 0
    }
  },
  methods: {
    updateDisplay() {
        let future = new Date(this.date);
        if (this.addDays !== 0) {
          future.setDate(future.getDate() + this.addDays);
        }

        const now = new Date();
        const diff = future - now;

        const days = Math.floor(diff / (1000 * 60 * 60 * 24));
        const hours = Math.floor(diff / (1000 * 60 * 60));
        //const mins = Math.floor(diff / (1000 * 60));
        //const secs = Math.floor(diff / 1000);

        const d = days;
        const h = hours - days * 24;
        //const m = mins - hours * 60;
        //const s = secs - mins * 60;

        if (diff < 0) {
          this.display = 'Expired';
        } else if (days > 0) {
          this.display = d + ' ' + (d == 1 ? 'day' : 'days');
        } else {
          this.display = h + ' ' + (h == 1 ? 'hour' : 'hours');
        }

        this.$forceUpdate();
    }
  },
  mounted() {
    if (this.date) {
      this.updateDisplay();
      setInterval(this.updateDisplay, 60000);
    }
  }
}
</script>
