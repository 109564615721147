import {
  SeaSysSysInsuranceCarriers,
  SeaSysSysState,
  SBIXCountry,
  SeaSysMortgagee,
  SeaSysMortgageeOrg,
  SBIXCityStateZip,
  IList,
  List,
  IListResult,
  ListResultDto,
  IPagedResult,
  PagedResultDto,
  Dictionary,
  IDictionary,
  IRequestOptions,
  IRequestConfig,
  getConfigs,
  axios,
  basePath
} from './index.defs';

export class SysService {
  /** Generate by swagger-axios-codegen */
  // @ts-nocheck
  /* eslint-disable */

  /**
   *
   */
  static insuranceCarrier(
    params: {
      /**  */
      insuranceRegulatoryTypeCode?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SeaSysSysInsuranceCarriers[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Sys/InsuranceCarrier';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { InsuranceRegulatoryTypeCode: params['insuranceRegulatoryTypeCode'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static state(options: IRequestOptions = {}): Promise<SeaSysSysState[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Sys/State';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static diligentEffort(
    params: {
      /**  */
      product?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SeaSysSysState[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Sys/DiligentEffort';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { product: params['product'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static country(options: IRequestOptions = {}): Promise<SBIXCountry[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Sys/Country';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static mortgagee(
    params: {
      /**  */
      id?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SeaSysMortgagee> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Sys/Mortgagee';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static mortgageeOrg(
    params: {
      /**  */
      id?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SeaSysMortgageeOrg> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Sys/MortgageeOrg';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static mortgagees(
    params: {
      /**  */
      mortgageeOrgId?: number;
      /**  */
      mortgageeOrgBranchZip?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SeaSysMortgagee[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Sys/Mortgagees';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        MortgageeOrgID: params['mortgageeOrgId'],
        MortgageeOrgBranchZip: params['mortgageeOrgBranchZip']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static mortgageeSeek(
    params: {
      /**  */
      q?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SeaSysMortgagee[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Sys/MortgageeSeek';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { q: params['q'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static mortgageeOrgSeek(
    params: {
      /**  */
      q?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SeaSysMortgageeOrg[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Sys/MortgageeOrgSeek';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { q: params['q'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static cityStateZipSeek(
    params: {
      /**  */
      q?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SBIXCityStateZip[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Sys/CityStateZipSeek';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { q: params['q'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}
