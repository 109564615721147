<template>
  <div>
    <v-progress-circular
        indeterminate
        :size="size"
        :color="color"
    ></v-progress-circular>
  </div>
</template>

<script>
export default {
  name: "Loading",
  data() {
    return {}
  },
  props: {
    size: {
      type: Number,
      default: 32
    },
    color: {
      type: String,
      default: null
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/style.scss';

.v-progress-circular {color: $gray4;}

</style>