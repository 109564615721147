<template>
  <router-link :to="routeObj"
               :is="disableButton ? 'span' : 'router-link'">
    <v-btn color="secondary" type="button" x-small :disabled="disableButton">
      View
    </v-btn>
  </router-link>
</template>

<script>
  //import { SubmissionService } from '@/api/SubmissionService'
  // import { PolicyStatus } from '@/api/index.defs.ts'

  export default {
    name: 'ViewAccount',
    props: {
      account: Object
    },
    data() {
      return {
        routeObj: {}
      }
    },
    computed: {
      disableButton() {
        if (!this.account.id) {
          return true
        }

        return false
      }
    },
    async created() {
      if (this.account.id) {

        if (this.account.Type == 'Policy' || this.account.AccountStatus == 'Bound' || this.account.AccountStatus == 'Submitted' || this.account.AccountStatus == 'Quoted') {
          this.routeObj = {
            path: '/account', query: { id: this.account.id }
          }
        } else {
          this.routeObj = {
            path: '/quote/quote', query: { id: this.account.id }
          }
        }

      }
    }
  }

</script>

<style scoped>
</style>
