import {
  IList,
  List,
  IListResult,
  ListResultDto,
  IPagedResult,
  PagedResultDto,
  Dictionary,
  IDictionary,
  IRequestOptions,
  IRequestConfig,
  getConfigs,
  axios,
  basePath
} from './index.defs';

export class MappingService {
  /** Generate by swagger-axios-codegen */
  // @ts-nocheck
  /* eslint-disable */

  /**
   *
   */
  static getProgramCode(
    params: {
      /**  */
      coverageOption?: string;
      /**  */
      windPeril?: boolean;
      /**  */
      vacant?: boolean;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Mapping/program-code';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        coverageOption: params['coverageOption'],
        windPeril: params['windPeril'],
        vacant: params['vacant']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}
