<template>
    <div>

        <div v-if="initialOidcUserLoaded" class="dashboard-header" style="margin-top: -20px;">
            <v-container style="padding-top: 0; padding-bottom: 0;">
                <div class="d-sm-flex">
                    <h2>Welcome back, {{$store.getters.getUserGivenName}}!</h2>
                    <v-spacer></v-spacer>
                    <UnderwriterCard class="underwriter-card" />
                </div>
               
            </v-container>
        </div>

        <v-container v-if="initialOidcUserLoaded">
            <NewsSlider />

            <br><br>

            <v-row v-if="amlinkProfile && amlinkProfile.HumanId">
                <v-col sm="12"
                       xl="6">
                    <h3>Incomplete Submissions</h3>
                    <div>
                        <v-switch 
                                  v-model="myAccountsIncomplete"
                                  label="My Accounts"
                                  class="d-inline-block mr-4"
                                  @change="myAccountsIncompleteChanged"></v-switch>
                    </div>
                    <v-card class="mb-4">
                        <ResultsTable
                            :tableHeaders="submissionsTableHeaders"
                            :results="incompleteSubmissionsResults"
                            :loading="submissionsLoading">

                            <template v-slot:PolicyQuoteNumber="{item}">
                                <span>
                                    {{ item.PolicyNumber }}
                                </span>
                            </template>

                            <template v-slot:InsuredText="{item}">
                                <span>
                                    {{item.Insured.Name || '[No Name Entered]'}}<br>
                                    {{item.Risk.Street1}}<br>
                                    <span v-if="item.Risk.Street2">
                                        {{item.Risk.Street2}}<br>
                                    </span>
                                    <span v-if="item.Risk.City">
                                        {{item.Risk.City}}, {{item.Risk.State}} {{item.Risk.Postal}} {{item.Risk.Country}}<br>
                                    </span>
                                    <span v-if="item.Risk.County">
                                        {{item.Risk.County}} County
                                    </span>
                                </span>
                            </template>

                            <template v-slot:Status="{item}">
                                <PolicyStatus :Status="item.AccountStatus" />
                            </template>

                            <template v-slot:TermBegin="{item}">
                                <span>
                                    {{  nonDefaultDate(item.TermBegin) ? formatDateForDisplay(item.TermBegin, item.Product.toLowerCase() == 'flood') : '' }}
                                </span>
                                <span v-if="nonDefaultDate(item.TermEnd)">
                                    - {{ formatDateForDisplay(item.TermEnd, item.Product.toLowerCase() == 'flood') }}
                                </span>
                            </template>

                            <template v-slot:Updated="{item}">
                                <span>
                                    {{ formatDateForDisplay(item.Updated, item.Product.toLowerCase() == 'flood') }}
                                </span>
                            </template>

                            <template v-slot:Actions="{item}">
                                <ViewAccount :account="item" />
                            </template>

                        </ResultsTable>
                    </v-card>
                </v-col>   

                <v-col sm="12"
                       xl="6">
                    <h3>Pending Activity</h3>
                    <div>
                        <v-switch 
                                  v-model="myAccountsPending"
                                  label="My Accounts"
                                  class="d-inline-block mr-4"
                                  @change="myAccountsPendingChanged"></v-switch>
                    </div>
                    <v-card class="mb-4">
                        <ResultsTable 
                            :tableHeaders="pendingTableHeaders"
                            :results="pendingSubmissionsResults"
                            :loading="pendingLoading">

                            <template v-slot:InsuredText="{item}">
                                <span>
                                    {{item.Insured.Name || '[No Name Entered]'}}<br>
                                    {{item.Risk.Street1}}<br>
                                    <span v-if="item.Risk.Street2">
                                        {{item.Risk.Street2}}<br>
                                    </span>
                                    <span v-if="item.Risk.City">
                                        {{item.Risk.City}}, {{item.Risk.State}} {{item.Risk.Postal}} {{item.Risk.Country}}<br>
                                    </span>
                                    <span v-if="item.Risk.County">
                                        {{item.Risk.County}} County
                                    </span>
                                </span>
                            </template>

                            <template v-slot:TermBegin="{item}">
                              <span>
                                  {{  nonDefaultDate(item.TermBegin) ? formatDateForDisplay(item.TermBegin, item.Product.toLowerCase() == 'flood') : '' }}
                              </span>
                              <span v-if="nonDefaultDate(item.TermEnd)">
                                  - {{ formatDateForDisplay(item.TermEnd, item.Product.toLowerCase() == 'flood') }}
                              </span>
                            </template>

                            <template v-slot:Updated="{item}">
                                <span>
                                    {{ formatDateForDisplay(item.Updated, item.Product.toLowerCase() == 'flood') }}
                                </span>
                            </template>

                            <template v-slot:Actions="{item}">
                                <ViewAccount :account="item" />
                            </template>

                        </ResultsTable>
                    </v-card>
                </v-col>

            </v-row>
            <v-row v-else>
                <v-col sm="12">
                    <v-card>
                        <v-card-text>
                            Dashboard is unavailable. This account was not found in Amlink.
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <br><br>

        </v-container>

    </div>
</template>

<script>
import NewsSlider from '@/components/shared/NewsSlider.vue'
import UnderwriterCard from '@/components/shared/UnderwriterCard.vue'
import ResultsTable from '@/components/shared/ResultsTable.vue'
import ViewAccount from '@/components/shared/ViewAccount.vue'
import { AccountService } from '@/api/AccountService'
import { makeFilterableText, formatDateForDisplay, nonDefaultDate } from '@/scripts/helper'
import { AccountDashboardMode } from '@/api/index.defs'

export default {
    name: 'Dashboard',
    components: {
        NewsSlider,
        UnderwriterCard,
        ResultsTable,
        ViewAccount
    },
    computed: {
        amlinkProfile() {
            return this.$store.getters.getAmlinkProfile;
        },
        initialOidcUserLoaded() {
            return this.$store.getters.getUser
        },
        isAgent() {
          return this.$store.getters.userHasRole('agent');
        }
    },
    watch: {
        'amlinkProfile.HumanId'(val) {
            if (val) {
                this.getIncompleteSubmissionData()
                this.getPendingSubmissionData()
            }
        }
    },
    data() {
        return {
            submissionsLoading: false,
            pendingLoading: false,
            incompleteSubmissionsResults: [],
            pendingSubmissionsResults: [],
            submissionsTableHeaders: [
                {text: "Policy/Quote Number", value: "PolicyQuoteNumber", sortable: true, filterable: true},
                {text: "Insured", value: "InsuredText", sortable: false, filterable: true},
                {text: "Policy Status", value: "Status", sortable: false, filterable: true},
                {text: "Effective Date", value: "TermBegin", sortable: true, filterable: false},
                {text: "Date Modified", value: "Updated", sortable: true, filterable: false},
                {text: "Product", value: "Product", sortable: false, filterable: true},
                {text: "", value: "Actions", sortable: false, filterable: false},
            ],
            pendingTableHeaders: [
                {text: "Policy/Quote Number", value: "PolicyQuoteNumber", sortable: true, filterable: true},
                {text: "Insured", value: "InsuredText", sortable: false, filterable: true},
                {text: "Pending", value: "PendingTags", sortable: true, filterable: true},
                {text: "Effective Date", value: "TermBegin", sortable: true, filterable: false},
                {text: "Date Modified", value: "Updated", sortable: true, filterable: false},
                {text: "Product", value: "Product", sortable: false, filterable: true},
                {text: "", value: "Actions", sortable: false, filterable: false},
            ],
            myAccountsIncomplete: true,
            myAccountsPending: true,
        }
    },
    methods: {
        makeFilterableText,
        formatDateForDisplay,
        nonDefaultDate,
        initialize() {
          if (this.amlinkProfile && this.amlinkProfile.HumanId) {
              this.getIncompleteSubmissionData()
              this.getPendingSubmissionData()
          }
        },
        getIncompleteSubmissionData() {
            this.incompleteSubmissionsResults = []
            
            this.submissionsLoading = true

            AccountService.dashboard({ mode: AccountDashboardMode.Incomplete, scopeToUser: this.myAccountsIncomplete })
                .then((searchResponse) => {
                    searchResponse.forEach(item => {
                        // Add filter for PolicyNumber
                        item.PolicyQuoteNumber = this.makeFilterableText(item, 'PolicyNumber');
                        // Add filter for InsuredText
                        item.InsuredText = this.makeFilterableText(item.Insured, 'Name');
                        item.InsuredText += this.makeFilterableText(item.Risk, 'Street1');
                        item.InsuredText += this.makeFilterableText(item.Risk, 'Street2');
                        item.InsuredText += this.makeFilterableText(item.Risk, 'City');
                        item.InsuredText += this.makeFilterableText(item.Risk, 'State');
                        item.InsuredText += this.makeFilterableText(item.Risk, 'City');
                        item.InsuredText += this.makeFilterableText(item.Risk, 'Postal');
                        item.InsuredText += this.makeFilterableText(item.Risk, 'Country');
                        item.InsuredText += this.makeFilterableText(item.Risk, 'County');
                        this.incompleteSubmissionsResults.push(item)
                    })
                })
                .finally(() => {
                    this.submissionsLoading = false
                })
        },
        getPendingSubmissionData() {
            this.pendingSubmissionsResults = []
            
            this.pendingLoading = true

            AccountService.dashboard({ mode: AccountDashboardMode.Pending, scopeToUser: this.myAccountsPending })
                .then((searchResponse) => {
                    searchResponse.forEach(item => {
                        // Add filter for PolicyNumber
                        item.PolicyQuoteNumber = this.makeFilterableText(item, 'PolicyNumber');
                        // Add filter for InsuredText
                        item.InsuredText = this.makeFilterableText(item.Insured, 'Name');
                        item.InsuredText += this.makeFilterableText(item.Risk, 'Street1');
                        item.InsuredText += this.makeFilterableText(item.Risk, 'Street2');
                        item.InsuredText += this.makeFilterableText(item.Risk, 'City');
                        item.InsuredText += this.makeFilterableText(item.Risk, 'State');
                        item.InsuredText += this.makeFilterableText(item.Risk, 'City');
                        item.InsuredText += this.makeFilterableText(item.Risk, 'Postal');
                        item.InsuredText += this.makeFilterableText(item.Risk, 'Country');
                        item.InsuredText += this.makeFilterableText(item.Risk, 'County');
                        item.PendingTags = item.PendingTags.replace(",", ", ").replace("_", " ")
                        this.pendingSubmissionsResults.push(item)
                      })
                    })
                .finally(() => {
                    this.pendingLoading = false
                })
        },
        myAccountsIncompleteChanged() {
            this.getIncompleteSubmissionData()
        },
        myAccountsPendingChanged() {
            this.getPendingSubmissionData()
        },
        
    },
    mounted() {
      this.initialize()
    }
}
</script>

<style scoped lang="scss">
    @import '@/style.scss';

    .dashboard-header {
        background-color: $amwins-blue;
        min-height: 122px;
        margin-bottom: 30px;
    }

    .dashboard-header h2 {
        color: white;
        font-weight: bold;
        margin-bottom: 0;
        padding-top: 43px;
    }

    .underwriter-card {
        margin-top: 20px;
        margin-bottom: -20px;
    }

    h3 {
        color: $amwins-blue;
        font-weight: bold;
        margin-bottom: 10px;
    }

    a {
        text-decoration: none;
        color: $amwins-secondary-medium-blue!important;
    }

</style>
