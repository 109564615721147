import {
  PolicyV1,
  Amwins_Orbits_Contracts_Models_v1_Policies_PolicyV1,
  Amwins_Orbits_Contracts_MessageContracts_Policies_PolicyHeaderV1,
  Amwins_Orbits_Contracts_Models_SalesChannels_SalesChannel,
  Amwins_Orbits_Contracts_Models_ProductType,
  Amwins_Orbits_Contracts_Models_v1_Policies_PolicyPricing,
  Amwins_Orbits_Contracts_Models_v1_Taxes_Assessment,
  Amwins_Orbits_Contracts_Models_v1_Taxes_AssessmentClassificationV1,
  Amwins_Orbits_Contracts_Models_v1_Policies_PolicyStatus,
  Amwins_Orbits_Contracts_Models_v1_Policies_BindingStatus,
  Amwins_Orbits_Contracts_Models_v1_Policies_PolicyDocumentsV1,
  Amwins_Orbits_Contracts_Models_Files_FileReference,
  Amwins_Orbits_Contracts_MessageContracts_Quotes_PaymentSource,
  Amwins_Orbits_Contracts_Models_Payments_PaymentOption,
  Amwins_Orbits_Contracts_Models_AdditionalInterestInformation_Mortgagee,
  Amwins_Orbits_Contracts_Models_v1_InsuredInformation_MailingAddressV1,
  Amwins_Orbits_Contracts_Models_v1_Policies_NewBusinessTransactionV1,
  Amwins_Orbits_Contracts_Models_Policies_TransactionType,
  Amwins_Orbits_Contracts_Models_v1_RiskInformation_RiskV1,
  Amwins_Orbits_Contracts_Models_v1_RiskInformation_LocationV1,
  Amwins_Orbits_Contracts_Models_v1_RiskInformation_BuildingV1,
  Amwins_Orbits_Contracts_Models_v1_RiskInformation_BuildingType,
  Amwins_Orbits_Contracts_Models_v1_AddressV1,
  Amwins_Orbits_Contracts_Models_v1_RiskInformation_OccupancyType,
  Amwins_Orbits_Contracts_Models_v1_RiskInformation_MonthsOccupiedV1,
  Amwins_Orbits_Contracts_Models_v1_RiskInformation_ConstructionType,
  Amwins_Orbits_Contracts_Models_v1_RiskInformation_FoundationType,
  Amwins_Orbits_Contracts_Models_v1_RiskInformation_BasementType,
  Amwins_Orbits_Contracts_Models_v1_RiskInformation_ElevatedEnclosureType,
  Amwins_Orbits_Contracts_Models_v1_RiskInformation_NumberOfStoriesV1,
  Amwins_Orbits_Contracts_Models_v1_RiskInformation_SquareFootageV1,
  Amwins_Orbits_Contracts_Models_v1_RiskInformation_YearBuiltV1,
  Amwins_Orbits_Contracts_Models_v1_RiskInformation_AttachedGarageType,
  Amwins_Orbits_Contracts_Models_v1_RiskInformation_BuildingOverWaterType,
  Amwins_Orbits_Contracts_Models_v1_RiskInformation_FinishedFirstFloorElevationV1,
  Amwins_Orbits_Contracts_Models_v1_RiskInformation_FloodZone,
  Amwins_Orbits_Contracts_Models_v1_RiskInformation_ElevationHeightV1,
  Amwins_Orbits_Contracts_Models_v1_RiskInformation_FEMABaseFloodElevationV1,
  Amwins_Orbits_Contracts_Models_v1_RiskInformation_DistanceToNearestWaterV1,
  Amwins_Orbits_Contracts_Models_v1_RiskInformation_DistanceToCoastV1,
  Amwins_Orbits_Contracts_Models_v1_RiskInformation_DistanceToFloodSourceAvailableV1,
  Amwins_Orbits_Contracts_Models_v1_RiskInformation_ElevationCertificateAvailableV1,
  Amwins_Orbits_Contracts_Models_v1_RiskInformation_HighestFinishedGradeNextToBuildingV1,
  Amwins_Orbits_Contracts_Models_v1_RiskInformation_LowestOccupiedFloor,
  Amwins_Orbits_Contracts_Models_v1_RiskInformation_BuildingValuation,
  Amwins_Orbits_Contracts_Models_v1_RiskInformation_ValuationType,
  Amwins_Orbits_Contracts_Models_v1_RiskInformation_ContentsValuation,
  Amwins_Orbits_Contracts_Models_v1_RiskInformation_CrawlspaceCompletionStatus,
  Amwins_Orbits_Contracts_Models_v1_RiskInformation_NumberOfStepsV1,
  Amwins_Orbits_Contracts_Models_v1_RiskInformation_ValuationRCV,
  Amwins_Orbits_Contracts_Models_v1_RiskInformation_ValuationACV,
  Amwins_Orbits_Contracts_Models_v1_RiskInformation_DistanceAttributeV1,
  Amwins_Orbits_Contracts_Models_v1_CoverageInformation_CoveragesV1,
  Amwins_Orbits_Contracts_Models_FormType,
  Amwins_Orbits_Contracts_Models_v1_CoverageInformation_CoverageAV1,
  Amwins_Orbits_Contracts_Models_v1_CoverageInformation_LiabilityV1,
  Amwins_Orbits_Contracts_Models_v1_CoverageInformation_LimitV1,
  Amwins_Orbits_Contracts_Models_v1_CoverageInformation_DeductibleV1,
  Amwins_Orbits_Contracts_Models_v1_CoverageInformation_DeductibleMode,
  Amwins_Orbits_Contracts_Models_v1_CoverageInformation_MinimumDeductibleV1,
  Amwins_Orbits_Contracts_Models_Percentage,
  Amwins_Orbits_Contracts_Models_v1_CoverageInformation_AOPV1,
  Amwins_Orbits_Contracts_Models_v1_CoverageInformation_WaterPerilV1,
  Amwins_Orbits_Contracts_Models_v1_CoverageInformation_WindPerilV1,
  Amwins_Orbits_Contracts_Models_v1_CoverageInformation_WindDeductibleType,
  Amwins_Orbits_Contracts_Models_v1_CoverageInformation_FloodPerilV1,
  Amwins_Orbits_Contracts_Models_v1_CoverageInformation_StormSurgePerilV1,
  Amwins_Orbits_Contracts_Models_v1_CoverageInformation_EarthquakePerilV1,
  Amwins_Orbits_Contracts_Models_v1_CoverageInformation_OrdinanceAndLawV1,
  Amwins_Orbits_Contracts_Models_v1_CoverageInformation_CoverageBV1,
  Amwins_Orbits_Contracts_Models_v1_CoverageInformation_CoverageCV1,
  Amwins_Orbits_Contracts_Models_v1_CoverageInformation_CoverageDV1,
  Amwins_Orbits_Contracts_Models_v1_CoverageInformation_CoverageEV1,
  Amwins_Orbits_Contracts_Models_v1_CoverageInformation_CoverageFV1,
  Amwins_Orbits_Contracts_Models_v1_InsuredInformation_InsuredV1,
  Amwins_Orbits_Contracts_Models_v1_InsuredInformation_PersonV1,
  Amwins_Orbits_Contracts_Models_v1_InsuredInformation_ConsumerCreditDisclosureV1,
  Amwins_Orbits_Contracts_Models_Payments_InsuredEmailOptions,
  Amwins_Orbits_Contracts_Models_v1_InsuredInformation_ClaimV1,
  Amwins_Orbits_Contracts_Models_v1_InsuredInformation_ClaimType,
  Amwins_Orbits_Contracts_Models_v1_InsuredInformation_ClaimAmountV1,
  Amwins_Orbits_Contracts_Models_v1_InsuredInformation_ClaimRepairedV1,
  Amwins_Orbits_Contracts_Models_v1_InsuredInformation_ElevatedAfterPriorFloodLossV1,
  Amwins_Orbits_Contracts_Models_v1_InsuredInformation_ClaimSettledV1,
  Amwins_Orbits_Contracts_Models_v1_InsuredInformation_ClaimSettledDateV1,
  Amwins_Orbits_Contracts_Models_v1_Policies_EndorsementTransactionV1,
  Amwins_Orbits_Contracts_Models_Endorsements_EndorsementType,
  Amwins_Orbits_Contracts_Models_v1_Policies_CancellationTransactionV1,
  Amwins_Orbits_Contracts_Models_v1_Policies_ReinstatementTransactionV1,
  Amwins_Orbits_Contracts_Models_v1_Policies_AmlinkIdentifiers,
  Amwins_Orbits_Contracts_Models_v1_Policies_AmlinkEndorsement,
  Amwins_Orbits_Contracts_Models_v1_Quotes_PricingV1,
  Amwins_Orbits_Contracts_Models_Quotes_PricingStatus,
  Amwins_Orbits_Contracts_Models_Quotes_QuoteEligibilityStatus,
  Amwins_Orbits_Contracts_Models_Quotes_PlacementStatus,
  Amwins_Orbits_Contracts_Models_v1_Quotes_QuoteDocumentsV1,
  v2PolicyV2,
  Amwins_Orbits_Contracts_Models_v2_Policies_PolicyV2_System_Nullable_System_Text_Json_JsonElement__System_Nullable_System_Text_Json_JsonElement,
  Amwins_Orbits_Contracts_Models_v2_PricingV2,
  Amwins_Orbits_Contracts_Models_v2_FeeV2,
  Amwins_Orbits_Contracts_Models_v2_FeeClassificationV2,
  Amwins_Orbits_Contracts_Models_Inspections_InspectionProvider,
  Amwins_Orbits_Contracts_Models_v2_ServiceFee,
  Amwins_Orbits_Contracts_Models_v2_Policies_PolicyDocumentsV2,
  Amwins_Orbits_Contracts_Models_v2_InsuredInformation_InsuredV2,
  Amwins_Orbits_Contracts_Models_AdditionalInterestInformation_AdditionalInterest,
  Amwins_Orbits_Contracts_Models_v2_Policies_NewBusinessTransactionV2_System_Nullable_System_Text_Json_JsonElement__System_Nullable_System_Text_Json_JsonElement,
  System_Text_Json_JsonElement,
  System_Text_Json_JsonValueKind,
  Amwins_Orbits_Contracts_Models_v2_Policies_EndorsementTransactionV2_System_Nullable_System_Text_Json_JsonElement__System_Nullable_System_Text_Json_JsonElement,
  Amwins_Orbits_Contracts_Models_v2_Policies_CancellationTransactionV2_System_Nullable_System_Text_Json_JsonElement__System_Nullable_System_Text_Json_JsonElement,
  Amwins_Orbits_Contracts_Models_v2_Policies_ReinstatementTransactionV2_System_Nullable_System_Text_Json_JsonElement__System_Nullable_System_Text_Json_JsonElement,
  Amwins_Orbits_Contracts_Models_v2_RiskModelType,
  Amwins_Orbits_Contracts_Models_v2_CoverageModelType,
  Amwins_Orbits_Contracts_Models_v2_Quotes_QuoteBindRequirementsV2,
  Amwins_Orbits_Contracts_Models_Inspections_InspectionType,
  Amwins_Orbits_Contracts_Models_v2_Quotes_QuoteDocumentsV2,
  Amwins_Orbits_Contracts_MessageContracts_v2_Predecessor,
  EndorsementV1,
  Amwins_Orbits_Contracts_MessageContracts_Endorsements_EndorsementStateUpdatedV1,
  Amwins_Orbits_Contracts_Models_v1_Endorsements_EndorsementV1,
  Amwins_Orbits_Contracts_Models_Endorsements_EndorsementStatus,
  Amwins_Orbits_Contracts_Models_ChangedElement,
  Amwins_Orbits_Contracts_Models_TrackedElement,
  Amwins_Orbits_Contracts_MessageContracts_Endorsements_EndorsementHeaderV1,
  v2EndorsementV2,
  Amwins_Orbits_Contracts_MessageContracts_Endorsements_v2_EndorsementStateUpdatedV2_System_Nullable_System_Text_Json_JsonElement__System_Nullable_System_Text_Json_JsonElement,
  Amwins_Orbits_Contracts_Models_v2_Endorsements_EndorsementV2_System_Nullable_System_Text_Json_JsonElement__System_Nullable_System_Text_Json_JsonElement,
  Amwins_Orbits_Contracts_Models_User,
  Amwins_Orbits_Contracts_Models_UserRole,
  FloodCancellationForm,
  SubmissionRiskData,
  Attestation,
  v2CancellationFormV2,
  Amwins_Orbits_Contracts_Models_v1_PolicyCancellations_CancellationReason,
  Amwins_Orbits_Contracts_Models_v1_PolicyCancellations_CancellationSubReason,
  FloodReinstatementForm,
  v2ReinstatementFormV2,
  Amwins_Orbits_Contracts_Models_Reinstatements_ReinstatementReason,
  Amwins_Orbits_Contracts_MessageContracts_Endorsements_v2_EndorsementRequestV2_System_Nullable_System_Text_Json_JsonElement__System_Nullable_System_Text_Json_JsonElement,
  Amwins_Orbits_Contracts_MessageContracts_Policies_v2_InspectionCompletedV2,
  Amwins_Portal_Api_Services_ReinstatementReasonsResponse,
  Amwins_Portal_Api_Services_ReinstatementReasonWithSubReasons,
  Amwins_Portal_Api_Services_CancellationReasonsResponse,
  Amwins_Portal_Api_Services_CancellationReasonWithSubReasons,
  IList,
  List,
  IListResult,
  ListResultDto,
  IPagedResult,
  PagedResultDto,
  Dictionary,
  IDictionary,
  IRequestOptions,
  IRequestConfig,
  getConfigs,
  axios,
  basePath
} from './index.defs';

export class PolicyService {
  /** Generate by swagger-axios-codegen */
  // @ts-nocheck
  /* eslint-disable */

  /**
   *
   */
  static get(
    params: {
      /**  */
      submissionId?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PolicyV1> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Policy';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { submissionId: params['submissionId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getV2(
    params: {
      /**  */
      submissionId?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<v2PolicyV2> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Policy/v2';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { submissionId: params['submissionId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getEndorsement(
    params: {
      /**  */
      id?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EndorsementV1> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Policy/Endorsement';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getEndorsementV2(
    params: {
      /**  */
      id?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<v2EndorsementV2> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Policy/EndorsementV2';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static cancelRequest(
    params: {
      /**  */
      body?: FloodCancellationForm;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Policy/CancelRequest';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static cancelRequestV2(
    params: {
      /**  */
      body?: v2CancellationFormV2;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Policy/CancelRequestV2';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static reinstatementRequest(
    params: {
      /**  */
      body?: FloodReinstatementForm;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Policy/ReinstatementRequest';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static reinstatementRequestV2(
    params: {
      /**  */
      body?: v2ReinstatementFormV2;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Policy/ReinstatementRequestV2';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static endorsementRequest(
    params: {
      /**  */
      formStateId?: string;
      /**  */
      submissionId?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Policy/EndorsementRequest';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { formStateId: params['formStateId'], submissionId: params['submissionId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static endorsementRequestV2(
    params: {
      /**  */
      body?: Amwins_Orbits_Contracts_MessageContracts_Endorsements_v2_EndorsementRequestV2_System_Nullable_System_Text_Json_JsonElement__System_Nullable_System_Text_Json_JsonElement;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Policy/EndorsementRequestV2';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static inspectionCompletedV2(
    params: {
      /**  */
      body?: Amwins_Orbits_Contracts_MessageContracts_Policies_v2_InspectionCompletedV2;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Policy/InspectionCompletedV2';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static submitEndorsement(
    params: {
      /**  */
      accountId?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Policy/SubmitEndorsement';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { accountId: params['accountId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static submitEndorsementV2(
    params: {
      /**  */
      accountId?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Policy/SubmitEndorsementV2';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { accountId: params['accountId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getReinstatementReasons(
    params: {
      /**  */
      value?: string;
      /**  */
      id?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Amwins_Portal_Api_Services_ReinstatementReasonsResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Policy/ReinstatementReasons';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Value: params['value'], Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getCancellationReasons(
    params: {
      /**  */
      value?: string;
      /**  */
      id?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Amwins_Portal_Api_Services_CancellationReasonsResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Policy/CancellationReasons';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { Value: params['value'], Id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}
