<template>
    <div>

        <Dialog :title="jsonModalTitle" :value="showModal" @close-dialog="closeModal" width="70%">
      <template v-slot:card-text>
        <span style="text-align: center;">
            <pre>{{ modalData }}</pre>
      </span>
       
      </template>
      <template v-slot:card-actions>
          <v-btn color="secondary" text type="button" @click="closeModal">No</v-btn>
          <v-btn color="secondary" text type="button" @click="clear()">Yes</v-btn>
      </template>
    </Dialog>
        <v-container>
            <v-row>
                <v-col>
            <h1>Message Queue Admin</h1>
            <h5><i>Dead letter queue counts refresh every 10 minutes</i></h5>
        </v-col>
        <v-col>
            <v-btn @click="refresh" :disabled="messageQueueTopologyLoading">Refresh</v-btn>
            <v-btn @click="openModal" :disabled="messageQueueTopologyLoading">Clear All</v-btn>            
        </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-alert type="info" v-if="!messageQueueTopologyLoading && !messageQueueTopology">No data</v-alert>
            <MessageQueueTopologyTable v-if="messageQueueTopology" :messageQueueTopologyLoading="messageQueueTopologyLoading"
                :subscribingApplications="messageQueueTopology.SubscribingApplications" />
            </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import { MessageQueueAdminService } from '@/api/MessageQueueAdminService'
import Environment from '@/scripts/environment'
import AxoisInstances from '@/plugins/axios'
import MessageQueueTopologyTable from './MessageQueueTopologyTable.vue'
import Dialog from '@/components/shared/Dialog.vue'

export default {
    name: 'MessageQueueAdmin',
    components: {
        MessageQueueTopologyTable,
        Dialog
    },
    data()
    {
        return {
            messageQueueTopologyLoading: false,
            messageQueueTopology: null,
            modalData: null,
            jsonModalTitle: "",
            showModal: false

        }
    },
    methods: {
        initialize() {
            // if (Environment.environment == "localhost") {
            //     var MockAdapter = require("axios-mock-adapter");

            //     var mock = new MockAdapter(AxoisInstances.bff);
            //     mock.onGet("api/MessageQueueAdmin/MessageQueueTopology")
            //         .reply(200, {
            //             "SubscribingApplications": [
            //                 {
            //                     "ApplicationName": "example-app-api",
            //                     "SubscriptionTopics": [
            //                         {
            //                             "Topic": "example-topic-1",
            //                             "DeadLetterQueueCount": 0
            //                         },
            //                         {
            //                             "Topic": "example-topic-2",
            //                             "DeadLetterQueueCount": 0
            //                         }
            //                     ],
            //                     "DeadLetterQueueCount": 0
            //                 },
            //                 {
            //                     "ApplicationName": "some-other-app-api",
            //                     "SubscriptionTopics": [
            //                         {
            //                             "Topic": "example-topic-1",
            //                             "DeadLetterQueueCount": 10
            //                         }
            //                     ],
            //                     "DeadLetterQueueCount": 10
            //                 },
            //                 {
            //                     "ApplicationName": "hello-world-api",
            //                     "SubscriptionTopics": [
            //                         {
            //                             "Topic": "example-topic-1",
            //                             "DeadLetterQueueCount": 10
            //                         },
            //                         {
            //                             "Topic": "example-topic-2",
            //                             "DeadLetterQueueCount": 25
            //                         },
            //                         {
            //                             "Topic": "example-topic-50",
            //                             "DeadLetterQueueCount": 100
            //                         }
            //                     ],
            //                     "DeadLetterQueueCount": 135
            //                 },
            //                 {
            //                     "ApplicationName": "channel-test-api",
            //                     "SubscriptionTopics": [
            //                         {
            //                             "Topic": "example-topic-1",
            //                             "DeadLetterQueueCount": 9
            //                         },
            //                         {
            //                             "Topic": "example-topic-2",
            //                             "DeadLetterQueueCount": 0
            //                         },
            //                         {
            //                             "Topic": "example-topic-50",
            //                             "DeadLetterQueueCount": 0
            //                         }
            //                     ],
            //                     "DeadLetterQueueCount": 9
            //                 }
            //             ]
            //         })
            // }

            this.getMessageQueueTopology();
        },
        refresh() {
            this.messageQueueTopologyLoading = true;
            MessageQueueAdminService.refreshCache()
            .then(() => {
                this.getMessageQueueTopology();
            })
        },
        clear() {
            this.messageQueueTopologyLoading = true;
            this.showModal = false;
            this.modalMode = null;
            this.modalData = null;
             MessageQueueAdminService.clearAllDeadLetters()
             .then(() => {
                setTimeout(() => {
            // Call getDeadLetterQueueMessages after 10 seconds
            this.getDeadLetterQueueMessages();

            // Turn off loading indicator after 10 seconds
            this.deadLetterQueueMessagesLoading = false;
        }, 60000); // 10000 milliseconds = 10 seconds
            })
            this.refresh();
        },
        openModal() {
            this.modalData = "Are you sure you want to remove all messages in each queue? This will commence background job untill all messages are removed"
            this.showModal = true;
            this.jsonModalTitle = `Message Queue Admin Service - Remove all dead letters`
       },
        closeModal() {
            this.showModal = false;
            this.modalMode = null;
            this.modalData = null;
      
        },
        getMessageQueueTopology() {
            this.messageQueueTopology = null;
            this.messageQueueTopologyLoading = true;

            MessageQueueAdminService.messageQueueTopology()
            .then((response) => {
                this.messageQueueTopology = response;
            })
            .finally(() => {
                this.messageQueueTopologyLoading = false
            })
        }
    },
    mounted() {
        this.initialize()
    }
}
</script>