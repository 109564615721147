<template>
    <div>
        <v-text-field v-if="!currency"
            v-model="newValue"
            outlined
            :label="required ? label + '*' : label" 
            :type="type || 'text'"
            :error="errors && errors.length > 0"
            :class="errors && errors.length ? 'hasError' : ''"
            :prefix="prefix"
            :suffix="suffix"
            :clearable="clearable || false"
            :autofocus="autofocus || false"
            :disabled="disabled || false"
            :min="min"
            :hide-details="true"
            :maxlength="maxlength"
            @blur="emitBlur"
            @keydown="emitKeydown"
            @keypress="emitKeypress">
            <template v-slot:prepend-inner><v-icon v-if="prependIcon">{{prependIcon}}</v-icon></template>
            <template v-slot:append><v-icon class="error-icon" v-if="errors && errors.length">mdi-alert</v-icon></template>
        </v-text-field>

        <!-- https://phiny1.github.io/v-currency-field/config.html#component-props -->
        <v-currency-field v-else-if="currency"
            v-model="newValue"
            outlined
            :label="required ? label + '*' : label" 
            :error="errors && errors.length > 0"
            :class="errors && errors.length ? 'hasError' : ''"
            :prefix="prefix"
            :suffix="suffix"
            :clearable="clearable || false"
            :autofocus="autofocus || false"
            :disabled="disabled || false"
            locale="en-US"
            :decimal-length="decimalLength"
            :auto-decimal-mode="autoDecimalMode"
            :min="min"
            :max="max"
            :default-value="defaultValue"
            :value-as-integer="valueAsInteger"
            :allow-negative="allowNegative"
            :hide-details="true"
            @blur="emitBlur"
            @keydown="emitKeydown"
            @keypress="emitKeypress">
            <template v-slot:append><v-icon class="error-icon" v-if="errors && errors.length">mdi-alert</v-icon> </template>
        </v-currency-field>

        <ErrorMessages :errors="errors"></ErrorMessages>
    </div> 
</template>

<script>
import { trim } from '@/scripts/helper'

export default {
    name: 'TextInput',
    props: {
        value: {
            type: [String, Number],
            default: null
        },
        type: String,
        rules: String,
        label: String,
        prefix: String,
        suffix: String,
        required: Boolean,
        clearable: Boolean,
        autofocus: Boolean,
        disabled: Boolean,
        maxlength: {
            type: Number,
            default: 128
        },
        currency: {
            type: Boolean,
            default: false
        },
        phone: {
            type: Boolean,
            default: false
        },   
        year: {
            type: Boolean,
            default: false
        },
        errors: {
            type: Array,
            default: () => []
        },
        min: {
            type: Number,
            default: null
        },
        mask: {
            type: String,
        }, 
        max: {
            type: Number,
            default: null
        },
        allowNegative: {
            type: Boolean,
            default: true
        },
        defaultValue: {
            type: Number,
            default: null
        },
        valueAsInteger: {
            type: Boolean,
            default: false
        },
        autoDecimalMode: {
            type: Boolean,
            default: false
        },
        decimalLength: {
            type: [Number, Object],
            default: 0
        },
        prependIcon: String
    },
    emits: [
        'change',
        'keypress',
        'keydown',
        'blur'
    ],
    data() {
        return {
            newValue: null,
        }
    },
    methods: {
        emitValue() {
            if (!this.currency) this.newValue = trim(this.newValue);
            this.$emit('change', this.newValue);     
        },
        emitKeydown() {
            this.$emit('keydown', true);
        },
        emitKeypress(event) {
            this.$emit('keypress', event);
            // untill we have a better way to handle old JSONS lets check to see if they were trying to do a phone mask
            if(this.phone || this.mask === '###-###-####'){ 
                this.formatPhone(event);
            }
            else if(this.year || this.mask === '####'){ 
                this.formatYear(event);
            }
            
        },
        formatPhone(event){
            const charCode = (event.which) ? event.which : event.keyCode;
            // only allow number and keypad numbers
            if (!(charCode >= 48 && charCode <= 57)) {            
                event.preventDefault();
                return;
            }
            if(!this.newValue){
                return;
            }
            this.newValue = this.newValue?.replace(/[^0-9-]/g, '') ?? ''
            var numChars = this.newValue.length;
            if(numChars === 3 || numChars === 7){
                this.newValue += '-';
            }

        },
        formatYear(event){
            const charCode = (event.which) ? event.which : event.keyCode;
            // only allow number and keypad numbers
            if (!(charCode >= 48 && charCode <= 57)) {            
                event.preventDefault();
                return;
            }
            if(!this.newValue){
                return;
            }
            this.newValue = this.newValue?.replace(/[^0-9-]/g, '') ?? ''
            if(this.newValue.length > 3){
                this.newValue = this.newValue.substring(0, 3);
            }
        },
        emitBlur() {
            this.$emit('blur')
        }
    },
    computed: {
    },
    watch: {
        value: {
            immediate: true,
            handler(newVal) {
                this.newValue = newVal;
            }
        },
        newValue() {
            this.emitValue();
        }

    },
}

</script>

<style lang="scss" scoped>

::v-deep .v-input--is-label-active .v-input__prepend-inner,
::v-deep .v-input--is-dirty .v-input__prepend-inner, 
::v-deep .v-input--is-focused .v-input__prepend-inner,
::v-deep .v-input--is-label-active .v-input__prepend-inner,
::v-deep .v-input--is-dirty .v-input__prepend-inner, 
::v-deep .v-input--is-focused .v-input__prepend-inner {
    margin-top: 22px;
}

</style>