<template>
  <v-container fill-height>

    <v-row v-if="!isTimingDiscrepancy" justify="center">
      <v-col class="text-center">
        <div style="position:relative; top: 110px">
          Signing in...
        </div>
        <Loading :size="200" />
      </v-col>
    </v-row>

    <GenericDialog :value="isTimingDiscrepancy"
                   :title="`Machine Time Discrepancy`"
                   :width="`600px`"
                   persistent>
      <template v-slot:card-text>
        <v-row>
          <v-col cols="12">
            <p class="mb-0">
              Our system clock deviates more than 5 minutes from your machine's clock. Please contact your IT department to update your system time to access the Personal Lines Portal.
            </p>
          </v-col>
        </v-row>
      </template>

    </GenericDialog>
  </v-container>
</template>

<script>
  import Loading from '@/components/shared/Loading.vue'
  import GenericDialog from '@/components/shared/Dialog.vue'
  import { signinRedirectCallback } from '@/plugins/auth';

  export default {
    components: {
      Loading,
      GenericDialog
    },
    data() {
      return {
        isTimingDiscrepancy: false
      }
    },
    methods: {
      
    },
    async created() {
      try {
        var redirectPath = await signinRedirectCallback()
        console.log("[Auth] Callback Redirecting to:", redirectPath);
        this.$router.push({ path: redirectPath });
      }
      catch(e)
      {
        if(e.error == 'clock_sync') this.isTimingDiscrepancy = true;
        else
        {
          console.error("[Auth] Callback Unexpected error, reloading:", e);
          location.path = '/';
        }
      }
    }
  }

</script>
 