<template>
    <div>
        <v-container>
            <v-row>
                <v-col>
            <h1>Health Checks Dashboard</h1>
           
        </v-col>
        <v-col>
            <v-btn @click="refresh" :disabled="healthcheckLoading">Refresh</v-btn> 

              
        </v-col>
        <v-col>
         
            <v-btn @click="launchClarindoor()" color="secondary">View Clarion Door Status</v-btn>
            <v-btn @click="launchBellClements()" color="secondary">View Bell & Clements Status</v-btn>    
              
        </v-col>
      
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-alert type="info" v-if="!appHealthChecks &&healthcheckLoading">Loading Health Checks</v-alert>
            <HealthCheckDashboard v-if="appHealthChecks" :healthcheckLoading="healthcheckLoading"
               :subscribingApplications="appHealthChecks" 
               @remove-app="removeAppFromDashboard"/>
            </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import { HealthCheckService } from '@/api/HealthCheckService'
import Environment from '@/scripts/environment'
import AxoisInstances from '@/plugins/axios'
import HealthCheckDashboard from './HealthCheckDashboard.vue'
import { mapState } from 'vuex';

export default {
    name: 'HealthCheck',
    components: {
        HealthCheckDashboard
    },
    data()
    {
        return {
            healthcheckLoading: false
           
        }
    },
    computed: {
    ...mapState('health', ['appHealthChecks']),
  },
    methods: {
        initialize() {
            this.GetLiveAggregrateHealthChecks();
            
        },
        refresh() {
            this.GetLiveAggregrateHealthChecks();
            
        },
         GetLiveAggregrateHealthChecks() {
           this.$store.dispatch('health/fetchAppHealthChecks');
           this.healthcheckLoading = false;
           this.FetchEnd()
        },
        Fetch() {
         this.healthcheckLoading = true;
        },
        FetchEnd() {
         this.healthcheckLoading = false;
        },
        launchClarindoor() {
        const url = `https://status.zywave.com/`;
        window.open(url, '_blank');
        },
        launchBellClements() {
        const url = `https://bcmon.bellandclements.com/`;
        window.open(url, '_blank');
        },
        removeAppFromDashboard(data) {
            console.log(data)
            this.$store.dispatch('health/removeApp', data);
           // this.$store.getters('health/getItems');
      
            
          
        }
    }, 
    mounted() {

        this.initialize()
    }
}
</script>