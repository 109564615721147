var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('v-row',[_c('v-col',{staticClass:"text-center",attrs:{"md":"12","cols":"12"}},[_c('h1',[_vm._v("What coverage do you need?")]),_c('ValidationObserver',{attrs:{"tag":"span"},scopedSlots:_vm._u([{key:"default",fn:function({ handleSubmit }){return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('DynamicFormControl',{attrs:{"control":{
                                    type: 'RadioItemGroup',
                                    name: 'CoverageOption',
                                    value: _vm.CoverageOption,
                                    config: {
                                        label: null,
                                        rules: 'required',
                                        col: 12,
                                        itemCol: 2,
                                        iconSize: '86px',
                                        multiple: false,
                                        justify: 'center',
                                        showCheck: true,
                                        circle: true,
                                        containerSize: '100px',
                                        height: '200px',
                                        disabled: _vm.processing,
                                        options: _vm.programOptions
                                    }
                                }},on:{"change":_vm.updateCoverageOption}})],1)],1),(!_vm.CoverageOption)?_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12"}},[_c('label',[_c('a',{attrs:{"href":_vm.professionalLinesUrl}},[_vm._v("Looking for Professional Lines Coverage?")])])])],1):_vm._e(),(_vm.CoverageOption && _vm.prompt)?_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12"}},[_c('label',[_c('strong',[_vm._v(_vm._s(_vm.prompt))])])])],1):_vm._e(),(_vm.CoverageOption == 'wind')?_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('DynamicFormControl',{attrs:{"control":{
                                    type: 'RadioItemGroup',
                                    name: 'ExtraWind',
                                    value: _vm.ExtraWind,
                                    config: {
                                        label: null,
                                        rules: null,
                                        col: 12,
                                        itemCol: 3,
                                        iconSize: '40px',
                                        containerSize: '40px',
                                        multiple: false,
                                        justify: 'center',
                                        alignment: 'horizontal',
                                        options: _vm.extrasOptions,
                                        disabled: _vm.processing,
                                        short: true
                                    }
                                }},on:{"change":function($event){_vm.ExtraWind = $event.value}}})],1)],1):_vm._e(),_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.CoverageOption && _vm.CoverageOption != 'flood'),expression:"CoverageOption && CoverageOption != 'flood'"}]},[_c('v-col',{staticClass:"text-left pb-0",attrs:{"md":"12","cols":"12"}},[_c('DynamicFormControl',{attrs:{"control":{
                                    type: 'UnderwriterAutocomplete',
                                    name: 'Underwriter',
                                    config: {
                                        label:'Choose your Amwins Underwriter',
                                        rules: 'required',
                                        col: 12,
                                        disabled: _vm.processing
                                    }
                                }},on:{"change":function($event){_vm.underwriter = $event.value}}})],1)],1),(!_vm.isAgent)?_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.CoverageOption),expression:"CoverageOption"}]},[_c('v-col',{staticClass:"pt-0",attrs:{"md":"12","cols":"12"}},[_c('DynamicFormControl',{attrs:{"control":{
                                    type: 'AgentAutocomplete',
                                    name: 'Agent',
                                    config: {
                                        label:'Retail Agent',
                                        rules: 'required',
                                        col: 12,
                                        disabled: _vm.processing,
                                    }
                                }},on:{"change":function($event){_vm.agent = $event.value}}})],1)],1):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.CoverageOption),expression:"CoverageOption"}]},[_c('v-row',{staticClass:"d-flex align-top"},[_c('v-col',{staticClass:"text-center py-0",attrs:{"md":"12","cols":"12"}},[_c('br'),_c('label',[_c('strong',[_vm._v("Prefill Submission (optional):")])])]),(_vm.betaFeaturesEnabled)?_c('v-col',{staticClass:"text-left",attrs:{"md":"6","cols":"12"}},[_c('DynamicFormControl',{attrs:{"control":{
                                        type: 'FileUploadSingle',
                                        name: 'ACORDForm',
                                        value: _vm.ACORDForm,
                                        config: {
                                            label:'ACORD Form',
                                            rules: null,
                                            accept:'.pdf',
                                            disabled: _vm.processing || _vm.account != null,
                                            short: true                                           
                                        }
                                    },"isBetaFeature":""},on:{"change":function($event){_vm.ACORDForm = $event.value}}})],1):_vm._e(),(_vm.betaFeaturesEnabled)?_c('v-col',{staticClass:"px-0 divider-col",staticStyle:{"width":"0"},attrs:{"md":"auto","cols":"12"}},[_c('div',{staticClass:"divider"})]):_vm._e(),_c('v-col',{staticClass:"text-left",attrs:{"md":_vm.betaFeaturesEnabled ? 6 : 12,"cols":"12"}},[_c('DynamicFormControl',{attrs:{"control":{
                                        type: 'AccountAutocomplete',
                                        name: 'Account',
                                        value: _vm.account,
                                        config: {
                                            label:'Copy from prior Submission in the system',
                                            rules: '',
                                            col: 12,
                                            disabled: _vm.processing || _vm.ACORDForm != null,
                                            short: true,
                                            footnote: 'You have the option to Search for a previous risk you\'ve entered and copy (CLONE) that data into your new Submission.'
                                        }
                                    }},on:{"change":function($event){_vm.account = $event.value}}}),(_vm.account)?_c('AccountCard',{staticClass:"text-left",attrs:{"account":_vm.account}}):_vm._e()],1)],1)],1),_c('br'),_c('br'),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.CoverageOption),expression:"CoverageOption"}],key:"save",attrs:{"color":"secondary","x-large":"","type":"submit","disabled":!_vm.CoverageOption || (_vm.CoverageOption == 'wind' && !_vm.ExtraWind) || _vm.processing || _vm.loading}},[(_vm.loading)?_c('Loading',{staticClass:"mr-2",attrs:{"size":24}}):_vm._e(),_vm._v(" Start Submission ")],1),_c('br'),(_vm.processing)?[_c('br'),_c('br'),_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.CoverageOption),expression:"CoverageOption"}]},[_c('v-col',{attrs:{"md":"6","offset-md":"3","cols":"12"}},[_c('v-alert',{staticClass:"text-left",attrs:{"dense":"","type":"info"}},[_vm._v(" You will receive a notification when your submission is ready to start. ")])],1)],1)]:_vm._e()],2)]}}])}),_c('br'),_c('br'),_c('br')],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }