var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[(_vm.accountId)?_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12"}},[(_vm.accountId)?_c('v-btn',{staticClass:"go-back mb-0",attrs:{"outlined":""},on:{"click":_vm.goBack}},[_c('i',{staticClass:"fas fa-chevron-left"}),_vm._v(" Go Back ")]):_vm._e()],1)],1):_vm._e(),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('h1',[_vm._v("Requirements")])])],1),(!_vm.showMessage && !_vm.processing)?_c('div',{key:"loading"},[_c('Loading',{staticClass:"text-center",attrs:{"size":64}})],1):_vm._e(),(_vm.processing)?[_c('v-alert',{staticClass:"text-left",attrs:{"type":"success"}},[_vm._v(" The submitted requirements are under review. ")])]:_vm._e(),(_vm.account && _vm.underwritingItemList && _vm.underwritingItemList.length > 0)?_c('v-card',{staticClass:"pb-8 px-8 pt-4"},[_c('ValidationObserver',{ref:"observer",attrs:{"tag":"span"},scopedSlots:_vm._u([{key:"default",fn:function({}){return [_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.validate()}}},[_c('v-row',{staticClass:"py-0"},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[(_vm.underwritingItemList)?_c('DynamicFormControl',{attrs:{"control":{
                    name: 'Requirements',
                    type: 'Requirements',
                    value: _vm.value,
                    config: {
                      short: true,
                      underwritingItemList: _vm.underwritingItemList,
                      mode: 'OPTIONAL',
                      account: _vm.account,
                      disabled: _vm.submitting
                    }
                  }},on:{"change":_vm.updateValue}}):_vm._e()],1)],1),_c('v-row',[_c('v-col',{staticClass:"text-right pt-0",attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"color":"secondary","type":"submit","large":"","disabled":_vm.submitting || !_vm.canSubmit}},[(_vm.submitting)?_c('Loading',{staticClass:"mr-2",attrs:{"size":24}}):_vm._e(),_vm._v(" Submit ")],1)],1)],1)],1)]}}],null,false,2134450839)})],1):[(_vm.showMessage)?_c('v-alert',{staticClass:"text-left",attrs:{"type":"info"}},[_vm._v(" There are no requirements for this policy at this time. ")]):_vm._e()]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }