import { Amwins_Orbits_Contracts_Models_Submissions_LossV0, Amwins_Orbits_Contracts_Models_v0_CoverageInformation_FlatDeductibleV0, Amwins_Orbits_Contracts_Models_v0_CoverageInformation_FlatOnlyDeductibleV0 } from "@/api/index.defs"

interface VavePersonalLinesDeductibleValues {
  state: string,
  peril: any,
  type: string,
  value: number,
  windHailPerilValueHolder: number,
  floodPerilValueHolder: number,
  earthquakePerilValueHolder: number,
  formType: string,
  deductibleValues: {
    windHailPerilValues: {
      flat: number,
      percent: number
    },
    floodPerilValues: {
      flat: number,
      percent: number
    },
    earthquakePerilValues: {
      flat: number,
      percent: number
    },
  },
  CovA: {
    value: number
  },
  CovC: {
    value: number
  },
  CovD: {
    value: number
  },
  CovB: {
    value: number
  },
  WindHailFlatAmount: {
    value: number
  },
  FloodFlatAmount: {
    value: number
  },
  EarthquakeFlatAmount: {
    value: number
  },
  WaterDamageFlatAmount: {
    value: number
  },
  WaterDamageSublimit: {
    value: number
  },
  LossesInPast5YearsList: {
    value: Amwins_Orbits_Contracts_Models_Submissions_LossV0[]
  },
  Liability: {
    value: number
  },
  EarthquakePeril: {
    value: number
  }
}

const updateVavePersonalLinesDeductibles = {
  'CovA': function covARule(data: VavePersonalLinesDeductibleValues) {
    if (data.value && data.value > 100000) {
      if (data.windHailPerilValueHolder === 0) data.windHailPerilValueHolder = data.deductibleValues.windHailPerilValues.percent
      if (data.floodPerilValueHolder === 0) data.floodPerilValueHolder = data.deductibleValues.floodPerilValues.percent
      if (data.earthquakePerilValueHolder === 0) data.earthquakePerilValueHolder = data.deductibleValues.earthquakePerilValues.percent
      if (data.formType.toUpperCase() === 'HO3' || data.formType.toUpperCase() === 'HO5' || data.formType.toUpperCase() === 'DP3') {
        data.deductibleValues.windHailPerilValues.flat = (data.windHailPerilValueHolder / 100) * data.value
        data.deductibleValues.floodPerilValues.flat = (data.floodPerilValueHolder / 100) * data.value
        data.deductibleValues.earthquakePerilValues.flat = (data.earthquakePerilValueHolder / 100) * data.value
      } else if (data.formType.toUpperCase() === 'HO6') {
        data.deductibleValues.windHailPerilValues.flat = (data.windHailPerilValueHolder / 100) * (data.value + data.CovC.value)
        data.deductibleValues.floodPerilValues.flat = (data.floodPerilValueHolder / 100) * (data.value + data.CovC.value)
        data.deductibleValues.earthquakePerilValues.flat = (data.earthquakePerilValueHolder / 100) * (data.value + data.CovC.value)
      }

      return {
        deductibleValues: {
          windHailPerilValues: {
            flat: data.deductibleValues.windHailPerilValues.flat,
          },
          floodPerilValues: {
            flat: data.deductibleValues.floodPerilValues.flat,
          },
          earthquakePerilValues: {
            flat: data.deductibleValues.earthquakePerilValues.flat,
          },
        },
        windHailPerilValueHolder: data.windHailPerilValueHolder,
        floodPerilValueHolder: data.floodPerilValueHolder,
        earthquakePerilValueHolder: data.earthquakePerilValueHolder,
      }
    }
  },
  'CovC': function covCRule(data: VavePersonalLinesDeductibleValues) {
    if (data.value && data.formType.toUpperCase() === 'HO6' && (data.CovA.value + data.value) > 100000) {
      if (data.windHailPerilValueHolder === 0) data.windHailPerilValueHolder = data.deductibleValues.windHailPerilValues.percent
      if (data.floodPerilValueHolder === 0) data.floodPerilValueHolder = data.deductibleValues.floodPerilValues.percent
      if (data.earthquakePerilValueHolder === 0) data.earthquakePerilValueHolder = data.deductibleValues.earthquakePerilValues.percent

      data.deductibleValues.windHailPerilValues.flat = (data.windHailPerilValueHolder / 100) * (data.CovA.value + data.value)
      data.deductibleValues.floodPerilValues.flat = (data.floodPerilValueHolder / 100) * (data.CovA.value + data.value)
      data.deductibleValues.earthquakePerilValues.flat = (data.earthquakePerilValueHolder / 100) * (data.CovA.value + data.value)

      return {
        deductibleValues: {
          windHailPerilValues: {
            flat: data.deductibleValues.windHailPerilValues.flat,
          },
          floodPerilValues: {
            flat: data.deductibleValues.floodPerilValues.flat,
          },
          earthquakePerilValues: {
            flat: data.deductibleValues.earthquakePerilValues.flat,
          },
        },
        windHailPerilValueHolder: data.windHailPerilValueHolder,
        floodPerilValueHolder: data.floodPerilValueHolder,
        earthquakePerilValueHolder: data.earthquakePerilValueHolder,
      }
    }
  }
}

const salesChannelDeductibleDictionary: { [key: string]: { [key: string]: (data: VavePersonalLinesDeductibleValues) => void } } = {
  'VavePersonalLines': updateVavePersonalLinesDeductibles
}

export function calculateDeductibleChanges(data: VavePersonalLinesDeductibleValues, salesChannel: string, controlChanged: string) {
  let selectedSalesChannelDeductibleChangedFunction = salesChannelDeductibleDictionary[salesChannel]
  let selectedFunction = selectedSalesChannelDeductibleChangedFunction[controlChanged]
  return selectedFunction(data)
}

const vavePersonalLinesDeductibleRules = {
  'EarthquakePerilIncrementer': function earthquakePerilIncrementerRule(data: VavePersonalLinesDeductibleValues) {
    let rules = 'required|min_value:0|max_value:100'

    if (data.deductibleValues.earthquakePerilValues.percent > 0) {
      rules = rules + '|min_value:5'
    }

    return rules
  },
  'WaterDamageFlatAmount': function waterDamageFlatAmountRule(data: VavePersonalLinesDeductibleValues) {
    let rules = 'required|min_value:@AllPerilDeductible|less_than:@TIV'

    if (data.WaterDamageSublimit?.value === 0) {
      rules = 'required|less_than:@TIV'
    }

    return rules
  },
}

const salesChannelDeductibleRulesDictionary: { [key: string]: { [key: string]: (data: VavePersonalLinesDeductibleValues) => string } } = {
  'VavePersonalLines': vavePersonalLinesDeductibleRules
}

export function getDeductibleRules(data: VavePersonalLinesDeductibleValues, salesChannel: string, control: string) {
  let selectedSalesChannelDeductibleRulesFunction = salesChannelDeductibleRulesDictionary[salesChannel]
  let selectedFunction = selectedSalesChannelDeductibleRulesFunction[control]
  return selectedFunction(data)
}

const salesChannelDeductibleInitializationDictionary: { [key: string]: (data: VavePersonalLinesDeductibleValues) => void } = {
  'VavePersonalLines': function vavePersonalLinesDeductibleInitialization(data: VavePersonalLinesDeductibleValues) {
    if (data.formType && (data.formType.toUpperCase() === 'HO3' || data.formType.toUpperCase() === 'HO5' || data.formType.toUpperCase() === 'DP3')) {
      data.deductibleValues.windHailPerilValues.percent = (data.WindHailFlatAmount?.value / data.CovA?.value) * 100
      data.deductibleValues.windHailPerilValues.flat = data.WindHailFlatAmount?.value
      data.deductibleValues.floodPerilValues.percent = (data.FloodFlatAmount?.value / data.CovA?.value) * 100
      data.deductibleValues.floodPerilValues.flat = data.FloodFlatAmount?.value
      data.deductibleValues.earthquakePerilValues.percent = (data.EarthquakeFlatAmount?.value / data.CovA?.value) * 100
      data.deductibleValues.earthquakePerilValues.flat = data.EarthquakeFlatAmount?.value
    } else if (data.formType && data.formType.toUpperCase() === 'HO6') {
      data.deductibleValues.windHailPerilValues.percent = (data.WindHailFlatAmount?.value / (data.CovA.value + data.CovC?.value)) * 100
      data.deductibleValues.windHailPerilValues.flat = data.WindHailFlatAmount?.value
      data.deductibleValues.floodPerilValues.percent = (data.FloodFlatAmount?.value / (data.CovA.value + data.CovC?.value)) * 100
      data.deductibleValues.floodPerilValues.flat = data.FloodFlatAmount?.value
      data.deductibleValues.earthquakePerilValues.percent = (data.EarthquakeFlatAmount?.value / (data.CovA.value + data.CovC?.value)) * 100
      data.deductibleValues.earthquakePerilValues.flat = data.EarthquakeFlatAmount?.value
    }

    return data.deductibleValues
  },
}

export function initializeDeductibles(data: VavePersonalLinesDeductibleValues, salesChannel: string) {
  let selectedFunction = salesChannelDeductibleInitializationDictionary[salesChannel]
  return selectedFunction(data)
}

const salesChannelDeductibleUpdateDictionary: { [key: string]: (data: VavePersonalLinesDeductibleValues) => void } = {
  'VavePersonalLines': function vavePersonalLinesDeductibleUpdate(data: VavePersonalLinesDeductibleValues) {
    let flatValue = 0
    let percentValue = 0

    // reset deductible holders
    data.windHailPerilValueHolder = 0;
    data.floodPerilValueHolder = 0;
    data.earthquakePerilValueHolder = 0;

    if (data.formType.toUpperCase() === 'HO3' || data.formType.toUpperCase() === 'HO5' || data.formType.toUpperCase() === 'DP3') {
      if (data.type === 'flat') {
        flatValue = data.value;
        percentValue = (data.value / data.CovA?.value) * 100
      } else {
        flatValue = (data.value / 100) * data.CovA?.value;
        percentValue = data.value
      }
    } else if (data.formType.toUpperCase() === 'HO6') {
      if (data.type === 'flat') {
        flatValue = data.value;
        percentValue = (data.value / (data.CovA?.value + data.CovC?.value)) * 100
      } else {
        flatValue = (data.value / 100) * (data.CovA?.value + data.CovC?.value);
        percentValue = data.value
      }
    }

    switch (data.peril) {
      case 'windHail':
        data.deductibleValues.windHailPerilValues.flat = flatValue;
        data.deductibleValues.windHailPerilValues.percent = percentValue;
        break;
      case 'flood':
        data.deductibleValues.floodPerilValues.flat = flatValue;
        data.deductibleValues.floodPerilValues.percent = percentValue;
        break;
      case 'earthquake':
        data.deductibleValues.earthquakePerilValues.flat = flatValue;
        data.deductibleValues.earthquakePerilValues.percent = percentValue;
        break;
      default:
        break;
    }
  },
}

export function updateDeductibleValues(data: VavePersonalLinesDeductibleValues, salesChannel: string) {
  let selectedFunction = salesChannelDeductibleUpdateDictionary[salesChannel]
  return selectedFunction(data)
}

const vavePersonalLinesControlChanged:{ [key: string]: (data: VavePersonalLinesDeductibleValues) => any } = {
  'AllPerilDeductible': function vavePersonalLinesValueChanged(data: VavePersonalLinesDeductibleValues) {
    if (data.value) {
      const waterDamageAmount = data.WaterDamageFlatAmount?.value;
      return Math.max(waterDamageAmount, data.value)
    }
  },
  'Liability': function vavePersonalLinesValueChanged(data: VavePersonalLinesDeductibleValues) {
    let clearMedPayAndPersonalInjury = false

    if (!data.value || data.value == 0) {
      clearMedPayAndPersonalInjury = true
    }

    return { clearMedPayAndPersonalInjury }
  },
}

const salesChannelValueChangedDictionary: { [key: string]: { [key: string]: (data: VavePersonalLinesDeductibleValues) => string } } = {
  'VavePersonalLines': vavePersonalLinesControlChanged
}

export function valueChanged(data: VavePersonalLinesDeductibleValues, salesChannel: string, control: string) {
  const selectedSalesChannelControlChangedFunction = salesChannelValueChangedDictionary[salesChannel]
  const selectedFunction = selectedSalesChannelControlChangedFunction[control]
  return selectedFunction(data)
}

const vavePersonalLinesComputedValue = {
  'WaterDamageSublimit': function vavePersonalLinesComputed(data: VavePersonalLinesDeductibleValues) {
    if (data.state.toUpperCase() === 'FL' || data.state.toUpperCase() === 'TX') {
      return 10000
    } else {
      return data.CovA.value + data.CovB.value + data.CovC.value + data.CovD.value;
    }
  },
  'TIV': function vavePersonalLinesComputed(data: VavePersonalLinesDeductibleValues) {
    const covA = data.CovA?.value ?? 0
    const covB = data.CovB?.value ?? 0
    const covC = data.CovC?.value ?? 0
    const covD = data.CovD?.value ?? 0

    return covA + covB + covC + covD;
  },
  'Theft': function vavePersonalLinesComputed(data: VavePersonalLinesDeductibleValues) {
    if (!data.LossesInPast5YearsList?.value || data.LossesInPast5YearsList.value.length === 0) return 'Included'

    return data.LossesInPast5YearsList?.value?.filter(x => x.Type === 'Theft' && x.PaymentAmount > 0)?.length > 0 ? 'Excluded' : 'Included'
  },
  'ShowLossAssessmentEQ': function vavePersonalLinesComputed(data: VavePersonalLinesDeductibleValues) {
    return !(data.EarthquakePeril?.value == null || data.EarthquakeFlatAmount?.value == 0)
  },
  'LiabilityCoveragesEnabled': function vavePersonalLinesComputed(data: VavePersonalLinesDeductibleValues) {
    if (!data.Liability.value || data.Liability.value == 0) return false

    return true
  },
  'WaterDamageFlatAmountEnabled': function vavePersonalLinesComputed(data: VavePersonalLinesDeductibleValues) {
    if (!data.WaterDamageSublimit?.value || data.WaterDamageSublimit?.value == 0) return false

    return true
  },
  'CovALabel': function vavePersonalLinesComputed(data: VavePersonalLinesDeductibleValues) {
    if (data.formType.toUpperCase() === 'HO3' || data.formType.toUpperCase() === 'DP3') {
      return 'Home'
    } else if (data.formType.toUpperCase() === 'HO6') {
      return 'Additions & Alterations'
    } else {
      return 'Home'
    }
  },
}

const salesChannelComputedValueDictionary: { [key: string]: { [key: string]: (data: VavePersonalLinesDeductibleValues) => any } } = {
  'VavePersonalLines': vavePersonalLinesComputedValue
}

export function getComputedValue(data: VavePersonalLinesDeductibleValues, salesChannel: string, control: string) {
  const selectedSalesChannelComputedFunction = salesChannelComputedValueDictionary[salesChannel]
  const selectedFunction = selectedSalesChannelComputedFunction[control]
  return selectedFunction(data)
}