import store from '@/vuex/store';

export const setValues = (module: string, values: any): void => {
  Object.keys(values).forEach((name: string) => {
    store.commit('updateFormControl', {module, name, value: values[name]});
  })
}

export const prefillQuoteForm = (): void => {
  
  const values: any = {
    "Risk": {
      "RiskKey": "4D36C831E5764AE69AC6523CF66912FD",
      "StreetAddress": null,
      "StreetNumber": "29",
      "StreetName": "Pelican St",
      "UnitType": null,
      "UnitNumber": null,
      "City": "Hilton Head Island",
      "State": "SC",
      "PostalCode": "29928",
      "County": "Beaufort",
      "CountryCode": "US",
      "Formatted": "29 Pelican St, Hilton Head Island, SC 29928 US",
      "Locked": true
    },
    "MailingAddressSame": true,
    "Insured": {
      "PrimaryInsured": {
        "FirstName": "Bill",
        "LastName": "Smith",
        "DateOfBirth": "1/1/1971",
        "EmailAddress": "billsmith@gmail.com"
      }
    },
    "FirstName": "Bill",
    "LastName": "Smith",
    "DateOfBirth": "1/1/1971",
    "Email": "billsmith@gmail.com",
    "Occupation": "Actor",
    "CustomerType": "individual",
    "CoApplicant": false,
    "ConsumerCreditDisclosure": {
      "Text": "The Insured has confirmed compliance with disclosure requirements.",
      "UserId": "bobby.jones@agency.com",
      "UserEmail": "bobby.jones@agency.com",
      "UserName": "Bobby Jones",
      "DateTime": "2021-11-17T20:44:10.677Z"
    },
    "Occupancy": "Primary",
    "OccasionallyRented": false,
    "RentalOccupedBy": "Annual",
    "DwellingForSale": false,
    "NumberOfFamilies": 1,
    "DwellingUnderRenovation": false,
    "BuildingType": "Residential - Detached - Single Family",
    "FoundationType": "Slab on Grade",
    "ConstructionType": "Frame",
    "CladdingType": "Siding-Vinyl",
    "DwellingStories": "1",
    "SquareFeet": 2000,
    "YearBuilt": 2000,
    "FrameFoundationConnection": "Bolted",
    "ArchitecturalElements": "None",
    "RoofMaterial2": "Slate",
    "RoofUpdated": false,
    "RoofShape": "Gable",
    "RoofModifiers": false,
    "ProtectionClass": "3",
    "Protections": {
      "GatedGuardedCommunity": false,
      "MonitoredCameras": false,
      "BurglarAlarm": false,
      "FireAlarm": true,
      "InteriorFireSprinklers": false,
      "AutomaticWaterShutOffSystem": false,
      "RoofUpToLatestCode": false,
      "HurricaneProtection": false
    },
    "FireAlarmType": "Central Station",
    "GlazedOpeningProtection": "Other / Unknown",
    "NonGlazedOpeningProtection": "Other / Unknown",
    "LossesInPast5Years": false,
    "AnyOtherExposures": false,

    "CovA": 200000,
    "CovB": 20000,
    "CovC": 80000,
    "CovD": 30000,

    "CovE": 100000,
    "CovF": 0,
    "AllPerilDeductible": "5000",
    "WindPeril": false,
    "RecommendedCoverageOption": "costConscious",
  }

  setValues('quoteForm', values);

}

export const prefillDigitalForm = (): void => {
  const values: any = {
    "DiligentEffortList": [
      {
         "Company": {
             "InsuranceCarrierCode": "HARC",
             "InsuranceCarrierName": "Harco National Insurance Company",
             "InsuranceEntityTypeCode": "C",
             "InsuranceRegulatoryTypeCode": "A",
             "EffectiveDate": "2000-01-01T00:00:00",
             "ExpirationDate": "2079-06-06T00:00:00",
             "OrderGroupNr": 1
          },
         "ContactedDate": "11/28/2021",
         "ContactName": "Bob Smith",
         "ContactPhone": "888-222-4444",
         "ContactEmail": null,
         "ContactWebsite": null,
         "DeclineReason": "Distance to Water"
      }
   ],
   "Mortgagee1": true,
   "Mortgagee1Company": {
      "MortgageeID": null,
      "MortgageeName": "Made Up Bank",
      "MortgageeSuffixCode": "I",
      "MortgageeAddress1": "300 Galleria Parkway Ste 800",
      "MortgageeCity": "Atlanta",
      "MortgageeState": "GA",
      "MortgageeZip": "30339",
      "MortgageeCountry": "USA",
      "MortgageeEffectiveDate": "1900-01-01T00:00:00",
      "MortgageeExpirationDate": "2079-06-06T00:00:00",
      "MortgageeOrgID": null,
      "MortgageeOrgBranchID": null
    },
    "Mortgagee1Suffix": "NA",
    "Mortgagee1LoanNumber": "12345",
    "Mortgagee1Invoice": true,
    "Mortgagee2": true,
    "Mortgagee2Company": {
      "MortgageeID": 53,
      "MortgageeName": "Agfirst Farm Credit Bank",
      "MortgageeSuffixCode": "IA",
      "MortgageeAddress1": "PO Box 100285",
      "MortgageeCity": "Columbia",
      "MortgageeState": "SC",
      "MortgageeZip": "29202",
      "MortgageeCountry": "USA",
      "MortgageeEffectiveDate": "1900-01-01T00:00:00",
      "MortgageeExpirationDate": "2079-06-06T00:00:00"
    },
    "Mortgagee2Suffix": "ATIMA",
    "Mortgagee2LoanNumber": "67890",
    "AdditionalInterests": true,
    "AdditionalInterestsList": [
        {
          "Name":"Bill Smith",
          "Type":"Landlord",
          "Address":{
              "StreetAddress":"100 Main St",
              "City":"Hilton Head Island",
              "State":"SC",
              "PostalCode":"29926",
              "CountryCode":"US",
              "Locked":true,
              "Formatted":"100 Main St, Hilton Head Island, SC 29926 US"
          }
        }
    ],
    "RequiredDocuments": {
        "WY": {
          "Type": "FileUpload",
          "Text": "Sample Text",
          "Files": [
            {
              "DisplayName":"file2.pdf",
              "FileName":"58a0fff8-3b9d-433e-b0ef-dc8815e949b2.pdf",
              "FileSize": 1000
            }
          ]
        },
        "HS": {
          "Type": "FileUpload",
          "Text": "Sample Text",
          "Files": [
            {
              "DisplayName":"file1.pdf",
              "FileName":"58a0fff8-3b9d-433e-b0ef-dc8815e949b2.pdf",
              "FileSize": 1000
            }
          ]
        },
        "Photos": {
          "Type": "FileUpload",
          "Text": "Sample Text",
          "Files": [
            {
              "DisplayName":"house.jpg",
              "FileName":"ef0a7e0e-f8c0-4d95-9925-532fdfcebc9a.jpg",
              "FileSize": 1000
            },
            {
                "DisplayName":"flamingo.jpg",
                "FileName":"aa81f7be-0132-4bdf-9d19-bf6d1e00660b.jpg",
                "FileSize": 1000
            },
            {
                "DisplayName":"crab.jpg",
                "FileName":"6a584f26-be0c-48b9-8cea-e56a398ab446.jpg",
                "FileSize": 1000
            }
          ]
        }
    },
    "ElectronicSignature": {
      "UserId": "bobby.jones@agency.com",
      "UserEmail": "bobby.jones@agency.com",
      "UserName": "Bobby Jones",
      "DateTime": "2021-11-17T20:44:10.677Z"
    },
  }

  setValues('additionalForm', values);

}

export const prefillHumanForm = (): void => { 
  const values: any = {
    "UnitOwnersCoverageASpecialCoverage": true,
    "PersonalSpecialPerils": true,
    "ReplacementCostOnPersonalProperty": true,
    "PersonalInjury": true,
    "LimitedMoldCoverageProperty": true,
    "LimitedMoldCoverageLiability": true,
    "WaterBackUp": true,
    "OrdinanceAndLaw": true,
    "ExtendedReplacementCost": true,
    "TheftOfBuildingMaterials": true,
    "IdentityFraud": true,
    "MechanicalBreakdown": true,
    "ServiceLine": true,
    "LossAssessment": true,
    "LimitedPollutionLiability": true,
    "LimitedPollutionProperty": true,
    "GolfCartPhysicalCost": true,
    "IncreasedLimitOnBusinessProperty": true,
    "ReplacementCostForCertainNonBuildingStructures": true,
    "IncreasedSpecialLimitsOfLiability": true,
    "EnhancedAdditionalCoverages": true,
    "InflationGuard": true,
    "IncidentalLowPowerRecreationalMotorVehicleLiability": true,
    "EarthquakeSprinklerLeakage": true,
    "LargeLossDeductibleWaiver": true,
    "MandatoryEvacuation": true,
    "TransitAndOffsite": true,
    "WorkersCompensation": true,
    "AgreedValue": true,
    "RCVCashOutOption": true,
    "GuaranteedRebuildingCostCoverageAB": true,
    "LockReplacementCost": true,
    "LossPreventionDevicesCoverage": true,
    "UpgradeToGreenCoverage": true,
    "CalendarYearWindstormDeductible": true,

   "Mortgagee1": true,
   "Mortgagee1Company": {
        "MortgageeID": 7664,
        "MortgageeName": "Bank of America",
        "MortgageeSuffixCode": "I",
        "MortgageeAddress1": "300 Galleria Parkway Ste 800",
        "MortgageeCity": "Atlanta",
        "MortgageeState": "GA",
        "MortgageeZip": "30339",
        "MortgageeCountry": "USA",
        "MortgageeEffectiveDate": "1900-01-01T00:00:00",
        "MortgageeExpirationDate": "2079-06-06T00:00:00"
    },
    "Mortgagee1Suffix": "",
    "Mortgagee1LoanNumber": "12345",
    "Mortgagee1Invoice": true,
    "Mortgagee2": true,
    "Mortgagee2Company": {
          "MortgageeID": 53,
          "MortgageeName": "Agfirst Farm Credit Bank",
          "MortgageeSuffixCode": "IA",
          "MortgageeAddress1": "PO Box 100285",
          "MortgageeCity": "Columbia",
          "MortgageeState": "SC",
          "MortgageeZip": "29202",
          "MortgageeCountry": "USA",
          "MortgageeEffectiveDate": "1900-01-01T00:00:00",
          "MortgageeExpirationDate": "2079-06-06T00:00:00"
    },
    "Mortgagee2Suffix": "ATIMA",
    "Mortgagee2LoanNumber": "67890",
    "AdditionalInterests": true,
    "AdditionalInterestsList": [
      {
         "Name":"Bill Smith",
         "Type":"Landlord",
         "Address":{
            "StreetAddress":"100 Main St",
            "City":"Hilton Head Island",
            "State":"SC",
            "PostalCode":"29926",
            "CountryCode":"US",
            "Locked":true,
            "Formatted":"100 Main St, Hilton Head Island, SC 29926 US"
         }
      }
   ],
  //  "Underwriter": {
  //    "Id":"288a4b30-163c-4f0b-b6ad-7968a3822c7a",
  //    "AmlinkOrgBranchId":null,
  //    "AmlinkHumanId":null,
  //    "Name":"Jack Martin",
  //    "Title":"Underwriter",
  //    "Street1":"407 Vale Driver",
  //    "Street2":null,
  //    "City":"Pittsburgh",
  //    "State":"PA",
  //    "Postal":"15239",
  //    "County":null,
  //    "Country":"US",
  //    "Phone":"4126557516",
  //    "Email":"jmartin@gmail.com",
  //    "ImageUrl":"https://images.generated.photos/qMXAlnZyEVGvI9_B0gI6kAmtFU9GgfMRunJwMaSu_uI/rs:fit:512:512/wm:0.95:sowe:18:18:0.33/czM6Ly9pY29uczgu/Z3Bob3Rvcy1wcm9k/LnBob3Rvcy92M18w/MjQ4NjIzLmpwZw.jpg"
  //   },
  }

  setValues('additionalForm', values);
}


export const prefillFloodForm = (): void => {
  
  const values: any = {
    "Risk": {
      "RiskKey": "4D36C831E5764AE69AC6523CF66912FD",
      "StreetAddress": null,
      "StreetNumber": "29",
      "StreetName": "Pelican St",
      "UnitType": null,
      "UnitNumber": null,
      "City": "Hilton Head Island",
      "State": "SC",
      "PostalCode": "29928",
      "County": "Beaufort",
      "CountryCode": "US",
      "Formatted": "29 Pelican St, Hilton Head Island, SC 29928 US",
      "Locked": true
    },
    "MailingAddressSame": true,
    "FirstName": "Bill",
    "LastName": "Smith",
    "DateOfBirth": "1/1/1971",
    "Email": "billsmith@gmail.com",
    "Occupation": "Actor",
    "CustomerType": "individual",
    "CoApplicant": false,
    "ConsumerCreditDisclosure": {
      "Text": "The Insured has confirmed compliance with disclosure requirements.",
      "UserId": "bobby.jones@agency.com",
      "UserEmail": "bobby.jones@agency.com",
      "UserName": "Bobby Jones",
      "DateTime": "2021-11-17T20:44:10.677Z"
    },
    "Occupancy": "Primary",
    "OccasionallyRented": false,
    "RentalOccupedBy": "Annual",
    "DwellingForSale": false,
    "NumberOfFamilies": 1,
    "DwellingUnderRenovation": false,
    "BuildingType": "ResidentialSFD",
    "FoundationType": "Slab On Grade",
    "ConstructionType": "Frame",
    "CladdingType": "Siding-Vinyl",
    "NumStories": "1",
    "SquareFeet": 2000,
    "YearBuilt": 2000,
    "FrameFoundationConnection": "Bolted",
    "ArchitecturalElements": "None",
    "RoofMaterial2": "Slate",
    "RoofUpdated": false,
    "RoofShape": "Gable",
    "RoofModifiers": false,
    "ProtectionClass": "3",
    "Protections": {
      "GatedGuardedCommunity": false,
      "MonitoredCameras": false,
      "BurglarAlarm": false,
      "FireAlarm": true,
      "InteriorFireSprinklers": false,
      "AutomaticWaterShutOffSystem": false,
      "RoofUpToLatestCode": false,
      "HurricaneProtection": false
    },
    "FireAlarmType": "Central Station",
    "GlazedOpeningProtection": "Other / Unknown",
    "NonGlazedOpeningProtection": "Other / Unknown",
    "LossesInPast5Years": false,
    "AnyOtherExposures": false,

    "BuildingValuationAmount": 200000,
    "ContentsValuationAmount": 200000,
    "CovA": 200000,
    "CovB": 20000,
    "CovC": 80000,
    "CovD": 30000,

    "CovE": 100000,
    "CovF": 0,
    "FloodPerilDeductible": "5000",
    "WindPeril": false,
    "RecommendedCoverageOption": "costConscious",

  }

  setValues('quoteForm', values);

}
