import { createStateForm } from '@/scripts/helper'
import { Module } from 'vuex';
import { AdditionalFormState, RootState } from './types';
import Vue from 'vue';

export const state: AdditionalFormState = {
    form: null,
    currentStepNumber: 0,
    submission: null,
    selectedQuote: null
}

const mutations = {
    clearAdditionalForm(state: any) {
        Vue.set(state, 'form', null);
        state.currentStepNumber = 0;
        state.submission = null;
        state.selectedQuote = null;
    },
    initAdditionalForm(state: any, form: any) {
        state.form = createStateForm({...form});
        state.currentStepNumber = 0;
    },
    setSubmission(state: any, submission: any) {
        state.submission = submission || {};
    },
    setSelectedQuote(state: any, quote: any) {
        state.selectedQuote = quote;
    }
}

const getters = {
    getSubmission(state: any) {
        if (state.submission) return state.submission;
        else return {};
    },
    getSubmissionId(state: any) {
        if (state.submission) return state.submission.id;
        else return null;
    },
    getSelectedQuote(state: any) {
        if (state.selectedQuote) return state.selectedQuote;
    }
}

export const actions = {

}

const namespaced: boolean = true;

export const additionalForm: Module<AdditionalFormState, RootState> = {
    namespaced,
    state,
    getters,
    actions,
    mutations
}