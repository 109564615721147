<template>
    <span :style="{'color': color, 'font-size': fontSize ? fontSize : null}" class="tag">
        <i :class="icon"></i>&nbsp;<span v-html="text" class="text"></span>
    </span>
</template>

<script>
export default {
    name: 'Tag',
    props: {
        color: String,
        text: String,
        icon: String,
        fontSize: String
    },
    data() {
        return {}
    }
}
</script>

<style scoped lang="scss">
    .tag {
        // font-size: 12px;
        white-space: nowrap;

        .text {
            white-space: normal;
        }
    }
</style>
