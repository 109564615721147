import {
  Agency,
  IList,
  List,
  IListResult,
  ListResultDto,
  IPagedResult,
  PagedResultDto,
  Dictionary,
  IDictionary,
  IRequestOptions,
  IRequestConfig,
  getConfigs,
  axios,
  basePath
} from './index.defs';

export class AgencyService {
  /** Generate by swagger-axios-codegen */
  // @ts-nocheck
  /* eslint-disable */

  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Agency> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Agency';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static seek(
    params: {
      /**  */
      q?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Agency[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Agency/Seek';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { q: params['q'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static nonProdAccess(
    params: {
      /**  */
      id?: string;
      /**  */
      hasNonProdAccess?: boolean;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Agency/NonProdAccess';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { id: params['id'], hasNonProdAccess: params['hasNonProdAccess'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static myAgencies(options: IRequestOptions = {}): Promise<Agency[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Agency/MyAgencies';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}
