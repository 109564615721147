<template>
    <v-btn :color="color || 'secondary'" 
    :disabled="disabled"
    :type="type || 'button'" 
    :x-small="buttonSize == 'x-small'"
    :small="buttonSize == 'small'"
    :large="buttonSize == 'large'"
    :x-large="buttonSize == 'x-large'"
    :download="download"
    :href="href"
    :text="text"
    @click="emitClick"
    >
    <template v-if="icon"><i :class="icon"></i>&nbsp;</template>
    {{label || 'Submit'}}
    </v-btn>
</template>

<script>
export default {
    name: 'FormButton',
    props: {
        label: String,
        color: String,
        type: String,
        size: String,
        icon: String,
        text: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        download: {
            type: Boolean,
            default: false
        },
        href: String
    },
    emits: [
        'click'
    ],
    data() {
        return {}
    },
    computed: {
        buttonSize() {
            return this.size ? this.size : 'large';
        }
    },
    methods: {
        emitClick() {
            this.$emit('click');
        }
    }
}
</script>

<style lang="scss" scoped>
     .v-btn {
         text-transform: none;
         letter-spacing: inherit!important;
     }
</style>
