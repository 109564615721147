var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card-text',{staticClass:"pt-4"},[(!_vm.loaded)?_c('div',{key:"loading"},[_c('Loading',{staticClass:"text-center",attrs:{"size":64}})],1):_c('div',{key:"form"},[_c('ValidationObserver',{ref:"observer",attrs:{"tag":"span"},scopedSlots:_vm._u([{key:"default",fn:function({}){return [(_vm.open)?_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.refineValidate()}}},[(_vm.validationMessages)?_c('v-alert',{staticClass:"validationMessages",attrs:{"type":"error","dense":"","icon":false}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.validationMessages)}})]):_vm._e(),(_vm.showRatePlanEffectiveDate)?_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"6"}},[_c('DynamicFormControl',{attrs:{"control":{
                      name: 'Ext_Algo_RatePlanEffectiveDate',
                      type: 'DateInput',
                      value: _vm.newQuote.Ext_Algo_RatePlanEffectiveDate,
                      config: {
                          label: 'Rate Plan Effective Date',
                          rules: 'required|validDate',
                          col: 12,
                          short: true,
                          disabled: _vm.calcing,
                          clearable: false,
                          default: '$TodaysDate'
                      }
                  }},on:{"change":_vm.updateValue}})],1)],1):_vm._e(),(_vm.getOptions('CovLossAssessment_NonHO6').length > 0)?_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('h4',{staticClass:"mb-2 mt-2"},[_vm._v("Property Coverages")])])],1):_vm._e(),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"md":"6","cols":"12"}},[_c('DynamicFormControl',{attrs:{"control":{
                      name: 'CovI',
                      type: 'TextInput',
                      value: _vm.newQuote.CovI,
                      config: {
                          label: _vm.getLabel('CovI'),
                          rules: _vm.CovIRules,
                          col: 12,
                          short: true,
                          currency: true,
                          prefix: '$',
                          disabled: _vm.calcing
                      }
                  }},on:{"change":_vm.updateValue}})],1),_c('v-col',{staticClass:"py-0",attrs:{"md":"6","cols":"12"}},[_c('DynamicFormControl',{attrs:{"control":{
                      name: 'CovII',
                      type: 'Dropdown',
                      value: _vm.newQuote.CovII,
                      config: {
                          label: _vm.getLabel('CovII'),
                          rules: 'required',
                          col: 12,
                          short: true,
                          clearable: false,
                          options: _vm.getOptions('CovII'),
                          disabled: _vm.calcing || _vm.hasSingleOption('CovII')
                      }
                  }},on:{"change":_vm.updateValue}})],1),_c('v-col',{staticClass:"py-0",attrs:{"md":"6","cols":"12"}},[_c('DynamicFormControl',{attrs:{"control":{
                      name: 'CovIII_LU',
                      type: 'Dropdown',
                      value: _vm.newQuote.CovIII_LU,
                      config: {
                          label: _vm.getLabel('CovIII_LU'),
                          rules: 'required',
                          col: 12,
                          short: true,
                          clearable: false,
                          options: _vm.getOptions('CovIII_LU'),
                          disabled: _vm.calcing || _vm.hasSingleOption('CovIII_LU')
                      }
                  }},on:{"change":_vm.updateValue}})],1),_c('v-col',{staticClass:"py-0",attrs:{"md":"6","cols":"12"}},[_c('DynamicFormControl',{attrs:{"control":{
                      name: 'CovIV',
                      type: 'Dropdown',
                      value: _vm.newQuote.CovIV,
                      config: {
                          label: _vm.getLabel('CovIV'),
                          rules: 'required',
                          col: 12,
                          short: true,
                          clearable: false,
                          options: _vm.getOptions('CovIV'),
                          disabled: _vm.calcing || _vm.hasSingleOption('CovIV')
                      }
                  }},on:{"change":_vm.updateValue}})],1),(_vm.getOptions('CovLossAssessment_NonHO6').length > 0)?_c('v-col',{staticClass:"py-0",attrs:{"md":"6","cols":"12"}},[_c('DynamicFormControl',{attrs:{"control":{
                      name: 'CovLossAssessment_NonHO6',
                      type: 'Dropdown',
                      value: _vm.newQuote.CovLossAssessment_NonHO6,
                      config: {
                          label: 'Loss Assessment',
                          rules: 'required',
                          col: 12,
                          short: true,
                          clearable: false,
                          options: _vm.getOptions('CovLossAssessment_NonHO6'),
                          disabled: _vm.calcing || _vm.hasSingleOption('CovLossAssessment_NonHO6')
                      }
                  }},on:{"change":_vm.updateValue}})],1):_vm._e()],1),_c('hr'),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('h4',{staticClass:"mb-2 mt-2"},[_vm._v("Liability Coverages")])])],1),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"md":"6","cols":"12"}},[_c('DynamicFormControl',{attrs:{"control":{
                      name: 'CovEPersonalLiability',
                      type: 'Dropdown',
                      value: _vm.newQuote.CovEPersonalLiability,
                      config: {
                          label: 'Personal Liability',
                          rules: 'required',
                          col: 12,
                          short: true,
                          clearable: false,
                          options: _vm.getOptions('CovEPersonalLiability'),
                          disabled: _vm.calcing || _vm.hasSingleOption('CovEPersonalLiability')
                      }
                  }},on:{"change":_vm.updateValue}})],1),_c('v-col',{staticClass:"py-0",attrs:{"md":"6","cols":"12"}},[_c('DynamicFormControl',{attrs:{"control":{
                      name: 'CovFMedPay',
                      type: 'Dropdown',
                      value: _vm.newQuote.CovFMedPay,
                      config: {
                          label: 'Medical Payments',
                          rules: 'required',
                          col: 12,
                          short: true,
                          clearable: false,
                          options: _vm.getOptions('CovFMedPay'),
                          disabled: _vm.calcing || _vm.hasSingleOption('CovFMedPay')
                      }
                  }},on:{"change":_vm.updateValue}})],1),_c('v-col',{staticClass:"py-0",attrs:{"md":"6","cols":"12"}},[_c('DynamicFormControl',{attrs:{"control":{
                      name: 'CovPersonalInjury',
                      type: 'Dropdown',
                      value: _vm.newQuote.CovPersonalInjury,
                      config: {
                          label: 'Personal Injury',
                          rules: 'required',
                          col: 12,
                          short: true,
                          clearable: false,
                          options: _vm.getOptions('CovPersonalInjury'),
                          disabled: _vm.calcing || _vm.hasSingleOption('CovPersonalInjury')
                      }
                  }},on:{"change":_vm.updateValue}})],1)],1),_c('hr'),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('h4',{staticClass:"mb-2 mt-2"},[_vm._v("Add-On/Optional Coverages")])])],1),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"md":"6","cols":"12"}},[_c('DynamicFormControl',{attrs:{"control":{
                      name: 'CovReplacementCostStructures',
                      type: 'Dropdown',
                      value: _vm.newQuote.CovReplacementCostStructures,
                      config: {
                          label: 'Replacement Cost (Dwelling)',
                          rules: 'required',
                          col: 12,
                          short: true,
                          clearable: false,
                          options: _vm.getOptions('CovReplacementCostStructures'),
                          disabled: _vm.calcing|| _vm.hasSingleOption('CovReplacementCostStructures')
                      }
                  }},on:{"change":_vm.updateValue}})],1),_c('v-col',{staticClass:"py-0",attrs:{"md":"6","cols":"12"}},[_c('DynamicFormControl',{attrs:{"control":{
                      name: 'CovReplacementCostContents',
                      type: 'Dropdown',
                      value: _vm.newQuote.CovReplacementCostContents,
                      config: {
                          label: 'Replacement Cost (Contents)',
                          rules: 'required',
                          col: 12,
                          short: true,
                          clearable: false,
                          options: _vm.getOptions('CovReplacementCostContents'),
                          disabled: _vm.calcing || _vm.hasSingleOption('CovReplacementCostContents')
                      }
                  }},on:{"change":_vm.updateValue}})],1),_c('v-col',{staticClass:"py-0",attrs:{"md":"6","cols":"12"}},[_c('DynamicFormControl',{attrs:{"control":{
                      name: 'CovInflationGuard',
                      type: 'Dropdown',
                      value: _vm.newQuote.CovInflationGuard,
                      config: {
                          label: 'Inflation Guard',
                          rules: 'required',
                          col: 12,
                          short: true,
                          clearable: false,
                          options: _vm.getOptions('CovInflationGuard'),
                          disabled: _vm.calcing || _vm.hasSingleOption('CovInflationGuard')
                      }
                  }},on:{"change":_vm.updateValue}})],1),_c('v-col',{staticClass:"py-0",attrs:{"md":"6","cols":"12"}},[_c('DynamicFormControl',{attrs:{"control":{
                      name: 'CovOrdinanceLaw',
                      type: 'Dropdown',
                      value: _vm.newQuote.CovOrdinanceLaw,
                      config: {
                          label: 'Building Ordinance Or Law',
                          rules: 'required',
                          col: 12,
                          short: true,
                          clearable: false,
                          options: _vm.getOptions('CovOrdinanceLaw'),
                          disabled: _vm.calcing || _vm.hasSingleOption('CovOrdinanceLaw')
                      }
                  }},on:{"change":_vm.updateValue}})],1),_c('v-col',{staticClass:"py-0",attrs:{"md":"6","cols":"12"}},[_c('DynamicFormControl',{attrs:{"control":{
                      name: 'CovWDR',
                      type: 'Dropdown',
                      value: _vm.newQuote.CovWDR,
                      config: {
                          label: 'Wind Driven Rain',
                          rules: 'required',
                          col: 12,
                          short: true,
                          clearable: false,
                          options: _vm.getOptions('CovWDR'),
                          disabled: _vm.calcing || _vm.hasSingleOption('CovWDR')
                      }
                  }},on:{"change":_vm.updateValue}})],1),_c('v-col',{staticClass:"py-0",attrs:{"md":"6","cols":"12"}},[_c('DynamicFormControl',{attrs:{"control":{
                      name: 'CovTheftOnPremises',
                      type: 'Dropdown',
                      value: _vm.newQuote.CovTheftOnPremises,
                      config: {
                          label: 'Theft',
                          rules: 'required',
                          col: 12,
                          short: true,
                          clearable: false,
                          options: _vm.getOptions('CovTheftOnPremises'),
                          disabled: _vm.calcing || _vm.hasSingleOption('CovTheftOnPremises')
                      }
                  }},on:{"change":_vm.updateValue}})],1),_c('v-col',{staticClass:"py-0",attrs:{"md":"6","cols":"12"}},[_c('DynamicFormControl',{attrs:{"control":{
                      name: 'CovAnimalLiability',
                      type: 'Dropdown',
                      value: _vm.newQuote.CovAnimalLiability,
                      config: {
                          label: 'Domestic Animal Liability',
                          rules: 'required',
                          col: 12,
                          short: true,
                          clearable: false,
                          options: _vm.getOptions('CovAnimalLiability'),
                          disabled: _vm.calcing || _vm.hasSingleOption('CovAnimalLiability')
                      }
                  }},on:{"change":_vm.updateValue}})],1),_c('v-col',{staticClass:"py-0",attrs:{"md":"6","cols":"12"}},[_c('DynamicFormControl',{attrs:{"control":{
                      name: 'CovWaterBackUp',
                      type: 'Dropdown',
                      value: _vm.newQuote.CovWaterBackUp,
                      config: {
                          label: 'Water Backup',
                          rules: 'required',
                          col: 12,
                          short: true,
                          clearable: false,
                          options: _vm.getOptions('CovWaterBackUp'),
                          disabled: _vm.calcing || _vm.hasSingleOption('CovWaterBackUp')
                      }
                  }},on:{"change":_vm.updateValue}})],1)],1),_c('hr'),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('h4',{staticClass:"mb-2 mt-2"},[_vm._v("Deductibles")])])],1),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"md":"6","cols":"12"}},[_c('DynamicFormControl',{attrs:{"control":{
                      name: 'DedAOP',
                      type: 'Dropdown',
                      value: _vm.newQuote.DedAOP,
                      config: {
                          label: 'All Perils',
                          rules: 'required',
                          col: 12,
                          short: true,
                          clearable: false,
                          options: _vm.getOptions('DedAOP'),
                          disabled: _vm.calcing || _vm.hasSingleOption('DedAOP')
                      }
                  }},on:{"change":_vm.updateValue}})],1),_c('v-col',{staticClass:"py-0",attrs:{"md":"6","cols":"12"}},[_c('DynamicFormControl',{attrs:{"control":{
                      name: 'DedWind',
                      type: 'Dropdown',
                      value: _vm.newQuote.DedWind,
                      config: {
                          label: 'Wind/Hail',
                          rules: 'required',
                          col: 12,
                          short: true,
                          clearable: false,
                          options: _vm.getOptions('DedWind'),
                          disabled: _vm.calcing || _vm.hasSingleOption('DedWind')
                      }
                  }},on:{"change":_vm.updateValue}})],1),_c('v-col',{staticClass:"py-0",attrs:{"md":"6","cols":"12"}},[_c('DynamicFormControl',{attrs:{"control":{
                      name: 'DedEQ',
                      type: 'Dropdown',
                      value: _vm.newQuote.DedEQ,
                      config: {
                          label: 'Earthquake',
                          rules: 'required',
                          col: 12,
                          short: true,
                          clearable: false,
                          options: _vm.getOptions('DedEQ'),
                          disabled: _vm.calcing || _vm.hasSingleOption('DedEQ')
                      }
                  }},on:{"change":_vm.updateValue}})],1),_c('v-col',{staticClass:"py-0",attrs:{"md":"6","cols":"12"}},[_c('DynamicFormControl',{attrs:{"control":{
                      name: 'DedFlood',
                      type: 'Dropdown',
                      value: _vm.newQuote.DedFlood,
                      config: {
                          label: 'Flood',
                          rules: 'required',
                          col: 12,
                          short: true,
                          clearable: false,
                          options: _vm.getOptions('DedFlood'),
                          disabled: _vm.calcing || _vm.hasSingleOption('DedFlood')
                      }
                  }},on:{"change":_vm.updateValue}})],1),_c('v-col',{staticClass:"py-0",attrs:{"md":"6","cols":"12"}},[_c('DynamicFormControl',{attrs:{"control":{
                      name: 'DedWaterDamage',
                      type: 'Dropdown',
                      value: _vm.newQuote.DedWaterDamage,
                      config: {
                          label: 'Water Damage',
                          rules: 'required',
                          col: 12,
                          short: true,
                          clearable: false,
                          options: _vm.getOptions('DedWaterDamage'),
                          disabled: _vm.calcing || _vm.hasSingleOption('DedWaterDamage')
                      }
                  }},on:{"change":_vm.updateValue}})],1),_c('v-col',{staticClass:"py-0",attrs:{"md":"6","cols":"12"}},[_c('DynamicFormControl',{attrs:{"control":{
                      name: 'CovWaterDamage',
                      type: 'Dropdown',
                      value: _vm.newQuote.CovWaterDamage,
                      config: {
                          label: 'Water Damage Sublimit',
                          rules: 'required',
                          col: 12,
                          short: true,
                          clearable: false,
                          options: _vm.getOptions('CovWaterDamage'),
                          disabled: _vm.calcing || _vm.hasSingleOption('CovWaterDamage')
                      }
                  }},on:{"change":_vm.updateValue}})],1),_c('v-col',{staticClass:"py-0",attrs:{"md":"6","cols":"12"}},[_c('DynamicFormControl',{attrs:{"control":{
                      name: 'CovMold',
                      type: 'Dropdown',
                      value: _vm.newQuote.CovMold,
                      config: {
                          label: 'Mold Sublimit',
                          rules: 'required',
                          col: 12,
                          short: true,
                          clearable: false,
                          options: _vm.getOptions('CovMold'),
                          disabled: _vm.calcing || _vm.hasSingleOption('CovMold')
                      }
                  }},on:{"change":_vm.updateValue}})],1)],1),_c('div',{staticClass:"py-6 actions"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"py-0",attrs:{"sm":"6","cols":"12"}},[_c('v-btn',{key:"save",staticClass:"mr-4",attrs:{"color":"secondary","type":"submit","large":"","disabled":_vm.calcing || !_vm.cvcLoaded || (_vm.validationMessages)}},[(_vm.calcing)?_c('Loading',{staticClass:"mr-2",attrs:{"size":24}}):_vm._e(),_vm._v(" Calculate ")],1),(_vm.premium)?_c('h2',{staticClass:"premium"},[_vm._v(_vm._s(_vm.premium))]):_vm._e()],1),_c('v-col',{staticClass:"py-0 text-right",attrs:{"sm":"6","cols":"12"}},[_c('v-btn',{staticClass:"mr-2",attrs:{"color":"secondary","type":"button","text":"","large":"","disabled":_vm.calcing || !_vm.cvcLoaded},on:{"click":_vm.close}},[_vm._v(" Cancel ")]),(_vm.premium)?_c('v-btn',{attrs:{"color":"secondary","type":"button","large":"","disabled":!_vm.calcResponse || _vm.calcing || !_vm.cvcLoaded || (_vm.validationMessages)},on:{"click":_vm.save}},[_vm._v(" Save ")]):_vm._e()],1)],1)],1)],1):_vm._e()]}}])})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }