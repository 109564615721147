import { Module } from 'vuex';
import { UserState, RootState } from './types';
import { ActionTree, GetterTree, MutationTree } from 'vuex';

import {getTokenAsync, isUserAuthenticated, signoutRedirect} from '@/plugins/auth';
import { User } from 'oidc-client-ts';
import { UserProfileService } from '@/api/UserProfileService';
import Vue from 'vue';
import Environment from '@/scripts/environment';
import { getUserAvatarPath, newGuid, MD5 } from '@/scripts/helper';

// @ts-ignore
import Five9 from '@/scripts/five9';
// @ts-ignore
import UserGuiding from '@/scripts/user-guiding'
import { UnderwriterService } from '@/api/UnderwriterService';
import { AgentService } from '@/api/AgentService';

export const state: UserState = {
    currentUser: null,
    currentParsedToken: null,
    accessTokenExpired: false,
    isLoggedIn: false,
    authMode: Environment.AUTH_MODE,
    profile: null,
    amlink: null,
    userAvatarPath: null,
    defaultUnderwriter: null,
    agentProfile: null
}

const mutations: MutationTree<UserState> = {
    setCurrentUser(state, user: User) {
        Vue.set(state, 'currentUser', user);
        state.accessTokenExpired = state.currentUser?.expired;
        state.isLoggedIn = user && !user?.expired;
    },
    setCurrentParsedToken(state, parsedToken: any){
        Vue.set(state, 'currentParsedToken', parsedToken);
    },    
    setUserProfile(state, data) {
        if (!data) data = {};
        state.profile = Object.assign({}, state.profile, data);
    },
    setAmlinkProfile(state, data) {
        state.amlink = data;
    },
    setAgentProfile(state, data) {
        state.agentProfile = data;
    },
    setUserAvatarPath(state) {
        const token = state.currentParsedToken;
        if (token) {
            state.userAvatarPath = getUserAvatarPath(token.email) + '?v=' + newGuid();
        } else {
            state.userAvatarPath = null;
        }
    },
    setDefaultUnderwriter(state, data) {
        Vue.set(state, 'defaultUnderwriter', data);
    }
}

const getters: GetterTree<UserState, RootState> = {
    getUserName: (state: any, getters: any) => {
        if (state.amlink?.human_first_name)
            return state.amlink?.human_first_name + ' ' + state.amlink?.human_last_name;
        else if (state.currentParsedToken) 
            return state.currentParsedToken["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"];
        else 
            return state.currentParsedToken.given_name;
    },
    getUserGivenName: (state: any) => {
        return state.amlink?.HumanFirstName ?? state.currentParsedToken.given_name;
    },
    getUserLastName: (state: any) => {
        return state.currentParsedToken?.last_name ?? state.currentParsedToken['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/surname'];
    },
    getUserEmail: (state: any) => {
        //todo - get email from OIDC token
        return state.currentParsedToken?.sub;
    },
    getUserHumanId: (state: any) => {
        return state.currentParsedToken?.human_id;
    },
    getUserRole: (state: any) => {
        return state.currentParsedToken?.role;
    },
    getUserPartition: (state: any) => {
        const tokenPayload = state.currentParsedToken;
        if (tokenPayload) {
            const p = tokenPayload.partition;
            return p;
        }
        return null;
    },
    userHasRole: (state: any) => (role: string) => {
        const tokenPayload = state.currentParsedToken;
        if (tokenPayload) {
            const app_role = tokenPayload.role;
            if (role == app_role) return true;
        }
        return false;
    },
    userHasRestrictedFeature: (state: any) => (restrictedFeature: string) => {
        const tokenPayload = state.currentParsedToken;
        if (tokenPayload) {
            const app_role = tokenPayload.role;
            if (app_role == 'admin') return true;

            const restricted_features = tokenPayload.restricted_features;
            if (!restricted_features) return false;
            else return restricted_features.split("|").indexOf(restrictedFeature) > -1;
            
        }
        return false;
    },
    isImpersonatingUser: (state: any) => {
        const profile = state.currentParsedToken
        if (profile) {
            return profile['delegate-idp']?.includes('impersonation')
        }
        return false
    },
    isExternalUser: (state: any) => {
        const profile = state.currentParsedToken
        if (profile) {
            return profile['delegate-idp']?.includes('Microsoft') || profile['delegate-idp']?.includes('Google')
        }
        return false
    },
    authorizedWithoutRole: (state: any) => {
        const userRole = state.currentParsedToken?.role;
        return userRole == 'none';
    },
    getUser: (state: any) => {
        return state.currentUser;
    },
    getUserProfile: (state: any) => {
        return state.profile;
    },
    getAmlinkProfile: (state: any) => {
        return state.amlink;
    },
    getAgentProfile: (state: any) => {
        return state.agentProfile;
    },
    getUserIsLoggedIn: (state: any) => {
        const tokenPayload = state.currentParsedToken;
        return state.currentUser && state.currentUser?.expires_at > Math.floor(Date.now() / 1000) && tokenPayload && tokenPayload?.exp > Math.floor(Date.now() / 1000)
    },
    getUserAvatarPath: (state: any) => {
        return state.userAvatarPath;
    },
    getShowDevAuthDialog: (state: any) => {
        return state.authMode == 'DEV' && state.currentUser == null;
    },
    getDefaultUnderwriter: (state: any) => {
        return state.defaultUnderwriter;
    },
    getDefaultUnderwriterKey: (state: any) => {
        if (state.profile) return state.profile.DefaultUnderwriterKey;
        return null;
    },
    getPreferredUnderwriterKeys: (state: any) => {
        if (state.profile) {
            if (typeof(state.profile.PreferredUnderwriterKeys) != 'object') return [];
            return state.profile.PreferredUnderwriterKeys || [];
        }
        return [];
    },
    getDefaultAgentKey: (state: any) => {
        if (state.profile) return state.profile.DefaultAgentKey;
        return null;
    },
    getPreferredAgentKeys: (state: any) => {
        if (state.profile) {
            if (typeof(state.profile.PreferredAgentKeys) != 'object') return [];
            return state.profile.PreferredAgentKeys || [];
        }
        return [];
    },
    getSavedDiligentEfforts: (state: any) => {
        if (state.profile) {
            if (typeof(state.profile.SavedDiligentEfforts) != 'object') return [];
            return state.profile.SavedDiligentEfforts || [];
        }
        return [];
    },
    getSavedDiligentEffortsV1: (state: any) => {
        if (state.profile) {
            if (typeof(state.profile.SavedDiligentEffortsV1) != 'object') return [];
            return state.profile.SavedDiligentEffortsV1 || [];
        }
        return [];
    },    
    getStateLicenses: (state: any) => {
        if (state.profile) {
            if (typeof(state.profile.StateLicenses) != 'object') return [];
            return state.profile.StateLicenses || [];
        }
        return [];
    }
}

const actions: ActionTree<UserState, RootState> = {
    getUser({ commit, state, dispatch }) {
    },
    setUser({ commit, state, dispatch }, user) {
        if (user) {
            commit('setCurrentUser', user);
        }
    },
    initUser(context) {

        const userEmail = context.getters.getUserEmail;

        if (Environment.environment != 'localhost') {
            
            if (Environment.RESTRICT_ACCESS && Environment.RESTRICT_ACCESS.trim()) {
                console.warn("RESTRICT_ACCESS");
                const admins = Environment.RESTRICT_ACCESS.split(';').map((i: String) => i.trim());
                if (admins.indexOf(userEmail) == -1) {
                    window.location.href = 'https://offline.amwins.com/';
                }
            }
        }

        // force access for only known admins (use for deploy safety)
        // const admins = [
        //   'ben.reynolds@amwins.com', 
        //   'jerome.rose@amwins.com', 
        //   'lars.ulbricht@amwins.com',
        // ];
        // if (admins.indexOf(userEmail) == -1) {
        //   window.location.href = 'https://offline.amwins.com/';
        // }       

        context.dispatch('resource/getMoratoriumBanner'); 
        context.dispatch('resource/getCatastropheClosings');
        context.dispatch('resource/getCountries');
        context.dispatch('resource/getCountriesV1');
        context.dispatch('resource/getUnderwriters');

        
        context.dispatch('initFive9');
        context.dispatch('initFullStory');        
        context.dispatch('initGoogleAnalyticsUser')
       if (context.getters.getUserRole === 'admin'|| context.getters.getUserRole === 'support'){
            context.dispatch('health/fetchAppHealthChecks');
           console.log('Fetch App Health Checks-user');
        }
       
        
        context.dispatch('refreshProfile');
       
      

    },
    //handle the visable/impersonable parts of the user session
    refreshProfile(context){
        context.commit('setUserAvatarPath');
        context.dispatch('getFeatureFlags');
        context.dispatch('loadUserProfile');
        context.dispatch('loadAmlinkProfile');
        context.dispatch('loadAgentProfile');
        context.dispatch('initUserGuiding');
    },
    initGoogleAnalyticsUser(context) {
        if (!context.getters.isImpersonatingUser) {

            Vue.prototype.$gtag.set({
                user_id:  MD5(context.getters.getUserEmail)      
              });

            Vue.prototype.$gtag.set("user_properties", {
                'user_app_id': MD5(context.getters.getUserEmail),
                'user_app_role': context.getters.getUserRole
            })
        }
    },
    initFive9(context) {
        Five9.init(context.getters.getUserName, context.getters.getUserEmail);
    },
    initUserGuiding(context) {
        // Setting this localStorage variable so that already viewed user
        // guiding does not display again after clearing the cache
        localStorage.setItem('__UGS__uid', MD5(context.getters.getUserEmail))
        if (!context.getters.authorizedWithoutRole && !context.getters.isImpersonatingUser) {
            //@ts-ignore
            if (!window.userGuiding) UserGuiding.init()

            //@ts-ignore
            window.userGuiding.identify(MD5(context.getters.getUserEmail), {
                app_role: context.getters.getUserRole
            })
        }
    },
    initFullStory(context) {
        if (Environment.environment != 'localhost' && Environment.environment != 'development' && Vue.prototype.$FullStory && !context.getters.isImpersonatingUser) {
            Vue.prototype.$FullStory.identify(context.getters.getUserEmail, {
                displayName: context.getters.getUserName,
                email: context.getters.getUserEmail,
                role: context.getters.getUserRole,
                versionHash: window.localStorage.getItem('versionHash')
            })
        }
    },
    loadUserProfile(context) {
        UserProfileService.get()
          .then((data) => {
              context.commit('setUserProfile', data);
              context.dispatch('loadDefaultUnderwriter');
          })
          .catch((e) => {
              console.log('error', e)
          });
    },
    async loadAmlinkProfile(context) {
        await UserProfileService.amlink()
          .then(async (data) => {
              await context.commit('setAmlinkProfile', data)
          })
          .catch((e) => {
              console.log('error', e)
          });
    },
    loadAgentProfile(context) {
        if (context.getters.getUserHumanId && context.getters.getUserRole === "agent"){
            AgentService.get({
                id: context.getters.getUserHumanId
            })
            .then((data) => {
                context.commit('setAgentProfile', data);
            })
            .catch((e) => {
                console.log('error', e)
            })
        }
    },
    loadDefaultUnderwriter(context) {
        if (context.getters.getDefaultUnderwriterKey) {
            UnderwriterService.get({
                id: context.getters.getDefaultUnderwriterKey
            })
            .then((data) => {
                context.commit('setDefaultUnderwriter', data);
            })
            .catch((e) => {
                if (e.response.status === 404) {
                    context.dispatch('updateUserProfile', {
                        name: "DefaultUnderwriterKey",
                        value: null
                    })
                }
                else {
                    console.log('error', e)
                }
            })
        } 
    },
    updateDefaultUnderwriterKey(context, data) {
        context.dispatch('updateUserProfile', {
            name: "DefaultUnderwriterKey",
            value: data
        });
    },
    addPreferredUnderwriterKey(context, data) {
        const PreferredUnderwriterKeys = context.getters.getPreferredUnderwriterKeys;
        if (PreferredUnderwriterKeys.indexOf(data) == -1) {
            PreferredUnderwriterKeys.push(data);
            context.dispatch('updateUserProfile', {
                name: "PreferredUnderwriterKeys",
                value: PreferredUnderwriterKeys
            });
        }
    },
    removePreferredUnderwriterKey(context, data) {  
        const u = context.getters.getPreferredUnderwriterKeys;
        const PreferredUnderwriterKeys = u.filter((i: any) => i && i != data);

        context.dispatch('updateUserProfile', {
            name: "PreferredUnderwriterKeys",
            value: PreferredUnderwriterKeys
        });
    },
    updateDefaultAgentKey(context, data) {
        context.dispatch('updateUserProfile', {
            name: "DefaultAgentKey",
            value: data
        });
    },
    addPreferredAgentKey(context, data) {
        const PreferredAgentKeys = context.getters.getPreferredAgentKeys;
        if (PreferredAgentKeys.indexOf(data) == -1) {
            PreferredAgentKeys.push(data);
            context.dispatch('updateUserProfile', {
                name: "PreferredAgentKeys",
                value: PreferredAgentKeys
            });
        }
    },
    removePreferredAgentKey(context, data) {  
        const u = context.getters.getPreferredAgentKeys;
        const PreferredAgentKeys = u.filter((i: string) => i && i != data);

        context.dispatch('updateUserProfile', {
            name: "PreferredAgentKeys",
            value: PreferredAgentKeys
        });
    },
    addSavedDiligentEffort(context, data) {
        const d = {...data};
        delete d.ContactedDate;
        delete d.DeclineReason;

        if (!d.Company || !d.Company.InsuranceCarrierCode) return;

        const savedDiligentEfforts = context.getters.getSavedDiligentEfforts;
        const s = savedDiligentEfforts.filter((i: any) => i.Company.InsuranceCarrierCode != d.Company.InsuranceCarrierCode);
        s.unshift(d);

        context.dispatch('updateUserProfile', {
            name: "SavedDiligentEfforts",
            value: s
        });
    },
    addSavedDiligentEffortV1(context, data) {
        const d = {...data};
        delete d.ContactedDate;
        delete d.DeclineReason;
        if (!d.Company) return;

        const savedDiligentEfforts = context.getters.getSavedDiligentEffortsV1;
        const s = savedDiligentEfforts.filter((i: any) => i.Company != d.Company);
        s.unshift(d);

        context.dispatch('updateUserProfile', {
            name: "SavedDiligentEffortsV1",
            value: s
        });
    },    
    removeSavedDiligentEffort(context, data) {
        const d = {...data};
        if (!d.Company || !d.Company.InsuranceCarrierCode) return;

        const savedDiligentEfforts = context.getters.getSavedDiligentEfforts;
        const s = savedDiligentEfforts.filter((i: any) => i.Company.InsuranceCarrierCode != d.Company.InsuranceCarrierCode);

        context.dispatch('updateUserProfile', {
            name: "SavedDiligentEfforts",
            value: s
        });
    },
    removeSavedDiligentEffortV1(context, data) {
        const d = {...data};
        if (!d.Company ) return;

        const savedDiligentEfforts = context.getters.getSavedDiligentEffortsV1;
        const s = savedDiligentEfforts.filter((i: any) => i.Company != d.Company);

        context.dispatch('updateUserProfile', {
            name: "SavedDiligentEffortsV1",
            value: s
        });
    },    
    updateStateLicenses(context, data) {
        context.dispatch('updateUserProfile', {
            name: "StateLicenses",
            value: data
        });
    },
    updateUserProfile(context, data) {
        const userProfile = context.getters.getUserProfile;
        const user = context.getters.getUser;
        if (user.profile.sub === userProfile.id || user.profile.email === userProfile.id) {
            userProfile[data.name] = data.value;
            UserProfileService.put({
                body: userProfile
            }).then((data2) => {
                context.commit('setUserProfile', data2);

                if (data.name == 'DefaultUnderwriterKey') {
                    context.dispatch('loadDefaultUnderwriter');
                }
            })
        }
    },
    logout(context) {
        if (state.authMode == 'OIDC') {
            signoutRedirect();
        } else {
            context.commit('devLogout');
        }
    },
}

const namespaced: boolean = false;

export const user: Module<UserState, RootState> = {
    namespaced,
    state,
    getters,
    actions,
    mutations
}