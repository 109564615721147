//import form from '@/config/start'
import { createStateForm } from '@/scripts/helper'
import { Module } from 'vuex';
import { FormState, RootState } from './types';

export const state: FormState = {
    form: null,
    currentStepNumber: 0
}

const mutations = {
    initStartForm(state: FormState, form: any) {
        state.form = createStateForm({...form});
        state.currentStepNumber = 0;
    }
}

const getters = {

}

export const actions = {

}

const namespaced: boolean = true;

export const startForm: Module<FormState, RootState> = {
    namespaced,
    state,
    getters,
    actions,
    mutations
}