<template>

    <v-app>
        <TheHeader></TheHeader>

        <v-main>
          <router-view :key="$route.fullPath" />    
        </v-main>

        <v-overlay :value="blockUI" :z-index="500">
          <Loading :size="64" />
        </v-overlay>        
      
        <TheFooter></TheFooter>
        <div id="liveChat"></div>

        <template v-if="showLockContestDialog">
          <LockContestDialog />
        </template>

        <template v-if="showLockReleaseDialog">
          <LockReleaseDialog />
        </template>

        <template v-if="authorizedWithoutRole">          
            <SignupDialog :value="authorizedWithoutRole" />
        </template>

        <v-overlay :value="blockAgencyFromAccess && isTestEnvironment">
            <GenericDialog :value="true"
                           :title="`Error Accessing Test Site`"
                           :width="`600px`"
                           persistent>

              <template v-slot:card-text>
                <v-row>                   
                    <v-col cols="12">
                      <p class="mb-0">
                        You are attempting to access a test site, you can find our main site at: <a href="https://personal-lines.amwins.com">personal-lines.amwins.com</a>
                      </p>
                    </v-col>                  
                </v-row>
              </template>

              <template v-slot:card-actions>
                <div>
                    <v-btn v-if="isImpersonatingUser" class="mr-2" color="secondary" type="submit" @click="stopUserImpersonation">
                        Stop Impersonating User
                    </v-btn>
                    <v-btn color="secondary" type="submit" @click="redirectToProductionSite">
                        OK
                    </v-btn>
                </div>
              </template>

            </GenericDialog>
        </v-overlay>

    </v-app>
</template>

<script>
import TheHeader from '@/components/layout/TheHeader.vue'
import TheFooter from '@/components/layout/TheFooter.vue'
import LockContestDialog from '@/components/shared/LockContestDialog.vue'
import LockReleaseDialog from '@/components/shared/LockReleaseDialog.vue'
import Loading from '@/components/shared/Loading.vue'
import GenericDialog from '@/components/shared/Dialog.vue'
import SignupDialog from '@/components/views/Amlink/SignupDialog.vue'
import Environment from '@/scripts/environment'
import { AgencyService } from '@/api/AgencyService'
import { tabReactivated, removeUser, getTokenDataAsync, endImpersonation } from '@/plugins/auth';


export default {
  name: 'App',
  components: {
    TheHeader,
    TheFooter,
    LockContestDialog,
    LockReleaseDialog,
    Loading,
    GenericDialog,
    SignupDialog
  },
  data() {
    return {
      username: null,
      password: null,
      showLockContestDialog: false,
      showLockReleaseDialog: false,
      blockAgencyFromAccess: false
    }
  },
  computed: {
    blockUI() {
      return this.$store.getters.showBlockUI && this.$route.path !== '/callback' && !this.authorizedWithoutRole
    },
    isImpersonatingUser() {
      return this.$store.getters.isImpersonatingUser
    },
    authorizedWithoutRole() {
        return this.$store.getters.authorizedWithoutRole 
    },
    hasRole() {
        return this.$store.getters.getUserRole != null 
    },
    hasPartition() {
        return (this.$store.getters.getUserPartition != null && this.$store.getters.getUserPartition != '');
    },
    isAgent() {
      return this.$store.getters.getUserRole == 'agent';
    },
    isTestEnvironment() {
      return Environment.environment != 'prod';
    },
    orgBranchid() {
      return this.$store.getters.getUser.profile?.org_branch_id;
    }
  },
  watch: {
    '$store.getters.getRejectedLockRequest': function() {
      this.showLockContestDialog = this.$store.getters.getRejectedLockRequest != null;
    },
    '$store.getters.getLockReleaseRequest': function() {
      this.showLockReleaseDialog = this.$store.getters.getLockReleaseRequest != null;
    },
    isAgent(val) {
      if (val) {
        AgencyService.get({ id: this.orgBranchid })
          .then((result) => {
            this.blockAgencyFromAccess = !result?.HasNonProdAccess
          })
      }
    }
  },
  methods: {
    completeSignupProcess() {
      // removing user because if you don't, redirect to the amwins site (but don't complete setup) and attempt to
      // login as another user that has completed their account setup without logging out as the first user, the 
      // oidc client retains the first user's data and displays the Complete Signup Process modal when it shouldn't.
      removeUser()
      window.location = ' https://www.amwins.com/solutions/small-accounts/personal-lines/portal-sign-up'
    },
    redirectToLogin() {
      removeUser()
      window.location.href = 'https://www.amwins.com/solutions/small-accounts/personal-lines';
    },
    redirectToProductionSite() {
      window.location.href = 'https://personal-lines.amwins.com';
    },
    stopUserImpersonation() {
      endImpersonation();
      const redirectUrl = sessionStorage.getItem('returnPath') ? `${Environment.UI_HOST}${sessionStorage.getItem('returnPath')}` : `${Environment.UI_HOST}/dashboard`
      window.location = redirectUrl
    },
  },
  mounted() {
    // Add event listeners to detect focus/blur on the active tab. This is done to auto refresh the user's access token
    // if it expires while they are on a different tab. After about 5 minutes of being on a different tab, javascript will
    // stop working in this tab and the silent renew will fail to renew the access token.

    window.addEventListener('blur', () => { tabReactivated() });

    document.addEventListener('visibilitychange', () => { 
      if (document.visibilityState == 'visible') {
        tabReactivated() 
      }
    });

  }
};
</script>

<style lang="scss">
@import '@/style.scss';

body .theme--light.v-application {
  background-color: $background-color;
}

.v-overlay__content {
  margin-top: -200px;
}

.v-main__wrap {
  margin-top: 20px;
}

#nav {
  padding-left: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: pink;
    }
  }
}

</style>
