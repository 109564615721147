var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},model:{value:(_vm.open),callback:function ($$v) {_vm.open=$$v},expression:"open"}},[_c('v-card',{staticClass:"pa-4"},[_c('v-card-title',[_c('h3',[_vm._v("Select an Agent")]),_c('v-spacer')],1),_c('v-card-text',[(_vm.inactiveProducer)?[_c('p',{staticStyle:{"color":"#333333"}},[_c('strong',[_vm._v(_vm._s(_vm.inactiveProducer))]),_vm._v(" is no longer active. Please select a new Agent for this Submission.")])]:[_c('p',{staticStyle:{"color":"#333333"}},[_vm._v("This Submission is missing an Agent.")])],_c('ValidationObserver',{ref:"agentObserver",attrs:{"tag":"span"},scopedSlots:_vm._u([{key:"default",fn:function({}){return [(_vm.open)?_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.validate()}}},[(_vm.inactiveProducerOrgBranchId)?_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('DynamicFormControl',{attrs:{"control":{
                        type: 'Dropdown',
                        name: 'Agent',
                        value: _vm.Agent,
                        config: {
                            label:'Retail Agent',
                            rules: 'required',
                            col: 12,
                            returnObject: true,
                            itemText: 'SeekDisplay',
                            itemValue: 'id',
                            options: _vm.options
                        }
                    }},on:{"change":function($event){_vm.Agent = $event.value}}})],1)],1):_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('DynamicFormControl',{attrs:{"control":{
                        type: 'AgentAutocomplete',
                        name: 'Agent',
                        config: {
                            label:'Retail Agent',
                            rules: 'required',
                            col: 12,
                        }
                    }},on:{"change":function($event){_vm.Agent = $event.value}}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"text-right",attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"mr-2",attrs:{"color":"secondary","type":"button","large":"","text":""},on:{"click":_vm.cancel}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"secondary","type":"submit","large":""}},[_vm._v("Submit")])],1)],1)],1):_vm._e()]}}])})],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }