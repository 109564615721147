<template>
  <div>

    <v-expansion-panels v-if="policy?.Policy?.EndorsementTransactions && policy?.Policy?.EndorsementTransactions.length > 0"
                        class="mb-4">
      <v-expansion-panel>
        <v-expansion-panel-header>
          <h3 style="font-weight: 500">
            Endorsement History
          </h3>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="mx-0">
          <span>
            The following changes were recently made to the policy:
          </span>

          <v-card style="max-width: 600px;">
            <v-card-text class="py-0">
              <v-container class="pa-0">
                <v-row class="my-2 mb-0">

                  <v-col cols="12" sm="4" class="text-center">
                    ENDORSEMENT DATE
                  </v-col>

                  <v-col cols="12" sm="8">
                    PREMIUM CHANGE
                  </v-col>

                </v-row>
                <v-divider />

                <template v-for="(item) in sortedEndorsementTransactions">
                  <div :key="item.controlName">
                    <v-row class="my-2">

                      <v-col cols="12" sm="4" class="d-flex align-center justify-center">
                        <span>{{formatDateForDisplay(item.EffectiveDate, true)}}</span>
                      </v-col>

                      <v-col cols="12" sm="8">
                        <ReadOnlyDisplay label="Original Premium" class="d-inline-block">
                          <label slot="content" class="d-inline-block">{{formatMoney(calculateTotalPremium(getPriorPricing(item)), true)}}</label>
                        </ReadOnlyDisplay>

                        <v-divider class="d-inline-block mx-8" vertical></v-divider>

                        <ReadOnlyDisplay label="New Total Premium" class="d-inline-block">
                          <label slot="content" class="text-bold d-inline-block pr-2">{{formatMoney(calculateTotalPremium(item.AnnualPricing), true)}}</label>
                          <span slot="content" :class="priceChangeClass(calculateTotalPremium(getPriorPricing(item)), calculateTotalPremium(item.AnnualPricing))">
                            {{priceDifference(calculateTotalPremium(getPriorPricing(item)), calculateTotalPremium(item.AnnualPricing))}}
                          </span>
                        </ReadOnlyDisplay>
                      </v-col>

                    </v-row>
                    <v-divider />
                  </div>
                </template>
              </v-container>
            </v-card-text>
          </v-card>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <ValidationObserver tag="span" ref="observer">
      <form @submit.prevent="validate()">
        <DynamicFormControl :control="{
                                     name: 'TIV',
                                     type: 'Hidden',
                                     value: tiv,
                                 }" />

        <DynamicFormControl :control="{
                                     name: 'TenPercentTIV',
                                     type: 'Hidden',
                                     value: tenPercentTIV,
                                 }" />


          <v-expansion-panels v-model="panels"       
                              multiple
                              class="mb-1">
            <v-expansion-panel v-for="(step, index) in form?.steps"
                               :key="index">
              <div v-if="showStep(step)">
                <v-expansion-panel-header>
                  <h3 style="font-weight: 500">
                    {{step.label}}
                  </h3>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="mx-0">
                  <v-container class="pa-0" v-show="showNotEditableAlert(isV2SalesChannel ? policy?.Policy?.CoverageModelType : 1, step.label)">
                    <v-alert type="info" outlined>If there is any information you need to change here that is not editable, email your Underwriter for update and potential rewrite.</v-alert>
                  </v-container>
                  <template v-for="(section, sectionIndex) in step.sections">
                    <v-container v-if="showSection(section)" :key="'section' + sectionIndex" class="mb-4 px-0">
                      <v-row>
                        <v-col>
                          <h3>
                            {{section.label}}
                          </h3>
                        </v-col>
                      </v-row>
                      <v-row>
                        <template v-for="(control, controlIndex) in section.controls">
                          <v-col v-if="showControl(control) && control.type != 'Hidden'" cols="12" :md="cols(control)" class="py-0 mb-2" :key="controlIndex">
                            <DynamicFormControl v-if="!readOnlyControl(control)"
                                                :control="control"
                                                :module="module"
                                                @change="updateValue" />
                            <DynamicFormControl v-else-if="displayValue(control) && displayValue(control) !== ''"
                                                :module="module"
                                                :control="{
                                                  type: 'ReadOnlyDisplay',
                                                  name: control.name,
                                                  value: control.value,
                                                  config: {
                                                      short: true,
                                                      label: control.config?.label,
                                                      val: displayValue(control),
                                                      rules: control.config?.rules
                                                  }
                                              }" />
                            <v-row v-if="controlValueModified(control)" class="mt-0" :key="control.renderValue">
                              <v-col :cols="control.config?.col" class="pt-0">
                                <span class="values-changed-label">
                                  Original Value: <strong v-html="getUnmodifiedValue(control)"></strong>
                                </span>
                              </v-col>
                            </v-row>
                          </v-col>
                        </template>
                      </v-row>
                      <v-divider v-if="sectionIndex != (step.sections.length - 1)" class="mt-4" />
                    </v-container>
                  </template>
                </v-expansion-panel-content>
              </div>
            </v-expansion-panel>
          </v-expansion-panels>

      </form>
    </ValidationObserver>

    <v-overlay :value="loading || rating" :z-index="1">
      <Loading :size="64" />
    </v-overlay>

  </div>
</template>

<script>
  import DynamicFormControl from '@/components/form/DynamicFormControl.vue'
  import ReadOnlyDisplay from '@/components/form/ReadOnlyDisplay.vue'
  import { formatDateForDisplay, formatMoney, getControlObj, getNonNullAliasObj, calculateTotalPremium } from '@/scripts/helper'
  import { getControlOptionTextFromControlValue, formatMortgageeV1ForDisplay, showNotEditableAlert } from '@/scripts/endorsements.js'
  import { calculateDeductibleChanges, valueChanged, getComputedValue } from '@/scripts/sales-channel-deductible'

  export default {
    name: 'EndorsementForm',
    components: {
      DynamicFormControl,
      ReadOnlyDisplay
    },
    props: {
      form: {
        type: Object,
        default: null
      },
      policy: {
        type: Object,
        default: null
      },
      validateForm: {
        type: Boolean,
        default: false
      },
      loading: {
        type: Boolean,
        default: false
      },
      rating: {
        type: Boolean,
        default: false
      },
      module: {
        type: String,
        default: null
      },
      premiumBearingEndorsement: {
        type: Boolean,
        default: false
      },
      isV2SalesChannel: {
        type: Boolean,
        default: false
      },
      deductibleValues: {
        type: Object,
        default: false
      },
      windHailPerilValueHolder: {
        type: Number,
        default: 0
      },
      floodPerilValueHolder: {
        type: Number,
        default: 0
      },
      earthquakePerilValueHolder: {
        type: Number,
        default: 0
      },
      canNotEditCoverages: {
        type: Boolean,
        default: true
      }
    },
    emits: [
      'change',
      'valid',
      'updateDeductibleValues'
    ],
    data() {
      return {
        // Used to validate CovB limits on Hiscox endorsements
        hiscoxCovBPercentLimit: .1,
        // Used to validate CovD limits on Hiscox endorsements
        hiscoxCovDPercentLimit: .2,
        retainValueIfHidden: ['ValuationType', 'CovF', 'CovMdp3', 'PersonalInjury'],
        panels: [],
        formReRenderList: ["LossesInPast5YearsList", "OtherRisksAssociated", "AdditionalInterestsList"]
      }
    },
    computed: {
      unmodifiedForm() {
        return this.$store.getters['endorsementForm/getUnmodifiedEndorsementForm'];
      },
      sortedEndorsementTransactions() {
        const endorsementTransactionsClone = this.policy.Policy.EndorsementTransactions
        return endorsementTransactionsClone?.sort(function (a, b) {
          return new Date(b.TransactionDate) - new Date(a.TransactionDate);
        });
      },
      covA() {
        if (!this.form) return null

        return this.$store.getters.getControl(this.module, 'CovA')
      },
      covB() {
        if (!this.form) return null

        return this.$store.getters.getControl(this.module, 'CovB')
      },
      covC() {
        if (!this.form) return null

        return this.$store.getters.getControl(this.module, 'CovC')
      },
      covD() {
        if (!this.form) return null

        return this.$store.getters.getControl(this.module, 'CovD')
      },
      liability() {
        if (!this.form) return null

        return this.$store.getters.getControl(this.module, 'CovE')
      },
      dp3Liability() {
        if (!this.form) return null

        return this.$store.getters.getControl(this.module, 'CovLdp3')
      },
      tiv() {
        if (this.isV2SalesChannel && this.policy?.Policy != null) {
          return this.getComputedValue({
            CovA: this.covA,
            CovB: this.covB,
            CovC: this.covC,
            CovD: this.covD,
          }, this.policy.Policy.SalesChannel.Value, 'TIV')
        }

        return 0
      },
      tenPercentTIV() {
        if (this.isV2SalesChannel) {
          return this.tiv * .1;
        }

        return 0
      },
      endorsementEffectiveDate() {
        if (!this.form) return null

        return this.$store.getters.getControl(this.module, 'EndorsementEffectiveDate')
      },
      floodPerilDeductible() {
        if (!this.form) return null

        return this.$store.getters.getControl(this.module, 'FloodPerilDeductible')
      },
      isSupportOrHigher() {
        return this.$store.getters.userHasRole('support') || this.$store.getters.userHasRole('admin');
      },
      rcv() {
        if (this.isV2SalesChannel && this.policy?.Policy != null) {
          return this.policy.Policy.Coverages?.CovA?.DataServiceValue ?? 0
        }

        return 0
      },
    },
    watch: {
      async validateForm(val) {
        if (val) {
          await this.validate()
        }
      },
      covA: {
        handler: function (val) {
          if (this.isV2SalesChannel && this.covC) {
            const result = this.calculateDeductibleChanges({
              value: val.value,
              deductibleValues: this.deductibleValues,
              CovC: this.covC,
              formType: this.policy.Policy.FormType.Value,
              windHailPerilValueHolder: this.windHailPerilValueHolder,
              floodPerilValueHolder: this.floodPerilValueHolder,
              earthquakePerilValueHolder: this.earthquakePerilValueHolder,
            }, this.policy.Policy.SalesChannel.Value, 'CovA')

            if (result && result.windHailPerilValueHolder > 0) {
              this.$emit('change', { name: 'WindHailFlatAmount', value: result.deductibleValues.windHailPerilValues.flat, peril: 'windHail', type: 'flat' })
            }
            if (result && result.earthquakePerilValueHolder > 0) {
              this.$emit('change', { name: 'EarthquakeFlatAmount', value: result.deductibleValues.earthquakePerilValues.flat, peril: 'earthquake', type: 'flat' })
            }
            if (result && result.floodPerilValueHolder > 0) {
              this.$emit('change', { name: 'FloodPerilFlatAmount', value: result.deductibleValues.floodPerilValues.flat, peril: 'flood', type: 'flat' })
            }
          }
        },
        deep: true
      },
      covC: {
        handler: function (val) {
          if (this.isV2SalesChannel && this.covA) {
            const result = this.calculateDeductibleChanges({
              value: val.value,
              deductibleValues: this.deductibleValues,
              CovA: this.covA,
              formType: this.policy.Policy.FormType.Value,
              windHailPerilValueHolder: this.windHailPerilValueHolder,
              floodPerilValueHolder: this.floodPerilValueHolder,
              earthquakePerilValueHolder: this.earthquakePerilValueHolder,
            }, this.policy.Policy.SalesChannel.Value, 'CovC')

            if (result && result.windHailPerilValueHolder > 0) {
              this.$emit('change', { name: 'WindHailFlatAmount', value: result.deductibleValues.windHailPerilValues.flat, peril: 'windHail', type: 'flat' })
            }
            if (result && result.earthquakePerilValueHolder > 0) {
              this.$emit('change', { name: 'EarthquakeFlatAmount', value: result.deductibleValues.earthquakePerilValues.flat, peril: 'earthquake', type: 'flat' })
            }
            if (result && result.floodPerilValueHolder > 0) {
              this.$emit('change', { name: 'FloodPerilFlatAmount', value: result.deductibleValues.floodPerilValues.flat, peril: 'flood', type: 'flat' })
            }
          }
        },
        deep: true
      },
      liability: {
        handler: function (value) {
          if (this.policy?.Policy != null && this.isV2SalesChannel && value?.value >= 0) {
            const ret = this.valueChanged({
              value: value.value,
            }, this.policy.Policy.SalesChannel.Value, 'Liability')
            
            if (ret.clearMedPayAndPersonalInjury && !this.canNotEditCoverages) {
              this.$emit('change', { name: 'CovF', value: 0,  })
              this.$emit('change', { name: 'PersonalInjury', value: 'Excluded' })
            }

          }
        },
        deep: true
      },
      dp3Liability: {
        handler: function (value) {
          if (this.policy?.Policy != null && this.isV2SalesChannel && value?.value >= 0) {
            const ret = this.valueChanged({
              value: value.value,
            }, this.policy.Policy.SalesChannel.Value, 'Liability')
            
            if (ret.clearMedPayAndPersonalInjury && !this.canNotEditCoverages) {
              this.$emit('change', { name: 'CovMdp3', value: 0 })
              this.$emit('change', { name: 'PersonalInjury', value: 'Excluded' })
            }

          }
        },
        deep: true
      },
    },
    methods: {
      getControlObj,
      formatMoney,
      formatDateForDisplay,
      calculateDeductibleChanges,
      getComputedValue,
      valueChanged,
      showNotEditableAlert,
      calculateTotalPremium,
      updateValue(event) {
        let control = this.$store.getters.getControl(this.module, event.name)
        this.$emit('change', event)
        if (this.formReRenderList.includes(event.name)) {
          control.renderValue = Math.random()
        }
      },
      showStep(step) {
        if (!step) return false;

        let show = false;

        if (step.sections != undefined) {
          step.sections.forEach((section) => {
            if (this.showSection(section)) show = true
          });
        }

        return show;
      },
      showSection(section) {
        if (!section) return false;

        if (section.hidden) return false;

        let show = false;
        section.controls.forEach((control) => {
          if (this.showControl(control)) show = true;
        });

        return show;
      },
      readOnlyControl(control) {
        return control.config?.disabled == true && control.type != 'AddressV1';
      },
      showControl(control) {
        // check if the control should show
        let showMainComponent = this.$store.getters.showControl(this.module, control.name);
        let persistAliasData = false;
        if (!showMainComponent) { // if not lets verify an alias doesn't back the control's data and should be shown
          const nonNullAliasObj = getNonNullAliasObj(this.form, control.name);
          const controlName = nonNullAliasObj?.name || control.name
          persistAliasData = this.$store.getters.showControl(this.module, controlName); // stops from murdering the data in the update value below
        }

        if (!showMainComponent && this.$store.getters.getControlValue(this.module, control.name) !== null && !control.alias) {
          if (!this.retainValueIfHidden.includes(control.name) && !persistAliasData) {
            this.updateValue({ name: control.name, value: null });
          }
        }
        return showMainComponent;
      },
      transformIsoDate(date) {
        const newData = new Date(date).toISOString()
        const year = newData.slice(0, 4)
        const month = newData.slice(5, 7)
        const day = newData.slice(8, 10)
        return `${month}/${day}/${year}`
      },
      displayValue(control) {
        if (control.name === 'CovA' || 
            control.name === 'CovB' || 
            control.name === 'CovC' || 
            control.name === 'CovD' || 
            control.name === 'BuildingValuationAmount' || 
            control.name === 'ContentsValuationAmount' || 
            control.name === 'WindHailFlatAmount' || 
            control.name === 'WindHailReadOnly' || 
            control.name === 'EarthquakeReadOnly' || 
            control.name === 'FloodReadOnly' || 
            control.name === 'EarthquakeFlatAmount' || 
            control.name === 'WaterReadOnly' ||
            control.name === 'Theft' || 
            control.name === 'WaterBackup' ||
            control.name === 'WaterDamageSublimit' ||            
            control.name === 'WaterDamagePeril') {
          return this.formatMoney(control?.value)
        }

        // value is a string that needs to be an int
        if (control.name === 'AllPerilDeductible') {
          return this.formatMoney(parseFloat(control?.value))
        }

        if (control.name === 'SquareFeet') {
          return control?.value.toLocaleString()
        }

        if (control.name === 'DateOfBirth' || control.name === 'CoApplicantDateOfBirth') {
          return this.formatDateForDisplay(control?.value, true)
        }

        if (control.name === 'Protections') {
          return Object.keys(control.value).filter(key => control.value[key]).join(', ')
        }

        if (control.name === 'OtherRisksAssociated') {
          return this.otherRisksAssociatedDisplay(control)
        }

        if (control.name === 'AdditionalInterestsList' && control.value) {
          return control.value.map(x => `${x.Name}, ${x.Type}, ${x.Address.Formatted}`).join('; ')
        }

        if (control.name === 'LossesInPast5YearsList') {
          return control.value.map(x => 
            `Type: ${x.Type}, Date: ${this.transformIsoDate(x.Date)}, PaymentAmount: ${this.formatMoney(x.PaymentAmount)}, IsClaimAtThisLocation: ${x.IsClaimAtThisLocation}, IsClaimClosed: ${x.IsClaimClosed}, IsDamageRepaired: ${x.IsDamageRepaired}, LossDetails: ${x.LossDetails}, MitigateFutureLosses: ${x.MitigateFutureLosses}`, )
            .join('; <br /> ')
        }

        if (control.name === 'TheftExclusion' && control.value === '1') {
          return 'No Coverage'
        }

        switch (control.type) {
          case 'RadioButtonGroup':
            if (control.value !== undefined) {
              if (control.value === true) {
                return 'Yes'
              }
              if (control.value === false) {
                return 'No'
              }
              return control.value
            }

            if (control.config.default === true) {
              return 'Yes'
            }
            if (control.config.default === false) {
              return 'No'
            }

            return control.config.default
          case 'Dropdown':
          case 'RadioItemGroup':
            return getControlOptionTextFromControlValue(control)
          case 'MortgageeV1':
            return formatMortgageeV1ForDisplay(control)
          case 'AddressV1':
          case 'Address':
            return control.value?.Formatted
          case 'CountryDropdown':
            return control.value?.CountryCode
          case 'Checkbox':
          case 'FormLabel':
            return control.value === true ? 'Yes' : 'No'
          default:
            return control.value?.toString() ?? ''
        }
      },
      otherRisksAssociatedDisplay(control) {
        const options = [
          {
            "value": "isCoverageLapse",
            "v2ContractName": "IsCoverageLapse",
            "text": "Dwellings with <b>lapse in coverage?</b>"
          },
          {
            "value": "insuredHighProfileOccupation",
            "v2ContractName": "InsuredHighProfileOccupation",
            "text": "Insured's with <b>high profile occupations?</b>"
          },
          {
            "value": "dwellingWithKnobAndTubeWiring",
            "v2ContractName": "DwellingWithKnobAndTubeWiring",
            "text": "Dwellings with <b>knob and tube</b> wiring, <b>fuses</b>, <b>aluminum or cloth wiring</b>, and/or <b>Federal Pacific Electric (FPE) Stab-Lok</b> breakers (Zinsco Breakers or Electrical Service less than 100 amp)?"
          },
          {
            "value": "noSmokeDetectors",
            "v2ContractName": "NoSmokeDetectors",
            "text": "Dwellings <b>without smoke detectors?</b>"
          },
          {
            "value": "isUnacceptablePlumbing",
            "v2ContractName": "IsUnacceptablePlumbing",
            "text": "Dwellings with <b>plumbing systems</b> containing <b>cast iron/galvanized/lead/polybutylene</b> materials?"
          },
          {
            "value": "existingDamage",
            "v2ContractName": "ExistingDamage",
            "text": "Property with <b>existing damage?</b>"
          },
          {
            "value": "anyMobileHomes",
            "v2ContractName": "AnyMobileHomes",
            "text": "Is this risk a <b>mobile/modular/manufactured home?</b>"
          },
          {
            "value": "fuelTankOnPremise",
            "v2ContractName": "FuelTankOnPremise",
            "text": "Dwellings with a <b>fuel tank</b> on the premise?"
          },
          {
            "value": "dwellingWithMoreThanTwoMortages",
            "v2ContractName": "DwellingWithMoreThanTwoMortages",
            "text": "Dwellings with <b>more than two mortgages?</b>"
          },
          {
            "value": "anyBankruptcyForeclosureRepossession",
            "v2ContractName": "AnyBankruptcyForeclosureRepossession",
            "text": "Any <b>bankruptcy, foreclosure, or repossession</b> in the last 5 years?"
          },
          {
            "value": "isUnacceptableHeatSource",
            "v2ContractName": "IsUnacceptableHeatSource",
            "text": "Dwellings without Central Heating system utilizing <b>Space Heater(s) or Wood Stove(s) as Primary Heat source?</b>"
          },
          {
            "value": "dwellingWithWoodStoveHeat",
            "v2ContractName": "dwellingWithWoodStoveHeat",
            "text": "Dwellings with <b>wood stoves</b> as a utilized heat source?"
          },
          {
            "value": "dwellingsWithDaycareOrAssistedLiving",
            "v2ContractName": "DwellingsWithDaycareOrAssistedLiving",
            "text": "Dwellings with <b>daycare, assisted living operations or student housing?</b>"
          },
          {
            "value": "developersSpeculationHomes",
            "v2ContractName": "DevelopersSpeculationHomes",
            "text": "Developers <b>speculation homes?</b>"
          },
          {
            "value": "dwellingOnNationalRegistery",
            "v2ContractName": "DwellingOnNationalRegistry",
            "text": "Dwellings on the <b>National Registry?</b>"
          },
          {
            "value": "ownFarms",
            "v2ContractName": "OwnFarms",
            "text": "Do you own any <b>farms?</b>"
          },
          {
            "value": "businessOnPremise",
            "v2ContractName": "BusinessOnPremise",
            "text": "Any <b>business</b> on premise?"
          },
          {
            "value": "notInsuredToFullValue",
            "v2ContractName": "NotInsuredToFullValue",
            "text": "Dwellings not insured to <b>100% of RCV value?</b>"
          },
          {
            "value": "isDwellingWithAsbestosOrLead",
            "v2ContractName": "IsDwellingWithAsbestosOrLead",
            "text": "Risks with known <b>asbestos/lead</b> hazard?"
          },
          {
            "value": "isDwellingInCOBRAZone",
            "v2ContractName": "IsDwellingInCOBRAZone",
            "text": "Risks built on <b>Coastal Barrier Land</b> (COBRA Zones)?"
          },
          {
            "value": "isDwellingFallingRocks",
            "v2ContractName": "IsDwellingFallingRocks",
            "text": "Property subject to and/or with <b>prior/current sinkhole activity, landslide, falling rocks, erosion, subsidence or in poor condition?</b>"
          },
          {
            "value": "isDeededToMultipleUnits",
            "v2ContractName": "IsMultipleLivingUnit",
            "text": "Property is <b>deeded</b> to <b>multiple living units?</b>"
          },
          {
            "value": "hasSolarPanelsInstalled",
            "v2ContractName": "IsDwellingWithSolarPanel",
            "text": "Dwellings with <b>Solar Panels</b> installed on the <b>Roof?</b>"
          },
          {
            "value": "isNonStandardConstruction",
            "v2ContractName": "IsDwellingNonStandardConstruction",
            "text": "Dwellings with <b>Non-Standard Construction</b> (i.e., Airplane Hangar, Boat Condo, Fiberglass, Yurts, Tiny/Tree houses, Houseboats, Floating homes, etc.)"
          }
        ]
        
        const properties = Object.keys(control.value)
        let returnString = ''
        for(const property of properties) {
          if (control.value[property]) {
            const option= options.find(o => o.value === property)
            if (option) {
              returnString += option.v2ContractName + '; '
            }
          }
        }
        return returnString
      },
      async asyncTimeout(ms) {
        // try to resolve the open panels
        return new Promise((resolve) => {
          setTimeout(resolve, ms);
        });
      },
      async validate() {
        const storedPanels = this.panels
        this.openPanels()
        // wait for all panels to open before attempting to validate (2 seconds seems safe)
        await this.asyncTimeout(2000).then(async () => {
          // custom validations
          await this.checkCustomValidations()
            .then((customValidationsValid) => {
              if (customValidationsValid) {
                this.$refs.observer.validate()
                  .then(valid => {
                    if (valid) {
                      this.panels = storedPanels
                      this.$emit('valid', true)
                    } else {
                      this.scrollToFirstError();
                      this.$emit('valid', false)
                    }
                  });
              } else {
                this.scrollToFirstError();
                this.$emit('valid', false)
              }
            })
        })
      },
      openPanels() {
        let shownSteps = []
        this.form.steps.forEach((step, index) => {
          if (this.showStep(step)) {
            shownSteps.push(index)
          }
        })
        this.panels = shownSteps
      },
      async checkCustomValidations() {
        let valid = true

        if (!this.isV2SalesChannel) {
          // If Coverages or Deductible change, this is a premium bearing endorsement and the Endorsement Effective Date must be today
          await this.checkEndorsementEffectiveDate()
            .then((invalid) => {
              if (invalid) {
                this.$refs.observer.setErrors({ EndorsementEffectiveDate: ['Endorsement Effective Date must be today for premium bearing endorsements'] })
                valid = false
              }
            })

          // Checks to see if the endorsement effective date starts before the beginning of the policy (for upcoming policies)
          await this.checkEndorsementDatePriorToInception()
          .then((invalid) => {
            if (invalid) {
              this.$refs.observer.setErrors({ EndorsementEffectiveDate: ['Endorsement Effective Date cannot be set before the policy starts'] })
              valid = false
            }
          })

          // CovA cannot be decreased
          await this.checkCovADecreased()
            .then((invalid) => {
              if (invalid) {
                this.$refs.observer.setErrors({ CovA: ['Dwelling Coverage cannot be decreased'] })
                valid = false
              }
            })
        }

        if (this.policy.Policy?.SalesChannel?.Value === 'VavePersonalLines') {
          // RCV must be set to rate and endorse
          await this.checkRcvNotSet()
            .then( async(invalid) => {
              if (invalid) {
                this.$refs.observer.setErrors({ CovA: [`Cannot Endorse. Missing manatory value for RCV`] })
                valid = false
              } else {
                if (!this.isSupportOrHigher) {
                  // CovA cannot be decreased below RCV (data service value)
                  await this.checkCovADecreasedBelowRcv()
                    .then((invalid) => {
                      if (invalid) {
                        this.$refs.observer.setErrors({ CovA: [`Dwelling Coverage cannot be decreased below RCV (${this.formatMoney(this.rcv)})`] })
                        valid = false
                      }
                    })
                }
              }
            })

          // CovA cannot be increased more than 10% per endorsement
          await this.checkCovAIncreasedMoreThan10Percent()
            .then((invalid) => {
              if (invalid) {
                const originalCovA = this.getControlObj(this.unmodifiedForm, 'CovA');
                const covAIncreaseLimit = (originalCovA?.value ?? 0) * 1.1
                this.$refs.observer.setErrors({ CovA: [`Dwelling Coverage cannot be increased more than 10% (${this.formatMoney(covAIncreaseLimit)})`] })
                valid = false
              }
            })
        }

        // If Hiscox Policy, CovB limits must be 10% of CovA & CovD limits must be 20% of CovA
        if (this.policy.Policy.Header?.SalesChannel.value === 'Hiscox') {
          await this.checkHiscoxCovBLimit(this.covB.value)
            .then((invalid) => {
              if (invalid) {
                const covBLimit = this.covA.value * this.hiscoxCovBPercentLimit;
                this.$refs.observer.setErrors({ CovB: [`Other Structures Coverage must be $${Math.trunc(covBLimit).toLocaleString()}`] })
                valid = false
              }
            })
          await this.checkHiscoxCovDLimit(this.covD.value)
            .then((invalid) => {
              if (invalid) {
                const covDLimit = this.covA.value * this.hiscoxCovDPercentLimit;
                this.$refs.observer.setErrors({ CovD: [`Loss of Use Coverage must be $${Math.trunc(covDLimit).toLocaleString()}`] })
                valid = false
              }
            })
        }

        // If Vave Policy, CovB limit must be $0 & CovD limit must be $10,000
        if (this.policy.Policy.Header?.SalesChannel.value === 'Vave') {
          await this.checkVaveCovBLimit()
            .then((invalid) => {
              if (invalid) {
                this.$refs.observer.setErrors({ CovB: [`Other Structures Coverage must be $0`] })
                valid = false
              }
            })
          await this.checkVaveCovDLimit()
            .then((invalid) => {
              if (invalid) {
                this.$refs.observer.setErrors({ CovD: [`Loss of Use Coverage must be $10,000`] })
                valid = false
              }
            })
        }

        // If Chubb Policy, CovC can't be greater than 50% of CovA,
        // and, if TIV is greater than 1,000,000, the deductible must be 5k or 10k,
        // and, if TIV is greater than 5,000,000, the deductible must be 10k
        if (this.policy.Policy.Header?.SalesChannel.value === 'Chubb') {
          await this.checkChubbCovCLimit()
            .then((invalid) => {
              if (invalid) {
                const covALimit = this.covA.value * .5 + 1
                this.$refs.observer.setErrors({ CovC: [`Personal Property Coverage must be 50% or less of Dwelling Coverage, or less than $${Math.trunc(covALimit).toLocaleString()}`] })
                valid = false
              }
            })
          await this.checkChubbTIV1MilLimit()
            .then((invalid) => {
              if (invalid) {
                this.$refs.observer.setErrors({ FloodPerilDeductible: [`If TIV is $1,000,000+, the Deductible must the $5,000 or more`] })
                valid = false
              }
            })
          await this.checkChubbTIV5MilLimit()
            .then((invalid) => {
              if (invalid) {
                this.$refs.observer.setErrors({ FloodPerilDeductible: [`If TIV is $5,000,000+, the Deductible must the $10,000`] })
                valid = false
              }
            })
        }

        return valid
      },
      async checkCovADecreased() {
        const originalCovA = this.getControlObj(this.unmodifiedForm, 'CovA');
        const covA = this.covA
        return covA.value < originalCovA.value
      },
      async checkCovADecreasedBelowRcv() {
        return this.covA.value < this.rcv
      },
      async checkRcvNotSet() {
        const rcv = this.rcv
        return rcv === 0
      },
      async checkCovAIncreasedMoreThan10Percent() {
        const originalCovA = this.getControlObj(this.unmodifiedForm, 'CovA');
        return this.covA.value > (originalCovA.value * 1.1)
      },
      async checkEndorsementEffectiveDate() {
        return this.premiumBearingEndorsement && this.endorsementEffectiveDate.value != this.formatDateForDisplay(new Date(), true)
      },
      async checkEndorsementDatePriorToInception() {
        let endDate = new Date(this.endorsementEffectiveDate.value);
        endDate.setUTCHours(0, 0, 0, 0);
        let policyEffectiveDate = new Date(this.policy.Policy.Header?.EffectiveDate ?? this.policy.Policy.EffectiveDate);
        policyEffectiveDate.setUTCHours(0, 0, 0, 0);
        return (endDate < policyEffectiveDate)
      },
      async checkHiscoxCovBLimit(covB) {
        const covBLimit = this.covA.value * this.hiscoxCovBPercentLimit;
        return covB != Math.trunc(covBLimit)
      },
      async checkHiscoxCovDLimit(covD) {
        const covDLimit = this.covA.value * this.hiscoxCovDPercentLimit;
        return covD != Math.trunc(covDLimit)
      },
      async checkVaveCovBLimit() {
        return this.covB.value != 0
      },
      async checkVaveCovDLimit() {
        return this.covD.value != 10000
      },
      async checkChubbCovCLimit() {
        return this.covC.value > (this.covA.value * .5)
      },
      async checkChubbTIV1MilLimit() {
        if (this.tiv >= 1000000) {
          return this.floodPerilDeductible.value == 2000
        }
      },
      async checkChubbTIV5MilLimit() {
        if (this.tiv >= 5000000) {
          return this.floodPerilDeductible.value == 2000 || 5000
        }
      },
      scrollToFirstError() {
        const item = this.$el.getElementsByClassName("hasError")[0];
        const viewportOffset = item.getBoundingClientRect();

        const header = document.getElementsByTagName("header")[0];
        const headerHeight = header.offsetHeight;

        window.scrollTo({
          top: viewportOffset.top + window.scrollY - (60 + headerHeight),
          left: 0,
          behavior: 'smooth'
        });
      },
      controlValueModified(control) {
        const unmodifiedControl = this.getControlObj(this.unmodifiedForm, control.name);
        if (control.value === null && unmodifiedControl.value === null) {
          return false
        }
        if (control.value === null || unmodifiedControl.value === null) {
          return true
        }
        if ((!unmodifiedControl || !unmodifiedControl?.value) 
          && unmodifiedControl?.value !== 0 
          && typeof unmodifiedControl?.value !== "boolean") {
            return false
        }

        if (control.type === 'DateInput' && unmodifiedControl.value && control.value) {
          const unmodifiedDate = formatDateForDisplay(unmodifiedControl.value, true);
          const controlDate = formatDateForDisplay(control.value, true)
          return unmodifiedDate != controlDate
        }

        if (typeof control.value === 'object' && !Array.isArray(control.value)) {
          var controlCopy = JSON.parse(JSON.stringify(control));
          var unmodifiedControlCopy = JSON.parse(JSON.stringify(unmodifiedControl));
          if (control.value?.Locked) {
            delete controlCopy.value.Locked
            delete unmodifiedControlCopy.value.Locked
          }
          let isChanged = false;
          var properties = Object.keys(controlCopy.value);
          // This code does not consider cases wehre the property itself is an object (it only looks one layer deep)
            for (let j = 0; j < properties.length; j++) {
              const currentControlValue = controlCopy.value[properties[j]]
              const unmodifiedControlValue = unmodifiedControlCopy.value[properties[j]]
              if (currentControlValue !== unmodifiedControlValue) {
                isChanged = true
              }
            }
          return isChanged
        } else if (typeof control.value === 'object' && Array.isArray(control.value)) {
          let changedArrayValue = false
          if (control.value.length !== unmodifiedControl.value.length) {
            return true
          }
          for (let i = 0; i < control.value.length; i++) {
            const currentControlObj = control.value[i];
            const unmodifiedControlObj = unmodifiedControl.value[i];
            var properties = Object.keys(currentControlObj);
            for (let j = 0; j < properties.length; j++) {
              const currentControlValue = currentControlObj[properties[j]]
              const unmodifiedControlValue = unmodifiedControlObj[properties[j]]
              if (properties[j] !== 'Date') {
                if (currentControlValue !== unmodifiedControlValue && JSON.stringify(control.value) != JSON.stringify(unmodifiedControl.value)) {
                  changedArrayValue = true
                }
              } else if (properties[j] === 'Date') {
                if (currentControlValue && unmodifiedControlValue) {
                  const currentControlDate = formatDateForDisplay(currentControlValue, true)
                  const unmodifiedControlDate = formatDateForDisplay(unmodifiedControlValue, true)
                  if (currentControlDate !== unmodifiedControlDate) {
                    changedArrayValue = true
                  }
                }
              }
            }
          }
          return changedArrayValue
        } else {
          return JSON.stringify(control.value) != JSON.stringify(unmodifiedControl.value)
        }
      },
      getUnmodifiedValue(control) {
        const unmodifiedControl = this.getControlObj(this.unmodifiedForm, control.name);
        if (!unmodifiedControl) {
          return null
        }

        return this.displayValue(unmodifiedControl)
      },
      priceDifference(oldValue, newValue) {
        if (!oldValue || !newValue) return ''

        const oldVal = parseFloat(oldValue);
        const newVal = parseFloat(newValue);

        if (isNaN(oldVal) || isNaN(newVal)) return ''

        const priceChange = newVal - oldVal
        if (priceChange === 0) return ''

        let priceChangeDisplay = this.formatMoney(priceChange)
        if (priceChange < 0) {
          const negativeAmount = priceChangeDisplay.substring(2)
          priceChangeDisplay = '-$' + negativeAmount
        }

        return priceChange > 0 ? '+' + priceChangeDisplay : priceChangeDisplay
      },
      formatValueForDisplay(value) {
        const val = parseFloat(value)

        if (!isNaN(val)) {
          return this.formatMoney(val)
        }

        return value
      },
      priceChangeClass(oldValue, newValue) {
        if (!oldValue || !newValue) return ''

        const oldVal = parseFloat(oldValue);
        const newVal = parseFloat(newValue);

        if (isNaN(oldVal) || isNaN(newVal)) return ''

        const priceChange = newVal - oldVal

        return priceChange > 0 ? 'price-change-positive' :
          priceChange === 0 ? '' :
            'price-change-negative';
      },
      getPriorPricing(endorsementTransaction) {
        const filteredList = this.policy.Policy.EndorsementTransactions.filter(x => x.Id != endorsementTransaction.Id && x.TransactionDate < endorsementTransaction.TransactionDate);

        if (filteredList.length === 0) return this.policy.Policy.NewBusinessTransactions[0]?.AnnualPricing

        filteredList.sort(function (a, b) {
          return new Date(b.TransactionDate) - new Date(a.TransactionDate);
        });

        return filteredList[0]?.AnnualPricing
      },
      cols(control) {
        // put custom col widths here
        if (control.name === 'ExposureTrampoline' || control.name === 'ExposureAnimals' || control.name === 'CommonlyOccurringLabel' || control.name === 'LossesInPast5Years' ||
          control.name === 'ExposureLand' || control.name === 'TotalPeril' || control.name === 'AllPerilDeductible' || control.name === 'Protections' || control.name === 'CoApplicant' ||
          control.name === 'WindHailReadOnly' || control.name === 'FloodReadOnly' || control.name === 'EarthquakeReadOnly') {
          return 12
        }

        return 6
      }
    }
  }
</script>

<style scoped lang="scss">
  @import '@/style.scss';

  .theme--light.v-card > .v-card__text,
  .theme--light.v-card > .v-card__subtitle {
    color: rgba(0, 0, 0, 0.87)
  }

  .values-changed-label {
    text-align: right;
    display: block;
    font-size: 12px;
  }

  .dfc {
    margin-bottom: 5px!important;
  }

  .price-change-positive {
    font-size: 12px;
    color: red;
  }

  .price-change-negative {
    font-size: 12px;
    color: green;
  }
</style>