<template>
  <div>
    <v-container>
      <QuoteForm :formInit="template" mode="Admin" v-if="template && templateType == 'HO'">
        <template v-slot:admin="{}">
          <div class="mb-1">
            <FormButton 
                class="admin-button"
                label='Template'
                color='white'
                type='button'
                size='large'
                icon="fas fa-tools"
                @click="toggleTemplateDrawer"
                >
            </FormButton>
            <FormButton 
                class="admin-button"
                label='Export'
                color='white'
                type='button'
                size='large'
                icon="fas fa-download"
                @click="getExport"
                >
            </FormButton>
            <br><br>
          </div>
        </template>
      </QuoteForm>

      <AdditionalForm :formInit="template" mode="Admin" v-if="template && templateType != 'HO'">
        <template v-slot:admin="{}">
            <div class="mb-1">
              <FormButton 
                  class="admin-button"
                  label='Template'
                  color='white'
                  type='button'
                  size='large'
                  icon="fas fa-tools"
                  @click="toggleTemplateDrawer"
                  >
              </FormButton>
              <FormButton 
                  class="admin-button"
                  label='Export'
                  color='white'
                  type='button'
                  size='large'
                  icon="fas fa-download"
                  @click="getExport"
                  >
              </FormButton>
            </div>
            <br><br>
        </template>
      </AdditionalForm>
    </v-container>
    

    <v-navigation-drawer
        v-model="showTemplateDrawer"
        width="800"
        temporary fixed right app
        class="pa-5">
          <div class="d-flex mb-4" style="width: 100%;">
              <h2>Quote Form Template</h2>
              <v-spacer></v-spacer>
              <v-btn icon @click="showTemplateDrawer=false" x-small>
                  <v-icon size="12" color="primary">fa-times</v-icon>
              </v-btn>
          </div>
          <MonacoEditor
              height="80%"
              language="json"
              :value="templateData"
              :options="monacoOptions"
              @change="onChangeMonaco"
          ></MonacoEditor>
          <br>

          <template v-if="invalidTemplateJSON">
            <v-alert type="error">
              Invalid Template JSON.
            </v-alert>
          </template>

          <FormButton v-bind="{label: 'Preview', color: '#00B3E3', type: 'button'}" @click="previewTemplate()"></FormButton>&nbsp;

          <!-- <v-menu :close-on-content-click="true" offset-x>
              <template v-slot:activator="{ on, attrs }">
                  <v-btn color="secondary" type="button" :disabled="invalidTemplateJSON"
                      large
                      v-bind="attrs"
                      v-on="on">
                      Save
                  </v-btn>
              </template>

              <v-card class="pa-4">
                  <p>Please confirm that you would like to apply these updates to all new submissions.</p>
                  <v-card-actions class="pb-0">
                      <v-spacer></v-spacer>
                      <v-btn text>
                          Cancel
                      </v-btn>
                      <v-btn color="secondary" @click="saveTemplate()">
                          Confirm
                      </v-btn>
                  </v-card-actions>
              </v-card>
          </v-menu>  -->

        
        

    </v-navigation-drawer>
  </div>
</template>

<script>
import QuoteForm from '@/components/views/Quote/QuoteForm.vue'
import AdditionalForm from '@/components/views/Quote/AdditionalForm.vue'
import FormButton from '@/components/form/FormButton.vue'
import { FormTemplateService } from '@/api/FormTemplateService'
import { getFormStateJSON } from '@/scripts/helper'
//@ts-ignore
import MonacoEditor from 'monaco-editor-vue';

export default {
  name: 'FormTemplate',
  components: {
    MonacoEditor,
    QuoteForm,
    AdditionalForm,
    FormButton,
  },
  data() {
    return {
      templateType: null,
      template: null,
      showTemplateDrawer: false,
      templateData: '',
      invalidTemplateJSON: false,
      monacoOptions: {
          colorDecorators: true,
          validate: true
      },
    }
  },
  methods: {
    toggleTemplateDrawer() {
        this.showTemplateDrawer = !this.showTemplateDrawer;
        
    },
    onChangeMonaco(data) {
        this.invalidTemplateJSON = false;

        try {
            JSON.parse(data)
        } catch(error) {
            this.invalidTemplateJSON = true;
            return;
        }

        this.templateData = data;
       // this.previewTemplate();        
    },
    previewTemplate() {
        this.template = null;
        this.$nextTick(() => {
          this.template = JSON.parse(this.templateData);
          this.showTemplateDrawer = false;
        });
    },
    saveTemplate() {
        this.showTemplateDrawer = false;

        FormTemplateService.put({
          templateType: this.templateType,
          body: JSON.parse(this.templateData)
        }).then((data) => {
          window.location.reload();
        })
    },
    async getExport() {
      FormTemplateService.export({
        format: 'xlsx',
        body: getFormStateJSON(this.templateType)
      }).then((result) => {
        const blob = new Blob([result.data], { type: result.headers['content-type'] })
        const url = window.URL.createObjectURL(blob);
        var link = document.createElement('a');
        link.href = url;
        link.target = '_blank';
        link.click();
      })
    }
  },
  computed: {
  },
  mounted() {
    this.$store.commit('quoteForm/clearQuoteForm');
    this.$store.commit('additionalForm/clearAdditionalForm');

    this.templateType = this.$route.query.id
    let data = getFormStateJSON(this.templateType)

    this.template = {...data};
    this.templateData = JSON.stringify(data, null, 2);
  }

}
</script>

<style scoped>
.admin-button {float: right; margin-left: 10px; vertical-align: middle;}
</style>
