<template>
  <!-- <i class="far fa-times"></i> -->
  <v-btn x-small text color="secondary">Dismiss</v-btn>
</template>

<script>
export default {
  name: 'ToastCloseButton',
  data() {
    return {}
  }
}
</script>
