import {
  Amwins_PersonalLines_Moratorium_AdminUI_Generated_Moratorium,
  Amwins_PersonalLines_Moratorium_AdminUI_Generated_Area,
  Amwins_PersonalLines_Moratorium_AdminUI_Generated_PerilType,
  Amwins_PersonalLines_Moratorium_AdminUI_Generated_Confirmation,
  Amwins_PersonalLines_Moratorium_AdminUI_Generated_MoratoriumCheckRequest,
  MoratoriumsMoratoriumBanner,
  IList,
  List,
  IListResult,
  ListResultDto,
  IPagedResult,
  PagedResultDto,
  Dictionary,
  IDictionary,
  IRequestOptions,
  IRequestConfig,
  getConfigs,
  axios,
  basePath
} from './index.defs';

export class MoratoriumService {
  /** Generate by swagger-axios-codegen */
  // @ts-nocheck
  /* eslint-disable */

  /**
   *
   */
  static getMoratoriums(
    options: IRequestOptions = {}
  ): Promise<Amwins_PersonalLines_Moratorium_AdminUI_Generated_Moratorium[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Moratorium/Moratoriums';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static banner(options: IRequestOptions = {}): Promise<MoratoriumsMoratoriumBanner> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Moratorium/Banner';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static checkIfQuoteUnderMoratorium(
    params: {
      /**  */
      submissionId?: string;
      /**  */
      selectedQuoteId?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Moratorium/CheckIfQuoteUnderMoratorium';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { submissionId: params['submissionId'], selectedQuoteId: params['selectedQuoteId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static checkIfPolicyUnderMoratorium(
    params: {
      /**  */
      policyId?: string;
      /**  */
      transactionEffectiveDate?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Moratorium/CheckIfPolicyUnderMoratorium';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { policyId: params['policyId'], transactionEffectiveDate: params['transactionEffectiveDate'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}
