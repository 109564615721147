const Environment: any = require('@/environment.json');

const env = window.location.hostname.toLowerCase()
if (env === 'localhost') {
  Environment.environment = 'localhost';
  Environment.API_HOST =  process.env.VUE_APP_PAPI ?? 'https://pl-portal-api-dev.amwins.com';
  Environment.UI_HOST = 'http://localhost:8080';
  Environment.CL_UI_HOST = 'https://digital-dev.amwins.com';
  Environment.AUTH_MODE = 'OIDC';
  Environment.SBIX_HOST = process.env.VUE_APP_SBIX ??'https://sbix-api-dev.amwins.com';
  Environment.GOOGLE_ANALYTICS_ID = 'G-P1QJJ7JM7E';
  Environment.SEASYS_PORTAL_HOST = 'http://localhost:4200';
  Environment.AVATAR_HOST = 'https://stidentityportal.blob.core.windows.net/avatar';
  Environment.CLIENT_ID = 'D428B280-9DB7-4A80-97BE-65D14CFCA70E';
  Environment.CLIENT_SECRET = '2C63030A-2850-47F1-B3E9-E803DD8BADBF';
  Environment.USEMOCK = process.env.VUE_APP_USEMOCK === 'true' ? true : false;

  Environment.STS_DOMAIN = 'https://id-nonprod.amwins.com';
  Environment.STS_TARGET_ENVIRONMENT = 'dev';
  //Environment.STS_DOMAIN = 'https://localhost:5003';
  //Environment.STS_TARGET_ENVIRONMENT = 'local';
  Environment.SERVICEBUS_SUBSCRIPTION_ID = '185d5142-c467-422e-bbf8-eaf8383f3183';
  Environment.SERVICEBUS_RESOURCE_GROUP = 'rg-orbits-dev-eastus2';
  Environment.SERVICEBUS_NAMESPACE = 'sb-orbits-dev-eastus2';
}

Environment.SBIX_EFILE_HOST = Environment.SBIX_HOST + '/efile';

export default Environment;

