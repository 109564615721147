<template>
  <div>

    <Dialog :title="jsonModalTitle" :value="showModal" @close-dialog="closeModal" width="90%">
      <template v-slot:card-text>
          <div v-if="messageDetail">
            <div v-if="modalMode == 'Exception'">
              <h4>An error occured in the euler app while indexing this message</h4>
              <p class="pt-4">{{ messageDetail }}</p>
            </div>
            <ExternalIntegrationMessage v-else-if="messageName == 'ExternalIntegrationEventV1' || messageName == 'ExternalIntegrationEvent'" :message="messageDetail" />
            <SubmissionMonaco v-else :submission="messageDetail"></SubmissionMonaco>
          </div>
          <div v-else>
            <Loading :size="64" class="text-center" /> 
          </div>
      </template>
      <template v-slot:card-actions>
          <v-btn color="secondary" text type="button" @click="closeModal">Close</v-btn>
      </template>
    </Dialog>

    <v-container>
      <h1>Message Tracing</h1>
      <v-card class="mb-4">
        <v-card-title>
          Lookup
        </v-card-title>
        <v-card-text>
          <ValidationObserver tag="span" v-slot="{}" ref="observer1">
            <v-form ref="form" @submit.prevent="lookupSubmission">
              <v-row>
                <v-col md="6"
                       lg="4">
                  <DynamicFormControl :control="{
                                                  name: 'Submission Number',
                                                  type: 'TextInput',
                                                  value: submissionNumber,
                                                  config: {
                                                      label: 'Submission Number',
                                                      rules: 'required',
                                                      col: 12,
                                                      short: true,
                                                      clearable: true,
                                                      ignoreDataServiceValue: true
                                                  }
                                                }"
                                      style="margin-top: 0px;"
                                      @change="submissionNumber = $event.value"
                                      @keyup.enter="lookupSubmission" />
                </v-col>
                <v-col md="6"
                       kg="8">
                  <FormButton label="Submit" size="x-large" @click="lookupSubmission" :disabled="loading" />
                </v-col>
              </v-row>
            </v-form>
          </ValidationObserver>
        </v-card-text>
      </v-card>

      <v-card class="mb-4" :loading="loading">
        <v-card-title>
          Trace Results
        </v-card-title>
        <v-card-text>
          <ResultsTable :tableHeaders="indexTable" :results="indexData" :loading="loading" sortBy="IngestedAt" :sortDesc="true">

            <template v-slot:IngestedAt="{ item }">
              <span>
                {{ formatDateTimeForDisplay(item.IngestedAt) }}
              </span>
            </template>

            <template v-slot:CreatedDate="{ item }">
              <span>
                {{ formatDateTimeForDisplay(item.CreatedDate, true) }}
              </span>
            </template>

            <template v-slot:MessageName="{ item }">
              <span>
                {{ item.MessageName }}
              </span>
            </template>

            <template v-slot:Channel="{ item }">
              <span>
                {{ item.Channel }}
              </span>
            </template>

            <template v-slot:Integration="{ item }">
              <span>
                {{ item.Integration }}
              </span>
            </template>

            <template v-slot:Endpoint="{ item }">
              <span>
                {{ item.Endpoint }}
              </span>
            </template>

            <template v-slot:MessageId="{ item }">
              <span>
                {{ item.MessageId }}
              </span>
            </template>

            <template v-slot:Actions="{ item }">
              <v-btn v-if="item.HadException" x-small color="red"  @click="openExceptionModal(item)">
                Read Exception
             </v-btn>   
              <v-btn v-else x-small color="secondary" @click="openJsonModal(item)">
                Message JSON
             </v-btn>
            </template>         
          </ResultsTable>
        </v-card-text>
      </v-card>

    </v-container>



  </div>
</template>

<script>
import ResultsTable from '@/components/shared/ResultsTable.vue'
import ExternalIntegrationMessage from '@/components/shared/ExternalIntegrationMessage.vue'
import FormButton from '@/components/form/FormButton.vue'
import DynamicFormControl from '@/components/form/DynamicFormControl.vue'
import Dialog from '@/components/shared/Dialog.vue'
import SubmissionMonaco from '@/components/shared/SubmissionMonaco.vue'
import { makeFilterableText, formatDateTimeForDisplay, nonDefaultDate } from '@/scripts/helper'
import { EulerService } from '@/api/EulerService'
import { SubmissionService } from '@/api/SubmissionService'

export default {
  name: 'MessageTracing',
  props:{
    submissionId: {
        type: String,
        required: false,
        default: null
      },
      messageId: {
        type: String,
        required: false,
        default: null
      }
    },
  components: {
    ResultsTable,
    FormButton,
    DynamicFormControl,
    Dialog,
    SubmissionMonaco,
    ExternalIntegrationMessage
  },
  data() {
    return {
      submissionNumber: "",
      loading: false,
      indexTable: [
        { text: "IngestedAt", value: "IngestedAt", sortable: true, filterable: false },
        { text: "CreatedDate", value: "CreatedDate", sortable: true, filterable: false },
        { text: "MessageName", value: "MessageName", sortable: true, filterable: true },
        { text: "Channel", value: "Channel", sortable: true, filterable: true },
        { text: "Integration", value: "Integration", sortable: true, filterable: true },
        { text: "Endpoint", value: "Endpoint", sortable: true, filterable: true },
        { text: "MessageId", value: "MessageId", sortable: true, filterable: true },
        { text: "Actions", value: "Actions", sortable: false, filterable: false },
      ],
      indexData: [],
      showModal: false,
      modalMode: null,
      jsonModalTitle: "",
      messageName: null,
      messageDetail: null,
      monacoOptions: {
        colorDecorators: true,
        readOnly: true
      },
    }
  },
  methods: {
    makeFilterableText,
    formatDateTimeForDisplay,
    nonDefaultDate,
    setupMocks() {
      
      //mock when in local host mode
      // if (Environment.environment == "localhost") {
      //   var MockAdapter = require("axios-mock-adapter");       

      //   var mock = new MockAdapter(AxoisInstances.bff);
        
      //   mock.onGet("/api/Submission/AccountBase")
      //     .reply(async (context) =>{
      //       const accountBase = { guid: "a29ec876-9807-41bb-b8f5-c1f96d8cd8fd", id: "SUB220004590" }
      //       console.warn("[AxiosMock](/api/Submission/AccountBase) Reponse", accountBase);
      //       return [200, accountBase];
      //     });

      //   mock.onGet("/invoke/Euler/Message/Index")
      //     .reply(async (context) =>{
      //       const index = require("@/../mocks/messages/index.json");
      //       console.warn("[AxiosMock](/invoke/Euler/Message/Index) Reponse", index);
      //       return [200, index];
      //     });
        
      //   mock.onGet("/invoke/Euler/Message")
      //     .reply(async (context) =>{
      //       const msg = require(`@/../mocks/messages/${context.params.messageId}.json`);
      //       console.warn("[AxiosMock](/invoke/Euler/Message) Reponse", msg);
      //       return [200, msg];
      //     });        

      // }
    },
    async lookupSubmission(){
      var accountBase = await SubmissionService.getAccountBase({submissionNumber: this.submissionNumber})
      this.$router.push({name:"MessageTracing_Submission", params:{submissionId: accountBase.guid}}).catch(()=>{}); //don't throw "NavigationDuplicated" error
    },
    async fetchIndex(){
      this.loading = true;
      var result = await EulerService.messageIndex({submissionId: this.submissionId});
      this.indexData = result.messages.map((r) => ({
         IngestedAt: r.ingestedAt ?? '',
         CreatedDate: r.tags?.createdDate ?? '',
         TypeName: r.typeName,
         MessageName: r.typeName.split('`')[0].split('.').pop() ?? '',
         Channel: r.tags?.channel ?? '',
         Integration: r.tags?.integration ?? '',
         Endpoint: r.tags?.endpoint ?? '',
         MessageId: r.messageId ?? '',
         HadException: r.hadException,
         ExceptionMessage: r.exceptionMessage
        }) )
      this.loading = false;
    },
    openJsonModal(item) {
      this.messageName = item.MessageName;
      this.messageDetail = null;
      this.jsonModalTitle = `${this.submissionNumber} - ${item.MessageName} - ${item.MessageId}`
      this.showModal = true;

      EulerService.messageGet({
        submissionId: this.submissionId, 
        messageId: item.MessageId, 
        typeName:  item.TypeName
      })
      .then((r) => this.messageDetail = r);
      
    },
    openExceptionModal(item) {
      this.messageName = item.MessageName;
      this.modalMode = "Exception";
      this.jsonModalTitle = `Exception - ${this.submissionNumber} - ${item.MessageName} - ${item.MessageId}`
      this.showModal = true;
      this.messageDetail = item.ExceptionMessage;      
    },
    closeModal() {
      this.showModal = false;
      this.modalMode = null;
      this.typeName = null;
      this.messageDetail = null;
    }
  },
  created() {
    this.setupMocks()
    
    //if started with sub id, backfill display number
    if(this.submissionId){
      this.loading = true;
      SubmissionService.getAccountBase({submissionId: this.submissionId}).then((r) => {
        this.submissionNumber = r.id;        
      })
      .then(this.fetchIndex()
      .then(()=> {if(this.messageId){
        const items = this.indexData.filter((i) => i.MessageId == this.messageId);
        if(items[0]){
          this.openJsonModal(items[0]);
        }
      }}));
      
    }
    
  }
}
</script>

