<template>
  <div>
    <v-container>
      <h1>Agency</h1>

      <v-card v-if="isSupport || isAdmin"
              class="pa-4">
        <div class="d-flex">
          <DynamicFormControl 
                    :control="{
                        value: selectedAgency,
                        name: 'Agency',
                        type: 'AgencyAutocomplete',
                        config: {
                            label: 'Search for Agency',
                            col: 12,
                            short: true,
                            clearable: true
                        }
                    }"
                    @change="selectedAgencyChanged($event.value)" />
        </div>
      </v-card>

      <v-card v-if="showMyAgencies" class="pa-4">
        <div class="d-flex">
          <DynamicFormControl 
                    :control="{
                        value: selectedAgency,
                        name: 'Agency',
                        type: 'Dropdown',
                        config: {
                            label: 'Selected Agency Branch',
                            col: 12,
                            short: true,
                            clearable: false,
                            options: agencies,
                            itemText: 'BranchName',
                            itemValue: 'id',
                            returnObject: true
                        }
                    }"
                    @change="selectedAgencyChanged($event.value)" />
        </div>
      </v-card>

      <v-row v-if="selectedAgency"
             class="mt-4">
        <v-col cols="12">
          <v-card class="d-flex align-content-stretch flex-grow-1">
            <div class="insured-details pa-6 pb-4 flex-grow-1">
              <h3 class="mb-3 text-bold">{{selectedAgency.OrgName}}</h3>
              <p class="mb-1">{{selectedAgency.Street1}}</p>
              <p class="mb-1">{{selectedAgency.City}}, {{selectedAgency.State}} {{selectedAgency.Postal}} {{selectedAgency.Country}}</p>
            </div>
          </v-card>
        </v-col>
      </v-row>

      <v-row v-if="selectedAgency">
        <v-col cols="12">
          <!-- <v-tabs vertical> -->
            <!--<v-tab>
              Users
            </v-tab>-->
            <!-- <v-tab>
              Reports
            </v-tab> -->
            <!--<v-tab-item>
              <AgencyUsers />
            </v-tab-item>-->
            <!-- <v-tab-item> -->
              <v-card class="pa-6">
                <h2>Reports</h2>
                <AgencyReports :orgBranchId="selectedAgency.OrgBranchId" />
              </v-card>
              <!--<AgencyReports />-->
            <!-- </v-tab-item> -->
          <!-- </v-tabs> -->
        </v-col>
      </v-row>

    </v-container>
  </div>
</template>

<script>
  import AgencyReports from '@/components/views/Agency/Reports.vue'
  //import AgencyUsers from '@/components/views/Agency/Users.vue'
  import DynamicFormControl from '@/components/form/DynamicFormControl.vue'
  import { AgencyService } from '@/api/AgencyService'

  export default {
    name: 'Agency',
    components: {
      AgencyReports,
      //AgencyUsers,
      DynamicFormControl
    },
    data() {
      return {
        selectedAgency: null,
        agencies: []
      }
    },
    watch: {
      // the below method is a fix for when a user is impersonating an agent and they refresh the page.
      // The user's profile gets wiped by the refresh before this.initialize() is called and the page is blank.
      '$store.getters.getUser'() {
        this.initialize()
      }
    },
    created() {
      this.initialize()
    },
    computed: {
      isAdmin() {
        return this.$store.getters.getUserRole == 'admin';
      },
      isSupport() {
        return this.$store.getters.getUserRole == 'support';
      },
      isAgent() {
        return this.$store.getters.getUserRole == 'agent';
      },
      user() {
        return this.$store.getters.getUser;
      },
      showMyAgencies() {
        return this.isAgent && this.agencies.length > 1;
      }
    },
    methods: {
      initialize() {
        if (this.isAgent) {
         this.getAgency();
         this.getMyAgencies();
        }
      },
      getAgency() {
        const id = this.user.profile.org_branch_id
        if (id) {
          AgencyService.get({id: id})
            .then((response) => {
              if (response) {
                this.selectedAgency = response
              }
            })
        }
      },
      getMyAgencies() {
        AgencyService.myAgencies()
        .then(response => {
          if (response) {
            this.agencies = response;
          }
        })
      },
      selectedAgencyChanged(event) {
        this.selectedAgency = event
      }
    }
  }
</script>

<style scoped lang="scss">
  @import '@/style.scss';
</style>
