<template>
  <v-container>
    <v-row class="d-flex align-center">
      <v-col md="6" cols="12">
        <h1 class="mb-0">Documents</h1>
      </v-col>
      <v-col md="6" cols="12">
        <DynamicFormControl 
            :control="{
                name: 'filterTerm',
                type: 'TextInput',
                value: filterTerm,
                config: {
                    label: 'Find a Document',
                    rules: '',
                    short: true,
                    clearable: true,
                    prependIcon: 'fas fa-search'
                }
            }"
            @change="updateFilterTerm" 
        />
      </v-col>
    </v-row>

    <v-row class="mt-0" v-if="!generalDocumentsDisplay.length && !stateDocumentsDisplay.length">
      <v-col cols="12">
        <br>
        <p>No documents found matching {{filterTerm}}.</p>
      </v-col>
    </v-row>
    <v-row class="mt-0" v-else>
      <v-tabs>
        <v-tab v-if="generalDocumentsDisplay.length > 0">
          General
        </v-tab>
        <v-tab v-if="stateDocumentsDisplay.length > 0">
          State Packets
        </v-tab>
        <v-tab-item v-if="generalDocumentsDisplay.length > 0">
          <v-row v-if="informationalDocumentsDisplay.length > 0">
            <v-col md="6" cols="12">
              <h2 class="mb-0 mt-6">Informational</h2>
            </v-col>
          </v-row>
          <v-row>
            <v-col xl="3" lg="4" md="6" cols="12" v-for="doc in informationalDocumentsDisplay" :key="doc.path">
              <v-card class="pa-3 mb-1">
                <a :href="documentPath(doc.path)" target="_blank">
                  <v-row class="py-0 d-flex align-center">
                    <v-col cols="1">
                      <i v-if="getIcon(doc.path)"
                         class="fa mr-2"
                         style="display: inline; font-size: 24px; vertical-align: middle;"
                         :class="getIcon(doc.path).icon"
                         :style="{'color': getIcon(doc.path).color}">
                      </i>
                    </v-col>
                    <v-col cols="11">
                      {{doc.name}}
                    </v-col>
                  </v-row>
                </a>
              </v-card>

            </v-col>
          </v-row>
          <v-row v-if="agencyDocumentsDisplay.length > 0">
            <v-col md="6" cols="12">
              <h2 class="mb-0 mt-4">Agency</h2>
            </v-col>
          </v-row>
          <v-row>
            <v-col xl="3" lg="4" md="6" cols="12" v-for="doc in agencyDocumentsDisplay" :key="doc.path">
              <v-card class="pa-3 mb-1">
                <a :href="documentPath(doc.path)" target="_blank">
                  <v-row class="py-0 d-flex align-center">
                    <v-col cols="1">
                      <i v-if="getIcon(doc.path)"
                         class="fa mr-2"
                         style="display: inline; font-size: 24px; vertical-align: middle;"
                         :class="getIcon(doc.path).icon"
                         :style="{'color': getIcon(doc.path).color}">
                      </i>
                    </v-col>
                    <v-col cols="11">
                      {{doc.name}}
                    </v-col>
                  </v-row>
                </a>
              </v-card>

            </v-col>
          </v-row>
          <v-row v-if="supplementalDocumentsDisplay.length > 0">
            <v-col md="6" cols="12">
              <h2 class="mb-0 mt-4">Supplemental Forms</h2>
            </v-col>
          </v-row>
          <v-row>
            <v-col xl="3" lg="4" md="6" cols="12" v-for="doc in supplementalDocumentsDisplay" :key="doc.path">
              <v-card class="pa-3 mb-1">
                <a :href="documentPath(doc.path)" target="_blank">
                  <v-row class="py-0 d-flex align-center">
                    <v-col cols="1">
                      <i v-if="getIcon(doc.path)"
                         class="fa mr-2"
                         style="display: inline; font-size: 24px; vertical-align: middle;"
                         :class="getIcon(doc.path).icon"
                         :style="{'color': getIcon(doc.path).color}">
                      </i>
                    </v-col>
                    <v-col cols="11">
                      {{doc.name}}
                    </v-col>
                  </v-row>
                </a>
              </v-card>

            </v-col>
          </v-row>
        </v-tab-item>
        <v-tab-item v-if="stateDocumentsDisplay.length > 0">
          <v-row>
            <v-col xl="3" lg="4" md="6" cols="12" v-for="doc in stateDocumentsDisplay" :key="doc.path">
              <v-card class="pa-3 mb-1">
                <a :href="documentPath(doc.path)" target="_blank">
                  <v-row class="py-0 d-flex align-center">
                    <v-col cols="1">
                      <i v-if="getIcon(doc.path)"
                         class="fa mr-2"
                         style="display: inline; font-size: 24px; vertical-align: middle;"
                         :class="getIcon(doc.path).icon"
                         :style="{'color': getIcon(doc.path).color}">
                      </i>
                    </v-col>
                    <v-col cols="11">
                      {{doc.name}}
                    </v-col>
                  </v-row>
                </a>
              </v-card>
            </v-col>
          </v-row>
        </v-tab-item>
      </v-tabs>
    </v-row>
    <br><br><br>
  </v-container>
</template>

<script>
import Environment from '@/scripts/environment'
import DynamicFormControl from '@/components/form/DynamicFormControl.vue'

const documents = [
  {
    name: 'Builders Risk Supplement Application',
    path: 'Builders Risk Supplemental Application.pdf',
    channel: 'Human',
    type: 'Supplemental',
    tab: 'General'
  },
  {
    name: 'Consumer Credit Disclosure',
    path: 'Consumer_Credit_Disclosure.pdf',
    channel: 'Human',
    type: 'Supplemental',
    tab: 'General'
  },
  {
    name: 'Excess Flood Application',
    path: 'Excess_Flood_Application.docx',
    channel: 'Human',
    type: 'Supplemental',
    tab: 'General'
  },
  {
    name: 'Homeowners Application',
    path: 'Acord-80-Homeowner-PL.pdf',
    channel: 'Human',
    type: 'Supplemental',
    tab: 'General'
  },
  {
    name: 'Landslide Supplement',
    path: 'Landslide_Supplement.docx',
    channel: 'Human',
    type: 'Supplemental',
    tab: 'General'
  },
  {
    name: 'Log Home Questionnaire',
    path: 'Log_Home_Questionnaire.pdf',
    channel: 'Human',
    type: 'Supplemental',
    tab: 'General'
  },
  {
    name: 'Secondary Modifiers Supplemental Application',
    path: 'Secondary_Modifiers_Supplemental_Application.pdf',
    channel: 'Human',
    type: 'Supplemental',
    tab: 'General'
  },
  {
    name: 'Short Term Rental Questionnaire',
    path: 'Short Term Rental Questionnaire.pdf',
    channel: 'Human',
    type: 'Supplemental',
    tab: 'General'
  },
  {
    name: 'Supplemental Corporate Named Insured Questionnaire',
    path: 'Supplemental Corporate Named Insured Questionnaire.pdf',
    channel: 'Human',
    type: 'Supplemental',
    tab: 'General'
  },
  {
    name: 'Supplemental Heating Questionnaire',
    path: 'Supplemental_Heating_Questionnaire.pdf',
    channel: 'Human',
    type: 'Supplemental',
    tab: 'General'
  },
  {
    name: 'Unprotected Rating Questionnaire',
    path: 'Unprotected Rating Questionnaire.pdf',
    channel: 'Human',
    type: 'Supplemental',
    tab: 'General'
  },
  {
    name: 'Vacant Questionnaire',
    path: 'Vacant Questionnaire.pdf',
    channel: 'Human',
    type: 'Supplemental',
    tab: 'General'
  },

  {
    name: 'Statement of Diligent Effort',
    path: 'digital/DiligentEffort.pdf',
    channel: 'Digital',
    type: 'Supplemental',
    tab: 'General'
  },
  {
    name: 'Statement of No Loss',
    path: 'digital/StatementOfNoLoss.pdf',
    channel: 'Digital',
    type: 'Supplemental',
    tab: 'General'
  },
  {
    name: 'Agent Of Record',
    path: 'digital/AgentOfRecord.pdf',
    channel: 'Digital',
    type: 'Agency',
    tab: 'General'
  },
  {
    name: 'Bank Change Request',
    path: 'digital/BankChangeRequest.pdf',
    channel: 'Digital',
    type: 'Agency',
    tab: 'General'
  },

  {
    name: 'State Packet - Alaska',
    path: 'state/State Packet - AK.pdf',
    type: 'State'
  },
  {
    name: 'State Packet - Alabama Personal Lines',
    path: 'state/State Packet - AL Personal Lines.pdf',
    type: 'State'
  },
  {
    name: 'State Packet - Alabama',
    path: 'state/State Packet - AL.pdf',
    type: 'State'
  },
  {
    name: 'State Packet - Arkansas',
    path: 'state/State Packet - AR.pdf',
    type: 'State'
  },
  {
    name: 'State Packet - Arizona',
    path: 'state/State Packet - AZ.pdf',
    type: 'State'
  },
  {
    name: 'State Packet - California',
    path: 'state/State Packet - CA.pdf',
    type: 'State'
  },
  {
    name: 'State Packet - Colorado',
    path: 'state/State Packet - CO.pdf',
    type: 'State'
  },
  {
    name: 'State Packet - Connecticut',
    path: 'state/State Packet - CT.pdf',
    type: 'State'
  },
  {
    name: 'State Packet - District of Columbia',
    path: 'state/State Packet - DC.pdf',
    type: 'State'
  },
  {
    name: 'State Packet - Delaware',
    path: 'state/State Packet - DE.pdf',
    type: 'State'
  },
  {
    name: 'State Packet - Florida',
    path: 'state/State Packet - FL.pdf',
    type: 'State'
  },
  {
    name: 'State Packet - Georgia Appendix A',
    path: 'state/State Packet - GA Appendix A.pdf',
    type: 'State'
  },
  {
    name: 'State Packet - Georgia',
    path: 'state/State Packet - GA.pdf',
    type: 'State'
  },
  {
    name: 'State Packet - Hawaii',
    path: 'state/State Packet - HI.pdf',
    type: 'State'
  },
  {
    name: 'State Packet - Iowa',
    path: 'state/State Packet - IA.pdf',
    type: 'State'
  },
  {
    name: 'State Packet - Idaho',
    path: 'state/State Packet - ID.pdf',
    type: 'State'
  },
  {
    name: 'State Packet - Illinois',
    path: 'state/State Packet - IL.pdf',
    type: 'State'
  },
  {
    name: 'State Packet - Indiana',
    path: 'state/State Packet - IN.pdf',
    type: 'State'
  },
  {
    name: 'State Packet - Kansas',
    path: 'state/State Packet - KS.pdf',
    type: 'State'
  },
  {
    name: 'State Packet - Kentucky',
    path: 'state/State Packet - KY.pdf',
    type: 'State'
  },
  {
    name: 'State Packet - Lousiana Personal Lines',
    path: 'state/State Packet - LA Personal Lines.pdf',
    type: 'State'
  },
  {
    name: 'State Packet - Louisiana',
    path: 'state/State Packet - LA.pdf',
    type: 'State'
  },
  {
    name: 'State Packet - Massachusetts',
    path: 'state/State Packet - MA.pdf',
    type: 'State'
  },
  {
    name: 'State Packet - Maryland',
    path: 'state/State Packet - MD.pdf',
    type: 'State'
  },
  {
    name: 'State Packet - Maine',
    path: 'state/State Packet - ME.pdf',
    type: 'State'
  },
  {
    name: 'State Packet - Michigan',
    path: 'state/State Packet - MI.pdf',
    type: 'State'
  },
  {
    name: 'State Packet - Minnesota',
    path: 'state/State Packet - MN.pdf',
    type: 'State'
  },
  {
    name: 'State Packet - Missouri',
    path: 'state/State Packet - MO.pdf',
    type: 'State'
  },
  {
    name: 'State Packet - Mississippi',
    path: 'state/State Packet - MS.pdf',
    type: 'State'
  },
  {
    name: 'State Packet - Montana',
    path: 'state/State Packet - MT.pdf',
    type: 'State'
  },
  {
    name: 'State Packet - North Carolina',
    path: 'state/State Packet - NC.pdf',
    type: 'State'
  },
  {
    name: 'State Packet - North Dakota',
    path: 'state/State Packet - ND.pdf',
    type: 'State'
  },
  {
    name: 'State Packet - Nebraska',
    path: 'state/State Packet - NE.pdf',
    type: 'State'
  },
  {
    name: 'State Packet - New Hampshire',
    path: 'state/State Packet - NH.pdf',
    type: 'State'
  },
  {
    name: 'State Packet - New Jersey',
    path: 'state/State Packet - NJ.pdf',
    type: 'State'
  },
  {
    name: 'State Packet - New Mexico',
    path: 'state/State Packet - NM.pdf',
    type: 'State'
  },
  {
    name: 'State Packet - Nevada',
    path: 'state/State Packet - NV.pdf',
    type: 'State'
  },
  {
    name: 'State Packet - New York',
    path: 'state/State Packet - NY.pdf',
    type: 'State'
  },
  {
    name: 'State Packet - Ohio',
    path: 'state/State Packet - OH.pdf',
    type: 'State'
  },
  {
    name: 'State Packet - Oklahoma',
    path: 'state/State Packet - OK.pdf',
    type: 'State'
  },
  {
    name: 'State Packet - Oregon',
    path: 'state/State Packet - OR.pdf',
    type: 'State'
  },
  {
    name: 'State Packet - Pennsylvania',
    path: 'state/State Packet - PA.pdf',
    type: 'State'
  },
  {
    name: 'State Packet - Rhode Island',
    path: 'state/State Packet - RI.pdf',
    type: 'State'
  },
  {
    name: 'State Packet - South Carolina',
    path: 'state/State Packet - SC.pdf',
    type: 'State'
  },
  {
    name: 'State Packet - South Dakota',
    path: 'state/State Packet - SD.pdf',
    type: 'State'
  },
  {
    name: 'State Packet - Tennessee',
    path: 'state/State Packet - TN.pdf',
    type: 'State'
  },
  {
    name: 'State Packet - Texas',
    path: 'state/State Packet - TX.pdf',
    type: 'State'
  },
  {
    name: 'State Packet - US Virgin Islands',
    path: 'state/State Packet - USVI.pdf',
    type: 'State'
  },
  {
    name: 'State Packet - Utah',
    path: 'state/State Packet - UT.pdf',
    type: 'State'
  },
  {
    name: 'State Packet - Virginia',
    path: 'state/State Packet - VA.pdf',
    type: 'State'
  },
  {
    name: 'State Packet - Vermont',
    path: 'state/State Packet - VT.pdf',
    type: 'State'
  },
  {
    name: 'State Packet - Washington',
    path: 'state/State Packet - WA.pdf',
    type: 'State'
  },
  {
    name: 'State Packet - Wisconsin',
    path: 'state/State Packet - WI.pdf',
    type: 'State'
  },
  {
    name: 'State Packet - West Virginia',
    path: 'state/State Packet - WV.pdf',
    type: 'State'
  },
  {
    name: 'State Packet - Wyoming',
    path: 'state/State Packet - WY.pdf',
    type: 'State'
  },
  {
    name: 'Flood - California - D1',
    path: 'flood/StatePacket_CA.pdf',
    type: 'State'
  },
  {
    name: 'Frequently Asked Questions',
    path: 'AmwinsIQ_PersonalLines_FAQ.pdf',
    type: 'Informational',
    tab: 'General'
  },
  {
    //If this file name is changed, update accordingly on Catastrophe Closings page
    name: 'Personal Lines Overview',
    path: 'AmwinsIQ_PersonalLines_Overview.pdf',
    type: 'Informational',
    tab: 'General'
  },
  {
    name: 'Digital Policy Resource Document',
    path: 'AmwinsIQ_PersonalLines_DigitalPolicyResources.pdf',
    type: 'Informational',
    tab: 'General'
  },
  {
    name: 'Flood Coverage Comparison',
    path: 'flood/AmwinsIQ_Flood_CoverageComparison.pdf',
    type: 'Informational',
    tab: 'General'
  },
  {
    name: 'Flood Frequently Asked Questions',
    path: 'flood/AmwinsIQ_FloodMarketplaceFAQ.pdf',
    type: 'Informational',
    tab: 'General'
  },
  {
    name: 'Flood Product Overview',
    path: 'flood/AmwinsIQ_FloodProductOverview.pdf',
    type: 'Informational',
    tab: 'General'
  },
  {
    name: 'FloodPlus Residential Guide',
    path: 'flood/AmwinsIQ_FloodPlusResidentialGuide.pdf',
    type: 'Informational',
    tab: 'General'
  },
  
];

export default {
  name: 'Documents',
  components: {
    DynamicFormControl
  },
  data() {
    return {
      documents: [],
      generalDocumentsDisplay: [],
      informationalDocumentsDisplay: [],
      agencyDocumentsDisplay: [],
      supplementalDocumentsDisplay: [],
      stateDocumentsDisplay: [],
      filterTerm: null,
      timeout: null,
      host: Environment.API_HOST
    }
  },
  methods: {
    documentPath(path) {
      return this.host + '/documents/' + path;  
    },
    getIcon(path) {
      if (!path) return null;
      path = path.toLowerCase();
      if (path.indexOf('.pdf') > -1) {
        return {color: 'red', icon: 'fa-file-pdf'};
      } else if (path.indexOf('.doc') > -1) {
        return {color: 'rgb(68, 136, 227)', icon: 'fa-file-word'};
      } else {
        return null;
      }
    },
    updateFilterTerm(event) {
      if (event) {
        this.filterTerm = event.value;
      } else {
        this.filterTerm = null;
      }

      if (this.timeout) clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.updateDocumentsDisplay();
      }, 200)
    },
    updateDocumentsDisplay() {
      if (!this.filterTerm) {
        this.generalDocumentsDisplay = [...this.documents.filter(x => x.tab === 'General')];
        this.informationalDocumentsDisplay = [...this.documents.filter(x => x.type === 'Informational')];
        this.agencyDocumentsDisplay = [...this.documents.filter(x => x.type === 'Agency')];
        this.supplementalDocumentsDisplay = [...this.documents.filter(x => x.type === 'Supplemental')];
        this.stateDocumentsDisplay = [...this.documents.filter(x => x.type === 'State')];
      }
      else {
        const f = this.filterTerm.toLowerCase();
        this.generalDocumentsDisplay = [...this.documents.filter(d => d.tab === 'General' && (d.name.toLowerCase().indexOf(f) > -1 || d.path.toLowerCase().indexOf(f) > -1))];
        this.informationalDocumentsDisplay = [...this.documents.filter(d => d.type === 'Informational' && (d.name.toLowerCase().indexOf(f) > -1 || d.path.toLowerCase().indexOf(f) > -1))];
        this.agencyDocumentsDisplay = [...this.documents.filter(d => d.type === 'Agency' && (d.name.toLowerCase().indexOf(f) > -1 || d.path.toLowerCase().indexOf(f) > -1))];
        this.supplementalDocumentsDisplay = [...this.documents.filter(d => d.type === 'Supplemental' && (d.name.toLowerCase().indexOf(f) > -1 || d.path.toLowerCase().indexOf(f) > -1))];
        this.stateDocumentsDisplay = [...this.documents.filter(d => d.type === 'State' && (d.name.toLowerCase().indexOf(f) > -1 || d.path.toLowerCase().indexOf(f) > -1))];
      }
    }
  },
  computed: {
  },
  mounted() {
    this.documents = documents.sort((a, b) => (a.name > b.name) ? 1 : -1);
    this.generalDocumentsDisplay = [...this.documents.filter(x => x.tab === 'General')];
    this.informationalDocumentsDisplay = [...this.documents.filter(x => x.type === 'Informational')];
    this.agencyDocumentsDisplay = [...this.documents.filter(x => x.type === 'Agency')];
    this.supplementalDocumentsDisplay = [...this.documents.filter(x => x.type === 'Supplemental')];
    this.stateDocumentsDisplay = [...this.documents.filter(x => x.type === 'State')];

  }
}
</script>

<style scoped lang="scss">
  @import '@/style.scss';
  a {
    text-decoration: none; 
    display: block;
    width: 100%;
  }
  a:hover {opacity: 0.8;}
</style>

