import { UserManagerSettingsStore, WebStorageStateStore, UserManager, ErrorResponse } from 'oidc-client-ts';
import Environment from '@/scripts/environment'
import store from '@/vuex/store';
import {Mutex} from 'async-mutex';
import axios from 'axios'


//async locking instance
const mutex = new  Mutex();

class AuthState {

    isInit: boolean;

    errorCount: number; //sequental error counter
    errorLimit: number; //redirect after this many sequental errors
    errorRedirectUrl: string; //redirect to this url after error limit reached

    token?: string;
    tokenParsed?: any;

    impersonationEmail?: string;
    impersonationToken?: string;
    impersonationTokenParsed?: any;
  }

interface IClientState {
    returnPath: string,
    impersonationEmail: string
}

//private state store force access via exported methods
const state : AuthState = {

    isInit: true,
    errorCount: 0,

    errorLimit: 5,
    errorRedirectUrl: "https://www.amwins.com/quote-online",

    //jwt of current user
    token: null,
    tokenParsed: null,

    ///jwt of spoofed user
    impersonationEmail: sessionStorage.getItem('impersonationEmail'),
    impersonationToken: null,
    impersonationTokenParsed: null
}

const settings = new UserManagerSettingsStore({
    userStore: new WebStorageStateStore({ store: window.localStorage }),
    authority: Environment.STS_DOMAIN,
    client_id: Environment.CLIENT_ID,
    redirect_uri: Environment.UI_HOST + "/callback",
    automaticSilentRenew: false,
    silent_redirect_uri: Environment.UI_HOST + "/silent-renew.html",
    response_type: 'code',
    accessTokenExpiringNotificationTimeInSeconds: 30,
    scope: 'openid profile personal-lines',
    post_logout_redirect_uri: Environment.UI_HOST + "/signout-callback.html",
    filterProtocolClaims: true,
    extraTokenParams: { targetEnvironment: Environment.STS_TARGET_ENVIRONMENT }
})

const mgr = new UserManager(settings)


function parseJwt(token: any) {
    if (!token) {
        return null
    }
    const base64Url = token.split('.')[1]
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
    }).join(''))

    return JSON.parse(jsonPayload)
}

function jwtToUser(jwt: any) {
    if (!jwt) {
        return null
    }

    return {
        access_token: '',
        expires_at: jwt.exp,
        profile: {
            amr: jwt.amr,
            appList: "userAppList",
            auth_time: jwt.auth_time,
            branch_id: jwt.branch_id,
            'delegate-idp': jwt['delegate-idp'],
            given_name: jwt.given_name,
            groupId: jwt.groupId,
            'http://schemas.microsoft.com/ws/2008/06/identity/claims/role': jwt['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'],
            'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress': jwt['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress'],
            'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name': jwt['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name'],
            'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/surname': jwt['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/surname'],
            idp: jwt.idp,
            org_branch_id: jwt.org_branch_id,
            org_id: jwt.org_id,
            partition: jwt.partition,
            person_id: jwt.person_id,
            s_hash: jwt.s_hash,
            sid: jwt.sid,
            sub: jwt.sub,
            user_id: jwt.user_id
        },
        scope: jwt.scope.join(' '),
        token_type: 'Bearer'
    }
}

// Handle silent renew manually since automatic silent renew is garbage
mgr.events.addAccessTokenExpiring(async function () {
    await acquireAccessToken();   
});

// Handle scenario where manual silent renew fails
mgr.events.addAccessTokenExpired(async function () {
    await acquireAccessToken();   
});


export async function getTokenDataAsync(ignoreImpersonation = false) : Promise<any>
{
    await  getTokenAsync(ignoreImpersonation);
    if(ignoreImpersonation) return state.tokenParsed;
    return state.impersonationTokenParsed ?? state.tokenParsed;
}

export async function isImpersonating(): Promise<boolean>
{
    return state.impersonationToken != null;
}

export async function getTokenAsync(ignoreImpersonation = false) : Promise<string>
{
    while(!isUserAuthenticated())
    {          
        await acquireAccessToken();   
    }
    
    if(ignoreImpersonation) return state.token;
    return state.impersonationToken ?? state.token;
}

export async function waitForAuthenticationAsync()
{
    await getTokenAsync();
}

export function isUserAuthenticated(): boolean
{
    if (state.tokenParsed) {
        const now = Math.ceil(Date.now() / 1000)
        return now < state.tokenParsed.exp
    }
    return false;
}

export function tabReactivated() {

    console.log("[Auth] tabReactivated");
    if (state.tokenParsed) {
        const now = Math.ceil(Date.now() / 1000)
        const earlyExpire = state.tokenParsed.exp - 300; //only force renew if there are less than 5 mins left 
        if(now > earlyExpire)
        {
            acquireAccessToken();
        }
    }
    
}

export async function acquireAccessToken() {   

    if(mutex.isLocked()){
        console.log("[Auth] acquireAccessToken: waiting");
        await mutex.waitForUnlock();
    } else 
    {
        console.log("[Auth] acquireAccessToken: executing");
        await mutex.runExclusive(async () => {
            const returnPath : string = window.location.pathname + window.location.search;

            await mgr.signinSilent({state: {returnPath: returnPath}})
                .then(async (user) => {
                state.token = user.access_token;
                state.tokenParsed = state.token ? parseJwt(state.token) : null;

                //console.debug("[Auth] state.tokenParsed?.partition?.trim():", state.tokenParsed?.partition?.trim());

                if(!state.tokenParsed?.partition?.trim() && state.tokenParsed?.role != 'none')
                {
                    throw new Error('partition unexpectedly undefined, null or empty');
                }

                //check if we need to cascade into our impersonation
                if(state.impersonationEmail)
                {
                    await acquireImpersonationToken(state.token, state.impersonationEmail);
                }
                
                //setup current user store for app
                if(state.impersonationEmail)
                {
                    store.commit('setCurrentUser', jwtToUser(state.impersonationTokenParsed));
                    store.commit('setCurrentParsedToken', state.impersonationTokenParsed);
                    console.log(`[Auth] acquireAccessToken: complete ${state.tokenParsed.email} -> ${state.impersonationTokenParsed.email}`);
                }
                else
                {
                    store.commit('setCurrentUser', user);
                    store.commit('setCurrentParsedToken', state.tokenParsed);
                    console.log(`[Auth] acquireAccessToken: complete ${state.tokenParsed.email}`);
                }

                //run any first time auth actions
                if(state.isInit)
                {
                    store.dispatch('initUser');
                    state.isInit = false;
                }

                //reset error count
                state.errorCount = 0;

            })
            .catch(async (error) => {
                state.token = null;
                state.tokenParsed = null;

                //limit recurring errors
                if(state.errorCount++ > state.errorLimit)
                {
                    console.error(`[Auth] error limit reached, redirecting to ${state.errorRedirectUrl}`);
                    window.location.href = state.errorRedirectUrl;
                    return;
                }

                if(!error || Object.keys(error).length === 0)
                {
                    console.log('[Auth] client not yet initalized, retrying...');
                }
                if(error?.error == 'login_required')
                {
                    await mgr.signinRedirect({state: {returnPath: returnPath}});
                }
                if(error?.message == 'partition unexpectedly undefined, null or empty')
                {
                    console.error(`[Auth] ${error?.message}`);
                    await mgr.signinRedirect({state: {returnPath: returnPath}});
                }
                else
                {
                    console.error('[Auth] unexpected error in acquireAccessToken:', error);
                }
                
                await new Promise(r => setTimeout(r, 2000)); //error cooldown timeout
            });
        });
    }   
}

///returns redirect path
export async function signinRedirectCallback() : Promise<string>
{
    console.log("[Auth] signinRedirectCallback");
    
    const user = await mgr.signinRedirectCallback();
    
    const parsedToken = parseJwt(user.access_token);
    const now = Math.ceil(Date.now() / 1000)
    
    if(!parsedToken) throw({error:'empty_token'});
    
    if(Math.abs(now - parsedToken.iat) > 300) throw({error:'clock_sync'});

    let returnToUrl : string = '/';
    if (user.state !== undefined) { 
        const clientState = user.state as IClientState;
        returnToUrl = clientState.returnPath; 
        if(!returnToUrl || returnToUrl.includes('callback')){
            returnToUrl = '/'; 
        }
    }
    return returnToUrl;

} 

export function signoutRedirect() : Promise<void>
{
    console.log("[Auth] signoutRedirect");
    return mgr.signoutRedirect();
}

export function removeUser() : Promise<void>
{
    console.log("[Auth] removeUser");
    store.dispatch('setCurrentUser', null);
    store.commit('setCurrentParsedToken', null);
    return mgr.removeUser();
}

export async function beginImpersonation(email : string)
{
    //check our current status
    await mutex.acquire();
    if(state.impersonationEmail){
        mutex.release();
        throw({error:'exisiting_impersonation'});
    }
    state.impersonationEmail = email;
    sessionStorage.setItem('impersonationEmail', email);
    mutex.release();

    await acquireAccessToken();    
    
    //force reload page, todo: fix this: store.dispatch('refreshProfile');
    location.reload();

}

export async function endImpersonation()
{
    //check our current status
    await mutex.acquire();
    sessionStorage.removeItem('impersonationEmail');
    state.impersonationEmail = null;
    state.impersonationToken = null;
    state.impersonationTokenParsed = null;
    mutex.release();
    
  await acquireAccessToken();  

    //force reload page, todo: fix this: store.dispatch('refreshProfile');
    location.reload();

}

async function acquireImpersonationToken(token: string, email: string)
{
    if(!email) {
        sessionStorage.removeItem('impersonationEmail');
        state.impersonationEmail = null;
        state.impersonationToken = null;
        state.impersonationTokenParsed = null;
        return false;
    }

    const safeEmail = encodeURIComponent(email);
    const qs = `client_id=${settings.client_id}&grant_type=impersonation-delegation&token=${token}&impersonationEmail=${safeEmail}&targetEnvironment=${Environment.STS_TARGET_ENVIRONMENT}`

    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': `Bearer ${token}`
    }

    const http = axios.create({
        baseURL: settings.authority
      })
    
    return await http.post(`${Environment.STS_DOMAIN}/connect/token`, qs, {
        headers: headers
    })
    .then((response) => {

        if(response?.data?.access_token)
        {
            sessionStorage.setItem('impersonationEmail', email);
            state.impersonationEmail = email;
            state.impersonationToken = response.data.access_token;
            state.impersonationTokenParsed = parseJwt(state.impersonationToken);
            mutex.release();
            console.log('[Auth] acquireImpersonationToken (Success) user:', email);
            return true;
        }
        else
        {
            sessionStorage.removeItem('impersonationEmail');
            state.impersonationEmail = null;
            state.impersonationToken = null;
            state.impersonationTokenParsed = null;
            console.error('[Auth] acquireImpersonationToken (Failed) user:', email, response);
            return false;
        }
    
    });
}