<template>

  <v-footer padless
            fixed
            class="endorsement-footer">

    <v-container>
      <v-row v-if="endorsement && endorsement.Endorsement.Status.Value === 'Rejected'">
        <v-col cols="12" class="pb-0 pt-4">
          <v-alert type="error" outlined class="mb-0"><strong>Endorsement Rejected</strong> - This change is not supported at this time. Please email your underwriter with any questions or proceed with a cancellation.</v-alert>
        </v-col>
      </v-row>
      <v-row class="d-flex align-center">
        <v-col cols="12" sm="6">

          <v-row>
            <v-col cols="6" sm="6" md="5" lg="3">
              <ReadOnlyDisplay v-if="showNewPremium" label="New Total Premium">
                <template v-if="loadingPremium" slot="content">
                  <Loading :size="24" />
                </template>
                <template v-else slot="content">
                  <label class="text-bold d-inline-block">{{formatMoney(newPremium, true)}}</label>
                  &nbsp;&nbsp;
                  <span class="premium-change">{{premiumChangeDisplay}}</span>
                </template>
              </ReadOnlyDisplay>
              <ReadOnlyDisplay v-else label="New Total Premium">
                <template slot="content">
                  <Loading v-if="loading" :size="24" />
                  <span v-else class="premium-change">
                    Click Rate to see new Premium!
                  </span>
                </template>
              </ReadOnlyDisplay>
            </v-col>

            <v-col class="d-none d-sm-flex justify-center" cols="1">
              <v-divider vertical></v-divider>
            </v-col>

            <v-col cols="6" sm="5">
              <ReadOnlyDisplay label="Current Premium">
                <template slot="content">
                  <label class="d-inline-block">{{formatMoney(premium, true)}}</label>
                </template>
              </ReadOnlyDisplay>
            </v-col>
          </v-row>

        </v-col>

        <v-col cols="12" sm="6" :align="documentButtonAlignment">
          <v-btn :disabled="loading"
                color="secondary"
                type="button"
                @click="save"
                class="mr-2"
                outlined>
            Save
          </v-btn>

          <v-btn v-if="page == 1"
                :disabled="loading || blockRating"
                color="secondary"
                type="button"
                @click="rate"
                class="mr-2"
                outlined>
            Rate
          </v-btn>

          <v-btn v-if="page == 1"
                :disabled="loading || !endorsement || endorsement.Endorsement.Status.Value === 'Rejected' || !readyForSubmit"
                color="secondary"
                type="submit"
                @click="next">
            Next
          </v-btn>
          <v-tooltip v-else right :disabled="!isRiskUnderMoratorium">
            <template v-slot:activator="{ on }">
              <span v-on="on">
                <v-btn
                    :disabled="loading || !endorsement || 
                    !readyForSubmit ||
                    isRiskUnderMoratorium"
                    color="secondary"
                    type="submit"
                    @click="submit">
                  Submit Endorsement
                </v-btn>
              </span>
            </template>
            <span>This endorsement cannot be issued because there is a moratorium in the area of the risk.</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </v-container>

  </v-footer>

</template>

<script>
  import ReadOnlyDisplay from '@/components/form/ReadOnlyDisplay.vue'
  import { formatMoney } from '@/scripts/helper'
  import {  isRiskUnderMoratorium, isRiskUnderMoratoriumForPolicy } from '@/scripts/moratoriums'
  import { isPremiumBearingEndorsement } from '@/scripts/endorsements'

  export default {
    name: 'EndorsementFooter',
    components: {
      ReadOnlyDisplay
    },
    props: {
      page: {
        type: Number,
        default: 1
      },
      premium: {
        type: Number,
        default: null
      },
      newPremium: {
        type: Number,
        default: null
      },
      rated: {
        type: Boolean,
        default: false
      },
      loading: {
        type: Boolean,
        default: false
      },
      endorsement: {
        type: Object,
        default: null
      },
      blockRating: {
        type: Boolean,
        default: false
      },
      policyId: {
        type: String,
        default: ''
      },
      isV2SalesChannel: {
        type: Boolean,
        default: false
      },
    },
    emits: [
      'next',
      'rate',
      'save',
      'submit'
    ],
    data() {
      return {
        isRiskUnderMoratorium: false
      }
    },
    computed: {
      loadingPremium() {
        if (this.loading || this.newPremium == 0) {
          return true
        }
        if (!this.endorsement?.Endorsement) {
          return false
        }
        return !(this.rejectedEndorsement || this.readyForSubmit)
      },
      rejectedEndorsement() {
        return this.endorsement?.Endorsement.Status.Value === 'Rejected' ?? false
      },
      readyForSubmit() {
        return this.endorsement?.Endorsement.Status.Value === 'ReadyForSubmit' ?? false
      },
      showNewPremium() {
        const shouldShow = this.rated || this.page == 2
        return shouldShow
      },
      premiumChangeDisplay() {
        if (!this.newPremium || !this.premium) return ''

        const premiumChange = this.newPremium - this.premium
        if (premiumChange === 0) return ''

        let premiumChangeDisplay = this.formatMoney(premiumChange, true)
        if (premiumChange < 0) {
          const negativeAmount = premiumChangeDisplay.substring(2)
          premiumChangeDisplay = '-$' + negativeAmount
        }

        return premiumChange > 0 ? '+' + premiumChangeDisplay : premiumChangeDisplay
      },
      documentButtonAlignment() {
        switch (this.$vuetify.breakpoint.name) {
          case 'xs': return 'left'
          default: return 'right'
        }
      }
    },
    methods: {
      formatMoney,
      save() {
        this.$emit('save')
      },
      next() {
        this.$emit('next')
      },
      rate() {
        this.$emit('rate')
      },
      submit() {
        this.$emit('submit')
      }
    },
    watch: {
      endorsement: {
        async handler() {
          // TODO: remove the '!this.isV2SalesChannel' condition and build out the moratorium check for v2 policy endorsements
          if (!this.isV2SalesChannel && isPremiumBearingEndorsement(this.endorsement)) {
            this.isRiskUnderMoratorium = await isRiskUnderMoratoriumForPolicy(this.policyId, this.endorsement.Endorsement.EndorsementEffectiveDate);
          }
        }
      }
    }
  }
</script>

<style scoped lang="scss">
  @import '@/style.scss';

  .endorsement-footer {
    background-color: #fbfdff;
    border-top: 1px solid lightgrey
  }

  .premium-change {
    font-size: 12px;
  }
</style>