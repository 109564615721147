import {
  Amwins_Portal_Api_Controllers_AmlinkSubmissionFileController_SubmissionFileIdRef,
  IList,
  List,
  IListResult,
  ListResultDto,
  IPagedResult,
  PagedResultDto,
  Dictionary,
  IDictionary,
  IRequestOptions,
  IRequestConfig,
  getConfigs,
  axios,
  basePath
} from './index.defs';

export class AmlinkSubmissionFileService {
  /** Generate by swagger-axios-codegen */
  // @ts-nocheck
  /* eslint-disable */

  /**
   *
   */
  static getSubmissionFileId(
    params: {
      /**  */
      submissionId?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Amwins_Portal_Api_Controllers_AmlinkSubmissionFileController_SubmissionFileIdRef> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/AmlinkSubmissionFile/GetSubmissionFileId';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { submissionId: params['submissionId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static putSubmissionFileId(
    params: {
      /**  */
      submissionId?: string;
      /**  */
      body?: Amwins_Portal_Api_Controllers_AmlinkSubmissionFileController_SubmissionFileIdRef;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/AmlinkSubmissionFile/PutSubmissionFileId';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);
      configs.params = { submissionId: params['submissionId'] };
      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}
