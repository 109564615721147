var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-container',[_c('h1',[_vm._v("Account Teleporter")]),_c('v-card',{staticClass:"mb-4"},[_c('v-card-title',[_vm._v(" Upstream Account ")]),_c('v-card-text',[_c('ValidationObserver',{ref:"observer1",attrs:{"tag":"span"},scopedSlots:_vm._u([{key:"default",fn:function({}){return [_c('v-form',{ref:"form"},[_c('v-row',[_c('v-col',{attrs:{"md":"6","lg":"4"}},[_c('DynamicFormControl',{staticStyle:{"margin-top":"0px"},attrs:{"control":{
                                      name: 'Account',
                                      type: 'TextInput',
                                      config: {
                                          label: 'Account Id',
                                          rules: _vm.idRules,
                                          col: 12,
                                          short: true,
                                          clearable: true
                                      }
                                    }},on:{"change":function($event){_vm.accountId = $event.value}}})],1),_c('v-col',{attrs:{"md":"6","kg":"8"}},[_c('FormButton',{attrs:{"label":"Submit","size":"x-large","disabled":_vm.loading},on:{"click":_vm.fetchAccount}})],1)],1)],1)]}}])})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }