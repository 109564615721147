<template>
    <div>
        <v-card v-if="underwriter">
            <Avatar :src="getUserAvatarPath(underwriter.Email)" color="white" :alt="underwriter.Name" class="avatar" />
            <h3>
                Your Amwins Underwriter

                <v-btn icon router-link to="/profile" title="My Profile" class="ml-1" small color="secondary" style="position: relative; top: -3px;">
                    <i class="fas fa-pen"></i>
                </v-btn>
            </h3>
            <p>{{underwriter.Name}}</p>
            <div class="d-flex align-center">
                <span>{{underwriter.Phone}}</span>
                <v-spacer></v-spacer>
                <span><a :href="'mailto:' + underwriter.Email" style="word-break: break-all;">{{underwriter.Email}}</a></span>
            </div>
        </v-card>
    </div>
</template>

<script>
import Avatar from '@/components/shared/Avatar.vue'
import { getUserAvatarPath } from '@/scripts/helper'

export default {
    name: 'UnderwriterCard',
    components: {
        Avatar
    },
    methods: {
        getUserAvatarPath
    },
    data() {
        return {
            underwriter: null
        }
    },
    mounted() {
        this.$store.watch(
            () => {
                this.underwriter = this.$store.getters.getDefaultUnderwriter
            },
            () =>  { },
            {
                deep: true,
                immediate: true
            }
        );
    },

}
</script>

<style scoped lang="scss">
    @import '@/style.scss';

    .v-card {
        padding: 20px 20px 20px 50px;
        position: relative;
    }

    .v-card .avatar {
        position: absolute;
        top: -10px;
        left: -10px;
    }

    h3 {
        color: $amwins-blue;
        margin-bottom: 10px;
        font-weight: bold;
    }

    p {margin-bottom: 0;}

    a {
        color: $amwins-secondary-medium-blue;
        text-decoration: none;
    }

    ::v-deep .spacer {min-width: 20px;}
</style>