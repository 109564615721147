<template>
  <v-dialog
    v-model="open"
    persistent
    max-width="800px"
  >
    <v-card class="pa-4">

      <v-card-title class="pa-0 pb-4">
        <h3>Crop Image</h3>
        <v-spacer></v-spacer>

        <v-btn color="secondary" type="button" icon small @click="emitClose">
          <v-icon>fa-times</v-icon>
        </v-btn>
      </v-card-title>

      <div v-if="imgSrc">
        <img id="cropper" :src="imgSrc" style="width: 100%; height: 500px;" />
      </div>

      <v-row class="mt-1">
        <v-col md="6" cols="12">
            <v-btn large icon color="secondary" @click="zoomIn">
              <i class="fas fa-search-plus" style="font-size: 20px;"></i>
            </v-btn>
            <v-btn large icon color="secondary" @click="zoomOut">
              <i class="fas fa-search-minus" style="font-size: 20px;"></i>
            </v-btn>
            <v-btn large icon color="secondary" @click="rotateLeft">
              <i class="fas fa-undo" style="font-size: 20px;"></i>
            </v-btn>
            <v-btn large icon color="secondary" @click="rotateRight">
              <i class="fas fa-undo" style="font-size: 20px; transform: scaleX(-1);"></i>
            </v-btn>
        </v-col>
        <v-col md="6" cols="12" class="text-right">
            <v-btn large color="secondary" text @click="emitClose" class="mr-2">
              Cancel
            </v-btn>
            <v-btn large color="secondary" @click="exportPhoto">
              Upload Cropped Photo
            </v-btn>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import 'cropperjs/dist/cropper.css';
import Cropper from 'cropperjs';

export default {
  name: 'CropperDialog',
  data() {
    return {
      open: true,
      imgSrc: null,
      cropper: null,
    }
  },
  props: {
    file: {
      type: File,
      default: null,
    },
    aspectRatio: {
      type: Number,
      default: null
    },
    autoCropArea: {
      type: Number,
      default: null
    }
  },
  emits: [
    'emitPhoto',
    'close'
  ],
  methods: {
    exportPhoto() {
      const name = this.file.name.split('.')[0] + '.jpg';

      const b = this.cropper.getCroppedCanvas({
        fillColor: '#ffffff'
      }).toDataURL('image/jpeg');

      const arr = b.split(',');
      const mime = arr[0].match(/:(.*?);/)[1];

      const byteString = atob(b.split(',')[1]);
      const ab = new ArrayBuffer(byteString.length);
      const ia = new Uint8Array(ab);

      for (var i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i);
      }
      const newFile = new Blob([ab], { type: mime });
      newFile.lastModifiedDate = new Date();
      newFile.name = name;

      this.emitPhoto(newFile);
    },
    emitPhoto(newFile) {
      this.$emit('emitPhoto', newFile);
    },
    emitClose() {
      this.$emit('close');
    },
    zoomIn() {
      this.cropper.zoom(0.1);
    },
    zoomOut() {
      this.cropper.zoom(-0.1);
    },
    rotateLeft() {                
      this.cropper.rotate(-90);
    },
    rotateRight() {
      this.cropper.rotate(90);
    }
  },
  mounted() {
    if (!this.file) return;

    if (this.cropper) {
      this.cropper.destroy();
    }

    this.imgSrc = null;

    this.imgSrc = URL.createObjectURL(this.file);

    setTimeout(() => {
      const img = document.getElementById('cropper');
      this.cropper = new Cropper(img, {
          viewMode: 0,
          //checkCrossOrigin: false,
          minContainerHeight: 500,
          zoomable: true,
          dragMode: 'move',
          autoCropArea: this.autoCropArea,
          aspectRatio: this.aspectRatio,
      });
    }, 100)
  }
}
</script>

<style scoped>
  ::v-deep .cropper-bg {
    background-repeat: repeat!important;
  }
</style>