import Vue from 'vue';
//@ts-ignore
import dateformat from 'dateformat'
import { PolicyType, TempFileHeader, SBIXCityStateZip} from '../api/index.defs';
import { StateNames } from './options';
import store, { setControlOnFormData } from '@/vuex/store'
import Environment from './environment';

export const DEFAULT_TITLE = 'Amwins IQ';

export const isRequired = (rules: string) => {
    const r = rules.split("|");
    if (r.indexOf('required') > -1) return true; 
    return false;
}

export const createStateForm = (form: any) => {
    return JSON.parse(JSON.stringify(form));
}

export const newGuid = (): string => {
          //df208a21-da9c-487a-bd3c-6a7f4daa6321
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        const r = Math.random()*16|0, v = c == 'x' ? r : (r&0x3|0x8);
        return v.toString(16);
    });
  }

export const getDaysBetweenDates = (startDate: Date, endDate: Date) => {
    const diff = endDate.getTime() - startDate.getTime();
    return Math.floor(diff / 86400000);
}

export function makeFilterableText(data: any, field: String | null) {
    if (!field && data) {
        const values = Object.values(data);
        let str = '';
        values.forEach(v => {
            str += v + ' ';
        })

        return str
    }
    else {
        if (!data) {
            return ''
        } else if (!data[`${field}`]) {
            return '';
        }

        return data[`${field}`];
    }
}

export function makeStatusFilterable(begin: Date, end: Date, type: PolicyType) {
    const termBegin = new Date(begin);
    const termEnd = new Date(end);
    const today = new Date();

    if (type == 'Policy') {
        const active = termBegin < today && termEnd > today;
        const expires = getDaysBetweenDates(today, termEnd);

        if (active && expires < 30) {
            return 'Active (Expires in ' + expires + ' days)';
        } else if (active) {
            return 'Active';
        } else if (termEnd < today) {
            return 'Prior Term';
        } else if (today < termBegin) {
            return 'Upcoming';
        }
    } else if (type == 'Quote') {
        if (today < termBegin) {
            return 'Active';
        } else {
            return 'Expired';
        }
    }
}

export function formatDateForDisplay(date: Date, utc: boolean = null) {
    if (!date) return ''; 
    if (new Date(date) < new Date('1/1/1900')) return '';
    return dateformat(date, 'mm/dd/yyyy', utc)
}

export function formatDateTimeForDisplay(date: Date, addSeconds: boolean = false) {
    if (!date) return ''
    if (addSeconds) return dateformat(date, 'mm/dd/yyyy h:MM:ss TT')
    return dateformat(date, 'mm/dd/yyyy h:MM TT')
}

export function nonDefaultDate(date: Date) {
    if (!date) {
        return false
    }

    return new Date(date).getFullYear() !== 1
}


export const googleMapsService = {
    googleMapsKey: 'AIzaSyDCyDXkgW2KmTgIJqGevVwWcMA8DGbcZAA',

    getGoogleResultStreetNumber(result: any) {
        return result.address_components.filter((a: any) => a.types.indexOf("street_number") > -1)[0]?.long_name || '';
    },
    
    getGoogleResultStreetName(result: any) {
        return result.address_components.filter((a: any) => a.types.indexOf("route") > -1)[0]?.short_name || '';
    },
    getGoogleResultCity(result: any) {
        if (result.address_components.filter((a: any) => a.types.indexOf("locality") > -1).length > 0) {
          return result.address_components.filter((a: any) => a.types.indexOf("locality") > -1)[0]?.long_name;
        } else if (result.address_components.filter((a: any) => a.types.indexOf("sublocality") > -1).length > 0) {
          return result.address_components.filter((a: any) => a.types.indexOf("sublocality") > -1)[0]?.long_name;
        } else if (result.address_components.filter((a: any) => a.types.indexOf("neighborhood") > -1).length > 0) {
          return result.address_components.filter((a: any) => a.types.indexOf("neighborhood") > -1)[0]?.long_name;
        } else if (result.address_components.filter((a: any) => a.types.indexOf("administrative_area_level_3") > -1).length > 0) {
          return result.address_components.filter((a: any) => a.types.indexOf("administrative_area_level_3") > -1)[0]?.long_name ;
        } else {
          return "";
        }
    },
    getGoogleResultState(result: any) {
        return result.address_components.filter((a: any) => a.types.indexOf("administrative_area_level_1") > -1)[0]?.short_name || '';
    },
    getGoogleResultPostalCode(result: any) {
        return result.address_components.filter((a: any) => a.types.indexOf("postal_code") > -1)[0]?.long_name || '';
    },
    getGoogleResultCountryCode(result: any) {
        return result.address_components.filter((a: any) => a.types.indexOf("country") > -1)[0]?.short_name || '';
    },
    getGoogleResultCounty(result: any) {
        return result.address_components.filter((a: any) => a.types.indexOf("administrative_area_level_2") > -1)[0]?.long_name.split(" County").join('') || '';
    },
    geocode(address: string): any {
        address = address.split('#').join('UNIT ');
        address = address.split('  ').join(' ');
        address = address.split(' ').join('+');
        address = encodeURI(address);

        return new Promise((resolve, reject) => {
            Vue.prototype.$httpExternal.get('https://maps.googleapis.com/maps/api/geocode/json?address=' + address + '&key=' + this.googleMapsKey).then((response: any) => {
                resolve(response)
            }, (error: any) => {
                reject(error);
            });
        });
    },
    reverseGeocodeCoordinates(lat: number, lng: number) {
        return new Promise((resolve, reject) => {
            Vue.prototype.$httpExternal.get('https://maps.googleapis.com/maps/api/geocode/json?latlng=' + lat + ',' + lng + '&key=' + this.googleMapsKey).then((response: any) => {
                resolve(response)
            }, (error: any) => {
                reject(error);
            });
        });
    },
    getAddressByAdvancedSearch(streetNumber: string, streetName: string, city: string, state: string, zip: string) {
        let address = '';
        if (streetNumber) address += streetNumber + ' ';
        if (streetName) address += streetName + ', ';   
        if (city) address += city + ', ';
        if (state) address += state + ' ';
        if (zip) address += zip + ' ';
        
        //address += " USA";
        
        address = address.trim();
        return this.geocode(address);
      }
}

export const calculateTotalPremium = (pricing: any) => {
  if (!pricing || !pricing.Premium) return 0

  const annualPremium = pricing.Premium
  let fees = 0;
  if (pricing.Fee != null) {
    fees = pricing.Fee
  } else if (pricing.Fees?.length > 0) {
    pricing.Fees?.forEach((fee: any) => {
      fees += fee.Amount
    })
  }

  let totalAssessments = 0;

  pricing.Assessments?.forEach((assessment: any) => {
    totalAssessments += assessment.Amount
  })

  const totalPremium = annualPremium + fees + totalAssessments;
  return totalPremium
}

export const getControlObj = (form: any, name: string): any => {
    let obj;
    try {
        form?.steps?.forEach((step: any) => {
            step.sections?.forEach((section: any) => {
                section.controls?.forEach((control: any) => {
                    if (control.name == name) obj = control;
                });
            });
        });
    } catch(e) {
        console.log(`getControlObj(${form}, ${name}): unhandled exception`, e);
    }
    return obj;
}

export const getControlObjFromV2DataPath = (form: any, v2DataPath: string): any => {
    let obj;
    try {
        form.steps?.forEach((step: any) => {
            step.sections?.forEach((section: any) => {
                section.controls?.forEach((control: any) => {
                    if (control.v2DataPath == v2DataPath) obj = control;
                });
            });
        });
    } catch(e) {
        console.log(`getControlObjFromV2DataPath(${form}, ${v2DataPath}): unhandled exception`, e);
    }
    return obj;
}

export const getNonNullAliasValue = (form: any, aliasName: string): any => {
    let value = null
    form.steps?.forEach((step: any) => {
        step.sections?.forEach((section: any) => {
            section.controls?.forEach((control: any) => {
                if (control.alias == aliasName && control.value) {
                    value = control.value;
                }
            });
        });
    });
    return value
}

export const getNonNullAliasObj = (form: any, aliasName: string): any => {
    let value = null
    form.steps?.forEach((step: any) => {
        step.sections?.forEach((section: any) => {
            section.controls?.forEach((control: any) => {
                if (control.alias == aliasName && control.value) {
                    value = control;
                }
            });
        });
    });
    return value
}

export const getAliasControlObj = (form: any, aliasName: string): any => {
    let obj;
    try {
        form.steps?.forEach((step: any) => {
            step.sections?.forEach((section: any) => {
                section.controls?.forEach((control: any) => {
                    if (control.alias == aliasName) obj = control;
                });
            });
        });
    } catch(e) {
        console.log(form, name, e);
    }
    return obj;
}

export const getControlObjByNameOrAlias = (form: any, name: string): any => {
    const objects: any[] = [];
    try {
        form.steps.forEach((step: any) => {
        if (step.sections) {
            step.sections.forEach((section: any) => {
                if (section.controls) {
                    section.controls.forEach((control: any) => {
                        if (control.name == name || control.alias == name) objects.push(control);
                    });
                }
            });
        }
        });
    } catch(e) {
        console.log(e)
    }

    if (objects.length == 1) {
        return objects[0];
    } else if (objects.length > 1) {
        const obj = objects.find(o => o.value !== undefined && o.value !== null);
        if (obj) return obj;
        else return objects[0];
    } else {
        return null;
    }
}


export const createCityStateZipObject = (city: string, stateCode: string, zip: string): SBIXCityStateZip => {

    if (!city || !stateCode || !zip) return null;

    let stateName = "";
    const s = StateNames.find(s => s.abbreviation == stateCode);
    if (s) {
        stateName = s.name;
    }

    const result: SBIXCityStateZip = {
        Name: city + ", " + stateName + " " + zip,
        AddressCityName: city,
        AddressStateName: stateName,
        AddressStateKey: stateCode,
        AddressPostalCodeName: zip
    }

    return result;
}

export const formatMoney = (n: any, forceDecimal: boolean = false) => {
    if ((typeof n !== 'number')) return n
    let result =  '$' + n.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
    if (!forceDecimal) {
        result = result.replace('.00', '');
    }
    return result;
}

export const getUserAvatarPath = (email: string) => {
    if (!email) return null;
    return Environment.AVATAR_HOST + '/' + (email ? email : '').toLowerCase() + '.jpeg';
}

const CryptoJS = require("crypto-js");
export const MD5 = (v: string) => {
    return CryptoJS.MD5(v).toString();
}

export const dataURLtoFile = (dataurl: string, filename: string) => {
 
    const arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
        
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    
    return new File([u8arr], filename, {type:mime});
}
export const resizeImage = (file: any) => {
    return new Promise((resolve, reject) => {
        if(file.type.match(/image.*/)) {
            const reader = new FileReader();
    
            reader.onload = function (readerEvent) {
                const image: any = new Image();
                image.onload = function () {
    
                    const canvas = document.createElement('canvas');
                    const max_size = 1000;
                    let width = image.width;
                    let height = image.height;
                    if (width > height) {
                        if (width > max_size) {
                            height *= max_size / width;
                            width = max_size;
                        }
                    } else {
                        if (height > max_size) {
                            width *= max_size / height;
                            height = max_size;
                        }
                    }
                    canvas.width = width;
                    canvas.height = height;
                    canvas.getContext('2d').drawImage(image, 0, 0, width, height);
                    const dataUrl = canvas.toDataURL('image/jpeg');
                    const name = file.name.split('.')[0] + '.jpg';
                    const resizedImage = dataURLtoFile(dataUrl, name);
                    resolve(resizedImage);
                }
                image.src = readerEvent.target.result;
            }
    
            reader.readAsDataURL(file);
        }
        else {
            resolve(file);
        }
     });    
};

export const HighProfileOccupations: string[] = [
    'Celebrity'
];

export const sanitizeAddress = (text: string)  => {
    return trim(text);

}

//Parallels rules in dbo.fnString_Is_EFileName and dbo.fnString_Is_HierarchyName
export const sanitizeFileName = (name: string) => {
    // \/:*?"<>|+
    return name.split("\\").join("-")
      .split("/").join("-")
      .split(":").join("-")
      .split("*").join("-")
      .split("?").join("-")
      .split("\"").join("-")
      .split("'").join("-")
      .split("<").join("-")
      .split(">").join("-")
      .split("|").join("-")
      .split("%").join("-")
      .split(",").join("-")
      .split("#").join("-")
      .split("&").join("-")
      .split("+").join("-")
      .split("@").join("-");

  }

export const sanitizeSeek = (text: string) => {
    if (!text) return text;
    text = trim(text);
    text = text.split('/').join(' ').split('+').join(' ');
    return text;
}

export const encodeSeek = (text: string) => {
    if (!text) return text;
    text = trim(text);
    text = encodeURIComponent(text);
    return text;
}

export const trim = (text: string) => {
    if (!text) return text;
    text = text.trim();
    text = text.replace(/[\r\n\t]/g, " ");
    return text;
}

export const setControlDataServiceValue = (module: string, controlName: string, dataServiceValue: string, dataServiceType: string) => {

    const form = store.getters.getForm(module);
    const controlObj = getControlObj(form, controlName);

    if(!controlObj){
        console.error(`setControlDataServiceValue(module:${module}, controlName:${controlName}, dataServiceValue:${dataServiceValue}, dataServiceType:${dataServiceType}): invalid control`);
        return;
    }

    Vue.set(controlObj, 'DataServiceType', dataServiceType);
    Vue.set(controlObj, 'DataServiceValue', dataServiceValue);

    if (!dataServiceType) return;

    if (!controlObj.config || (controlObj.config && !controlObj.config.ignoreDataServiceValue)) {
        if (controlObj.value === null || controlObj.value === undefined || controlObj.type === "Hidden") {
            Vue.set(controlObj, 'value', dataServiceValue);
            setControlOnFormData(form.data, controlObj);
        }
    }
}

export const getSalesChannelLogo = (salesChannel: string, market: string, floodTFIAFeatureFlagEnabled: boolean = false) => {
    let path = '';
    switch(salesChannel) {
        case 'TestV0': path = 'TestV0.png'; break;
        case 'Human': path = 'amwins_logo--access.svg'; break;
        case 'TFIA': path = 'TFIA-logo_full-color_with_tag.png'; break;
        case 'Digital': path = 'amwins_logo--digital.svg'; break;
        case 'Munich': path = 'munich-re.svg'; break;
        case 'Vave': path = 'vave.jpg'; break;
        case 'VavePersonalLines': path = 'vave.jpg'; break;
        case 'Hiscox': path = 'hiscox.png'; break;
        case 'Chubb': path = 'chubb.png'; break;
      default: path = ''; break;
  }
    return '/images/logos/' + path;
}

export const downloadTempFile = (data: TempFileHeader) => {
    const baseURL = Vue.prototype.$http.defaults.baseURL;
    const path = baseURL + '/api/TempFile/' + data.FileName;

    Vue.prototype.$http.get(path, { responseType: 'arraybuffer' })
    .then((result: any) => {
        if (result) {
            const blob = new Blob([result.data], { type: result.headers['content-type'] })
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a')
            link.href = url;
            link.download = sanitizeFileName(data.DisplayName);
            link.click();
            window.URL.revokeObjectURL(url);
        }
    })
}

export const validateEmail = (email: string) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };


export const submissionIsExpired = (submission: any) => {
    if (submission) {

        const effDate =
            submission?.Data?.EffectiveDate?.value ??
            submission?.CreatedAt;

        //if we cannot find a 
        if (!effDate) return true;

        const maxDays = Environment.SUBMISSION_EXPIRATION_DAYS;
        const today = new Date();
        const effectiveDate = new Date(effDate);

        const daysBetween = getDaysBetweenDates(effectiveDate, today);
        if (daysBetween > maxDays) {
            return true
        }
        return false;
    }
    return false;
}

export const getFormStateJSON = (formStateType: string) => {
    let parentFormState = require(`@/config/${formStateType}.json`)
    return JSON.parse(JSON.stringify(parentFormState))
}

export const showSection = (module: string, section: any) => {
    const show = store.getters.showSection(module, section);
    let toggle = true;
    if (section.toggle) {
        const toggleStr = section.toggle[0] === '!' ? section.toggle.substring(1) : section.toggle;
        const toggleVal = store.getters.isFeatureFlagEnabled(toggleStr);
        toggle = section.toggle[0] === '!' ? !toggleVal : toggleVal;
    }
    return show && toggle;
}