<template>
  <div>
    <v-container>
      <h1>Graph Demo</h1>

      <v-chart class="chart" :option="option" autoresize />

    </v-container>
  </div>
</template>

<script>
import { use } from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { GraphChart } from 'echarts/charts';
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
} from 'echarts/components';
import VChart, { THEME_KEY } from 'vue-echarts';
import { ref, defineComponent } from 'vue';

use([
  CanvasRenderer,
  GraphChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
]);

export default defineComponent({
  name: 'GraphDemo',
  components: {
    VChart,
  },
  provide: {
    [THEME_KEY]: 'light',
  },
  setup() {
    const option = ref( {
  // title: {
  //   text: 'Basic Graph'
  // },
  tooltip: {},
  animationDurationUpdate: 1500,
  animationEasingUpdate: 'quinticInOut',
  series: [
    {
      type: 'graph',
      layout: 'force',
      force:{
        repulsion: 2000,
      },
      symbolSize: 50,
      roam: true,
      label: {
        show: true
      },
      edgeSymbol: ['circle', 'arrow'],
      edgeSymbolSize: [4, 10],
      edgeLabel: {
        fontSize: 20
      },
  "data": [
    {
      "id": "SubmissionGatedV1",
      "name": "SubmissionGatedV1"
    },
    {
      "id": "QuotePricedV1",
      "name": "QuotePricedV1"
    },
    {
      "id": "QuoteEligibilityUpdatedV1",
      "name": "QuoteEligibilityUpdatedV1"
    },
    {
      "id": "QuotePlacementUpdatedV1",
      "name": "QuotePlacementUpdatedV1"
    },
    {
      "id": "CalculateQuoteFeeV1",
      "name": "CalculateQuoteFeeV1"
    },
    {
      "id": "QuoteFeeCalculatedV1",
      "name": "QuoteFeeCalculatedV1"
    },
    {
      "id": "CalculateQuoteTaxesV1",
      "name": "CalculateQuoteTaxesV1"
    },
    {
      "id": "QuoteTaxCalculatedV1",
      "name": "QuoteTaxCalculatedV1"
    },
    {
      "id": "CreateQuoteApplicationDocumentV1",
      "name": "CreateQuoteApplicationDocumentV1"
    },
    {
      "id": "CreateQuoteFormBundleDocumentV1",
      "name": "CreateQuoteFormBundleDocumentV1"
    },
    {
      "id": "SaveQuoteDocumentV1+Application",
      "name": "SaveQuoteDocumentV1+Application"
    },
    {
      "id": "SaveQuoteDocumentV1+FormBundle",
      "name": "SaveQuoteDocumentV1+FormBundle"
    },
    {
      "id": "QuoteDocumentSavedV1+Application",
      "name": "QuoteDocumentSavedV1+Application"
    },
    {
      "id": "QuoteDocumentSavedV1+FormBundle",
      "name": "QuoteDocumentSavedV1+FormBundle"
    },
    {
      "id": "QuoteStateUpdatedV1",
      "name": "QuoteStateUpdatedV1"
    }
  ],
  "links": [
    {
      "source": "SubmissionGatedV1",
      "target": "QuotePricedV1"
    },
    {
      "source": "SubmissionGatedV1",
      "target": "QuoteEligibilityUpdatedV1"
    },
    {
      "source": "SubmissionGatedV1",
      "target": "QuotePlacementUpdatedV1"
    },
    {
      "source": "QuotePricedV1",
      "target": "CalculateQuoteFeeV1"
    },
    {
      "source": "CalculateQuoteFeeV1",
      "target": "QuoteFeeCalculatedV1"
    },
    {
      "source": "QuoteFeeCalculatedV1",
      "target": "CalculateQuoteTaxesV1"
    },
    {
      "source": "CalculateQuoteTaxesV1",
      "target": "QuoteTaxCalculatedV1"
    },
    {
      "source": "QuoteStateUpdatedV1",
      "target": "CreateQuoteApplicationDocumentV1"
    },
    {
      "source": "QuoteStateUpdatedV1",
      "target": "CreateQuoteFormBundleDocumentV1"
    },
    {
      "source": "CreateQuoteApplicationDocumentV1",
      "target": "SaveQuoteDocumentV1+Application"
    },
    {
      "source": "CreateQuoteFormBundleDocumentV1",
      "target": "SaveQuoteDocumentV1+FormBundle"
    },
    {
      "source": "SaveQuoteDocumentV1+Application",
      "target": "QuoteDocumentSavedV1+Application"
    },
    {
      "source": "SaveQuoteDocumentV1+FormBundle",
      "target": "QuoteDocumentSavedV1+FormBundle"
    },
    {
      "source": "QuotePricedV1",
      "target": "QuoteStateUpdatedV1"
    },
    {
      "source": "QuoteEligibilityUpdatedV1",
      "target": "QuoteStateUpdatedV1"
    },
    {
      "source": "QuotePlacementUpdatedV1",
      "target": "QuoteStateUpdatedV1"
    },
    {
      "source": "QuoteFeeCalculatedV1",
      "target": "QuoteStateUpdatedV1"
    },
    {
      "source": "QuoteTaxCalculatedV1",
      "target": "QuoteStateUpdatedV1"
    },
    {
      "source": "QuoteDocumentSavedV1+Application",
      "target": "QuoteStateUpdatedV1"
    },
    {
      "source": "QuoteDocumentSavedV1+FormBundle",
      "target": "QuoteStateUpdatedV1"
    }
  ],
      lineStyle: {
        opacity: 0.9,
        width: 2,
        curveness: 0
      }
    }
  ]
}
    );

    return { option };
  },
});
</script>

<style scoped>
.chart {
  height: 100vh;
}
</style>
