<template>
  <div>
    <v-container>
      <h1>{{gateData ? gateData.SalesChannel : ''}}</h1>
      <h3>Gate Rules</h3>
      <br>
      <v-card class="pa-4">
        <ValidationObserver tag="span" v-slot="{}" ref="observer">
          <!-- {{errors}}<br>
          {{openRules}}<br>
          {{openChecks}} -->
          <form @submit.prevent="validate()">
            <div v-if="gateData">

              <h3>Rules</h3>
              <br>

              <v-expansion-panels multiple v-model="openRules">
                <v-expansion-panel v-for="(rule, ruleIndex) in gateData.Rules" :key="ruleIndex">
                  <v-expansion-panel-header>
                    <h4>
                      <v-tooltip v-if="rule.frontendOrder > minFrontendRuleOrder && rule.Name" bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn color="secondary" type="button" icon x-small height="20" width="20" @click.native.stop="moveRuleUp(rule)"
                                 v-bind="attrs"
                                 v-on="on">
                            <v-icon>fa-arrow-up</v-icon>
                          </v-btn>
                        </template>
                        <span>Move Up</span>
                      </v-tooltip>
                      <span v-else width="20" class="ml-2">
                        &nbsp;
                      </span>

                      <v-tooltip v-if="rule.frontendOrder < maxFrontendRuleOrder && rule.Name" bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn color="secondary" type="button" icon x-small height="20" width="20" @click.native.stop="moveRuleDown(rule)" class="ml-2"
                                 v-bind="attrs"
                                 v-on="on">
                            <v-icon>fa-arrow-down</v-icon>
                          </v-btn>
                        </template>
                        <span>Move Down</span>
                      </v-tooltip>
                      <span v-else width="20" class="ml-2">
                        &nbsp;
                      </span>

                      <span v-if="rule.Name" class="ml-3">{{rule.Name}}</span>
                      <em v-else class="ml-5">New Rule</em>

                      <v-menu :close-on-content-click="true"
                              offset-x>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn color="#9F2242" type="button" icon x-small height="20" width="20"
                                 v-bind="attrs"
                                 v-on="on"
                                 class="ml-5">
                            <v-icon>fa-times</v-icon>
                          </v-btn>
                        </template>

                        <v-card class="pa-4">
                          <p>Are you sure you want to delete this rule?</p>

                          <v-card-actions class="pb-0">
                            <v-spacer></v-spacer>
                            <v-btn text>
                              Cancel
                            </v-btn>
                            <v-btn color="secondary" @click.stop="deleteRule(ruleIndex)">
                              Yes
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-menu>

                    </h4>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content class="px-6">
                    <v-row>
                      <v-col md="6" cols="12">
                        <DynamicFormControl :control="{
                                                        name: 'Rule' + ruleIndex + '_Name',
                                                        type: 'TextInput',
                                                        value: rule.Name,
                                                        config: {
                                                            label: 'Name',
                                                            rules: 'required',
                                                            col: 12
                                                        }
                                                    }"
                                            @change="rule.Name = $event.value" />
                      </v-col>
                      <v-col md="6" cols="12">
                        <DynamicFormControl :control="{
                                                        name: 'Rule' + ruleIndex + '_Result',
                                                        type: 'Dropdown',
                                                        value: rule.VerdictOnMatch,
                                                        config: {
                                                            label: 'Result',
                                                            rules: 'required',
                                                            col: 12,
                                                            options: VerdictOptions
                                                        }
                                                    }"
                                            @change="rule.VerdictOnMatch = $event.value" />
                      </v-col>
                      <v-col md="12" cols="12">
                        <DynamicFormControl :control="{
                                                        name: 'Rule' + ruleIndex + '_ExclusionMessage',
                                                        type: 'FormTextArea',
                                                        value: rule.ExclusionMessage,
                                                        config: {
                                                            label: 'Exclusion Message',
                                                            rules: '',
                                                            col: 12,
                                                            maxlength: 0,
                                                            counter: false,
                                                            short: true
                                                        }
                                                    }"
                                            @change="rule.ExclusionMessage = $event.value" />
                      </v-col>
                      <!--<v-col md="2" cols="12">
                          <DynamicFormControl
                              :control="{
                                  name: 'Rule' + ruleIndex + '_Order',
                                  type: 'TextInput',
                                  value: rule.Order,
                                  config: {
                                      label: 'Order',
                                      rules: 'required',
                                      col: 12,
                                      currency: true,
                                      allowNegative: false,
                                      default: 0
                                  }
                              }"
                              @change="rule.Order = $event.value"
                          />
                      </v-col>-->
                    </v-row>

                    <br>
                    <h4>Checks</h4>
                    <br>

                    <v-expansion-panels multiple v-model="openChecks[ruleIndex]">
                      <v-expansion-panel v-for="(check, checkIndex) in rule.Checks" :key="checkIndex">
                        <v-expansion-panel-header>
                          <p class="ma-0">
                            <v-tooltip v-if="check.frontendOrder > getMinCheckFrontendOrder(ruleIndex) && check.Path" bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn color="secondary" type="button" icon x-small height="20" width="20" @click.native.stop="moveCheckUp(ruleIndex, check)"
                                       v-bind="attrs"
                                       v-on="on">
                                  <v-icon>fa-arrow-up</v-icon>
                                </v-btn>
                              </template>
                              <span>Move Up</span>
                            </v-tooltip>
                            <span v-else width="20" class="ml-2">
                              &nbsp;
                            </span>

                            <v-tooltip v-if="check.frontendOrder < getMaxCheckFrontendOrder(ruleIndex) && check.Path" bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn color="secondary" type="button" icon x-small height="20" width="20" @click.native.stop="moveCheckDown(ruleIndex, check)" class="ml-2"
                                       v-bind="attrs"
                                       v-on="on">
                                  <v-icon>fa-arrow-down</v-icon>
                                </v-btn>
                              </template>
                              <span>Move Down</span>
                            </v-tooltip>
                            <span v-else width="20" class="ml-2">
                              &nbsp;
                            </span>

                            <span v-if="check.Path" class="ml-3">{{check.Path}}</span>
                            <em v-else>New Check</em>

                            <v-menu :close-on-content-click="true"
                                    offset-x>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn color="#9F2242" type="button" icon x-small height="20" width="20"
                                       v-bind="attrs"
                                       v-on="on"
                                       class="ml-5">
                                  <v-icon>fa-times</v-icon>
                                </v-btn>
                              </template>

                              <v-card class="pa-4">
                                <p>Are you sure you want to delete this check?</p>

                                <v-card-actions class="pb-0">
                                  <v-spacer></v-spacer>
                                  <v-btn text>
                                    Cancel
                                  </v-btn>
                                  <v-btn color="secondary" @click.stop="deleteCheck(ruleIndex, checkIndex)">
                                    Yes
                                  </v-btn>
                                </v-card-actions>
                              </v-card>
                            </v-menu>

                          </p>

                        </v-expansion-panel-header>
                        <v-expansion-panel-content class="px-6">
                          <v-row>
                            <v-col md="12" cols="12">
                              <DynamicFormControl :control="{
                                                                    name: 'Rule' + ruleIndex + '_Check' + checkIndex + '_Path',
                                                                    type: 'TextInput',
                                                                    value: check.Path,
                                                                    config: {
                                                                        label: 'Path',
                                                                        rules: 'required',
                                                                        col: 12
                                                                    }
                                                                }"
                                                  @change="check.Path = $event.value" />
                            </v-col>
                            <!--<v-col md="2" cols="12">
                              <DynamicFormControl :control="{
                                                                    name: 'Rule' + ruleIndex + '_Check' + checkIndex + '_Order',
                                                                    type: 'TextInput',
                                                                    value: check.Order,
                                                                    config: {
                                                                        label: 'Order',
                                                                        rules: 'required',
                                                                        col: 12,
                                                                        currency: true,
                                                                        allowNegative: false,
                                                                        default: 0
                                                                    }
                                                                }"
                                                  @change="check.Order = $event.value" />
                            </v-col>-->
                          </v-row>
                          <v-row>
                            <v-col md="4" cols="12">
                              <DynamicFormControl :control="{
                                                                    name: 'Rule' + ruleIndex + '_Check' + checkIndex + '_ComparatorType',
                                                                    type: 'Dropdown',
                                                                    value: check.ComparatorType,
                                                                    config: {
                                                                        label: 'Comparator Type',
                                                                        rules: 'required',
                                                                        col: 12,
                                                                        options: ComparatorTypeOptions
                                                                    }
                                                                }"
                                                  @change="check.ComparatorType = $event.value; check.Comparator = null; check.OptionalDefaultValueWhenNull = null;" />
                            </v-col>
                            <v-col md="4" cols="12">
                              <DynamicFormControl :control="{
                                                                    name: 'Rule' + ruleIndex + '_Check' + checkIndex + '_Comparator',
                                                                    type: 'Dropdown',
                                                                    value: check.Comparator,
                                                                    config: {
                                                                        label: 'Comparator',
                                                                        rules: 'required',
                                                                        col: 12,
                                                                        options: check.ComparatorType == 'Numeric' ? NumericComparatorOptions : StringComparatorOptions
                                                                    }
                                                                }"
                                                  @change="updateComparator(check, $event.value);" />
                            </v-col>
                            <v-col md="4" cols="12">
                              <div v-if="check.Comparator && check.Comparator.indexOf('List') > -1">
                                <DynamicFormControl :control="{
                                                                            name: 'Rule' + ruleIndex + '_Check' + checkIndex + '_ExpectedValue',
                                                                            type: 'ComboboxMultiple',
                                                                            value: Array.isArray(check.ExpectedValue) ? check.ExpectedValue : [],
                                                                            config: {
                                                                                label: 'Value',
                                                                                rules: 'required|min_length:1',
                                                                                col: 12,
                                                                                multiple: true,
                                                                                options: []
                                                                            }
                                                                        }"
                                                    @change="check.ExpectedValue = $event.value" />
                              </div>
                              <div v-if="check.Comparator && check.Comparator.indexOf('List') == -1">
                                <DynamicFormControl :control="{
                                                                            name: 'Rule' + ruleIndex + '_Check' + checkIndex + '_ExpectedValue',
                                                                            type: 'TextInput',
                                                                            value: check.ExpectedValue[0] || '',
                                                                            config: {
                                                                                label: 'Value',
                                                                                rules: 'required',
                                                                                col: 12,
                                                                                currency: check.ComparatorType == 'Numeric'
                                                                            }
                                                                        }"
                                                    @change="check.ExpectedValue = [String($event.value)]" />
                              </div>

                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col md="4" cols="12">
                              <DynamicFormControl :control="{
                                                                    name: 'Rule' + ruleIndex + '_Check' + checkIndex + '_HasOptionalDefaultValueWhenNull',
                                                                    type: 'Checkbox',
                                                                    value: check.HasOptionalDefaultValueWhenNull,
                                                                    config: {
                                                                        label: 'Has Default Value?',
                                                                        rules: '',
                                                                        col: 12
                                                                    }
                                                                }"
                                                  @change="check.HasOptionalDefaultValueWhenNull = $event.value; check.OptionalDefaultValueWhenNull = null" />
                            </v-col>
                            <v-col md="4" cols="12" v-if="check.HasOptionalDefaultValueWhenNull && check.ComparatorType">
                              <DynamicFormControl :control="{
                                                                    name: 'Rule' + ruleIndex + '_Check' + checkIndex + '_OptionalDefaultValueWhenNull',
                                                                    type: 'TextInput',
                                                                    value: check.OptionalDefaultValueWhenNull,
                                                                    config: {
                                                                        label: 'Default Value',
                                                                        rules: '',
                                                                        col: 12,
                                                                        currency: check.ComparatorType == 'Numeric',
                                                                        default: check.ComparatorType == 'Numeric' ? 0 : '',
                                                                        defaultValue: check.ComparatorType == 'Numeric' ? 0 : null,
                                                                    }
                                                                }"
                                                  @change="check.OptionalDefaultValueWhenNull = $event.value" />
                            </v-col>
                          </v-row>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>

                    <br>
                    <FormButton v-bind="{label: 'Add Check', color: '#00B3E3', type: 'button'}" @click="addCheck(ruleIndex)"></FormButton>

                  </v-expansion-panel-content>

                </v-expansion-panel>
              </v-expansion-panels>

              <br>
              <FormButton v-bind="{label: 'Add Rule', color: '#00B3E3', type: 'button'}" @click="addRule()"></FormButton>
              <br>
              <br>
              <br>

              <v-row>
                <v-col md="6" cols="12">
                  <DynamicFormControl :control="{
                                            name: 'DefaultVerdict',
                                            type: 'Dropdown',
                                            value: gateData.DefaultVerdict,
                                            config: {
                                                label: 'Default Result',
                                                rules: 'required',
                                                col: 12,
                                                options: VerdictOptions
                                            }
                                        }"
                                      @change="gateData.DefaultVerdict = $event.value" />
                </v-col>
                <v-col md="12" cols="12">
                  <DynamicFormControl :control="{
                                            name: 'ExclusionMessage',
                                            type: 'FormTextArea',
                                            value: gateData.ExclusionMessage,
                                            config: {
                                                label: 'Exclusion Message',
                                                rules: '',
                                                col: 12,
                                                maxlength: 0,
                                                counter: false,
                                                short: true
                                            }
                                        }"
                                      @change="gateData.ExclusionMessage = $event.value" />
                </v-col>
              </v-row>

              <br>
            </div>

            <FormButton v-bind="{label: 'Save', color: 'secondary', type: 'button'}" @click="validate()"></FormButton>
          </form>
        </ValidationObserver>
      </v-card>

      <br><br>
      <template v-if="gateData">
        <h3>Test Gate</h3>
        <br>
        <v-card class="pa-8">
          <v-row>
            <v-col md="6" cols="12">
              <h4 class="pb-2">Submission JSON:</h4>
              <MonacoEditor height="400"
                            language="json"
                            :value="testSubmissionData"
                            :options="monacoOptions"
                            @change="onChangeMonaco"></MonacoEditor>
            </v-col>
            <v-col md="6" cols="12">
              <h4 v-if="testResult">Include: <strong>{{testResult.BasicResponse.Include}}</strong></h4>
              <MonacoEditor v-if="testResult"
                            height="400"
                            language="json"
                            :value="testResultFullResult"
                            :options="{readOnly: true}"></MonacoEditor>
            </v-col>
            <v-col cols="12">
              <br><br>
              <FormButton v-bind="{label: 'Run Test', color: '#00B3E3', type: 'button'}" @click="testGate()"></FormButton>
            </v-col>
          </v-row>
        </v-card>
        <br>
        <br>
      </template>
    </v-container>
  </div>
</template>

<script>
  import Vue from 'vue'
  import DynamicFormControl from '@/components/form/DynamicFormControl.vue'
  import FormButton from '@/components/form/FormButton.vue'
  import { GateService } from '@/api/GateService'
  import { GateRuleNumericComparator, GateRuleStringComparator, GateRuleComparatorType, GateRules_EnumsDefaultSalesChannelVerdict} from '@/api/index.defs'
  import MonacoEditor from 'monaco-editor-vue';


  export default {
    name: 'Gate',
    components: {
      DynamicFormControl,
      FormButton,
      MonacoEditor
    },
    data() {
      return {
        response: null,
        gateData: null,
        VerdictOptions: [],
        ComparatorTypeOptions: [],
        StringComparatorOptions: [],
        NumericComparatorOptions: [],

        errorControlNames: [],

        openRules: [],
        openChecks: [[]],

        monacoOptions: {},
        testSubmissionData: '',
        testResult: null,
        testResultFullResult: null,
      }
    },
    methods: {
      validate() {

        //this.$refs['observer_channel_0'][0].validate()
        const observer = this.$refs.observer;

        observer.validate()
          .then((valid) => {

            this.errorControlNames = [];
            Object.keys(observer.errors).forEach((c) => {
              if (observer.errors[c].length > 0) {
                this.errorControlNames.push(c)
              }
            });

            this.initializeOpenArrays();

            const reorderRules = []
            this.gateData.Rules.forEach((rule) => {
              const reorderedRule = rule
              reorderedRule.Order = rule.frontendOrder
              rule.Checks.forEach((check) => {
                const reorderedCheck = check
                reorderedCheck.Order = check.frontendOrder
              });
              reorderRules.push(reorderedRule)
            });
            this.gateData.Rules = reorderRules

            this.gateData.Rules.forEach((rule, ruleIndex) => {
              if (this.errorControlNames.filter(i => i.indexOf('Rule' + ruleIndex + '_') > -1).length > 0) {
                if (this.openRules.indexOf(ruleIndex) == -1) this.openRules.push(ruleIndex);
              }

              rule.Checks.forEach((check, checkIndex) => {
                if (this.errorControlNames.filter(i => i.indexOf('Rule' + ruleIndex + '_Check' + checkIndex + '_') > -1).length > 0) {
                  if (this.openChecks[ruleIndex].indexOf(checkIndex) == -1) this.openChecks[ruleIndex].push(checkIndex);
                }
              });

            });

            if (valid) {
              this.onSubmit();
            }
          });
      },
      onSubmit() {
        const obj = { ...this.gateData };
        obj.Rules.forEach((rule) => {
          rule.Checks.forEach((check) => {
            if (check.HasOptionalDefaultValueWhenNull === false) {
              check.OptionalDefaultValueWhenNull = null;
            }
            delete check.HasOptionalDefaultValueWhenNull;
          })
        })

        GateService.upsertSalesChannelDefinition({ body: obj })
          .then((data) => {
            if (data) console.log(data);
            this.$store.dispatch('toastSuccess', { Message: 'Sales Channel has been saved.' });
            this.loadGate();
          });
      },
      updateComparator(check, value) {

        check.Comparator = null;
        this.$nextTick(() => {
          check.Comparator = value;
          this.onComparatorChange(check);
        });
      },
      onComparatorChange(check) {
        if (check.Comparator && check.Comparator.indexOf('InList') == -1) {
          if (check.ExpectedValue && check.ExpectedValue.length > 0 && Array.isArray(check.ExpectedValue)) {
            check.ExpectedValue = [check.ExpectedValue[0]];
          } else {
            check.ExpectedValue = [];
          }
        }
      },
      addRule() {
        this.gateData.Rules.push({
          Name: null,
          VerdictOnMatch: null,
          ExclusionMessage: null,
          Checks: [],
          Order: this.maxFrontendRuleOrder + 1,
          frontendOrder: this.maxFrontendRuleOrder + 1
        });
        this.openRules.push(this.gateData.Rules.length - 1);
        this.openChecks.push([]);
      },
      addCheck(ruleIndex) {
        const maxCheckFrontendOrder = this.getMaxCheckFrontendOrder(ruleIndex)
        this.gateData.Rules[ruleIndex].Checks.push({
          ComparatorType: null,
          Comparator: null,
          Path: null,
          ExpectedValue: [],
          Order: maxCheckFrontendOrder + 1,
          frontendOrder: maxCheckFrontendOrder + 1,
          OptionalDefaultValueWhenNull: null,
          HasOptionalDefaultValueWhenNull: false
        });
        this.openChecks[ruleIndex].push(this.gateData.Rules[ruleIndex].Checks.length - 1);
      },
      forceUpdate() {
        this.$forceUpdate();
      },
      deleteRule(ruleIndex) {
        this.gateData.Rules.splice(ruleIndex, 1);
        this.openRules = [];
      },
      deleteCheck(ruleIndex, checkIndex) {
        this.gateData.Rules[ruleIndex].Checks.splice(checkIndex, 1);
        this.openChecks[ruleIndex] = [];
      },
      initializeOpenArrays() {
        let openRules = [];
        let openChecks = [];

        this.gateData.Rules.forEach(() => {
          openChecks.push([]);
        })

        this.openRules = openRules;
        this.openChecks = openChecks;

      },
      onChangeMonaco(data) {
        this.testSubmissionData = data;
        this.testResult = null;
        this.testResultFullResult = null;
      },
      testGate() {
        let validJSON = true;

        try {
          JSON.parse(this.testSubmissionData)
        } catch (error) {
          console.error(error);
          validJSON = false;
        }

        if (!validJSON) {
          this.$store.dispatch('toastError', { Message: 'Invalid Submission JSON' });
          return;
        }

        GateService.testAGate({
          body: {
            Submission: JSON.parse(this.testSubmissionData),
            GateDefinition: this.gateData
          }
        }).then((data) => {
          console.log(data);
          this.testResult = data;
          this.testResultFullResult = JSON.stringify(data.FullResult, null, 2);
        })
      },
      loadGate() {
        const id = this.$route.query.id;
        GateService.getSalesChannelGateById({ id: id })
          .then((data) => {

            data.Rules.forEach((rule) => {
              rule.Checks.sort((a, b) => (a.Order > b.Order) ? 1 : -1);

              rule.Checks.forEach((check) => {
                check.HasOptionalDefaultValueWhenNull = check.OptionalDefaultValueWhenNull !== null;
              })
            });
            data.Rules.sort((a, b) => (a.Order > b.Order) ? 1 : -1)

            this.response = data;
            this.gateData = { ...this.response };

            let ruleFrontendOrder = 0
            let checkFrontendOrder = 0
            this.gateData.Rules.forEach((x) => {
              x.frontendOrder = ruleFrontendOrder
              x.Checks.forEach((y) => {
                y.frontendOrder = checkFrontendOrder
                checkFrontendOrder++
              })
              ruleFrontendOrder++
              checkFrontendOrder = 0
            })

            this.initializeOpenArrays();

          })
      },
      moveRuleUp(rule) {
        let movedRule = this.gateData.Rules.find((x) => x.Name == rule.Name)
        if (movedRule == null) {
          movedRule = this.gateData.Rules.find((x) => x.frontendOrder == rule.frontendOrder)
        }
        this.gateData.Rules = this.gateData.Rules.filter((x) => x.Name !== movedRule.Name)
        this.gateData.Rules.find((x) => x.frontendOrder == movedRule.frontendOrder - 1).frontendOrder = movedRule.frontendOrder
        movedRule.frontendOrder = movedRule.frontendOrder - 1
        this.gateData.Rules.push(movedRule)
        this.gateData.Rules.sort((a, b) => (a.frontendOrder > b.frontendOrder) ? 1 : -1)
      },
      moveRuleDown(rule) {
        let movedRule = this.gateData.Rules.find((x) => x.Name == rule.Name)
        if (movedRule == null) {
          movedRule = this.gateData.Rules.find((x) => x.frontendOrder == rule.frontendOrder)
        }
        this.gateData.Rules = this.gateData.Rules.filter((x) => x.Name !== movedRule.Name)
        this.gateData.Rules.find((x) => x.frontendOrder == movedRule.frontendOrder + 1).frontendOrder = movedRule.frontendOrder
        movedRule.frontendOrder = movedRule.frontendOrder + 1
        this.gateData.Rules.push(movedRule)
        this.gateData.Rules.sort((a, b) => (a.frontendOrder > b.frontendOrder) ? 1 : -1)
      },
      moveCheckUp(ruleIndex, check) {
        const movedCheck = this.gateData.Rules[ruleIndex].Checks.find((x) => x.Path == check.Path)
        this.gateData.Rules[ruleIndex].Checks = this.gateData.Rules[ruleIndex].Checks.filter((x) => x.Path !== movedCheck.Path)
        this.gateData.Rules[ruleIndex].Checks.find((x) => x.frontendOrder == movedCheck.frontendOrder - 1).frontendOrder = movedCheck.frontendOrder
        movedCheck.frontendOrder = movedCheck.frontendOrder - 1
        this.gateData.Rules[ruleIndex].Checks.push(movedCheck)
        this.gateData.Rules[ruleIndex].Checks.sort((a, b) => (a.frontendOrder > b.frontendOrder) ? 1 : -1)
      },
      moveCheckDown(ruleIndex, check) {
        const movedCheck = this.gateData.Rules[ruleIndex].Checks.find((x) => x.Path == check.Path)
        this.gateData.Rules[ruleIndex].Checks = this.gateData.Rules[ruleIndex].Checks.filter((x) => x.Path !== movedCheck.Path)
        this.gateData.Rules[ruleIndex].Checks.find((x) => x.frontendOrder == movedCheck.frontendOrder + 1).frontendOrder = movedCheck.frontendOrder
        movedCheck.frontendOrder = movedCheck.frontendOrder + 1
        this.gateData.Rules[ruleIndex].Checks.push(movedCheck)
        this.gateData.Rules[ruleIndex].Checks.sort((a, b) => (a.frontendOrder > b.frontendOrder) ? 1 : -1)
      },
      getMaxCheckFrontendOrder(ruleIndex) {
        if (this.gateData.Rules[ruleIndex].length === 0 || this.gateData.Rules[ruleIndex].Checks.length === 0) {
          return 0
        }
        const arr = this.gateData.Rules[ruleIndex].Checks.map(x => x.frontendOrder)
        return arr.reduce(function (p, v) {
          return (p > v ? p : v);
        })
      },
      getMinCheckFrontendOrder(ruleIndex) {
        if (this.gateData.Rules[ruleIndex].length === 0 || this.gateData.Rules[ruleIndex].Checks.length === 0) {
          return 0
        }
        const arr = this.gateData.Rules[ruleIndex].Checks.map(x => x.frontendOrder)
        return arr.reduce(function (p, v) {
          return (p < v ? p : v);
        })
      }
    },
    computed: {
      maxFrontendRuleOrder() {
        if (this.gateData.Rules.length === 0 || this.gateData.Rules.length === 0) {
          return 0
        }
        const arr = this.gateData.Rules.map(x => x.frontendOrder)
        return arr.reduce(function (p, v) {
          return (p > v ? p : v);
        })
      },
      minFrontendRuleOrder() {
        if (this.gateData.Rules.length === 0 || this.gateData.Rules.length === 0) {
          return 0
        }
        const arr = this.gateData.Rules.map(x => x.frontendOrder)
        return arr.reduce(function (p, v) {
          return (p < v ? p : v);
        })
      }
    },
    created() {
      this.VerdictOptions = Object.values(GateRules_EnumsDefaultSalesChannelVerdict).map(i => { return { text: i, value: i } });
      this.ComparatorTypeOptions = Object.values(GateRuleComparatorType).map(i => { return { text: i, value: i } });
      this.StringComparatorOptions = Object.values(GateRuleStringComparator).map(i => { return { text: i, value: i } });
      this.NumericComparatorOptions = Object.values(GateRuleNumericComparator).map(i => { return { text: i, value: i } });
    },
    mounted() {
      this.loadGate();
    },

  };
</script>

<style lang="scss" scoped>
  .row > div {
    padding-top: 0;
    padding-bottom: 0;
  }
</style>
