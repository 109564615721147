<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12">
          <h1>Agent Underwriter Reassignment</h1>
        </v-col>
      </v-row>  

      <div  v-if="showAgentUnderwriterReassignment">

        <v-card class="mb-6">
          <v-card-text>
            <v-row>

              <v-col cols="12" class="pb-4" style="padding-top: 20px;">
                <v-row class="pb-2">
                    <v-col cols="12" class="d-flex align-center py-0">
                        <label class="main-label mb-0">Generate CSV Template</label>
                    </v-col>
                </v-row>
                
                <p class="description mb-0">Enter an Underwriter's Email Address to generate an Agent Underwriter Reassignment CSV template</p>

                <DynamicFormControl 
                    :control="{
                      type: 'TextInput',
                      name: 'Underwriter',
                      value: underwriterEmail,
                      config: {
                          label:'Enter an Amwins Underwriter Email Address',
                          short: true,
                          rules: 'email',
                          clearable: true,
                          type: 'email'
                      }
                    }"
                    @change="updateUnderwriterEmail"
                />
              </v-col>

              <v-col v-if="showGenerateCSV && !csvRequested" cols="12" class="py-0 mb-6 text-center">
                <v-btn color="secondary" type="button" @click="generateCSV" large key="download" :disabled="disableGenerateCSV">
                  <Loading v-if="disableGenerateCSV" :size="24" class="mr-2" />
                  <i class="fas fa-download"></i>&nbsp;Generate CSV
                </v-btn>
              </v-col>

              <v-col v-if="csvRequested" cols="12" class="py-0">
                <v-alert type="info" class="text-left" dense>
                  CSV Template is being generated and will download automatically.
                </v-alert>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <v-card class="mb-6">
          <v-card-text>
            <v-row>
              <v-col cols="12" class="py-0">
                <DynamicFormControl 
                    :control="{
                      type: 'FileUpload',
                      name: 'File',
                      value: files,
                      config: {
                          label:'Reassign Agent Underwriters',
                          description: 'Upload a CSV with the following headings: AgentEmail, OldUnderwriterEmail, NewUnderwriterEmail',
                          accept: '.csv',
                          max: 1,
                          rules: ''
                      }
                    }"
                    @change="updateFiles"
                />
              </v-col>

              <v-col v-if="files && files.length" cols="12" class="py-0 mb-6 text-center">
                <v-btn color="secondary" type="button" @click="upload" large>
                  <i class="fas fa-upload"></i>&nbsp;Submit
                </v-btn>
              </v-col>

              <v-col v-if="uploadSubmitted" cols="12" class="py-0">
                <v-alert type="info" class="text-left" dense>
                  {{uploadSubmittedCount}} Agent Underwriter Reassignments Queued
                </v-alert>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

      </div>

      <v-alert v-else type="warning" dense :icon="false" class="mt-4">You do not have access to this feature.</v-alert>


    </v-container>
  </div>
</template>

<script>
import DynamicFormControl from '@/components/form/DynamicFormControl.vue'
import { AgentService } from '@/api/AgentService';
import { sanitizeFileName, validateEmail } from '@/scripts/helper'

export default {
  name: 'AgentUnderwriterReassignment',
  components: {
    DynamicFormControl
  },
  data() {
    return {
      files: null,
      //underwriter: null,
      underwriterEmail: null,
      showGenerateCSV: false,
      disableGenerateCSV: false,
      csvTemplateName: null,
      uploadSubmitted: false,
      uploadSubmittedCount: 0,
      csvRequested: false
    }
  },
  computed: {
    showAgentUnderwriterReassignment() {
      return this.$store.getters.userHasRestrictedFeature('AgentUnderwriterReassignment');
    },
  },
  methods: {
    updateFiles(event) {
      this.files = event.value;
      this.uploadSubmitted = false;
    },
    // updateUnderwriter(event) {
    //   this.underwriter = event.value;
    //   //this.csvTemplateName = null;
    //   this.csvRequested = false;
    // },
    updateUnderwriterEmail(event) {
      this.underwriterEmail = event.value;
      this.csvRequested = false;

      if (validateEmail(this.underwriterEmail)) {
        this.showGenerateCSV = true;
      } else {
        this.showGenerateCSV = false;
      }
    },
    upload() {
      //alert(this.files[0].FileName);
      AgentService.underwriterReassignments({
        fileName: this.files[0].FileName
      }).then((data) => {
        this.files = null;
        this.uploadSubmittedCount = data;
        this.uploadSubmitted = true;
      })
    },
    generateCSV() {
      this.disableGenerateCSV = true;
      AgentService.underwriterAgents({
        oldUnderwriterEmail: this.underwriterEmail
      }).then(data => {
        this.csvRequested = true;
      })
      .catch(() => {})
      .finally(() => {this.disableGenerateCSV = false;});
    }
  }
}
</script>

<style scoped>
  .main-label {
      display: inline-block;
      font-weight: bold;
      line-height: 36px;
  }

  .main-label, .description {
      display: block;
      color: rgba(0, 0, 0, 0.87);
  }
</style>