// @ts-nocheck
import Vue from 'vue'
import App from './App.vue'

import Vuex from 'vuex'
import 'es6-promise/auto'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './vuex/store'
import * as FullStory from '@fullstory/browser'
import './validation'
import './plugins/axios'
import Environment from './scripts/environment'

//IE11 compatibility?
import 'core-js/stable';
import 'regenerator-runtime/runtime'; 

declare module 'vue/types/vue' {
  interface Vue {
    $http: any,
    $httpSBIX: any,
    $httpIdentity: any,
    $httpMoratorium: any,
    $validator: any,
    $gtag: any
  }
}


Vue.config.productionTip = false


import VueGtag from 'vue-gtag';
Vue.use(VueGtag, {
  config: { 
    id: Environment.GOOGLE_ANALYTICS_ID,
    params: {
      send_page_view: false,
      //debug_mode: true,
    }
  },
  pageTrackerEnabled: false,
});



Vue.config.errorHandler = (err, vm, info) => {
  Vue.util.warn(`Error in ${info}: "${err.toString()}"`, vm);
  console.error(err);

  Vue.prototype.$gtag.exception({
    'page_location': window.location.href,
    'description': err,
  })

  if (Environment.environment != 'localhost' && Environment.environment != 'development') {
    // @ts-ignore
    hj('event', err.toString());
  }
};

import Loading from '@/components/shared/Loading.vue'
Vue.component('Loading', Loading);

import PolicyStatus from '@/components/shared/PolicyStatus.vue'
Vue.component('PolicyStatus', PolicyStatus);

import { VTextField } from 'vuetify/lib'
Vue.component('v-text-field', VTextField)

// @ts-ignore
import VCurrencyField from 'v-currency-field'
Vue.use(VCurrencyField);

import moment from 'moment';
Vue.filter('formatDate', function(value: any) {
  if (value) {
    return moment(String(value)).format('MM/DD/YYYY');
  }
});


if (Environment.environment != 'development' && Environment.environment != 'localhost') {
  FullStory.init({
    orgId: Environment.FULLSTORY_ID,
    devMode: Environment.environment !== 'prod'
  })
  Vue.prototype.$FullStory = FullStory
}

import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import ToastCloseButton from '@/components/shared/ToastCloseButton.vue'
//const toastContainer = document.querySelector('.v-main__wrap');
Vue.use(Toast, {
  transition: "Vue-Toastification__fade",
  maxToasts: 20,
  newestOnTop: true,
  position: "bottom-center",
  timeout: false,
  closeOnClick: true,
  draggable: false,
  hideProgressBar: true,
  closeButton: ToastCloseButton,
  icon: false,
  toastClassName: 'amwins-toast',
  //container: toastContainer
});

import hub from '@/plugins/webapp-hub.js'
Vue.use(hub)

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
