<template>
  <v-card-text>
    <div v-if="loading">
      <Loading :size="64" class="text-center" />
    </div>
    <div v-else>
      <div v-if="salesChannel === salesChannelConst.VavePersonalLines">
        <ValidationObserver tag="span" ref="observer">
          <form @submit.prevent="refineVavePersonalLinesValidate()">

            <DynamicFormControl :control="{
                                     name: 'TIV',
                                     type: 'Hidden',
                                     value: tiv,
                                 }" />

            <DynamicFormControl :control="{
                                     name: 'TenPercentTIV',
                                     type: 'Hidden',
                                     value: tenPercentTIV,
                                 }" />

            <DynamicFormControl :control="{
                                     name: 'AllPerilDeductible',
                                     type: 'Hidden',
                                     value: allPerilDeductible,
                                 }" />

            <v-row>
              <v-col cols="12" class="pb-0">
                <h4 class="mb-2 mt-2">Property Coverages</h4>
              </v-col>
            </v-row>

            <v-row class="mb-2">
              <v-col md="6" cols="12" class="py-0" v-if="showControlByCoverageOption(salesChannel, newQuote?.FormType?.value, 'CovA')">
                <DynamicFormControl :control="{
                                        name: 'CovA',
                                        type: 'TextInput',
                                        value: getQuoteValue(newQuote?.CovA?.value),
                                        config: {
                                          ...getControlConfig(salesChannel, newQuote?.FormType?.value, 'CovA'),
                                          label: covAlabel,     
                                          disabled: loading || calcing,                                        
                                        }
                                    }"
                                    @change="updateValue" />
              </v-col>
              <v-col md="6" cols="12" class="py-0" v-if="showControlByCoverageOption(salesChannel, newQuote?.FormType?.value, 'CovB')">
                <DynamicFormControl :control="{
                                        name: 'CovB',
                                        type: 'TextInput',
                                        value: getQuoteValue(newQuote?.CovB?.value),
                                        config: {
                                          ...getControlConfig(salesChannel, newQuote?.FormType?.value, 'CovB'),
                                          disabled: loading || calcing,
                                       }
                                    }"
                                    @change="updateValue" />
              </v-col>
              <v-col md="6" cols="12" class="py-0" v-if="showControlByCoverageOption(salesChannel, newQuote?.FormType?.value, 'CovC')">
                <DynamicFormControl :control="{
                                        name: 'CovC',
                                        type: 'TextInput',
                                        value: getQuoteValue(newQuote?.CovC?.value),
                                         config: {
                                          ...getControlConfig(salesChannel, newQuote?.FormType?.value, 'CovC'),
                                          disabled: loading || calcing,
                                        }
                                    }"
                                    @change="updateValue" />
              </v-col>
              <v-col md="6" cols="12" class="py-0" v-if="showControlByCoverageOption(salesChannel, newQuote?.FormType?.value, 'CovD')">
                <DynamicFormControl :control="{
                                        name: 'CovD',
                                        type: 'TextInput',
                                        value: getQuoteValue(newQuote?.CovD?.value),
                                        config: {
                                          ...getControlConfig(salesChannel, newQuote?.FormType?.value, 'CovD'),
                                          disabled: loading || calcing,
                                       }
                                    }"
                                    @change="updateValue" />
              </v-col>
              <v-col md="6" cols="12" class="py-0" v-if="showControlByCoverageOption(salesChannel, newQuote?.FormType?.value, 'LossAssessment')">
                <DynamicFormControl :control="{
                                        name: 'LossAssessment',
                                        type: 'TextInput',
                                        value: getQuoteValue(newQuote?.LossAssessment?.value),
                                        config: {
                                          ...getControlConfig(salesChannel, newQuote?.FormType?.value, 'LossAssessment'),
                                          disabled: loading || calcing,
                                        }
                                    }"
                                    @change="updateValue" />
              </v-col>
              <v-col md="6" cols="12" class="py-0" v-if="showControlByCoverageOption(salesChannel, newQuote?.FormType?.value, 'LossAssessmentEQ') && showLossAssessmentEQ">
                <DynamicFormControl :control="{
                                        name: 'LossAssessmentEQ',
                                        type: 'TextInput',
                                        value: getQuoteValue(newQuote?.LossAssessmentEQ?.value),
                                         config: {
                                          ...getControlConfig(salesChannel, newQuote?.FormType?.value, 'LossAssessmentEQ'),
                                          disabled: loading || calcing,
                                        }
                                    }"
                                    @change="updateValue" />
              </v-col>
            </v-row>

            <hr>

            <v-row>
              <v-col cols="12" class="py-0">
                <h4 class="mb-2 mt-2">Liability Coverages</h4>
              </v-col>
            </v-row>

            <v-row class="mb-2">
              <v-col md="6" cols="12" class="py-0" v-if="showControlByCoverageOption(salesChannel, newQuote?.FormType?.value, 'Liability')">
                <DynamicFormControl :control="{
                                        name: 'Liability',
                                        type: 'Dropdown',
                                        value: getQuoteValue(newQuote?.Liability?.value),
                                        config: {
                                         ...getControlConfig(salesChannel, newQuote?.FormType?.value, 'Liability'),
                                         disabled: loading || calcing,
                                        }
                                    }"
                                    @change="updateValue" />
              </v-col>
              <v-col md="6" cols="12" class="py-0" v-if="showControlByCoverageOption(salesChannel, newQuote?.FormType?.value, 'MedPay')">
                <DynamicFormControl :control="{
                                       name: 'MedPay',
                                       type: 'Dropdown',
                                       value: getQuoteValue(newQuote?.MedPay?.value),
                                       config: {
                                         ...getControlConfig(salesChannel, newQuote?.FormType?.value, 'MedPay'),
                                         disabled: loading || calcing || !liabilityCoveragesEnabled,
                                       }
                                   }"
                                    @change="updateValue" />
              </v-col>
              <v-col md="6" cols="12" class="py-0" v-if="showControlByCoverageOption(salesChannel, newQuote?.FormType?.value, 'PersonalInjury')">
                <DynamicFormControl :control="{
                                        name: 'PersonalInjury',
                                        type: 'Dropdown',
                                        value: getQuoteValue(newQuote?.PersonalInjury?.value),
                                         config: {
                                          ...getControlConfig(salesChannel, newQuote?.FormType?.value, 'PersonalInjury'),
                                          disabled: loading || calcing || !liabilityCoveragesEnabled,
                                        }
                                    }"
                                    @change="updateValue" />
              </v-col>
            </v-row>

            <hr>

            <v-row>
              <v-col cols="12" class="py-0">
                <h4 class="mb-2 mt-2">Add-On/Optional Coverages</h4>
              </v-col>
            </v-row>

            <v-row class="mb-2">
              <v-col md="6" cols="12" class="py-0" v-if="showControlByCoverageOption(salesChannel, newQuote?.FormType?.value, 'ReplacementCostStructures') && showControlBySalesChannel(salesChannel, newQuote?.FormType?.value, 'ReplacementCostStructures')">
                <DynamicFormControl :control="{
                                        name: 'ReplacementCostStructures',
                                        type: 'Dropdown',
                                        value: getQuoteValue(newQuote?.ReplacementCostStructures?.value),
                                        config: {
                                          ...getControlConfig(salesChannel, newQuote?.FormType?.value, 'ReplacementCostStructures'),
                                          disabled: loading || calcing,
                                        }
                                    }"
                                    @change="updateValue" />
              </v-col>
              <v-col md="6" cols="12" class="py-0" v-if="showControlByCoverageOption(salesChannel, newQuote?.FormType?.value, 'ReplacementCostContents') && showControlBySalesChannel(salesChannel, newQuote?.FormType?.value, 'ReplacementCostContents')">
                <DynamicFormControl :control="{
                                        name: 'ReplacementCostContents',
                                        type: 'Dropdown',
                                        value: getQuoteValue(newQuote?.ReplacementCostContents?.value),
                                         config: {
                                          ...getControlConfig(salesChannel, newQuote?.FormType?.value, 'ReplacementCostContents'),
                                          disabled: loading || calcing,
                                        }
                                    }"
                                    @change="updateValue" />
              </v-col>
              <v-col md="6" cols="12" class="py-0" v-if="showControlByCoverageOption(salesChannel, newQuote?.FormType?.value, 'InflationGuard') && showControlBySalesChannel(salesChannel, newQuote?.FormType?.value, 'InflationGuard')">
                <DynamicFormControl :control="{
                                        name: 'InflationGuard',
                                        type: 'PercentIncrementer',
                                        value: getQuoteValue(newQuote?.InflationGuard?.value),
                                        config: {
                                          ...getControlConfig(salesChannel, newQuote?.FormType?.value, 'InflationGuard'),
                                          disabled: loading || calcing,
                                        }
                                    }"
                                    @change="updateValue" />
              </v-col>
              <v-col md="6" cols="12" class="py-0" v-if="showControlByCoverageOption(salesChannel, newQuote?.FormType?.value, 'BuildingOrdinanceOrLaw') && showControlBySalesChannel(salesChannel, newQuote?.FormType?.value, 'BuildingOrdinanceOrLaw')">
                <DynamicFormControl :control="{
                                        name: 'BuildingOrdinanceOrLaw',
                                        type: 'Dropdown',
                                        value: getQuoteValue(newQuote?.BuildingOrdinanceOrLaw?.value),
                                        config: {
                                          ...getControlConfig(salesChannel, newQuote?.FormType?.value, 'BuildingOrdinanceOrLaw'),
                                          disabled: loading || calcing,
                                        }
                                    }"
                                    @change="updateValue" />
              </v-col>
              <v-col md="6" cols="12" class="py-0" v-if="showControlByCoverageOption(salesChannel, newQuote?.FormType?.value, 'Theft') && showControlBySalesChannel(salesChannel, newQuote?.FormType?.value, 'Theft')">
                <DynamicFormControl v-if="theftDefaultValue != 'Excluded'" :control="{
                                          name: 'Theft',
                                          type: 'TextInput',
                                          value: getQuoteValue(newQuote?.Theft?.value),
                                          config: {
                                            ...getControlConfig(salesChannel, newQuote?.FormType?.value, 'Theft'),
                                            disabled: loading || calcing || theftDefaultValue === 'Excluded',
                                          }
                                      }"
                                    @change="updateValue" />
                <DynamicFormControl v-else :control="{
                                          name: 'TheftExclusion',
                                          type: 'TextInput',
                                          value: 'No Coverage',
                                          config: {
                                            ...getControlConfig(salesChannel, newQuote?.FormType?.value, 'TheftExclusion'),
                                          }
                                      }" />
              </v-col>
              <v-col md="6" cols="12" class="py-0" v-if="showControlByCoverageOption(salesChannel, newQuote?.FormType?.value, 'AdditionalTheft') && showControlBySalesChannel(salesChannel, newQuote?.FormType?.value, 'AdditionalTheft')">
                <DynamicFormControl :control="{
                                          name: 'AdditionalTheft',
                                          type: 'Dropdown',
                                          value: getQuoteValue(newQuote?.AdditionalTheft?.value),
                                          config: {
                                            ...getControlConfig(salesChannel, newQuote?.FormType?.value, 'AdditionalTheft'),
                                            disabled: loading || calcing || theftDefaultValue == 'Excluded',
                                          }
                                      }"
                                    @change="updateValue" />
              </v-col>
              <v-col md="6" cols="12" class="py-0" v-if="showControlByCoverageOption(salesChannel, newQuote?.FormType?.value, 'LimitedTheft') && showControlBySalesChannel(salesChannel, newQuote?.FormType?.value, 'LimitedTheft')">
                <DynamicFormControl :control="{
                                        name: 'LimitedTheft',
                                        type: 'Dropdown',
                                        value: getQuoteValue(newQuote?.LimitedTheft?.value),
                                        config: {
                                          ...getControlConfig(salesChannel, newQuote?.FormType?.value, 'LimitedTheft'),
                                          disabled: loading || calcing,
                                        }
                                    }"
                                    @change="updateValue" />
              </v-col>
              <v-col md="6" cols="12" class="py-0" v-if="showControlByCoverageOption(salesChannel, newQuote?.FormType?.value, 'BroadTheft') && showControlBySalesChannel(salesChannel, newQuote?.FormType?.value, 'BroadTheft')">
                <DynamicFormControl :control="{
                                        name: 'BroadTheft',
                                        type: 'Dropdown',
                                        value: getQuoteValue(newQuote?.BroadTheft?.value),
                                        config: {
                                          ...getControlConfig(salesChannel, newQuote?.FormType?.value, 'BroadTheft'),
                                          disabled: loading || calcing,
                                        }
                                    }"
                                    @change="updateValue" />
              </v-col>
              <v-col md="6" cols="12" class="py-0" v-if="showControlByCoverageOption(salesChannel, newQuote?.FormType?.value, 'WaterBackup') && showControlBySalesChannel(salesChannel, newQuote?.FormType?.value, 'WaterBackup')">
                <DynamicFormControl :control="{
                                        name: 'WaterBackup',
                                        type: 'TextInput',
                                        value: getQuoteValue(newQuote?.WaterBackup?.value),
                                        config: {
                                          ...getControlConfig(salesChannel, newQuote?.FormType?.value, 'WaterBackup'),
                                          disabled: loading || calcing,
                                        }
                                    }"
                                    @change="updateValue" />
              </v-col>
              <v-col md="6" cols="12" class="py-0" v-if="showControlByCoverageOption(salesChannel, newQuote?.FormType?.value, 'GolfCartPhysicalDamage') && showControlBySalesChannel(salesChannel, newQuote?.FormType?.value, 'GolfCartPhysicalDamage')">
                <DynamicFormControl :control="{
                                        name: 'GolfCartPhysicalDamage',
                                        type: 'Dropdown',
                                        value: getQuoteValue(newQuote?.GolfCartPhysicalDamage?.value),
                                        config: {
                                          ...getControlConfig(salesChannel, newQuote?.FormType?.value, 'GolfCartPhysicalDamage'),
                                          disabled: loading || calcing,
                                        }
                                    }"
                                    @change="updateValue" />
              </v-col>
              <v-col md="6" cols="12" class="py-0" v-if="showControlByCoverageOption(salesChannel, newQuote?.FormType?.value, 'HO6ExtensionCoverage') && showControlBySalesChannel(salesChannel, newQuote?.FormType?.value, 'HO6ExtensionCoverage')">
                <DynamicFormControl :control="{
                                        name: 'HO6ExtensionCoverage',
                                        type: 'Dropdown',
                                        value: getQuoteValue(newQuote?.HO6ExtensionCoverage?.value),
                                        config: {
                                          ...getControlConfig(salesChannel, newQuote?.FormType?.value, 'HO6ExtensionCoverage'),
                                          disabled: loading || calcing,
                                        }
                                    }"
                                    @change="updateValue" />
              </v-col>
              <v-col md="6" cols="12" class="py-0" v-if="showControlByCoverageOption(salesChannel, newQuote?.FormType?.value, 'GreenUpgrades') && showControlBySalesChannel(salesChannel, newQuote?.FormType?.value, 'GreenUpgrades')">
                <DynamicFormControl :control="{
                                        name: 'GreenUpgrades',
                                        type: 'Dropdown',
                                        value: getQuoteValue(newQuote?.GreenUpgrades?.value),
                                        config: {
                                          ...getControlConfig(salesChannel, newQuote?.FormType?.value, 'GreenUpgrades'),
                                          disabled: loading || calcing,
                                        }
                                    }"
                                    @change="updateValue" />
              </v-col>
              <v-col md="6" cols="12" class="py-0" v-if="showControlByCoverageOption(salesChannel, newQuote?.FormType?.value, 'IdentityFraudExp') && showControlBySalesChannel(salesChannel, newQuote?.FormType?.value, 'IdentityFraudExp')">
                <DynamicFormControl :control="{
                                        name: 'IdentityFraudExp',
                                        type: 'Dropdown',
                                        value: getQuoteValue(newQuote?.IdentityFraudExp?.value),
                                        config: {
                                          ...getControlConfig(salesChannel, newQuote?.FormType?.value, 'IdentityFraudExp'),
                                          disabled: loading || calcing,
                                        }
                                    }"
                                    @change="updateValue" />
              </v-col>
              <v-col md="6" cols="12" class="py-0" v-if="showControlByCoverageOption(salesChannel, newQuote?.FormType?.value, 'RefrigeratedGoods') && showControlBySalesChannel(salesChannel, newQuote?.FormType?.value, 'RefrigeratedGoods')">
                <DynamicFormControl :control="{
                                        name: 'RefrigeratedGoods',
                                        type: 'Dropdown',
                                        value: getQuoteValue(newQuote?.RefrigeratedGoods?.value),
                                        config: {
                                          ...getControlConfig(salesChannel, newQuote?.FormType?.value, 'RefrigeratedGoods'),
                                          disabled: loading || calcing,
                                        }
                                    }"
                                    @change="updateValue" />
              </v-col>
              <v-col md="6" cols="12" class="py-0" v-if="showControlByCoverageOption(salesChannel, newQuote?.FormType?.value, 'SpecialComputerCover') && showControlBySalesChannel(salesChannel, newQuote?.FormType?.value, 'SpecialComputerCover')">
                <DynamicFormControl :control="{
                                        name: 'SpecialComputerCover',
                                        type: 'Dropdown',
                                        value: getQuoteValue(newQuote?.SpecialComputerCover?.value),
                                        config: {
                                          ...getControlConfig(salesChannel, newQuote?.FormType?.value, 'SpecialComputerCover'),
                                          disabled: loading || calcing,
                                        }
                                    }"
                                    @change="updateValue" />
              </v-col>
              <v-col md="6" cols="12" class="py-0" v-if="showControlByCoverageOption(salesChannel, newQuote?.FormType?.value, 'MechanicalBreakdownCover') && showControlBySalesChannel(salesChannel, newQuote?.FormType?.value, 'MechanicalBreakdownCover')">
                <DynamicFormControl :control="{
                                        name: 'MechanicalBreakdownCover',
                                        type: 'Dropdown',
                                        value: getQuoteValue(newQuote?.MechanicalBreakdownCover?.value),
                                        config: {
                                          ...getControlConfig(salesChannel, newQuote?.FormType?.value, 'MechanicalBreakdownCover'),
                                          disabled: loading || calcing,
                                        }
                                    }"
                                    @change="updateValue" />
              </v-col>
            </v-row>

            <hr>

            <v-row>
              <v-col cols="12" class="py-0">
                <h4 class="mb-2 mt-2">Deductibles</h4>
              </v-col>
            </v-row>

            <v-row>
              <v-col md="6" cols="12" class="py-0" v-if="showControlByCoverageOption(salesChannel, newQuote?.FormType?.value, 'AllPerilDeductible')">
                <DynamicFormControl :control="{
                                        name: 'TotalPeril',
                                        type: 'TextInput',
                                        value: getQuoteValue(newQuote?.TotalPeril?.value),
                                        config: {
                                          ...getControlConfig(salesChannel, newQuote?.FormType?.value, 'AllPerilDeductible'),
                                          disabled: loading || calcing,
                                        }
                                    }"
                                    @change="updateValue" />
              </v-col>
              <v-col cols="12" class="py-0" v-if="showControlByCoverageOption(salesChannel, newQuote?.FormType?.value, 'WindHailPerilIncrementer')">
                <v-row>
                  <v-col md="6" cols="12">
                    <DynamicFormControl :control="{
                                            name: 'WindHailPerilIncrementer',
                                            type: 'PercentIncrementer',
                                            value: deductibleValues.windHailPerilValues.percent,
                                            config: {
                                              ...getControlConfig(salesChannel, newQuote?.FormType?.value, 'WindHailPerilIncrementer'),
                                              disabled: loading || calcing,
                                            }
                                        }"
                                        @change="updateDeductibles('windHail', 'percent', $event)" />
                  </v-col>
                  <v-col md="6" cols="12">
                    <DynamicFormControl :control="{
                                            name: 'WindHailFlatAmount',
                                            type: 'TextInput',
                                            value: deductibleValues.windHailPerilValues.flat,
                                            config: {
                                              ...getControlConfig(salesChannel, newQuote?.FormType?.value, 'WindHailFlatAmount')
                                            }
                                        }"
                                        @change="updateDeductibles('windHail', 'flat', $event)" />
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" class="py-0" v-if="showControlByCoverageOption(salesChannel, newQuote?.FormType?.value, 'FloodPerilIncrementer')">
                <!--always disabling FloodPeril for now.-->
                <v-row>
                  <v-col md="6" cols="12">
                    <DynamicFormControl :control="{
                                            name: 'FloodPerilIncrementer',
                                            type: 'PercentIncrementer',
                                            value: deductibleValues.floodPerilValues.percent,
                                            config: {
                                              ...getControlConfig(salesChannel, newQuote?.FormType?.value, 'FloodPerilIncrementer'),
                                              disabled: loading || calcing || true,
                                            }
                                        }"
                                        @change="updateDeductibles('flood', 'percent', $event)" />
                  </v-col>
                  <v-col md="6" cols="12">
                    <DynamicFormControl :control="{
                                            name: 'FloodFlatAmount',
                                            type: 'TextInput',
                                            value: deductibleValues.floodPerilValues.flat,
                                            config: {
                                              ...getControlConfig(salesChannel, newQuote?.FormType?.value, 'FloodFlatAmount')
                                            }
                                        }"
                                        @change="updateDeductibles('flood', 'flat', $event)" />
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" class="py-0" v-if="showControlByCoverageOption(salesChannel, newQuote?.FormType?.value, 'EarthquakePerilIncrementer')">
                <v-row>
                  <v-col md="6" cols="12">
                    <DynamicFormControl :control="{
                                            name: 'EarthquakePerilIncrementer',
                                            type: 'PercentIncrementer',
                                            value: deductibleValues.earthquakePerilValues.percent,
                                            config: {
                                              ...getControlConfig(salesChannel, newQuote?.FormType?.value, 'EarthquakePerilIncrementer'),
                                              rules: earthquakePercentageRules,
                                              disabled: loading || calcing,
                                            }
                                        }"
                                        @change="updateDeductibles('earthquake', 'percent', $event)" />
                  </v-col>
                  <v-col md="6" cols="12">
                    <DynamicFormControl :control="{
                                            name: 'EarthquakeFlatAmount',
                                            type: 'TextInput',
                                            value: deductibleValues.earthquakePerilValues.flat,
                                            config: {
                                              ...getControlConfig(salesChannel, newQuote?.FormType?.value, 'EarthquakeFlatAmount')
                                            }
                                        }"
                                        @change="updateDeductibles('earthquake', 'flat', $event)" />
                  </v-col>
                </v-row>
              </v-col>
              <v-col md="6" cols="12" class="py-0" v-if="showControlByCoverageOption(salesChannel, newQuote?.FormType?.value, 'WaterDamagePeril')">
                <DynamicFormControl :control="{
                                          name: 'WaterDamagePeril',
                                          type: 'TextInput',
                                          value: getQuoteValue(newQuote?.WaterDamagePeril?.value),
                                          config: {
                                            ...getControlConfig(salesChannel, newQuote?.FormType?.value, 'WaterDamagePeril')
                                          }
                                      }"
                                      @change="updateValue" />
              </v-col>
              <v-col md="6" cols="12" class="py-0" v-if="showControlByCoverageOption(salesChannel, newQuote?.FormType?.value, 'WaterDamageSublimit')">
                <DynamicFormControl :control="{
                                          name: 'WaterDamageSublimit',
                                          type: 'TextInput',
                                          value: getQuoteValue(newQuote?.WaterDamageSublimit?.value),
                                          config: {
                                            ...getControlConfig(salesChannel, newQuote?.FormType?.value, 'WaterDamageSublimit'),
                                          }
                                      }" />
              </v-col>
              <v-col md="6" cols="12" class="py-0" v-if="showControlByCoverageOption(salesChannel, newQuote?.FormType?.value, 'Mold')">
                <DynamicFormControl :control="{
                                        name: 'Mold',
                                        type: 'TextInput',
                                        value: getQuoteValue(newQuote?.Mold?.value),
                                        config: {
                                          ...getControlConfig(salesChannel, newQuote?.FormType?.value, 'Mold'),
                                          disabled: loading || calcing,
                                        }
                                    }"
                                    @change="updateValue" />
              </v-col>
            </v-row>

            <hr>

            <v-row>
              <v-col cols="12" class="py-0">
                <h4 class="mb-2 mt-2">Fees</h4>
              </v-col>
            </v-row>

            <v-row>
              <v-col md="6" cols="12" class="py-0">
                <DynamicFormControl :control="{
                                         name: 'ServiceFee',
                                         type: 'TextInput',
                                         value: getQuoteValue(newQuote?.ServiceFee),
                                         config: {
                                           ...getControlConfig(salesChannel, newQuote?.FormType?.value, 'ServiceFee'),
                                           rules: amwinsServiceFeeRules,
                                           disabled: loading || calcing || !amwinsServiceFeeEnabled,
                                         }
                                     }"
                                    @change="updateServiceFee" />
              </v-col>
              <v-col md="6" cols="12" class="py-0">
                <DynamicFormControl :control="{
                                        name: 'InspectionFee',
                                        type: 'TextInput',
                                        value: getQuoteValue(newQuote?.InspectionFee),
                                        config: {
                                          ...getControlConfig(salesChannel, newQuote?.FormType?.value, 'InspectionFee'),
                                        }
                                    }"
                                    @change="updateInspectionFee" />
              </v-col>
            </v-row>

            <div class="py-6 actions">
              <v-row no-gutters>
                <v-col sm="6" cols="12" class="py-0">

                  <v-btn color="secondary" type="submit" large
                         :disabled="calcing" class="mr-4"
                         key="save">
                    <Loading v-if="calcing" :size="24" class="mr-2" />
                    Calculate
                  </v-btn>

                  <h2 v-if="!calcing" class="premium">{{premium}}</h2>

                </v-col>
                <v-col sm="6" cols="12" class="py-0 text-right">
                  <v-btn color="secondary" type="button" text large class="mr-2"
                         :disabled="calcing" @click="cancel">
                    Cancel
                  </v-btn>
                  <v-btn color="secondary" type="button" large v-if="refinementCalled"
                         :disabled="!refinementCalled || calcing" @click="save">
                    Save
                  </v-btn>
                </v-col>

              </v-row>
            </div>

          </form>
        </ValidationObserver>
      </div>
      <div v-else>
      </div>
    </div>
  </v-card-text>
</template>

<script>
  import DynamicFormControl from '@/components/form/DynamicFormControl.vue'
  import { getControlConfig, showControlByCoverageOption, showControlBySalesChannel } from '@/scripts/sales-channel-config'
  import { calculateDeductibleChanges, getDeductibleRules, initializeDeductibles, updateDeductibleValues, valueChanged, getComputedValue } from '@/scripts/sales-channel-deductible'
  import { formatMoney } from '@/scripts/helper'
  import { SalesChannel } from '@/api/index.defs';
  import { SubmissionService } from '@/api/SubmissionService'
  import { QuotesService } from '@/api/QuotesService'

  export default {
    name: "V2RefineDialogDigital",
    components: {
      DynamicFormControl
    },
    props: {
      policyKey: String,
      salesChannel: String,
      submissionId: String
    },
    data() {
      return {
        newQuote: null,
        salesChannelConst: SalesChannel,
        loading: false,
        calcing: false,
        submission: null,
        originalQuote: null,
        refinementCalled: false,
        deductibleValues: {
          windHailPerilValues: {
            flat: 0,
            percent: 0
          },
          floodPerilValues: {
            flat: 0,
            percent: 0
          },
          earthquakePerilValues: {
            flat: 0,
            percent: 0
          }
        },
        windHailPerilValueHolder: 0,
        floodPerilValueHolder: 0,
        earthquakePerilValueHolder: 0,
      }
    },
    computed: {
      premium() {
        if (this.refinementCalled) {
          return formatMoney(parseFloat(this.currentRefinementQuote?.Total));
        } else {
          return formatMoney(parseFloat(this.newQuote?.Total));
        }
      },
      titleSalesChannel() {
        switch (this.salesChannel) {
          case SalesChannel.VavePersonalLines: return "Vave Personal Lines";
          default: return "";
        }
      },
      tiv() {
        if (!this.newQuote) return 0;

        return this.getComputedValue({
          CovA: this.newQuote?.CovA,
          CovB: this.newQuote?.CovB,
          CovC: this.newQuote?.CovC,
          CovD: this.newQuote?.CovD,
        }, this.salesChannel, 'TIV')
      },
      tenPercentTIV() {
        if (!this.newQuote) return 0;

        return this.tiv * .1;
      },
      allPerilDeductible() {
        if (!this.newQuote) return 0;

        return this.newQuote?.TotalPeril?.value ?? 0;
      },
      theftDefaultValue() {
        return this.getComputedValue({
          LossesInPast5YearsList: this.submission?.Data?.LossesInPast5YearsList,
        }, this.salesChannel, 'Theft')
      },
      showLossAssessmentEQ() {
        return this.getComputedValue({
          EarthquakePeril: this.newQuote?.EarthquakePeril,
          EarthquakeFlatAmount: this.newQuote?.EarthquakeFlatAmount,
        }, this.salesChannel, 'ShowLossAssessmentEQ')
      },
      amwinsServiceFeeRules() {
        let rules = 'required'

        if (!this.newQuote) return rules

        return rules + '|min_value:' + this.newQuote.ServiceFeeMin + '|max_value:' + this.newQuote.ServiceFeeMax
      },
      covAlabel() {
        return this.getComputedValue({
          formType: this.newQuote?.FormType?.value?.toUpperCase(),
        }, this.salesChannel, 'CovALabel')
      },
      currentRefinementQuote() {
        return this.$store.getters['quoteForm/getCurrentRefinementQuote'];
      },
      amwinsServiceFeeEnabled() {
        // Amwins Service Fee is only potentially enabled for VavePersonalLines
        if (this.salesChannel !== SalesChannel.VavePersonalLines) return false

        const isEmployee = this.$store.getters.userHasRole('employee')
        const user = this.$store.getters.getUser

        // User can edit service fees if they're an employee and match the UW email for the submission or they're a support or admin role
        return (isEmployee && user?.profile?.sub && user?.profile?.sub?.toLowerCase() === this.submission.Data?.Underwriter?.value?.Email.toLowerCase()) ||
          this.$store.getters.userHasRole('support') || this.$store.getters.userHasRole('admin')
      },
      liabilityCoveragesEnabled() {
        return this.getComputedValue({
          Liability: this.newQuote?.Liability,
        }, this.salesChannel, 'LiabilityCoveragesEnabled')
      },
      earthquakePercentageRules() {
        return this.getDeductibleRules({ deductibleValues: this.deductibleValues }, this.salesChannel, 'EarthquakePerilIncrementer')
      },
    },
    watch: {
      currentRefinementQuote() {
        this.calcing = false;
      },
      theftDefaultValue(value) {
        if (value === 'Excluded') {
          this.newQuote.Theft.value = 0
        }
      },
      'newQuote.Liability': {
        handler: function (value) {
          const ret = this.valueChanged({
            value: value.value,
          }, this.salesChannel, 'Liability')

          if (ret.clearMedPayAndPersonalInjury && this.newQuote?.MedPay && this.newQuote?.PersonalInjury) {
            this.newQuote.MedPay.value = 0
            this.newQuote.PersonalInjury.value = 'Excluded'
          }
        },
        deep: true
      },
      'newQuote.TotalPeril.value'(value) {
        if (value) {
          this.newQuote.WaterDamagePeril.value = this.valueChanged({
            value,
            WaterDamageFlatAmount: this.newQuote.WaterDamagePeril
          }, this.salesChannel, 'AllPerilDeductible')
        }
      },
      'newQuote.CovA.value'(value) {
         this.calculateDeductibleChanges({
          value,
          deductibleValues: this.deductibleValues,
          CovC: this.newQuote.CovC,
          formType: this.newQuote?.FormType?.value?.toUpperCase(),
          windHailPerilValueHolder: this.windHailPerilValueHolder,
          floodPerilValueHolder: this.floodPerilValueHolder,
          earthquakePerilValueHolder: this.earthquakePerilValueHolder,
        }, this.salesChannel, 'CovA')
      },
      'newQuote.CovC.value'(value) {
        this.calculateDeductibleChanges({
          value,
          deductibleValues: this.deductibleValues,
          CovA: this.newQuote.CovA,
          formType: this.newQuote?.FormType?.value?.toUpperCase(),
          windHailPerilValueHolder: this.windHailPerilValueHolder,
          floodPerilValueHolder: this.floodPerilValueHolder,
          earthquakePerilValueHolder: this.earthquakePerilValueHolder,
        }, this.salesChannel, 'CovC')
      },
    },
    async mounted() {
      this.loading = true
      await SubmissionService.get({ id: this.submissionId })
        .then((submission) => {
          if (submission) {
            this.submission = submission;
            this.newQuote = submission.Quotes.find(x => x.id === this.policyKey && x.Number != null)

              // set deductible values
              this.initializeDeductibles({
                formType: this.newQuote?.FormType?.value?.toUpperCase(),
                deductibleValues: this.deductibleValues,
                WindHailFlatAmount: this.newQuote?.WindHailFlatAmount,
                FloodFlatAmount: this.newQuote?.FloodFlatAmount,
                EarthquakeFlatAmount: this.newQuote?.EarthquakeFlatAmount,
                CovA: this.newQuote?.CovA,
                CovC: this.newQuote?.CovC
              }, this.salesChannel)
            // Store the original quote so that if the user declines the refinement, we can call refinement again with the original values.
            // for Vave, once refinement is called, that refinement quote becomes the new quote, even though the UI won't reflect that.
            // this is intentional. We're going to call refine again with the original values on cancel to try to get the original quote.
            this.originalQuote = JSON.parse(JSON.stringify(this.newQuote));
          }
        })
        .catch(() => {
          this.$store.dispatch('toastError', { Message: 'Failed to retrieve submission' })
        })
        .finally(() => {
          this.loading = false
        })
    },
    methods: {
      formatMoney,
      getControlConfig,
      showControlByCoverageOption,
      showControlBySalesChannel,
      calculateDeductibleChanges,
      getDeductibleRules,
      initializeDeductibles,
      updateDeductibleValues,
      valueChanged,
      getComputedValue,
      async refineVavePersonalLinesValidate() {
        const valid = await this.$refs.observer.validate()
        if (valid) {
          this.refinementCalled = true
          await this.refine(true);
        } else {
          this.scrollToFirstError();
        }
      },
      async cancel() {
        if (this.refinementCalled) {
          // Refine with original values
          await this.refine(false)
            .then(() => {
              this.close()
            });
        } else {
          this.close()
        }
      },
      async refine(useNewQuote) {
        this.calcing = true;

        // Convert some quote values to map properly in the back end. For example, 'Excluded' to 0
        const quote = useNewQuote ? this.cleanQuoteValuesForApiCall(this.newQuote) : this.cleanQuoteValuesForApiCall(this.originalQuote)

        const body = {
          SubmissionId: this.submission?.guid,
          SubmissionNumber: this.submissionId,
          RequestedCoverages: quote,
          QuoteId: this.newQuote?.id,
          CoverageModelType: this.newQuote?.CoverageModelType
        }

        await QuotesService.refineV2({ body })
          .then((quoteId) => {
            if (quoteId && useNewQuote) {
              this.$store.commit('quoteForm/setRefinementListenId', quoteId);
            }
          })
          .catch(() => {
            this.$store.dispatch('toastError', { Message: 'Failed to refine quote' })
            this.calcing = false;
          })
      },
      cleanQuoteValuesForApiCall(quote) {
        let clonedQuote = JSON.parse(JSON.stringify(quote));

        // ReplacementCostDwelling
        if (quote.ReplacementCostDwelling.value === 'Included') {
          clonedQuote.ReplacementCostDwelling.value = true
        } else {
          clonedQuote.ReplacementCostDwelling.value = false
        }

        // HO6ExtensionCoverage
        if (quote.HO6ExtensionCoverage.value === '0') {
          clonedQuote.HO6ExtensionCoverage.value = 'No Coverage'
        } else if (quote.HO6ExtensionCoverage.value === '1') {
          clonedQuote.HO6ExtensionCoverage.value = 'Extended Coverage A'
        } else if (quote.HO6ExtensionCoverage.value === '2') {
          clonedQuote.HO6ExtensionCoverage.value = 'Extended Coverage C'
        } else if (quote.HO6ExtensionCoverage.value === '3') {
          clonedQuote.HO6ExtensionCoverage.value = 'Extended Coverage A & C'
        }

        // InflationGuard
        if (typeof quote.InflationGuard.value === 'string' && quote.InflationGuard.value.includes('%')) {
          clonedQuote.InflationGuard.value = clonedQuote.InflationGuard.value.slice(0, -1);
        }

        // AllPeril
        const allPeril = {
          value: {
            Flat: {
              Amount: this.getQuoteValue(clonedQuote.TotalPeril.value)
            }
          }
        }
        delete clonedQuote.TotalPeril;
        clonedQuote.TotalPeril = allPeril

        // WindHailPeril
        if (this.deductibleValues.windHailPerilValues.flat > 0 || this.deductibleValues.windHailPerilValues.percent > 0) {
          clonedQuote.WindHailPeril.value = true;
        } else {
          clonedQuote.WindHailPeril.value = false;
        }
        const windPeril = {
          value: {
            Flat: {
              Amount: this.deductibleValues.windHailPerilValues.flat
            },
            Percentage: {
              Percent: this.deductibleValues.windHailPerilValues.percent
            }
          }
        }
        clonedQuote.WindPerilDeductible = windPeril

        // EarthquakePeril
        if (this.deductibleValues.earthquakePerilValues.flat > 0 || this.deductibleValues.earthquakePerilValues.percent > 0){
          clonedQuote.EarthquakePeril.value = true;
        } else {
          clonedQuote.EarthquakePeril.value = false;
        }
        const eqPeril = {
          value: {
            Flat: {
              Amount: this.deductibleValues.earthquakePerilValues.flat
            },
            Percentage: {
              Percent: this.deductibleValues.earthquakePerilValues.percent
            }
          }
        }
        clonedQuote.EarthquakePerilDeductible = eqPeril

        // FloodPeril
        if (this.deductibleValues.floodPerilValues.flat > 0 || this.deductibleValues.floodPerilValues.percent > 0){
          clonedQuote.FloodPeril.value = true;
        } else {
          clonedQuote.FloodPeril.value = false;
        }
        const floodPeril = {
          value: {
            Flat: {
              Amount: this.deductibleValues.floodPerilValues.flat
            },
            Percentage: {
              Percent: this.deductibleValues.floodPerilValues.percent
            }
          }
        }
        clonedQuote.FloodPerilDeductible = floodPeril

        // WaterDamagePeril
        var waterDamage = this.getQuoteValue(clonedQuote.WaterDamagePeril.value);
        const waterDamagePeril = {
          value: {
            Flat: {
              Amount: this.getQuoteValue(clonedQuote.WaterDamagePeril.value)
            }
          }
        }
        delete clonedQuote.WaterDamagePeril;
        clonedQuote.WaterDamagePeril = waterDamagePeril

        // WaterBackup
        const waterBackup =
        {
          value: this.getQuoteValue(clonedQuote.WaterBackup.value),
          Altered: clonedQuote.WaterBackup.Altered
        }
        delete clonedQuote.WaterBackup;
        clonedQuote.WaterBackup = waterBackup

        const mappedQuote = {
          ...clonedQuote,
          FormType: clonedQuote.ContractFormType.value,
          CovA: clonedQuote.CovA,
          CovB: clonedQuote.CovB,
          CovC: clonedQuote.CovC,
          CovD: clonedQuote.CovD,
          LossAssessmentLimit: clonedQuote.LossAssessment,
          CovE: clonedQuote.FormType.value.toUpperCase() != 'DP3' ? clonedQuote.Liability : null,
          CovF: clonedQuote.FormType.value.toUpperCase() != 'DP3' ? clonedQuote.MedPay: null,
          CovLdp3: clonedQuote.FormType.value.toUpperCase() === 'DP3' ? clonedQuote.Liability : null,
          CovMdp3: clonedQuote.FormType.value.toUpperCase() === 'DP3' ? clonedQuote.MedPay : null,
          AllPerilDeductible: clonedQuote.TotalPeril,
          PersonalInjury: clonedQuote.PersonalInjury,
          ReplacementCostDwelling: clonedQuote.ReplacementCostDwelling,
          ReplacementCostStructures: clonedQuote.ReplacementCostStructures,
          ReplacementCostContents: clonedQuote.ReplacementCostContents,
          InflationGuard: clonedQuote.InflationGuard,
          BuildingOrdinanceOrLaw: clonedQuote.BuildingOrdinanceOrLaw,
          Theft: clonedQuote.Theft?.value === 'No Coverage' ? { value: null } : clonedQuote.Theft,
          Mold: clonedQuote.Mold,
          DomesticAnimalLiability: clonedQuote.DomesticAnimalLiability,
          WaterBackup: clonedQuote.WaterBackup,
          WaterDamageSublimit: clonedQuote.WaterDamageSublimit,
          WaterDamagePeril: clonedQuote.WaterDamagePeril,
          WindPeril: clonedQuote.WindHailPeril,
          AdditionalTheft: clonedQuote.AdditionalTheft,
          BroadTheft: clonedQuote.BroadTheft,
          LimitedTheft: clonedQuote.LimitedTheft,
          GolfCartPhysicalDamage: clonedQuote.GolfCartPhysicalDamage,
          GreenUpgrades: clonedQuote.GreenUpgrades,
          HO6ExtensionCoverage: clonedQuote.HO6ExtensionCoverage,
          IdentityFraudExp: clonedQuote.IdentityFraudExp,
          LossAssessmentEQ: clonedQuote.LossAssessmentEQ,
          RefrigeratedGoods: clonedQuote.RefrigeratedGoods,
          SpecialComputerCover: clonedQuote.SpecialComputerCover,
          MechanicalBreakdownCover: clonedQuote.MechanicalBreakdownCover,
        }

        return mappedQuote;
      },
      getQuoteValue(value) {
        if (value === 'No Coverage') return 0;

        return value
      },
      save() {
        this.$store.commit('quoteForm/addCurrentRefinementQuote');
        this.close();
      },
      scrollToFirstError() {
        setTimeout(() => {
          const item = document.getElementsByClassName("hasError")[0];
          const viewportOffset = item.getBoundingClientRect();

          document.getElementsByClassName('v-card__text')[0].scrollTo({
            top: viewportOffset.top + window.scrollY,
            left: 0,
            behavior: 'smooth'
          });
        }, 100)
      },
      close() {
        this.$emit('close');
      },
      updateValue(event) {
        if (!event || !this.newQuote) return
        this.newQuote[event.name].value = event.value;
      },
      updateServiceFee(event) {
        if (!event || !this.newQuote) return
        this.newQuote.ServiceFee = event.value;
      },
      updateInspectionFee(event) {
        if (!event || !this.newQuote) return
        this.newQuote.InspectionFee = event.value;
      },
      updateDeductibles(peril, type, event) {
        if (!event || !this.newQuote) return

        this.updateDeductibleValues({
          type,
          peril,
          value: event.value,
          formType: this.newQuote?.FormType?.value?.toUpperCase(),
          windHailPerilValueHolder: this.windHailPerilValueHolder,
          floodPerilValueHolder: this.floodPerilValueHolder,
          earthquakePerilValueHolder: this.earthquakePerilValueHolder,
          CovA: this.newQuote?.CovA,
          CovC: this.newQuote?.CovC,
          deductibleValues: this.deductibleValues
        }, this.salesChannel)
      }
    }
  }
</script>

<style scoped lang="scss">
  .actions {
    position: absolute;
    bottom: 0px;
    background-color: white;
    width: 90%;
  }

  .sticky {
    position: sticky;
  }

  .close-btn {
    position: absolute;
    right: 40px;
  }

  h3 {
    font-weight: bold;
  }

  h4 {
    color: rgba(0, 0, 0, 0.87);
    font-weight: bold;
  }

  hr {
    margin: 20px 0;
  }

  .premium {
    color: rgba(0, 0, 0, 0.87);
    font-weight: bold;
    margin-bottom: 0;
    display: inline-block;
    vertical-align: middle;
  }

  ::v-deep .dfc {
    margin-top: 4px;
    margin-bottom: 4px;
  }

  form {
    padding-bottom: 80px;
  }

  @media screen and (max-width: 768px) {
    form {
      padding-bottom: 150px;
    }
  }
</style>