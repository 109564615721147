//import form from '@/config/quote'
import { createStateForm } from '@/scripts/helper'
import { Module } from 'vuex';
import { QuoteFormState, RootState } from './types';
import Vue from 'vue';

export const state: QuoteFormState = {
  form: null,
  currentStepNumber: 0,
  gateTheSubmissionResponse: [],
  quotes: [],
  refinementListenId: null,
  currentRefinementQuote: null
}

const mutations = {
  clearQuoteForm(state: any) {
    Vue.set(state, 'form', null);
    state.currentStepNumber = 0;
    state.expectedChannels = 0;
    state.quotes = [];
    state.refinementListenId = null;
    state.currentRefinementQuote = null;
  },
  initQuoteForm(state: any, form: any) {
    state.form = createStateForm({ ...form });
    state.currentStepNumber = 0;
    state.expectedChannels = 0;
    state.quotes = [];
  },
  setGateTheSubmissionResponse(state: any, gateTheSubmissionResponse: any[]) {
    Vue.set(state, 'gateTheSubmissionResponse', gateTheSubmissionResponse);
  },
  clearQuotes(state: any) {
    Vue.set(state, 'quotes', []);
  },
  deleteQuote(state: any, salesChannel: string) {
    const quotes: any[] = [...state.quotes];
    const filteredQuotes = quotes.filter(q => q.SalesChannel != salesChannel);
    Vue.set(state, 'quotes', filteredQuotes);
  },
  addCurrentRefinementQuote(state: any) {
    const quotes: any[] = [...state.quotes];

    const quoteIndex = quotes.findIndex((q: any) => q.SalesChannel == state.currentRefinementQuote.SalesChannel);

    if (quoteIndex > -1) {
      quotes[quoteIndex] = state.currentRefinementQuote;
    } else {
      quotes.push(state.currentRefinementQuote);
    }

    Vue.set(state, 'quotes', quotes);
    state.refinementListenId = null;
    state.currentRefinementQuote = null;
  },
  addQuote(state: any, quote: any) {
    if (!state.form) return;
    if (quote.SubmissionId == state.form.ResultId) {

      // catch refinement quotes
      if (quote.id && quote.id === state.refinementListenId) {
        state.currentRefinementQuote = quote;
        return
      }

      const quotes: any[] = [...state.quotes];

      const quoteIndex = quotes.findIndex((q: any) => q.SalesChannel == quote.SalesChannel);

      if (quoteIndex > -1) {
        quotes[quoteIndex] = quote;
      } else {
        quotes.push(quote);
      }


      Vue.set(state, 'quotes', quotes);


    }
  },
  setRefinementListenId(state: any, quoteId: any) {
    state.refinementListenId = quoteId
  }
}

const getters = {
  getGateTheSubmissionResponse: (state: any) => {
    return state.gateTheSubmissionResponse;
  },
  getQuotes: (state: any) => {
    return state.quotes;
  },
  getCurrentRefinementQuote: (state: any) => {
    return state.currentRefinementQuote;
  }
}

export const actions = {

}

const namespaced: boolean = true;

export const quoteForm: Module<QuoteFormState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
}