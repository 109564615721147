var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Dialog',{attrs:{"title":_vm.title,"persistent":true,"value":_vm.value,"scrollable":true,"width":`700px`,"loading":_vm.loading},scopedSlots:_vm._u([{key:"card-text",fn:function(){return [_c('ValidationObserver',{ref:"observer",attrs:{"tag":"span"},scopedSlots:_vm._u([{key:"default",fn:function({ }){return [_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.impersonate.apply(null, arguments)}}},[_c('div',{staticClass:"d-flex"},[_c('DynamicFormControl',{staticClass:"d-inline-block",staticStyle:{"flex-grow":"2","margin-top":"0px"},attrs:{"control":{
                              value: _vm.email,
                              name: 'Search',
                              type: 'TextInput',
                                config: {
                                    label: 'Email of User to Impersonate',
                                    col: 12,
                                    short: true,
                                    rules: _vm.emailRules,
                                    autofocus: true
                                }
                              },"disabled":_vm.loading},on:{"change":function($event){_vm.email = $event.value}}})],1)])]}}])}),_c('v-alert',{attrs:{"outlined":"","text":"","dense":"","type":"warning"}},[_vm._v(" By clicking \"Impersonate,\" I confirm that I have been given the authority to act on this user's behalf. ")])]},proxy:true},{key:"card-actions",fn:function(){return [_c('v-btn',{attrs:{"text":"","color":"secondary","large":""},on:{"click":_vm.close}},[_vm._v(" Close ")]),_c('v-btn',{attrs:{"color":"secondary","type":"submit","large":""},on:{"click":_vm.impersonate,"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.impersonate.apply(null, arguments)}}},[_vm._v(" Impersonate ")])]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }