<template>
  <div>
    <v-container>
      <h1>
        Flood Reinstatment
      </h1>

      <v-row>
        <v-col cols="12" md="8" class="text-right">
          <PolicyServicingHeader :account="account" />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="8">
          <v-card class="py-8 px-8 mb-8">
            <ValidationObserver tag="span" ref="observer">
              <form @submit.prevent="validate()">

                <DynamicFormControl :control="{
                                      name: 'ReinstatementReason',
                                      type: 'Dropdown',
                                      value: reinstatementReason,
                                      config: {
                                          label:'What is the reason for reinstating the policy?',
                                          col: 12,
                                          small: true,
                                          options: reinstatementReasons,
                                          rules: 'required',
                                          short: true,
                                          itemText: 'text',
                                          itemValue: 'value',
                                      }
                                    }"
                                    @change="reinstatementReason = $event.value" />

                <DynamicFormControl v-if="reinstatementReason === 'Other'" :control="{
                                        name: 'ReinstatementReasonOther',
                                        value: reinstatementReasonOther,
                                        type: 'FormTextArea',
                                        config: {
                                            label: 'Other Reinstatement Reason',
                                            rules: 'required',
                                            short: true
                                        }
                                    }"
                                    @change="reinstatementReasonOther = $event.value" />

                <DynamicFormControl :control="{
                                        name: 'TermEndDate',
                                        value: termEndDate,
                                        type: 'TextInput',
                                        config: {
                                            label: 'Term End Date',
                                            short: true,
                                            disabled: true
                                        }
                                    }" />

                <div class="text-right">
                  <v-btn color="white" large :disabled="loading" @click="returnToAccount" class="ml-4">
                    <Loading v-if="loading" :size="24" class="mr-2" />
                    Cancel
                  </v-btn>
                  <v-btn color="secondary" large type="submit" :disabled="loading" class="ml-4">
                    <Loading v-if="loading" :size="24" class="mr-2" />
                    Submit
                  </v-btn>
                </div>
              </form>
            </ValidationObserver>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  import DynamicFormControl from '@/components/form/DynamicFormControl.vue'
  import PolicyServicingHeader from '@/components/views/Account/PolicyServicingHeader.vue'
  import { formatDateForDisplay } from '@/scripts/helper'
  import { AccountService } from '@/api/AccountService'
  import { PolicyService } from '@/api/PolicyService'
  import dateformat from 'dateformat'

  export default {
    name: 'FloodReinstatement',
    components: {
      DynamicFormControl,
      PolicyServicingHeader
    },
    data() {
      return {
        account: null,
        accountId: '',
        loading: false,
        reinstatementReason: '',
        reinstatementReasonOther: null,
        reinstatementReasons: [
          {
            'value': 'User Error',
            'text': 'User Error'
          },
          {
            'value': 'Received Late Payment',
            'text': 'Received Late Payment'
          },
          {
            'value': 'Other',
            'text': 'Other'
          },
        ],
      }
    },    
    mounted() {
      this.accountId = this.$route.query.accountId
      if (this.accountId) {
        this.loading = true;
        AccountService.get({ id: this.accountId })
          .then((data) => {
            this.account = data;
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    computed: {
      termEndDate() {
        if (!this.account) return ''
        if (this.account.TermBegin) {
          let temp = new Date(this.account.TermBegin)
          temp.setFullYear(temp.getFullYear() +1)
          return formatDateForDisplay(temp, true)
        }
        else { 
          return ''
        }
      }
    },
    methods: {
      returnToAccount() {
        this.$router.push({ path: '/account', query: { id: this.account.id } })
      },
      validate() {
        this.loading = true;
        this.$refs.observer.validate()
          .then(valid => {
            if (valid) {
              let reinstatementForm = {};
              reinstatementForm.AccountId = this.accountId;
              reinstatementForm.ReinstatementReason = this.reinstatementReason;
              reinstatementForm.ReinstatementReasonOther = this.reinstatementReasonOther;
              reinstatementForm.TermEndDate = this.termEndDate;

              PolicyService.reinstatementRequest({ body: reinstatementForm })
                .then(() => {
                  this.$router.push({ path: '/account', query: { id: this.account.id } }).then(() => {
                    this.$store.dispatch('toastSuccess', { Message: 'Successfully submitted reinstatement request' })
                  })
                })
                .catch((err) => {
                  this.$store.dispatch('toastError', { Message: 'Failed to process reinstatement request' })
                })
            }

            this.loading = false;
          });
      }
    }
  }
</script>
