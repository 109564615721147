var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-container',[_c('h1',[_vm._v(" Lookup "),_c('BetaFeatureTag',{staticClass:"d-inline-block"})],1),_c('v-card',{staticClass:"mb-4"},[_c('v-card-title',[_vm._v(" Agent ")]),_c('v-card-text',[_c('v-row',[_c('v-col',{staticClass:"py-0 mb-3",attrs:{"cols":"12"}},[_c('DynamicFormControl',{attrs:{"control":{
                                    type: 'AgentAutocomplete',
                                    name: 'Agent',
                                    config: {
                                        label:'Retail Agent'
                                    }
                                }}})],1)],1)],1)],1),_c('v-card',{staticClass:"mb-4"},[_c('v-card-title',[_vm._v(" Underwriter ")]),_c('v-card-text',[_c('v-row',[_c('v-col',{staticClass:"py-0 mb-3",attrs:{"cols":"12"}},[_c('DynamicFormControl',{attrs:{"control":{
                                    name: 'Underwriter',
                                    type: 'UnderwriterAutocomplete',
                                    value: _vm.underwriter,
                                    config: {
                                        label: 'Principal Amwins Underwriter',
                                        simple: true,
                                        short: true,
                                        clearable: false
                                    }
                                }}})],1)],1)],1)],1),_c('v-card',{staticClass:"mb-4"},[_c('v-card-title',[_vm._v(" Mortgagee ")]),_c('v-card-text',[_c('v-row',[_c('v-col',{staticClass:"py-0 mb-3",attrs:{"cols":"12"}},[_c('DynamicFormControl',{attrs:{"control":{
                                    name: 'MortgageeOrg',
                                    type: 'MortgageeOrgAutocomplete',
                                    value: _vm.mortgageeOrg,
                                    config: {
                                        label: 'Mortgagee Organization',
                                        short: true
                                    }
                                }},on:{"change":_vm.updateMortgageeOrg}})],1),(_vm.mortgageeOrg)?_c('v-col',{staticClass:"py-0 mb-2",attrs:{"cols":"12"}},[_c('div',{key:"addressSearch"},[_c('DynamicFormControl',{attrs:{"control":{
                                      name: 'Mortgagee',
                                      type: 'MortgageeAutocomplete',
                                      value: _vm.mortgageeOrgBranch,
                                      config: {
                                          label: 'Mortgagee Location Address',
                                          short: true,
                                          MortgageeOrgID: _vm.mortgageeOrg.ID
                                      }
                                  }}})],1)]):_vm._e()],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }