<template>
  <div>
    <v-container>
      <h1>Account Teleporter</h1>

      <v-card class="mb-4">
        <v-card-title>
          Upstream Account
        </v-card-title>
        <v-card-text>
          <ValidationObserver tag="span" v-slot="{}" ref="observer1">
            <v-form ref="form">
              <v-row>
                <v-col md="6"
                       lg="4">
                  <DynamicFormControl :control="{
                                        name: 'Account',
                                        type: 'TextInput',
                                        config: {
                                            label: 'Account Id',
                                            rules: idRules,
                                            col: 12,
                                            short: true,
                                            clearable: true
                                        }
                                      }"
                                      style="margin-top: 0px;"
                                      @change="accountId = $event.value" />
                </v-col>
                <v-col md="6"
                       kg="8">
                  <FormButton label="Submit" size="x-large" @click="fetchAccount" :disabled="loading" />
                </v-col>
              </v-row>
            </v-form>
          </ValidationObserver>
        </v-card-text>
      </v-card>

    </v-container>
  </div>
</template>

<script>
  import DynamicFormControl from '@/components/form/DynamicFormControl.vue'
  import FormButton from '@/components/form/FormButton.vue'
  import { AccountService } from '@/api/AccountService'

  export default {
    name: 'AccountTeleporter',
    components: {
      DynamicFormControl,
      FormButton
    },
    data() {
      return {
        loading: false,
        idRules: 'required',
        accountId: 0,
      }
    },
    methods: {
      fetchAccount() {
        this.$refs.observer1.validate()
          .then((valid) => {
            if (valid) {
              this.loading = true
              AccountService.teleport({ id: this.accountId })
                .then(() => {
                  this.$store.dispatch('toastSuccess', { Message: 'Successfully Fetched Upstream Account' })
                })
                .finally(() => {
                  this.loading = false
                })
            }
          });
      },
    }
  }
</script>
