<template>
  <div>
    <v-container>
      <v-card class="mb-4" :loading="messageQueueTopologyLoading">
        <v-card-text>
          <ResultsTable :tableHeaders="tableHeaders"
            :results="subscribingApplications"
            :loading="messageQueueTopologyLoading"
            :sort-by="sortBy"
            :sort-desc="desc"
            :rowkey="applicationName"
            showExpand>
            
            <template v-slot:ApplicationName="{item}">
              <span>
                {{ item.ApplicationName }}
              </span>
            </template>

            <template v-slot:DeadLetterQueueCount="{item}">
              <span>
                <v-chip :color="getColor(item.DeadLetterQueueCount)">
                  {{ item.DeadLetterQueueCount }}
                </v-chip>
              </span>
            </template>

            <template v-slot:expansion-panel="{item}">
              <v-simple-table>
                <thead>
                    <tr>
                        <th class="text-left">Topic</th>
                        <th class="text-left">Dead Letter Queue Count</th>
                        <th></th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                    v-for="topic in item.SubscriptionTopics"
                    :key="topic.Topic">
                        <td>{{ topic.Topic }}</td>
                        <td><v-chip :color="getColor(topic.DeadLetterQueueCount)">{{ topic.DeadLetterQueueCount }}</v-chip></td>
                        <td><v-btn @click="launchServiceBusExplorer(item.ApplicationName, topic.Topic)" color="secondary">View Service Bus Explorer</v-btn></td>
                        <td><v-btn v-if="topic.DeadLetterQueueCount > 0" @click="launchDeadLetterQueue(item.ApplicationName, topic.Topic)" color="secondary">View Dead Letter Queue</v-btn></td>
                    </tr>
                </tbody>
              </v-simple-table>
            </template>
          </ResultsTable>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import ResultsTable from '@/components/shared/ResultsTable.vue'
import Environment from '@/scripts/environment'

export default {
  name: 'MessageQueueTopologyTable',
  components: {
    ResultsTable
  },
  props: {
    messageQueueTopologyLoading: Boolean,
    subscribingApplications: []
  },
  data() {
    return {
      tableHeaders: [
        { text: "Application Name", value: "ApplicationName", sortable: true, filterable: true, key: 'ApplicationName' },
        { text: "Dead Letter Queue Count", value: "DeadLetterQueueCount", sortable: true, filterable: true, key: 'DeadLetterQueueCount' }
      ],
      applicationName: "ApplicationName",
      sortBy: "DeadLetterQueueCount",
      desc: true
    }
  },
  methods: {
    getColor (count) {
        if (count >= 10) return 'red'
        else if (count >= 1) return 'orange'
        else return 'green'
    },
    launchServiceBusExplorer(applicationName, topic) {
        const url = `https://portal.azure.com/#@amwinsusa.onmicrosoft.com/resource/subscriptions/${Environment.SERVICEBUS_SUBSCRIPTION_ID}/resourceGroups/${Environment.SERVICEBUS_RESOURCE_GROUP}/providers/Microsoft.ServiceBus/namespaces/${Environment.SERVICEBUS_NAMESPACE}/topics/${topic}/subscriptions/${applicationName}/explorer`;
        window.open(url, '_blank');
    },
    launchDeadLetterQueue(applicationName, topic) {
        const routePath = "message-queue-deadletters/" + applicationName + "/" + topic;
        const route = this.$router.resolve(routePath);
        window.open(route.href, '_blank');
    }
  }
}
</script>