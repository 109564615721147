import { MoratoriumService } from '@/api/MoratoriumService'

import testDataModule from '@/vuex/testDataModule';



export const isRiskUnderMoratorium = async (submissionId: string, quoteId: string) => {
    if (process.env.NODE_ENV === 'development') {
        const moratoriumsForRiskResponse = testDataModule.state.moratoriumResponseTestData; 
        return moratoriumsForRiskResponse.IsUnderMoratorium;
    }
    
    return await MoratoriumService.checkIfQuoteUnderMoratorium({ submissionId: submissionId, selectedQuoteId: quoteId });
}

export const isRiskUnderMoratoriumForPolicy = async (policyId: string, effectiveDate?: string) => {
    if (process.env.NODE_ENV === 'development') {
        const moratoriumsForRiskResponse = testDataModule.state.moratoriumResponseTestData; 
        return moratoriumsForRiskResponse.IsUnderMoratorium;
    }

    return await MoratoriumService.checkIfPolicyUnderMoratorium({ policyId: policyId, transactionEffectiveDate: effectiveDate });
}