<template>
  <div>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-chip small v-bind="attrs" v-on="on" color="#00B3E3" class="font-weight-medium white--text">
          Beta
        </v-chip>
      </template>
      <span>This feature has not been fully tested. Visit 'My Profile' to disable Beta features.</span>
    </v-tooltip>
  </div>
</template>

<script>

  export default {
    name: 'BetaFeatureTag',
    data() {
      return {

      }
    },
  }

</script>