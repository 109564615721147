import { Module } from 'vuex';
import { HealthState, RootState } from './types';
import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { HealthCheckService } from '@/api/HealthCheckService'
import { ActionContext } from 'vuex';
import testDataModule from '@/vuex/testDataModule';


export const state: HealthState = {
  appHealthChecks: [],
  healthcheckLoading: false

}

const mutations = {
  setAppHealthChecks: (state: any, items: any[]) => {
    state.appHealthChecks = items;
   // state.healthcheckLoadingg = false;

  },
  setLoading: (state: any, loading: boolean) => {
    state.healthcheckLoading = loading;
  }
 
}



const getters = {
 
 
  overallHealthStatus(state: any): string {
    const reports = state.appHealthChecks;

    if (reports.some((appHealthCheck: { HealthReport: { Status: string } }) => appHealthCheck.HealthReport.Status === 'Unhealthy')) {
      return 'Unhealthy';
    } else if (reports.some((appHealthCheck: { HealthReport: { Status: string } }) => appHealthCheck.HealthReport.Status === 'Degraded')) {
      return 'Degraded';
    } else {
      return 'Healthy';
    }
  },
  isHealthy(state: any): boolean {
    return getters.overallHealthStatus(state) === 'Healthy';
  },

  isDegraded(state: any): boolean {
    return getters.overallHealthStatus(state) === 'Degraded';
  },

  isUnhealthy(state: any): boolean {
    return getters.overallHealthStatus(state) === 'Unhealthy';
  },

  // Returns array of objects for all closings
  getItems(state: any): [] {
    return state.appHealthChecks;
  }

}

const actions: ActionTree<HealthState, RootState> = {
  fetchAppHealthChecks: ({ commit, state, dispatch }: ActionContext<HealthState, RootState>) => {
    commit('setLoading', true);
    const isLocalEnvironment = process.env.NODE_ENV === 'development';
    if (isLocalEnvironment) {
      console.log('using test health data data');
      commit('setAppHealthChecks', testDataModule.state.appHealthTestData);
      commit('setLoading', false);
    } else {
      
      HealthCheckService.healthChecks()
      .then((response) => {
        commit('setAppHealthChecks', response.Reports);
      })
      .finally(() => {
        commit('setLoading', false);
       
        
      });
    

    }

  },
  removeApp: ({ commit, state, dispatch }: ActionContext<HealthState, RootState>, data: string) => {
    commit('setLoading', true);
    const isLocalEnvironment = process.env.NODE_ENV === 'development';
 
      
      HealthCheckService.removeApp({
        appName: data})
      .then((response) => {
        commit('setAppHealthChecks', response.Reports);
      })
      .finally(() => {
        commit('setLoading', false);
       
        
      });
    

    

  }
 
  
}

const namespaced: boolean = true;

export const health: Module<HealthState, RootState> = {
    namespaced,
    state,
    getters,
    actions,
    mutations
}
