import Environment from '@/scripts/environment';

export default {

 init : (name, email) => {
  
  if(window.Five9SocialWidget){
    window.Five9SocialWidget.removeWidget();
  }
  var options = {
    "rootUrl": "https://app.five9.com/consoles/",
    "type": "chat",
    "title": "Chat with Us",
    "tenant": "AmWINS NIA",
    "profiles": "Access Digital Chat",
    "showProfiles": false,
    "autostart": true,
    "theme": Environment.UI_HOST + "/css/five9.css",
    "surveyOptions": {
      "showComment": false,
      "requireComment": false
    },
    "fields": {
      "name": {
        "value": name,
        "show": true,
        "label": "Name",
        "required": true
      },
      "email": {
        "value": email,
        "show": true,
        "label": "Email",
        "required": true
      },
      "Named_Insured": {
        "value": "",
        "show": true,
        "label": "Named Insured",
        "required": false
      },
      "Policy_Quote": {
        "value": "",
        "show": true,
        "label": "Policy/Quote Number",
        "required": false
      }
    },
    "playSoundOnMessage": true,
    "allowCustomerToControlSoundPlay": false,
    "showEmailButton": false,
    "hideDuringAfterHours": false,
    "useBusinessHours": false,
    "showPrintButton": false,
    "allowUsabilityMenu": false,
    "enableCallback": false,
    "callbackList": "",
    "allowRequestLiveAgent": false
  };
  window.Five9SocialWidget.addWidget(options);
}

}