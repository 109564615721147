<template>
  <div>
    

    <v-dialog
      v-model="show"
      width="1000"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn small text color="secondary" style="margin-top: 14px;" 
          v-bind="attrs"
          v-on="on">
          More Info
        </v-btn>
      </template>

      <v-card class="pa-4">
        <v-card-title class="mb-4">
          <h2 v-html="title"></h2>
          <v-spacer></v-spacer>
          <v-btn icon @click="show=false" style="float: right;" type="button" x-small height="20" width="20" >
            <v-icon color="primary">fa-times</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-row class="d-flex align-stretch">
            <v-col sm="4" cols="12" v-for="item in items" :key="item.title">
              <v-card style="height: 100%;">
                <v-img height="180" :src="item.image" v-if="item.image"></v-img>
                <div class="pa-4">
                  <h3 v-html="item.title" class="mb-2"></h3>
                  <p v-html="item.content" class="mb-0"></p>
                </div>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>

      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'MoreInfoDialog',
  data() {
    return {
      show: false,
    }
  },
  props: {
    title: String,
    items: Array
  }
}
</script>

<style scoped lang="scss">
@import '@/style.scss';

  ::v-deep .v-card__text {
    color: $amwins-blue!important;
  }

  ::v-deep p {
    font-size: 14px;
    line-height: 16px;
  }

</style>
