import {
  Agent,
  License,
  IList,
  List,
  IListResult,
  ListResultDto,
  IPagedResult,
  PagedResultDto,
  Dictionary,
  IDictionary,
  IRequestOptions,
  IRequestConfig,
  getConfigs,
  axios,
  basePath
} from './index.defs';

export class AgentService {
  /** Generate by swagger-axios-codegen */
  // @ts-nocheck
  /* eslint-disable */

  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Agent> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Agent';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static find(
    params: {
      /**  */
      agencyId?: string;
      /**  */
      license?: string;
      /**  */
      producer?: boolean;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Agent[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Agent/Find';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { agencyId: params['agencyId'], license: params['license'], producer: params['producer'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static seek(
    params: {
      /**  */
      q?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Agent[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Agent/Seek';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { q: params['q'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static sync(
    params: {
      /**  */
      humanId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Agent/Sync';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { humanId: params['humanId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static underwriterAgents(
    params: {
      /**  */
      oldUnderwriterEmail?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Agent/UnderwriterAgents';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { OldUnderwriterEmail: params['oldUnderwriterEmail'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static underwriterReassignments(
    params: {
      /**  */
      fileName?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Agent/UnderwriterReassignments';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { fileName: params['fileName'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}
