<template>

  <div>

    <v-card class="mb-4">
      <v-card-title class="ml-4">
        Endorsement Summary
      </v-card-title>
      <v-card-text>
        <span class="ml-4">
          The following changes were recently made to the policy:
        </span>

        <v-card style="max-width: 800px;" class="ma-4">
          <v-card-text class="py-0">
            <v-container class="pa-0">
              <template v-for="(item, index) in changeset">
                <div :key="item.controlName">
                  <v-row class="my-2">

                    <v-col cols="12" sm="2">
                      <ReadOnlyDisplay label="Effective Date">
                        <label slot="content" class="d-inline-block">{{endorsementEffectiveDate}}</label>
                      </ReadOnlyDisplay>
                    </v-col>

                    <v-col class="d-none d-sm-flex" cols="1">
                      <v-divider vertical></v-divider>
                    </v-col>

                    <v-col cols="12" sm="4">
                      <ReadOnlyDisplay :label="'Current ' + item.Element.Label">
                        <label v-if="isV2SalesChannel" slot="content" class="d-inline-block">{{item.Prior}}</label>
                        <label v-else slot="content" class="d-inline-block">{{formatChangesetValueForDisplay(item.Prior)}}</label>
                      </ReadOnlyDisplay>
                    </v-col>

                    <v-col class="d-none d-sm-flex" cols="1">
                      <v-divider vertical></v-divider>
                    </v-col>

                    <v-col cols="12" sm="4">
                      <ReadOnlyDisplay :label="'New ' + item.Element.Label">
                        <label v-if="isV2SalesChannel" slot="content" class="text-bold d-inline-block pr-2">{{item.Altered}}</label>
                        <label v-else slot="content" class="text-bold d-inline-block pr-2">{{formatChangesetValueForDisplay(item.Altered)}}</label>
                        <span v-if="isV2SalesChannel" slot="content" class="price-change"></span> <!--TODO: add the delta for v2-->
                        <span v-else slot="content" class="price-change">{{priceDifference(item.Prior, item.Altered)}}</span>
                      </ReadOnlyDisplay>
                    </v-col>

                  </v-row>

                  <v-divider v-if="index != changeset.length - 1" />
                </div>
              </template>
            </v-container>
          </v-card-text>
        </v-card>

      </v-card-text>
    </v-card>

    <v-card class="mb-4">
      <v-card-title class="ml-4">
        Premium Updates
      </v-card-title>
      <v-card-text>
        <div class="ml-4 pb-2">
          The following changes were recently made to the premium:
        </div>

        <v-card class="ma-4" style="max-width: 800px;">
          <v-card-text class="py-0">
            <v-container class="pa-0">
              <v-row class="my-2">

                <v-col cols="12" sm="2">
                  <ReadOnlyDisplay label="Effective Date">
                    <label slot="content" class="d-inline-block">{{endorsementEffectiveDate}}</label>
                  </ReadOnlyDisplay>
                </v-col>

                <v-col class="d-none d-sm-flex" cols="1">
                  <v-divider vertical></v-divider>
                </v-col>

                <v-col cols="12" sm="4">
                  <ReadOnlyDisplay label="Current Premium">
                    <label slot="content" class="d-inline-block">{{formatMoney(premium, true)}}</label>
                  </ReadOnlyDisplay>
                </v-col>

                <v-col class="d-none d-sm-flex" cols="1">
                  <v-divider vertical></v-divider>
                </v-col>

                <v-col cols="12" sm="4">
                  <ReadOnlyDisplay label="New Total Premium">
                    <label slot="content" class="text-bold d-inline-block pr-2">{{formatMoney(newPremium, true)}}</label>
                    <span slot="content" class="price-change">{{priceDifference(premium, newPremium)}}</span>
                  </ReadOnlyDisplay>
                </v-col>

              </v-row>
            </v-container>
          </v-card-text>
        </v-card>

      </v-card-text>

      <v-card-text class="py-0 ml-0">
        <span class="ml-4 text-bold">
          Cost Breakdown:
        </span>

        <v-container class="ml-0 pt-0" style="max-width: 650px;">
          <v-row>
            <v-col cols="8">
              <DynamicFormControl :control="{
                                  type: 'RadioButtonGroup',
                                  name: 'CostPeriod',
                                  value: costPeriod,
                                  config: {
                                      'rules': 'required',
                                      'cols': 12,
                                      'default': true,
                                      'options': costPeriodItems,
                                      'mandatory': true
                                      }
                                  }"
                                  @change="costPeriodChanged($event.value)" />
            </v-col>
          </v-row>
          <v-row class="mt-0">
            <v-col cols="5">
              Sub-Total:
            </v-col>
            <v-col cols="7" sm="4" md="3" class="text-right">
              <label class="breakdown-item">{{formatMoney(pricing.Premium, true)}}</label>
            </v-col>
          </v-row>
          <v-row class="mt-0">
            <v-col cols="5">
              Amwins Service Fee:
            </v-col>
            <v-col cols="7" sm="4" md="3" class="text-right">
              <label class="breakdown-item">{{formatMoney(pricing.Fee, true)}}</label>
            </v-col>
          </v-row>
          <template v-for="item in pricing.Assessments">
            <v-row class="mt-0" :key="item.Name">
              <v-col cols="5">
                {{ item.Name }}:
              </v-col>
              <v-col cols="7" sm="4" md="3" class="text-right">
                <label class="breakdown-item">{{formatMoney(item.Amount, true)}}</label>
              </v-col>
            </v-row>
          </template>
          <v-row class="mt-0 mb-0">
            <v-col cols="5">
              Tax Total:
            </v-col>
            <v-col cols="7" sm="4" md="3" class="text-right">
              <label class="breakdown-item">{{formatMoney(sumTaxes(pricing.Assessments), true)}}</label>
            </v-col>
          </v-row>

          <v-row class="mt-0 mb-0">
            <v-col class="py-0" cols="12" sm="10" md="8">
              <v-divider />
            </v-col>
          </v-row>

          <v-row class="mt-0 mb-2">
            <v-col cols="5">
              Total Premium:
            </v-col>
            <v-col cols="7" sm="4" md="3" class="text-right">
              <label class="breakdown-total">{{formatMoney(calculateTotalPremiumBreakDown(), true)}}</label>
            </v-col>
          </v-row>
        </v-container>

      </v-card-text>
    </v-card>

    <v-card v-if="calculateTotalPremium(transactionPricing) > 0" class="px-1 mb-4">
      <v-card-title class="ml-4 pb-0">
        Payment Details
      </v-card-title>
      <v-card-text>
        <v-container class="pt-0">
          <ValidationObserver tag="span" ref="paymentObserver">
            <form @submit.prevent="validate()">
              <DynamicFormControl :control="{
                                    type: 'Payment',
                                    name: 'Payment',
                                    config: {
                                      rules: 'payment',
                                      short: true,
                                      endorsement: true
                                    }
                                  }"
                                  :module="'endorsementForm'" />
            </form>
          </ValidationObserver>
        </v-container>
      </v-card-text>
    </v-card>

    <v-card v-if="showCommsComponent" class="px-1 mb-4">
      <v-card-title class="ml-4 pb-0">
        Policy Communication
      </v-card-title>
      <v-card-text>
        <v-container class="pt-0">
          <ValidationObserver tag="span" ref="insuredEmailOptionsObserver">
            <form @submit.prevent="validate()">
              <DynamicFormControl :control="{
                                    type: 'InsuredEmailOptions',
                                    name: 'InsuredEmailOptions',
                                    config: {
                                      rules: 'requireInsuredEmailOptions',
                                      short: true,
                                      endorsement: true
                                    }
                                  }"
                                  :module="'endorsementForm'" />
            </form>
          </ValidationObserver>
        </v-container>
      </v-card-text>
    </v-card>

    <v-card class="px-1">
      <v-card-title class="ml-4 pb-0">
        Attestation
      </v-card-title>
      <v-card-text>
        <v-container class="pt-0">
          <ValidationObserver tag="span" ref="attestationObserver">
            <form @submit.prevent="validate()">
              <DynamicFormControl :control="{
                                    name: 'AgentAttestation',
                                    type: 'Attestation',
                                    value: attestation, 
                                    config: {
                                      rules: 'required',
                                      useGlobalAgentAttestation: true,
                                      col: 12,
                                      label: ''
                                    }
                                  }"
                                  @change="attestation = $event.value"
                                  :module="'endorsementForm'" />
            </form>
          </ValidationObserver>
        </v-container>
      </v-card-text>
    </v-card>

    <v-overlay :value="loading" :z-index="1">
      <Loading :size="64" />
    </v-overlay>

  </div>

</template>

<script>
  import ReadOnlyDisplay from '@/components/form/ReadOnlyDisplay.vue'
  import DynamicFormControl from '@/components/form/DynamicFormControl.vue'
  import { formatMoney, calculateTotalPremium } from '@/scripts/helper'

  export default {
    name: 'EndorsementReview',
    components: {
      ReadOnlyDisplay,
      DynamicFormControl
    },
    props: {
      premium: {
        type: Number,
        default: null
      },
      newPremium: {
        type: Number,
        default: null
      },
      endorsementEffectiveDate: {
        type: String,
        default: null
      },
      changeset: {
        type: Array,
        default: null
      },
      transactionPricing: {
        type: Object,
        default: null
      },
      annualPricing: {
        type: Object,
        default: null
      },
      loading: {
        type: Boolean,
        default: false
      },
      validateForm: {
        type: Boolean,
        default: false
      },
      policy: {
        type: Object,
        default: null
      },
      isV2SalesChannel: {
        type: Boolean,
        default: false
      },
    },
    emits: [
      'valid',
    ],
    data() {
      return {
        costPeriod: 'Annual',
        costPeriodItems: [
          {
            "value": 'Annual',
            "text": 'Annual'
          },
          {
            "value": 'Prorated',
            "text": 'Prorated'
          }
        ],
        attestation: null
      }
    },
    watch: {
      async validateForm(val) {
        if (val) {
          await this.validate()
        }
      }
    },
    computed: {
      documentButtonAlignment() {
        switch (this.$vuetify.breakpoint.name) {
          case 'xs': return 'left'
          default: return 'right'
        }
      },
      pricing() {
        if (!this.annualPricing || !this.transactionPricing) return {}

        if (this.costPeriod === 'Annual') {
          return this.annualPricing
        } else {
          return this.transactionPricing
        }
      },
      paymentRules() {
        if (this.isV2SalesChannel) {
          return ''
        } else {
          return 'required'
        }
      },
      showCommsComponent () {
        return this.$store.getters.isFeatureFlagEnabled("ePayPaymentOptions");
      }
    },
    methods: {
      formatMoney,
      calculateTotalPremium,
      formatChangesetValueForDisplay(value) {
        if (!isNaN(value)) {
          const val = parseFloat(value)

          if (!isNaN(val)) {
            return this.formatMoney(val)
          }
        }

        return value
      },
      priceDifference(oldValue, newValue) {
        if (!oldValue || !newValue) return ''

        if (isNaN(oldValue) || isNaN(newValue)) return ''

        const oldVal = parseFloat(oldValue);
        const newVal = parseFloat(newValue);

        if (isNaN(oldVal) || isNaN(newVal)) return ''

        const priceChange = newVal - oldVal
        if (priceChange === 0) return ''

        let priceChangeDisplay = this.formatMoney(priceChange)
        if (priceChange < 0) {
          const negativeAmount = priceChangeDisplay.substring(2)
          priceChangeDisplay = '-$' + negativeAmount
        }

        return priceChange > 0 ? '+' + priceChangeDisplay : priceChangeDisplay
      },
      costPeriodChanged(val) {
        this.costPeriod = val       
      },
      //proratedValue(value) {
      //  if (!value || value === 0) {
      //    return 0;
      //  }

      //  // TODO: may need to calculate prorated rate
      //  const _MS_PER_DAY = 1000 * 60 * 60 * 24;
      //  let dayDiff = 30
      //  const newEffectiveDate = new Date(this.endorsementEffectiveDate)
      //  const oldEffectiveDate = new Date(this.oldEffectiveDate)

      //  const oldEffectiveDateUtc = Date.UTC(oldEffectiveDate.getFullYear(), oldEffectiveDate.getMonth(), oldEffectiveDate.getDate());
      //  const newEffectiveDateUtc = Date.UTC(newEffectiveDate.getFullYear(), newEffectiveDate.getMonth(), newEffectiveDate.getDate());

      //  dayDiff = Math.floor((newEffectiveDateUtc - oldEffectiveDateUtc) / _MS_PER_DAY);

      //  return (dayDiff / 365) * value
      //},      
      sumTaxes(assessments) {
        if (!assessments || assessments.length == 0) return 0

        let taxTotal = 0;

        assessments.forEach((assessment) => {
          if (assessment.Amount) {
            taxTotal += assessment.Amount
          }
        })

        return taxTotal
      },
      calculateTotalPremiumBreakDown() {
        let pricing = {}

        if (this.costPeriod === 'Annual') {
          pricing = this.annualPricing
        } else {
          pricing = this.transactionPricing
        }
        return this.calculateTotalPremium(pricing)
      },
      async validate() {
        let paymentIsValid = false
        let attestationIsValid = false
        let insuredEmailOptionsIsValid = false

        if (this.calculateTotalPremium(this.transactionPricing) > 0) {
          await this.$refs.paymentObserver.validate()
            .then(valid => {
              if (valid) {
                paymentIsValid = true
              } else {
                this.scrollToFirstError();
                paymentIsValid = false
              }
            });
        } else {
          paymentIsValid = true
        }

        await this.$refs.insuredEmailOptionsObserver.validate()
          .then(valid => {
            if (valid) {
              insuredEmailOptionsIsValid = true
            } else {
              this.scrollToFirstError();
              insuredEmailOptionsIsValid = false
            }
          });

        await this.$refs.attestationObserver.validate()
          .then(valid => {
            if (valid) {
              attestationIsValid = true
            } else {
              this.scrollToFirstError();
              attestationIsValid = false
            }
          });

        this.$emit('valid', paymentIsValid && attestationIsValid && insuredEmailOptionsIsValid)
      },
      scrollToFirstError() {
        const item = this.$el.getElementsByClassName("hasError")[0];
        const viewportOffset = item.getBoundingClientRect();

        const header = document.getElementsByTagName("header")[0];
        const headerHeight = header.offsetHeight;

        window.scrollTo({
          top: viewportOffset.top + window.scrollY - (60 + headerHeight),
          left: 0,
          behavior: 'smooth'
        });
      },
    }
  }
</script>

<style scoped lang="scss">
  @import '@/style.scss';

  .price-change {
    font-size: 12px;
    color: darkorange;
  }

  .theme--light.v-card > .v-card__text label,
  .theme--light.v-card > .v-card__subtitle label {
    color: rgba(0, 0, 0, 0.87);
  }

  .breakdown-item {
    font-weight: bold
  }

  .breakdown-total {
    font-size: 18px;
    font-weight: bold
  }
</style>