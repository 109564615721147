export const UnitTypes: any[] = [
  {
    "Key":"UNIT",
    "Name":"Unit",
    "IsRange":true,
    "CONDO": true,
    "HOME": true
  },
  {
    "Key":"BLDG",
    "Name":"Building",
    "IsRange":true,
    "CONDO": false,
    "HOME": true
  },
  {
    "Key":"PH",
    "Name":"Penthouse",
    "IsRange":false,
    "CONDO": true,
    "HOME": false
  },
  {
    "Key":"#",
    "Name":"No Unit Number",
    "IsRange":false,
    "CONDO": true,
    "HOME": false
  }
];

export const StateNames = [
  {
    "name": "Alabama",
    "abbreviation": "AL"
  },
  {
    "name": "Alaska",
    "abbreviation": "AK"
  },
  {
    "name": "American Samoa",
    "abbreviation": "AS"
  },
  {
    "name": "Arizona",
    "abbreviation": "AZ"
  },
  {
    "name": "Arkansas",
    "abbreviation": "AR"
  },
  {
    "name": "California",
    "abbreviation": "CA"
  },
  {
    "name": "Colorado",
    "abbreviation": "CO"
  },
  {
    "name": "Connecticut",
    "abbreviation": "CT"
  },
  {
    "name": "Delaware",
    "abbreviation": "DE"
  },
  {
    "name": "District Of Columbia",
    "abbreviation": "DC"
  },
  {
    "name": "Federated States Of Micronesia",
    "abbreviation": "FM"
  },
  {
    "name": "Florida",
    "abbreviation": "FL"
  },
  {
    "name": "Georgia",
    "abbreviation": "GA"
  },
  {
    "name": "Guam",
    "abbreviation": "GU"
  },
  {
    "name": "Hawaii",
    "abbreviation": "HI"
  },
  {
    "name": "Idaho",
    "abbreviation": "ID"
  },
  {
    "name": "Illinois",
    "abbreviation": "IL"
  },
  {
    "name": "Indiana",
    "abbreviation": "IN"
  },
  {
    "name": "Iowa",
    "abbreviation": "IA"
  },
  {
    "name": "Kansas",
    "abbreviation": "KS"
  },
  {
    "name": "Kentucky",
    "abbreviation": "KY"
  },
  {
    "name": "Louisiana",
    "abbreviation": "LA"
  },
  {
    "name": "Maine",
    "abbreviation": "ME"
  },
  {
    "name": "Marshall Islands",
    "abbreviation": "MH"
  },
  {
    "name": "Maryland",
    "abbreviation": "MD"
  },
  {
    "name": "Massachusetts",
    "abbreviation": "MA"
  },
  {
    "name": "Michigan",
    "abbreviation": "MI"
  },
  {
    "name": "Minnesota",
    "abbreviation": "MN"
  },
  {
    "name": "Mississippi",
    "abbreviation": "MS"
  },
  {
    "name": "Missouri",
    "abbreviation": "MO"
  },
  {
    "name": "Montana",
    "abbreviation": "MT"
  },
  {
    "name": "Nebraska",
    "abbreviation": "NE"
  },
  {
    "name": "Nevada",
    "abbreviation": "NV"
  },
  {
    "name": "New Hampshire",
    "abbreviation": "NH"
  },
  {
    "name": "New Jersey",
    "abbreviation": "NJ"
  },
  {
    "name": "New Mexico",
    "abbreviation": "NM"
  },
  {
    "name": "New York",
    "abbreviation": "NY"
  },
  {
    "name": "North Carolina",
    "abbreviation": "NC"
  },
  {
    "name": "North Dakota",
    "abbreviation": "ND"
  },
  {
    "name": "Northern Mariana Islands",
    "abbreviation": "MP"
  },
  {
    "name": "Ohio",
    "abbreviation": "OH"
  },
  {
    "name": "Oklahoma",
    "abbreviation": "OK"
  },
  {
    "name": "Oregon",
    "abbreviation": "OR"
  },
  {
    "name": "Palau",
    "abbreviation": "PW"
  },
  {
    "name": "Pennsylvania",
    "abbreviation": "PA"
  },
  {
    "name": "Puerto Rico",
    "abbreviation": "PR"
  },
  {
    "name": "Rhode Island",
    "abbreviation": "RI"
  },
  {
    "name": "South Carolina",
    "abbreviation": "SC"
  },
  {
    "name": "South Dakota",
    "abbreviation": "SD"
  },
  {
    "name": "Tennessee",
    "abbreviation": "TN"
  },
  {
    "name": "Texas",
    "abbreviation": "TX"
  },
  {
    "name": "Utah",
    "abbreviation": "UT"
  },
  {
    "name": "Vermont",
    "abbreviation": "VT"
  },
  {
    "name": "Virgin Islands",
    "abbreviation": "VI"
  },
  {
    "name": "Virginia",
    "abbreviation": "VA"
  },
  {
    "name": "Washington",
    "abbreviation": "WA"
  },
  {
    "name": "West Virginia",
    "abbreviation": "WV"
  },
  {
    "name": "Wisconsin",
    "abbreviation": "WI"
  },
  {
    "name": "Wyoming",
    "abbreviation": "WY"
  }
]

export const Countries: any[] = [
  {
      "CountryCode": "US",
      "CountryName": "USA",
      "CountryOrderNr": 1
  },
  {
    "CountryCode": "CA",
    "CountryName": "Canada",
    "CountryOrderNr": 35
  },
  {
      "CountryCode": "AL",
      "CountryName": "Albania",
      "CountryOrderNr": 2
  },
  {
      "CountryCode": "DZ",
      "CountryName": "Algeria",
      "CountryOrderNr": 3
  },
  {
      "CountryCode": "AS",
      "CountryName": "American Samoa",
      "CountryOrderNr": 4
  },
  {
      "CountryCode": "AD",
      "CountryName": "Andorra",
      "CountryOrderNr": 5
  },
  {
      "CountryCode": "AO",
      "CountryName": "Angola",
      "CountryOrderNr": 6
  },
  {
      "CountryCode": "AI",
      "CountryName": "Anguilla",
      "CountryOrderNr": 7
  },
  {
      "CountryCode": "AG",
      "CountryName": "Antigua",
      "CountryOrderNr": 8
  },
  {
      "CountryCode": "AR",
      "CountryName": "Argentina",
      "CountryOrderNr": 9
  },
  {
      "CountryCode": "AM",
      "CountryName": "Armenia",
      "CountryOrderNr": 10
  },
  {
      "CountryCode": "AW",
      "CountryName": "Aruba",
      "CountryOrderNr": 11
  },
  {
      "CountryCode": "AU",
      "CountryName": "Australia",
      "CountryOrderNr": 12
  },
  {
      "CountryCode": "AT",
      "CountryName": "Austria",
      "CountryOrderNr": 13,
      "IsEuropeanUnion": true
  },
  {
      "CountryCode": "AZ",
      "CountryName": "Azerbaijan",
      "CountryOrderNr": 14
  },
  {
      "CountryCode": "BS",
      "CountryName": "Bahamas",
      "CountryOrderNr": 15
  },
  {
      "CountryCode": "BH",
      "CountryName": "Bahrain",
      "CountryOrderNr": 16
  },
  {
      "CountryCode": "BD",
      "CountryName": "Bangladesh",
      "CountryOrderNr": 17
  },
  {
      "CountryCode": "BB",
      "CountryName": "Barbados",
      "CountryOrderNr": 18
  },
  {
      "CountryCode": "BY",
      "CountryName": "Belarus",
      "CountryOrderNr": 19
  },
  {
      "CountryCode": "BE",
      "CountryName": "Belgium",
      "CountryOrderNr": 20,
      "IsEuropeanUnion": true
  },
  {
      "CountryCode": "BZ",
      "CountryName": "Belize",
      "CountryOrderNr": 21
  },
  {
      "CountryCode": "BJ",
      "CountryName": "Benin",
      "CountryOrderNr": 22
  },
  {
      "CountryCode": "BM",
      "CountryName": "Bermuda",
      "CountryOrderNr": 23
  },
  {
      "CountryCode": "BT",
      "CountryName": "Bhutan",
      "CountryOrderNr": 24
  },
  {
      "CountryCode": "BO",
      "CountryName": "Bolivia",
      "CountryOrderNr": 25
  },
  {
      "CountryCode": "BW",
      "CountryName": "Botswana",
      "CountryOrderNr": 26
  },
  {
      "CountryCode": "BR",
      "CountryName": "Brazil",
      "CountryOrderNr": 27
  },
  {
      "CountryCode": "VG",
      "CountryName": "British Virgin Island",
      "CountryOrderNr": 28
  },
  {
      "CountryCode": "BN",
      "CountryName": "Brunei",
      "CountryOrderNr": 29
  },
  {
      "CountryCode": "BG",
      "CountryName": "Bulgaria",
      "CountryOrderNr": 30,
      "IsEuropeanUnion": true
  },
  {
      "CountryCode": "BF",
      "CountryName": "Burkino Faso",
      "CountryOrderNr": 31
  },
  {
      "CountryCode": "BI",
      "CountryName": "Burundi",
      "CountryOrderNr": 32
  },
  {
      "CountryCode": "KH",
      "CountryName": "Cambodia",
      "CountryOrderNr": 33
  },
  {
      "CountryCode": "CM",
      "CountryName": "Cameroon",
      "CountryOrderNr": 34
  },
  {
      "CountryCode": "CV",
      "CountryName": "Cape Verde",
      "CountryOrderNr": 36
  },
  {
      "CountryCode": "KY",
      "CountryName": "Cayman Islands",
      "CountryOrderNr": 37
  },
  {
      "CountryCode": "CF",
      "CountryName": "Central African",
      "CountryOrderNr": 38
  },
  {
      "CountryCode": "TD",
      "CountryName": "Chad",
      "CountryOrderNr": 39
  },
  {
      "CountryCode": "CL",
      "CountryName": "Chile",
      "CountryOrderNr": 40
  },
  {
      "CountryCode": "CN",
      "CountryName": "China",
      "CountryOrderNr": 41
  },
  {
      "CountryCode": "CO",
      "CountryName": "Colombia",
      "CountryOrderNr": 42
  },
  {
      "CountryCode": "CG",
      "CountryName": "Congo",
      "CountryOrderNr": 43
  },
  {
      "CountryCode": "CD",
      "CountryName": "Congo, The Republic of",
      "CountryOrderNr": 44
  },
  {
      "CountryCode": "CK",
      "CountryName": "Cook Islands",
      "CountryOrderNr": 45
  },
  {
      "CountryCode": "CR",
      "CountryName": "Costa Rica",
      "CountryOrderNr": 46
  },
  {
      "CountryCode": "HR",
      "CountryName": "Croatia",
      "CountryOrderNr": 47,
      "IsEuropeanUnion": true
  },
  {
      "CountryCode": "CU",
      "CountryName": "Cuba",
      "CountryOrderNr": 48
  },
  {
      "CountryCode": "CY",
      "CountryName": "Cyprus",
      "CountryOrderNr": 49
  },
  {
      "CountryCode": "CZ",
      "CountryName": "Czech Republic",
      "CountryOrderNr": 50,
      "IsEuropeanUnion": true
  },
  {
      "CountryCode": "DK",
      "CountryName": "Denmark",
      "CountryOrderNr": 51,
      "IsEuropeanUnion": true
  },
  {
      "CountryCode": "DJ",
      "CountryName": "Djibouti",
      "CountryOrderNr": 52
  },
  {
      "CountryCode": "DM",
      "CountryName": "Dominica",
      "CountryOrderNr": 53
  },
  {
      "CountryCode": "DO",
      "CountryName": "Dominican Republic",
      "CountryOrderNr": 54
  },
  {
      "CountryCode": "EC",
      "CountryName": "Ecuador",
      "CountryOrderNr": 55
  },
  {
      "CountryCode": "EG",
      "CountryName": "Egypt",
      "CountryOrderNr": 56
  },
  {
      "CountryCode": "SV",
      "CountryName": "El Salvador",
      "CountryOrderNr": 57
  },
  {
      "CountryCode": "GQ",
      "CountryName": "Equatorial Guinea",
      "CountryOrderNr": 58
  },
  {
      "CountryCode": "ER",
      "CountryName": "Eritrea",
      "CountryOrderNr": 59
  },
  {
      "CountryCode": "EE",
      "CountryName": "Estonia",
      "CountryOrderNr": 60,
      "IsEuropeanUnion": true
  },
  {
      "CountryCode": "ET",
      "CountryName": "Ethiopia",
      "CountryOrderNr": 61
  },
  {
      "CountryCode": "FO",
      "CountryName": "Faeroe Islands",
      "CountryOrderNr": 62
  },
  {
      "CountryCode": "FJ",
      "CountryName": "Fiji",
      "CountryOrderNr": 63
  },
  {
      "CountryCode": "FI",
      "CountryName": "Finland",
      "CountryOrderNr": 64,
      "IsEuropeanUnion": true
  },
  {
      "CountryCode": "FR",
      "CountryName": "France",
      "CountryOrderNr": 65,
      "IsEuropeanUnion": true
  },
  {
      "CountryCode": "GF",
      "CountryName": "French Guiana",
      "CountryOrderNr": 66
  },
  {
      "CountryCode": "PF",
      "CountryName": "French Polynesia",
      "CountryOrderNr": 67
  },
  {
      "CountryCode": "GA",
      "CountryName": "Gabon",
      "CountryOrderNr": 68
  },
  {
      "CountryCode": "GM",
      "CountryName": "Gambia",
      "CountryOrderNr": 69
  },
  {
      "CountryCode": "GE",
      "CountryName": "Georgia, Republic of",
      "CountryOrderNr": 70
  },
  {
      "CountryCode": "DE",
      "CountryName": "Germany",
      "CountryOrderNr": 71,
      "IsEuropeanUnion": true
  },
  {
      "CountryCode": "GH",
      "CountryName": "Ghana",
      "CountryOrderNr": 72
  },
  {
      "CountryCode": "GI",
      "CountryName": "Gibraltar",
      "CountryOrderNr": 73
  },
  {
      "CountryCode": "GR",
      "CountryName": "Greece",
      "CountryOrderNr": 74,
      "IsEuropeanUnion": true
  },
  {
      "CountryCode": "GL",
      "CountryName": "Greenland",
      "CountryOrderNr": 75
  },
  {
      "CountryCode": "GD",
      "CountryName": "Grenada",
      "CountryOrderNr": 76
  },
  {
      "CountryCode": "GP",
      "CountryName": "Guadeloupe",
      "CountryOrderNr": 77
  },
  {
      "CountryCode": "GU",
      "CountryName": "Guam",
      "CountryOrderNr": 78
  },
  {
      "CountryCode": "GT",
      "CountryName": "Guatemala",
      "CountryOrderNr": 79
  },
  {
      "CountryCode": "GN",
      "CountryName": "Guinea",
      "CountryOrderNr": 80
  },
  {
      "CountryCode": "GW",
      "CountryName": "Guinea-Bissau",
      "CountryOrderNr": 81
  },
  {
      "CountryCode": "GY",
      "CountryName": "Guyana",
      "CountryOrderNr": 82
  },
  {
      "CountryCode": "HT",
      "CountryName": "Haiti",
      "CountryOrderNr": 83
  },
  {
      "CountryCode": "HN",
      "CountryName": "Honduras",
      "CountryOrderNr": 84
  },
  {
      "CountryCode": "HK",
      "CountryName": "Hong Kong",
      "CountryOrderNr": 85
  },
  {
      "CountryCode": "HU",
      "CountryName": "Hungary",
      "CountryOrderNr": 86,
      "IsEuropeanUnion": true
  },
  {
      "CountryCode": "IS",
      "CountryName": "Iceland",
      "CountryOrderNr": 87
  },
  {
      "CountryCode": "IN",
      "CountryName": "India",
      "CountryOrderNr": 88
  },
  {
      "CountryCode": "ID",
      "CountryName": "Indonesia",
      "CountryOrderNr": 89
  },
  {
      "CountryCode": "IR",
      "CountryName": "Iran",
      "CountryOrderNr": 90
  },
  {
      "CountryCode": "IE",
      "CountryName": "Ireland",
      "CountryOrderNr": 91,
      "IsEuropeanUnion": true
  },
  {
      "CountryCode": "IL",
      "CountryName": "Israel",
      "CountryOrderNr": 92
  },
  {
      "CountryCode": "IT",
      "CountryName": "Italy",
      "CountryOrderNr": 93,
      "IsEuropeanUnion": true
  },
  {
      "CountryCode": "CI",
      "CountryName": "Ivory Coast",
      "CountryOrderNr": 94
  },
  {
      "CountryCode": "JM",
      "CountryName": "Jamaica",
      "CountryOrderNr": 95
  },
  {
      "CountryCode": "JP",
      "CountryName": "Japan",
      "CountryOrderNr": 96
  },
  {
      "CountryCode": "JO",
      "CountryName": "Jordan",
      "CountryOrderNr": 97
  },
  {
      "CountryCode": "KZ",
      "CountryName": "Kazakhstan",
      "CountryOrderNr": 98
  },
  {
      "CountryCode": "KE",
      "CountryName": "Kenya",
      "CountryOrderNr": 99
  },
  {
      "CountryCode": "KW",
      "CountryName": "Kuwait",
      "CountryOrderNr": 100
  },
  {
      "CountryCode": "KG",
      "CountryName": "Kyrgyzstan",
      "CountryOrderNr": 101
  },
  {
      "CountryCode": "LV",
      "CountryName": "Latvia",
      "CountryOrderNr": 102,
      "IsEuropeanUnion": true
  },
  {
      "CountryCode": "LB",
      "CountryName": "Lebanon",
      "CountryOrderNr": 103
  },
  {
      "CountryCode": "LS",
      "CountryName": "Lesotho",
      "CountryOrderNr": 104
  },
  {
      "CountryCode": "LI",
      "CountryName": "Liechtenstein",
      "CountryOrderNr": 105
  },
  {
      "CountryCode": "LT",
      "CountryName": "Lithuania",
      "CountryOrderNr": 106,
      "IsEuropeanUnion": true
  },
  {
      "CountryCode": "LU",
      "CountryName": "Luxembourg",
      "CountryOrderNr": 107,
      "IsEuropeanUnion": true
  },
  {
      "CountryCode": "MO",
      "CountryName": "Macau",
      "CountryOrderNr": 108
  },
  {
      "CountryCode": "MK",
      "CountryName": "Macedonia",
      "CountryOrderNr": 109
  },
  {
      "CountryCode": "MG",
      "CountryName": "Madagascar",
      "CountryOrderNr": 110
  },
  {
      "CountryCode": "MW",
      "CountryName": "Malawi",
      "CountryOrderNr": 111
  },
  {
      "CountryCode": "MY",
      "CountryName": "Malaysia",
      "CountryOrderNr": 112
  },
  {
      "CountryCode": "MV",
      "CountryName": "Maldives",
      "CountryOrderNr": 113
  },
  {
      "CountryCode": "ML",
      "CountryName": "Mali",
      "CountryOrderNr": 114
  },
  {
      "CountryCode": "MT",
      "CountryName": "Malta",
      "CountryOrderNr": 115,
      "IsEuropeanUnion": true
  },
  {
      "CountryCode": "MH",
      "CountryName": "Marshall Islands",
      "CountryOrderNr": 116
  },
  {
      "CountryCode": "MQ",
      "CountryName": "Martinique",
      "CountryOrderNr": 117
  },
  {
      "CountryCode": "MR",
      "CountryName": "Mauritania",
      "CountryOrderNr": 118
  },
  {
      "CountryCode": "MU",
      "CountryName": "Mauritius",
      "CountryOrderNr": 119
  },
  {
      "CountryCode": "MX",
      "CountryName": "Mexico",
      "CountryOrderNr": 120
  },
  {
      "CountryCode": "FM",
      "CountryName": "Micronesia",
      "CountryOrderNr": 121
  },
  {
      "CountryCode": "MD",
      "CountryName": "Moldova",
      "CountryOrderNr": 122
  },
  {
      "CountryCode": "MC",
      "CountryName": "Monaco",
      "CountryOrderNr": 123
  },
  {
      "CountryCode": "MN",
      "CountryName": "Mongolia",
      "CountryOrderNr": 124
  },
  {
      "CountryCode": "MS",
      "CountryName": "Montserrat",
      "CountryOrderNr": 125
  },
  {
      "CountryCode": "MA",
      "CountryName": "Morocco",
      "CountryOrderNr": 126
  },
  {
      "CountryCode": "MZ",
      "CountryName": "Mozambique",
      "CountryOrderNr": 127
  },
  {
      "CountryCode": "MM",
      "CountryName": "Myanmar (Burma)",
      "CountryOrderNr": 128
  },
  {
      "CountryCode": "NA",
      "CountryName": "Namibia",
      "CountryOrderNr": 129
  },
  {
      "CountryCode": "NP",
      "CountryName": "Nepal",
      "CountryOrderNr": 130
  },
  {
      "CountryCode": "NL",
      "CountryName": "Netherlands",
      "CountryOrderNr": 131,
      "IsEuropeanUnion": true
  },
  {
      "CountryCode": "AN",
      "CountryName": "Netherlands Antilles",
      "CountryOrderNr": 132
  },
  {
      "CountryCode": "NC",
      "CountryName": "New Caledonia",
      "CountryOrderNr": 133
  },
  {
      "CountryCode": "NZ",
      "CountryName": "New Zealand",
      "CountryOrderNr": 134
  },
  {
      "CountryCode": "NI",
      "CountryName": "Nicaragua",
      "CountryOrderNr": 135
  },
  {
      "CountryCode": "NE",
      "CountryName": "Niger",
      "CountryOrderNr": 136
  },
  {
      "CountryCode": "NG",
      "CountryName": "Nigeria",
      "CountryOrderNr": 137
  },
  {
      "CountryCode": "NO",
      "CountryName": "Norway",
      "CountryOrderNr": 138
  },
  {
      "CountryCode": "OM",
      "CountryName": "Oman",
      "CountryOrderNr": 139
  },
  {
      "CountryCode": "PK",
      "CountryName": "Pakistan",
      "CountryOrderNr": 140
  },
  {
      "CountryCode": "PW",
      "CountryName": "Palau",
      "CountryOrderNr": 141
  },
  {
      "CountryCode": "PA",
      "CountryName": "Panama",
      "CountryOrderNr": 142
  },
  {
      "CountryCode": "PG",
      "CountryName": "Papua New Guinea",
      "CountryOrderNr": 143
  },
  {
      "CountryCode": "PY",
      "CountryName": "Paraguay",
      "CountryOrderNr": 144
  },
  {
      "CountryCode": "PE",
      "CountryName": "Peru",
      "CountryOrderNr": 145
  },
  {
      "CountryCode": "PH",
      "CountryName": "Philippines",
      "CountryOrderNr": 146
  },
  {
      "CountryCode": "PL",
      "CountryName": "Poland",
      "CountryOrderNr": 147,
      "IsEuropeanUnion": true
  },
  {
      "CountryCode": "PT",
      "CountryName": "Portugal",
      "CountryOrderNr": 148,
      "IsEuropeanUnion": true
  },
  {
      "CountryCode": "QA",
      "CountryName": "Qatar",
      "CountryOrderNr": 149
  },
  {
      "CountryCode": "KR",
      "CountryName": "South Korea",
      "CountryOrderNr": 150
  },
  {
      "CountryCode": "RE",
      "CountryName": "Reunion Island",
      "CountryOrderNr": 151
  },
  {
      "CountryCode": "RO",
      "CountryName": "Romania",
      "CountryOrderNr": 152,
      "IsEuropeanUnion": true
  },
  {
      "CountryCode": "RU",
      "CountryName": "Russia",
      "CountryOrderNr": 153
  },
  {
      "CountryCode": "RW",
      "CountryName": "Rwanda",
      "CountryOrderNr": 154
  },
  {
      "CountryCode": "MP",
      "CountryName": "Saipan",
      "CountryOrderNr": 155
  },
  {
      "CountryCode": "SM",
      "CountryName": "San Marino",
      "CountryOrderNr": 156
  },
  {
      "CountryCode": "SA",
      "CountryName": "Saudi Arabia",
      "CountryOrderNr": 157
  },
  {
      "CountryCode": "SN",
      "CountryName": "Senegal",
      "CountryOrderNr": 158
  },
  {
      "CountryCode": "SC",
      "CountryName": "Seychelles",
      "CountryOrderNr": 159
  },
  {
      "CountryCode": "SL",
      "CountryName": "Sierra Leone",
      "CountryOrderNr": 160
  },
  {
      "CountryCode": "SG",
      "CountryName": "Singapore",
      "CountryOrderNr": 161
  },
  {
      "CountryCode": "SK",
      "CountryName": "Slovak Republic",
      "CountryOrderNr": 162
  },
  {
      "CountryCode": "SI",
      "CountryName": "Slovenia",
      "CountryOrderNr": 163,
      "IsEuropeanUnion": true
  },
  {
      "CountryCode": "ZA",
      "CountryName": "South Africa",
      "CountryOrderNr": 164
  },
  {
      "CountryCode": "KP",
      "CountryName": "North Korea",
      "CountryOrderNr": 165
  },
  {
      "CountryCode": "SS",
      "CountryName": "South Sudan",
      "CountryOrderNr": 166
  },
  {
      "CountryCode": "ES",
      "CountryName": "Spain",
      "CountryOrderNr": 167,
      "IsEuropeanUnion": true
  },
  {
      "CountryCode": "LK",
      "CountryName": "Sri Lanka",
      "CountryOrderNr": 168
  },
  {
      "CountryCode": "KN",
      "CountryName": "St. Kitts & Nevis",
      "CountryOrderNr": 169
  },
  {
      "CountryCode": "LC",
      "CountryName": "St. Lucia",
      "CountryOrderNr": 170
  },
  {
      "CountryCode": "VC",
      "CountryName": "St. Vincent",
      "CountryOrderNr": 171
  },
  {
      "CountryCode": "SD",
      "CountryName": "Sudan",
      "CountryOrderNr": 172
  },
  {
      "CountryCode": "SR",
      "CountryName": "Suriname",
      "CountryOrderNr": 173
  },
  {
      "CountryCode": "SZ",
      "CountryName": "Swaziland",
      "CountryOrderNr": 174
  },
  {
      "CountryCode": "SE",
      "CountryName": "Sweden",
      "CountryOrderNr": 175,
      "IsEuropeanUnion": true
  },
  {
      "CountryCode": "CH",
      "CountryName": "Switzerland",
      "CountryOrderNr": 176
  },
  {
      "CountryCode": "SY",
      "CountryName": "Syria",
      "CountryOrderNr": 177
  },
  {
      "CountryCode": "TW",
      "CountryName": "Taiwan",
      "CountryOrderNr": 178
  },
  {
      "CountryCode": "TZ",
      "CountryName": "Tanzania",
      "CountryOrderNr": 179
  },
  {
      "CountryCode": "TH",
      "CountryName": "Thailand",
      "CountryOrderNr": 180
  },
  {
      "CountryCode": "TG",
      "CountryName": "Togo",
      "CountryOrderNr": 181
  },
  {
      "CountryCode": "TT",
      "CountryName": "Trinidad & Tobago",
      "CountryOrderNr": 182
  },
  {
      "CountryCode": "TN",
      "CountryName": "Tunisia",
      "CountryOrderNr": 183
  },
  {
      "CountryCode": "TR",
      "CountryName": "Turkey",
      "CountryOrderNr": 184
  },
  {
      "CountryCode": "TM",
      "CountryName": "Turkmenistan, Republic of",
      "CountryOrderNr": 185
  },
  {
      "CountryCode": "TC",
      "CountryName": "Turks & Caicos Is.",
      "CountryOrderNr": 186
  },
  {
      "CountryCode": "UG",
      "CountryName": "Uganda",
      "CountryOrderNr": 187
  },
  {
      "CountryCode": "UA",
      "CountryName": "Ukraine",
      "CountryOrderNr": 188
  },
  {
      "CountryCode": "AE",
      "CountryName": "United Arab Emirates",
      "CountryOrderNr": 189
  },
  {
      "CountryCode": "GB",
      "CountryName": "United Kingdom",
      "CountryOrderNr": 190,
      "IsEuropeanUnion": true
  },
  {
      "CountryCode": "UY",
      "CountryName": "Uruguay",
      "CountryOrderNr": 191
  },
  {
      "CountryCode": "VI",
      "CountryName": "US Virgin Islands",
      "CountryOrderNr": 192
  },
  {
      "CountryCode": "UZ",
      "CountryName": "Uzbekistan",
      "CountryOrderNr": 193
  },
  {
      "CountryCode": "VU",
      "CountryName": "Vanuatu",
      "CountryOrderNr": 194
  },
  {
      "CountryCode": "VA",
      "CountryName": "Vatican City",
      "CountryOrderNr": 195
  },
  {
      "CountryCode": "VE",
      "CountryName": "Venezuela",
      "CountryOrderNr": 196
  },
  {
      "CountryCode": "VN",
      "CountryName": "Vietnam",
      "CountryOrderNr": 197
  },
  {
      "CountryCode": "WF",
      "CountryName": "Wallis & Futuna Islands",
      "CountryOrderNr": 198
  },
  {
      "CountryCode": "YE",
      "CountryName": "Yemen",
      "CountryOrderNr": 199
  },
  {
      "CountryCode": "ZM",
      "CountryName": "Zambia",
      "CountryOrderNr": 200
  },
  {
      "CountryCode": "ZW",
      "CountryName": "Zimbabwe",
      "CountryOrderNr": 201
  }
]

export const Occupations: string[] = [
    "Actor",
    "Artist",
    "Athlete / Sports Player",
    "Chicken Chaser",
    "Chicken Sexer",
    "Circus Worker",
    "Dancer",
    "Dustman",
    "Exotic Dancer",
    "Fashion / Other Model",
    "Musician",
    "Pig Man",
    "Reality TV Star",
    "Refuse Collector",
    "Waste Dealer",
    "Celebrity",
    "First Contract Sports Personalities",
    "Rapper",
    "Abattoir Worker",
    "Accommodation Officer",
    "Account Executive",
    "Account Manager",
    "Accountant",
    "Accounts Assistant",
    "Accounts Clerk",
    "Accounts Manager Exotic Dancer",
    "Accounts Staff",
    "Acoustic Engineer",
    "Actuary",
    "Acupuncturist",
    "Administration Assistant",
    "Administration Clerk",
    "Administration Manager",
    "Administration Staff Fashion / Other Model",
    "Administrator",
    "Advertising Agent",
    "Advertising Assistant",
    "Advertising Clerk",
    "Advertising Contractor",
    "Advertising Executive",
    "Advertising Manager",
    "Advertising Staff",
    "Aerobic Instructor",
    "Aeronautical Engineer",
    "Agister",
    "Agricultural Consultant",
    "Agricultural Contractor",
    "Agricultural Engineer",
    "Agricultural Merchant",
    "Agricultural Worker",
    "Agronomist",
    "Air Traffic Controller",
    "Aircraft Cabin Crew",
    "Aircraft Designer",
    "Aircraft Engineer",
    "Aircraft Maintenance Engineer",
    "Aircraft Surface Finisher",
    "Airline Check-In Staff",
    "Airman",
    "Airport Controller",
    "Airport Manager",
    "Almoner",
    "Ambulance Controller",
    "Ambulance Crew",
    "Ambulance Driver",
    "Anesthesiologist",
    "Analytical Chemist",
    "Animal Breeder",
    "Anthropologist",
    "Antique Dealer",
    "Applications Engineer",
    "Applications Programmer",
    "Aquarist",
    "Arbitrator",
    "Arborist",
    "Archaeologist",
    "Architect",
    "Archivist",
    "Area Manager",
    "Aromatherapist",
    "Art Dealer",
    "Art Historian",
    "Art Restorer",
    "Artexer",
    "Articled Clerk",
    "Artificial Limb Fitter",
    "Asphalter",
    "Assessor",
    "Assistant Accounts Manager",
    "Assistant Caretaker",
    "Assistant Manager",
    "Assistant Nurse",
    "Assistant Teacher",
    "Astrologer",
    "Astronomer",
    "Au Pair",
    "Auction Worker",
    "Auctioneer",
    "Audiologist",
    "Audit Clerk",
    "Audit Manager",
    "Auditor",
    "Author",
    "Auto Electrician",
    "Auxiliary Nurse",
    "Baggage Handler",
    "Baker",
    "Bakery Assistant",
    "Bakery Manager",
    "Bakery Operator",
    "Balloonist",
    "Bank Clerk",
    "Bank Manager",
    "Bank Messenger",
    "Baptist Minister",
    "Bar Manager",
    "Barber",
    "Barrister",
    "Beautician",
    "Beauty Therapist",
    "Betting Shop Clerk",
    "Bingo Caller",
    "Bingo Hall Staff",
    "Biochemist",
    "Biologist",
    "Blacksmith",
    "Blind Assembler",
    "Blind Fitter",
    "Blinds Installer",
    "Boat Builder",
    "Body Fitter",
    "Bodyshop Manager",
    "Boiler Maker",
    "Boiler Man",
    "Booking Agent",
    "Booking Clerk",
    "Booking Office Clerk",
    "Botanist",
    "Box Office Clerk",
    "Branch Manager",
    "Brewer",
    "Brewery Manager",
    "Brewery Worker",
    "Bricklayer",
    "Broadcasting Engineer",
    "Builder",
    "Builders Labourer",
    "Building Contractor",
    "Building Engineer",
    "Building Estimator",
    "Building Foreman",
    "Building Inspector",
    "Building Manager",
    "Building Site Inspector",
    "Building Society Clerk",
    "Bursar",
    "Bus Conductor",
    "Bus Driver",
    "Bus Mechanic",
    "Butcher",
    "Butchery Manager",
    "Butler",
    "Cabinet Maker",
    "Cafe Owner",
    "Cafe Staff",
    "Cafe Worker",
    "Call Centre Manager",
    "Call Centre Staff",
    "Cameraman",
    "Car Body Repairer",
    "Car Builder",
    "Car Dealer",
    "Car Delivery Driver",
    "Car Park Attendant",
    "Car Salesman",
    "Care Assistant",
    "Care Manager",
    "Careers Advisor",
    "Careers Officer",
    "Career - Professional",
    "Caretaker",
    "Cargo Operator",
    "Carpenter",
    "Carpet Cleaner",
    "Carphone Fitter",
    "Cartographer",
    "Cash Point Fitter",
    "Cashier",
    "Caterer",
    "Catering Manager",
    "Caulker",
    "Centre Lathe Operator",
    "Certified Accountant",
    "Chambermaid",
    "Chaplain",
    "Chartered Accountant",
    "Chartered Engineer",
    "Chartered Surveyor",
    "Chartered Valuer",
    "Chauffeur",
    "Check-Out Assistant",
    "Chef",
    "Chemical Engineer",
    "Chemist",
    "Chief Cashier",
    "Chief Chemist",
    "Chief Executive",
    "Chief Executive Officer",
    "Child Minder",
    "Childrens Entertainer",
    "Chimney Sweep",
    "China Restorer",
    "Chiropodist",
    "Chiropractor",
    "Cinema Assistant",
    "Civil Engineer",
    "Civil Servant",
    "Claims Adjustor",
    "Claims Assessor",
    "Claims Manager",
    "Cleaner",
    "Cleaning Contractor",
    "Cleaning Supervisor",
    "Clergyman",
    "Clerk",
    "Clerk Of Works",
    "Clinical Psychologist",
    "Coach Driver",
    "Coach Sprayer",
    "Coal Merchant",
    "Coastguard",
    "Cobbler",
    "College Dean",
    "College Lecturer",
    "College Principal",
    "Commercial Manager",
    "Commissionaire",
    "Commissioned Officer",
    "Commissioning Engineer",
    "Commodity Broker",
    "Commodity Dealer",
    "Community Nurse",
    "Company Chairman",
    "Company Director",
    "Composer",
    "Computer Analyst",
    "Computer Consultant",
    "Computer Engineer",
    "Computer Manager",
    "Computer Operator",
    "Computer Programmer",
    "Computer Technician",
    "Conference Manager",
    "Conference Organizer",
    "Construction Engineer",
    "Construction Worker",
    "Consultant",
    "Contract Cleaner",
    "Contract Manager",
    "Contractor",
    "Contracts Supervisor",
    "Conveyancer",
    "Copywriter",
    "Coroner",
    "Council Worker",
    "Counsellor",
    "Courier",
    "Court Officer",
    "Crane Driver",
    "Crane Operator",
    "Credit Broker",
    "Credit Controller",
    "Credit Manager",
    "Croupier",
    "Crown Prosecutor",
    "Curator",
    "Customer Advisor",
    "Customer Liaison Officer",
    "Customs & Excise Officer",
    "Dairy Engineer",
    "Dairy Worker",
    "Dark Room Technician",
    "Data Administrator",
    "Data Coordinator",
    "Data Processor",
    "Day Care Officer",
    "Debt Counsellor",
    "Decorator",
    "Delivery Driver",
    "Dental Assistant",
    "Dental Hygienist",
    "Dental Nurse",
    "Dental Surgeon",
    "Dental Technician",
    "Dental Therapist",
    "Dentist",
    "Deputy Head Teacher",
    "Deputy Manager",
    "Deputy Principal",
    "Dermatologist Musician",
    "Design Engineer",
    "Design Manager",
    "Despatch Driver",
    "Despatch Rider",
    "Development Manager",
    "Dietician",
    "Dinner Lady",
    "Dispatch Driver",
    "Dispatch Rider",
    "Distillery Worker",
    "District Nurse",
    "District Valuer",
    "Diver",
    "Docker",
    "Dockyard Worker",
    "Doctor",
    "Dog Breeder",
    "Dog Groomer",
    "Dog Trainer",
    "Doll Maker",
    "Domestic Staff",
    "Door Fitter",
    "Double Glazing Fitter",
    "Double Glazing Salesman",
    "Draughtsman",
    "Drayman",
    "Dressmaker",
    "Driving Examiner",
    "Driving Instructor",
    "Driving Instructor (Hgv)",
    "Drug Addiction Counsellor",
    "Dry Cleaner",
    "Dryliner",
    "Ecologist",
    "Economist",
    "Editor",
    "Editorial Consultant",
    "Editorial Staff",
    "Education Officer",
    "Electrical Contractor",
    "Electrical Engineer",
    "Electrical Fitter",
    "Electrician",
    "Electrologist",
    "Electronic Engineer",
    "Electronics Supervisor",
    "Electronics Technician",
    "Embalmer",
    "Engineer",
    "Engraver",
    "Environmental Chemist",
    "Environmental Consultant",
    "Environmental Health Officer",
    "Estate Agent",
    "Estimator",
    "Evangelist",
    "Events Organizer",
    "Excursion Manager",
    "Exhaust Fitter",
    "Exhibition Organizer",
    "Export Consultant",
    "Exporter",
    "Factory Worker",
    "Farm Manager",
    "Farm Worker",
    "Farmer",
    "Farrier",
    "Fashion Designer",
    "Fashion Photographer",
    "Figure Painter",
    "Finance Director",
    "Finance Manager",
    "Finance Officer",
    "Financial Advisor",
    "Financial Analyst",
    "Financial Consultant",
    "Financier",
    "Fire Officer",
    "Fire Prevention Officer",
    "Fire Protection Consultant",
    "Firefighter",
    "Fireplace Fitter",
    "Fish Buyer",
    "Fish Filleter",
    "Fish Merchant",
    "Fish Worker",
    "Fisheries Inspector",
    "Fisherman",
    "Fishery Manager",
    "Fishmonger",
    "Fitness Instructor",
    "Fitter",
    "Fitter - Tyre/Exhaust",
    "Flight Deck Crew",
    "Floor Layer",
    "Floor Manager",
    "Florist",
    "Flour Miller",
    "Flower Arranger",
    "Flying Instructor",
    "Foreman",
    "Forensic Scientist",
    "Forest Ranger",
    "Forester",
    "Fork Lift Truck Driver",
    "Foster Parent",
    "Fraud Investigator",
    "French Polisher",
    "Fund Raiser",
    "Funeral Director",
    "Funeral Furnisher",
    "Furniture Dealer",
    "Furniture Remover",
    "Furniture Restorer",
    "Gamekeeper",
    "Garden Designer",
    "Gardener",
    "Gas Fitter",
    "Gas Mechanic",
    "Gas Technician",
    "General Practitioner",
    "Geologist",
    "Glazier",
    "Goldsmith",
    "Golf Caddy",
    "Goods Handler",
    "Graphic Designer",
    "Grave Digger",
    "Gravel Merchant",
    "Green Keeper",
    "Greengrocer",
    "Grocer",
    "Groom",
    "Ground Worker",
    "Groundsman",
    "Guide",
    "Gynecologist",
    "Hairdresser",
    "Handyman",
    "Hardware Dealer",
    "Haulage Contractor",
    "Head Accurist",
    "Head Greenkeeper",
    "Head Teacher",
    "Health Advisor",
    "Health and Safety Consultant",
    "Health and Safety Officer",
    "Health Care Assistant",
    "Health Planner",
    "Health Therapist",
    "Health Visitor",
    "Heating & Ventilation Engineer",
    "Herbalist",
    "Hgv Driver",
    "Hgv Mechanic",
    "Hire Car Driver",
    "Historian",
    "Home Economist",
    "Home Help",
    "Homecare Manager",
    "Homeopath",
    "Horse Breeder",
    "Horse Dealer",
    "Horse Riding Instructor",
    "Horse Trader",
    "Horse Trainer",
    "Horticultural Consultant",
    "Hospital Consultant",
    "Hospital Manager",
    "Hospital Technician",
    "Hospital Warden",
    "Hospital Worker Reality TV Star",
    "Hot Foil Printer",
    "Hotel Consultant",
    "Housekeeper",
    "Housewife or Househusband",
    "Housing Assistant",
    "Housing Officer",
    "Housing Supervisor Refuse Collector",
    "Human Resources Manager",
    "Human Resources Staff",
    "Hydro Geologist",
    "Hygienist",
    "Ice Cream Vendor",
    "Illustrator",
    "Immigration Officer",
    "Import Consultant",
    "Importer",
    "Industrial Chemist",
    "Industrial Designer",
    "Inland Revenue Officer",
    "Inspector",
    "Instrument Engineer",
    "Instrument Maker",
    "Instrument Supervisor",
    "Instrument Technician",
    "Insurance Inspector",
    "Interior Decorator",
    "Interior Designer",
    "Interpreter",
    "Investigator",
    "Investment Advisor",
    "Investment Banker",
    "Investment Manager",
    "Ironmonger",
    "IT Consultant",
    "IT Manager",
    "Janitor",
    "Jazz Composer",
    "Jeweller",
    "Jewellery Consultant",
    "Joiner",
    "Joinery Consultant",
    "Journalist",
    "Judge",
    "Keep Fit Instructor",
    "Kitchen Worker",
    "Labelling Operator",
    "Laboratory Analyst",
    "Laboratory Assistant",
    "Laboratory Attendant",
    "Laboratory Manager",
    "Laboratory Operative",
    "Laboratory Supervisor",
    "Laboratory Technician",
    "Labourer",
    "Laminator",
    "Land Agent",
    "Land Surveyor",
    "Landlady",
    "Landlord",
    "Landowner",
    "Landscape Architect",
    "Landscape Gardener",
    "Landworker",
    "Lathe Operator",
    "Laundry Worker",
    "Law Clerk",
    "Lawyer",
    "Ledger Clerk",
    "Legal Advisor",
    "Legal Assistant",
    "Legal Executive",
    "Legal Secretary",
    "Leisure Centre Attendant",
    "Leisure Centre Manager",
    "Letting Agent",
    "Liaison Officer",
    "Librarian",
    "Library Manager",
    "Lifeguard",
    "Lighting Technician",
    "Lithographer",
    "Litigation Manager",
    "Local Government Officer",
    "Locksmith",
    "Locum Pharmacist",
    "Lorry Driver",
    "Loss Assessor",
    "Lumberjack",
    "Machine Fitters Mate",
    "Machine Minder",
    "Machine Operator",
    "Machine Setter",
    "Machine Technician",
    "Machine Tool Engineer",
    "Machine Tool Fitter",
    "Machinist",
    "Magistrate",
    "Magistrates Clerk",
    "Maid",
    "Maintenance Engineer",
    "Maintenance Man",
    "Management Trainee",
    "Manager",
    "Managing Director",
    "Manicurist",
    "Manufacturing Technician",
    "Marine Engineer",
    "Marine Pilot",
    "Market Research Assistant",
    "Market Researcher",
    "Marketing Agent",
    "Marketing Assistant",
    "Marketing Consultant",
    "Marketing Coordinator",
    "Marketing Director",
    "Marketing Executive",
    "Marketing Manager",
    "Masseur",
    "Matron",
    "Mature Student",
    "Mechanic",
    "Mechanical Engineer",
    "Medical Assistant",
    "Medical Consultant",
    "Medical Officer",
    "Medical Physicist",
    "Medical Practitioner",
    "Medical Researcher",
    "Medical Secretary",
    "Medical Student",
    "Member Of Parliament",
    "Merchant Banker",
    "Merchant Seaman",
    "Metal Dealer",
    "Metal Engineer",
    "Metallurgist",
    "Meteorologist",
    "Meter Reader",
    "Microbiologist",
    "Microfilm Operator",
    "Midwife",
    "Milklady",
    "Milkman",
    "Mill Operator",
    "Mill Worker",
    "Miller",
    "Milliner",
    "Millwright",
    "Miner",
    "Minibus Driver",
    "Mining Consultant",
    "Mining Engineer",
    "Minister Of Religion",
    "Missionary",
    "Mobile Service Engineer",
    "Monk",
    "Monumental Sculptor",
    "Mortgage Broker",
    "Mortgage Consultant",
    "Mortician",
    "Museum Assistant",
    "Museum Attendant",
    "Museum Consultant",
    "Museum Technician",
    "Music Teacher",
    "Music Therapist",
    "Nanny",
    "Naturopath",
    "Neurologist",
    "Newsagent",
    "Night Porter",
    "Night Watchman",
    "Nuclear Scientist",
    "Nun",
    "Nurse",
    "Nursery Assistant",
    "Nursery Nurse",
    "Nursery Worker",
    "Nursing Assistant",
    "Nursing Auxiliary",
    "Nursing Manager",
    "Nursing Sister",
    "Nutritionist",
    "Occupational Health Consultant",
    "Occupational Health Nurse",
    "Occupational Therapist",
    "Oculist",
    "Office Administrator",
    "Office Manager",
    "Oil Rig Crew",
    "Operations Manager",
    "Operations Supervisor",
    "Optical Advisor",
    "Optician",
    "Orchestral Violinist",
    "Order Clerk",
    "Organist",
    "Ornamental Blacksmith",
    "Orthopaedic Technician",
    "Orthoptist",
    "Osteopath",
    "Ostler",
    "Other Waste Dealer",
    "Outdoor Pursuits Instructor",
    "Outreach Worker",
    "Packaging Consultant",
    "Packer",
    "Pediatrician",
    "Paint Consultant",
    "Paint Sprayer",
    "Painter",
    "Painter and Decorator",
    "Paleobotanist",
    "Paleontologist",
    "Panel Beater",
    "Paper Mill Worker",
    "Paramedic",
    "Park Attendant",
    "Park Keeper",
    "Park Ranger",
    "Parts Man",
    "Parts Manager",
    "Parts Supervisor",
    "Party Planner",
    "Pasteurizer",
    "Pastry Chef",
    "Pathologist",
    "Patrol Person",
    "Patrolman",
    "Pattern Maker Actor",
    "Payroll Assistant",
    "Payroll Clerk",
    "Payroll Manager",
    "Payroll Supervisor",
    "Pedicurist",
    "Pensions Consultant",
    "Pensions Manager",
    "Personal Assistant",
    "Personnel Administrator",
    "Personnel Manager",
    "Personnel Officer",
    "Pest Control",
    "Pest Controller",
    "Petroleum Engineer",
    "Pharmaceutical Assistant",
    "Pharmacist",
    "Pharmacy Manager",
    "Pharmacy Technician",
    "Photo Engraver",
    "Photographer",
    "Physician",
    "Physicist",
    "Physiologist",
    "Physiotherapist",
    "Piano Teacher",
    "Piano Tuner",
    "Picker",
    "Picture Editor",
    "Picture Framer",
    "Picture Researcher",
    "Pig Manager",
    "Pilot",
    "Pipe Fitter",
    "Pipe Inspector",
    "Pipe Insulator",
    "Pipe Layer",
    "Planning Engineer",
    "Planning Manager",
    "Planning Officer",
    "Plant Driver",
    "Plant Engineer",
    "Plant Fitter",
    "Plant Manager",
    "Plant Operator",
    "Plasterer",
    "Plastics Consultant",
    "Plastics Engineer",
    "Playgroup Assistant",
    "Plumber",
    "Podiatrist",
    "Police Officer",
    "Polisher",
    "Porter",
    "Post Office Counter Clerk",
    "Postman/woman",
    "Potter",
    "Preacher",
    "Precision Engineer Artist",
    "Print Finisher",
    "Printer",
    "Prison Chaplain",
    "Prison Officer",
    "Private Investigator",
    "Probation Officer",
    "Probation Worker",
    "Process Engineer",
    "Procurator Fiscal",
    "Produce Supervisor Athlete / Sports Player",
    "Product Manager",
    "Production Engineer",
    "Production Manager",
    "Production Planner",
    "Professor",
    "Progress Clerk",
    "Project Coordinator",
    "Project Leader",
    "Project Manager",
    "Project Worker",
    "Projectionist",
    "Promoter",
    "Proof Reader",
    "Property Buyer",
    "Property Dealer",
    "Property Developer",
    "Property Valuer",
    "Proprietor",
    "Prosthetist",
    "Psychiatrist",
    "Psychologist",
    "Psychotherapist",
    "Psychodynamic Counsellor",
    "Public House Manager",
    "Public Relations Officer",
    "Publican",
    "Publisher",
    "Purchase Clerk",
    "Purchase Ledger Clerk",
    "Purchasing Manager",
    "Quality Controller",
    "Quality Engineer",
    "Quality Inspector",
    "Quality Manager",
    "Quantity Surveyor",
    "Rabbi",
    "Radio Controller",
    "Radio Engineer",
    "Radio Producer",
    "Radiographer",
    "Radiologist",
    "Receptionist",
    "Recruitment Consultant",
    "Rector",
    "Reflexologist",
    "Refractory Engineer",
    "Refrigeration Engineer",
    "Registrar",
    "Reporter",
    "Research Analyst",
    "Research Consultant",
    "Research Director",
    "Research Scientist",
    "Research Technician",
    "Researcher",
    "Restaurant Manager",
    "Restaurateur",
    "Restorer",
    "Retired",
    "Revenue Clerk",
    "Rigger",
    "Road Sweeper",
    "Road Worker",
    "Roof Tiler",
    "Roofer",
    "Rose Grower",
    "Royal Marine",
    "Safety Officer",
    "Sales Administrator",
    "Sales Assistant",
    "Sales Director",
    "Sales Engineer",
    "Sales Executive",
    "Sales Manager",
    "Sales Representative",
    "Sales Support",
    "Saw Miller",
    "Scaffolder",
    "School Crossing Warden",
    "School Inspector",
    "Scientific Officer",
    "Scientist",
    "Screen Printer",
    "Script Writer",
    "Sculptor",
    "Seaman",
    "Secretary",
    "Secretary and Pa",
    "Security Consultant",
    "Security Guard",
    "Service Engineer",
    "Sheet Metal Worker",
    "Shelf Filler",
    "Shift Controller",
    "Shipping Clerk",
    "Shoe Repairer",
    "Shop Assistant",
    "Shop Fitter",
    "Shop Manager",
    "Sign Maker",
    "Signalman",
    "Signwriter",
    "Skipper",
    "Slaughterman",
    "Social Worker",
    "Software Consultant",
    "Software Engineer",
    "Soldier",
    "Solicitor",
    "Sound Engineer",
    "Sound Technician",
    "Speech Therapist",
    "Sports Centre Attendant",
    "Sports Coach",
    "Stable Hand",
    "Staff Nurse",
    "Stage Director",
    "Stage Manager",
    "Stage Mover",
    "Station Manager",
    "Stationer",
    "Statistician",
    "Steeplejack",
    "Stenographer",
    "Stevedore",
    "Steward",
    "Stewardess",
    "Stock Controller",
    "Stock Manager",
    "Stockbroker",
    "Stockman",
    "Stocktaker",
    "Stone Cutter",
    "Stone Sawyer",
    "Stonemason",
    "Storeman/woman",
    "Stud Hand",
    "Student",
    "Student Nurse",
    "Student Teacher",
    "Studio Photographer",
    "Supervisor",
    "Supply Teacher",
    "Surgeon",
    "Surveyor - Chartered",
    "Systems Analyst",
    "Systems Engineer",
    "Systems Manager",
    "Tailor",
    "Tattooist",
    "Tax Advisor",
    "Tax Analyst",
    "Tax Assistant",
    "Tax Consultant",
    "Tax Inspector",
    "Tax Manager",
    "Tax Officer",
    "Taxidermist",
    "Teacher",
    "Teachers Assistant",
    "Technical Advisor",
    "Technical Author",
    "Technical Coordinator",
    "Technical Director",
    "Technical Editor",
    "Technical Instructor",
    "Technical Manager",
    "Telecommunication Consultant",
    "Telecommunications Engineer",
    "Telecommunications Manager",
    "Telemarketer",
    "Telephone Engineer",
    "Telephonist",
    "Telesales Person",
    "Television Engineer",
    "Tennis Coach",
    "Textile Consultant",
    "Textile Engineer",
    "Theatre Manager",
    "Theatre Technician",
    "Theatrical Agent",
    "Therapist",
    "Thermal Engineer",
    "Ticket Inspector",
    "Tiler",
    "Timber Worker",
    "Tobacconist",
    "Tool Maker Dancer",
    "Tool Setter",
    "Town Planner",
    "Toy Maker",
    "Trade Union Official",
    "Traffic Warden",
    "Train Driver",
    "Trainee Manager",
    "Trainer",
    "Training Advisor",
    "Training Assistant",
    "Training Consultant",
    "Training Coordinator",
    "Training Instructor",
    "Training Manager",
    "Training Officer",
    "Translator",
    "Transport Clerk",
    "Transport Consultant",
    "Transport Controller",
    "Transport Engineer",
    "Transport Manager",
    "Transport Officer",
    "Transport Planner",
    "Travel Agent",
    "Travel Clerk",
    "Travel Consultant",
    "Travel Guide",
    "Travel Representative",
    "Tree Feller",
    "Tree Surgeon",
    "Trichologist",
    "Trout Farmer",
    "T-Shirt Printer",
    "Tug Skipper",
    "Turf Accountant",
    "Turkey Farmer",
    "Tutor",
    "Tv Editor",
    "Typist",
    "Tyre Fitter",
    "Tyre Inspector",
    "Tyre Technician",
    "Undertaker",
    "Underwriter",
    "Unemployed",
    "Upholsterer",
    "Valuer",
    "Van Driver",
    "Vdu Operator",
    "Veterinary Assistant",
    "Veterinary Surgeon",
    "Violin Maker",
    "Wages Clerk Dustman",
    "Waiter",
    "Waitress",
    "Warehouse Manager",
    "Warehouseman",
    "Watchmaker",
    "Web Designer",
    "Welder",
    "Welfare Assistant",
    "Welfare Officer",
    "Window Cleaner",
    "Window Dresser",
    "Windscreen Fitter",
    "Wine Merchant",
    "Yacht Master",
    "Yoga Teacher",
    "Zoo Keeper",
    "Zoo Manager",
    "Zoologist",
    "Zoology Consultant"
  ];