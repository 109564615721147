import Store from '@/vuex/store'

export const showControlHelper = (module: string, show: any[], formState?: any, controlName?: string): boolean => {
     
    if (!show) return true;

    let showResult = false;
    let isSubFormRule = false;

    //Show conditions are arrays of arrays. Each array within the array is an OR condition containing checks that all must be true

    try {
        show.forEach((showConditions: any[]) => {
            //loop through the set of OR conditions
            let result = true;

    
            showConditions.forEach((s: any) => {
                //Loop through the inner AND conditions
    
                let value: any = null;
                if (s.location == 'SubForm' && formState) {
                    //Used for SubFormTable showing
                    value = formState[s.name];
                    isSubFormRule = true;
                } 
                // else if (s.location == 'Submission') {
                //     value = Store.getters.getSubmissionValue(module, s.name);
                // } 
                else {
                    value = Store.getters.getControlValue(module, s.name)
                }

                if (s.type == 'UserRoleInList') {
                    const userRole = Store.getters.getUserRole;
                    if (s.value && s.value.indexOf(userRole) == -1) result = false;
                }
                else if (s.type == 'EqualTo') {
                    if (value === undefined || value !== s.value) result = false;
                }
                else if (s.type == 'GreaterThan') {
                    if (value === null || value === undefined || value <= parseFloat(s.value)) result = false;
                }
                else if (s.type == 'GreaterThanOrEqualTo') {
                    if (value === null || value === undefined || value < parseFloat(s.value)) result = false;
                }
                else if (s.type == 'LessThan') {
                    if (value === null || value === undefined || value >= parseFloat(s.value)) result = false;
                }
                else if (s.type == 'LessThanOrEqualTo') {
                    if (value === null || value === undefined || value > parseFloat(s.value)) result = false;
                }
                else if (s.type == 'InList') {
                    if (s.value.indexOf(value) == -1) result = false;
                }
                else if (s.type == 'NotInList') {
                    if (value === undefined || value === null || s.value.indexOf(value) > -1) result = false;
                }
                else if (s.type == 'RiskStateInList') {
                    if (value && s.value.indexOf(value.State) == -1) result = false;
                }
                else if (s.type == 'RiskStateNotInList') {
                    if (value && s.value.indexOf(value.State) > -1) result = false;
                }
                else if (s.type == 'ListHasValue') {
                    if (value.indexOf(s.value) == -1) result = false;
                }
                else if (s.type == 'ListDoesNotHaveValue') {
                    if (value.indexOf(s.value) > -1) result = false;
                }
                else if (s.type == 'PropertyEqualTo') {
                    if (value === null || value === undefined || value[s.property] === null || value[s.property] === undefined || value[s.property] !== s.value) result = false;
                }
                else if (s.type == 'PropertyNotEqualTo') {
                    if (value == null || value[s.property] === undefined || value[s.property] === s.value) result = false;
                }
                else if (s.type == 'HasValue') {
                    if (
                        value === undefined || 
                        value === null ||
                        value === ""
                    ) result = false;
                } else if (s.type == "HasNoValue") {
                    if (value !== undefined &&
                        value !== null &&
                        value !== ""
                    ) result = false;
                }
                else if (s.type == 'GreaterThanZero') {
                  if (
                    value === undefined ||
                    value === null ||
                    value < 1
                  ) result = false;
                }
            })
    
            if (showResult == false) showResult = result;
    
        })
    } catch(e) { 
        return false
    }

    if (isSubFormRule && formState && showResult == false) {
        formState[controlName] = null;
    }
    

    return showResult;
}
