var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"md":"6","cols":"12"}},[_c('h1',{staticClass:"mb-0"},[_vm._v("Restricted Features")])])],1),(_vm.showRestrictedFeatureAdmin)?_c('v-row',[_c('v-col',{attrs:{"sm":"6","cols":"12"}},[_c('v-card',{staticClass:"mb-4 pa-2"},[_c('v-card-title',{staticClass:"pb-0"},[_vm._v(" Select a User ")]),_c('v-card-text',[_c('ValidationObserver',{ref:"userObserver",attrs:{"tag":"span"}},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.validateUser()}}},[_c('div',{staticClass:"d-flex"},[_c('DynamicFormControl',{attrs:{"control":{
                        type: 'TextInput',
                        name: 'UserEmail',
                        value: _vm.selectedUserEmail,
                        config: {
                            label:'User Email',
                            rules: 'required|email',
                            short: true,
                            disabled: _vm.selectedUserLoading
                        }
                      }},on:{"change":function($event){_vm.selectedUserEmail = $event.value}}}),_c('v-btn',{staticClass:"ml-2",staticStyle:{"margin-top":"10px"},attrs:{"color":"secondary","x-large":"","type":"submit","disabled":_vm.selectedUserLoading}},[_vm._v(" Search ")])],1)])])],1)],1)],1),_c('v-col',{attrs:{"sm":"6","cols":"12"}},[(_vm.selectedUserInfo)?_c('v-card',{staticClass:"mb-4 pa-2 px-4"},[_c('v-card-title',{staticClass:"pb-0"},[_vm._v(" "+_vm._s(_vm.selectedUserInfo.Name)+" ")]),_c('v-card-text',[_c('h5',{staticClass:"mb-0 mt-2",staticStyle:{"color":"#333"}},[_vm._v("Role: "),_c('span',{staticStyle:{"text-transform":"capitalize"}},[_vm._v(_vm._s(_vm.selectedUserInfo.Role))])])])],1):_vm._e(),_c('v-card',{staticClass:"mb-4 pa-2"},[_c('v-card-title',{staticClass:"pb-0"},[_c('v-col',{staticClass:"py-0",attrs:{"md":"9","cols":"12"}},[_vm._v(" Restricted Features ")]),_c('v-col',{staticClass:"text-right py-0",attrs:{"md":"3","cols":"12"}},[(_vm.isAdmin)?_c('v-btn',{attrs:{"color":"white","type":"button"},on:{"click":function($event){return _vm.openRestrictedFeatureModal()}}},[_c('i',{staticClass:"fas fa-plus-circle",staticStyle:{"position":"relative","top":"-1px"}}),_vm._v(" Add ")]):_vm._e()],1)],1),_c('v-card-text',{staticClass:"px-6"},[_c('ValidationObserver',{ref:"userRestrictedFeaturesObserver",attrs:{"tag":"span"}},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.validateUserRestrictedFeatures()}}},[_vm._l((_vm.restrictedFeatures),function(f){return _c('div',{key:f.id},[(_vm.showRestrictedFeature(f))?_c('v-row',{staticClass:"mt-0"},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"9"}},[_c('DynamicFormControl',{attrs:{"control":{
                          type: 'Checkbox',
                          name:  f.id,
                          value: _vm.userHasSelectedFeature(f.id),
                          config: {
                              label: f.name,
                              rules: '',
                              short: true,
                              disabled: !_vm.selectedUser
                          }
                        }},on:{"change":function($event){return _vm.updateSelectedFeatureUser($event)}}})],1),_c('v-col',{staticClass:"text-right py-0",attrs:{"cols":"3"}},[(_vm.isAdmin)?_c('v-btn',{key:f.id + 'edit',staticClass:"mt-2",attrs:{"icon":"","small":"","title":"Edit"},on:{"click":function($event){return _vm.openRestrictedFeatureModal(f)}}},[_c('i',{staticClass:"fas fa-pen"})]):_vm._e()],1)],1):_vm._e()],1)}),(_vm.selectedUser)?_c('div',{staticStyle:{"text-align":"right"}},[_c('br'),_c('v-btn',{staticClass:"ml-2 mt-2",attrs:{"color":"secondary","type":"submit"}},[_vm._v(" Update User ")])],1):_vm._e()],2)])],1)],1)],1)],1):_c('v-alert',{staticClass:"mt-4",attrs:{"type":"warning","dense":"","icon":false}},[_vm._v("You do not have access to this feature.")]),_c('Dialog',{attrs:{"title":_vm.restrictedFeatureModalTitle,"value":_vm.showRestrictedFeatureModal,"width":"600px"},on:{"close-dialog":_vm.closeRestrictedFeatureModal},scopedSlots:_vm._u([{key:"card-text",fn:function(){return [_c('ValidationObserver',{ref:"restrictedFeatureObserver",attrs:{"tag":"span"}},[(_vm.selectedRestrictedFeature)?_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.validateRestrictedFeature()}}},[_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('DynamicFormControl',{attrs:{"control":{
                                      name: 'key',
                                      value: _vm.selectedRestrictedFeature.key,
                                      type: 'TextInput',
                                      config: {
                                          label: 'Key',
                                          rules: 'required',
                                          short: true
                                      }
                                  }},on:{"change":function($event){_vm.selectedRestrictedFeature.key = $event.value}}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('DynamicFormControl',{attrs:{"control":{
                                      name: 'name',
                                      value: _vm.selectedRestrictedFeature.name,
                                      type: 'TextInput',
                                      config: {
                                          label: 'Name',
                                          rules: 'required',
                                          short: true
                                      }
                                  }},on:{"change":function($event){_vm.selectedRestrictedFeature.name = $event.value}}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('DynamicFormControl',{attrs:{"control":{
                                      name: 'isAdminManaged',
                                      value: _vm.selectedRestrictedFeature.isAdminManaged,
                                      type: 'Checkbox',
                                      config: {
                                          label: 'Is Admin Managed',
                                          rules: '',
                                          short: true
                                      }
                                  }},on:{"change":function($event){_vm.selectedRestrictedFeature.isAdminManaged = $event.value}}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"text-right",attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"mr-2",attrs:{"color":"secondary","type":"button","large":"","text":""},on:{"click":_vm.closeRestrictedFeatureModal}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"secondary","type":"submit","large":""}},[_vm._v("Submit")])],1)],1)],1):_vm._e()])]},proxy:true}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }