<template>
  <div>
    <v-container>
      <v-row class="py-0">
        <v-col cols="12" sm="6">
          <v-btn outlined class="go-back" @click="returnToQuotes">
            <i class="fas fa-chevron-left"></i>&nbsp;Return to Quotes
          </v-btn>
        </v-col>
        <v-col cols="12" sm="6" class="text-right">
            <QuoteProductHeader v-if="submission" :submission="submission" />
        </v-col>
      </v-row>

      <div v-if="underwriter">
        <h1>You're done! Thanks for your submission, {{$store.getters.getUserGivenName}}.</h1>
        <v-card class="pa-8 mb-8">
          <h2>Your submission has been assigned to your Amwins underwriter, {{underwriter.Name}}.</h2>

          <hr>

          <p>
            If the submission is complete and cleared internally within Amwins, we will begin working on it immediately with an intent to provide you with a quote shortly. 
            If the submission is incomplete, or not cleared internally within Amwins, we will be in touch advising such and requesting additional information we will need in order to try to provide you with terms.
          </p>
          <p>We look forward to working with you on this risk. Any questions, please let us know.</p>
          <p>Best Regards,</p>
          <h3 class="mb-4">{{underwriter.Name}}</h3>
          <p class="mb-0">{{underwriter.Phone}}</p>
          <p><a :href="'mailto:' + underwriter.Email" style="text-decoration: none;">{{underwriter.Email}}</a></p>

          <hr>

          <DocumentPreview mode="Human" v-if="submission" :submission="submission" />
          <br><br>


        </v-card>
      </div>

    </v-container>
    

  </div>
</template>

<script>
  import { SubmissionService } from '@/api/SubmissionService'
  import DocumentPreview from '@/components/views/Quote/DocumentPreview.vue'
  import QuoteProductHeader from '@/components/views/Quote/QuoteProductHeader.vue'

  export default {
    name: "SubmissionConfirmation",
    components: {
      DocumentPreview,
      QuoteProductHeader
    },
    data() {
      return {
        underwriterId: null,
        underwriter: null,
        //pdfPath: "http://unec.edu.az/application/uploads/2014/12/pdf-sample.pdf"
        pdfPath: null,
        quoteForm: null,
        submission: null
      }
    },
    mounted() {
      const submissionId = this.$route.query.id;

      if (submissionId) {
        SubmissionService.get({ id: submissionId })
          .then((submission) => {
            if (submission.CurrentForm != 'Human') {
              this.$router.push({ path: '/account', query: { id: submission.id } })
            } else {
              this.submission = submission;
              this.underwriter = submission.Data.Underwriter.value;
              const baseURL = this.$http.defaults.baseURL;
              this.pdfPath = baseURL + '/api/Test/DocSummaryHuman?submissionId=' + this.submission.id + "&v=" + Math.random();
            }
          })
      }
    },
    methods: {
      returnToQuotes() {
        this.$router.push({ path: '/quote/quote', query: { id: this.$route.query.id } })
      }
    }
  }
</script>

<style scoped lang="scss">
@import '@/style.scss';

  hr {
    border-color: $gray6;
    margin: 40px 0;
    border-style: solid;
  }

  object {
    display: block;
    width: 100%;
    height: 800px;
  }
</style>

