import {
  GateRules_PersistenceModelsGateDefinition,
  SalesChannel,
  GateRules_EnumsDefaultSalesChannelVerdict,
  GateRulesRule,
  GateRules_EnumsRuleVerdict,
  GateRulesCheck,
  GateRuleComparatorType,
  Any,
  GateRules_ResponsesLimitedGateResponse,
  GateRules_RequestModelsTestAGateRequestBody,
  GateRules_ResponsesAdminGateResponse,
  GateRules_PersistenceModelsGateRunPersistence,
  GateRulesExecutedRule,
  GateRulesExecutedCheck,
  GateRules_EnumsCheckVerdict,
  IList,
  List,
  IListResult,
  ListResultDto,
  IPagedResult,
  PagedResultDto,
  Dictionary,
  IDictionary,
  IRequestOptions,
  IRequestConfig,
  getConfigs,
  axios,
  basePath
} from './index.defs';

export class GateService {
  /** Generate by swagger-axios-codegen */
  // @ts-nocheck
  /* eslint-disable */

  /**
   *
   */
  static getSalesChannels(options: IRequestOptions = {}): Promise<GateRules_PersistenceModelsGateDefinition[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Gate/SalesChannel';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static upsertSalesChannelDefinition(
    params: {
      /**  */
      body?: GateRules_PersistenceModelsGateDefinition;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GateRules_PersistenceModelsGateDefinition> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Gate/SalesChannel';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getSalesChannelGateById(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GateRules_PersistenceModelsGateDefinition> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Gate/SalesChannel/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static gateTheSubmission(
    params: {
      /**  */
      body?: Any;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GateRules_ResponsesLimitedGateResponse[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Gate/GateTheSubmission';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static testAGate(
    params: {
      /**  */
      body?: GateRules_RequestModelsTestAGateRequestBody;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<GateRules_ResponsesAdminGateResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Gate/TestAGate';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static export(
    params: {
      /**  */
      format?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Gate/export';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { format: params['format'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}
