import v1EndorsementsConfig from '@/scripts/v1-endorsement-config.json'
import { getControlConfig, showControlByCoverageOption } from '@/scripts/sales-channel-config'
import { getDeductibleRules, getComputedValue } from '@/scripts/sales-channel-deductible'
import { v2CoverageModelTypeMapping } from '@/scripts/v2-endorsement-config'
import { formatDateForDisplay } from '@/scripts/helper'
import Store from '@/vuex/store'

export let mergeFormStates = (form1, form2) => {
  return (form1.steps).concat(form2.steps);
}

export let updateFormStateV1 = (formStates) => {
  formStates.forEach((form) => {
    if (form.sections) {
      form.sections.forEach((section) => {
        if (section.controls) {
          section.controls.forEach((control) => {
            if (v1EndorsementsConfig.fieldsToHide.includes(control.name)) {
              if (!control.config) {
                control.config = { 'hidden': true };
              }

              if (!('hidden' in control.config) || control.config.hidden === null) {
                control.config.hidden = true;
              }
            }
            else if (!v1EndorsementsConfig.editableFields.includes(control.name)) {
              if (!control.config) {
                control.config = { 'disabled': true };
              }

              if (!('disabled' in control.config) || control.config.disabled === null) {
                control.config.disabled = true;
              }
            }

            // Fix issue where these controls are not the right width
            if ((control.name === 'FloodPerilDeductible' || control.name === 'Email') && control.type != 'Hidden') {
              control.config.col = 8
            }

            if (control.name === 'EffectiveDate') {
              control.config.rules = control.config.rules.replace("\|validBackdateFlood", "")
            }
          })
        }
      })
    }
  })

  return formStates;
}

let canNotEditCoverages = false;
let canNotEditRisk = false;

// if hasOpenInspection = true
// canNotEditCoverages = localHasOpenInspection
// and endorsement request clicked => lock down risk+coverage.
// or correct discrepancies is clicked => expose all the risk and lock down coverge.
export function setEditRiskAndCoveragesPermissions(role, userIsOnInspectionsReviewTeamOrAdmin, localCorrectDiscrepancies, localHasOpenInspection) {
  canNotEditRisk = role != 'agent' && role !== 'employee' && !userIsOnInspectionsReviewTeamOrAdmin || (!localCorrectDiscrepancies && localHasOpenInspection)
  canNotEditCoverages = (role != 'agent' && role !== 'employee' && !userIsOnInspectionsReviewTeamOrAdmin) || localHasOpenInspection
}

const fieldsToKeepEditable = ["MailingAddressSame", "MailingAddress", "Mortgagee1", "Mortgagee1Company", "Mortgagee2", "Mortgagee2Company", "AdditionalInterests", "AdditionalInterestsList"]
export let updateFormStateV2 = (policy, formStates, role, userIsOnInspectionsReviewTeamOrAdmin) => {
  const coverageModelType = policy.CoverageModelType
  const config = v2CoverageModelTypeMapping[coverageModelType.Value];
  formStates.forEach((form) => {
    if (form.sections) {
      form.sections.forEach((section) => {
        if (section.controls) {
          section.controls.forEach((control) => {
            control.renderValue = Math.random()
            if (config.fieldsToHide.includes(control.name)) {
              if (!control.config) {
                control.config = { 'hidden': true };
                control.config = { 'disabled': true };
              }

              if (!('hidden' in control.config) || control.config.hidden === null) {
                control.config.hidden = true;
                control.config.disabled = true;
              }
            }
            else if ((role === 'agent' || role === 'employee') && !config.agentEditableFields.includes(control.name)) {
              if (!control.config) {
                control.config = { 'disabled': true };
              }

              if (!('disabled' in control.config) || control.config.disabled === null) {
                control.config.disabled = true;
              }
            }
            else if (role !== 'agent' && role !== 'employee' && !userIsOnInspectionsReviewTeamOrAdmin) {
              if (!control.config) {
                control.config = { 'disabled': true };
              }

              if (!('disabled' in control.config) || control.config.disabled === null) {
                control.config.disabled = true;
              }
            }
            else if (userIsOnInspectionsReviewTeamOrAdmin && !config.editableFields.includes(control.name)) {
              if (!control.config) {
                control.config = { 'disabled': true };
              }

              if (!('disabled' in control.config) || control.config.disabled === null) {
                control.config.disabled = true;
              }
            }

            // Fix issue where these controls are not the right width
            if (control.name === 'Email' || control.name === 'Protections') {
              control.config.col = 8
            }

            if (canNotEditRisk) {
              if (control.config && !fieldsToKeepEditable.includes(control.name)) {
                control.config.disabled = true
              }
            }
          })
        }
      })
    }
  })

  return formStates;
}

const v1CoverageControlsToAdd = [
  "BuildingValuationAmount",
  "CovA",
  "CovB",
  "CovC",
  "CovD",
  "ValuationType"
]

// This method locates the FloodCoverages component and repalces the entire component (which contains the coverage and limits controls) with individual controls
// to break the dependency on the QuoteForm vuex state and to give us control over ui formatting. This is not ideal since we'll have to update this list of controls
// whenever the control properties within FloodCoverages changes, but this was a necessary evil.
export let replaceCoverages = (formStates, salesChannel) => {
  formStates.forEach((form) => {
    if (form.sections) {
      // Locate the Valuation and Limits section
      const valuationAndLimitsSection = form.sections.find(sec => sec.label === 'Valuation and Limits')
      let covDRules = '';
      if (salesChannel === 'Vave') {
        covDRules = 'required|min5000IfSelected|max_value:5000000'
      }
      if (salesChannel === 'Chubb') {
        covDRules = 'required|max_value:5000'
      }
      if (valuationAndLimitsSection != undefined) {
        // Add the individual Coverages and Limits controls
        // Coverage Limits
        form.sections.unshift(
          {
            label: 'Limits',
            controls: [
              {
                name: 'CovA',
                type: 'TextInput',
                value: 0,
                config: {
                  label: 'Dwelling Coverage',
                  rules: 'required|min_value:1|max_value:5000000|greaterThanBuildingValuation:@BuildingValuationAmount',
                  short: true,
                  col: 8,
                  prefix: '$',
                  default: 0,
                  currency: true,
                  min: 0,
                  allowNegative: false,
                  defaultValue: 0,
                  hideDataServiceValue: true,
                  ignoreDataServiceValue: true
                }
              },
              {
                name: 'CovB',
                type: 'TextInput',
                value: 0,
                config: {
                  label: 'Other Structures Coverage',
                  rules: 'required|min5000IfSelected|max_value:5000000',
                  short: true,
                  col: 8,
                  prefix: '$',
                  default: 0,
                  currency: true,
                  min: 0,
                  allowNegative: false,
                  defaultValue: 0,
                  hideDataServiceValue: true,
                  ignoreDataServiceValue: true,
                  disabled: salesChannel === 'Vave' || salesChannel === 'Chubb' ? true : false
                }
              },
              {
                name: 'CovC',
                type: 'TextInput',
                value: 0,
                config: {
                  label: 'Personal Property Coverage',
                  rules: 'required|min5000IfSelected|max_value:5000000|greaterThanContentsValuation:@ContentsValuationAmount',
                  short: true,
                  col: 8,
                  prefix: '$',
                  default: 0, 
                  currency: true,
                  min: 0,
                  allowNegative: false,
                  defaultValue: 0,
                  hideDataServiceValue: true,
                  ignoreDataServiceValue: true
                }
              },
              {
                name: 'ValuationType',
                type: 'RadioButtonGroup',
                value: 'RCV',
                config: {
                  label: 'Replacement Cost or Actual Cost for Personal Property Limit?',
                  rules: 'required',
                  col: 8,
                  indented: true,
                  default: 'RCV',
                  options: [
                    {
                      value: 'RCV',
                      text: 'Replacement Cost Valuation'
                    },
                    {
                      value: 'ACV',
                      text: 'Actual Cost Valuation'
                    }
                  ],
                  show: [
                    [
                      {
                        "name": "CovC",
                        "type": "GreaterThanZero"
                      }
                    ]
                  ],
                  disabled: true
                }
              },
              {
                name: 'CovD',
                type: 'TextInput',
                value: 0,
                config: {
                  label: 'Loss of Use Coverage',
                  rules: covDRules,
                  short: true,
                  col: 8,
                  prefix: '$',
                  default: 0,
                  currency: true,
                  min: 0,
                  allowNegative: false,
                  defaultValue: 0,
                  hideDataServiceValue: true,
                  ignoreDataServiceValue: true,
                  disabled: salesChannel === 'Vave' ? true : false
                }
              }
            ]
          }
        )

        // Add BuildingValuationAmount
        form.sections.unshift(
          {
            label: 'Valuation',
            controls: [
              {
                name: 'BuildingValuationAmount',
                type: 'TextInput',
                value: 0,
                config: {
                  label: 'Building Estimated Valuation',
                  rules: 'required|min_value:1|max_value:999999999',
                  short: true,
                  col: 8,
                  prefix: '$',
                  default: 0,
                  currency: true,
                  min: 0,
                  allowNegative: false,
                  defaultValue: 0,
                  disabled: true
                }
              },
              {
                name: 'ContentsValuationAmount',
                type: 'TextInput',
                value: 0,
                config: {
                  label: 'Personal Property Estimated Valuation',
                  rules: 'required|min_value:0|max_value:999999999',
                  short: true,
                  col: 8,
                  prefix: '$', 
                  default: 0,
                  currency: true,
                  min: 0,
                  allowNegative: false,
                  defaultValue: 0,
                  disabled: true
                }
              }
            ]
          }
        )
      }

      form.sections.forEach((section) => {
        if (section.controls) {
          if (section.controls.find(ctrl => ctrl.name === 'FloodCoverages') != undefined) {
            // Remove the Flood Coverages control
            section.controls = section.controls.filter(ctrl => ctrl.name != 'FloodCoverages')

            // Remove the Hidden Flood Coverages controls so we don't have duplicate 'name' properties (unique ID)
            section.controls = section.controls.filter(ctrl => v1CoverageControlsToAdd.includes(ctrl.name) && ctrl.type !== 'Hidden')
          }
        }
      })
    }
  })

  return formStates;
}

const v2CoverageControlsToAdd = [
  "CovA",
  "CovB",
  "CovC",
  "CovD",
  "CovEdp3"
]

// This method locates the Coverages component and replaces the entire component (which contains the coverage and limits controls) with individual controls
// to break the dependency on the QuoteForm vuex state and to give us control over ui formatting. This is not ideal since we'll have to update this list of controls
// whenever the control properties within Coverages changes, but this was a necessary evil.
export let replaceCoveragesV2 = (policy, formStates, salesChannel, formType) => {
  const coverageModelMappingFunction = coverageModelMapping[policy.CoverageModelType.Value];
  formStates = coverageModelMappingFunction(policy, formStates, salesChannel, formType);
  return formStates;
}

const coverageModelMapping = {
  'SubmissionCoverageV0': replaceSubmissionCoveragesV0FormState
}

function replaceSubmissionCoveragesV0FormState(policy, formStates, salesChannel, formType) {
  const config = v2CoverageModelTypeMapping[policy.CoverageModelType.Value];
  formStates.forEach((form) => {
    if (form.sections) {

      // Add rules to hide specific postGateFields fields
      const hiddenRules = (control, policy) => {
        const rule1 = (control.name === 'TheftExclusion' && policy.Coverages?.TheftExclusion?.value !== '1')
        const rule2 = (control.name === 'Theft' && policy.Coverages?.TheftExclusion?.value === '1')
        return rule1 || rule2
      };

      // Add rules to disable specific postGateFields fields
      const disableRules = (control, policy) => {
        const rule1 = (control.name === 'TheftExclusion' && policy.Coverages?.TheftExclusion?.value === '1')
        return rule1
      };

      // Locate the Valuation and Limits section
      const valuationAndLimitsSection = form.sections.find((sec) => sec.label === 'Risk Limits')
      if (valuationAndLimitsSection != undefined) {
        // Add add-on/optional coverages
        if (config.postGateFields) {
          form.sections.push({
            label: 'Add-On/Optional Coverages',
            controls: config.postGateFields.find(y => y.salesChannel === salesChannel)?.controls
              .filter(x => x.coverageOptions.includes(formType) && !hiddenRules(x, policy))
              .map(control => ({
                ...control,
                config: {
                  ...getControlConfig(salesChannel, formType, control.name),
                  disabled: canNotEditCoverages || disableRules(control, policy)
                }
              }))
          })
        }

        // Remove the existing Liability Limits
        form.sections = form.sections.filter((sec) => sec.label !== 'Liability Limits')

        var liabilityAndMedpayControls = []
        var liabilityControlName = ''

        if (formType.toUpperCase() === 'DP3') {
          liabilityControlName = 'CovLdp3'
          liabilityAndMedpayControls.push({
            name: 'CovLdp3',
            type: 'Dropdown',
            value: 0,
            v2DataPath: "Coverages.CovLdp3.value",
            config: {
              ...getControlConfig(salesChannel, formType, 'Liability'),
              col: 8,
              default: 0,
              hideDataServiceValue: true,
              ignoreDataServiceValue: true,
              disabled: canNotEditCoverages
            }
          })
          liabilityAndMedpayControls.push({
              name: 'CovMdp3',
              type: 'Dropdown',
              value: 0,
              v2DataPath: "Coverages.CovMdp3.value",
              config: {
                ...getControlConfig(salesChannel, formType, 'MedPay'),
                col: 8,
                default: 0,
                hideDataServiceValue: true,
                ignoreDataServiceValue: true,
                disabled: canNotEditCoverages,
                show: [
                  [
                    {
                      "name": "CovLdp3",
                      "value": 0,
                      "type": "GreaterThan"
                    }
                  ]
                ]
              }
            })
        } else {
          liabilityControlName = 'CovE'
          liabilityAndMedpayControls.push({
            name: 'CovE',
            type: 'Dropdown',
            value: 0,
            v2DataPath: "Coverages.CovE.value",
            config: {
              ...getControlConfig(salesChannel, formType, 'Liability'),
              col: 8,
              default: 0,
              hideDataServiceValue: true,
              ignoreDataServiceValue: true,
              disabled: canNotEditCoverages
            }
          })
          liabilityAndMedpayControls.push({
            name: 'CovF',
            type: 'Dropdown',
            value: 0,
            v2DataPath: "Coverages.CovF.value",
            config: {
              ...getControlConfig(salesChannel, formType, 'MedPay'),
              col: 8,
              default: 0,
              hideDataServiceValue: true,
              ignoreDataServiceValue: true,
              disabled: canNotEditCoverages,
              show: [
                [
                  {
                    "name": "CovE",
                    "value": 0,
                    "type": "GreaterThan"
                  }
                ]
              ]
            }
          })
        }

        // Add the individual Liability Limits
        form.sections.unshift(
          {
            label: 'Liability Limits',
            controls: [
              ...liabilityAndMedpayControls,
              {
                name: 'PersonalInjury',
                type: 'Dropdown',
                v2DataPath: "Coverages.PersonalInjury.value",
                config: {
                  ...getControlConfig(salesChannel, formType, 'PersonalInjury'),
                  col: 8,
                  default: 0,
                  hideDataServiceValue: true,
                  ignoreDataServiceValue: true,
                  disabled: canNotEditCoverages,
                  show: [
                    [
                      {
                        "name": liabilityControlName,
                        "value": 0,
                        "type": "GreaterThan"
                      }
                    ]
                  ]
                }
              },
            ]
          }
        )

        // Add the individual Coverages and Limits controls
        let controls = []
        if (getControlConfig(salesChannel, formType, 'CovA')) {
          controls.push({
            name: 'CovA',
            type: 'TextInput',
            value: 0,
            v2DataPath: "Coverages.CovA.value",
            config: {
              ...getControlConfig(salesChannel, formType, 'CovA'),
              label: getComputedValue({
                formType,
              }, salesChannel, 'CovALabel'),
              col: 8,
              disabled: canNotEditCoverages
            }
          })
        }
        if (getControlConfig(salesChannel, formType, 'CovB')) {
          controls.push({
            name: 'CovB',
            type: 'TextInput',
            value: 0,
            v2DataPath: "Coverages.CovB.value",
            config: {
              ...getControlConfig(salesChannel, formType, 'CovB'),
              col: 8,
              default: 0,
              hideDataServiceValue: true,
              ignoreDataServiceValue: true,
              disabled: canNotEditCoverages
            }
          })
        }
        if (getControlConfig(salesChannel, formType, 'CovC')) {
          controls.push({
            name: 'CovC',
            type: 'TextInput',
            value: 0,
            v2DataPath: "Coverages.CovC.value",
            config: {
              ...getControlConfig(salesChannel, formType, 'CovC'),
              col: 8,
              prefix: '$',
              default: 0,
              hideDataServiceValue: true,
              ignoreDataServiceValue: true,
              disabled: canNotEditCoverages
            }
          })
        }
        if (getControlConfig(salesChannel, formType, 'CovD')) {
          controls.push({
            name: 'CovD',
            type: 'TextInput',
            value: 0,
            v2DataPath: "Coverages.CovD.value",
            config: {
              ...getControlConfig(salesChannel, formType, 'CovD'),
              col: 8,
              default: 0,
              hideDataServiceValue: true,
              ignoreDataServiceValue: true,
              disabled: canNotEditCoverages
            }
          })
        }
        if (getControlConfig(salesChannel, formType, 'CovEdp3')) {
          controls.push({
            name: 'CovEdp3',
            type: 'TextInput',
            value: 0,
            v2DataPath: 'Coverages.CovEdp3.value',
            config: {
              ...getControlConfig(salesChannel, formType, 'CovEdp3'),
              disabled: canNotEditCoverages
            }
          })
        }
        form.sections.unshift(
          {
            label: 'Risk Limits',
            controls: controls
          }
        )        
      }

      form.sections.forEach((section) => {
        if (section.controls) {
          if (section.controls.find((ctrl) => ctrl.name === 'Coverages') != undefined) {
            // Remove the Coverages control
            section.controls = section.controls.filter((ctrl) => ctrl.name != 'Coverages')

            // Remove the Hidden Coverages controls so we don't have duplicate 'name' properties (unique ID)
            section.controls = section.controls.filter((ctrl) => v2CoverageControlsToAdd.includes(ctrl.name) && ctrl.type !== 'Hidden')
          }
        }
      })
    }
  })
  return formStates;
}


// This method locates the Perils & Deductibles section and replaces the deductible controls with individual controls to break out the deductible amounts into flat amounts and percentage amounts.
// This is not ideal since we'll have to update this list of controls whenever the control properties within Coverages changes, but this was a necessary evil.
export let replaceDeductiblesV2 = (formStates, salesChannel, formType, deductibleValues, policy) => {
  const deductibleModelMappingFunction = deductibleModelMapping[salesChannel];
    formStates = deductibleModelMappingFunction(formStates, salesChannel, formType, deductibleValues, policy);
  return formStates;
}

let deductibleModelMapping = {
  'VavePersonalLines': replaceVavePersonalLinesDeductiblesFormState
}

const vavePersonalLinesDeductibleControlsToRemove = [
  "AllPerilDeductible",
  "WindPeril",
  "WindPerilDeductibleWarning",
  "WindPerilDeductible2",
  "WindPerilApply",
  "FloodPeril",
  "FloodPerilDeductible",
  "FloodPerilDeductibleWarning",
  "EarthquakePeril",
  "EarthquakePerilDeductible",
  "EarthquakePerilDeductibleWarning"
]

function replaceVavePersonalLinesDeductiblesFormState(formStates, salesChannel, formType, deductibleValues, policy) {
  formStates.forEach((form) => {
    if (form.sections) {
      // Locate the Perils & Deductibles section
      let perilsAndDeductiblesSection = form.sections.find((sec) => sec.label === 'Perils & Deductibles')

      if (perilsAndDeductiblesSection != undefined) {
        //store values of original deductible controls to determine whether to display the flat/percent controls
        const windPeril = perilsAndDeductiblesSection.controls.find(ctrl => ctrl.name === 'WindPeril').value;
        const floodPeril = perilsAndDeductiblesSection.controls.find(ctrl => ctrl.name === 'FloodPeril').value;
        const earthquakePeril = perilsAndDeductiblesSection.controls.find(ctrl => ctrl.name === 'EarthquakePeril').value;

        // Remove soon to be duplicate deductible controls
        const allPerilDeductibleControl = perilsAndDeductiblesSection.controls.find((ctrl) => ctrl.name === 'AllPerilDeductible')

        perilsAndDeductiblesSection.controls = perilsAndDeductiblesSection.controls.filter((ctrl) => !vavePersonalLinesDeductibleControlsToRemove.includes(ctrl.name))

        // Add the individual Deductible controls
        perilsAndDeductiblesSection.controls.push({
          name: 'AllPerilDeductible',
          type: 'TextInput',
          v2DataPath: 'Coverages.AllPerilDeductible.value.Flat.Amount',
          value: allPerilDeductibleControl.value,
          config: {
            ...getControlConfig(salesChannel, formType, 'AllPerilDeductible'),
            col: 6,
            disabled: canNotEditCoverages
          }
        })

        if (windPeril && showControlByCoverageOption(salesChannel, formType, 'WindHailPerilIncrementer')) {
          perilsAndDeductiblesSection.controls.push({
            name: 'WindHailPerilIncrementer',
            type: 'PercentIncrementer',
            value: deductibleValues.windHailPerilValues.percent,
            config: {
              ...getControlConfig(salesChannel, formType, 'WindHailPerilIncrementer'),
              disabled: canNotEditCoverages
            },
          })
          perilsAndDeductiblesSection.controls.push({
            name: 'WindHailFlatAmount',
            type: 'TextInput',
            v2DataPath: 'Coverages.WindPerilDeductible.value.Flat.Amount',
            value: deductibleValues.windHailPerilValues.flat,
            config: {
              ...getControlConfig(salesChannel, formType, 'WindHailFlatAmount')
            }
          })
        } else if (!windPeril && showControlByCoverageOption(salesChannel, formType, 'WindHailPerilIncrementer')) {
          perilsAndDeductiblesSection.controls.push({
            name: 'WindHailReadOnly',
            type: 'TextInput',
            value: 'No Coverage',
            config: {
              label: 'Wind Peril Deductible',
              disabled: true,
              hidden: true,
              col: 12
            },
          })
        }

        // VavePersonalLines non-flood does not quote for flood
        if (!floodPeril && showControlByCoverageOption(salesChannel, formType, 'FloodPerilIncrementer')) {
          perilsAndDeductiblesSection.controls.push({
            name: 'FloodReadOnly',
            type: 'TextInput',
            value: 'No Coverage',
            config: {
              label: 'Flood Peril Deductible',
              disabled: true,
              hidden: true,
              col: 12
            }
          })
        }

        if (earthquakePeril && showControlByCoverageOption(salesChannel, formType, 'EarthquakePerilIncrementer')) {
          perilsAndDeductiblesSection.controls.push({
            name: 'EarthquakePerilIncrementer',
            type: 'PercentIncrementer',
            value: deductibleValues.earthquakePerilValues.percent,
            config: {
              ...getControlConfig(salesChannel, formType, 'EarthquakePerilIncrementer'),
              rules: getDeductibleRules({ deductibleValues }, salesChannel, 'EarthquakePerilIncrementer'),
              disabled: canNotEditCoverages
            }
          })
          perilsAndDeductiblesSection.controls.push({
            name: 'EarthquakeFlatAmount',
            type: 'TextInput',
            v2DataPath: 'Coverages.EarthquakePerilDeductible.value.Flat.Amount',
            value: deductibleValues.earthquakePerilValues.flat,
            config: {
              ...getControlConfig(salesChannel, formType, 'EarthquakeFlatAmount')
            }
          })
        } else if (!earthquakePeril && showControlByCoverageOption(salesChannel, formType, 'EarthquakePerilIncrementer')) {
          perilsAndDeductiblesSection.controls.push({
            name: 'EarthquakeReadOnly',
            type: 'TextInput',
            value: 'No Coverage',
            config: {
              label: 'Earthquake Peril Deductible',
              disabled: true,
              hidden: true,
              col: 12
            }
          })
        }

        const waterDamageSublimit = policy.Coverages.WaterDamageSublimit ?? 0;
        if (showControlByCoverageOption(salesChannel, formType, 'WaterDamagePeril') && !getComputedValue({ WaterDamageSublimit: waterDamageSublimit }, salesChannel, 'WaterDamageFlatAmountEnabled')) {
          perilsAndDeductiblesSection.controls.push({
            name: 'WaterReadOnly',
            type: 'TextInput',
            value: 'No Coverage',
            config: {
              label: 'Water Peril Deductible',
              disabled: true,
              hidden: true,
              col: 12
            }
          })
        }
        else {          
          perilsAndDeductiblesSection.controls.push({
            name: 'WaterDamagePeril',
            type: 'TextInput',
            v2DataPath: 'Coverages.WaterDamagePeril.value.Flat.Amount',
            value: policy.Coverages.WaterDamagePeril.value.Flat.Amount,
            config: {
              ...getControlConfig(salesChannel, formType, 'WaterDamagePeril'),
              col: 12,
              disabled: canNotEditCoverages,
              rules: getDeductibleRules({ WaterDamageSublimit: waterDamageSublimit }, salesChannel, 'WaterDamageFlatAmount')
            }
          })

          perilsAndDeductiblesSection.controls.push({
            name: 'WaterDamageSublimit',
            type: 'TextInput',
            v2DataPath: 'Coverages.WaterDamageSublimit.value',
            config: {
              ...getControlConfig(salesChannel, formType, 'WaterDamageSublimit'),
              label: 'Water Damage Sublimit',
              disabled: true,
              col: 12
            }
          })
        }
      }
    }
  })
  return formStates;
}

// This method adds the Endorsement Effective Date to the formState
export let addEndorsementDateV1 = (formStates) => {
  formStates.forEach((form) => {
    if (form.sections) {
      if (form.label === 'Insured Information') {
        form.sections.unshift(
          {
            label: 'Effective Date',
            controls: [
              {
                name: 'EndorsementEffectiveDate',
                type: 'DateInput',
                config: {
                  label: 'Endorsement Effective Date (mm/dd/yyyy)',
                  rules: 'required|validDate|validBackdateFlood|effectiveDateBeforeExpirationDate:@ExpirationDate',
                  default: formatDateForDisplay(new Date(), true),
                  col: 6
                }
              }
            ]
          }
        )
      }
    }
  })

  return formStates;
}

// This method adds the Endorsement Effective Date to the formState
export let addEndorsementDateV2 = (formStates, isUnderwriterOnUwTeamInspectionTeamOrAdmin, policy) => {
  const backdateDays = isUnderwriterOnUwTeamInspectionTeamOrAdmin ? 90 : 30
  let transactionDateValidation = ''

  if (policy?.Policy?.EndorsementTransactions && policy?.Policy?.EndorsementTransactions.length > 0) {
    const sortedTransactions = policy.Policy.EndorsementTransactions?.sort(function (a, b) {
      return new Date(b.TransactionDate) - new Date(a.TransactionDate);
    });

    const mostRecentTransactionDate = new Date(sortedTransactions[0]?.TransactionDate)
    const transactionDate = mostRecentTransactionDate.toLocaleDateString();
    transactionDateValidation = `|endorsementDateOnOrAfterLatestTransactionDate:${transactionDate}`
  }

  formStates.forEach((form) => {
    if (form.sections) {
      if (form.label === 'Insured Information') {
        form.sections.unshift(
          {
            label: 'Effective Date',
            controls: [
              {
                name: 'EndorsementEffectiveDate',
                type: 'DateInput',
                config: {
                  label: 'Endorsement Effective Date (mm/dd/yyyy)',
                  rules: `required|validDate|endorsementDateOnOrAfterEffectiveDate:@EffectiveDate|backdateNotMoreThanDaysFromToday:${backdateDays}|endorsementDateOnOrBeforeExpirationDate:@ExpirationDate${transactionDateValidation}`,
                  default: formatDateForDisplay(policy?.Policy?.TermBegin, true),
                  col: 6
                }
              }
            ]
          }
        )
      }
    }
  })

  return formStates;
}

// This method adds the Payment and Communication data points to the formState
export let addPaymentAndCommunicationData = (formStates) => {
  var endorsementForm = formStates.find(x => x.id === 'endorsement');
  if (!endorsementForm) {
    formStates.push({
      "id": "endorsement",
      sections: [
        {
          label: "Payment",
          controls: [
              {
                name: 'Payment',
                type: 'Hidden'
              },
              {
                name: 'PaymentTab',
                type: 'Hidden'
              }
          ],
          hidden: true
        },
        {
          label: "Communication",
          controls: [
            {
              "name": "InsuredEmailOptions",
              "type": "Hidden"
            }
          ],
          hidden: true
        }
      ]
    })
  }

  return formStates;
}

let sectionShouldBeRemovedByToggle = (sectionLabel, sectionsToRemoveByToggle) => {
  // check for control in list
  var sectionToRemove = sectionsToRemoveByToggle.find(section => section.label === sectionLabel && section.toggle);
  if (sectionToRemove) {
    // check toggle value
    return Store.getters.isFeatureFlagEnabled(sectionToRemove.toggle);
  }
  return false;
};

export let filterSection = (steps, coverageModelType) => {
  const selectedConfig = v2CoverageModelTypeMapping[coverageModelType.Value];
  for (let step of steps) {
    if (step.sections) {
      for (let i = 0; i < step.sections.length; i++) {
        let section = step.sections[i];
        let config = v1EndorsementsConfig

        if (selectedConfig) {
          config = selectedConfig
        }

        if (config.sectionsToRemove.includes(section.label) || sectionShouldBeRemovedByToggle(section.label, config.sectionsToRemoveByToggle)) {
          step.sections.splice(i, 1);
          i--;
        }
      }
    }
  }

  return steps;
}

export function showNotEditableAlert(coverageModelType, label) {
  if (!coverageModelType) return false

  const selectedConfig = v2CoverageModelTypeMapping[coverageModelType.Value];  
  let config = v1EndorsementsConfig

  if (selectedConfig) {
    config = selectedConfig
  }

  if (config.sectionsWithNoAlert.includes(label)) {
    return false;
  } else {
    return true;
  }
}


// The keys are the value that policy data spits out, and the values are what we want the form state data to display
const buildingSFDItems = {
  SingleFamily: "ResidentialSFD",
  MultiFamily: "ResidentialMFD",
  Condo: "ResidentialAPT"
}

const occupancyItems = {
  RentalTenant: "Rental / Tenanted",
  VacantUnoccupied: "Vacant / Unoccupied",
  CourseOfConstruction: "Course of Construction",
  VacantRenovation: "Vacant Renovation"
}

const constructionTypeItems = {
  SteelModifiedFireResistive: "Steel - Modified Fire Resistive",
  ReinforcedConcreteFireResistive: "Reinforced Concrete - Fire Resistive"
}

const foundationTypeItems = {
  ElevatedonCrawlSpace: "Elevated on Crawlspace",
  PiersPostsPilings: "Piers / Posts / Pilings",
  SlabOnGrade: "Slab On Grade",
  SlabOnFill: "Slab On Fill",
  SplitLevelSlab: "Split Level Slab",
  SubGradeCrawlspace: "Sub Grade Crawlspace"
}

export let mapPolicyToFormStateV1 = (policy) => {
  mapPolicyKeysToFormStateKeys(policy.Policy);
  mapPolicyKeysToValue(policy.Policy);
  policy.Policy.Insured.PrimaryInsured["DateOfBirth"] = formatDateForDisplay(policy.Policy.Insured.PrimaryInsured.DateOfBirth, true);
  mapCovValues(policy.Policy.Coverages);
  createDeductibleNode(policy.Policy.Coverages);
  createInsuredEmailOptionsNode(policy.Policy.Insured);
  mapAddressV1(policy.Policy.Risk.Location.Building.Risk);
  mapAddressV1(policy.Policy.Insured.MailingAddress);
  comparePolicyAddresses(policy.Policy);
  mapMortgagee(policy.Policy);
  mapPolicyValueToReadableFormState(policy.Policy.Risk.Location.Building.BuildingType, buildingSFDItems);
  mapPolicyValueToReadableFormState(policy.Policy.Risk.Location.Building.Occupancy, occupancyItems);
  mapPolicyValueToReadableFormState(policy.Policy.Risk.Location.Building.ConstructionType, constructionTypeItems);
  mapPolicyValueToReadableFormState(policy.Policy.Risk.Location.Building.FoundationType, foundationTypeItems);
  policy.Policy.Email = setEmail(policy.Policy.Email, policy.Policy.Insured.PrimaryInsured);
  setInsuredValue(policy.Policy.Insured.PrimaryInsured);

  return policy;
}

export let mapPolicyToFormStateV2 = (policy) => {
  mapMortgagee(policy.Policy);
  translateAdditionalInterests(policy.Policy);
  mapInsureds(policy.Policy)
  mapMailingAddress(policy.Policy)
  return policy;
}

// dynamic data binding -- overkill for now
// export let mapPolicyToFormStateV2DataPath = (formState, policy, keyPathList = null) => {
//   let keys = Object.keys(policy)
//   for(const key in keys) {
//     keyPathList = keyPathList || {parentProperty: '', keyPath: []};
//     let propetyName = keys[key]
//     let policyData = policy[propetyName]
//     if (!policyData) {
//       keyPathList = null
//       continue;
//     }
//     if (typeof policyData === 'object') {
//       if (!keyPathList.parentProperty) {
//         keyPathList.parentProperty = propetyName
//       } else if (keyPathList.parentProperty && !Array.isArray(policyData) && !Array.isArray(policy)) {
//         keyPathList.keyPath.push(keyPathList.parentProperty + '.' + propetyName)
//       }
//       mapPolicyToFormStateV2DataPath(formState, policyData, keyPathList)
//     } else if (typeof policyData === 'number' || typeof policyData === 'string') {
//       if (keyPathList.keyPath.length > 0) {
//         let keyPath = keyPathList.keyPath[keyPathList.keyPath.length - 1] ?? ''
//         let v2DataPath = keyPath + '.' + propetyName
//         let formStateControl = null
//         for(const step of formState.steps ?? [{sections: []}]) {
//           if (formStateControl) {
//             break
//           }
//           for(const section of step.sections ?? [{ controls: []}]) {
//             if (formStateControl) {
//               break
//             }
//             for (const control of section.controls ?? [{}]) {
//               if (control.v2DataPath === v2DataPath) {
//                 formStateControl = control
//                 break
//               }
//             }
//           }
//         }
//         if (formStateControl) {
//           formStateControl.value = policyData
//           Store.commit('updateFormFromContract', {
//             module: 'endorsementForm',
//             v2DataPath: v2DataPath,
//             value: policyData,
//           });
//         }
//       }

//     } else {
//       keyPathList = null
//     }
//   }
//   return formState;
// }

let translateAdditionalInterests = (policy) => {
  if (policy.AdditionalInterests != null && policy.AdditionalInterests.length > 0) {
    policy.AdditionalInterestsList = {
      value: policy.AdditionalInterests.map((ai) =>
      ({
        Type: ai.AdditionalInterestType,
        Address: formatAddress(ai.Address),
        Name: ai.Name
      }))
    }
    policy.AdditionalInterests = {
      value: true
    }
  } else {
    policy.AdditionalInterestsList = { value: []};
    policy.AdditionalInterests = {
      value: false
    }
  }
  return policy;
}

let formatAddress = (address) => {
  let formatted = '';
  formatted += (address.StreetAddress || (address.StreetNumber + ' ' + address.StreetName));
  if (address.UnitType && address.UnitType != '#') a += ' ' + address.UnitType + (address.UnitNumber ? ' ' + address.UnitNumber : '');
  formatted += ', ';
  formatted += (address.City ? address.City + ', ': '') + address.State + ' ' + address.PostalCode + ' ' + address.CountryCode;
  address.Formatted = formatted;
  return address;
}

// Key is the key for Policy, value is the key for FormState for when FormState and Policy keys do not line up.
// We are using this to align the Policy keys with FormState
const translateKey = {
  RiskAddress: "Risk",
  OccupancyType: "Occupancy",
  NumberOfStepsV1: "NumberOfSteps",
  NumberOfStories: "NumStories",
  SquareFootage: "SquareFeet",
  BuildingValuation: "BuildingValuationAmount",
  ContentsValuation: "ContentsValuationAmount"
}

let mapPolicyKeysToFormStateKeys = (policy) => {
  for (let nestedPolicy in policy) {
    if (typeof policy[nestedPolicy] === "object") {
      const formStateName = Object.keys(translateKey).find(p => p === nestedPolicy)
      if (formStateName) {
        policy[translateKey[formStateName]] = policy[nestedPolicy];
        delete policy[nestedPolicy];
      }
      else {
        mapPolicyKeysToFormStateKeys(policy[nestedPolicy]);
      }
    }
    else if (typeof policy[nestedPolicy] === "string") {
      const formStateName = Object.keys(translateKey).find(p => p === nestedPolicy)
      if (formStateName) {
        policy[translateKey[formStateName]] = policy[nestedPolicy];
        delete policy[nestedPolicy];
      }
    }
  }

  return policy;
}

// List of Policy keys that need to have the key changed to value to match FormState
const translateValue = [
  "Elevation",
  "NumberOfMonths",
  "NumberOfSteps",
  "NumOfStories",
  "SqrFootage",
  "Year",
  "ValuationAmount",
  "ContentsValuation",
  "Value",
  "Valuation"
]

let mapPolicyKeysToValue = (policy) => {
  for (const nestedPolicy in policy) {
    if (typeof policy[nestedPolicy] === 'object') {
      mapPolicyKeysToValue(policy[nestedPolicy]);
    } else {
      translateValue.forEach(translateKey => {
        if (nestedPolicy === translateKey) {
          policy['value'] = policy[nestedPolicy];
          delete policy[nestedPolicy];
        }
      });
    }
  }
  return policy;
}

let mapCovValues = (cov) => {
  for (const key in cov) {
    if (cov[key] && cov[key].FloodPeril && cov[key].FloodPeril.Limit) {
      if (typeof cov[key].FloodPeril.Limit.Amount === 'undefined') {
        cov[key].value = 0
      }
      else {
        cov[key].value = cov[key].FloodPeril.Limit.Amount;
      }
    }
  }
  return cov;
}

let createDeductibleNode = (cov) => {
  const deductible = cov.CovA.FloodPeril.Deductible.Amount.toString();

  cov.FloodPerilDeductible = {
    value: deductible
  };

  return cov;
}

let createInsuredEmailOptionsNode = (insured) => {
  insured.InsuredEmailOptions = {
    value: { ...insured.InsuredEmailOptions }
  };

  return insured;
}

let mapAddressV1 = (address) => {
  let unitNumber = "";

  if (address.UnitType != null && address.UnitNumber != null) {
    unitNumber = " " + address.UnitType + " " + address.UnitNumber;
  }

  let streetAddress = "";

  if (address.StreetNumber !== undefined && address.StreetName !== undefined) {
    streetAddress = address.StreetNumber + " " + address.StreetName;
  }
  else {
    streetAddress = address.StreetAddress;
  }

  const formattedAddress =
    streetAddress
    + unitNumber + ", "
    + address.City + ", "
    + address.State + " "
    + address.PostalCode + " "
    + address.CountryCode;

  let newAddress = JSON.stringify(address);

  if (!address.value) {
    address.value = JSON.parse(newAddress);
    address.value.Formatted = formattedAddress;
    address.value.Locked = true;
  }
  return address;
}

let comparePolicyAddresses = (policy) => {
  let riskAddress = policy.Risk.Location.Building.Risk.value.Formatted;
  let mailingAddress = policy.Insured.MailingAddress.value.Formatted;

  if (riskAddress.toLowerCase() === mailingAddress.toLowerCase()) {
    policy.MailingAddressSame = {
      value: true
    }
  }
  else {
    policy.MailingAddressSame = {
      value: false
    }
  }

  return policy;
}

let mapMortgagee = (policy) => {
  let primary = policy?.PrimaryMortgagee;
  let secondary = policy?.SecondaryMortgagee;

  if (primary !== null) {
    policy.Mortgagee1 = {
      value: true
    }

    policy.Mortgagee1Company = {
      value: {
        MortgageeName: primary.MortgageeOrganization,
        MortgageeAddress1: primary.Address.StreetAddress,
        MortgageeCity: primary.Address.City,
        MortgageeState: primary.Address.State,
        MortgageeZip: primary.Address.PostalCode,
        MortgageeCountry: primary.Address.CountryCode,
        MortgageeSuffix: primary.MortageeSuffix,
        MortgageeLoanNumber: primary.LoanNumber
      }
    }

    // MortageeSuffix is mispelled in the policy data
    policy.Mortgagee1Suffix = {
      value: primary.MortageeSuffix
    }

    policy.Mortgagee1LoanNumber = {
      value: primary.LoanNumber
    }
    policy.Mortgagee1Invoice = {
      value: primary.InvoiceOnRenewal
    }
    if (secondary !== null) {
      policy.Mortgagee2 = {
        value: true
      }

      policy.Mortgagee2Company = {
        value: {
          MortgageeName: secondary.MortgageeOrganization,
          MortgageeAddress1: secondary.Address.StreetAddress,
          MortgageeCity: secondary.Address.City,
          MortgageeState: secondary.Address.State,
          MortgageeZip: secondary.Address.PostalCode,
          MortgageeCountry: secondary.Address.CountryCode,
          MortgageeSuffix: secondary.MortageeSuffix,
          MortgageeLoanNumber: secondary.LoanNumber
        }
      }

      policy.Mortgagee2Suffix = {
        value: secondary.MortageeSuffix
      }

      policy.Mortgagee2LoanNumber = {
        value: secondary.LoanNumber
      }
    }
    else {
      policy.Mortgagee2 = {
        value: false
      }
      policy.Mortgagee2Company = {
        value: null
      }
    }
  }
  else {
    policy.Mortgagee1 = {
      value: false
    }
    policy.Mortgagee1Company = {
      value: null
    }
    policy.Mortgagee2 = {
      value: false
    }
    policy.Mortgagee2Company = {
      value: null
    }
  }

  return policy;
}

let mapInsureds = (policy) => {

  let primary = policy?.Insured?.PrimaryInsured;
  let secondary = policy?.Insured?.SecondaryInsured;

  if (primary !== null) {
    policy.FirstName = {
      value: primary.FirstName
    } 
    policy.LastName = {
      value: primary.LastName
    }
    policy.DateOfBirth = {
      value: formatDateForDisplay(primary.DateOfBirth, true)
    }
    policy.Occupation = {
      value: policy?.Risk?.Occupation?.value
    }

    if (secondary !== null && (secondary.FirstName != null && secondary.LastName != null && secondary.DateOfBirth != null)) {
      policy.CoApplicant = {
        value: true
      }
      policy.CoApplicantFirstName = {
        value: secondary.FirstName
      }
      policy.CoApplicantLastName = {
        value: secondary.LastName
      }
      policy.CoApplicantDateOfBirth = {
        value: formatDateForDisplay(secondary.DateOfBirth, true)
      }
      policy.CoApplicantRelationship = {
        value: policy?.Risk?.CoApplicantRelationship?.value
      }
    }
  }
  return policy;
}

let mapMailingAddress = (policy) => {

  let mailingAddress = policy?.Insured?.MailingAddress;
  let riskAddress = policy?.Risk.Risk.value;
  const riskStreetAddress = riskAddress.StreetNumber + ' ' + riskAddress.StreetName

  if (mailingAddress !== null && mailingAddress.StreetAddress != null) {
    if (mailingAddress.City != riskAddress.City || 
      mailingAddress.CountryCode != riskAddress.CountryCode ||
      mailingAddress.PostalCode != riskAddress.PostalCode ||
      mailingAddress.State != riskAddress.State || 
      mailingAddress.StreetAddress != riskStreetAddress) {
      policy.MailingAddressSame = {
        value: false
      } 
    }
    policy.MailingAddress = {
      value: {
        City: mailingAddress.City,
        CountryCode: mailingAddress.CountryCode,
        Locked: true,
        PostalCode: mailingAddress.PostalCode,
        State: mailingAddress.State,
        StreetAddress: mailingAddress.StreetAddress
      }
    } 
  }
  return policy;
}

let mapPolicyValueToReadableFormState = (policyValue, translationLayer) => {
  for (const key in translationLayer) {
    const value = translationLayer[key];

    if (policyValue.value === key) {
      policyValue.value = value;
      break;
    }
  }

  return policyValue;
}

let setInsuredValue = (insured) => {
  for (const item in insured) {
    insured[item] = { value: insured[item] }
  }

  return insured;
}

let setEmail = (insuredEmail, primaryInsured) => {
  var email = ""
  if (!primaryInsured.EmailAddress) {
    email = insuredEmail
  } else {
    email = primaryInsured.EmailAddress
  }
  insuredEmail = {Email: {'value': email}}
  return insuredEmail;
}

export let updateFormStateValues = (formState, policy) => {
  for (const key in policy) {
    if (typeof policy[key] === 'object' && policy[key] !== null) {
      if ('value' in policy[key]) {
        const nameToMatch = key;
        const valueToOverwrite = policy[key].value;
        for (const step of formState.steps) {
          if (step.sections) {
            for (const section of step.sections) {
              if (section.controls) {
                for (const control of section.controls) {
                  if (control.name === nameToMatch) {
                    const typeOfValueToOverwrite = typeof valueToOverwrite;
                    const typeOfControlValue = typeof control.value;
                    if (control.value === null && valueToOverwrite === null) {
                      continue
                    }
                    if (control.value === null || typeOfControlValue === 'undefined' || typeOfValueToOverwrite === typeOfControlValue) {
                      if (typeof control.value === 'object') {
                        const currentValueKeys = Object.keys(control.value ?? {});
                        for (const key of currentValueKeys) {
                          if (valueToOverwrite[key] === undefined) {
                            valueToOverwrite[key] = control.value[key];
                          }
                        }
                      }
                      control.value = valueToOverwrite;
                      Store.commit('updateFormControl', { // we have to update data again to push policy data into the v2 data path since the server overrides data now
                        module: 'endorsementForm',
                        name: control.name,
                        value: control.value,
                      });
                    } else {
                      let newValue = traverse(valueToOverwrite)
                      if (typeof control.value === 'string' && typeof newValue === 'number') {
                        newValue = newValue.toString()
                      }
                      control.value = newValue
                      Store.commit('updateFormControl', {  // we have to update data again to push policy data into the v2 data path since the server overrides data now
                        module: 'endorsementForm',
                        name: control.name,
                        value: control.value,
                      });
                    }
                  }
                }
              }
            }
          }
        }
      } else {
        updateFormStateValues(formState, policy[key]);
      }
    }
  }

  return formState;
}

function traverse(jsonObj) {
  let returnValue = null;
  if (jsonObj !== null && typeof jsonObj == "object") {
    Object.entries(jsonObj).forEach(([key, value]) => {
      // key is either an array index or object key
      returnValue = traverse(value);
    });
    return returnValue;
  } else {
    return jsonObj
  }
}

export let getControlOptionTextFromControlValue = (control) => {
  if (control.value !== undefined) {
    const option = control.config.options.find(option => option.value === control.value);
    if (option) {
      return option.text;
    }
  }
  return null;
}

export let formatMortgageeV1ForDisplay = (control) => {
  if (control.value) {
    let formattedMortgageeName = ''

    if (control.value.MortgageeSuffix) {
      formattedMortgageeName = control.value.MortgageeName + ' ' +
        control.value.MortgageeSuffix
    }
    else {
      formattedMortgageeName = control.value.MortgageeName
    }

    let formattedMortgageeV1 = '<pre>'
      + formattedMortgageeName
      + '\r\n'
      + control.value.MortgageeAddress1
      + '\r\n'
      + control.value.MortgageeCity
      + ', ' + control.value.MortgageeState
      + ' ' + control.value.MortgageeZip
      + ' ' + control.value.MortgageeCountry

    if (control.value.MortgageeLoanNumber) {
      formattedMortgageeV1 = formattedMortgageeV1
        + '\r\n'
        + 'Loan Number: '
        + control.value.MortgageeLoanNumber
        + '<pre/>'
    }

    return formattedMortgageeV1
  }

  return "No mortgagee on original policy";
}

export let isPremiumBearingEndorsement = (endorsement) => {
  // 3ab99971-905e-40c4-9fdf-16f0a406f904 is the ID for PremiumBearing EndorsementType
  return endorsement?.Endorsement.EndorsementType.Id === '3ab99971-905e-40c4-9fdf-16f0a406f904'
}


let mapCovValuesV2 = (cov) => {
  // TODO: figure out what this was doing
  //for (const key in cov) {
  //  if (cov[key] && cov[key].FloodPeril && cov[key].FloodPeril.Limit) {
  //    if (typeof cov[key].FloodPeril.Limit.Amount === 'undefined') {
  //      cov[key].value = 0
  //    }
  //    else {
  //      cov[key].value = cov[key].FloodPeril.Limit.Amount;
  //    }
  //  }
  //}
  //return cov;
}

let createDeductibleNodeV2 = (cov) => {
  // TODO: figure out what this was doing
  //const deductible = cov.CovA.FloodPeril.Deductible.Amount.toString();

  //cov.FloodPerilDeductible = {
  //  value: deductible
  //};

  return cov;
}