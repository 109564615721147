<template>
  <v-container>
    <v-row v-if="accountId">
        <v-col cols="12" class="pb-0">
            <v-btn outlined v-if="accountId" class="go-back mb-0" @click="goBack">
              <i class="fas fa-chevron-left"></i>&nbsp;Go Back
            </v-btn>
        </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <h1>Account Servicing</h1>
      </v-col>
    </v-row>

    <v-card class="pa-8">
      <v-row>
        <v-col cols="12" class="text-center">
          <h3 class="mb-2">This account is serviced by your underwriting team.</h3>
          <h3>Please submit a request to this account's underwriter.</h3>
        </v-col>
        <v-col cols="12" class="text-center" v-if="underwriter">
          <Avatar 
            :src="getUserAvatarPath(underwriter.Email)" 
            size="150"
            color="white" 
            :alt="underwriter.Name" 
            class="mb-3" 
          />
          <div>
            <h2 class="mb-2">{{underwriter.Name}}</h2>
            <h4 class="mb-1">{{underwriter.Phone}}</h4>
            <h4 class="mb-0"><a :href="'mailto:' + underwriter.Email" style="word-break: break-all;">{{underwriter.Email}}</a></h4>
          </div>
        </v-col>
      </v-row>
      
    </v-card>

  </v-container>
</template>

<script>
import { AccountService } from '@/api/AccountService'
import { getUserAvatarPath } from '@/scripts/helper'
import Avatar from '@/components/shared/Avatar.vue'

export default {
  name: 'AccountServicing',
  components: {
    Avatar
  },
  data() {
    return {
      accountId: null,
      policyKey: null,
      account: null,
      underwriter: null
    }
  },
  methods: {
    getUserAvatarPath,
    goBack() {
      this.$router.push({ path: '/account', query: { id: this.accountId } })
    }
  },
  async mounted() {
    this.accountId = this.$route.query.id;
    this.policyKey = this.$route.query.policy;
    if (this.accountId) {
      this.account = await AccountService.get({id: this.accountId});
      this.underwriter = this.account.Underwriter;
    }

  }


}
</script>
