<template>
    <div>
        <!-- :header-props="{sortIcon: null}" -->
        
            
      <v-data-table v-if="loading || results"
                    :headers="tableHeaders"
                    :items="resultsDisplay"
                    :items-per-page="10"
                    :item-key="rowkey"
                    :loading="loading"
                    loading-text="Loading..."
                    :hide-default-footer="hideFooter"
                    :show-expand="showExpand"
                    :expanded.sync="expanded"
                    :sort-by="sortBy"
                    :sort-desc="sortDesc"
                    :single-expand="singleExpand"
                    :header-props="{sortIcon: 'fad fa-sort-up'}"
                    :footer-props="{
                        itemsPerPageOptions: [10, 20, 50, 100, -1]
                    }">


        <template v-for="h in tableHeaders" v-slot:[`header.${h.value}`]="{header}">
          <span :key="h.value">
            <span>{{header.text}}</span>
            <v-menu v-if="header.filterable"
                    offset-y
                    :close-on-content-click="false"
                    v-model="filtersOpen[header.value]"
                    :key="h.value">
              <template v-slot:activator="{on, attrs}">
                <v-icon small v-bind="attrs" v-on="on"
                        :style="{'color': filters[header.value] ? 'black' : ''}">mdi-filter</v-icon>
              </template>
              <div style="background-color: white; width: 280px; padding: 20px;">
                <div class="dfc">
                  <TextInput label="Search"
                             :clearable="true"
                             :autofocus="true"
                             @change="updateFilter(header.value, $event)"
                             @keydown.enter="closeFilter(header.value)"></TextInput>
                </div>
              </div>
            </v-menu>
          </span>

        </template>

        <template v-slot:item.data-table-expand="{ item, expand, isExpanded }">
          <v-btn icon
                 @click="expandIconClick(item, expand, isExpanded)">
            <v-icon>mdi-chevron-down</v-icon>
          </v-btn>
        </template>

        <template v-for="(h, i) in tableHeaders" v-slot:[`item.${h.value}`]="{item}">
          <slot :name="h.value" :item="item">
            <span v-html="item[h.value]" v-bind:key="i" />
          </slot>
        </template>

        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <slot name="expansion-panel" :item="item" />
          </td>
        </template>

      </v-data-table>
        
        
    </div>
</template>

<script>
import Vue from 'vue'
import TextInput from '@/components/form/TextInput.vue'

export default {
    name: "ResultsTable",
    components: {
        TextInput
    },
    props: {
        tableHeaders: Array,
        results: Array,
        loading: {
            type: Boolean,
            default: false
        },
        hideFooter: {
            type: Boolean,
            default: false
        },
        showExpand: {
            type: Boolean,
            default: false
        },
        singleExpand: {
            type: Boolean,
            default: false
        },
        rowkey: String,
        sortBy: String,
        sortDesc: {
          type: Boolean,
          default: false
        }
    },
    emits: [
        'item-expanded'
    ],
    data() {
        return {
            resultsDisplay: [],        
            filters: {},
            filtersOpen: {},
            expanded: []
        }
    }, 
    methods: {
        compile(template) {
            return Vue.compile(template)
        },
        updateFilter(field, value) {
            this.filters[field] = value;
            this.filterResults();
        },
        closeFilter(field) {
            this.filtersOpen[field] = false;
        },
        filterResults() {

          let data = [...this.results];
          let filters = Object.keys(this.filters);
            filters.forEach(f => {
              if (this.filters[f]) {
                    data = data.filter(d => d[f].toLowerCase().indexOf(this.filters[f].toLowerCase()) > -1);
                }
            })
            this.resultsDisplay = [...data];

        },
        rerender() {
            this.$nextTick(() => {
                this.$forceUpdate();
            });
        },
        expandIconClick(item, expand, isExpanded) {
            expand(!isExpanded)
            if (!isExpanded) {
                this.$emit('item-expanded', item)
            }
        }
    },
    computed: {
    },
    watch: {
        results: {
            handler(newVal) {
                if (newVal) {
                  this.resultsDisplay = [...newVal];
                  this.filterResults();
                }
            },
            deep: true,
            immediate: true,
        }
    },
    mounted() {
        this.tableHeaders.forEach((h) => {
            if (h.filterable) {
                this.filters[h.value] = '';
                this.filtersOpen[h.value] = false;
            }
        })
    }
}
</script>

<style scoped lang="scss">
@import '@/style.scss';

::v-deep .v-data-table-header__icon {
    opacity: 1;
    margin-left: 2px;
    transition: none!important;
}

::v-deep .v-data-table tr th,
::v-deep .v-data-table tr td {
    padding-top: 20px!important;
    padding-bottom: 20px!important;
}

::v-deep .v-data-table-header th {
  white-space: nowrap;
}

::v-deep .v-data-table tbody {
    tr:nth-child(2n + 1) {
        background-color: #F7FAFF;
    }
}

::v-deep .v-data-table > .v-data-table__wrapper > table > tbody > tr > td, 
::v-deep .v-data-table > .v-data-table__wrapper > table > tbody > tr > th, 
::v-deep .v-data-table > .v-data-table__wrapper > table > thead > tr > td, 
::v-deep .v-data-table > .v-data-table__wrapper > table > thead > tr > th, 
::v-deep .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td, 
::v-deep .v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
    padding: 0 10px;
}

</style>
