<template>
  <div>
    <v-container>
      <h1>Gate Rules</h1>

      <v-card class="mb-4">
          <ResultsTable 
              :tableHeaders="tableHeaders"
              :results="results">

              <template v-slot:GateName="{item}">
                <h3>{{item.GateName}}</h3>
              </template>

              <template v-slot:Actions="{item}">
                <router-link :to="{ path: '/admin/gate', query: { id: item.id}}" style="display: block; text-align: center;">
                    <v-btn color="secondary" type="button" small>
                        View
                    </v-btn>
                </router-link>
              </template>

          </ResultsTable>
      </v-card>

    </v-container>
  </div>
</template>

<script>
import ResultsTable from '@/components/shared/ResultsTable.vue'
import { GateService } from '@/api/GateService'

export default {
  name: 'Gates',
  components: {
    ResultsTable
  },
  data() {
    return {
      gates: [],
      tableHeaders: [
          {text: "Gate", value: "GateName", sortable: true, filterable: true},
          {text: "", value: "Actions", sortable: false, filterable: false},
      ],
      results: [],
    }
  },
  mounted() {
    GateService.getSalesChannels()
    .then((data) => {
      this.gates = data;

      this.gates.forEach(g => {
        this.results.push({
          GateName: g.SalesChannel,
          id: g.id
        });
      })
    });
  }
};
</script>
