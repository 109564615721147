<template>
  <div>

    <v-container v-if="message">
      <v-row no-gutters>
        <v-col cols="6">
          <v-list lines="one">

            <v-list-item v-for="n in metadataHeader.filter((v, k) => k % 2 == 0)" :key="n.key">
              <v-list-item-content>
                <v-list-item-title>{{ n.key }}</v-list-item-title>
                <v-list-item-subtitle>{{ n.value }}</v-list-item-subtitle>
              </v-list-item-content>

            </v-list-item>
          </v-list>
        </v-col>
        <v-col cols="6">
          <v-list lines="one">

            <v-list-item v-for="n in metadataHeader.filter((v, k) => k % 2 == 1)" :key="n.key">
              <v-list-item-content>
                <v-list-item-title>{{ n.key }}</v-list-item-title>
                <v-list-item-subtitle>{{ n.value }}</v-list-item-subtitle>
              </v-list-item-content>

            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row no-gutters>
        <v-col cols="6">
          <v-list lines="one">
            <h3>Outbound</h3>
            <v-list-item v-for="n in metadataOutbound" :key="n.key">
              <v-list-item-content>
                <v-list-item-title>{{ n.key }}</v-list-item-title>
                <v-list-item-subtitle>{{ n.value }}</v-list-item-subtitle>
              </v-list-item-content>

            </v-list-item>
          </v-list>
        </v-col>
        <v-col cols="6">
          <v-list lines="one">
            <h3>Inbound</h3>
            <v-list-item v-for="n in metadataInbound" :key="n.key">
              <v-list-item-content>
                <v-list-item-title>{{ n.key }}</v-list-item-title>
                <v-list-item-subtitle>{{ n.value }}</v-list-item-subtitle>
              </v-list-item-content>

            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row no-gutters>
        <v-col cols="6">
          <MonacoEditor height="700px" language="json" :value="outboundString" :options="monacoOptions"></MonacoEditor>
        </v-col>
        <v-col cols="6">
          <MonacoEditor height="700px" language="json" :value="inboundString" :options="monacoOptions"></MonacoEditor>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import MonacoEditor from 'monaco-editor-vue';

export default {
  name: 'ExternalIntegrationMessage',
  components: {
    MonacoEditor
  },
  data() {
    return {
      monacoOptions: {
        colorDecorators: true,
        readOnly: true
      },
    }
  },
  props: {
    message: Object
  },
  computed: {
    outboundString() {
      if (!this?.message?.Outbound?.Content) return "";
      else return JSON.stringify(JSON.parse(atob(this.message.Outbound.Content)), undefined, 2);
    },
    inboundString() {
      if (!this?.message?.Inbound?.Content) return "";
      else return JSON.stringify(JSON.parse(atob(this.message.Inbound.Content)), undefined, 2);
    },
    metadataHeader() {

      const formatted = {
        "Integration Name": this.message?.IntegrationName ?? "",
        "Endpoint Name": this.message?.EndpointName ?? "",
      };
      const result = Object.entries(formatted).map(e => { return { key: e[0], value: e[1] } });
      return result;
    },
    metadataOutbound() {

      const formatted = {
        "Method": this.message?.Outbound?.Metadata?.Method ?? "",
        "Content Type": this.message?.Outbound?.ContentType ?? "",
        "Query String": this.message?.Outbound?.QueryString ?? "",
      };
      const result = Object.entries(formatted).map(e => { return { key: e[0], value: e[1] } });
      return result;
    },
    metadataInbound() {

      const formatted = {
        "Status Code": this.message?.Inbound?.Metadata?.StatusCode ?? "",
        "Content Type": this.message?.Inbound?.ContentType ?? "",
      };
      const result = Object.entries(formatted).map(e => { return { key: e[0], value: e[1] } });
      return result;
    }
  }
}
</script>
