import Store from '@/vuex/store'


export const showTipHelper = (module: string, tip: any): boolean => {
  if (!tip) return true;

  let showTip = true;

  tip.conditions.forEach((t: any) => {

    let result = false;

    let value: any = null;
    value = Store.getters.getControlValue(module, t.name)

    if (t.type == 'EqualTo') {
      if (value === t.value) result = true;
    }

    //each tipCondition is treated as an AND condition, thus if a result is false, the overall showTip needs to be false
    if (showTip == true) showTip = result;

  })

  return showTip;
}