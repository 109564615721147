var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-container',[_c('h1',[_vm._v("Partition Management")]),_c('v-card',{staticClass:"mb-4",attrs:{"loading":_vm.accountLoading}},[_c('v-card-title',[_vm._v(" Sync Account ")]),_c('v-card-text',[_c('ValidationObserver',{ref:"observer1",attrs:{"tag":"span"},scopedSlots:_vm._u([{key:"default",fn:function({}){return [_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.syncAccount.apply(null, arguments)}}},[_c('v-row',[_c('v-col',{attrs:{"md":"6","lg":"4"}},[_c('DynamicFormControl',{staticStyle:{"margin-top":"0px"},attrs:{"control":{
                                      name: 'Account',
                                      type: 'TextInput',
                                      config: {
                                          label: 'AccountId',
                                          rules: _vm.accountIdRules,
                                          col: 12,
                                          short: true,
                                          clearable: true
                                      }
                                    }},on:{"change":function($event){_vm.accountId = $event.value}}})],1),_c('v-col',{attrs:{"md":"6","lg":"8"}},[_c('FormButton',{attrs:{"label":"Submit","size":"x-large","disabled":_vm.accountLoading},on:{"click":_vm.syncAccount}})],1)],1)],1)]}}])})],1)],1),_c('v-card',{staticClass:"mb-4",attrs:{"loading":_vm.agentLoading}},[_c('v-card-title',[_vm._v(" Sync Amlink User ")]),_c('v-card-text',[_c('ValidationObserver',{ref:"observer2",attrs:{"tag":"span"},scopedSlots:_vm._u([{key:"default",fn:function({}){return [_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.syncAgent.apply(null, arguments)}}},[_c('v-row',[_c('v-col',{attrs:{"md":"6","lg":"4"}},[_c('DynamicFormControl',{staticStyle:{"margin-top":"0px"},attrs:{"control":{
                                      name: 'Agent',
                                      type: 'TextInput',
                                      config: {
                                          label: 'Amlink Human ID',
                                          rules: _vm.idRules,
                                          col: 12,
                                          short: true,
                                          clearable: true,
                                          type: 'number',
                                          min: 1
                                      }
                                    }},on:{"change":function($event){_vm.humanId = $event.value}}})],1),_c('v-col',{attrs:{"md":"6","lg":"8"}},[_c('FormButton',{attrs:{"label":"Submit","size":"x-large","disabled":_vm.agentLoading},on:{"click":_vm.syncAgent}})],1)],1)],1)]}}])})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }