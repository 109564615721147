var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-container',[_c('h1',[_vm._v("Renewal Processing")]),_c('v-card',{staticClass:"mb-4"},[_c('v-card-title',[_vm._v(" Run Renewals ")]),_c('v-card-text',[_c('ValidationObserver',{ref:"observer1",attrs:{"tag":"span"},scopedSlots:_vm._u([{key:"default",fn:function({}){return [_c('v-form',{ref:"form"},[_c('v-row',[_c('v-col',{staticClass:"py-0"},[_c('DynamicFormControl',{staticClass:"pt-3",attrs:{"control":{
                                      name: 'PolicyKey',
                                      type: 'TextInput',
                                      config: {
                                          label: 'Policy Key',
                                          rules: _vm.policyKeyRules,
                                          col: 12,
                                          short: true,
                                          clearable: true,
                                          disabled: _vm.disablePolicyKey,
                                          maxlength: null // unlimited to support many, many keys in a CSV
                                      }
                                    }},on:{"change":function($event){_vm.policyKey = $event.value}}})],1),_c('v-col',{staticClass:"py-0"},[_c('DynamicFormControl',{attrs:{"control":{
                                      name: 'BeginDate',
                                      type: 'DateInput',
                                      value: _vm.beginDate,
                                      config: {
                                          label: 'Begin Date',
                                          col: 12,
                                          rules: _vm.beginDateRules,
                                          clearable: true,
                                          allowedDates: _vm.beginDateAllowedDates,
                                          disabled: _vm.disableBeginEndDates
                                      }
                                    }},on:{"change":function($event){_vm.beginDate = $event.value}}})],1),_c('v-col',{staticClass:"py-0"},[_c('DynamicFormControl',{attrs:{"control":{
                                      name: 'EndDate',
                                      type: 'DateInput',
                                      value: _vm.endDate,
                                      config: {
                                          label: 'End Date',
                                          col: 12,
                                          rules: _vm.endDateRules,
                                          clearable: true,
                                          allowedDates: _vm.endDateAllowedDates,
                                          disabled: _vm.disableBeginEndDates
                                      }
                                    }},on:{"change":function($event){_vm.endDate = $event.value}}})],1),_c('v-col',{staticClass:"py-0"},[_c('DynamicFormControl',{attrs:{"control":{
                                      name: 'RatePlanEffectiveDate',
                                      type: 'DateInput',
                                      value: _vm.ratePlanEffectiveDate,
                                      config: {
                                          label: 'Rate Plan Effective Date',
                                          col: 12,
                                          clearable: true
                                      }
                                    }},on:{"change":function($event){_vm.ratePlanEffectiveDate = $event.value}}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"py-0"},[_c('DynamicFormControl',{staticClass:"pt-3",attrs:{"control":{
                                      name: 'IncludeContractCodes',
                                      type: 'TextInput',
                                      value: _vm.includeContractCodeList,
                                      config: {
                                          label: 'Include these Contract Codes (in comma delimited list) default: All Contracts',
                                          col: 12,
                                          short: true,
                                          clearable: true
                                      }
                                    }},on:{"change":function($event){_vm.includeContractCodeList = $event.value}}})],1),_c('v-col',{staticClass:"py-0"},[_c('DynamicFormControl',{staticClass:"pt-3",attrs:{"control":{
                                      name: 'ExcludeContractCodes',
                                      type: 'TextInput',
                                      value: _vm.excludeContractCodeList,
                                      config: {
                                          label: 'Exclude these Contract Codes (in comma delimited list) default: All Contracts',
                                          col: 12,
                                          short: true,
                                          clearable: true
                                      }
                                    }},on:{"change":function($event){_vm.excludeContractCodeList = $event.value}}})],1),_c('v-col',{staticClass:"py-0"},[_c('DynamicFormControl',{staticClass:"pt-5",attrs:{"control":{
                                      type: 'Checkbox',
                                      name: 'ForceOverwrite',
                                      value: _vm.forceOverwrite,
                                      config: {
                                        label: 'ForceOverwrite?',
                                        short: true
                                      }
                                    }},on:{"change":function($event){_vm.forceOverwrite = $event.value}}})],1),_c('v-col',{staticClass:"py-0"},[_c('DynamicFormControl',{staticClass:"pt-5",attrs:{"control":{
                                      type: 'Checkbox',
                                      name: 'Recalc',
                                      value: _vm.recalc,
                                      config: {
                                        label: 'Recalc?',
                                        short: true
                                      }
                                    }},on:{"change":function($event){_vm.recalc = $event.value}}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-0"},[_c('FormButton',{attrs:{"label":"Begin","size":"x-large","disabled":_vm.loading},on:{"click":_vm.runRenewals}})],1)],1)],1)]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('v-card',{staticClass:"mb-4"},[_c('v-card-text',{staticClass:"text-center"},[_c('h2',{staticClass:"mb-2"},[_vm._v("Scheduled Renewal Offer Jobs:")]),_c('h1',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.scheduledJobsCount)+" ")])])],1)],1)],1),_c('p',[_c('FormButton',{attrs:{"label":"Refresh Data","size":"x-large","disabled":_vm.loading},on:{"click":_vm.getData}})],1),_c('v-card',{staticClass:"mb-4"},[_c('v-card-title',[_vm._v(" Failed Renewal Offer Jobs ("+_vm._s(_vm.failedJobs.length)+") ")]),_c('v-card-text',[_c('ResultsTable',{attrs:{"tableHeaders":_vm.failedJobsHeaders,"results":_vm.failedJobs,"loading":_vm.loading},scopedSlots:_vm._u([{key:"FailedAt",fn:function({item}){return [_c('span',[_vm._v(" "+_vm._s(_vm.formatDateTimeForDisplay(item.FailedAt))+" ")])]}},{key:"Name",fn:function({item}){return [_c('span',[_vm._v(" "+_vm._s(item.Name)+" ")])]}},{key:"Parameters",fn:function({item}){return [_vm._l((item.Parameters),function(param,index){return [_c('div',{key:index},[_c('strong',[_vm._v(_vm._s(_vm.methodParameters[index])+":")]),(_vm.methodParameters[index] == 'Rate Plan Effective Date')?_c('span',[_vm._v(" "+_vm._s(_vm.formatDateForDisplay(param))+" ")]):_c('span',[_vm._v(" "+_vm._s(param)+" ")])])]})]}},{key:"Exception",fn:function({item}){return [_c('span',[_vm._v(" "+_vm._s(item.Exception)+" ")])]}},{key:"Actions",fn:function({item}){return [_c('FormButton',{attrs:{"label":"Reprocess","size":"medium","disabled":_vm.loading},on:{"click":function($event){return _vm.reprocessFailedJob(item.Key)}}})]}}])})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }