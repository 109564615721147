<template>
    <div>
        <v-avatar :style="{'border-color': color}" class="card-box-shadow" :size="size">
            <v-img
                :src="src"
                :alt="alt"
                :width="size"
                :height="size"
                contain
                max-width="100%"
                :aspect-ratio="1"
            >
                <template v-slot:placeholder>
                    <v-img
                        :src="defaultImage ? defaultImage : standardDefaultImage"
                        :alt="alt"
                        :width="size"
                        :height="size"
                        contain
                        max-width="100%"
                        :aspect-ratio="1"
                    />
                </template>
            </v-img>
        </v-avatar>
    </div>
</template>

<script>
    //https://cdn.vuetifyjs.com/images/john.jpg

    export default {
        name: 'Avatar',
        props: {
            src: String,
            alt: {
                type: String,
                default: "",
            },
            color: {
                type: String,
                default: '#002B49'
            },
            size: {
                type: String,
                default: "54"
            },
            defaultImage: {
                type: String,
                default: null
            }
        },
        data() {
            return {
                standardDefaultImage: '/images/placeholder-profile.png'
            }
        },
    }

</script>

<style scoped>
    .v-avatar {
        border: 4px solid #002B49;
    }

    ::v-deep .v-avatar img, .v-avatar svg, .v-avatar .v-icon, .v-avatar .v-image, .v-avatar .v-responsive__content {
        display: block;
    }

</style>
