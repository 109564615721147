import { Module } from 'vuex';
import { ResourceState, RootState } from './types';
import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { SbixService } from '@/api/SbixService'
import { SysService } from '@/api/SysService';
import { EtcService } from '@/api/EtcService';
import { NewsFeedService } from '@/api/NewsFeedService'
import { FeatureFlagsService } from '@/api/FeatureFlagsService';
import { MoratoriumService } from '@/api/MoratoriumService'
import { UnderwriterService } from '@/api/UnderwriterService'
import { ActionContext } from 'vuex';
import testDataModule from '@/vuex/testDataModule';

export const state: ResourceState = {
  catastropheClosings: [],
  moratoriumInEffect: false,
  moratoriumBannerText: "",
  countries: [],
  countriesV1: [],
  dashboardNews: [],
  underwriters: [],
  loading: false
}

const mutations = {
  setLoading: (state: any, payload: any) => {
    state.loading = payload;
  },
  setCatastropheClosings: (state: any, items: any) => {
    state.catastropheClosings = items;
  },
  setMoratoriumInEffect: (state: any, moratoriumInEffect: boolean) => {
    state.moratoriumInEffect = moratoriumInEffect;
  },
  setMoratoriumBannerText: (state: any, moratoriumBannerText: string) => {
    state.moratoriumBannerText = moratoriumBannerText;
  },
  setCountries: (state: any, items: any[]) => {
    state.countries = items;
  },
  setCountriesV1: (state: any, items: any[]) => {
    state.countriesV1 = items;
  },
  setUnderwriters: (state: any, items: any[]) => {
    state.underwriters = items;
  },
  addDashboardNewsItem: (state: any, item: any) => {
    state.dashboardNews.push(item);
  }
}

const getters = {
  loading(state: any) {
    return state.loading;
  },
  // Returns boolean that determines if there are any current closings
  isCurrentClosing(state: any) {
    return state.catastropheClosings.some((x: { IsCurrent: boolean }) => x.IsCurrent);
  },
  // Returns array of objects for any current closings
  currentClosings(state: any) {
    return state.catastropheClosings.filter((x: { IsCurrent: boolean }) => x.IsCurrent);
  },
  // Returns the moratorium banner display text
  moratoriumBannerDisplayText(state: any) {
    return state.moratoriumBannerText;
  },
  // Returns boolean that determines if there are any current moratoriums
  isCurrentMoratorium(state: any) {
    return state.moratoriumInEffect;
  },
  // Returns array of objects for all closings
  getItems(state: any) {
    return state.catastropheClosings;
  },
  // Returns array of objects for any current closings for a specific risk
  currentClosingsForRisk: (state: any) => (postalCode: any, stateAbbr: any, county: any, program: any) => {
    const now = new Date();
    return state.catastropheClosings.filter((x: { EffectiveDateTime: Date, ExpirationDateTime: Date, IsCurrent: boolean, FormattedStates: string, FormattedZipCodes: string, FormattedCounties: string, FormattedPrograms: string }) =>
      //x.IsCurrent &&
      (now >= new Date(x.EffectiveDateTime)) &&
      (now <= new Date(x.ExpirationDateTime)) &&
      (x.FormattedStates.includes('All') || x.FormattedStates.includes(stateAbbr)) &&
      (x.FormattedZipCodes.includes(`All (${stateAbbr})`) || x.FormattedZipCodes.includes(postalCode)) &&
      (x.FormattedCounties.includes(`All (${stateAbbr})`) || x.FormattedCounties.includes(county)) &&
      (x.FormattedPrograms.includes('All') || x.FormattedPrograms.includes(program)))
  },
  getCountries(state: any) {
    return state.countries;
  },
  getCountriesV1(state: any) {
    return state.countriesV1;
  },
  getUnderwriters(state: any) {
    return state.underwriters;
  },
  getDashboardNews(state: any) {
    return state.dashboardNews;
  }
}

const actions: ActionTree<ResourceState, RootState> = {
  getMoratoriumBanner: ({ commit }: ActionContext<ResourceState, RootState>) => {
    // if env local, use mock data
    const isLocalEnvironment = process.env.NODE_ENV === 'development';
    if (isLocalEnvironment) {
      commit('setMoratoriumInEffect', testDataModule.state.moratoriumTestBanner.MoratoriumInEffect);
      commit('setMoratoriumBannerText', testDataModule.state.moratoriumTestBanner.BannerDisplayText);
    } else {
    // if env not local, make real request

          
            try {
              MoratoriumService.banner()
              .then((response) => {
                commit('setMoratoriumInEffect', response.MoratoriumInEffect);
                commit('setMoratoriumBannerText', response.BannerDisplayText);
              });
            } catch (error) {
              // Handle the error
              console.error(error);
            }
          
     
    }
  },
  getCatastropheClosings: ({ commit, dispatch }) => {
    commit('setLoading', true);
    SbixService.getCatastrophies()
      .then((response) => {
        commit('setCatastropheClosings', response);
      })
      .catch((e) => {
        console.warn('unexpected error in getCatastropheClosings, will retry in 10secs...', e)
        setTimeout(() => {
          dispatch('getCatastropheClosings');
        }, 10000);
      })
      .finally(() => commit('setLoading', false));
  },
  getCountries({commit}) {
    SysService.country()
      .then((response) => {
        commit('setCountries', response);
      })
      .catch((e) => {
        console.log('error', e)
      })
  },
  getCountriesV1({commit}) {
    EtcService.iso3166()
      .then((response) => {
        commit('setCountriesV1', response);
      })
      .catch((e) => {
        console.log('error', e)
      })
  },
  getUnderwriters({commit}) {
    const zips = require("zips");

    UnderwriterService.getAllUnderwriters()
      .then((response) => {
        response.forEach(underwriter => {
          if (!underwriter.GeoLatitude || !underwriter.GeoLongitude) {
            const place = zips.getByZipCode(underwriter.Postal);

            underwriter.GeoLatitude = place.lat;
            underwriter.GeoLongitude = place.long;
          }
        })
        commit('setUnderwriters', response);
      })
      .catch((e) => {
        console.log('error', e)
      })
  },
  getDashboardNews({state, commit}) {
    if (state.dashboardNews.length > 0) return;
    NewsFeedService.getAmwinsArticles({
      articleCount: 5
    }, {
      //@ts-ignore
      handleError: false
    }).then((response) => {
        if(!response) return; //handle 204 response
        response.value.forEach((article: any) => {
          NewsFeedService.getAmwinsArticleImage({ 
            key: article.Id 
          }, {
            //@ts-ignore
            handleError: false
          }).then((imageResponse) => {
              article.ImageUrl = imageResponse.value[0].Url
              article.AbstractForDisplay = article.Abstract.substring(0, 120) + "...";
              commit('addDashboardNewsItem', article);
            })
        })
      })
  }
}

const namespaced: boolean = true;

export const resource: Module<ResourceState, RootState> = {
    namespaced,
    state,
    getters,
    actions,
    mutations
}
