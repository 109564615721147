import {
  Underwriter,
  Amwins_Portal_Api_Services_EtcService_UnderwriterTeamMember,
  IList,
  List,
  IListResult,
  ListResultDto,
  IPagedResult,
  PagedResultDto,
  Dictionary,
  IDictionary,
  IRequestOptions,
  IRequestConfig,
  getConfigs,
  axios,
  basePath
} from './index.defs';

export class UnderwriterService {
  /** Generate by swagger-axios-codegen */
  // @ts-nocheck
  /* eslint-disable */

  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Underwriter> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Underwriter';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getFlood(
    params: {
      /**  */
      state?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Underwriter> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Underwriter/flood';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { state: params['state'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static userIsUnderwriter(options: IRequestOptions = {}): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Underwriter/UserIsUnderwriter';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static seek(
    params: {
      /**  */
      q?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Underwriter[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Underwriter/Seek';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { q: params['q'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAllUnderwriters(options: IRequestOptions = {}): Promise<Underwriter[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Underwriter/GetAllUnderwriters';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getUnderwriterTeamMap(
    options: IRequestOptions = {}
  ): Promise<Amwins_Portal_Api_Services_EtcService_UnderwriterTeamMember[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Underwriter/GetUnderwriterTeamMap';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}
