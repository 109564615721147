<template>
    <span>
        <Tag
            v-bind="{
                ...computedProperties
            }"
        >    
        </Tag>
    </span>
</template>

<script>
import Tag from '@/components/shared/Tag.vue'

export default {
    name: 'PolicyTag',
    components: {
        Tag
    },
    props: {
        Type: String,
    },
    data() {
        return {
            color: null,
            text: null,
            icon: null,
        }
    },
    computed: {
        computedProperties() {
            if (this.Type == 'Policy') {
                this.color = '#3778A2';
                this.text = 'Policy';
                this.icon = 'fas fa-file-alt';
            } else {
                this.color = '#DF5829';
                this.text = 'Quote';
                this.icon = 'fas fa-file-alt';
            }
            return {
                color: this.color,
                text: this.text,
                icon: this.icon
            }
        },
    }
}
</script>
