import {
  AmlinkUser,
  Any,
  IList,
  List,
  IListResult,
  ListResultDto,
  IPagedResult,
  PagedResultDto,
  Dictionary,
  IDictionary,
  IRequestOptions,
  IRequestConfig,
  getConfigs,
  axios,
  basePath
} from './index.defs';

export class UserProfileService {
  /** Generate by swagger-axios-codegen */
  // @ts-nocheck
  /* eslint-disable */

  /**
   *
   */
  static amlink(
    params: {
      /**  */
      email?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AmlinkUser> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/UserProfile/amlink';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { email: params['email'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static amlinkHistory(
    params: {
      /**  */
      email?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AmlinkUser[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/UserProfile/AmlinkHistory';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { email: params['email'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static sendAmlinkSupportRequest(
    params: {
      /**  */
      body?: Any;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/UserProfile/AmlinkSupportRequest';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAmlinkSupportRequest(options: IRequestOptions = {}): Promise<Any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/UserProfile/AmlinkSupportRequest';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(options: IRequestOptions = {}): Promise<Any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/UserProfile';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static put(
    params: {
      /**  */
      body?: Any;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/UserProfile';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static newUser(
    params: {
      /**  */
      firstName?: string;
      /**  */
      lastname?: string;
      /**  */
      body?: Any;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/UserProfile';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { firstName: params['firstName'], lastname: params['lastname'] };
      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static userOnUnderwriterTeam(
    params: {
      /**  */
      email?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/UserProfile/UserOnUnderwriterTeam';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { email: params['email'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}
