<template>
  <div> 
    <Dialog :title="jsonModalTitle" :value="showModal" @close-dialog="closeModal" width="90%">
      <template v-slot:card-text>
        <pre>{{ modalData }}</pre>
      </template>
      <template v-slot:card-actions>
          <v-btn color="secondary" text type="button" @click="closeModal">Close</v-btn>
      </template>
    </Dialog>

    <Dialog :title="jsonModalTitle2" :value="showModal2" @close-dialog="closeModal2" width="30%">
      <template v-slot:card-text>
        <div  v-if="CanRemove()" style="display: flex; flex-direction: column; align-items: center; text-align: center;">
      <pre>{{ modalData2 }}</pre>
      <span style="text-align: center;">
        Are you sure you want to remove the application from the dashboard?
      </span>
      <v-btn x-small color="secondary" @click="RemoveApp(modalData2)">Remove</v-btn>
    </div>

    <div  v-else style="display: flex; flex-direction: column; align-items: center; text-align: center;">
      <pre>{{ modalData2 }}</pre>
      <span style="text-align: center;">
       You do not have necessary perissions to remove application from dashboard.
      </span>
      
    </div>
      </template>
      
      <template v-slot:card-actions>
          <v-btn color="secondary" text type="button" @click="closeModal2">Close</v-btn>
      </template>
    </Dialog>

    


    <v-container>
      
      <v-card class="mb-4" :loading="healthcheckLoading">
        <v-card-text>
          <ResultsTable :tableHeaders="tableHeaders"
            :loading="healthcheckLoading"
            :results="subscribingApplications"
            :sort-by="sortBy"
            :sort-desc="desc"
            :rowkey="applicationName"
            showExpand>
            
            <template v-slot:AppName="{item}">
              <span>
                {{ item.AppName }}
              </span>

              
            </template>

            <template v-slot:header>
              <th class="text-left">Application Name</th>
              <th class="text-left">Health Status</th>
              <th class="text-left">
                <v-btn color="red" @click="removeAllApps">Remove All</v-btn>
              </th>
            </template>

             <template v-slot:Remove="{item}">
             
              <v-btn  v-if="isAdminOrSupport()"  x-small color="secondary" @click="openModalWithData2(item) ">Remove </v-btn>
            </template> 

            <template v-slot:HealthReport.StatusGrade="{item}">
              <span>
                <v-chip :color="getColor(item.HealthReport.Status)">
                  {{ item.HealthReport.Status}}
                </v-chip>
              </span>
            </template>

            <template v-slot:expansion-panel="{item}">
              <v-simple-table>
                <thead>
                    <tr>
                        <th class="text-left">Health Check</th>
                        <th class="text-left">Health Status</th>
                        <th class="text-left">Description</th>
                        <th class="text-left">Tags</th>
                        <th class="text-left">Data</th>
                       
                        
                    </tr>
                </thead>
                <tbody>
                
                    <tr
                    v-for="healthcheck in item.HealthReport.Entries"
                    :key="healthcheck.HealthCheckName">
                   
                        <td>
                          
                      
                          {{healthcheck.HealthCheckName}}</td>
                        <td>
                          
                          <v-chip :color="getColor(healthcheck.Status)">{{ healthcheck.Status }}</v-chip></td>
                          <td> {{ healthcheck.Description }}</td>
                          <td> {{ healthcheck.Tags }}</td>
                          <td>
                          <v-btn  x-small color="secondary" @click="openModalWithData(healthcheck) ">View </v-btn>
                        </td>
                       
     
                        
                          
                    </tr>
                </tbody>
              </v-simple-table>
            </template>
          </ResultsTable>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import ResultsTable from '@/components/shared/ResultsTable.vue'
import Environment from '@/scripts/environment'
import Dialog from '@/components/shared/Dialog.vue'

export default {
  name: 'HealthCheckDashboard',
  components: {
    ResultsTable,
    Dialog
  },
  props: {
    healthcheckLoading: Boolean,
    subscribingApplications: []
    
  },
  data() {
    return {
      tableHeaders: [
        { text: "Application Name", value: "AppName", sortable: true, filterable: true, key: 'AppName' },
        { text: "Health Status", value: "HealthReport.StatusGrade", sortable:true, filterable: true, key: 'HealthReport.Status'},
        { text: "Actions", value: "Remove", sortable:false, filterable: false, key: 'AppName'}
        
       
      ],
      applicationName: "AppName",
      sortBy: "HealthReport.StatusGrade",
      desc: false,
      modalData: null,
      jsonModalTitle: "",
      showModal: false,
      modalData2: null,
      jsonModalTitle2: "",
      showModal2: false
    }
  },
  methods: {
    getColor (status) {
      console.log('Status:', status);
        if (status == 'Unhealthy') return 'red'
        else if (status == 'Degraded') return 'orange'
        else return 'green'
    },
    openModalWithData(data) {
      this.modalData = data.Data 
      this.showModal = true;
      this.jsonModalTitle = `Data Details - ${data.HealthCheckName} - ${data.Status}`
    },
    openModalWithData2(data) {
      this.modalData2 = data.AppName 
      this.showModal2 = true;
      this.jsonModalTitle2 = `Removing - ${data.AppName} - ${data.HealthReport.Status}`
    },
    closeModal() {
      this.showModal = false;
      this.modalMode = null;
      this.modalData = null;
      
    },
    closeModal2() {
      this.showModal2 = false;
      this.modalMode2 = null;
      this.modalData2 = null;
      
    },
    RemoveApp(data) {
      console.log(data)
      this.$emit('remove-app', data);
      this.showModal2 = false;
      this.modalMode2 = null;
      this.modalData2 = null; 
    },
    isAdminOrSupport() {
        return ( this.$store.getters.getUserRole == 'admin' || this.$store.getters.getUserRole == 'support');
      },
    CanRemove() {
        return ( this.$store.getters.userHasRestrictedFeature("RestrictedFeatures_RemoveApplication"));
      }
      
  }
}
</script>