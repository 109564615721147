<template>
  <Dialog :title="title"
          cardTitleStyle="padding: 40px 40px 20px;"
          titleFontSize="26px" titleFontWeight="bold"
          width="750px"
          :value="open"
          @close-dialog="close">
    <template v-if="onAdditionalForm" v-slot:card-text>
      <div style="padding: 0 16px;">
        <br>
        <p class="mb-0" style="line-height: 26px;">If you click <strong>Agree &amp; Continue</strong> below, we will:</p>
        <br>
        <ul>
          <li style="line-height: 26px;">DISCARD any changes you have made at bind.</li>
          <li style="line-height: 26px;">RESET to originally quoted terms.</li>
        </ul>
      </div>
    </template>
    <template v-else v-slot:card-text>
      <div style="padding: 0 16px;">
        <br>
        <p class="mb-0" style="line-height: 26px;">If you click <strong>Agree &amp; Continue</strong> below, we will:</p>
        <br>
        <ul>
          <li style="line-height: 26px;">VOID any bindable quote(s) provided, rendering them invalid.</li>
          <li style="line-height: 26px;">RESTART the quote process which is subject to updated real-time Rating and Eligibility.</li>
        </ul>
        <br>
        <p class="mb-0" style="line-height: 26px;"><em style="opacity: 0.8">Note: Please discard any previous quote(s) you've printed out or saved.</em></p>
        <br>
      </div>
    </template>
    <template v-slot:card-actions>
      <div style="padding: 40px 30px 10px;">
        <v-btn color="secondary" outlined type="button" large @click="close" class="mr-4 px-6"><i class="fas fa-times"></i>&nbsp;&nbsp;&nbsp;<strong style="position: relative; top: 1px;">Cancel</strong></v-btn>
        <v-btn color="secondary" type="button" large @click="agree" class="px-8">Agree&nbsp;&amp;&nbsp;Continue&nbsp;&nbsp;&nbsp;<i class="fas fa-chevron-right"></i></v-btn>
      </div>
    </template>
  </Dialog>
</template>

<script>
  import Dialog from '@/components/shared/Dialog.vue'

  export default {
    components: {
      Dialog
    },
    props: {
      onAdditionalForm: {
        type: Boolean,
        default: false
      },
    },
    data() {
      return {
        open: false
      }
    },
    emits: [
      'agree',
      'close'
    ],
    computed: {
      title() {
        return this.onAdditionalForm ? 'Are you sure you want to go back?' : 'Are you sure you want to continue?'
      }
    },
    methods: {
      agree() {
        this.$emit('agree');
      },
      close() {
        this.$emit('close');
      }
    },
    mounted() {
      this.open = true;
    }
  }
</script>
