<template>
    <v-container>
        <h1>Search</h1>
        <v-card class="pa-8">

            <div v-if="!isAgent">
                <DynamicFormControl 
                    :control="{
                        value: selectedAgency,
                        name: 'Agency',
                        type: 'AgencyAutocomplete',
                        config: {
                            label: 'Filter By Agency',
                            col: 12,
                            short: true,
                            clearable: true
                        }
                    }"
                    @change="selectAgency"
                />
            </div>
            <div :class="{'d-flex': true, 'pb-2': loading || searched}">
              
                <DynamicFormControl 
                    :control="{
                        value: searchTerm,
                        name: 'Search',
                        type: 'TextInput',
                        config: {
                            label: 'Search by Name, Address, Policy/Submission/Quote Number',
                            col: 12,
                            short: true,
                            clearable: true
                        }
                    }"
                    style="margin-top: 0px;"
                    @change="searchInput"
                />


                <!-- <FormButton label="Search" size="x-large" @click="search" :disabled="disableSearch" /> -->

                <!-- <v-btn color="secondary" x-large type="submit" :disabled="disableSearch" @click="search" 
                    style="height: 57px; position: relative; top: -1px;">
                    Search
                </v-btn> -->

            </div>
            <!-- <div :class="{'pb-4': loading || searched}">
                <p class="mb-0">To see a list of Policies/Quotes, leave blank and click on Search button.  To filter, click on <v-icon style="color: inherit; top: -4px;">mdi-filter</v-icon></p>
            </div> -->
            <div class="text-center" v-if="loading && !searched">
                <Loading :size="60" />
            </div>
            <ResultsTable 
                v-else-if="searched"
                :tableHeaders="tableHeaders"
                :results="results"
                
                :sortDesc="false">

                <template v-slot:PolicyQuoteNumber="{item}">
                    <span v-if="item.PolicyNumber">
                        {{ item.PolicyNumber}}
                    </span>
                </template>

                <template v-slot:AddressText="{item}">
                    <span class="nowrap" style="font-size: 12px;">
                        {{item.Risk.Street1}} {{item.Risk.Street2}}<br>
                        {{item.Risk.City}}, {{item.Risk.State}} {{item.Risk.Postal}}
                    </span>
                </template>

                <template v-slot:PolicyNumber="{item}">
                    {{item.PolicyNumber}}
                </template>

                <template v-slot:Product="{item}">
                    {{item.Product ? item.Product.toUpperCase() : null}}
                </template>

                <template v-slot:AccountStatus="{item}">
                    <span style="font-size: 12px;">
                        <PolicyStatus :Status="item.AccountStatus" />
                    </span>
                </template>

                <template v-slot:Type="{item}">
                    <span style="font-size: 12px;">
                        <PolicyTag :Type="item.Type" />
                    </span>
                </template>

                <template v-slot:TermBegin="{item}">
                    <span>
                        {{ formatDateForDisplay(item.TermBegin, true) }}
                    </span>
                </template>

                <template v-slot:UnderwriterName="{item}">
                    <span v-if="item.Underwriter" style="font-size: 12px;">
                        {{ item.Underwriter.Name }}
                        <v-btn v-if="item.Underwriter.Name && showUwReassignmentBtn(item)" icon class="ml-1" @click="reassignUnderwriterClicked(item)">
                            <i class="fas fa-pen" style="font-size: 14px;"></i>
                        </v-btn>
                    </span>
                </template>

                <template v-slot:AgencyName="{item}">
                    <span v-if="item.Agency.BranchName" style="font-size: 12px; display: block; max-width: 200px;">
                        {{ item.Agency.SeekDisplay }}
                    </span>
                    <span v-else></span>
                </template>

                <template v-slot:InsuredName="{item}">
                    <span v-if="item.Insured" style="font-size: 12px;">
                        {{ item.Insured.Name }}
                    </span>
                </template>

                <template v-slot:View="{item}">
                    <ViewAccount :account="item" />
                </template>

            </ResultsTable>
        </v-card>
        
        <br><br>

        <GenericDialog :value="showEditUnderwriterModal"
                       title="Underwriter Reassignment"
                       width="600px"
                       @close-dialog="showEditUnderwriterModal = false">

            <template v-slot:card-text>
                <div cols="12">
                    <p>By selecting a new Underwriter below, you will reassign this risk to the selected Underwriter and it will no longer show up in your dashboard.</p>
                    <DynamicFormControl 
                        :control="{
                            type: 'UnderwriterAutocomplete',
                            name: 'Underwriter',
                            value: selectedUnderwriter,
                            config: {
                                label:'Choose your Amwins Underwriter',
                                rules: 'required',
                                col: 12
                            }
                        }"
                        @change="selectedUnderwriter = $event.value"/>
                </div>
            </template>

            <template v-slot:card-actions>
                <v-btn color="secondary" type="submit" outlined @click="showEditUnderwriterModal = false">
                    Cancel
                </v-btn>&nbsp;
                <v-btn color="secondary" type="submit" :disabled="uwReassignmentLoading" @click="submitUnderwriterReassignment()">
                  <Loading v-if="uwReassignmentLoading" :size="24" class="mr-2" />
                    Reassign
                </v-btn>
            </template>

        </GenericDialog>
    </v-container>
</template>

<script>
import ResultsTable from '@/components/shared/ResultsTable.vue'
import PolicyTag from '@/components/shared/PolicyTag.vue'
import ViewAccount from '@/components/shared/ViewAccount.vue'
import DynamicFormControl from '@/components/form/DynamicFormControl.vue'
import { AccountService } from '@/api/AccountService'
import { UnderwriterService } from '@/api/UnderwriterService'
import { makeFilterableText, formatDateForDisplay } from '@/scripts/helper'
import { AgencyService } from '@/api/AgencyService'

export default {
    name: 'Search',
    components: {
        ResultsTable,
        PolicyTag,
        ViewAccount,
        DynamicFormControl,
        GenericDialog: () => import(/* webpackPrefetch: true */ '@/components/shared/Dialog.vue')
    },
    data() {
        return {
            selectedAgency: null,
            searchTerm: null,
            loading: false,
            uwReassignmentLoading: false,
            searched: false,
            initialized: false,
            results: [],
            timeout: null,
            tableHeaders: [
                {text: "Policy/Quote Number", value: "PolicyQuoteNumber", filterable: true},
                {text: "Client Name", value: "InsuredName", filterable: true},
                {text: "Address", value: "AddressText", filterable: true},
                {text: "Product", value: "Product", filterable: true},
                {text: "Status", value: "AccountStatus"},
                {text: "Type", value: "Type"},
                {text: "Effective Date", value: "TermBegin"},
                {text: "Underwriter", value: "UnderwriterName", filterable: true},
                {text: "Agency", value: "AgencyName", filterable: true},
                {text: "View", value: "View", sortable: false}
            ],
            showEditUnderwriterModal: false,
            selectedUnderwriter: null,
            selectedAccount: null,
            underwriterTeamMap: []
      }
    },
    computed: {
        disableSearch() {
            //return !this.searchTerm && !this.selectedAgency;
            return false;
        },
        isAgent() {
            return this.$store.getters.userHasRole('agent');
        },
        isEmployee() {
            return this.$store.getters.userHasRole('employee');
        },
        isSupport() {
            return this.$store.getters.userHasRole('support');
        },
        isAdmin() {
            return this.$store.getters.userHasRole('admin');
        },
        searchTermIsEmpty() {
            return (!this.searchTerm || this.searchTerm === '');
        },
        userEmail() {
            return this.$store.getters.getUserEmail
        }
    },
    methods: {
        makeFilterableText,
        formatDateForDisplay,
        setParams() {
            const path = this.$route.path;

            if (this.searchTerm) this.searchTerm = this.searchTerm.trim();

            //if (!this.searchTerm && !this.selectedAgency) return;

            const params = [];
            if (this.searchTerm) params.push({key: 'q', value: encodeURIComponent(this.searchTerm)});
            if (this.selectedAgency) params.push({key: 'agency', value: encodeURIComponent(this.selectedAgency.id)})
            const paramsArray = params.map(p => p.key + '=' + p.value);
            let paramString = '';
            if (paramsArray.length) paramString += '?' + paramsArray.join('&');
            
            history.replaceState(
                {},
                null,
                path + paramString
            )
        },
        search() {

            this.setParams();

            this.$gtag.event('Account Search', {
                'search_term': this.searchTerm
            })
            
            this.loading = true;

            AccountService.search({ 
                query: this.searchTerm ? this.searchTerm : null,
                orgBranchId: this.selectedAgency ? this.selectedAgency.OrgBranchId : null
            })
            .then((searchResponse) => {
                this.results = [];
                searchResponse.forEach(item => {
                    // Add filter for PolicyNumber
                    item.PolicyQuoteNumber = this.makeFilterableText(item, 'PolicyNumber');
                    // Add filter for Insured
                    item.InsuredName = this.makeFilterableText(item.Insured, 'Name');
                    // Add filter for Address
                    item.AddressText = this.makeFilterableText(item.Risk);
                    // Add filter for Underwriter
                    item.UnderwriterName = this.makeFilterableText(item.Underwriter, 'Name');
                    // Add filter for Agency
                    item.AgencyName = this.makeFilterableText(item.Agency, 'SeekDisplay');
                    this.results.push(item)
                })
            })
            .catch(() => {
                
            })
            .finally(() => {
                this.searched = true;
                this.loading = false;
                this.loaded = true;
            });
        },
        searchInput(event) {
            this.searchTerm = event.value;
            if (this.timeout) clearTimeout(this.timeout)
            this.timeout = setTimeout(() => {
                if (this.isAgent || !this.searchTermIsEmpty) {
                    if (this.loaded) this.search();
                    else this.setParams();
                } else {
                    this.setParams();
                }
            }, 300)
        },
        selectAgency(event) {
            this.selectedAgency = event.value;
            if (this.selectedAgency || !this.searchTermIsEmpty) {
                this.search();
            } else {
                this.setParams();
            }
        },
        reassignUnderwriterClicked(account) {
            this.selectedAccount = account;
            this.selectedUnderwriter = account.Underwriter;
            this.showEditUnderwriterModal = true;
        },
        async submitUnderwriterReassignment() {
            this.uwReassignmentLoading = true
            await AccountService.updateUnderwriter({ id: this.selectedAccount.id, underwriterId: this.selectedUnderwriter.key })
              .then(() => {
                  this.$store.dispatch('toastSuccess', { Message: 'Underwriter reassigned successfully' })                
              })
              .catch(() => {
                  this.$store.dispatch('toastError', { Message: 'Failed to reassign underwriter' })
              })
              .finally(() => {
                  this.search();
                  this.showEditUnderwriterModal = false;
                  this.uwReassignmentLoading = false;
              })
        },
        showUwReassignmentBtn(account) {
            // only show the UW reassignment button for Quotes in Incomplete or Quoted status
            if (!(account.Type === 'Quote' && (account.Status === 'Incomplete' || account.Status === 'Quoted'))) return false;

            // additionally, the user must be admin/support OR an employee on the account UW's team
            if (this.isSupport || this.isAdmin) return true;

            const userIsUnderwriter = account.Underwriter.Email === this.userEmail;
            const userIsOnUwTeam = this.underwriterTeamMap.findIndex(x => x.underwriterId == account.Underwriter.key && x.email === this.userEmail) > -1;

            if (this.isEmployee && (userIsUnderwriter || userIsOnUwTeam)) return true;

            return false;
        },
        updateSelectedUnderwriter(event) {
            this.selectedUnderwriter = event.value
        }
    },
    async mounted() {
        const searchTerm = this.$route.query.q;
        const agency = this.$route.query.agency;

        if (this.isEmployee) {
          await UnderwriterService.getUnderwriterTeamMap()
            .then((response) => {
                this.underwriterTeamMap = response
            })
        }

        if (this.isAgent) {
            this.search();
        }
        else if (searchTerm || agency) {
            this.searchTerm = searchTerm;
            if (agency) {
                this.selectedAgency = await AgencyService.get({id: agency})
            }
            this.search();
        } else {
            this.loaded = true;
        }
    }
}
</script>

<style scoped>

</style>
