<template>
  <div>
    <v-container>
      <v-row>
        <v-col md="6" cols="12">
          <h1 class="mb-0">Restricted Features</h1>
        </v-col>
        <!-- <v-col md="6" cols="12" class="text-right">
          <v-btn color="white" type="button" class="ml-2">
            Add/Edit Feature
          </v-btn>
        </v-col> -->
      </v-row>  
      <v-row v-if="showRestrictedFeatureAdmin">
        <v-col sm="6" cols="12">
          <v-card class="mb-4 pa-2">
            <v-card-title class="pb-0">
              Select a User
            </v-card-title>
            <v-card-text>
              <ValidationObserver tag="span" ref="userObserver">
                <form @submit.prevent="validateUser()">
                  <div class="d-flex">
                      <DynamicFormControl :control="{
                          type: 'TextInput',
                          name: 'UserEmail',
                          value: selectedUserEmail,
                          config: {
                              label:'User Email',
                              rules: 'required|email',
                              short: true,
                              disabled: selectedUserLoading
                          }
                        }"
                        @change="selectedUserEmail = $event.value"
                       />

                      <v-btn color="secondary" x-large type="submit" class="ml-2" style="margin-top: 10px;" :disabled="selectedUserLoading">
                        Search
                      </v-btn>
                  </div>
                </form>
              </ValidationObserver>
            </v-card-text>
          </v-card>

          
        </v-col>

        <v-col sm="6" cols="12">
          <v-card class="mb-4 pa-2 px-4" v-if="selectedUserInfo">
              <v-card-title class="pb-0">
                {{selectedUserInfo.Name}}
              </v-card-title>
              <v-card-text>
                <h5 style="color: #333;" class="mb-0 mt-2">Role: <span style="text-transform: capitalize;">{{selectedUserInfo.Role}}</span></h5>
              </v-card-text>
          </v-card>

          <v-card class="mb-4 pa-2">

            
            <v-card-title class="pb-0">
              <v-col md="9" cols="12" class="py-0">
                Restricted Features
              </v-col>
              <v-col md="3" cols="12" class="text-right py-0">
                <v-btn color="white" type="button" @click="openRestrictedFeatureModal()" v-if="isAdmin">
                  <i class="fas fa-plus-circle" style="position: relative; top: -1px"></i>&nbsp;Add
                </v-btn>
              </v-col>


            </v-card-title>
            <v-card-text class="px-6">
              <ValidationObserver tag="span" ref="userRestrictedFeaturesObserver">
                <form @submit.prevent="validateUserRestrictedFeatures()">
                  <div v-for="f in restrictedFeatures" :key="f.id">
                    <v-row class="mt-0" v-if="showRestrictedFeature(f)">
                      <v-col cols="9" class="py-0">
                        <DynamicFormControl :control="{
                            type: 'Checkbox',
                            name:  f.id,
                            value: userHasSelectedFeature(f.id),
                            config: {
                                label: f.name,
                                rules: '',
                                short: true,
                                disabled: !selectedUser
                            }
                          }"
                          @change="updateSelectedFeatureUser($event)"
                        />
                      </v-col>
                      <v-col cols="3" class="text-right py-0">
                        <v-btn icon small @click="openRestrictedFeatureModal(f)" title="Edit" :key="f.id + 'edit'" v-if="isAdmin" class="mt-2">
                          <i class="fas fa-pen"></i>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </div>

                  <div style="text-align: right;" v-if="selectedUser">
                    <br>
                    <v-btn color="secondary" type="submit" class="ml-2 mt-2">
                      Update User
                    </v-btn>
                  </div>
                </form>
              </ValidationObserver>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-alert v-else type="warning" dense :icon="false" class="mt-4">You do not have access to this feature.</v-alert>

      <Dialog :title="restrictedFeatureModalTitle" :value="showRestrictedFeatureModal" width="600px" @close-dialog="closeRestrictedFeatureModal">
            <template v-slot:card-text>
                <ValidationObserver tag="span" ref="restrictedFeatureObserver">
                    <form @submit.prevent="validateRestrictedFeature()" v-if="selectedRestrictedFeature">
                        <v-row>
                            <v-col cols="12" class="py-0">
                                <DynamicFormControl
                                    :control="{
                                        name: 'key',
                                        value: selectedRestrictedFeature.key,
                                        type: 'TextInput',
                                        config: {
                                            label: 'Key',
                                            rules: 'required',
                                            short: true
                                        }
                                    }"
                                    @change="selectedRestrictedFeature.key = $event.value" 
                                />
                            </v-col>
                            <v-col cols="12" class="py-0">
                                <DynamicFormControl
                                    :control="{
                                        name: 'name',
                                        value: selectedRestrictedFeature.name,
                                        type: 'TextInput',
                                        config: {
                                            label: 'Name',
                                            rules: 'required',
                                            short: true
                                        }
                                    }"
                                    @change="selectedRestrictedFeature.name = $event.value" 
                                />
                            </v-col>
                            <v-col cols="12" class="py-0">
                                <DynamicFormControl
                                    :control="{
                                        name: 'isAdminManaged',
                                        value: selectedRestrictedFeature.isAdminManaged,
                                        type: 'Checkbox',
                                        config: {
                                            label: 'Is Admin Managed',
                                            rules: '',
                                            short: true
                                        }
                                    }"
                                    @change="selectedRestrictedFeature.isAdminManaged = $event.value" 
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" class="text-right">
                                <v-btn color="secondary" type="button" large text class="mr-2" @click="closeRestrictedFeatureModal">Cancel</v-btn>
                                <v-btn color="secondary" type="submit" large>Submit</v-btn>
                            </v-col>
                        </v-row>
                    </form>
                </ValidationObserver>
            </template>
        </Dialog>

    </v-container>
  </div>
</template>

<script>
import DynamicFormControl from '@/components/form/DynamicFormControl.vue'
import { IdentityService } from '@/api/IdentityService';
import { RestrictedFeatureService } from '@/api/RestrictedFeatureService';
import Dialog from '@/components/shared/Dialog.vue'

export default {
  name: 'RestrictedFeatures',
  components: {
    DynamicFormControl,
    Dialog
  },
  data() {
    return {
      selectedUserEmail: null,
      selectedUserLoading: false,
      selectedUserInfo: null,
      selectedUser: null,
      restrictedFeatures: [],

      selectedRestrictedFeature: null,
      showRestrictedFeatureModal: false
    }
  },
  computed: {
    restrictedFeatureModalTitle() {
      let action = 'Add';
      if (this.selectedRestrictedFeature && this.selectedRestrictedFeature.id) action = 'Edit';
      return action + ' Restricted Feature';
    },
    showRestrictedFeatureAdmin() {
      return this.$store.getters.userHasRestrictedFeature('RestrictedFeatures_ManageUsers');
    },
    isAdmin() {
      return this.$store.getters.getUserRole == 'admin';
    },
  },
  methods: { 
    showRestrictedFeature(f) {
      if (!f.isAdminManaged) return true;
      return this.isAdmin;
    },
    userHasSelectedFeature(id) {
      if (!this.selectedUser) return false;
      else return this.selectedUser.restrictedFeatureIds.indexOf(id) > -1
    },
    updateSelectedFeatureUser(event) {
      if (!this.selectedUser) return;
      //event.name is the RestrictedFeature id
      if (event.value) {
        if (this.selectedUser.restrictedFeatureIds.indexOf(event.name) == -1) {
          this.selectedUser.restrictedFeatureIds.push(event.name);
        }
      } else {
        this.selectedUser.restrictedFeatureIds = this.selectedUser.restrictedFeatureIds.filter(i => i != event.name)
      }
    },
    openRestrictedFeatureModal(f) {
      if (f) this.selectedRestrictedFeature = {...f};
      else this.selectedRestrictedFeature = {
        id: null,
        key: null,
        name: null,
        isAdminManaged: false
      };

      this.showRestrictedFeatureModal = true;
    },
    closeRestrictedFeatureModal() {
      this.selectedRestrictedFeature = null;
      this.showRestrictedFeatureModal = false;
    },
    validateRestrictedFeature() {
      this.$refs.restrictedFeatureObserver.validate()
      .then(valid => {
        if (valid) {
          if (this.selectedRestrictedFeature.id) {
            RestrictedFeatureService.put({
              body: this.selectedRestrictedFeature
            }).then(value => {
              this.fetchRestrictedFeatures();
              this.closeRestrictedFeatureModal();
            })
          } else {
            RestrictedFeatureService.post({
              body: this.selectedRestrictedFeature
            }).then(value => {
              this.fetchRestrictedFeatures();
              this.closeRestrictedFeatureModal();
            })
          }
        }
      });
    },
    validateUser() {
      this.$refs.userObserver.validate()
      .then(valid => {
          if (valid) {
            this.selectedUserLoading = true;
            IdentityService.isActive({
              email: this.selectedUserEmail
            }).then((value) => {
               console.log(value);

              if (value && !value.Role) {
                this.$refs.userObserver.refs['UserEmail'].setErrors(['User not found.']);
                this.selectedUserLoading = false;
              } else if (value && value.Role == 'admin') {
                this.$refs.userObserver.refs['UserEmail'].setErrors(['Admin users have access to all Restricted Features by default.']);
                this.selectedUserLoading = false;
              } else if (value && value.Role == 'agent') {
                this.$refs.userObserver.refs['UserEmail'].setErrors(['Restricted Features are not available for this user.']);
                this.selectedUserLoading = false;
              } else {
                this.selectedUserInfo = value;
                this.fetchRestrictedFeatureUser();
              }
            })
          }
      });
    },
    validateUserRestrictedFeatures() {
      this.$refs.userRestrictedFeaturesObserver.validate()
      .then(valid => {
        if (valid) {
          RestrictedFeatureService.updateUser({
            body: this.selectedUser
          }).then(value => {
            this.$store.dispatch('toastSuccess', { Message: 'Restricted Features User Updated' })
          })
        }
      })
    },
    fetchRestrictedFeatureUser() {
      this.selectedUserLoading = true;
      RestrictedFeatureService.getUser({
        email: this.selectedUserEmail
      }, {
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': '0',
      }).then((value) => {
        this.selectedUser = value;
        this.selectedUserLoading = false;
      })
    },
    fetchRestrictedFeatures() {
      this.restrictedFeatures = [];

      //update/getlist happening too fast for cosmos to keep up?
      //setTimeout(() => {
        RestrictedFeatureService.getList({
            'Cache-Control': 'no-cache',
            'Pragma': 'no-cache',
            'Expires': '0',
        })
        .then(value => {
          if (value && value.length) {
            this.restrictedFeatures = value;
          }
        })
      //}, 500)

    }
  },
  mounted() {
    this.fetchRestrictedFeatures();
  }
}
</script>
