import {
  Any,
  AccountBase,
  IList,
  List,
  IListResult,
  ListResultDto,
  IPagedResult,
  PagedResultDto,
  Dictionary,
  IDictionary,
  IRequestOptions,
  IRequestConfig,
  getConfigs,
  axios,
  basePath
} from './index.defs';

export class SubmissionService {
  /** Generate by swagger-axios-codegen */
  // @ts-nocheck
  /* eslint-disable */

  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Submission';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static selectQuote(
    params: {
      /**  */
      submissionId?: string;
      /**  */
      quoteId?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Submission/SelectQuote';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);
      configs.params = { submissionId: params['submissionId'], quoteId: params['quoteId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static reject(
    params: {
      /**  */
      submissionId: string;
      /**  */
      body?: Any;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Submission/{submissionId}/Reject';
      url = url.replace('{submissionId}', params['submissionId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAccountBase(
    params: {
      /**  */
      submissionNumber?: string;
      /**  */
      submissionId?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AccountBase> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Submission/AccountBase';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { submissionNumber: params['submissionNumber'], submissionId: params['submissionId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}
