<template>
  <div>
    <QuoteForm :formInit="form" v-if="form && !submissionIsExpired(submission)" :disabled="disabled || (submission && submission.Complete)" :submission="submission" @getSubmission="getSubmission" />
    <SubmissionExpirationDialog v-if="submission" :submission="submission" :form="form" />
  </div>
</template>

<script>
import QuoteForm from '@/components/views/Quote/QuoteForm.vue'
import { FormStateService } from '@/api/FormStateService'
import { SubmissionService } from '@/api/SubmissionService'
import { Amwins_Portal_Api_Services_LockService_Domain, PortalFormTemplateType } from '@/api/index.defs'
import SubmissionExpirationDialog from '@/components/views/Quote/SubmissionExpirationDialog.vue'
import { submissionIsExpired } from '@/scripts/helper'
import hub from '@/plugins/webapp-hub.js'

export default {
  name: 'Quote',
  components: {
    QuoteForm,
    SubmissionExpirationDialog
  },
  data() {
    return {
      form: null,
      submissionId: null,
      formStateId: null,
      lock: null,
      submission: null
    }
  },
  computed: {
    disabled() {
      return this.lock == null && this.formStateId != null;
    },
  },
  methods: {
    submissionIsExpired,
    checkLock() {
      const lock = this.$store.getters.getActiveLock;
      this.lock = (lock && lock.domain == Amwins_Portal_Api_Services_LockService_Domain.FormState && lock.key == this.formStateId) ? lock : null;
    },
    redirectToAdditionalForm() {
        //this.$router.push({ path: '/quote/additional', query: { id: this.submissionId } })
    },
    async getSubmission(submissionId) {
      this.submission = await SubmissionService.get({id: submissionId});
    }
  },
  created() {
    window.onbeforeunload = function() {
      this.$store.dispatch('deleteActiveLock')
    }
  },
  async mounted() {
    this.$store.commit('quoteForm/clearQuoteForm');
    this.$store.commit('additionalForm/clearAdditionalForm');
    this.submissionId = this.$route.query.id;

    this.$store.watch(() => {
      this.checkLock();
    }, () => {},
    {
        immediate: true
    });

    if (this.submissionId) {

      await this.getSubmission(this.submissionId)

      hub.attach("account", `${this.submission.OrgId}/${this.submission.OrgBranchId}/${this.submission.id}`);


      if (this.submission.Complete == true) {
        this.$router.push({ path: '/account', query: { id: this.submission.id } })
        return;
      }
      // else if (['Flood', 'HO'].indexOf(this.submission.CurrentForm) == -1) {
      //   this.redirectToAdditionalForm();
      // }

      const initialForm = this.submission.InitialForm || 'HO';
      this.formStateId = this.submission.FormState[initialForm];

      if (!this.lock) {
        this.$store.dispatch('requestLock', {
          domain: Amwins_Portal_Api_Services_LockService_Domain.FormState,
          key: this.formStateId
        })
      }

      FormStateService.get({
        resultId: this.submissionId,
        formStateId: this.formStateId
      }).then((data) => {
        this.form = data;
      })

    } else {
      this.$router.push({ path: '/dashboard' })
    }
  },
  beforeRouteLeave(to, from, next) {
      if (to.path != '/quote/additional') {
        this.$store.dispatch('deleteActiveLock').then(() => {
          next();
        })
      } else {
        next();
      }
  }
}
</script>
