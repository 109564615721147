import {
  Any,
  IList,
  List,
  IListResult,
  ListResultDto,
  IPagedResult,
  PagedResultDto,
  Dictionary,
  IDictionary,
  IRequestOptions,
  IRequestConfig,
  getConfigs,
  axios,
  basePath
} from './index.defs';

export class EulerService {
  /** Generate by swagger-axios-codegen */
  // @ts-nocheck
  /* eslint-disable */

  /**
   *
   */
  static messageIndex(
    params: {
      /**  */
      submissionId?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Any[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/invoke/Euler/Message/Index';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { submissionId: params['submissionId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static messageGet(
    params: {
      /**  */
      submissionId?: string;
      /**  */
      typeName?: string;
      /**  */
      messageId?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/invoke/Euler/Message';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        submissionId: params['submissionId'],
        typeName: params['typeName'],
        messageId: params['messageId']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}
