<template>
  <v-dialog
      v-model="open"
      persistent
      max-width="600px"
    >
      <v-card class="pa-4">

        <v-card-title>
          <h3>Select an Agent</h3>
          <v-spacer></v-spacer>

        </v-card-title>

        <v-card-text>
          <template v-if="inactiveProducer">
            <p style="color:#333333;"><strong>{{inactiveProducer}}</strong> is no longer active. Please select a new Agent for this Submission.</p>
          </template>
          <template v-else>
            <p style="color:#333333;">This Submission is missing an Agent.</p>
          </template>
          
          <ValidationObserver tag="span" v-slot="{}" ref="agentObserver">
            <form @submit.prevent="validate()" v-if="open">

              <v-row v-if="inactiveProducerOrgBranchId">
                <v-col cols="12" class="py-0">
                  <DynamicFormControl 
                      :control="{
                          type: 'Dropdown',
                          name: 'Agent',
                          value: Agent,
                          config: {
                              label:'Retail Agent',
                              rules: 'required',
                              col: 12,
                              returnObject: true,
                              itemText: 'SeekDisplay',
                              itemValue: 'id',
                              options
                          }
                      }"
                      @change="Agent = $event.value" 
                  />
                </v-col>
              </v-row>

              <v-row v-else>
                <v-col cols="12" class="py-0">
                  <DynamicFormControl 
                      :control="{
                          type: 'AgentAutocomplete',
                          name: 'Agent',
                          config: {
                              label:'Retail Agent',
                              rules: 'required',
                              col: 12,
                          }
                      }"
                      @change="Agent = $event.value" 
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" class="text-right">
                  <v-btn color="secondary" type="button" large text class="mr-2" @click="cancel">Cancel</v-btn>
                  <v-btn color="secondary" type="submit" large>Submit</v-btn>
                </v-col>
              </v-row>
              
            </form>
          </ValidationObserver>
        </v-card-text>

        
      </v-card>
    </v-dialog>

</template>

<script>
import DynamicFormControl from '@/components/form/DynamicFormControl.vue'
import { AgentService } from '@/api/AgentService'

export default {
  name: "AgentDialog",
  components: {
    DynamicFormControl
  },
  data() {
    return {
      open: false,
      options: [],
      Agent: null
    }
  },
  props: {
    inactiveProducer: {
      type: String,
      default: null
    },
    inactiveProducerOrgBranchId: {
      type: Number,
      default: null
    }
  },
  emits: [
    'agent'
  ],
  methods: {
    validate() {
      this.$refs.agentObserver.validate()
      .then((valid) => {
        if (valid && this.Agent) {
          this.$emit("agent", this.Agent);
        }
      });
    },
    cancel() {
      this.$router.push({ path: '/dashboard'});
    }
  },
  async mounted() {
    this.open = true;

    if (this.inactiveProducerOrgBranchId) {
      this.options = await AgentService.find({
        agencyId: this.inactiveProducerOrgBranchId,
      });

      if (this.$store.getters.userHasRole('agent')) {
        const human_id = this.$store.getters.getUserHumanId;
        const a = this.options.find(o => o.id == human_id);
        if (a) this.Agent = {...a};
      }

    }
  }
}
</script>

<style scoped>
  h3 {
    font-weight: bold;
  }
</style>