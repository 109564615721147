import { Module } from 'vuex';
import { AccountState, RootState } from './types';
import Vue from 'vue';

export const state: AccountState = {
  accounts: [],
  endorsement: null
}

const mutations = {
  clearAccounts(state: any) {
    state.accounts = [];
  },
  clearEndorsement(state: any) {
    Vue.set(state, 'endorsement', null);
    state.endorsement = null;
  },
  addAccount(state: any, account: any) {
    console.error("Call to depricated vuex.AccountState.addAccount()"); //find and fix me
    state.accounts.push(account);    
  },
  updateAccount(state: any, account: any) {
    state.accounts = state.accounts.filter((x: any) => x.id != account.id)
    state.accounts.push(account);
  },
  updateEndorsement(state: any, endorsementRequest: any) {
    state.endorsement = endorsementRequest?.Endorsement;
  },
}

const getters = {
  getAccount: (state: any) => (id: any) => {
    return state.accounts.find((x: any) => x.id == id)
  },
  getAccounts: (state: any) => {
    return state.accounts;
  },
  getEndorsement: (state: any) => {
    return state.endorsement;
  }
}

export const actions = {
}

const namespaced: boolean = true;

export const account: Module<AccountState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
}