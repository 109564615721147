import {
  QuoteV1,
  Amwins_Orbits_Contracts_Models_v1_Quotes_QuoteV1,
  Amwins_Orbits_Contracts_MessageContracts_Quotes_QuoteHeaderV1,
  Amwins_Orbits_Contracts_Models_SalesChannels_SalesChannel,
  Amwins_Orbits_Contracts_Models_ProductType,
  Amwins_Orbits_Contracts_Models_v1_Quotes_PricingV1,
  Amwins_Orbits_Contracts_Models_v1_Taxes_Assessment,
  Amwins_Orbits_Contracts_Models_v1_Taxes_AssessmentClassificationV1,
  Amwins_Orbits_Contracts_Models_v1_InsuredInformation_InsuredV1,
  Amwins_Orbits_Contracts_Models_v1_InsuredInformation_MailingAddressV1,
  Amwins_Orbits_Contracts_Models_v1_InsuredInformation_PersonV1,
  Amwins_Orbits_Contracts_Models_v1_InsuredInformation_ConsumerCreditDisclosureV1,
  Amwins_Orbits_Contracts_Models_Payments_InsuredEmailOptions,
  Amwins_Orbits_Contracts_Models_v1_InsuredInformation_ClaimV1,
  Amwins_Orbits_Contracts_Models_v1_InsuredInformation_ClaimType,
  Amwins_Orbits_Contracts_Models_v1_InsuredInformation_ClaimAmountV1,
  Amwins_Orbits_Contracts_Models_v1_InsuredInformation_ClaimRepairedV1,
  Amwins_Orbits_Contracts_Models_v1_InsuredInformation_ElevatedAfterPriorFloodLossV1,
  Amwins_Orbits_Contracts_Models_v1_InsuredInformation_ClaimSettledV1,
  Amwins_Orbits_Contracts_Models_v1_InsuredInformation_ClaimSettledDateV1,
  Amwins_Orbits_Contracts_Models_v1_RiskInformation_RiskV1,
  Amwins_Orbits_Contracts_Models_v1_RiskInformation_LocationV1,
  Amwins_Orbits_Contracts_Models_v1_RiskInformation_BuildingV1,
  Amwins_Orbits_Contracts_Models_v1_RiskInformation_BuildingType,
  Amwins_Orbits_Contracts_Models_v1_AddressV1,
  Amwins_Orbits_Contracts_Models_v1_RiskInformation_OccupancyType,
  Amwins_Orbits_Contracts_Models_v1_RiskInformation_MonthsOccupiedV1,
  Amwins_Orbits_Contracts_Models_v1_RiskInformation_ConstructionType,
  Amwins_Orbits_Contracts_Models_v1_RiskInformation_FoundationType,
  Amwins_Orbits_Contracts_Models_v1_RiskInformation_BasementType,
  Amwins_Orbits_Contracts_Models_v1_RiskInformation_ElevatedEnclosureType,
  Amwins_Orbits_Contracts_Models_v1_RiskInformation_NumberOfStoriesV1,
  Amwins_Orbits_Contracts_Models_v1_RiskInformation_SquareFootageV1,
  Amwins_Orbits_Contracts_Models_v1_RiskInformation_YearBuiltV1,
  Amwins_Orbits_Contracts_Models_v1_RiskInformation_AttachedGarageType,
  Amwins_Orbits_Contracts_Models_v1_RiskInformation_BuildingOverWaterType,
  Amwins_Orbits_Contracts_Models_v1_RiskInformation_FinishedFirstFloorElevationV1,
  Amwins_Orbits_Contracts_Models_v1_RiskInformation_FloodZone,
  Amwins_Orbits_Contracts_Models_v1_RiskInformation_ElevationHeightV1,
  Amwins_Orbits_Contracts_Models_v1_RiskInformation_FEMABaseFloodElevationV1,
  Amwins_Orbits_Contracts_Models_v1_RiskInformation_DistanceToNearestWaterV1,
  Amwins_Orbits_Contracts_Models_v1_RiskInformation_DistanceToCoastV1,
  Amwins_Orbits_Contracts_Models_v1_RiskInformation_DistanceToFloodSourceAvailableV1,
  Amwins_Orbits_Contracts_Models_v1_RiskInformation_ElevationCertificateAvailableV1,
  Amwins_Orbits_Contracts_Models_v1_RiskInformation_HighestFinishedGradeNextToBuildingV1,
  Amwins_Orbits_Contracts_Models_v1_RiskInformation_LowestOccupiedFloor,
  Amwins_Orbits_Contracts_Models_v1_RiskInformation_BuildingValuation,
  Amwins_Orbits_Contracts_Models_v1_RiskInformation_ValuationType,
  Amwins_Orbits_Contracts_Models_v1_RiskInformation_ContentsValuation,
  Amwins_Orbits_Contracts_Models_v1_RiskInformation_CrawlspaceCompletionStatus,
  Amwins_Orbits_Contracts_Models_v1_RiskInformation_NumberOfStepsV1,
  Amwins_Orbits_Contracts_Models_v1_RiskInformation_ValuationRCV,
  Amwins_Orbits_Contracts_Models_v1_RiskInformation_ValuationACV,
  Amwins_Orbits_Contracts_Models_v1_RiskInformation_DistanceAttributeV1,
  Amwins_Orbits_Contracts_Models_v1_CoverageInformation_CoveragesV1,
  Amwins_Orbits_Contracts_Models_FormType,
  Amwins_Orbits_Contracts_Models_v1_CoverageInformation_CoverageAV1,
  Amwins_Orbits_Contracts_Models_v1_CoverageInformation_LiabilityV1,
  Amwins_Orbits_Contracts_Models_v1_CoverageInformation_LimitV1,
  Amwins_Orbits_Contracts_Models_v1_CoverageInformation_DeductibleV1,
  Amwins_Orbits_Contracts_Models_v1_CoverageInformation_DeductibleMode,
  Amwins_Orbits_Contracts_Models_v1_CoverageInformation_MinimumDeductibleV1,
  Amwins_Orbits_Contracts_Models_Percentage,
  Amwins_Orbits_Contracts_Models_v1_CoverageInformation_AOPV1,
  Amwins_Orbits_Contracts_Models_v1_CoverageInformation_WaterPerilV1,
  Amwins_Orbits_Contracts_Models_v1_CoverageInformation_WindPerilV1,
  Amwins_Orbits_Contracts_Models_v1_CoverageInformation_WindDeductibleType,
  Amwins_Orbits_Contracts_Models_v1_CoverageInformation_FloodPerilV1,
  Amwins_Orbits_Contracts_Models_v1_CoverageInformation_StormSurgePerilV1,
  Amwins_Orbits_Contracts_Models_v1_CoverageInformation_EarthquakePerilV1,
  Amwins_Orbits_Contracts_Models_v1_CoverageInformation_OrdinanceAndLawV1,
  Amwins_Orbits_Contracts_Models_v1_CoverageInformation_CoverageBV1,
  Amwins_Orbits_Contracts_Models_v1_CoverageInformation_CoverageCV1,
  Amwins_Orbits_Contracts_Models_v1_CoverageInformation_CoverageDV1,
  Amwins_Orbits_Contracts_Models_v1_CoverageInformation_CoverageEV1,
  Amwins_Orbits_Contracts_Models_v1_CoverageInformation_CoverageFV1,
  Amwins_Orbits_Contracts_Models_Quotes_PricingStatus,
  Amwins_Orbits_Contracts_Models_Quotes_QuoteEligibilityStatus,
  Amwins_Orbits_Contracts_Models_Quotes_PlacementStatus,
  Amwins_Orbits_Contracts_Models_v1_Quotes_QuoteDocumentsV1,
  Amwins_Orbits_Contracts_Models_Files_FileReference,
  QuoteError,
  SalesChannel,
  Quote,
  QuoteStatus,
  DeclineType,
  PriceStatus,
  QuoteValue,
  Amwins_Orbits_Contracts_Models_v2_CoverageModelType,
  Amwins_Orbits_Contracts_Models_v2_Quotes_QuoteBindRequirementsV2,
  Amwins_Orbits_Contracts_Models_Inspections_InspectionType,
  v2QuoteV2,
  Amwins_Orbits_Contracts_Models_v2_Quotes_QuoteV2_System_Nullable_System_Text_Json_JsonElement__System_Nullable_System_Text_Json_JsonElement,
  Amwins_Orbits_Contracts_Models_v2_PricingV2,
  Amwins_Orbits_Contracts_Models_v2_FeeV2,
  Amwins_Orbits_Contracts_Models_v2_FeeClassificationV2,
  Amwins_Orbits_Contracts_Models_Inspections_InspectionProvider,
  Amwins_Orbits_Contracts_Models_v2_ServiceFee,
  Amwins_Orbits_Contracts_Models_v2_InsuredInformation_InsuredV2,
  Amwins_Orbits_Contracts_Models_v2_RiskModelType,
  System_Text_Json_JsonElement,
  System_Text_Json_JsonValueKind,
  Amwins_Orbits_Contracts_Models_v2_Quotes_QuoteDocumentsV2,
  Amwins_Orbits_Contracts_MessageContracts_v2_Predecessor,
  Amwins_Portal_Api_Controllers_QuotesController_RefineV2Message,
  Amwins_Orbits_Contracts_Models_Producers_Broker,
  Amwins_Orbits_Contracts_Models_Producers_Retailer,
  Amwins_Orbits_Contracts_Models_User,
  Amwins_Orbits_Contracts_Models_UserRole,
  IList,
  List,
  IListResult,
  ListResultDto,
  IPagedResult,
  PagedResultDto,
  Dictionary,
  IDictionary,
  IRequestOptions,
  IRequestConfig,
  getConfigs,
  axios,
  basePath
} from './index.defs';

export class QuotesService {
  /** Generate by swagger-axios-codegen */
  // @ts-nocheck
  /* eslint-disable */

  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<QuoteV1> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Quotes';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static submitTfiaRequest(
    params: {
      /**  */
      id?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Quotes/SubmitTfiaRequest';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static floodHumanSubmission(
    params: {
      /**  */
      id?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Quotes/FloodHumanSubmission';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static refine(
    params: {
      /**  */
      body?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Quotes/Refine';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static errors(
    params: {
      /**  */
      limit?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<QuoteError[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Quotes/Errors';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { limit: params['limit'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getV2(
    params: {
      /**  */
      id?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<v2QuoteV2> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Quotes/QuoteV2';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static refineV2(
    params: {
      /**  */
      body?: Amwins_Portal_Api_Controllers_QuotesController_RefineV2Message;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Quotes/RefineV2';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static prefillDiligentEffort(
    params: {
      /**  */
      body?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Quotes/PrefillDiligentEffort';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}
