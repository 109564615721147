<template>
  <div>
    <v-container>
      <h1>Unauthorized</h1>
      You are not authorized to view this resource.
    </v-container>
  </div>
</template>

<script>
export default {
    name: 'Unauthorized',
  data() {
    return {}
  }
}
</script>
