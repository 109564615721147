<template>
  <div>
    <v-container>
      <h1>
        Agency Non-Prod Access
      </h1>

      <v-card class="mb-4">
        <v-card-title>
          Agency
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" class="py-0 mb-3">
              <DynamicFormControl :control="{
                                        value: selectedAgency,
                                        name: 'Agency',
                                        type: 'AgencyAutocomplete',
                                        config: {
                                            label: 'Agency',
                                            col: 12,
                                            short: true,
                                            clearable: true,
                                            ignoreCache: true
                                        }
                                    }"
                                    @change="selectedAgencyChanged($event.value)" />
            </v-col>
          </v-row>
          <v-row v-if="selectedAgency">
            <v-col cols="12" class="py-0 mb-3">
              <DynamicFormControl :control="{
                                    type: 'Checkbox',
                                    name: 'NonProdAccess',
                                    value: hasNonProdAccess,
                                    config: {
                                      label: 'Allow Access to Non-Prod Environments?',
                                      short: true
                                    }
                                  }"
                                  @change="hasNonProdAccessChanged($event.value)" />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

    </v-container>
  </div>
</template>

<script>
  import DynamicFormControl from '@/components/form/DynamicFormControl.vue'
  import { AgencyService } from '@/api/AgencyService'

  export default {
    name: 'AgencyNonprodAccess',
    components: {
      DynamicFormControl
    },
    data() {
      return {
        selectedAgency: null,
        hasNonProdAccess: null,
        lastAgencyId: null
      }
    },
    methods: {
      selectedAgencyChanged(event) {
        this.hasNonProdAccess = event?.HasNonProdAccess
        this.selectedAgency = event
        if (this.lastAgencyId == null) this.lastAgencyId = event?.id
      },
      hasNonProdAccessChanged(event) {
        this.hasNonProdAccess = event
        if (this.lastAgencyId != null && this.lastAgencyId === this.selectedAgency.id && this.hasNonProdAccess != this.selectedAgency.HasNonProdAccess) {
          AgencyService.nonProdAccess({ id: this.selectedAgency.id, hasNonProdAccess: this.hasNonProdAccess })
            .then((result) => {
              if (result) {
                const message = result.HasNonProdAccess ? `Non-Prod access granted to agency` : `Non-Prod access revoked from agency`
                this.$store.dispatch('toastSuccess', { Message: message })
                this.selectedAgency = result
              } else {
                this.$store.dispatch('toastError', { Message: 'An Error has Occured' })
              }
            })
            .catch(() => {
              this.$store.dispatch('toastError', { Message: 'An Error has Occured' })
            })
        }
        this.lastAgencyId = this.selectedAgency.id
      }
    }
  }
</script>
