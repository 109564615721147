/** Generate by swagger-axios-codegen */
/* eslint-disable */
// @ts-nocheck
import axiosStatic, { AxiosInstance, AxiosRequestConfig } from 'axios';


// MANUALLY ADDED MODELS
// TODO: HANDLE THIS IN A SEPARATE FILE, MOST LIKELY CONTRACTS IMPORT
// For now, add these models back after running swaggergen
export interface Amwins_Orbits_Contracts_Models_Submissions_LossV0 {
  /**  */
  Date?: Date;

  /**  */
  Type?: string;

  /**  */
  PaymentAmount?: number;

  /**  */
  IsClaimAtThisLocation?: string;

  /**  */
  IsClaimClosed?: string;

  /**  */
  IsDamageRepaired?: string;

  /**  */
  LossDetails?: string;

  /**  */
  MitigateFutureLosses?: string;
}

export interface Amwins_Orbits_Contracts_Models_Submissions_SubmissionCoveragesV0 {
  /**  */
  FormType?: Amwins_Orbits_Contracts_Models_FormType;

  /**  */
  CovA?: Amwins_Orbits_Contracts_Models_Submissions_SubmissionValueWithDataServiceV0_System_Nullable_System_Decimal;

  /**  */
  CovB?: Amwins_Orbits_Contracts_Models_Submissions_SubmissionValueV0_System_Nullable_System_Decimal;

  /**  */
  CovC?: Amwins_Orbits_Contracts_Models_Submissions_SubmissionValueV0_System_Nullable_System_Decimal;

  /**  */
  CovD?: Amwins_Orbits_Contracts_Models_Submissions_SubmissionValueV0_System_Nullable_System_Decimal;

  /**  */
  LossAssessmentLimit?: Amwins_Orbits_Contracts_Models_Submissions_SubmissionValueV0_System_Nullable_System_Decimal;

  /**  */
  CovE?: Amwins_Orbits_Contracts_Models_Submissions_SubmissionValueV0_System_Nullable_System_Decimal;

  /**  */
  CovF?: Amwins_Orbits_Contracts_Models_Submissions_SubmissionValueV0_System_Nullable_System_Decimal;

  /**  */
  CovLdp3?: Amwins_Orbits_Contracts_Models_Submissions_SubmissionValueV0_System_Nullable_System_Decimal;

  /**  */
  CovMdp3?: Amwins_Orbits_Contracts_Models_Submissions_SubmissionValueV0_System_Nullable_System_Decimal;

  /**  */
  AllPerilDeductible?: Amwins_Orbits_Contracts_Models_v0_CoverageInformation_FlatOnlyDeductibleV0;

  /**  */
  WindPeril?: Amwins_Orbits_Contracts_Models_Submissions_SubmissionValueV0_System_Nullable_System_Boolean;

  /**  */
  WindPerilDeductible?: Amwins_Orbits_Contracts_Models_Submissions_SubmissionValueV0_Amwins_Orbits_Contracts_Models_v0_CoverageInformation_DeductibleV0;

  /**  */
  WindPerilApply?: Amwins_Orbits_Contracts_Models_Submissions_SubmissionValueV0_System_String;

  /**  */
  EarthquakePeril?: Amwins_Orbits_Contracts_Models_Submissions_SubmissionValueV0_System_Nullable_System_Boolean;

  /**  */
  EarthquakePerilDeductible?: Amwins_Orbits_Contracts_Models_Submissions_SubmissionValueV0_Amwins_Orbits_Contracts_Models_v0_CoverageInformation_DeductibleV0;

  /**  */
  EarthquakeTuckUnderParking?: Amwins_Orbits_Contracts_Models_Submissions_SubmissionValueV0_System_Nullable_System_Boolean;

  /**  */
  EarthquakeYearRetrofittedToCode?: Amwins_Orbits_Contracts_Models_Submissions_SubmissionValueV0_System_String;

  /**  */
  EarthquakeMasonryVeneer?: Amwins_Orbits_Contracts_Models_Submissions_SubmissionValueV0_System_Nullable_System_Boolean;

  /**  */
  DifferenceInConditions?: Amwins_Orbits_Contracts_Models_Submissions_SubmissionValueV0_System_Nullable_System_Boolean;

  /**  */
  FloodPeril?: Amwins_Orbits_Contracts_Models_Submissions_SubmissionValueV0_System_Nullable_System_Boolean;

  /**  */
  FloodPerilDeductible?: Amwins_Orbits_Contracts_Models_Submissions_SubmissionValueV0_Amwins_Orbits_Contracts_Models_v0_CoverageInformation_DeductibleV0;

  /**  */
  RecommendedCoverageOption?: Amwins_Orbits_Contracts_Models_Submissions_SubmissionValueV0_System_String;

  /**  */
  WaterDistance?: Amwins_Orbits_Contracts_Models_Submissions_SubmissionValueWithDataServiceV0_System_Nullable_System_Decimal;

  /**  */
  WindPoolEligible?: Amwins_Orbits_Contracts_Models_Submissions_SubmissionValueV0_System_Nullable_System_Boolean;

  /**  */
  FloodZone?: Amwins_Orbits_Contracts_Models_Submissions_SubmissionValueV0_System_String;

  /**  */
  LavaZone?: Amwins_Orbits_Contracts_Models_Submissions_SubmissionValueV0_System_String;

  /**  */
  RiskGeolocation?: Amwins_Orbits_Contracts_Models_Submissions_SubmissionValueWithDataServiceV0_Amwins_Orbits_Contracts_Models_Submissions_SubmissionRiskGeolocationV0;

  /**  */
  PersonalInjury?: Amwins_Orbits_Contracts_Models_Submissions_SubmissionValueV0_System_String;

  /**  */
  ReplacementCostDwelling?: Amwins_Orbits_Contracts_Models_Submissions_SubmissionValueV0_System_Nullable_System_Boolean;

  /**  */
  ReplacementCostStructures?: Amwins_Orbits_Contracts_Models_Submissions_SubmissionValueV0_System_Nullable_System_Int32;

  /**  */
  ReplacementCostContents?: Amwins_Orbits_Contracts_Models_Submissions_SubmissionValueV0_System_Nullable_System_Boolean;

  /**  */
  InflationGuard?: Amwins_Orbits_Contracts_Models_Submissions_SubmissionValueV0_System_Nullable_System_Decimal;

  /**  */
  BuildingOrdinanceOrLaw?: Amwins_Orbits_Contracts_Models_Submissions_SubmissionValueV0_System_Nullable_System_Decimal;

  /**  */
  CoveragePersonalArticle?: Amwins_Orbits_Contracts_Models_Submissions_SubmissionValueV0_System_Nullable_System_Decimal;

  /**  */
  WindDrivenRain?: Amwins_Orbits_Contracts_Models_Submissions_SubmissionValueV0_System_String;

  /**  */
  Theft?: Amwins_Orbits_Contracts_Models_Submissions_SubmissionValueV0_System_Nullable_System_Decimal;

  /**  */
  Mold?: Amwins_Orbits_Contracts_Models_Submissions_SubmissionValueV0_System_Nullable_System_Decimal;

  /**  */
  DomesticAnimalLiability?: Amwins_Orbits_Contracts_Models_Submissions_SubmissionValueV0_System_Nullable_System_Decimal;

  /**  */
  WaterBackup?: Amwins_Orbits_Contracts_Models_Submissions_SubmissionValueV0_System_Nullable_System_Decimal;

  /**  */
  WaterDamageSublimit?: Amwins_Orbits_Contracts_Models_Submissions_SubmissionValueV0_System_Nullable_System_Decimal;

  /**  */
  WaterDamagePeril?: Amwins_Orbits_Contracts_Models_v0_CoverageInformation_FlatOnlyDeductibleV0;

  /**  */
  ConsumerCreditAttestation?: Amwins_Orbits_Contracts_Models_Submissions_SubmissionValueV0_Amwins_Orbits_Contracts_Models_Submissions_AttestationV0;

  /**  */
  CovAAttestation?: Amwins_Orbits_Contracts_Models_Submissions_SubmissionValueV0_Amwins_Orbits_Contracts_Models_Submissions_AttestationV0;

  /**  */
  WindDecAttestation?: Amwins_Orbits_Contracts_Models_Submissions_SubmissionValueV0_Amwins_Orbits_Contracts_Models_Submissions_AttestationV0;

  /**  */
  HurricaneMEPAttestation?: Amwins_Orbits_Contracts_Models_Submissions_SubmissionValueV0_Amwins_Orbits_Contracts_Models_Submissions_AttestationV0;

  /**  */
  CoverageLapseDetail?: Amwins_Orbits_Contracts_Models_Submissions_SubmissionValueV0_System_String;

  /**  */
  RealEstateValuePerSqFt?: Amwins_Orbits_Contracts_Models_Submissions_SubmissionValueV0_System_Nullable_System_Decimal;

  /**  */
  RentalIncomeMonthly?: Amwins_Orbits_Contracts_Models_Submissions_SubmissionValueV0_System_Nullable_System_Decimal;

  /**  */
  HasFireExtinguisher?: Amwins_Orbits_Contracts_Models_Submissions_SubmissionValueWithDataServiceV0_System_Nullable_System_Boolean;

  /**  */
  HasDeadbolt?: Amwins_Orbits_Contracts_Models_Submissions_SubmissionValueWithDataServiceV0_System_Nullable_System_Boolean;

  /**  */
  AALHuricane_AIR?: Amwins_Orbits_Contracts_Models_Submissions_SubmissionValueWithDataServiceV0_Amwins_Orbits_Contracts_Models_Submissions_AverageAnnualLossV0;

  /**  */
  AALHuricane_RMS?: Amwins_Orbits_Contracts_Models_Submissions_SubmissionValueWithDataServiceV0_Amwins_Orbits_Contracts_Models_Submissions_AverageAnnualLossV0;

  /**  */
  AALEarthQuake_AIR?: Amwins_Orbits_Contracts_Models_Submissions_SubmissionValueWithDataServiceV0_Amwins_Orbits_Contracts_Models_Submissions_AverageAnnualLossV0;

  /**  */
  AALEarthQuake_RMS?: Amwins_Orbits_Contracts_Models_Submissions_SubmissionValueWithDataServiceV0_Amwins_Orbits_Contracts_Models_Submissions_AverageAnnualLossV0;

  /**  */
  AALTornadoHail_AIR?: Amwins_Orbits_Contracts_Models_Submissions_SubmissionValueWithDataServiceV0_Amwins_Orbits_Contracts_Models_Submissions_AverageAnnualLossV0;

  /**  */
  AALWildFire_AIR?: Amwins_Orbits_Contracts_Models_Submissions_SubmissionValueWithDataServiceV0_Amwins_Orbits_Contracts_Models_Submissions_AverageAnnualLossV0;

  /**  */
  WildFireScore?: Amwins_Orbits_Contracts_Models_Submissions_SubmissionValueWithDataServiceV0_System_Nullable_System_Decimal;

  /**  */
  VerikaiScore?: Amwins_Orbits_Contracts_Models_Submissions_SubmissionValueWithDataServiceV0_System_Nullable_System_Decimal;

  /**  */
  SeaSys_RatingTerritory?: Amwins_Orbits_Contracts_Models_Submissions_SubmissionValueV0_System_String;

  /**  */
  AdditionalTheft?: Amwins_Orbits_Contracts_Models_Submissions_SubmissionValueV0_System_String;

  /**  */
  BroadTheft?: Amwins_Orbits_Contracts_Models_Submissions_SubmissionValueV0_System_String;

  /**  */
  LimitedTheft?: Amwins_Orbits_Contracts_Models_Submissions_SubmissionValueV0_System_String;

  /**  */
  TheftExclusion?: Amwins_Orbits_Contracts_Models_Submissions_SubmissionValueV0_System_String;

  /**  */
  GolfCartPhysicalDamage?: Amwins_Orbits_Contracts_Models_Submissions_SubmissionValueV0_System_String;

  /**  */
  GreenUpgrades?: Amwins_Orbits_Contracts_Models_Submissions_SubmissionValueV0_System_String;

  /**  */
  HO6ExtensionCoverage?: Amwins_Orbits_Contracts_Models_Submissions_SubmissionValueV0_System_String;

  /**  */
  IdentityFraudExp?: Amwins_Orbits_Contracts_Models_Submissions_SubmissionValueV0_System_String;

  /**  */
  LossAssessmentEQ?: Amwins_Orbits_Contracts_Models_Submissions_SubmissionValueV0_System_Nullable_System_Decimal;

  /**  */
  RefrigeratedGoods?: Amwins_Orbits_Contracts_Models_Submissions_SubmissionValueV0_System_String;

  /**  */
  SpecialComputerCover?: Amwins_Orbits_Contracts_Models_Submissions_SubmissionValueV0_System_String;

  /**  */
  MechanicalBreakdownCover?: Amwins_Orbits_Contracts_Models_Submissions_SubmissionValueV0_System_String;

  /**  */
  RoofValuationType?: Amwins_Orbits_Contracts_Models_Submissions_SubmissionValueV0_System_String;
}

export interface Amwins_Orbits_Contracts_Models_v0_CoverageInformation_DeductibleBaseSource {
  /**  */
  Value?: string;

  /**  */
  Id?: string;
}

export interface Amwins_Orbits_Contracts_Models_v0_CoverageInformation_DeductibleV0 {
  /**  */
  Flat?: Amwins_Orbits_Contracts_Models_v0_CoverageInformation_FlatDeductibleV0;

  /**  */
  Percentage?: Amwins_Orbits_Contracts_Models_v0_CoverageInformation_PercentageDeductibleV0;
}

export interface Amwins_Orbits_Contracts_Models_v0_CoverageInformation_FlatDeductibleV0 {
  /**  */
  Amount?: number;
}

export interface Amwins_Orbits_Contracts_Models_v0_CoverageInformation_FlatOnlyDeductibleV0 {
  /**  */
  Flat?: Amwins_Orbits_Contracts_Models_v0_CoverageInformation_FlatDeductibleV0;
}

export interface Amwins_Orbits_Contracts_Models_v0_CoverageInformation_PercentageDeductibleV0 {
  /**  */
  DeductibleBaseSource?: Amwins_Orbits_Contracts_Models_v0_CoverageInformation_DeductibleBaseSource;

  /**  */
  MininumAmount?: number;

  /**  */
  Percent?: number;
}

export interface Amwins_Orbits_Contracts_Models_Submissions_SubmissionValueWithDataServiceV0_System_Nullable_System_Decimal {
  /**  */
  DataServiceValue?: number;

  /**  */
  DataService?: string;

  /**  */
  DataServiceDetail?: Amwins_Orbits_Contracts_Models_Submissions_SubmissionDataServiceValueV0_System_Nullable_System_Decimal[];

  /**  */
  value?: number;
}

// END OF MANUALLY ADDED MODELS
export interface IRequestOptions extends AxiosRequestConfig {}

export interface IRequestConfig {
  method?: any;
  headers?: any;
  url?: any;
  data?: any;
  params?: any;
}

// Add options interface
export interface ServiceOptions {
  axios?: AxiosInstance;
}

// Add default options
export const serviceOptions: ServiceOptions = {};

// Instance selector
export function axios(configs: IRequestConfig, resolve: (p: any) => void, reject: (p: any) => void): Promise<any> {
  if (serviceOptions.axios) {
    return serviceOptions.axios
      .request(configs)
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject(err);
      });
  } else {
    throw new Error('please inject yourself instance like axios  ');
  }
}

export function getConfigs(method: string, contentType: string, url: string, options: any): IRequestConfig {
  const configs: IRequestConfig = { ...options, method, url };
  configs.headers = {
    ...options.headers,
    'Content-Type': contentType
  };
  return configs;
}

export const basePath = '';

export interface IList<T> extends Array<T> {}
export interface List<T> extends Array<T> {}
export interface IDictionary<TValue> {
  [key: string]: TValue;
}
export interface Dictionary<TValue> extends IDictionary<TValue> {}

export interface IListResult<T> {
  items?: T[];
}

export class ListResultDto<T> implements IListResult<T> {
  items?: T[];
}

export interface IPagedResult<T> extends IListResult<T> {
  totalCount?: number;
  items?: T[];
}

export class PagedResultDto<T = any> implements IPagedResult<T> {
  totalCount?: number;
  items?: T[];
}

// customer definition
// empty

export interface Account {
  /**  */
  PolicyNumber?: string;

  /**  */
  PolicySeek?: string;

  /**  */
  SeekDisplay?: string;

  /**  */
  TermList?: PolicyTerm[];

  /**  */
  AccountStatus?: string;

  /**  */
  EndorsementFormStateId?: string;

  /**  */
  EndorsementStartDate?: Date;

  /**  */
  EndorsementId?: string;

  /**  */
  PriorId?: string;

  /**  */
  PolicyStateV0MessageQueueId?: number;

  /**  */
  PolicyStateV1Timestamp?: Date;

  /**  */
  AccountStatusTags?: string;

  /**  */
  PendingTags?: string;

  /**  */
  LastIndexValidation?: Date;

  /**  */
  Updated?: Date;

  /**  */
  Policies?: Quote[];

  /**  */
  TermStatus?: string;

  /**  */
  TPA?: string;

  /**  */
  PolicyDocuments?: EFileDocument[];

  /**  */
  AmlinkSubmissionFileDetailsUrl?: string;

  /**  */
  TransactionsHistory?: AccountViewTransaction[];

  /**  */
  Number?: string;

  /**  */
  Status?: PolicyStatus;

  /**  */
  PolicyTermStatus?: PolicyStatus;

  /**  */
  Type?: PolicyType;

  /**  */
  IsNewBusiness?: boolean;

  /**  */
  Product?: string;

  /**  */
  Risk?: Risk;

  /**  */
  SalesChannel?: string;

  /**  */
  SalesChannelModel?: Amwins_Orbits_Contracts_Models_SalesChannels_SalesChannel;

  /**  */
  Market?: Market;

  /**  */
  Insured?: Insured;

  /**  */
  AgentId?: number;

  /**  */
  Agent?: Agent;

  /**  */
  AgencyId?: number;

  /**  */
  Agency?: Agency;

  /**  */
  UnderwriterId?: number;

  /**  */
  Underwriter?: Underwriter;

  /**  */
  QuoteKey?: string;

  /**  */
  TermBegin?: Date;

  /**  */
  TermEnd?: Date;

  /**  */
  ProductType?: FormTemplateType;

  /**  */
  Balance?: number;

  /**  */
  SubmissionId?: string;

  /**  */
  SubmissionIdGuid?: string;

  /**  */
  SubmissionNumber?: string;

  /**  */
  HasOpenInspection?: boolean;

  /**  */
  guid?: string;

  /**  */
  HumanIdTags?: string;

  /**  */
  HumanIds?: number[];

  /**  */
  OrgBranchId?: number;

  /**  */
  OrgId?: number;

  /**  */
  key?: string;

  /**  */
  id?: string;
}

export interface AccountBase {
  /**  */
  guid?: string;

  /**  */
  HumanIdTags?: string;

  /**  */
  HumanIds?: number[];

  /**  */
  OrgBranchId?: number;

  /**  */
  OrgId?: number;

  /**  */
  key?: string;

  /**  */
  id?: string;
}

export interface AccountViewTransaction {
  /**  */
  PolicyTransactionDate?: Date;

  /**  */
  PolicyTransactionTypeGroupName?: string;

  /**  */
  PolicyTransactionAmountTotal?: number;
}

export interface Agency {
  /**  */
  key?: string;

  /**  */
  SeekDisplay?: string;

  /**  */
  HasNonProdAccess?: boolean;

  /**  */
  IsOrgPartition?: boolean;

  /**  */
  Active?: boolean;

  /**  */
  Name?: string;

  /**  */
  Salutation?: string;

  /**  */
  MiddleName?: string;

  /**  */
  Suffix?: string;

  /**  */
  OrgName?: string;

  /**  */
  BranchName?: string;

  /**  */
  Title?: string;

  /**  */
  Street1?: string;

  /**  */
  Street2?: string;

  /**  */
  City?: string;

  /**  */
  State?: string;

  /**  */
  Postal?: string;

  /**  */
  County?: string;

  /**  */
  Country?: string;

  /**  */
  GeoLatitude?: number;

  /**  */
  GeoLongitude?: number;

  /**  */
  Phone?: string;

  /**  */
  Mobile?: string;

  /**  */
  Updated?: Date;

  /**  */
  Email?: string;

  /**  */
  FirstName?: string;

  /**  */
  LastName?: string;

  /**  */
  HumanId?: number;

  /**  */
  OrgBranchId?: number;

  /**  */
  OrgId?: number;

  /**  */
  BranchId?: number;

  /**  */
  Role?: string;

  /**  */
  id?: string;
}

export interface Agent {
  /**  */
  AgencyKey?: string;

  /**  */
  SeekDisplay?: string;

  /**  */
  key?: string;

  /**  */
  Producer?: boolean;

  /**  */
  Licenses?: License[];

  /**  */
  LicenseTags?: string;

  /**  */
  AmlinkEnabled?: boolean;

  /**  */
  Active?: boolean;

  /**  */
  Name?: string;

  /**  */
  Salutation?: string;

  /**  */
  MiddleName?: string;

  /**  */
  Suffix?: string;

  /**  */
  OrgName?: string;

  /**  */
  BranchName?: string;

  /**  */
  Title?: string;

  /**  */
  Street1?: string;

  /**  */
  Street2?: string;

  /**  */
  City?: string;

  /**  */
  State?: string;

  /**  */
  Postal?: string;

  /**  */
  County?: string;

  /**  */
  Country?: string;

  /**  */
  GeoLatitude?: number;

  /**  */
  GeoLongitude?: number;

  /**  */
  Phone?: string;

  /**  */
  Mobile?: string;

  /**  */
  Updated?: Date;

  /**  */
  Email?: string;

  /**  */
  FirstName?: string;

  /**  */
  LastName?: string;

  /**  */
  HumanId?: number;

  /**  */
  OrgBranchId?: number;

  /**  */
  OrgId?: number;

  /**  */
  BranchId?: number;

  /**  */
  Role?: string;

  /**  */
  id?: string;
}

export interface AmlinkUser {
  /**  */
  HumanId?: number;

  /**  */
  HumanEmail?: string;

  /**  */
  LastUpdatedDate?: Date;

  /**  */
  IsLogonActive?: boolean;

  /**  */
  IsEnabled?: boolean;

  /**  */
  HasEmailConflict?: boolean;

  /**  */
  HasBranchConflict?: boolean;

  /**  */
  IsRetailer?: boolean;

  /**  */
  IsOrgPartition?: boolean;

  /**  */
  IsEmployee?: boolean;

  /**  */
  IsUnderwriter?: boolean;

  /**  */
  IsBroker?: boolean;

  /**  */
  IsVmgaProducer?: boolean;

  /**  */
  AmwinsDivisionId?: number;

  /**  */
  AmwinsDivisionName?: string;

  /**  */
  AppPersonalLines?: boolean;

  /**  */
  AppProfessionalLines?: boolean;

  /**  */
  HumanFirstName?: string;

  /**  */
  HumanMiddleName?: string;

  /**  */
  HumanLastName?: string;

  /**  */
  HumanNameSuffix?: string;

  /**  */
  HumanTitlePrefix?: string;

  /**  */
  HumanSalutation?: string;

  /**  */
  HumanNickName?: string;

  /**  */
  HumanTitle?: string;

  /**  */
  HumanPhone?: string;

  /**  */
  HumanMobile?: string;

  /**  */
  HumanEnabled?: boolean;

  /**  */
  HumanEffectiveDate?: Date;

  /**  */
  HumanExpirationDate?: Date;

  /**  */
  HumanLastUpdatedDate?: Date;

  /**  */
  BranchHumanId?: number;

  /**  */
  BranchHumanEnabled?: boolean;

  /**  */
  BranchHumanEffectiveDate?: Date;

  /**  */
  BranchHumanExpirationDate?: Date;

  /**  */
  BranchId?: number;

  /**  */
  BranchName?: string;

  /**  */
  MainBranch?: boolean;

  /**  */
  BranchEnabled?: boolean;

  /**  */
  BranchEffectiveDate?: Date;

  /**  */
  BranchExpirationDate?: Date;

  /**  */
  BranchLastUpdatedDate?: Date;

  /**  */
  BranchPhone?: string;

  /**  */
  BranchPhysicalAddressId?: number;

  /**  */
  BranchPhysicalAddress1?: string;

  /**  */
  BranchPhysicalAddress2?: string;

  /**  */
  BranchPhysicalAddress3?: string;

  /**  */
  BranchPhysicalAddress4?: string;

  /**  */
  BranchPhysicalCity?: string;

  /**  */
  BranchPhysicalStateCd?: string;

  /**  */
  BranchPhysicalZipCode?: string;

  /**  */
  BranchPhysicalZip4?: string;

  /**  */
  BranchPhysicalCountyId?: number;

  /**  */
  BranchPhysicalCountyName?: string;

  /**  */
  BranchPhysicalCountryId?: number;

  /**  */
  BranchPhysicalCountryCode?: string;

  /**  */
  BranchPhysicalCountryName?: string;

  /**  */
  BranchPhysicalLastUpdatedDate?: Date;

  /**  */
  BranchMailAddressSame?: boolean;

  /**  */
  BranchMailAddressId?: number;

  /**  */
  BranchMailAddress1?: string;

  /**  */
  BranchMailAddress2?: string;

  /**  */
  BranchMailAddress3?: string;

  /**  */
  BranchMailAddress4?: string;

  /**  */
  BranchMailCity?: string;

  /**  */
  BranchMailStateCd?: string;

  /**  */
  BranchMailZipCode?: string;

  /**  */
  BranchMailZip4?: string;

  /**  */
  BranchMailCountyId?: number;

  /**  */
  BranchMailCountyName?: string;

  /**  */
  BranchMailCountryId?: number;

  /**  */
  BranchMailCountryCode?: string;

  /**  */
  BranchMailCountryName?: string;

  /**  */
  BranchMailLastUpdatedDate?: Date;

  /**  */
  OrgBranchId?: number;

  /**  */
  OrgBranchEnabled?: boolean;

  /**  */
  OrgBranchEffectiveDate?: Date;

  /**  */
  OrgBranchExpirationDate?: Date;

  /**  */
  OrgId?: number;

  /**  */
  OrgCode?: string;

  /**  */
  OrgName?: string;

  /**  */
  OrgLegalEntityTypeId?: number;

  /**  */
  OrgLegalEntityName?: string;

  /**  */
  OrgFein?: string;

  /**  */
  OrgEnabled?: boolean;

  /**  */
  OrgEffectiveDate?: Date;

  /**  */
  OrgExpirationDate?: Date;

  /**  */
  CleanOrgName?: string;

  /**  */
  QueueUpdateDate?: Date;
}

export interface Amwins_Api123_Client_Models_Etc_Iso3166_Iso31661 {
  /**  */
  name?: string;

  /**  */
  alpha2?: string;

  /**  */
  alpha3?: string;

  /**  */
  countryCode?: string;

  /**  */
  isoCode?: string;

  /**  */
  region?: string;

  /**  */
  subRegion?: string;
}

export interface Amwins_Orbits_Contracts_MessageContracts_Endorsements_EndorsementHeaderV1 {
  /**  */
  EndorsementId?: string;

  /**  */
  ExternalEndorsementId?: string;

  /**  */
  PolicyId?: string;

  /**  */
  PolicyNumber?: string;

  /**  */
  QuoteId?: string;

  /**  */
  EffectiveDate?: Date;

  /**  */
  ExpirationDate?: Date;

  /**  */
  SalesChannel?: Amwins_Orbits_Contracts_Models_SalesChannels_SalesChannel;

  /**  */
  ProductType?: Amwins_Orbits_Contracts_Models_ProductType;

  /**  */
  ExternalPolicyId?: string;

  /**  */
  SubmissionNumber?: string;

  /**  */
  BindTime?: Date;
}

export interface Amwins_Orbits_Contracts_MessageContracts_Endorsements_EndorsementStateUpdatedV1 {
  /**  */
  Endorsement?: Amwins_Orbits_Contracts_Models_v1_Endorsements_EndorsementV1;

  /**  */
  VersionNumber?: number;

  /**  */
  Header?: Amwins_Orbits_Contracts_MessageContracts_Endorsements_EndorsementHeaderV1;

  /**  */
  SubmissionId?: string;

  /**  */
  MessageId?: string;

  /**  */
  PredecessorMessageId?: string;
}

export interface Amwins_Orbits_Contracts_MessageContracts_Endorsements_v2_EndorsementRequestV2_System_Nullable_System_Text_Json_JsonElement__System_Nullable_System_Text_Json_JsonElement {
  /**  */
  EffectiveDate?: Date;

  /**  */
  ExternalPolicyId?: string;

  /**  */
  ShouldCompleteInspection?: boolean;

  /**  */
  RequestedCoverageChanges?: System_Text_Json_JsonElement;

  /**  */
  CoverageModelType?: Amwins_Orbits_Contracts_Models_v2_CoverageModelType;

  /**  */
  RequestedInsuredChanges?: Amwins_Orbits_Contracts_Models_v2_InsuredInformation_InsuredV2;

  /**  */
  RequestedRiskChanges?: System_Text_Json_JsonElement;

  /**  */
  RiskModelType?: Amwins_Orbits_Contracts_Models_v2_RiskModelType;

  /**  */
  RequestedPrimaryMortgageeChanges?: Amwins_Orbits_Contracts_Models_AdditionalInterestInformation_Mortgagee;

  /**  */
  RequestedSecondaryMortgageeChanges?: Amwins_Orbits_Contracts_Models_AdditionalInterestInformation_Mortgagee;

  /**  */
  RequestedAdditionalInterestChanges?: Amwins_Orbits_Contracts_Models_AdditionalInterestInformation_AdditionalInterest[];

  /**  */
  EndorsementId?: string;

  /**  */
  ExternalEndorsementId?: string;

  /**  */
  SalesChannel?: Amwins_Orbits_Contracts_Models_SalesChannels_SalesChannel;

  /**  */
  PolicyId?: string;

  /**  */
  SubmissionId?: string;

  /**  */
  SubmissionNumber?: string;

  /**  */
  Predecessors?: Amwins_Orbits_Contracts_MessageContracts_v2_Predecessor[];

  /**  */
  PredecessorMessageIds?: string[];

  /**  */
  InitiatingUser?: Amwins_Orbits_Contracts_Models_User;

  /**  */
  CreatedDate?: Date;

  /**  */
  MessageId?: string;

  /**  */
  PredecessorMessageId?: string;
}

export interface Amwins_Orbits_Contracts_MessageContracts_Endorsements_v2_EndorsementStateUpdatedV2_System_Nullable_System_Text_Json_JsonElement__System_Nullable_System_Text_Json_JsonElement {
  /**  */
  Endorsement?: Amwins_Orbits_Contracts_Models_v2_Endorsements_EndorsementV2_System_Nullable_System_Text_Json_JsonElement__System_Nullable_System_Text_Json_JsonElement;

  /**  */
  VersionNumber?: number;

  /**  */
  EndorsementId?: string;

  /**  */
  ExternalEndorsementId?: string;

  /**  */
  SalesChannel?: Amwins_Orbits_Contracts_Models_SalesChannels_SalesChannel;

  /**  */
  PolicyId?: string;

  /**  */
  SubmissionId?: string;

  /**  */
  SubmissionNumber?: string;

  /**  */
  Predecessors?: Amwins_Orbits_Contracts_MessageContracts_v2_Predecessor[];

  /**  */
  PredecessorMessageIds?: string[];

  /**  */
  InitiatingUser?: Amwins_Orbits_Contracts_Models_User;

  /**  */
  CreatedDate?: Date;

  /**  */
  MessageId?: string;

  /**  */
  PredecessorMessageId?: string;
}

export interface Amwins_Orbits_Contracts_MessageContracts_Policies_PolicyHeaderV1 {
  /**  */
  PolicyId?: string;

  /**  */
  PolicyNumber?: string;

  /**  */
  QuoteId?: string;

  /**  */
  EffectiveDate?: Date;

  /**  */
  ExpirationDate?: Date;

  /**  */
  SalesChannel?: Amwins_Orbits_Contracts_Models_SalesChannels_SalesChannel;

  /**  */
  ProductType?: Amwins_Orbits_Contracts_Models_ProductType;

  /**  */
  ExternalPolicyId?: string;

  /**  */
  SubmissionNumber?: string;

  /**  */
  BindTime?: Date;
}

export interface Amwins_Orbits_Contracts_MessageContracts_Policies_v2_InspectionCompletedV2 {
  /**  */
  SalesChannel?: Amwins_Orbits_Contracts_Models_SalesChannels_SalesChannel;

  /**  */
  PolicyId?: string;

  /**  */
  SubmissionId?: string;

  /**  */
  SubmissionNumber?: string;

  /**  */
  Predecessors?: Amwins_Orbits_Contracts_MessageContracts_v2_Predecessor[];

  /**  */
  PredecessorMessageIds?: string[];

  /**  */
  InitiatingUser?: Amwins_Orbits_Contracts_Models_User;

  /**  */
  CreatedDate?: Date;

  /**  */
  MessageId?: string;

  /**  */
  PredecessorMessageId?: string;
}

export interface Amwins_Orbits_Contracts_MessageContracts_Quotes_PaymentSource {
  /**  */
  Value?: string;

  /**  */
  Id?: string;
}

export interface Amwins_Orbits_Contracts_MessageContracts_Quotes_QuoteHeaderV1 {
  /**  */
  QuoteId?: string;

  /**  */
  QuoteGroupingId?: string;

  /**  */
  SalesChannel?: Amwins_Orbits_Contracts_Models_SalesChannels_SalesChannel;

  /**  */
  SubmissionId?: string;

  /**  */
  SubmissionNumber?: string;

  /**  */
  State?: string;

  /**  */
  ProductType?: Amwins_Orbits_Contracts_Models_ProductType;

  /**  */
  TermBegin?: Date;

  /**  */
  TermEnd?: Date;

  /**  */
  QuoteRequested?: Date;

  /**  */
  ExternalQuoteId?: string;
}

export interface Amwins_Orbits_Contracts_MessageContracts_v2_Predecessor {
  /**  */
  Name?: string;

  /**  */
  MessageId?: string;
}

export interface Amwins_Orbits_Contracts_Models_AdditionalInterestInformation_AdditionalInterest {
  /**  */
  Name?: string;

  /**  */
  Address?: Amwins_Orbits_Contracts_Models_v1_AddressV1;

  /**  */
  AdditionalInterestType?: string;
}

export interface Amwins_Orbits_Contracts_Models_AdditionalInterestInformation_Mortgagee {
  /**  */
  LoanNumber?: string;

  /**  */
  MortgageeOrganization?: string;

  /**  */
  MortageeSuffix?: string;

  /**  */
  InvoiceOnRenewal?: boolean;

  /**  */
  Address?: Amwins_Orbits_Contracts_Models_v1_InsuredInformation_MailingAddressV1;

  /**  */
  PhoneNumber?: string;
}

export interface Amwins_Orbits_Contracts_Models_ChangedElement {
  /**  */
  Element?: Amwins_Orbits_Contracts_Models_TrackedElement;

  /**  */
  Prior?: string;

  /**  */
  Altered?: string;
}

export interface Amwins_Orbits_Contracts_Models_Endorsements_EndorsementStatus {
  /**  */
  Value?: string;

  /**  */
  Id?: string;
}

export interface Amwins_Orbits_Contracts_Models_Endorsements_EndorsementType {
  /**  */
  Value?: string;

  /**  */
  Id?: string;
}

export interface Amwins_Orbits_Contracts_Models_Files_FileReference {
  /**  */
  FileReferenceId?: string;
}

export interface Amwins_Orbits_Contracts_Models_FormType {
  /**  */
  Value?: string;

  /**  */
  Id?: string;
}

export interface Amwins_Orbits_Contracts_Models_Inspections_InspectionProvider {
  /**  */
  Value?: string;

  /**  */
  Id?: string;
}

export interface Amwins_Orbits_Contracts_Models_Inspections_InspectionType {
  /**  */
  Value?: string;

  /**  */
  Id?: string;
}

export interface Amwins_Orbits_Contracts_Models_Payments_InsuredEmailOptions {
  /**  */
  InsuredReceivesPolicyEmails?: boolean;

  /**  */
  InsuredEmailAddress?: string;

  /**  */
  InsuredEmailAttestationText?: string;
}

export interface Amwins_Orbits_Contracts_Models_Payments_PaymentOption {
  /**  */
  Value?: string;

  /**  */
  Id?: string;
}

export interface Amwins_Orbits_Contracts_Models_Percentage {
  /**  */
  Value?: number;
}

export interface Amwins_Orbits_Contracts_Models_Policies_TransactionType {
  /**  */
  Value?: string;

  /**  */
  Id?: string;
}

export interface Amwins_Orbits_Contracts_Models_Producers_Broker {
  /**  */
  Name?: string;

  /**  */
  Salutation?: string;

  /**  */
  FirstName?: string;

  /**  */
  MiddleName?: string;

  /**  */
  LastName?: string;

  /**  */
  Suffix?: string;

  /**  */
  OrgName?: string;

  /**  */
  OrgId?: number;

  /**  */
  BranchName?: string;

  /**  */
  BranchId?: number;

  /**  */
  OrgBranchId?: number;

  /**  */
  HumanId?: number;

  /**  */
  Title?: string;

  /**  */
  Street1?: string;

  /**  */
  Street2?: string;

  /**  */
  City?: string;

  /**  */
  State?: string;

  /**  */
  Postal?: string;

  /**  */
  County?: string;

  /**  */
  Country?: string;

  /**  */
  Phone?: string;

  /**  */
  Mobile?: string;

  /**  */
  Email?: string;
}

export interface Amwins_Orbits_Contracts_Models_Producers_Retailer {
  /**  */
  Name?: string;

  /**  */
  Salutation?: string;

  /**  */
  FirstName?: string;

  /**  */
  MiddleName?: string;

  /**  */
  LastName?: string;

  /**  */
  Suffix?: string;

  /**  */
  OrgName?: string;

  /**  */
  OrgId?: number;

  /**  */
  BranchName?: string;

  /**  */
  BranchId?: number;

  /**  */
  OrgBranchId?: number;

  /**  */
  HumanId?: number;

  /**  */
  Title?: string;

  /**  */
  Street1?: string;

  /**  */
  Street2?: string;

  /**  */
  City?: string;

  /**  */
  State?: string;

  /**  */
  Postal?: string;

  /**  */
  County?: string;

  /**  */
  Country?: string;

  /**  */
  Phone?: string;

  /**  */
  Mobile?: string;

  /**  */
  Email?: string;
}

export interface Amwins_Orbits_Contracts_Models_ProductType {
  /**  */
  Value?: string;

  /**  */
  Id?: string;
}

export interface Amwins_Orbits_Contracts_Models_Quotes_PlacementStatus {
  /**  */
  Value?: string;

  /**  */
  Id?: string;
}

export interface Amwins_Orbits_Contracts_Models_Quotes_PricingStatus {
  /**  */
  Value?: string;

  /**  */
  Id?: string;
}

export interface Amwins_Orbits_Contracts_Models_Quotes_QuoteEligibilityStatus {
  /**  */
  Value?: string;

  /**  */
  Id?: string;
}

export interface Amwins_Orbits_Contracts_Models_Reinstatements_ReinstatementReason {
  /**  */
  Value?: string;

  /**  */
  Id?: string;
}

export interface Amwins_Orbits_Contracts_Models_SalesChannels_SalesChannel {
  /**  */
  Value?: string;

  /**  */
  Id?: string;
}

export interface Amwins_Orbits_Contracts_Models_Submissions_IAttestationV0 {
  /**  */
  DateTime?: Date;

  /**  */
  Text?: string;

  /**  */
  UserEmail?: string;

  /**  */
  UserId?: string;

  /**  */
  UserName?: string;
}

export interface Amwins_Orbits_Contracts_Models_TrackedElement {
  /**  */
  Label?: string;

  /**  */
  Order?: number;

  /**  */
  Value?: string;

  /**  */
  Id?: string;
}

export interface Amwins_Orbits_Contracts_Models_User {
  /**  */
  Email?: string;

  /**  */
  FirstName?: string;

  /**  */
  LastName?: string;

  /**  */
  UserRole?: Amwins_Orbits_Contracts_Models_UserRole;
}

export interface Amwins_Orbits_Contracts_Models_UserRole {
  /**  */
  Value?: string;

  /**  */
  Id?: string;
}

export interface Amwins_Orbits_Contracts_Models_v1_AddressV1 {
  /**  */
  StreetNumber?: string;

  /**  */
  StreetName?: string;

  /**  */
  City?: string;

  /**  */
  State?: string;

  /**  */
  PostalCode?: string;

  /**  */
  County?: string;

  /**  */
  CountryCode?: string;

  /**  */
  UnitType?: string;

  /**  */
  UnitNumber?: string;

  /**  */
  Latitude?: number;

  /**  */
  Longitude?: number;
}

export interface Amwins_Orbits_Contracts_Models_v1_CoverageInformation_AOPV1 {
  /**  */
  Limit?: Amwins_Orbits_Contracts_Models_v1_CoverageInformation_LimitV1;

  /**  */
  Deductible?: Amwins_Orbits_Contracts_Models_v1_CoverageInformation_DeductibleV1;
}

export interface Amwins_Orbits_Contracts_Models_v1_CoverageInformation_CoverageAV1 {
  /**  */
  ValuationType?: Amwins_Orbits_Contracts_Models_v1_RiskInformation_ValuationType;

  /**  */
  ValuationAmount?: number;

  /**  */
  Liability?: Amwins_Orbits_Contracts_Models_v1_CoverageInformation_LiabilityV1;

  /**  */
  AOP?: Amwins_Orbits_Contracts_Models_v1_CoverageInformation_AOPV1;

  /**  */
  WaterPeril?: Amwins_Orbits_Contracts_Models_v1_CoverageInformation_WaterPerilV1;

  /**  */
  WindPeril?: Amwins_Orbits_Contracts_Models_v1_CoverageInformation_WindPerilV1;

  /**  */
  FloodPeril?: Amwins_Orbits_Contracts_Models_v1_CoverageInformation_FloodPerilV1;

  /**  */
  StormSurgePeril?: Amwins_Orbits_Contracts_Models_v1_CoverageInformation_StormSurgePerilV1;

  /**  */
  EarthquakePeril?: Amwins_Orbits_Contracts_Models_v1_CoverageInformation_EarthquakePerilV1;

  /**  */
  OrdinanceAndLaw?: Amwins_Orbits_Contracts_Models_v1_CoverageInformation_OrdinanceAndLawV1;
}

export interface Amwins_Orbits_Contracts_Models_v1_CoverageInformation_CoverageBV1 {
  /**  */
  Liability?: Amwins_Orbits_Contracts_Models_v1_CoverageInformation_LiabilityV1;

  /**  */
  AOP?: Amwins_Orbits_Contracts_Models_v1_CoverageInformation_AOPV1;

  /**  */
  WaterPeril?: Amwins_Orbits_Contracts_Models_v1_CoverageInformation_WaterPerilV1;

  /**  */
  WindPeril?: Amwins_Orbits_Contracts_Models_v1_CoverageInformation_WindPerilV1;

  /**  */
  FloodPeril?: Amwins_Orbits_Contracts_Models_v1_CoverageInformation_FloodPerilV1;

  /**  */
  StormSurgePeril?: Amwins_Orbits_Contracts_Models_v1_CoverageInformation_StormSurgePerilV1;

  /**  */
  EarthquakePeril?: Amwins_Orbits_Contracts_Models_v1_CoverageInformation_EarthquakePerilV1;

  /**  */
  OrdinanceAndLaw?: Amwins_Orbits_Contracts_Models_v1_CoverageInformation_OrdinanceAndLawV1;
}

export interface Amwins_Orbits_Contracts_Models_v1_CoverageInformation_CoverageCV1 {
  /**  */
  ValuationType?: Amwins_Orbits_Contracts_Models_v1_RiskInformation_ValuationType;

  /**  */
  ValuationAmount?: number;

  /**  */
  Liability?: Amwins_Orbits_Contracts_Models_v1_CoverageInformation_LiabilityV1;

  /**  */
  AOP?: Amwins_Orbits_Contracts_Models_v1_CoverageInformation_AOPV1;

  /**  */
  WaterPeril?: Amwins_Orbits_Contracts_Models_v1_CoverageInformation_WaterPerilV1;

  /**  */
  WindPeril?: Amwins_Orbits_Contracts_Models_v1_CoverageInformation_WindPerilV1;

  /**  */
  FloodPeril?: Amwins_Orbits_Contracts_Models_v1_CoverageInformation_FloodPerilV1;

  /**  */
  StormSurgePeril?: Amwins_Orbits_Contracts_Models_v1_CoverageInformation_StormSurgePerilV1;

  /**  */
  EarthquakePeril?: Amwins_Orbits_Contracts_Models_v1_CoverageInformation_EarthquakePerilV1;

  /**  */
  OrdinanceAndLaw?: Amwins_Orbits_Contracts_Models_v1_CoverageInformation_OrdinanceAndLawV1;
}

export interface Amwins_Orbits_Contracts_Models_v1_CoverageInformation_CoverageDV1 {
  /**  */
  Liability?: Amwins_Orbits_Contracts_Models_v1_CoverageInformation_LiabilityV1;

  /**  */
  AOP?: Amwins_Orbits_Contracts_Models_v1_CoverageInformation_AOPV1;

  /**  */
  WaterPeril?: Amwins_Orbits_Contracts_Models_v1_CoverageInformation_WaterPerilV1;

  /**  */
  WindPeril?: Amwins_Orbits_Contracts_Models_v1_CoverageInformation_WindPerilV1;

  /**  */
  FloodPeril?: Amwins_Orbits_Contracts_Models_v1_CoverageInformation_FloodPerilV1;

  /**  */
  StormSurgePeril?: Amwins_Orbits_Contracts_Models_v1_CoverageInformation_StormSurgePerilV1;

  /**  */
  EarthquakePeril?: Amwins_Orbits_Contracts_Models_v1_CoverageInformation_EarthquakePerilV1;

  /**  */
  OrdinanceAndLaw?: Amwins_Orbits_Contracts_Models_v1_CoverageInformation_OrdinanceAndLawV1;
}

export interface Amwins_Orbits_Contracts_Models_v1_CoverageInformation_CoverageEV1 {
  /**  */
  Liability?: Amwins_Orbits_Contracts_Models_v1_CoverageInformation_LiabilityV1;

  /**  */
  AOP?: Amwins_Orbits_Contracts_Models_v1_CoverageInformation_AOPV1;

  /**  */
  WaterPeril?: Amwins_Orbits_Contracts_Models_v1_CoverageInformation_WaterPerilV1;

  /**  */
  WindPeril?: Amwins_Orbits_Contracts_Models_v1_CoverageInformation_WindPerilV1;

  /**  */
  FloodPeril?: Amwins_Orbits_Contracts_Models_v1_CoverageInformation_FloodPerilV1;

  /**  */
  StormSurgePeril?: Amwins_Orbits_Contracts_Models_v1_CoverageInformation_StormSurgePerilV1;

  /**  */
  EarthquakePeril?: Amwins_Orbits_Contracts_Models_v1_CoverageInformation_EarthquakePerilV1;

  /**  */
  OrdinanceAndLaw?: Amwins_Orbits_Contracts_Models_v1_CoverageInformation_OrdinanceAndLawV1;
}

export interface Amwins_Orbits_Contracts_Models_v1_CoverageInformation_CoverageFV1 {
  /**  */
  Liability?: Amwins_Orbits_Contracts_Models_v1_CoverageInformation_LiabilityV1;

  /**  */
  AOP?: Amwins_Orbits_Contracts_Models_v1_CoverageInformation_AOPV1;

  /**  */
  WaterPeril?: Amwins_Orbits_Contracts_Models_v1_CoverageInformation_WaterPerilV1;

  /**  */
  WindPeril?: Amwins_Orbits_Contracts_Models_v1_CoverageInformation_WindPerilV1;

  /**  */
  FloodPeril?: Amwins_Orbits_Contracts_Models_v1_CoverageInformation_FloodPerilV1;

  /**  */
  StormSurgePeril?: Amwins_Orbits_Contracts_Models_v1_CoverageInformation_StormSurgePerilV1;

  /**  */
  EarthquakePeril?: Amwins_Orbits_Contracts_Models_v1_CoverageInformation_EarthquakePerilV1;

  /**  */
  OrdinanceAndLaw?: Amwins_Orbits_Contracts_Models_v1_CoverageInformation_OrdinanceAndLawV1;
}

export interface Amwins_Orbits_Contracts_Models_v1_CoverageInformation_CoveragesV1 {
  /**  */
  FormType?: Amwins_Orbits_Contracts_Models_FormType;

  /**  */
  CovA?: Amwins_Orbits_Contracts_Models_v1_CoverageInformation_CoverageAV1;

  /**  */
  CovB?: Amwins_Orbits_Contracts_Models_v1_CoverageInformation_CoverageBV1;

  /**  */
  CovC?: Amwins_Orbits_Contracts_Models_v1_CoverageInformation_CoverageCV1;

  /**  */
  CovD?: Amwins_Orbits_Contracts_Models_v1_CoverageInformation_CoverageDV1;

  /**  */
  CovE?: Amwins_Orbits_Contracts_Models_v1_CoverageInformation_CoverageEV1;

  /**  */
  CovF?: Amwins_Orbits_Contracts_Models_v1_CoverageInformation_CoverageFV1;
}

export interface Amwins_Orbits_Contracts_Models_v1_CoverageInformation_DeductibleMode {
  /**  */
  Value?: string;

  /**  */
  Id?: string;
}

export interface Amwins_Orbits_Contracts_Models_v1_CoverageInformation_DeductibleV1 {
  /**  */
  DeductibleMode?: Amwins_Orbits_Contracts_Models_v1_CoverageInformation_DeductibleMode;

  /**  */
  MinimumDeductible?: Amwins_Orbits_Contracts_Models_v1_CoverageInformation_MinimumDeductibleV1;

  /**  */
  Amount?: number;

  /**  */
  Percentage?: Amwins_Orbits_Contracts_Models_Percentage;
}

export interface Amwins_Orbits_Contracts_Models_v1_CoverageInformation_EarthquakePerilV1 {
  /**  */
  Limit?: Amwins_Orbits_Contracts_Models_v1_CoverageInformation_LimitV1;

  /**  */
  Deductible?: Amwins_Orbits_Contracts_Models_v1_CoverageInformation_DeductibleV1;
}

export interface Amwins_Orbits_Contracts_Models_v1_CoverageInformation_FloodPerilV1 {
  /**  */
  Limit?: Amwins_Orbits_Contracts_Models_v1_CoverageInformation_LimitV1;

  /**  */
  Deductible?: Amwins_Orbits_Contracts_Models_v1_CoverageInformation_DeductibleV1;
}

export interface Amwins_Orbits_Contracts_Models_v1_CoverageInformation_LiabilityV1 {
  /**  */
  Limit?: Amwins_Orbits_Contracts_Models_v1_CoverageInformation_LimitV1;

  /**  */
  Deductible?: Amwins_Orbits_Contracts_Models_v1_CoverageInformation_DeductibleV1;
}

export interface Amwins_Orbits_Contracts_Models_v1_CoverageInformation_LimitV1 {
  /**  */
  Amount?: number;
}

export interface Amwins_Orbits_Contracts_Models_v1_CoverageInformation_MinimumDeductibleV1 {
  /**  */
  Amount?: number;

  /**  */
  Percentage?: Amwins_Orbits_Contracts_Models_Percentage;
}

export interface Amwins_Orbits_Contracts_Models_v1_CoverageInformation_OrdinanceAndLawV1 {
  /**  */
  Limit?: Amwins_Orbits_Contracts_Models_v1_CoverageInformation_LimitV1;

  /**  */
  Deductible?: Amwins_Orbits_Contracts_Models_v1_CoverageInformation_DeductibleV1;
}

export interface Amwins_Orbits_Contracts_Models_v1_CoverageInformation_StormSurgePerilV1 {
  /**  */
  Limit?: Amwins_Orbits_Contracts_Models_v1_CoverageInformation_LimitV1;

  /**  */
  Deductible?: Amwins_Orbits_Contracts_Models_v1_CoverageInformation_DeductibleV1;
}

export interface Amwins_Orbits_Contracts_Models_v1_CoverageInformation_WaterPerilV1 {
  /**  */
  Limit?: Amwins_Orbits_Contracts_Models_v1_CoverageInformation_LimitV1;

  /**  */
  Deductible?: Amwins_Orbits_Contracts_Models_v1_CoverageInformation_DeductibleV1;
}

export interface Amwins_Orbits_Contracts_Models_v1_CoverageInformation_WindPerilV1 {
  /**  */
  WindDeductibleType?: Amwins_Orbits_Contracts_Models_v1_CoverageInformation_WindDeductibleType;

  /**  */
  Limit?: Amwins_Orbits_Contracts_Models_v1_CoverageInformation_LimitV1;

  /**  */
  Deductible?: Amwins_Orbits_Contracts_Models_v1_CoverageInformation_DeductibleV1;
}

export interface Amwins_Orbits_Contracts_Models_v1_Endorsements_EndorsementV1 {
  /**  */
  EndorsementId?: string;

  /**  */
  PolicyId?: string;

  /**  */
  EndorsementEffectiveDate?: Date;

  /**  */
  Status?: Amwins_Orbits_Contracts_Models_Endorsements_EndorsementStatus;

  /**  */
  LastUpdated?: Date;

  /**  */
  EndorsementType?: Amwins_Orbits_Contracts_Models_Endorsements_EndorsementType;

  /**  */
  CurrentInsured?: Amwins_Orbits_Contracts_Models_v1_InsuredInformation_InsuredV1;

  /**  */
  UpdatedInsured?: Amwins_Orbits_Contracts_Models_v1_InsuredInformation_InsuredV1;

  /**  */
  CurrentRisk?: Amwins_Orbits_Contracts_Models_v1_RiskInformation_RiskV1;

  /**  */
  UpdatedRisk?: Amwins_Orbits_Contracts_Models_v1_RiskInformation_RiskV1;

  /**  */
  CurrentCoverages?: Amwins_Orbits_Contracts_Models_v1_CoverageInformation_CoveragesV1;

  /**  */
  UpdatedCoverages?: Amwins_Orbits_Contracts_Models_v1_CoverageInformation_CoveragesV1;

  /**  */
  AnnualPricing?: Amwins_Orbits_Contracts_Models_v1_Policies_PolicyPricing;

  /**  */
  TransactionPricing?: Amwins_Orbits_Contracts_Models_v1_Policies_PolicyPricing;

  /**  */
  CurrentPrimaryMortgagee?: Amwins_Orbits_Contracts_Models_AdditionalInterestInformation_Mortgagee;

  /**  */
  UpdatedPrimaryMortgagee?: Amwins_Orbits_Contracts_Models_AdditionalInterestInformation_Mortgagee;

  /**  */
  CurrentSecondaryMortgagee?: Amwins_Orbits_Contracts_Models_AdditionalInterestInformation_Mortgagee;

  /**  */
  UpdatedSecondaryMortgagee?: Amwins_Orbits_Contracts_Models_AdditionalInterestInformation_Mortgagee;

  /**  */
  EndorsementQuoteDocument?: Amwins_Orbits_Contracts_Models_Files_FileReference;

  /**  */
  ChangedElements?: Amwins_Orbits_Contracts_Models_ChangedElement[];
}

export interface Amwins_Orbits_Contracts_Models_v1_InsuredInformation_ClaimAmountV1 {
  /**  */
  Amount?: number;
}

export interface Amwins_Orbits_Contracts_Models_v1_InsuredInformation_ClaimRepairedV1 {
  /**  */
  Repaired?: boolean;
}

export interface Amwins_Orbits_Contracts_Models_v1_InsuredInformation_ClaimSettledDateV1 {
  /**  */
  SettledDate?: Date;
}

export interface Amwins_Orbits_Contracts_Models_v1_InsuredInformation_ClaimSettledV1 {
  /**  */
  Settled?: boolean;
}

export interface Amwins_Orbits_Contracts_Models_v1_InsuredInformation_ClaimType {
  /**  */
  Value?: string;

  /**  */
  Id?: string;
}

export interface Amwins_Orbits_Contracts_Models_v1_InsuredInformation_ClaimV1 {
  /**  */
  ClaimType?: Amwins_Orbits_Contracts_Models_v1_InsuredInformation_ClaimType;

  /**  */
  ClaimDate?: Date;

  /**  */
  ClaimAmount?: Amwins_Orbits_Contracts_Models_v1_InsuredInformation_ClaimAmountV1;

  /**  */
  Description?: string;

  /**  */
  ClaimRepaired?: Amwins_Orbits_Contracts_Models_v1_InsuredInformation_ClaimRepairedV1;

  /**  */
  ElevatedAfterPriorFloodLoss?: Amwins_Orbits_Contracts_Models_v1_InsuredInformation_ElevatedAfterPriorFloodLossV1;

  /**  */
  ClaimSettled?: Amwins_Orbits_Contracts_Models_v1_InsuredInformation_ClaimSettledV1;

  /**  */
  ClaimSettledDate?: Amwins_Orbits_Contracts_Models_v1_InsuredInformation_ClaimSettledDateV1;
}

export interface Amwins_Orbits_Contracts_Models_v1_InsuredInformation_ConsumerCreditDisclosureV1 {
  /**  */
  HasConfirmedCompliance?: boolean;
}

export interface Amwins_Orbits_Contracts_Models_v1_InsuredInformation_ElevatedAfterPriorFloodLossV1 {
  /**  */
  Elevated?: boolean;
}

export interface Amwins_Orbits_Contracts_Models_v1_InsuredInformation_InsuredV1 {
  /**  */
  MailingAddress?: Amwins_Orbits_Contracts_Models_v1_InsuredInformation_MailingAddressV1;

  /**  */
  PrimaryInsured?: Amwins_Orbits_Contracts_Models_v1_InsuredInformation_PersonV1;

  /**  */
  SecondaryInsured?: Amwins_Orbits_Contracts_Models_v1_InsuredInformation_PersonV1;

  /**  */
  ConsumerCreditDisclosure?: Amwins_Orbits_Contracts_Models_v1_InsuredInformation_ConsumerCreditDisclosureV1;

  /**  */
  InsuredEmailOptions?: Amwins_Orbits_Contracts_Models_Payments_InsuredEmailOptions;

  /**  */
  Claims?: Amwins_Orbits_Contracts_Models_v1_InsuredInformation_ClaimV1[];
}

export interface Amwins_Orbits_Contracts_Models_v1_InsuredInformation_MailingAddressV1 {
  /**  */
  StreetAddress?: string;

  /**  */
  Address2?: string;

  /**  */
  Address3?: string;

  /**  */
  City?: string;

  /**  */
  State?: string;

  /**  */
  PostalCode?: string;

  /**  */
  CountryCode?: string;
}

export interface Amwins_Orbits_Contracts_Models_v1_InsuredInformation_PersonV1 {
  /**  */
  FirstName?: string;

  /**  */
  LastName?: string;

  /**  */
  DateOfBirth?: Date;
}

export interface Amwins_Orbits_Contracts_Models_v1_Policies_AmlinkEndorsement {
  /**  */
  PolicyDetailId?: number;

  /**  */
  PortalEndorsementId?: string;
}

export interface Amwins_Orbits_Contracts_Models_v1_Policies_AmlinkIdentifiers {
  /**  */
  AmlinkAccountId?: number;

  /**  */
  AmlinkSubmissionId?: number;

  /**  */
  AmlinkSubmissionFileId?: number;

  /**  */
  AmlinkSubmissionFileMarketId?: number;

  /**  */
  AmlinkQuoteId?: number;

  /**  */
  AmlinkPolicyId?: number;

  /**  */
  Endorsements?: Amwins_Orbits_Contracts_Models_v1_Policies_AmlinkEndorsement[];
}

export interface Amwins_Orbits_Contracts_Models_v1_Policies_BindingStatus {
  /**  */
  Value?: string;

  /**  */
  Id?: string;
}

export interface Amwins_Orbits_Contracts_Models_v1_Policies_CancellationTransactionV1 {
  /**  */
  Id?: string;

  /**  */
  TransactionNumber?: number;

  /**  */
  TransactionType?: Amwins_Orbits_Contracts_Models_Policies_TransactionType;

  /**  */
  EffectiveDate?: Date;

  /**  */
  TransactionDate?: Date;

  /**  */
  TransactionEffectiveDate?: Date;

  /**  */
  Risk?: Amwins_Orbits_Contracts_Models_v1_RiskInformation_RiskV1;

  /**  */
  Coverages?: Amwins_Orbits_Contracts_Models_v1_CoverageInformation_CoveragesV1;

  /**  */
  Insured?: Amwins_Orbits_Contracts_Models_v1_InsuredInformation_InsuredV1;

  /**  */
  TransactionPricing?: Amwins_Orbits_Contracts_Models_v1_Policies_PolicyPricing;

  /**  */
  AnnualPricing?: Amwins_Orbits_Contracts_Models_v1_Policies_PolicyPricing;

  /**  */
  CancellationDocument?: Amwins_Orbits_Contracts_Models_Files_FileReference;

  /**  */
  TransactionReason?: string;
}

export interface Amwins_Orbits_Contracts_Models_v1_Policies_EndorsementTransactionV1 {
  /**  */
  Id?: string;

  /**  */
  TransactionNumber?: number;

  /**  */
  TransactionType?: Amwins_Orbits_Contracts_Models_Policies_TransactionType;

  /**  */
  TransactionDate?: Date;

  /**  */
  TransactionEffectiveDate?: Date;

  /**  */
  EffectiveDate?: Date;

  /**  */
  Risk?: Amwins_Orbits_Contracts_Models_v1_RiskInformation_RiskV1;

  /**  */
  PriorCoverages?: Amwins_Orbits_Contracts_Models_v1_CoverageInformation_CoveragesV1;

  /**  */
  Coverages?: Amwins_Orbits_Contracts_Models_v1_CoverageInformation_CoveragesV1;

  /**  */
  Insured?: Amwins_Orbits_Contracts_Models_v1_InsuredInformation_InsuredV1;

  /**  */
  PrimaryMortgagee?: Amwins_Orbits_Contracts_Models_AdditionalInterestInformation_Mortgagee;

  /**  */
  SecondaryMortgagee?: Amwins_Orbits_Contracts_Models_AdditionalInterestInformation_Mortgagee;

  /**  */
  EndorsementQuoteDocument?: Amwins_Orbits_Contracts_Models_Files_FileReference;

  /**  */
  TransactionPricing?: Amwins_Orbits_Contracts_Models_v1_Policies_PolicyPricing;

  /**  */
  AnnualPricing?: Amwins_Orbits_Contracts_Models_v1_Policies_PolicyPricing;

  /**  */
  AmendedPolicyDecDocument?: Amwins_Orbits_Contracts_Models_Files_FileReference;

  /**  */
  TransactionReason?: string;

  /**  */
  AnnualizedPremium?: number;

  /**  */
  Endorsement?: Amwins_Orbits_Contracts_Models_Endorsements_EndorsementType;
}

export interface Amwins_Orbits_Contracts_Models_v1_Policies_NewBusinessTransactionV1 {
  /**  */
  Id?: string;

  /**  */
  TransactionNumber?: number;

  /**  */
  TransactionType?: Amwins_Orbits_Contracts_Models_Policies_TransactionType;

  /**  */
  EffectiveDate?: Date;

  /**  */
  TransactionDate?: Date;

  /**  */
  TransactionEffectiveDate?: Date;

  /**  */
  Risk?: Amwins_Orbits_Contracts_Models_v1_RiskInformation_RiskV1;

  /**  */
  Coverages?: Amwins_Orbits_Contracts_Models_v1_CoverageInformation_CoveragesV1;

  /**  */
  Insured?: Amwins_Orbits_Contracts_Models_v1_InsuredInformation_InsuredV1;

  /**  */
  TransactionPricing?: Amwins_Orbits_Contracts_Models_v1_Policies_PolicyPricing;

  /**  */
  AnnualPricing?: Amwins_Orbits_Contracts_Models_v1_Policies_PolicyPricing;

  /**  */
  QuoteApplication?: Amwins_Orbits_Contracts_Models_Files_FileReference;

  /**  */
  PolicyDocument?: Amwins_Orbits_Contracts_Models_Files_FileReference;

  /**  */
  DeclarationsPage?: Amwins_Orbits_Contracts_Models_Files_FileReference;

  /**  */
  StateCompliance?: Amwins_Orbits_Contracts_Models_Files_FileReference;

  /**  */
  TransactionReason?: string;
}

export interface Amwins_Orbits_Contracts_Models_v1_Policies_PolicyDocumentsV1 {
  /**  */
  Application?: Amwins_Orbits_Contracts_Models_Files_FileReference;

  /**  */
  Policy?: Amwins_Orbits_Contracts_Models_Files_FileReference;

  /**  */
  StateCompliance?: Amwins_Orbits_Contracts_Models_Files_FileReference;

  /**  */
  DeclarationsPage?: Amwins_Orbits_Contracts_Models_Files_FileReference;
}

export interface Amwins_Orbits_Contracts_Models_v1_Policies_PolicyPricing {
  /**  */
  Premium?: number;

  /**  */
  Fee?: number;

  /**  */
  Assessments?: Amwins_Orbits_Contracts_Models_v1_Taxes_Assessment[];
}

export interface Amwins_Orbits_Contracts_Models_v1_Policies_PolicyStatus {
  /**  */
  Value?: string;

  /**  */
  Id?: string;
}

export interface Amwins_Orbits_Contracts_Models_v1_Policies_PolicyV1 {
  /**  */
  Header?: Amwins_Orbits_Contracts_MessageContracts_Policies_PolicyHeaderV1;

  /**  */
  AnnualizedPricing?: Amwins_Orbits_Contracts_Models_v1_Policies_PolicyPricing;

  /**  */
  Status?: Amwins_Orbits_Contracts_Models_v1_Policies_PolicyStatus;

  /**  */
  BindingStatus?: Amwins_Orbits_Contracts_Models_v1_Policies_BindingStatus;

  /**  */
  LastUpdated?: Date;

  /**  */
  PolicyDocuments?: Amwins_Orbits_Contracts_Models_v1_Policies_PolicyDocumentsV1;

  /**  */
  PaymentSource?: Amwins_Orbits_Contracts_MessageContracts_Quotes_PaymentSource;

  /**  */
  PaymentOption?: Amwins_Orbits_Contracts_Models_Payments_PaymentOption;

  /**  */
  PrimaryMortgagee?: Amwins_Orbits_Contracts_Models_AdditionalInterestInformation_Mortgagee;

  /**  */
  SecondaryMortgagee?: Amwins_Orbits_Contracts_Models_AdditionalInterestInformation_Mortgagee;

  /**  */
  NewBusinessTransactions?: Amwins_Orbits_Contracts_Models_v1_Policies_NewBusinessTransactionV1[];

  /**  */
  EndorsementTransactions?: Amwins_Orbits_Contracts_Models_v1_Policies_EndorsementTransactionV1[];

  /**  */
  CancellationTransactions?: Amwins_Orbits_Contracts_Models_v1_Policies_CancellationTransactionV1[];

  /**  */
  ReinstatementTransactions?: Amwins_Orbits_Contracts_Models_v1_Policies_ReinstatementTransactionV1[];

  /**  */
  StateSpecificIdentifier?: string;

  /**  */
  AmlinkIdentifiers?: Amwins_Orbits_Contracts_Models_v1_Policies_AmlinkIdentifiers;

  /**  */
  Pricing?: Amwins_Orbits_Contracts_Models_v1_Quotes_PricingV1;

  /**  */
  Insured?: Amwins_Orbits_Contracts_Models_v1_InsuredInformation_InsuredV1;

  /**  */
  Risk?: Amwins_Orbits_Contracts_Models_v1_RiskInformation_RiskV1;

  /**  */
  Coverages?: Amwins_Orbits_Contracts_Models_v1_CoverageInformation_CoveragesV1;

  /**  */
  RequestedCoverages?: Amwins_Orbits_Contracts_Models_v1_CoverageInformation_CoveragesV1;

  /**  */
  PricingStatus?: Amwins_Orbits_Contracts_Models_Quotes_PricingStatus;

  /**  */
  QuoteEligibilityStatus?: Amwins_Orbits_Contracts_Models_Quotes_QuoteEligibilityStatus;

  /**  */
  PlacementStatus?: Amwins_Orbits_Contracts_Models_Quotes_PlacementStatus;

  /**  */
  QuoteExperationDate?: Date;

  /**  */
  QuoteDocuments?: Amwins_Orbits_Contracts_Models_v1_Quotes_QuoteDocumentsV1;
}

export interface Amwins_Orbits_Contracts_Models_v1_Policies_ReinstatementTransactionV1 {
  /**  */
  Id?: string;

  /**  */
  TransactionNumber?: number;

  /**  */
  TransactionType?: Amwins_Orbits_Contracts_Models_Policies_TransactionType;

  /**  */
  EffectiveDate?: Date;

  /**  */
  TransactionDate?: Date;

  /**  */
  Risk?: Amwins_Orbits_Contracts_Models_v1_RiskInformation_RiskV1;

  /**  */
  Coverages?: Amwins_Orbits_Contracts_Models_v1_CoverageInformation_CoveragesV1;

  /**  */
  Insured?: Amwins_Orbits_Contracts_Models_v1_InsuredInformation_InsuredV1;

  /**  */
  TransactionPricing?: Amwins_Orbits_Contracts_Models_v1_Policies_PolicyPricing;

  /**  */
  AnnualPricing?: Amwins_Orbits_Contracts_Models_v1_Policies_PolicyPricing;

  /**  */
  ReinstatementDocument?: Amwins_Orbits_Contracts_Models_Files_FileReference;

  /**  */
  TransactionReason?: string;

  /**  */
  TransactionEffectiveDate?: Date;
}

export interface Amwins_Orbits_Contracts_Models_v1_PolicyCancellations_CancellationReason {
  /**  */
  Value?: string;

  /**  */
  Id?: string;
}

export interface Amwins_Orbits_Contracts_Models_v1_PolicyCancellations_CancellationSubReason {
  /**  */
  Value?: string;

  /**  */
  Id?: string;
}

export interface Amwins_Orbits_Contracts_Models_v1_Quotes_PricingV1 {
  /**  */
  AnnualPremium?: number;

  /**  */
  Fees?: number;

  /**  */
  Assessments?: Amwins_Orbits_Contracts_Models_v1_Taxes_Assessment[];
}

export interface Amwins_Orbits_Contracts_Models_v1_Quotes_QuoteDocumentsV1 {
  /**  */
  QuoteApplication?: Amwins_Orbits_Contracts_Models_Files_FileReference;

  /**  */
  FormBundle?: Amwins_Orbits_Contracts_Models_Files_FileReference;

  /**  */
  QuoteEstimate?: Amwins_Orbits_Contracts_Models_Files_FileReference;
}

export interface Amwins_Orbits_Contracts_Models_v1_Quotes_QuoteV1 {
  /**  */
  Header?: Amwins_Orbits_Contracts_MessageContracts_Quotes_QuoteHeaderV1;

  /**  */
  Pricing?: Amwins_Orbits_Contracts_Models_v1_Quotes_PricingV1;

  /**  */
  Insured?: Amwins_Orbits_Contracts_Models_v1_InsuredInformation_InsuredV1;

  /**  */
  Risk?: Amwins_Orbits_Contracts_Models_v1_RiskInformation_RiskV1;

  /**  */
  Coverages?: Amwins_Orbits_Contracts_Models_v1_CoverageInformation_CoveragesV1;

  /**  */
  RequestedCoverages?: Amwins_Orbits_Contracts_Models_v1_CoverageInformation_CoveragesV1;

  /**  */
  PricingStatus?: Amwins_Orbits_Contracts_Models_Quotes_PricingStatus;

  /**  */
  QuoteEligibilityStatus?: Amwins_Orbits_Contracts_Models_Quotes_QuoteEligibilityStatus;

  /**  */
  PlacementStatus?: Amwins_Orbits_Contracts_Models_Quotes_PlacementStatus;

  /**  */
  LastUpdated?: Date;

  /**  */
  QuoteExperationDate?: Date;

  /**  */
  QuoteDocuments?: Amwins_Orbits_Contracts_Models_v1_Quotes_QuoteDocumentsV1;
}

export interface Amwins_Orbits_Contracts_Models_v1_RiskInformation_AttachedGarageType {
  /**  */
  Value?: string;

  /**  */
  Id?: string;
}

export interface Amwins_Orbits_Contracts_Models_v1_RiskInformation_BasementType {
  /**  */
  Value?: string;

  /**  */
  Id?: string;
}

export interface Amwins_Orbits_Contracts_Models_v1_RiskInformation_BuildingOverWaterType {
  /**  */
  Value?: string;

  /**  */
  Id?: string;
}

export interface Amwins_Orbits_Contracts_Models_v1_RiskInformation_BuildingType {
  /**  */
  Value?: string;

  /**  */
  Id?: string;
}

export interface Amwins_Orbits_Contracts_Models_v1_RiskInformation_BuildingV1 {
  /**  */
  BuildingType?: Amwins_Orbits_Contracts_Models_v1_RiskInformation_BuildingType;

  /**  */
  RiskAddress?: Amwins_Orbits_Contracts_Models_v1_AddressV1;

  /**  */
  OccupancyType?: Amwins_Orbits_Contracts_Models_v1_RiskInformation_OccupancyType;

  /**  */
  MonthsOccupied?: Amwins_Orbits_Contracts_Models_v1_RiskInformation_MonthsOccupiedV1;

  /**  */
  ConstructionType?: Amwins_Orbits_Contracts_Models_v1_RiskInformation_ConstructionType;

  /**  */
  FoundationType?: Amwins_Orbits_Contracts_Models_v1_RiskInformation_FoundationType;

  /**  */
  BasementType?: Amwins_Orbits_Contracts_Models_v1_RiskInformation_BasementType;

  /**  */
  ElevatedEnclosureType?: Amwins_Orbits_Contracts_Models_v1_RiskInformation_ElevatedEnclosureType;

  /**  */
  NumberOfStories?: Amwins_Orbits_Contracts_Models_v1_RiskInformation_NumberOfStoriesV1;

  /**  */
  SquareFootage?: Amwins_Orbits_Contracts_Models_v1_RiskInformation_SquareFootageV1;

  /**  */
  YearBuilt?: Amwins_Orbits_Contracts_Models_v1_RiskInformation_YearBuiltV1;

  /**  */
  AttachedGarageType?: Amwins_Orbits_Contracts_Models_v1_RiskInformation_AttachedGarageType;

  /**  */
  BuildingOverWaterType?: Amwins_Orbits_Contracts_Models_v1_RiskInformation_BuildingOverWaterType;

  /**  */
  FinishedFirstFloorElevation?: Amwins_Orbits_Contracts_Models_v1_RiskInformation_FinishedFirstFloorElevationV1;

  /**  */
  FloodZone?: Amwins_Orbits_Contracts_Models_v1_RiskInformation_FloodZone;

  /**  */
  ElevationHeight?: Amwins_Orbits_Contracts_Models_v1_RiskInformation_ElevationHeightV1;

  /**  */
  FEMABaseFloodElevation?: Amwins_Orbits_Contracts_Models_v1_RiskInformation_FEMABaseFloodElevationV1;

  /**  */
  DistanceToNearestWater?: Amwins_Orbits_Contracts_Models_v1_RiskInformation_DistanceToNearestWaterV1;

  /**  */
  DistanceToCoast?: Amwins_Orbits_Contracts_Models_v1_RiskInformation_DistanceToCoastV1;

  /**  */
  DistanceToFloodSourceAvailable?: Amwins_Orbits_Contracts_Models_v1_RiskInformation_DistanceToFloodSourceAvailableV1;

  /**  */
  ElevationCertificateAvailable?: Amwins_Orbits_Contracts_Models_v1_RiskInformation_ElevationCertificateAvailableV1;

  /**  */
  HighestFinishedGradeNextToBuilding?: Amwins_Orbits_Contracts_Models_v1_RiskInformation_HighestFinishedGradeNextToBuildingV1;

  /**  */
  LowestOccupiedFloor?: Amwins_Orbits_Contracts_Models_v1_RiskInformation_LowestOccupiedFloor;

  /**  */
  BuildingValuation?: Amwins_Orbits_Contracts_Models_v1_RiskInformation_BuildingValuation;

  /**  */
  ContentsValuation?: Amwins_Orbits_Contracts_Models_v1_RiskInformation_ContentsValuation;

  /**  */
  CrawlspaceCompletionStatus?: Amwins_Orbits_Contracts_Models_v1_RiskInformation_CrawlspaceCompletionStatus;

  /**  */
  NumberOfStepsV1?: Amwins_Orbits_Contracts_Models_v1_RiskInformation_NumberOfStepsV1;

  /**  */
  ValuationRCV?: Amwins_Orbits_Contracts_Models_v1_RiskInformation_ValuationRCV;

  /**  */
  ValuationACV?: Amwins_Orbits_Contracts_Models_v1_RiskInformation_ValuationACV;

  /**  */
  DistanceToCoastLine?: Amwins_Orbits_Contracts_Models_v1_RiskInformation_DistanceAttributeV1;

  /**  */
  DistanceToStreamOrRiver?: Amwins_Orbits_Contracts_Models_v1_RiskInformation_DistanceAttributeV1;

  /**  */
  IsNFIPSevereRepetitiveLoss?: boolean;
}

export interface Amwins_Orbits_Contracts_Models_v1_RiskInformation_BuildingValuation {
  /**  */
  ValuationAmount?: number;

  /**  */
  ValuationType?: Amwins_Orbits_Contracts_Models_v1_RiskInformation_ValuationType;
}

export interface Amwins_Orbits_Contracts_Models_v1_RiskInformation_ConstructionType {
  /**  */
  Value?: string;

  /**  */
  Id?: string;
}

export interface Amwins_Orbits_Contracts_Models_v1_RiskInformation_ContentsValuation {
  /**  */
  ValuationAmount?: number;

  /**  */
  ValuationType?: Amwins_Orbits_Contracts_Models_v1_RiskInformation_ValuationType;
}

export interface Amwins_Orbits_Contracts_Models_v1_RiskInformation_CoreLogicValuationReportV1 {
  /**  */
  Url?: string;

  /**  */
  ContainerName?: string;

  /**  */
  FileName?: string;
}

export interface Amwins_Orbits_Contracts_Models_v1_RiskInformation_CrawlspaceCompletionStatus {
  /**  */
  Value?: string;

  /**  */
  Id?: string;
}

export interface Amwins_Orbits_Contracts_Models_v1_RiskInformation_DistanceAttributeV1 {
  /**  */
  Distance?: number;
}

export interface Amwins_Orbits_Contracts_Models_v1_RiskInformation_DistanceToCoastV1 {
  /**  */
  Distance?: number;
}

export interface Amwins_Orbits_Contracts_Models_v1_RiskInformation_DistanceToFloodSourceAvailableV1 {
  /**  */
  IsDistanceToFloodSourceAvailable?: boolean;
}

export interface Amwins_Orbits_Contracts_Models_v1_RiskInformation_DistanceToNearestWaterV1 {
  /**  */
  Distance?: number;
}

export interface Amwins_Orbits_Contracts_Models_v1_RiskInformation_ElevatedEnclosureType {
  /**  */
  Value?: string;

  /**  */
  Id?: string;
}

export interface Amwins_Orbits_Contracts_Models_v1_RiskInformation_ElevationCertificateAvailableV1 {
  /**  */
  IsElevationCertificateAvailable?: boolean;
}

export interface Amwins_Orbits_Contracts_Models_v1_RiskInformation_ElevationHeightV1 {
  /**  */
  Elevation?: number;
}

export interface Amwins_Orbits_Contracts_Models_v1_RiskInformation_FEMABaseFloodElevationV1 {
  /**  */
  Elevation?: number;
}

export interface Amwins_Orbits_Contracts_Models_v1_RiskInformation_FinishedFirstFloorElevationV1 {
  /**  */
  Elevation?: number;
}

export interface Amwins_Orbits_Contracts_Models_v1_RiskInformation_FloodZone {
  /**  */
  Value?: string;

  /**  */
  Id?: string;
}

export interface Amwins_Orbits_Contracts_Models_v1_RiskInformation_FoundationType {
  /**  */
  Value?: string;

  /**  */
  Id?: string;
}

export interface Amwins_Orbits_Contracts_Models_v1_RiskInformation_HighestFinishedGradeNextToBuildingV1 {
  /**  */
  Elevation?: number;
}

export interface Amwins_Orbits_Contracts_Models_v1_RiskInformation_LocationV1 {
  /**  */
  Building?: Amwins_Orbits_Contracts_Models_v1_RiskInformation_BuildingV1;
}

export interface Amwins_Orbits_Contracts_Models_v1_RiskInformation_LowestOccupiedFloor {
  /**  */
  Value?: string;

  /**  */
  Id?: string;
}

export interface Amwins_Orbits_Contracts_Models_v1_RiskInformation_MonthsOccupiedV1 {
  /**  */
  NumberOfMonths?: number;
}

export interface Amwins_Orbits_Contracts_Models_v1_RiskInformation_NumberOfStepsV1 {
  /**  */
  NumberOfSteps?: number;
}

export interface Amwins_Orbits_Contracts_Models_v1_RiskInformation_NumberOfStoriesV1 {
  /**  */
  NumOfStories?: number;
}

export interface Amwins_Orbits_Contracts_Models_v1_RiskInformation_OccupancyType {
  /**  */
  Value?: string;

  /**  */
  Id?: string;
}

export interface Amwins_Orbits_Contracts_Models_v1_RiskInformation_RiskV1 {
  /**  */
  Location?: Amwins_Orbits_Contracts_Models_v1_RiskInformation_LocationV1;
}

export interface Amwins_Orbits_Contracts_Models_v1_RiskInformation_SquareFootageV1 {
  /**  */
  SqrFootage?: number;
}

export interface Amwins_Orbits_Contracts_Models_v1_RiskInformation_ValuationACV {
  /**  */
  Valuation?: number;
}

export interface Amwins_Orbits_Contracts_Models_v1_RiskInformation_ValuationRCV {
  /**  */
  Valuation?: number;
}

export interface Amwins_Orbits_Contracts_Models_v1_RiskInformation_ValuationType {
  /**  */
  Value?: string;

  /**  */
  Id?: string;
}

export interface Amwins_Orbits_Contracts_Models_v1_RiskInformation_YearBuiltV1 {
  /**  */
  Year?: number;
}

export interface Amwins_Orbits_Contracts_Models_v1_Taxes_Assessment {
  /**  */
  Name?: string;

  /**  */
  Amount?: number;

  /**  */
  AssessmentType?: string;

  /**  */
  Classification?: Amwins_Orbits_Contracts_Models_v1_Taxes_AssessmentClassificationV1;

  /**  */
  Rate?: number;
}

export interface Amwins_Orbits_Contracts_Models_v1_Taxes_AssessmentClassificationV1 {
  /**  */
  Value?: string;

  /**  */
  Id?: string;
}

export interface Amwins_Orbits_Contracts_Models_v2_CoverageModelType {
  /**  */
  Value?: string;

  /**  */
  Id?: string;
}

export interface Amwins_Orbits_Contracts_Models_v2_Endorsements_EndorsementV2_System_Nullable_System_Text_Json_JsonElement__System_Nullable_System_Text_Json_JsonElement {
  /**  */
  EndorsementId?: string;

  /**  */
  PolicyId?: string;

  /**  */
  EndorsementEffectiveDate?: Date;

  /**  */
  Status?: Amwins_Orbits_Contracts_Models_Endorsements_EndorsementStatus;

  /**  */
  LastUpdated?: Date;

  /**  */
  EndorsementType?: Amwins_Orbits_Contracts_Models_Endorsements_EndorsementType;

  /**  */
  CurrentInsured?: Amwins_Orbits_Contracts_Models_v2_InsuredInformation_InsuredV2;

  /**  */
  UpdatedInsured?: Amwins_Orbits_Contracts_Models_v2_InsuredInformation_InsuredV2;

  /**  */
  CurrentRisk?: System_Text_Json_JsonElement;

  /**  */
  UpdatedRisk?: System_Text_Json_JsonElement;

  /**  */
  RiskModelType?: Amwins_Orbits_Contracts_Models_v2_RiskModelType;

  /**  */
  CurrentCoverages?: System_Text_Json_JsonElement;

  /**  */
  UpdatedCoverages?: System_Text_Json_JsonElement;

  /**  */
  CoverageModelType?: Amwins_Orbits_Contracts_Models_v2_CoverageModelType;

  /**  */
  AnnualPricing?: Amwins_Orbits_Contracts_Models_v2_PricingV2;

  /**  */
  TransactionPricing?: Amwins_Orbits_Contracts_Models_v2_PricingV2;

  /**  */
  CurrentPrimaryMortgagee?: Amwins_Orbits_Contracts_Models_AdditionalInterestInformation_Mortgagee;

  /**  */
  UpdatedPrimaryMortgagee?: Amwins_Orbits_Contracts_Models_AdditionalInterestInformation_Mortgagee;

  /**  */
  CurrentSecondaryMortgagee?: Amwins_Orbits_Contracts_Models_AdditionalInterestInformation_Mortgagee;

  /**  */
  UpdatedSecondaryMortgagee?: Amwins_Orbits_Contracts_Models_AdditionalInterestInformation_Mortgagee;

  /**  */
  EndorsementQuoteDocument?: Amwins_Orbits_Contracts_Models_Files_FileReference;

  /**  */
  ExternalPolicyId?: string;

  /**  */
  SalesChannel?: Amwins_Orbits_Contracts_Models_SalesChannels_SalesChannel;

  /**  */
  SubmissionNumber?: string;

  /**  */
  CurrentAdditionalInterests?: Amwins_Orbits_Contracts_Models_AdditionalInterestInformation_AdditionalInterest[];

  /**  */
  UpdatedAdditionalInterests?: Amwins_Orbits_Contracts_Models_AdditionalInterestInformation_AdditionalInterest[];

  /**  */
  RejectionReason?: string;

  /**  */
  ChangedElements?: Amwins_Orbits_Contracts_Models_ChangedElement[];
}

export interface Amwins_Orbits_Contracts_Models_v2_FeeClassificationV2 {
  /**  */
  Value?: string;

  /**  */
  Id?: string;
}

export interface Amwins_Orbits_Contracts_Models_v2_FeeV2 {
  /**  */
  Classification?: Amwins_Orbits_Contracts_Models_v2_FeeClassificationV2;

  /**  */
  Amount?: number;

  /**  */
  InspectionProvider?: Amwins_Orbits_Contracts_Models_Inspections_InspectionProvider;

  /**  */
  ServiceFee?: Amwins_Orbits_Contracts_Models_v2_ServiceFee;
}

export interface Amwins_Orbits_Contracts_Models_v2_InsuredInformation_InsuredV2 {
  /**  */
  MailingAddress?: Amwins_Orbits_Contracts_Models_v1_InsuredInformation_MailingAddressV1;

  /**  */
  PrimaryInsured?: Amwins_Orbits_Contracts_Models_v1_InsuredInformation_PersonV1;

  /**  */
  SecondaryInsured?: Amwins_Orbits_Contracts_Models_v1_InsuredInformation_PersonV1;

  /**  */
  ConsumerCreditDisclosure?: Amwins_Orbits_Contracts_Models_v1_InsuredInformation_ConsumerCreditDisclosureV1;

  /**  */
  InsuredEmailOptions?: Amwins_Orbits_Contracts_Models_Payments_InsuredEmailOptions;

  /**  */
  Claims?: Amwins_Orbits_Contracts_Models_v1_InsuredInformation_ClaimV1[];
}

export interface Amwins_Orbits_Contracts_Models_v2_Policies_CancellationTransactionV2_System_Nullable_System_Text_Json_JsonElement__System_Nullable_System_Text_Json_JsonElement {
  /**  */
  Id?: string;

  /**  */
  TransactionNumber?: number;

  /**  */
  TransactionType?: Amwins_Orbits_Contracts_Models_Policies_TransactionType;

  /**  */
  EffectiveDate?: Date;

  /**  */
  TransactionDate?: Date;

  /**  */
  Risk?: System_Text_Json_JsonElement;

  /**  */
  Coverages?: System_Text_Json_JsonElement;

  /**  */
  Insured?: Amwins_Orbits_Contracts_Models_v2_InsuredInformation_InsuredV2;

  /**  */
  TransactionPricing?: Amwins_Orbits_Contracts_Models_v2_PricingV2;

  /**  */
  AnnualPricing?: Amwins_Orbits_Contracts_Models_v2_PricingV2;

  /**  */
  CancellationDocument?: Amwins_Orbits_Contracts_Models_Files_FileReference;
}

export interface Amwins_Orbits_Contracts_Models_v2_Policies_EndorsementTransactionV2_System_Nullable_System_Text_Json_JsonElement__System_Nullable_System_Text_Json_JsonElement {
  /**  */
  Id?: string;

  /**  */
  TransactionNumber?: number;

  /**  */
  TransactionType?: Amwins_Orbits_Contracts_Models_Policies_TransactionType;

  /**  */
  TransactionDate?: Date;

  /**  */
  EffectiveDate?: Date;

  /**  */
  Risk?: System_Text_Json_JsonElement;

  /**  */
  PriorCoverages?: System_Text_Json_JsonElement;

  /**  */
  Coverages?: System_Text_Json_JsonElement;

  /**  */
  Insured?: Amwins_Orbits_Contracts_Models_v2_InsuredInformation_InsuredV2;

  /**  */
  PrimaryMortgagee?: Amwins_Orbits_Contracts_Models_AdditionalInterestInformation_Mortgagee;

  /**  */
  SecondaryMortgagee?: Amwins_Orbits_Contracts_Models_AdditionalInterestInformation_Mortgagee;

  /**  */
  EndorsementQuoteDocument?: Amwins_Orbits_Contracts_Models_Files_FileReference;

  /**  */
  TransactionPricing?: Amwins_Orbits_Contracts_Models_v2_PricingV2;

  /**  */
  AnnualPricing?: Amwins_Orbits_Contracts_Models_v2_PricingV2;

  /**  */
  AmendedPolicyDecDocument?: Amwins_Orbits_Contracts_Models_Files_FileReference;
}

export interface Amwins_Orbits_Contracts_Models_v2_Policies_NewBusinessTransactionV2_System_Nullable_System_Text_Json_JsonElement__System_Nullable_System_Text_Json_JsonElement {
  /**  */
  Id?: string;

  /**  */
  TransactionNumber?: number;

  /**  */
  TransactionType?: Amwins_Orbits_Contracts_Models_Policies_TransactionType;

  /**  */
  EffectiveDate?: Date;

  /**  */
  TransactionDate?: Date;

  /**  */
  Risk?: System_Text_Json_JsonElement;

  /**  */
  Coverages?: System_Text_Json_JsonElement;

  /**  */
  Insured?: Amwins_Orbits_Contracts_Models_v2_InsuredInformation_InsuredV2;

  /**  */
  TransactionPricing?: Amwins_Orbits_Contracts_Models_v2_PricingV2;

  /**  */
  AnnualPricing?: Amwins_Orbits_Contracts_Models_v2_PricingV2;

  /**  */
  QuoteApplication?: Amwins_Orbits_Contracts_Models_Files_FileReference;

  /**  */
  PolicyDocument?: Amwins_Orbits_Contracts_Models_Files_FileReference;

  /**  */
  StateCompliance?: Amwins_Orbits_Contracts_Models_Files_FileReference;

  /**  */
  DeclarationsPage?: Amwins_Orbits_Contracts_Models_Files_FileReference;
}

export interface Amwins_Orbits_Contracts_Models_v2_Policies_PolicyDocumentsV2 {
  /**  */
  Application?: Amwins_Orbits_Contracts_Models_Files_FileReference;

  /**  */
  Policy?: Amwins_Orbits_Contracts_Models_Files_FileReference;

  /**  */
  StateCompliance?: Amwins_Orbits_Contracts_Models_Files_FileReference;

  /**  */
  DeclarationsPage?: Amwins_Orbits_Contracts_Models_Files_FileReference;
}

export interface Amwins_Orbits_Contracts_Models_v2_Policies_PolicyV2_System_Nullable_System_Text_Json_JsonElement__System_Nullable_System_Text_Json_JsonElement {
  /**  */
  AnnualizedPricing?: Amwins_Orbits_Contracts_Models_v2_PricingV2;

  /**  */
  Status?: Amwins_Orbits_Contracts_Models_v1_Policies_PolicyStatus;

  /**  */
  BindingStatus?: Amwins_Orbits_Contracts_Models_v1_Policies_BindingStatus;

  /**  */
  LastUpdated?: Date;

  /**  */
  PolicyDocuments?: Amwins_Orbits_Contracts_Models_v2_Policies_PolicyDocumentsV2;

  /**  */
  PaymentSource?: Amwins_Orbits_Contracts_MessageContracts_Quotes_PaymentSource;

  /**  */
  PaymentOption?: Amwins_Orbits_Contracts_Models_Payments_PaymentOption;

  /**  */
  PrimaryMortgagee?: Amwins_Orbits_Contracts_Models_AdditionalInterestInformation_Mortgagee;

  /**  */
  SecondaryMortgagee?: Amwins_Orbits_Contracts_Models_AdditionalInterestInformation_Mortgagee;

  /**  */
  Insured?: Amwins_Orbits_Contracts_Models_v2_InsuredInformation_InsuredV2;

  /**  */
  StateSpecificIdentifier?: string;

  /**  */
  AmlinkIdentifiers?: Amwins_Orbits_Contracts_Models_v1_Policies_AmlinkIdentifiers;

  /**  */
  HasOpenInspection?: boolean;

  /**  */
  InspectionCompleteDate?: Date;

  /**  */
  ExternalPolicyId?: string;

  /**  */
  PolicyId?: string;

  /**  */
  PolicyNumber?: string;

  /**  */
  BindTime?: Date;

  /**  */
  TotalInsuredValue?: number;

  /**  */
  FormType?: Amwins_Orbits_Contracts_Models_FormType;

  /**  */
  EffectiveDate?: Date;

  /**  */
  ExpirationDate?: Date;

  /**  */
  InspectionContactPhone?: string;

  /**  */
  AdditionalInterests?: Amwins_Orbits_Contracts_Models_AdditionalInterestInformation_AdditionalInterest[];

  /**  */
  NewBusinessTransactions?: Amwins_Orbits_Contracts_Models_v2_Policies_NewBusinessTransactionV2_System_Nullable_System_Text_Json_JsonElement__System_Nullable_System_Text_Json_JsonElement[];

  /**  */
  EndorsementTransactions?: Amwins_Orbits_Contracts_Models_v2_Policies_EndorsementTransactionV2_System_Nullable_System_Text_Json_JsonElement__System_Nullable_System_Text_Json_JsonElement[];

  /**  */
  CancellationTransactions?: Amwins_Orbits_Contracts_Models_v2_Policies_CancellationTransactionV2_System_Nullable_System_Text_Json_JsonElement__System_Nullable_System_Text_Json_JsonElement[];

  /**  */
  ReinstatementTransactions?: Amwins_Orbits_Contracts_Models_v2_Policies_ReinstatementTransactionV2_System_Nullable_System_Text_Json_JsonElement__System_Nullable_System_Text_Json_JsonElement[];

  /**  */
  Pricing?: Amwins_Orbits_Contracts_Models_v2_PricingV2;

  /**  */
  QuoteExpirationDate?: Date;

  /**  */
  RiskModelType?: Amwins_Orbits_Contracts_Models_v2_RiskModelType;

  /**  */
  Risk?: System_Text_Json_JsonElement;

  /**  */
  CoverageModelType?: Amwins_Orbits_Contracts_Models_v2_CoverageModelType;

  /**  */
  Coverages?: System_Text_Json_JsonElement;

  /**  */
  RequestedCoverageModelType?: Amwins_Orbits_Contracts_Models_v2_CoverageModelType;

  /**  */
  RequestedCoverages?: System_Text_Json_JsonElement;

  /**  */
  PricingStatus?: Amwins_Orbits_Contracts_Models_Quotes_PricingStatus;

  /**  */
  QuoteEligibilityStatus?: Amwins_Orbits_Contracts_Models_Quotes_QuoteEligibilityStatus;

  /**  */
  DeclinationReasons?: string[];

  /**  */
  QuoteBindRequirements?: Amwins_Orbits_Contracts_Models_v2_Quotes_QuoteBindRequirementsV2;

  /**  */
  PlacementStatus?: Amwins_Orbits_Contracts_Models_Quotes_PlacementStatus;

  /**  */
  QuoteDocuments?: Amwins_Orbits_Contracts_Models_v2_Quotes_QuoteDocumentsV2;

  /**  */
  QuoteId?: string;

  /**  */
  QuoteGroupingId?: string;

  /**  */
  SalesChannel?: Amwins_Orbits_Contracts_Models_SalesChannels_SalesChannel;

  /**  */
  SubmissionId?: string;

  /**  */
  SubmissionNumber?: string;

  /**  */
  State?: string;

  /**  */
  ProductType?: Amwins_Orbits_Contracts_Models_ProductType;

  /**  */
  TermBegin?: Date;

  /**  */
  TermEnd?: Date;

  /**  */
  QuoteRequested?: Date;

  /**  */
  ExternalQuoteId?: string;

  /**  */
  Predecessors?: Amwins_Orbits_Contracts_MessageContracts_v2_Predecessor[];
}

export interface Amwins_Orbits_Contracts_Models_v2_Policies_ReinstatementTransactionV2_System_Nullable_System_Text_Json_JsonElement__System_Nullable_System_Text_Json_JsonElement {
  /**  */
  Id?: string;

  /**  */
  TransactionNumber?: number;

  /**  */
  TransactionType?: Amwins_Orbits_Contracts_Models_Policies_TransactionType;

  /**  */
  EffectiveDate?: Date;

  /**  */
  TransactionDate?: Date;

  /**  */
  Risk?: System_Text_Json_JsonElement;

  /**  */
  Coverages?: System_Text_Json_JsonElement;

  /**  */
  Insured?: Amwins_Orbits_Contracts_Models_v2_InsuredInformation_InsuredV2;

  /**  */
  TransactionPricing?: Amwins_Orbits_Contracts_Models_v2_PricingV2;

  /**  */
  AnnualPricing?: Amwins_Orbits_Contracts_Models_v2_PricingV2;

  /**  */
  ReinstatementDocument?: Amwins_Orbits_Contracts_Models_Files_FileReference;
}

export interface Amwins_Orbits_Contracts_Models_v2_PricingV2 {
  /**  */
  Premium?: number;

  /**  */
  Fees?: Amwins_Orbits_Contracts_Models_v2_FeeV2[];

  /**  */
  Assessments?: Amwins_Orbits_Contracts_Models_v1_Taxes_Assessment[];
}

export interface Amwins_Orbits_Contracts_Models_v2_Quotes_QuoteBindRequirementsV2 {
  /**  */
  RequiresInspection?: boolean;

  /**  */
  InspectionType?: Amwins_Orbits_Contracts_Models_Inspections_InspectionType;
}

export interface Amwins_Orbits_Contracts_Models_v2_Quotes_QuoteDocumentsV2 {
  /**  */
  QuoteApplication?: Amwins_Orbits_Contracts_Models_Files_FileReference;

  /**  */
  FormBundle?: Amwins_Orbits_Contracts_Models_Files_FileReference;
}

export interface Amwins_Orbits_Contracts_Models_v2_Quotes_QuoteV2_System_Nullable_System_Text_Json_JsonElement__System_Nullable_System_Text_Json_JsonElement {
  /**  */
  Pricing?: Amwins_Orbits_Contracts_Models_v2_PricingV2;

  /**  */
  QuoteExpirationDate?: Date;

  /**  */
  Insured?: Amwins_Orbits_Contracts_Models_v2_InsuredInformation_InsuredV2;

  /**  */
  RiskModelType?: Amwins_Orbits_Contracts_Models_v2_RiskModelType;

  /**  */
  Risk?: System_Text_Json_JsonElement;

  /**  */
  CoverageModelType?: Amwins_Orbits_Contracts_Models_v2_CoverageModelType;

  /**  */
  Coverages?: System_Text_Json_JsonElement;

  /**  */
  RequestedCoverageModelType?: Amwins_Orbits_Contracts_Models_v2_CoverageModelType;

  /**  */
  RequestedCoverages?: System_Text_Json_JsonElement;

  /**  */
  PricingStatus?: Amwins_Orbits_Contracts_Models_Quotes_PricingStatus;

  /**  */
  QuoteEligibilityStatus?: Amwins_Orbits_Contracts_Models_Quotes_QuoteEligibilityStatus;

  /**  */
  DeclinationReasons?: string[];

  /**  */
  QuoteBindRequirements?: Amwins_Orbits_Contracts_Models_v2_Quotes_QuoteBindRequirementsV2;

  /**  */
  PlacementStatus?: Amwins_Orbits_Contracts_Models_Quotes_PlacementStatus;

  /**  */
  QuoteDocuments?: Amwins_Orbits_Contracts_Models_v2_Quotes_QuoteDocumentsV2;

  /**  */
  LastUpdated?: Date;

  /**  */
  QuoteId?: string;

  /**  */
  QuoteGroupingId?: string;

  /**  */
  SalesChannel?: Amwins_Orbits_Contracts_Models_SalesChannels_SalesChannel;

  /**  */
  SubmissionId?: string;

  /**  */
  SubmissionNumber?: string;

  /**  */
  State?: string;

  /**  */
  ProductType?: Amwins_Orbits_Contracts_Models_ProductType;

  /**  */
  TermBegin?: Date;

  /**  */
  TermEnd?: Date;

  /**  */
  QuoteRequested?: Date;

  /**  */
  ExternalQuoteId?: string;

  /**  */
  Predecessors?: Amwins_Orbits_Contracts_MessageContracts_v2_Predecessor[];
}

export interface Amwins_Orbits_Contracts_Models_v2_RiskModelType {
  /**  */
  Value?: string;

  /**  */
  Id?: string;
}

export interface Amwins_Orbits_Contracts_Models_v2_ServiceFee {
  /**  */
  TotalAmount?: number;

  /**  */
  Default?: number;

  /**  */
  Min?: number;

  /**  */
  Max?: number;

  /**  */
  Note?: string;
}

export interface Amwins_PersonalLines_Moratorium_AdminUI_Generated_Area {
  /**  */
  states?: string[];

  /**  */
  postalCodes?: string[];

  /**  */
  countyFIPS?: string[];
}

export interface Amwins_PersonalLines_Moratorium_AdminUI_Generated_Confirmation {
  /**  */
  confirmationCode?: string;

  /**  */
  moratoriums?: Amwins_PersonalLines_Moratorium_AdminUI_Generated_Moratorium[];

  /**  */
  moratoriumCheckRequest?: Amwins_PersonalLines_Moratorium_AdminUI_Generated_MoratoriumCheckRequest;

  /**  */
  isUnderMoratorium?: boolean;

  /**  */
  createdOn?: Date;
}

export interface Amwins_PersonalLines_Moratorium_AdminUI_Generated_Moratorium {
  /**  */
  id?: number;

  /**  */
  externalId?: string;

  /**  */
  area?: Amwins_PersonalLines_Moratorium_AdminUI_Generated_Area;

  /**  */
  beginDate?: Date;

  /**  */
  endDate?: Date;

  /**  */
  perilTypes?: Amwins_PersonalLines_Moratorium_AdminUI_Generated_PerilType[];

  /**  */
  description?: string;

  /**  */
  active?: boolean;

  /**  */
  confirmations?: Amwins_PersonalLines_Moratorium_AdminUI_Generated_Confirmation[];
}

export interface Amwins_PersonalLines_Moratorium_AdminUI_Generated_MoratoriumCheckRequest {
  /**  */
  countyFIPS?: string;

  /**  */
  state?: string;

  /**  */
  postalCode?: string;

  /**  */
  termBeginDate?: Date;

  /**  */
  perilTypes?: Amwins_PersonalLines_Moratorium_AdminUI_Generated_PerilType[];
}

export interface Amwins_Portal_Api_ApiClients_Munich_Models_EndorsementReference {
  /**  */
  quoteEnquiryId?: string;

  /**  */
  finalGrossPremiumPerYear?: number;

  /**  */
  policyNumber?: string;
}

export interface Amwins_Portal_Api_ApiClients_Munich_Models_MunichClaim {
  /**  */
  claimDateOfLoss?: Date;

  /**  */
  claimIsOpen?: boolean;

  /**  */
  claimTotalIncurredAmount?: number;

  /**  */
  claimDateClosed?: Date;

  /**  */
  claimTypeOfLoss?: Amwins_Portal_Api_ApiClients_Munich_Models_MunichClaimTypeOfLoss;
}

export interface Amwins_Portal_Api_ApiClients_Munich_Models_MunichQuoteRequest {
  /**  */
  externalReferenceId?: string;

  /**  */
  policyClass?: Amwins_Portal_Api_ApiClients_Munich_Models_PolicyClass;

  /**  */
  policyEffectiveDate?: Date;

  /**  */
  policyExpirationDate?: Date;

  /**  */
  renewalReference?: Amwins_Portal_Api_ApiClients_Munich_Models_MunichRenewalReference;

  /**  */
  endorsementReference?: Amwins_Portal_Api_ApiClients_Munich_Models_EndorsementReference;

  /**  */
  locationAddressFullText?: string;

  /**  */
  locationAddressStreetNumber?: string;

  /**  */
  locationAddressStreet?: string;

  /**  */
  locationAddressCity?: string;

  /**  */
  locationAddressState?: string;

  /**  */
  locationAddressPostalCode?: string;

  /**  */
  locationAddressCountry?: string;

  /**  */
  locationAddressAdditional?: string;

  /**  */
  locationAddressLatitude?: number;

  /**  */
  locationAddressLongitude?: number;

  /**  */
  policyBankruptcyLast5Years?: boolean;

  /**  */
  policyRepossessionLast5Years?: boolean;

  /**  */
  policyForeclosureLast5Years?: boolean;

  /**  */
  policyCriminalHistoryLast5Years?: boolean;

  /**  */
  propertyConstructionType?: Amwins_Portal_Api_ApiClients_Munich_Models_PropertyConstructionType;

  /**  */
  propertyBuiltYear?: number;

  /**  */
  propertyNumberOfStories?: number;

  /**  */
  propertySquareFootage?: number;

  /**  */
  propertyRoofShapeType?: Amwins_Portal_Api_ApiClients_Munich_Models_PropertyRoofShapeType;

  /**  */
  propertyRoofConstructionType?: Amwins_Portal_Api_ApiClients_Munich_Models_PropertyRoofConstructionType;

  /**  */
  propertyRoofUpdatesYear?: number;

  /**  */
  propertyRoofUpdatesType?: Amwins_Portal_Api_ApiClients_Munich_Models_UpdateType;

  /**  */
  propertyRoofShowsSignsOfDeterioration?: boolean;

  /**  */
  propertyIsWiringTypeAcceptable?: boolean;

  /**  */
  propertyWiringUpdatesYear?: number;

  /**  */
  propertyWiringUpdatesType?: Amwins_Portal_Api_ApiClients_Munich_Models_UpdateType;

  /**  */
  propertyIsOnRegisterHistoricPlaces?: boolean;

  /**  */
  propertyIsNonCosmeticRenovations?: boolean;

  /**  */
  propertyIsPlumbingTypeInListOfIneligibleTypes?: boolean;

  /**  */
  propertyPlumbingUpdatesYear?: number;

  /**  */
  propertyPlumbingUpdatesType?: Amwins_Portal_Api_ApiClients_Munich_Models_UpdateType;

  /**  */
  propertyHeatingUpdatesYear?: number;

  /**  */
  propertyHeatingUpdatesType?: Amwins_Portal_Api_ApiClients_Munich_Models_UpdateType;

  /**  */
  propertyIsStudentHousing?: boolean;

  /**  */
  propertyIsOnPremiseBusiness?: boolean;

  /**  */
  propertyIsOnPremiseDaycare?: boolean;

  /**  */
  propertyIsFarmingOrOtherBusiness?: boolean;

  /**  */
  propertyProtectionClass?: Amwins_Portal_Api_ApiClients_Munich_Models_PropertyProtectionClass;

  /**  */
  propertyisHeatSourcePrimaryInListOfIneligibleTypes?: boolean;

  /**  */
  propertyHeatSourceAlternate?: Amwins_Portal_Api_ApiClients_Munich_Models_PropertyHeatSourceAlternate;

  /**  */
  propertyIsAcreageGtThreshold?: boolean;

  /**  */
  riskOccupancyType?: Amwins_Portal_Api_ApiClients_Munich_Models_RiskOccupancyType;

  /**  */
  riskIsPriorLossesLast3Years?: boolean;

  /**  */
  protectedDeviceFireCentral?: boolean;

  /**  */
  protectedDeviceBurglarCentral?: boolean;

  /**  */
  protectedDeviceSprinklers?: boolean;

  /**  */
  protectedDeviceGatedCommunity?: boolean;

  /**  */
  protectedDeviceStormShutters?: boolean;

  /**  */
  propertyIsSmokeDetector?: boolean;

  /**  */
  propertyIsElectricCircuitBreaker?: boolean;

  /**  */
  propertyTotalNumberOfClaims?: number;

  /**  */
  propertyTotalIncurredClaimsAmount?: number;

  /**  */
  propertyIsFireRelatedLosses?: boolean;

  /**  */
  propertyIsLiabilityRelatedLosses?: boolean;

  /**  */
  policyIsCoverageLapse?: boolean;

  /**  */
  coverageDwellingAmount?: number;

  /**  */
  coverageOtherStructuresAmount?: number;

  /**  */
  coveragePersonalPropertyAmount?: number;

  /**  */
  coverageLossOfUseAmount?: number;

  /**  */
  coverageLossOfRentAmount?: number;

  /**  */
  coveragePersonalLiabilityAmount?: number;

  /**  */
  coverageAOPDeductibleAmount?: number;

  /**  */
  coverageWind?: boolean;

  /**  */
  coverageWindDeductiblePercent?: number;

  /**  */
  coverageNamedWindDeductiblePercent?: number;

  /**  */
  coverageEarthquake?: boolean;

  /**  */
  coverageEarthquakeDeductiblePercent?: number;

  /**  */
  coveragePersonalInjuryAmount?: number;

  /**  */
  coverageIncreasedMedicalPaymentsAmount?: number;

  /**  */
  coverageIsIncreasedLawOrdinance?: boolean;

  /**  */
  coverageIncreasedLawOrdinanceLimitPercent?: number;

  /**  */
  coverageLawOrdinanceLimitPercent?: number;

  /**  */
  coverageIsExtendedReplacementCost?: boolean;

  /**  */
  coverageExtendedReplacementCostLimitPercent?: number;

  /**  */
  coverageLossAssessmentAmount?: number;

  /**  */
  coverageIsWaterDamage?: boolean;

  /**  */
  coverageWaterDamageDeductibleAmount?: number;

  /**  */
  coverageWaterDamageSublimitAmount?: number;

  /**  */
  coverageWaterSewageBackupAmount?: number;

  /**  */
  coverageMoldSublimitAmount?: number;

  /**  */
  coverageAnimalLiabilityAmount?: number;

  /**  */
  coverageIsVandalismMischief?: boolean;

  /**  */
  coverageResidenceBurglaryAmount?: number;

  /**  */
  coverageDwellingValuation?: Amwins_Portal_Api_ApiClients_Munich_Models_CoverageDwellingValuation;

  /**  */
  coveragePersonalPropertyValuation?: Amwins_Portal_Api_ApiClients_Munich_Models_CoveragePersonalPropertyValuation;

  /**  */
  coverageIsCoverageIfp?: boolean;

  /**  */
  coverageIfpLimitAmount?: number;

  /**  */
  coverageHo0498RefrigeratedPropertyLimitAmount?: number;

  /**  */
  coverageHo0465MoneyLimitAmount?: number;

  /**  */
  coverageHo0465SecuritiesLimitAmount?: number;

  /**  */
  coverageHo0465JeweleryLimitAmount?: number;

  /**  */
  coverageHo0465FireArmsLimitAmount?: number;

  /**  */
  coverageHo0465SilverwareLimitAmount?: number;

  /**  */
  coverageHo0465PortableElecLimitAmount?: number;

  /**  */
  coverageHo0472IsSpecialComputer?: boolean;

  /**  */
  coverageIsHomeSystemProtection?: boolean;

  /**  */
  coverageIsIdentityRecovery?: boolean;

  /**  */
  coverageIsServiceLine?: boolean;

  /**  */
  coverageHo2303JewelryLimitAmount?: number;

  /**  */
  coverageHo2303FursLimitAmount?: number;

  /**  */
  coverageHo2303CamerasLimitAmount?: number;

  /**  */
  coverageHo2303MusicalInstrumentsLimitAmount?: number;

  /**  */
  coverageHo2303SilverwareLimitAmount?: number;

  /**  */
  coverageHo2303GolfingEquipmentLimitAmount?: number;

  /**  */
  coverageHo2303FineArtsExclBreakageLimitAmount?: number;

  /**  */
  coverageHo2303FineArtsInclBreakageLimitAmount?: number;

  /**  */
  coverageHo2303StampCollections?: number;

  /**  */
  coverageHo2303CoinCollections?: number;

  /**  */
  propertyOpenClaims?: boolean;

  /**  */
  propertyCondoUnitFloor?: number;

  /**  */
  policyDomicileCountry?: string;

  /**  */
  propertyIsWaterRelatedLosses?: boolean;

  /**  */
  claims?: Amwins_Portal_Api_ApiClients_Munich_Models_MunichClaim[];

  /**  */
  policyInsuredType?: Amwins_Portal_Api_ApiClients_Munich_Models_PolicyInsuredType;
}

export interface Amwins_Portal_Api_ApiClients_Munich_Models_MunichRenewalReference {
  /**  */
  quoteEnquiryId?: string;

  /**  */
  finalGrossPremiumPerYear?: number;

  /**  */
  locationAddressFullText?: string;

  /**  */
  externalReferenceId?: string;

  /**  */
  expiringPolicyNumber?: string;
}

export interface Amwins_Portal_Api_Controllers_AmlinkSubmissionFileController_SubmissionFileIdRef {
  /**  */
  submissionFileId?: string;
}

export interface Amwins_Portal_Api_Controllers_DocumentController_StoredDocumentResponse {
  /**  */
  Url?: string;

  /**  */
  Expires?: Date;
}

export interface Amwins_Portal_Api_Controllers_ImpersonationRequest {
  /**  */
  ImpersonatorEmail?: string;

  /**  */
  ImpersonationEmail?: string;
}

export interface Amwins_Portal_Api_Controllers_IssuedClaimsObj {
  /**  */
  Email?: string;
}

export interface Amwins_Portal_Api_Controllers_QuotesController_RefineV2Message {
  /**  */
  TermBegin?: Date;

  /**  */
  TermEnd?: Date;

  /**  */
  CoverageModelType?: Amwins_Orbits_Contracts_Models_v2_CoverageModelType;

  /**  */
  RequestedCoverages?: System_Text_Json_JsonElement;

  /**  */
  RiskModelType?: Amwins_Orbits_Contracts_Models_v2_RiskModelType;

  /**  */
  Risk?: System_Text_Json_JsonElement;

  /**  */
  ExternalQuoteId?: string;

  /**  */
  Broker?: Amwins_Orbits_Contracts_Models_Producers_Broker;

  /**  */
  Retailer?: Amwins_Orbits_Contracts_Models_Producers_Retailer;

  /**  */
  QuoteId?: string;

  /**  */
  QuoteGroupingId?: string;

  /**  */
  SalesChannel?: Amwins_Orbits_Contracts_Models_SalesChannels_SalesChannel;

  /**  */
  ProductType?: Amwins_Orbits_Contracts_Models_ProductType;

  /**  */
  State?: string;

  /**  */
  SubmissionId?: string;

  /**  */
  SubmissionNumber?: string;

  /**  */
  Predecessors?: Amwins_Orbits_Contracts_MessageContracts_v2_Predecessor[];

  /**  */
  PredecessorMessageIds?: string[];

  /**  */
  InitiatingUser?: Amwins_Orbits_Contracts_Models_User;

  /**  */
  CreatedDate?: Date;

  /**  */
  MessageId?: string;

  /**  */
  PredecessorMessageId?: string;
}

export interface Amwins_Portal_Api_Controllers_RenewalOfferController_RenewalOfferRunRequest {
  /**  */
  policyKey?: string;

  /**  */
  begin?: Date;

  /**  */
  end?: Date;

  /**  */
  includeContractCodeList?: string;

  /**  */
  excludeContractCodeList?: string;

  /**  */
  ratePlanEffectiveDate?: Date;

  /**  */
  forceOverwrite?: boolean;

  /**  */
  recalc?: boolean;
}

export interface Amwins_Portal_Api_Controllers_UserInfoObj {
  /**  */
  Details?: string[];

  /**  */
  HasAccess?: boolean;

  /**  */
  Role?: string;

  /**  */
  Name?: string;
}

export interface Amwins_Portal_Api_Services_AggregateHealthChecks {
  /**  */
  Reports?: Amwins_Portal_Api_Services_AppHealthCheck[];
}

export interface Amwins_Portal_Api_Services_AppHealthCheck {
  /**  */
  AppName?: string;

  /**  */
  HealthReport?: Amwins_Portal_Api_Services_OrbitsUIHealthReport;
}

export interface Amwins_Portal_Api_Services_CancellationReasonWithSubReasons {
  /**  */
  CancellationReason?: Amwins_Orbits_Contracts_Models_v1_PolicyCancellations_CancellationReason;

  /**  */
  CancellationSubReasons?: Amwins_Orbits_Contracts_Models_v1_PolicyCancellations_CancellationSubReason[];

  /**  */
  MissingRequiredDocument?: string[];
}

export interface Amwins_Portal_Api_Services_CancellationReasonsResponse {
  /**  */
  CancellationReasons?: Amwins_Portal_Api_Services_CancellationReasonWithSubReasons[];
}

export interface Amwins_Portal_Api_Services_EtcService_UnderwriterTeamMember {
  /**  */
  underwriterId?: number;

  /**  */
  email?: string;
}

export interface Amwins_Portal_Api_Services_LockService_UserLock {
  /**  */
  domain?: Amwins_Portal_Api_Services_LockService_Domain;

  /**  */
  key?: string;

  /**  */
  expires?: Date;

  /**  */
  contested?: boolean;
}

export interface Amwins_Portal_Api_Services_OrbitsUIHealthReport {
  /**  */
  Status?: string;

  /**  */
  StatusGrade?: number;

  /**  */
  Entries?: Amwins_Portal_Api_Services_OrbitsUIHealthReportEntry[];

  /**  */
  TotalDuration?: string;
}

export interface Amwins_Portal_Api_Services_OrbitsUIHealthReportEntry {
  /**  */
  HealthCheckName?: string;

  /**  */
  Data?: object;

  /**  */
  Description?: string;

  /**  */
  Duration?: string;

  /**  */
  Exception?: any | null;

  /**  */
  Status?: string;

  /**  */
  Tags?: string[];
}

export interface Amwins_Portal_Api_Services_ReinstatementReasonWithSubReasons {
  /**  */
  ReinstatementReason?: Amwins_Orbits_Contracts_Models_Reinstatements_ReinstatementReason;

  /**  */
  MissingRequiredDocument?: string[];
}

export interface Amwins_Portal_Api_Services_ReinstatementReasonsResponse {
  /**  */
  ReinstatementReasons?: Amwins_Portal_Api_Services_ReinstatementReasonWithSubReasons[];
}

export interface Amwins_SeaSys_Domain_Interfaces_IAddtionalInterest {
  /**  */
  Address?: string;

  /**  */
  City?: string;

  /**  */
  Country?: string;

  /**  */
  Invoice?: boolean;

  /**  */
  Name?: string;

  /**  */
  OtherInfo?: string;

  /**  */
  State?: string;

  /**  */
  TypeCode?: string;

  /**  */
  TypeDescription?: string;

  /**  */
  Zip?: string;
}

export interface Amwins_SeaSys_Domain_Interfaces_IApplicant {
  /**  */
  ApplicantType?: string;

  /**  */
  BusinessIndustryID?: number;

  /**  */
  BusinessIndustryName?: string;

  /**  */
  ContactCellPhone?: string;

  /**  */
  ContactEmail?: string;

  /**  */
  SSN?: string;

  /**  */
  DateOfBirth?: Date;

  /**  */
  MaritalStatus?: string;

  /**  */
  NameFirst?: string;

  /**  */
  NameLast?: string;

  /**  */
  OccupationID?: number;

  /**  */
  OccupationName?: string;

  /**  */
  RelationshipToInsured?: string;

  /**  */
  InspectionContactPhone?: string;

  /**  */
  InspectionContactName?: string;

  /**  */
  InspectionContactEmail?: string;
}

export interface Amwins_SeaSys_Domain_Interfaces_IAttestationModel {
  /**  */
  ConsumerCreditAttestation?: Amwins_Orbits_Contracts_Models_Submissions_IAttestationV0;

  /**  */
  CovAAttestation?: Amwins_Orbits_Contracts_Models_Submissions_IAttestationV0;

  /**  */
  WindDecAttestation?: Amwins_Orbits_Contracts_Models_Submissions_IAttestationV0;

  /**  */
  AuthorizedApplicantAttestation?: Amwins_Orbits_Contracts_Models_Submissions_IAttestationV0;

  /**  */
  HurricaneMEPAttestation?: Amwins_Orbits_Contracts_Models_Submissions_IAttestationV0;
}

export interface Amwins_SeaSys_Domain_Interfaces_IClaimsHistoryItem {
  /**  */
  AmountPaid?: string;

  /**  */
  CatNonCatCode?: string;

  /**  */
  Code?: string;

  /**  */
  InsuredOrLocationCode?: string;

  /**  */
  LossTypeCode?: string;

  /**  */
  Month?: string;

  /**  */
  RepairsCompleteCode?: string;

  /**  */
  StatusCode?: string;

  /**  */
  Year?: string;
}

export interface Amwins_SeaSys_Domain_Interfaces_IDataServiceRoot {
  /**  */
  Attestation?: Amwins_SeaSys_Domain_Interfaces_IAttestationModel;

  /**  */
  OtherRisksAssociated?: Amwins_SeaSys_Domain_Interfaces_IOtherRisksAssociated;

  /**  */
  Risk?: Amwins_SeaSys_Domain_Interfaces_IRiskModel;
}

export interface Amwins_SeaSys_Domain_Interfaces_IDiligentEffortContact {
  /**  */
  ContactedDate?: Date;

  /**  */
  ContactEmail?: string;

  /**  */
  ContactName?: string;

  /**  */
  ContactPhone?: string;

  /**  */
  ContactWebsite?: string;

  /**  */
  DeclineReason?: string;

  /**  */
  InsuranceCompanyKey?: string;

  /**  */
  InsuranceCompanyNameOther?: string;
}

export interface Amwins_SeaSys_Domain_Interfaces_IOtherRisksAssociated {
  /**  */
  AnyBankruptcyForeclosureRepossession?: boolean;

  /**  */
  AnyMobileHomes?: boolean;

  /**  */
  DevelopersSpeculationHomes?: boolean;

  /**  */
  DwellingOnNationalRegistery?: boolean;

  /**  */
  DwellingsWithDaycareOrAssistedLiving?: boolean;

  /**  */
  DwellingWithKnobAndTubeWiring?: boolean;

  /**  */
  ExistingDamage?: boolean;

  /**  */
  FuelTankOnPremise?: boolean;

  /**  */
  InsuredHighProfileOccupation?: boolean;

  /**  */
  IsCoverageLapse?: boolean;

  /**  */
  IsDwellingFallingRocks?: boolean;

  /**  */
  IsDwellingInCOBRAZone?: boolean;

  /**  */
  IsDwellingWithAsbestosOrLead?: boolean;

  /**  */
  IsUnacceptableHeatSource?: boolean;

  /**  */
  IsUnacceptablePlumbing?: boolean;

  /**  */
  NoSmokeDetectors?: boolean;

  /**  */
  NotInsuredToFullValue?: boolean;

  /**  */
  OwnFarms?: boolean;

  /**  */
  IsDwellingWithSolarPanel?: boolean;

  /**  */
  IsMultipleLivingUnit?: boolean;

  /**  */
  IsDwellingNonStandardConstruction?: boolean;
}

export interface Amwins_SeaSys_Domain_Interfaces_IRiskModel {
  /**  */
  EarthquakeMasonryVeneer?: string;

  /**  */
  EarthquakeTuckUnderParking?: string;

  /**  */
  EarthquakeYearRetrofittedToCode?: string;

  /**  */
  GCCarryCGLLimits?: string;

  /**  */
  GCLimit?: string;

  /**  */
  GroundUpCOC?: string;

  /**  */
  OwnerActingAsGC?: string;

  /**  */
  RecommendedCoverageOption?: string;

  /**  */
  SCCarryCGLLimits?: string;

  /**  */
  SCLimit?: string;
}

export interface Amwins_SeaSys_Domain_Interfaces_IUnderwritingItem {
  /**  */
  UnderwritingCode?: string;

  /**  */
  UnderwritingCode_Group?: string;

  /**  */
  UnderwritingCodeDescription?: string;

  /**  */
  UnderwritingCodeStatus?: number;

  /**  */
  UnderwritingCodeStatusDescription?: string;

  /**  */
  UnderwritingString_OfficeApproval_ContractCode?: string;

  /**  */
  UnderwritingString_OfficeApproval_Value?: string;
}

export interface Amwins_SeaSys_Domain_Models_ApplicationView {
  /**  */
  StatusGroup?: string;

  /**  */
  StatusDetail?: string;

  /**  */
  TransactionEffectiveDate?: Date;

  /**  */
  PrintProcessingDate?: Date;

  /**  */
  Cust_FullName_Sorted?: string;

  /**  */
  Cust_FullName?: string;

  /**  */
  Cust_FullName_NoName2?: string;

  /**  */
  Cust_Address1_2?: string;

  /**  */
  Cust_CityStateZip?: string;

  /**  */
  Cust_ProvCountry?: string;

  /**  */
  Loc_Address1_2?: string;

  /**  */
  Loc_CityStateZip?: string;

  /**  */
  NamedInsureds?: string;

  /**  */
  AdditionalInsureds?: string;

  /**  */
  PremiumI_Amount_Net?: number;

  /**  */
  PremiumII_Amount_Net?: number;

  /**  */
  PremiumOptions_Amount_Net?: number;

  /**  */
  SubTotal_Amount_Net?: number;

  /**  */
  PolicyFee_Amount_Net?: number;

  /**  */
  OtherFee_Amount_Net?: number;

  /**  */
  TaxableTotal_Amount_Net?: number;

  /**  */
  SurplusLinesTax_Amount_Net?: number;

  /**  */
  OtherTax1_Amount_Net?: number;

  /**  */
  OtherTax2_Amount_Net?: number;

  /**  */
  OtherTax3_Amount_Net?: number;

  /**  */
  PremiumTotal_Amount_Net?: number;

  /**  */
  PropertyTIV_Amount?: number;

  /**  */
  PropertyTIV_Amount_Transactional?: number;

  /**  */
  CovI_Amount_Transactional?: number;

  /**  */
  CovII_Amount_Transactional?: number;

  /**  */
  CovIII_Amount_Transactional?: number;

  /**  */
  CovIV_Amount_Transactional?: number;

  /**  */
  CovA?: number;

  /**  */
  CovB?: number;

  /**  */
  CovC?: number;

  /**  */
  CovD?: number;

  /**  */
  CovLossAssessment?: number;

  /**  */
  ProgramISO?: string;

  /**  */
  Loc_UpdateRenovation?: string;

  /**  */
  Loc_UpdateRenovation_Age?: number;

  /**  */
  Loc_UpdateRoof_Age?: number;

  /**  */
  Loc_YearConstructed_Age?: number;

  /**  */
  Loc_NrOfStoriesGroupCD?: string;

  /**  */
  Loc_Protection_WaterShutoff?: string;

  /**  */
  Loc_Protections_WildFirewiseCommunity?: string;

  /**  */
  CovFlood?: string;

  /**  */
  CovGolfCart?: string;

  /**  */
  CovReplacementCostStructuresPct?: number;

  /**  */
  CovTheftType?: string;

  /**  */
  CovLossAssessment_FormInclAmt?: number;

  /**  */
  CovLossAssessment_AddOnAmt?: number;

  /**  */
  CovOrdinanceLaw_FormInclPct?: number;

  /**  */
  CovOrdinanceLaw_AddOnPct?: number;

  /**  */
  CovVandalismMaliciousMischief?: boolean;

  /**  */
  CovVandalismMaliciousMischief_FormIncl?: boolean;

  /**  */
  CovVandalismMaliciousMischief_AddOnIncl?: boolean;

  /**  */
  CovValuationTypeBuilding?: string;

  /**  */
  CovValuationTypeContents?: string;

  /**  */
  CovWaterDamageGroupCD?: string;

  /**  */
  ClaimsHistoryStringCount?: number;

  /**  */
  DedWindTypeCode?: string;

  /**  */
  Loc_RentalExposure?: boolean;

  /**  */
  Loc_TrampolineExposure?: boolean;

  /**  */
  TaxTransactionNumber?: string;

  /**  */
  DocPolicyNo?: string;

  /**  */
  MinimumEarnedPremiumPctAmt?: number;

  /**  */
  DebitCreditOptionList?: Amwins_SeaSys_Domain_Models_DebitCreditOption[];

  /**  */
  AccountReference?: string;

  /**  */
  Agency_SubAgencyID?: number;

  /**  */
  AgencyID?: number;

  /**  */
  Agent_SubAgentID?: number;

  /**  */
  AgentID?: number;

  /**  */
  ApplicantDetailsList?: Amwins_SeaSys_Domain_Interfaces_IApplicant[];

  /**  */
  ApplicationID?: number;

  /**  */
  Calc_DedAOP_Amount?: number;

  /**  */
  Calc_DedAOP_Pct?: number;

  /**  */
  Calc_DedEQ_Amount?: number;

  /**  */
  Calc_DedEQ_Pct?: number;

  /**  */
  Calc_DedFlood_Amount?: number;

  /**  */
  Calc_DedFlood_Pct?: number;

  /**  */
  Calc_DedWind_Amount?: number;

  /**  */
  Calc_DedWind_Pct?: number;

  /**  */
  Canceled?: boolean;

  /**  */
  CertificateNr?: string;

  /**  */
  CertificateNr_Old?: string;

  /**  */
  CertificateNrClean?: string;

  /**  */
  ClaimsHistoryList?: Amwins_SeaSys_Domain_Interfaces_IClaimsHistoryItem[];

  /**  */
  ClaimsHistoryString?: string;

  /**  */
  Comments?: string;

  /**  */
  CommissionRateAgent?: number;

  /**  */
  CommissionRateContract?: number;

  /**  */
  Complete?: boolean;

  /**  */
  ContractCode?: string;

  /**  */
  ContractNr?: string;

  /**  */
  CoreNr?: string;

  /**  */
  CovAnimalLiability?: number;

  /**  */
  CovEPersonalLiability?: number;

  /**  */
  CovFMedPay?: number;

  /**  */
  CovI?: number;

  /**  */
  CovII?: number;

  /**  */
  CovIII_LU?: number;

  /**  */
  CovInflationGuard?: number;

  /**  */
  CovIV?: number;

  /**  */
  CovLossAssessment_NonHO6?: number;

  /**  */
  CovOptAddInsured?: number;

  /**  */
  CovOptAnimalLiability?: number;

  /**  */
  CovOptComments?: string;

  /**  */
  CovOptDedAOP?: number;

  /**  */
  CovOptDedEQ?: number;

  /**  */
  CovOptDedFlood?: number;

  /**  */
  CovOptDedWaterDamage?: number;

  /**  */
  CovOptDedWind?: number;

  /**  */
  CovOptInflationGuard?: number;

  /**  */
  CovOptLossAssessment?: number;

  /**  */
  CovOptLossOfUse?: number;

  /**  */
  CovOptMedPay?: number;

  /**  */
  CovOptOrdinanceLaw?: number;

  /**  */
  CovMold?: number;

  /**  */
  CovOptOtherStructures?: number;

  /**  */
  CovOptPersArticleFloater?: number;

  /**  */
  CovOptPersonalInjury?: number;

  /**  */
  CovOptPersonalLiability?: number;

  /**  */
  CovOptReplacementCostContents?: number;

  /**  */
  CovOptReplacementCostStructures?: number;

  /**  */
  CovOptTheft?: number;

  /**  */
  CovOptWaterBackUp?: number;

  /**  */
  CovOptWaterDamage?: number;

  /**  */
  CovOptWDR?: number;

  /**  */
  CovOrdinanceLaw?: number;

  /**  */
  CovPersArticleFloater?: number;

  /**  */
  CovPersonalInjury?: boolean;

  /**  */
  CovReplacementCostContents?: boolean;

  /**  */
  CovReplacementCostStructures?: number;

  /**  */
  CovTheftOnPremises?: number;

  /**  */
  CovWaterBackUp?: number;

  /**  */
  CovWaterDamage?: number;

  /**  */
  CovWDR?: boolean;

  /**  */
  Cust_AdditionalInterestList?: Amwins_SeaSys_Domain_Interfaces_IAddtionalInterest[];

  /**  */
  Cust_AdditionalInterestPayableNr?: number;

  /**  */
  Cust_Address?: string;

  /**  */
  Cust_City?: string;

  /**  */
  Cust_CountryCode?: string;

  /**  */
  Cust_DomicileCountryCode?: string;

  /**  */
  Cust_FirstName?: string;

  /**  */
  Cust_Name?: string;

  /**  */
  Cust_Name2?: string;

  /**  */
  Cust_Prefix?: string;

  /**  */
  Cust_Providence?: string;

  /**  */
  Cust_State?: string;

  /**  */
  Cust_Suffix?: string;

  /**  */
  Cust_Type?: string;

  /**  */
  Cust_Zip?: string;

  /**  */
  CustomerRiskAccountKey?: string;

  /**  */
  CX_Reason?: string;

  /**  */
  DebitCreditAlarm?: number;

  /**  */
  DebitCreditAmount?: number;

  /**  */
  DebitCreditHurricaneShutters?: number;

  /**  */
  DebitCreditProtectionClass?: number;

  /**  */
  DebitCreditRentalType?: number;

  /**  */
  DebitCreditSelections?: string;

  /**  */
  DedAOP?: string;

  /**  */
  DedEQ?: string;

  /**  */
  DedFlood?: string;

  /**  */
  DedWaterDamage?: string;

  /**  */
  DedWind?: string;

  /**  */
  DilligentEffortsList?: Amwins_SeaSys_Domain_Interfaces_IDiligentEffortContact[];

  /**  */
  DS_CovA_RCVEstimate?: number;

  /**  */
  DS_CovA_RCVEstimate_CoreLogic?: number;

  /**  */
  DS_Cust_Score_Verikai?: number;

  /**  */
  DS_RoofConditionRating_Cape?: number;

  /**  */
  DS_EQ_AAL_AIR?: number;

  /**  */
  DS_EQ_AAL_AIR_AmwinsMdl?: number;

  /**  */
  DS_EQ_AAL_RMS?: number;

  /**  */
  DS_EQ_AAL_RMS_AmwinsMdl?: number;

  /**  */
  DS_Geo_Latt?: number;

  /**  */
  DS_Geo_Latt_Google?: number;

  /**  */
  DS_Geo_Latt_MapRisk?: number;

  /**  */
  DS_Geo_Long?: number;

  /**  */
  DS_Geo_Long_Google?: number;

  /**  */
  DS_Geo_Long_MapRisk?: number;

  /**  */
  DS_HU_AAL_AIR?: number;

  /**  */
  DS_HU_AAL_AIR_AmwinsMdl?: number;

  /**  */
  DS_HU_AAL_AIR_CatAal?: number;

  /**  */
  DS_HU_AAL_AIR_DeductiblePct?: number;

  /**  */
  DS_HU_AAL_AIR_DeductiblePct_AmwinsMdl?: number;

  /**  */
  DS_HU_AAL_AIR_DeductiblePct_CatAal?: number;

  /**  */
  DS_HU_AAL_AIR_DeductiblePct_TigerCQ?: number;

  /**  */
  DS_HU_AAL_AIR_TigerCQ?: number;

  /**  */
  DS_HU_AAL_AIR_TIV?: number;

  /**  */
  DS_HU_AAL_AIR_TIV_AmwinsMdl?: number;

  /**  */
  DS_HU_AAL_AIR_TIV_CatAal?: number;

  /**  */
  DS_HU_AAL_AIR_TIV_TigerCQ?: number;

  /**  */
  DS_HU_AAL_RMS?: number;

  /**  */
  DS_HU_AAL_RMS_AmwinsMdl?: number;

  /**  */
  DS_HU_AAL_RMS_CatAal?: number;

  /**  */
  DS_HU_AAL_RMS_DeductiblePct?: number;

  /**  */
  DS_HU_AAL_RMS_DeductiblePct_AmwinsMdl?: number;

  /**  */
  DS_HU_AAL_RMS_DeductiblePct_CatAal?: number;

  /**  */
  DS_HU_AAL_RMS_DeductiblePct_TigerCQ?: number;

  /**  */
  DS_HU_AAL_RMS_TigerCQ?: number;

  /**  */
  DS_HU_AAL_RMS_TIV?: number;

  /**  */
  DS_HU_AAL_RMS_TIV_AmwinsMdl?: number;

  /**  */
  DS_HU_AAL_RMS_TIV_CatAal?: number;

  /**  */
  DS_HU_AAL_RMS_TIV_TigerCQ?: number;

  /**  */
  DS_ModelRunDate_AmwinsMdl?: Date;

  /**  */
  DS_ProtectionClass?: string;

  /**  */
  DS_ProtectionClass_BAMS?: string;

  /**  */
  DS_TH_AAL_AIR?: number;

  /**  */
  DS_TH_AAL_AIR_AmwinsMdl?: number;

  /**  */
  DS_WaterDistance_MapRisk?: number;

  /**  */
  DS_WF_AAL_AIR?: number;

  /**  */
  DS_WF_AAL_AIR_AmwinsMdl?: number;

  /**  */
  DS_WF_Score_AnchorPoint?: number;

  /**  */
  DS_WF_Score_CoreLogic?: number;

  /**  */
  EN_CovI_Old?: number;

  /**  */
  EN_CovII_Old?: number;

  /**  */
  EN_CovIII_LU_Old?: number;

  /**  */
  EN_CovIV_Old?: number;

  /**  */
  EN_OtherFee_Annual?: number;

  /**  */
  EN_OtherTax1_Annual?: number;

  /**  */
  EN_OtherTax2_Annual?: number;

  /**  */
  EN_OtherTax3_Annual?: number;

  /**  */
  EN_PolicyFee_Annual?: number;

  /**  */
  EN_PremiumI_Annual?: number;

  /**  */
  EN_PremiumII_Annual?: number;

  /**  */
  EN_PremiumOptions_Annual?: number;

  /**  */
  EN_PremiumTotal_Annual?: number;

  /**  */
  EN_Reason?: string;

  /**  */
  EN_SubTotal_Annual?: number;

  /**  */
  EN_SurplusLinesTax_Annual?: number;

  /**  */
  EN_TaxableTotal_Annual?: number;

  /**  */
  Endorsed?: boolean;

  /**  */
  EndorseNr?: string;

  /**  */
  EnteredBy?: string;

  /**  */
  ErrorDetail?: string;

  /**  */
  ErrorStatus?: string;

  /**  */
  Expiring_PremiumAdjustment?: number;

  /**  */
  Expiring_PremiumAOP?: number;

  /**  */
  Expiring_PremiumEQ?: number;

  /**  */
  Expiring_PremiumFlood?: number;

  /**  */
  Expiring_PremiumLiability?: number;

  /**  */
  Expiring_PremiumOptionAddOns?: number;

  /**  */
  Expiring_PremiumWildFire?: number;

  /**  */
  Expiring_PremiumWind?: number;

  /**  */
  Expiring_SubTotal?: number;

  /**  */
  Expiring_TIV?: number;

  /**  */
  Expiring_TIV_NonTM?: number;

  /**  */
  Ext_Algo_PricingKey?: string;

  /**  */
  Ext_Algo_RatePlanEffectiveDate?: Date;

  /**  */
  Ext_Market_TransactionKey?: string;

  /**  */
  FormsList?: string;

  /**  */
  Ins_CompanyCode?: string;

  /**  */
  InternalRenewal?: boolean;

  /**  */
  Legacy_Algo_Subtotal?: number;

  /**  */
  Loc_Address?: string;

  /**  */
  Loc_AddressSame?: boolean;

  /**  */
  Loc_AlarmSystem?: string;

  /**  */
  Loc_AssociationName?: string;

  /**  */
  Loc_BuildingType?: string;

  /**  */
  Loc_City?: string;

  /**  */
  Loc_ConstructionExteriorWallType?: string;

  /**  */
  Loc_ConstructionType?: string;

  /**  */
  Loc_CountryCode?: string;

  /**  */
  Loc_County?: string;

  /**  */
  Loc_DataServiceRoot?: Amwins_SeaSys_Domain_Interfaces_IDataServiceRoot;

  /**  */
  Loc_ElementsAttached?: string;

  /**  */
  Loc_ElementsDetached?: string;

  /**  */
  Loc_FireZone?: string;

  /**  */
  Loc_FloodZone?: string;

  /**  */
  Loc_FloorNr?: number;

  /**  */
  Loc_FoundationType?: string;

  /**  */
  Loc_GatedCommunity?: string;

  /**  */
  Loc_GroundFloor?: boolean;

  /**  */
  Loc_HeatingType?: string;

  /**  */
  Loc_NrOfBuildings?: number;

  /**  */
  Loc_NrOfStories?: number;

  /**  */
  Loc_OccupancyType?: string;

  /**  */
  Loc_OccupantsInfo?: string;

  /**  */
  Loc_PC_DistToFireDept?: number;

  /**  */
  Loc_PC_DistToWaterSource?: number;

  /**  */
  Loc_PC_FireDeptResponseTimeMinutes?: number;

  /**  */
  Loc_ProtectionClass?: string;

  /**  */
  Loc_RentalIncomeMonthly?: number;

  /**  */
  Loc_RentalType?: string;

  /**  */
  Loc_ResistiveDevice_Fire?: string;

  /**  */
  Loc_ResistiveDevice_Opening?: string;

  /**  */
  Loc_ResistiveDevice_RoofToWall?: string;

  /**  */
  Loc_ResistiveDevice_WallToFoundation?: string;

  /**  */
  Loc_RoofCover?: string;

  /**  */
  Loc_RoofType?: string;

  /**  */
  Loc_SqFeet?: string;

  /**  */
  Loc_SqFeetCostEstimate?: number;

  /**  */
  Loc_State?: string;

  /**  */
  Loc_Territory?: string;

  /**  */
  Loc_UnitNr?: string;

  /**  */
  Loc_UnitType?: string;

  /**  */
  Loc_UpdateElectric?: string;

  /**  */
  Loc_UpdateHvac?: string;

  /**  */
  Loc_UpdatePlumbing?: string;

  /**  */
  Loc_UpdateRoof?: string;

  /**  */
  Loc_UW_AnimalAttackHistory?: string;

  /**  */
  Loc_UW_AnimalExposureDetail?: string;

  /**  */
  Loc_UW_BusinessOnPremises?: boolean;

  /**  */
  Loc_UW_LandAcresExposure?: number;

  /**  */
  Loc_UW_LavaZone?: string;

  /**  */
  Loc_UW_LiveStock?: string;

  /**  */
  Loc_UW_NrOfLiens3OrMore?: boolean;

  /**  */
  Loc_UW_PossessionTakenYear?: number;

  /**  */
  Loc_UW_PrevInsuranceCancelledOrNonRN?: string;

  /**  */
  Loc_UW_PrevInsuranceCompany?: string;

  /**  */
  Loc_UW_PrevInsuranceExpirationDate?: Date;

  /**  */
  Loc_UW_SwimmingPool?: string;

  /**  */
  Loc_UW_UnderConstruction?: string;

  /**  */
  Loc_UW_WoodStove?: boolean;

  /**  */
  Loc_WaterDistance?: string;

  /**  */
  Loc_WindPool?: boolean;

  /**  */
  Loc_YearConstructed?: string;

  /**  */
  Loc_YearConstructedEffective?: string;

  /**  */
  Loc_Zip?: string;

  /**  */
  Market?: string;

  /**  */
  Mortgagee1_ClosingDate?: Date;

  /**  */
  Mortgagee1_ID?: number;

  /**  */
  Mortgagee1_InvoiceMortg?: boolean;

  /**  */
  Mortgagee1_LoanNr?: string;

  /**  */
  Mortgagee1_SuffixCode?: string;

  /**  */
  Mortgagee2_ID?: number;

  /**  */
  Mortgagee2_LoanNr?: string;

  /**  */
  Mortgagee2_SuffixCode?: string;

  /**  */
  NonRenewedBy?: string;

  /**  */
  NonRenewedDate?: Date;

  /**  */
  NonRenewReason?: string;

  /**  */
  OtherFee?: number;

  /**  */
  OtherFee_Amount?: number;

  /**  */
  OtherFeeSelections?: string;

  /**  */
  OtherTax1?: number;

  /**  */
  OtherTax1_Amount?: number;

  /**  */
  OtherTax1Rate?: number;

  /**  */
  OtherTax2?: number;

  /**  */
  OtherTax2_Amount?: number;

  /**  */
  OtherTax2Rate?: number;

  /**  */
  OtherTax3?: number;

  /**  */
  OtherTax3_Amount?: number;

  /**  */
  OtherTax3Rate?: number;

  /**  */
  PolicyBegin?: Date;

  /**  */
  PolicyEnd?: Date;

  /**  */
  PolicyFee?: number;

  /**  */
  PolicyFee_Amount?: number;

  /**  */
  PolicyKey?: string;

  /**  */
  PremiumI?: number;

  /**  */
  PremiumI_Amount?: number;

  /**  */
  PremiumII?: number;

  /**  */
  PremiumII_Amount?: number;

  /**  */
  PremiumOptions?: number;

  /**  */
  PremiumOptions_Amount?: number;

  /**  */
  PremiumReceived?: number;

  /**  */
  PremiumTotal?: number;

  /**  */
  PremiumTotal_Amount?: number;

  /**  */
  ProcessingDate?: Date;

  /**  */
  ProcessingHoldClearDate?: Date;

  /**  */
  ProcessingHoldReasonCode?: string;

  /**  */
  ProcessingHoldThruDate?: Date;

  /**  */
  Program?: string;

  /**  */
  ProgramCode?: string;

  /**  */
  ProgramGroup?: string;

  /**  */
  PropertyTIV?: number;

  /**  */
  PropertyTIV_NonTM?: number;

  /**  */
  RecordInactive?: Date;

  /**  */
  RecordInactiveLogonKey?: string;

  /**  */
  RecordInactiveSystemsModuleKey?: string;

  /**  */
  RecordInsert?: Date;

  /**  */
  RecordInsertLogonKey?: string;

  /**  */
  RecordInsertSystemsModuleKey?: string;

  /**  */
  RecordUpdate?: Date;

  /**  */
  RecordUpdateLogonKey?: string;

  /**  */
  RecordUpdateSystemsModuleKey?: string;

  /**  */
  ReInstated?: boolean;

  /**  */
  ReNewed?: boolean;

  /**  */
  RewriteFrom?: string;

  /**  */
  RewriteToContractCode?: string;

  /**  */
  RewrittenAS?: string;

  /**  */
  RiskKey?: string;

  /**  */
  StatusActive?: boolean;

  /**  */
  SubTotal?: number;

  /**  */
  SubTotal_Amount?: number;

  /**  */
  SurplusLinesRate?: number;

  /**  */
  SurplusLinesTax?: number;

  /**  */
  SurplusLinesTax_Amount?: number;

  /**  */
  SysUpdateTime?: Date;

  /**  */
  TaxableTotal?: number;

  /**  */
  TaxableTotal_Amount?: number;

  /**  */
  TermBegin?: Date;

  /**  */
  TermBegin_Original?: Date;

  /**  */
  TermEnd?: Date;

  /**  */
  TermEnd_Original?: Date;

  /**  */
  TermOrigin?: string;

  /**  */
  TermRateCalcValidThru?: Date;

  /**  */
  TermYear?: string;

  /**  */
  TransactionType?: string;

  /**  */
  UnderwriterID?: number;

  /**  */
  UnderwritingItemList?: Amwins_SeaSys_Domain_Interfaces_IUnderwritingItem[];

  /**  */
  UnderwritingString?: string;

  /**  */
  WebApplicationID?: number;

  /**  */
  WebApplicationLogID_Calc?: number;

  /**  */
  WindCatastrophe?: string;

  /**  */
  Loc_OccupantsInfoDetail?: string;

  /**  */
  Loc_PC_AccessibleByRoadYearRound?: boolean;

  /**  */
  Loc_PC_OccupiedOrCheckedDaily?: boolean;

  /**  */
  Loc_PC_VisibleToNeighborsOrCheckedDaily?: boolean;

  /**  */
  Loc_ProtectiveDevice_Surveillance?: string;

  /**  */
  Loc_RentalNumberOfDays?: number;

  /**  */
  Loc_RentalManagementCompanyInvolved?: boolean;

  /**  */
  Loc_ResistiveDevice_Roof2ndWaterResist?: string;

  /**  */
  Loc_ResistiveDevice_RoofDeckAttachment?: string;

  /**  */
  Loc_ResistiveDevice_OpeningNonGlazed?: string;

  /**  */
  Loc_ResistiveDevice_Water?: string;

  /**  */
  Loc_RoofUpToCode?: boolean;

  /**  */
  Loc_UpdateElectricPartial?: string;

  /**  */
  Loc_UpdateHvacPartial?: string;

  /**  */
  Loc_UpdatePlumbingPartial?: string;

  /**  */
  Loc_UpdateRoofPartial?: string;

  /**  */
  Loc_UW_PrevInsuranceType?: string;

  /**  */
  Loc_UW_SwimmingPoolDivingBoardOrSlide?: boolean;

  /**  */
  TransactionSequenceNumber?: number;

  /**  */
  Loc_UW_TrampolineExposure?: string;

  /**  */
  Loc_OccupancySaleDetail?: string;

  /**  */
  Loc_VacancyDetail?: string;
}

export interface Amwins_SeaSys_Domain_Models_ApplicationViewLog {
  /**  */
  AvailableContractString?: string;

  /**  */
  AvailableContractString_NotLimitedBySelections?: string;

  /**  */
  UnAvailableContractString?: string;

  /**  */
  UnAvailableContractString_LimitedBySelections?: string;

  /**  */
  UnAvailableContractString_NotLimitedBySelections?: string;

  /**  */
  FormsList_ItemsRemoved?: string;

  /**  */
  FormsList_ItemsAdded?: string;

  /**  */
  StatusGroup?: string;

  /**  */
  StatusDetail?: string;

  /**  */
  TransactionEffectiveDate?: Date;

  /**  */
  PrintProcessingDate?: Date;

  /**  */
  Cust_FullName_Sorted?: string;

  /**  */
  Cust_FullName?: string;

  /**  */
  Cust_FullName_NoName2?: string;

  /**  */
  Cust_Address1_2?: string;

  /**  */
  Cust_CityStateZip?: string;

  /**  */
  Cust_ProvCountry?: string;

  /**  */
  Loc_Address1_2?: string;

  /**  */
  Loc_CityStateZip?: string;

  /**  */
  NamedInsureds?: string;

  /**  */
  AdditionalInsureds?: string;

  /**  */
  PremiumI_Amount_Net?: number;

  /**  */
  PremiumII_Amount_Net?: number;

  /**  */
  PremiumOptions_Amount_Net?: number;

  /**  */
  SubTotal_Amount_Net?: number;

  /**  */
  PolicyFee_Amount_Net?: number;

  /**  */
  OtherFee_Amount_Net?: number;

  /**  */
  TaxableTotal_Amount_Net?: number;

  /**  */
  SurplusLinesTax_Amount_Net?: number;

  /**  */
  OtherTax1_Amount_Net?: number;

  /**  */
  OtherTax2_Amount_Net?: number;

  /**  */
  OtherTax3_Amount_Net?: number;

  /**  */
  PremiumTotal_Amount_Net?: number;

  /**  */
  PropertyTIV_Amount?: number;

  /**  */
  PropertyTIV_Amount_Transactional?: number;

  /**  */
  CovI_Amount_Transactional?: number;

  /**  */
  CovII_Amount_Transactional?: number;

  /**  */
  CovIII_Amount_Transactional?: number;

  /**  */
  CovIV_Amount_Transactional?: number;

  /**  */
  CovA?: number;

  /**  */
  CovB?: number;

  /**  */
  CovC?: number;

  /**  */
  CovD?: number;

  /**  */
  CovLossAssessment?: number;

  /**  */
  ProgramISO?: string;

  /**  */
  Loc_UpdateRenovation?: string;

  /**  */
  Loc_UpdateRenovation_Age?: number;

  /**  */
  Loc_UpdateRoof_Age?: number;

  /**  */
  Loc_YearConstructed_Age?: number;

  /**  */
  Loc_NrOfStoriesGroupCD?: string;

  /**  */
  Loc_Protection_WaterShutoff?: string;

  /**  */
  Loc_Protections_WildFirewiseCommunity?: string;

  /**  */
  CovFlood?: string;

  /**  */
  CovGolfCart?: string;

  /**  */
  CovReplacementCostStructuresPct?: number;

  /**  */
  CovTheftType?: string;

  /**  */
  CovLossAssessment_FormInclAmt?: number;

  /**  */
  CovLossAssessment_AddOnAmt?: number;

  /**  */
  CovOrdinanceLaw_FormInclPct?: number;

  /**  */
  CovOrdinanceLaw_AddOnPct?: number;

  /**  */
  CovVandalismMaliciousMischief?: boolean;

  /**  */
  CovVandalismMaliciousMischief_FormIncl?: boolean;

  /**  */
  CovVandalismMaliciousMischief_AddOnIncl?: boolean;

  /**  */
  CovValuationTypeBuilding?: string;

  /**  */
  CovValuationTypeContents?: string;

  /**  */
  CovWaterDamageGroupCD?: string;

  /**  */
  ClaimsHistoryStringCount?: number;

  /**  */
  DedWindTypeCode?: string;

  /**  */
  Loc_RentalExposure?: boolean;

  /**  */
  Loc_TrampolineExposure?: boolean;

  /**  */
  TaxTransactionNumber?: string;

  /**  */
  DocPolicyNo?: string;

  /**  */
  MinimumEarnedPremiumPctAmt?: number;

  /**  */
  DebitCreditOptionList?: Amwins_SeaSys_Domain_Models_DebitCreditOption[];

  /**  */
  AccountReference?: string;

  /**  */
  Agency_SubAgencyID?: number;

  /**  */
  AgencyID?: number;

  /**  */
  Agent_SubAgentID?: number;

  /**  */
  AgentID?: number;

  /**  */
  ApplicantDetailsList?: Amwins_SeaSys_Domain_Interfaces_IApplicant[];

  /**  */
  ApplicationID?: number;

  /**  */
  Calc_DedAOP_Amount?: number;

  /**  */
  Calc_DedAOP_Pct?: number;

  /**  */
  Calc_DedEQ_Amount?: number;

  /**  */
  Calc_DedEQ_Pct?: number;

  /**  */
  Calc_DedFlood_Amount?: number;

  /**  */
  Calc_DedFlood_Pct?: number;

  /**  */
  Calc_DedWind_Amount?: number;

  /**  */
  Calc_DedWind_Pct?: number;

  /**  */
  Canceled?: boolean;

  /**  */
  CertificateNr?: string;

  /**  */
  CertificateNr_Old?: string;

  /**  */
  CertificateNrClean?: string;

  /**  */
  ClaimsHistoryList?: Amwins_SeaSys_Domain_Interfaces_IClaimsHistoryItem[];

  /**  */
  ClaimsHistoryString?: string;

  /**  */
  Comments?: string;

  /**  */
  CommissionRateAgent?: number;

  /**  */
  CommissionRateContract?: number;

  /**  */
  Complete?: boolean;

  /**  */
  ContractCode?: string;

  /**  */
  ContractNr?: string;

  /**  */
  CoreNr?: string;

  /**  */
  CovAnimalLiability?: number;

  /**  */
  CovEPersonalLiability?: number;

  /**  */
  CovFMedPay?: number;

  /**  */
  CovI?: number;

  /**  */
  CovII?: number;

  /**  */
  CovIII_LU?: number;

  /**  */
  CovInflationGuard?: number;

  /**  */
  CovIV?: number;

  /**  */
  CovLossAssessment_NonHO6?: number;

  /**  */
  CovOptAddInsured?: number;

  /**  */
  CovOptAnimalLiability?: number;

  /**  */
  CovOptComments?: string;

  /**  */
  CovOptDedAOP?: number;

  /**  */
  CovOptDedEQ?: number;

  /**  */
  CovOptDedFlood?: number;

  /**  */
  CovOptDedWaterDamage?: number;

  /**  */
  CovOptDedWind?: number;

  /**  */
  CovOptInflationGuard?: number;

  /**  */
  CovOptLossAssessment?: number;

  /**  */
  CovOptLossOfUse?: number;

  /**  */
  CovOptMedPay?: number;

  /**  */
  CovOptOrdinanceLaw?: number;

  /**  */
  CovMold?: number;

  /**  */
  CovOptOtherStructures?: number;

  /**  */
  CovOptPersArticleFloater?: number;

  /**  */
  CovOptPersonalInjury?: number;

  /**  */
  CovOptPersonalLiability?: number;

  /**  */
  CovOptReplacementCostContents?: number;

  /**  */
  CovOptReplacementCostStructures?: number;

  /**  */
  CovOptTheft?: number;

  /**  */
  CovOptWaterBackUp?: number;

  /**  */
  CovOptWaterDamage?: number;

  /**  */
  CovOptWDR?: number;

  /**  */
  CovOrdinanceLaw?: number;

  /**  */
  CovPersArticleFloater?: number;

  /**  */
  CovPersonalInjury?: boolean;

  /**  */
  CovReplacementCostContents?: boolean;

  /**  */
  CovReplacementCostStructures?: number;

  /**  */
  CovTheftOnPremises?: number;

  /**  */
  CovWaterBackUp?: number;

  /**  */
  CovWaterDamage?: number;

  /**  */
  CovWDR?: boolean;

  /**  */
  Cust_AdditionalInterestList?: Amwins_SeaSys_Domain_Interfaces_IAddtionalInterest[];

  /**  */
  Cust_AdditionalInterestPayableNr?: number;

  /**  */
  Cust_Address?: string;

  /**  */
  Cust_City?: string;

  /**  */
  Cust_CountryCode?: string;

  /**  */
  Cust_DomicileCountryCode?: string;

  /**  */
  Cust_FirstName?: string;

  /**  */
  Cust_Name?: string;

  /**  */
  Cust_Name2?: string;

  /**  */
  Cust_Prefix?: string;

  /**  */
  Cust_Providence?: string;

  /**  */
  Cust_State?: string;

  /**  */
  Cust_Suffix?: string;

  /**  */
  Cust_Type?: string;

  /**  */
  Cust_Zip?: string;

  /**  */
  CustomerRiskAccountKey?: string;

  /**  */
  CX_Reason?: string;

  /**  */
  DebitCreditAlarm?: number;

  /**  */
  DebitCreditAmount?: number;

  /**  */
  DebitCreditHurricaneShutters?: number;

  /**  */
  DebitCreditProtectionClass?: number;

  /**  */
  DebitCreditRentalType?: number;

  /**  */
  DebitCreditSelections?: string;

  /**  */
  DedAOP?: string;

  /**  */
  DedEQ?: string;

  /**  */
  DedFlood?: string;

  /**  */
  DedWaterDamage?: string;

  /**  */
  DedWind?: string;

  /**  */
  DilligentEffortsList?: Amwins_SeaSys_Domain_Interfaces_IDiligentEffortContact[];

  /**  */
  DS_CovA_RCVEstimate?: number;

  /**  */
  DS_CovA_RCVEstimate_CoreLogic?: number;

  /**  */
  DS_Cust_Score_Verikai?: number;

  /**  */
  DS_RoofConditionRating_Cape?: number;

  /**  */
  DS_EQ_AAL_AIR?: number;

  /**  */
  DS_EQ_AAL_AIR_AmwinsMdl?: number;

  /**  */
  DS_EQ_AAL_RMS?: number;

  /**  */
  DS_EQ_AAL_RMS_AmwinsMdl?: number;

  /**  */
  DS_Geo_Latt?: number;

  /**  */
  DS_Geo_Latt_Google?: number;

  /**  */
  DS_Geo_Latt_MapRisk?: number;

  /**  */
  DS_Geo_Long?: number;

  /**  */
  DS_Geo_Long_Google?: number;

  /**  */
  DS_Geo_Long_MapRisk?: number;

  /**  */
  DS_HU_AAL_AIR?: number;

  /**  */
  DS_HU_AAL_AIR_AmwinsMdl?: number;

  /**  */
  DS_HU_AAL_AIR_CatAal?: number;

  /**  */
  DS_HU_AAL_AIR_DeductiblePct?: number;

  /**  */
  DS_HU_AAL_AIR_DeductiblePct_AmwinsMdl?: number;

  /**  */
  DS_HU_AAL_AIR_DeductiblePct_CatAal?: number;

  /**  */
  DS_HU_AAL_AIR_DeductiblePct_TigerCQ?: number;

  /**  */
  DS_HU_AAL_AIR_TigerCQ?: number;

  /**  */
  DS_HU_AAL_AIR_TIV?: number;

  /**  */
  DS_HU_AAL_AIR_TIV_AmwinsMdl?: number;

  /**  */
  DS_HU_AAL_AIR_TIV_CatAal?: number;

  /**  */
  DS_HU_AAL_AIR_TIV_TigerCQ?: number;

  /**  */
  DS_HU_AAL_RMS?: number;

  /**  */
  DS_HU_AAL_RMS_AmwinsMdl?: number;

  /**  */
  DS_HU_AAL_RMS_CatAal?: number;

  /**  */
  DS_HU_AAL_RMS_DeductiblePct?: number;

  /**  */
  DS_HU_AAL_RMS_DeductiblePct_AmwinsMdl?: number;

  /**  */
  DS_HU_AAL_RMS_DeductiblePct_CatAal?: number;

  /**  */
  DS_HU_AAL_RMS_DeductiblePct_TigerCQ?: number;

  /**  */
  DS_HU_AAL_RMS_TigerCQ?: number;

  /**  */
  DS_HU_AAL_RMS_TIV?: number;

  /**  */
  DS_HU_AAL_RMS_TIV_AmwinsMdl?: number;

  /**  */
  DS_HU_AAL_RMS_TIV_CatAal?: number;

  /**  */
  DS_HU_AAL_RMS_TIV_TigerCQ?: number;

  /**  */
  DS_ModelRunDate_AmwinsMdl?: Date;

  /**  */
  DS_ProtectionClass?: string;

  /**  */
  DS_ProtectionClass_BAMS?: string;

  /**  */
  DS_TH_AAL_AIR?: number;

  /**  */
  DS_TH_AAL_AIR_AmwinsMdl?: number;

  /**  */
  DS_WaterDistance_MapRisk?: number;

  /**  */
  DS_WF_AAL_AIR?: number;

  /**  */
  DS_WF_AAL_AIR_AmwinsMdl?: number;

  /**  */
  DS_WF_Score_AnchorPoint?: number;

  /**  */
  DS_WF_Score_CoreLogic?: number;

  /**  */
  EN_CovI_Old?: number;

  /**  */
  EN_CovII_Old?: number;

  /**  */
  EN_CovIII_LU_Old?: number;

  /**  */
  EN_CovIV_Old?: number;

  /**  */
  EN_OtherFee_Annual?: number;

  /**  */
  EN_OtherTax1_Annual?: number;

  /**  */
  EN_OtherTax2_Annual?: number;

  /**  */
  EN_OtherTax3_Annual?: number;

  /**  */
  EN_PolicyFee_Annual?: number;

  /**  */
  EN_PremiumI_Annual?: number;

  /**  */
  EN_PremiumII_Annual?: number;

  /**  */
  EN_PremiumOptions_Annual?: number;

  /**  */
  EN_PremiumTotal_Annual?: number;

  /**  */
  EN_Reason?: string;

  /**  */
  EN_SubTotal_Annual?: number;

  /**  */
  EN_SurplusLinesTax_Annual?: number;

  /**  */
  EN_TaxableTotal_Annual?: number;

  /**  */
  Endorsed?: boolean;

  /**  */
  EndorseNr?: string;

  /**  */
  EnteredBy?: string;

  /**  */
  ErrorDetail?: string;

  /**  */
  ErrorStatus?: string;

  /**  */
  Expiring_PremiumAdjustment?: number;

  /**  */
  Expiring_PremiumAOP?: number;

  /**  */
  Expiring_PremiumEQ?: number;

  /**  */
  Expiring_PremiumFlood?: number;

  /**  */
  Expiring_PremiumLiability?: number;

  /**  */
  Expiring_PremiumOptionAddOns?: number;

  /**  */
  Expiring_PremiumWildFire?: number;

  /**  */
  Expiring_PremiumWind?: number;

  /**  */
  Expiring_SubTotal?: number;

  /**  */
  Expiring_TIV?: number;

  /**  */
  Expiring_TIV_NonTM?: number;

  /**  */
  Ext_Algo_PricingKey?: string;

  /**  */
  Ext_Algo_RatePlanEffectiveDate?: Date;

  /**  */
  Ext_Market_TransactionKey?: string;

  /**  */
  FormsList?: string;

  /**  */
  Ins_CompanyCode?: string;

  /**  */
  InternalRenewal?: boolean;

  /**  */
  Legacy_Algo_Subtotal?: number;

  /**  */
  Loc_Address?: string;

  /**  */
  Loc_AddressSame?: boolean;

  /**  */
  Loc_AlarmSystem?: string;

  /**  */
  Loc_AssociationName?: string;

  /**  */
  Loc_BuildingType?: string;

  /**  */
  Loc_City?: string;

  /**  */
  Loc_ConstructionExteriorWallType?: string;

  /**  */
  Loc_ConstructionType?: string;

  /**  */
  Loc_CountryCode?: string;

  /**  */
  Loc_County?: string;

  /**  */
  Loc_DataServiceRoot?: Amwins_SeaSys_Domain_Interfaces_IDataServiceRoot;

  /**  */
  Loc_ElementsAttached?: string;

  /**  */
  Loc_ElementsDetached?: string;

  /**  */
  Loc_FireZone?: string;

  /**  */
  Loc_FloodZone?: string;

  /**  */
  Loc_FloorNr?: number;

  /**  */
  Loc_FoundationType?: string;

  /**  */
  Loc_GatedCommunity?: string;

  /**  */
  Loc_GroundFloor?: boolean;

  /**  */
  Loc_HeatingType?: string;

  /**  */
  Loc_NrOfBuildings?: number;

  /**  */
  Loc_NrOfStories?: number;

  /**  */
  Loc_OccupancyType?: string;

  /**  */
  Loc_OccupantsInfo?: string;

  /**  */
  Loc_PC_DistToFireDept?: number;

  /**  */
  Loc_PC_DistToWaterSource?: number;

  /**  */
  Loc_PC_FireDeptResponseTimeMinutes?: number;

  /**  */
  Loc_ProtectionClass?: string;

  /**  */
  Loc_RentalIncomeMonthly?: number;

  /**  */
  Loc_RentalType?: string;

  /**  */
  Loc_ResistiveDevice_Fire?: string;

  /**  */
  Loc_ResistiveDevice_Opening?: string;

  /**  */
  Loc_ResistiveDevice_RoofToWall?: string;

  /**  */
  Loc_ResistiveDevice_WallToFoundation?: string;

  /**  */
  Loc_RoofCover?: string;

  /**  */
  Loc_RoofType?: string;

  /**  */
  Loc_SqFeet?: string;

  /**  */
  Loc_SqFeetCostEstimate?: number;

  /**  */
  Loc_State?: string;

  /**  */
  Loc_Territory?: string;

  /**  */
  Loc_UnitNr?: string;

  /**  */
  Loc_UnitType?: string;

  /**  */
  Loc_UpdateElectric?: string;

  /**  */
  Loc_UpdateHvac?: string;

  /**  */
  Loc_UpdatePlumbing?: string;

  /**  */
  Loc_UpdateRoof?: string;

  /**  */
  Loc_UW_AnimalAttackHistory?: string;

  /**  */
  Loc_UW_AnimalExposureDetail?: string;

  /**  */
  Loc_UW_BusinessOnPremises?: boolean;

  /**  */
  Loc_UW_LandAcresExposure?: number;

  /**  */
  Loc_UW_LavaZone?: string;

  /**  */
  Loc_UW_LiveStock?: string;

  /**  */
  Loc_UW_NrOfLiens3OrMore?: boolean;

  /**  */
  Loc_UW_PossessionTakenYear?: number;

  /**  */
  Loc_UW_PrevInsuranceCancelledOrNonRN?: string;

  /**  */
  Loc_UW_PrevInsuranceCompany?: string;

  /**  */
  Loc_UW_PrevInsuranceExpirationDate?: Date;

  /**  */
  Loc_UW_SwimmingPool?: string;

  /**  */
  Loc_UW_UnderConstruction?: string;

  /**  */
  Loc_UW_WoodStove?: boolean;

  /**  */
  Loc_WaterDistance?: string;

  /**  */
  Loc_WindPool?: boolean;

  /**  */
  Loc_YearConstructed?: string;

  /**  */
  Loc_YearConstructedEffective?: string;

  /**  */
  Loc_Zip?: string;

  /**  */
  Market?: string;

  /**  */
  Mortgagee1_ClosingDate?: Date;

  /**  */
  Mortgagee1_ID?: number;

  /**  */
  Mortgagee1_InvoiceMortg?: boolean;

  /**  */
  Mortgagee1_LoanNr?: string;

  /**  */
  Mortgagee1_SuffixCode?: string;

  /**  */
  Mortgagee2_ID?: number;

  /**  */
  Mortgagee2_LoanNr?: string;

  /**  */
  Mortgagee2_SuffixCode?: string;

  /**  */
  NonRenewedBy?: string;

  /**  */
  NonRenewedDate?: Date;

  /**  */
  NonRenewReason?: string;

  /**  */
  OtherFee?: number;

  /**  */
  OtherFee_Amount?: number;

  /**  */
  OtherFeeSelections?: string;

  /**  */
  OtherTax1?: number;

  /**  */
  OtherTax1_Amount?: number;

  /**  */
  OtherTax1Rate?: number;

  /**  */
  OtherTax2?: number;

  /**  */
  OtherTax2_Amount?: number;

  /**  */
  OtherTax2Rate?: number;

  /**  */
  OtherTax3?: number;

  /**  */
  OtherTax3_Amount?: number;

  /**  */
  OtherTax3Rate?: number;

  /**  */
  PolicyBegin?: Date;

  /**  */
  PolicyEnd?: Date;

  /**  */
  PolicyFee?: number;

  /**  */
  PolicyFee_Amount?: number;

  /**  */
  PolicyKey?: string;

  /**  */
  PremiumI?: number;

  /**  */
  PremiumI_Amount?: number;

  /**  */
  PremiumII?: number;

  /**  */
  PremiumII_Amount?: number;

  /**  */
  PremiumOptions?: number;

  /**  */
  PremiumOptions_Amount?: number;

  /**  */
  PremiumReceived?: number;

  /**  */
  PremiumTotal?: number;

  /**  */
  PremiumTotal_Amount?: number;

  /**  */
  ProcessingDate?: Date;

  /**  */
  ProcessingHoldClearDate?: Date;

  /**  */
  ProcessingHoldReasonCode?: string;

  /**  */
  ProcessingHoldThruDate?: Date;

  /**  */
  Program?: string;

  /**  */
  ProgramCode?: string;

  /**  */
  ProgramGroup?: string;

  /**  */
  PropertyTIV?: number;

  /**  */
  PropertyTIV_NonTM?: number;

  /**  */
  RecordInactive?: Date;

  /**  */
  RecordInactiveLogonKey?: string;

  /**  */
  RecordInactiveSystemsModuleKey?: string;

  /**  */
  RecordInsert?: Date;

  /**  */
  RecordInsertLogonKey?: string;

  /**  */
  RecordInsertSystemsModuleKey?: string;

  /**  */
  RecordUpdate?: Date;

  /**  */
  RecordUpdateLogonKey?: string;

  /**  */
  RecordUpdateSystemsModuleKey?: string;

  /**  */
  ReInstated?: boolean;

  /**  */
  ReNewed?: boolean;

  /**  */
  RewriteFrom?: string;

  /**  */
  RewriteToContractCode?: string;

  /**  */
  RewrittenAS?: string;

  /**  */
  RiskKey?: string;

  /**  */
  StatusActive?: boolean;

  /**  */
  SubTotal?: number;

  /**  */
  SubTotal_Amount?: number;

  /**  */
  SurplusLinesRate?: number;

  /**  */
  SurplusLinesTax?: number;

  /**  */
  SurplusLinesTax_Amount?: number;

  /**  */
  SysUpdateTime?: Date;

  /**  */
  TaxableTotal?: number;

  /**  */
  TaxableTotal_Amount?: number;

  /**  */
  TermBegin?: Date;

  /**  */
  TermBegin_Original?: Date;

  /**  */
  TermEnd?: Date;

  /**  */
  TermEnd_Original?: Date;

  /**  */
  TermOrigin?: string;

  /**  */
  TermRateCalcValidThru?: Date;

  /**  */
  TermYear?: string;

  /**  */
  TransactionType?: string;

  /**  */
  UnderwriterID?: number;

  /**  */
  UnderwritingItemList?: Amwins_SeaSys_Domain_Interfaces_IUnderwritingItem[];

  /**  */
  UnderwritingString?: string;

  /**  */
  WebApplicationID?: number;

  /**  */
  WebApplicationLogID_Calc?: number;

  /**  */
  WindCatastrophe?: string;

  /**  */
  Loc_OccupantsInfoDetail?: string;

  /**  */
  Loc_PC_AccessibleByRoadYearRound?: boolean;

  /**  */
  Loc_PC_OccupiedOrCheckedDaily?: boolean;

  /**  */
  Loc_PC_VisibleToNeighborsOrCheckedDaily?: boolean;

  /**  */
  Loc_ProtectiveDevice_Surveillance?: string;

  /**  */
  Loc_RentalNumberOfDays?: number;

  /**  */
  Loc_RentalManagementCompanyInvolved?: boolean;

  /**  */
  Loc_ResistiveDevice_Roof2ndWaterResist?: string;

  /**  */
  Loc_ResistiveDevice_RoofDeckAttachment?: string;

  /**  */
  Loc_ResistiveDevice_OpeningNonGlazed?: string;

  /**  */
  Loc_ResistiveDevice_Water?: string;

  /**  */
  Loc_RoofUpToCode?: boolean;

  /**  */
  Loc_UpdateElectricPartial?: string;

  /**  */
  Loc_UpdateHvacPartial?: string;

  /**  */
  Loc_UpdatePlumbingPartial?: string;

  /**  */
  Loc_UpdateRoofPartial?: string;

  /**  */
  Loc_UW_PrevInsuranceType?: string;

  /**  */
  Loc_UW_SwimmingPoolDivingBoardOrSlide?: boolean;

  /**  */
  TransactionSequenceNumber?: number;

  /**  */
  Loc_UW_TrampolineExposure?: string;

  /**  */
  Loc_OccupancySaleDetail?: string;

  /**  */
  Loc_VacancyDetail?: string;
}

export interface Amwins_SeaSys_Domain_Models_DebitCreditOption {
  /**  */
  OptionCode?: string;

  /**  */
  OptionDescription?: string;

  /**  */
  OptionAmount?: number;

  /**  */
  OptionPercent?: number;

  /**  */
  OptionRenewalActionCode?: string;

  /**  */
  OptionRenewalActionDescription?: string;

  /**  */
  CalculationBase?: string;

  /**  */
  CalculationReturnType?: string;

  /**  */
  SystemOptionOrderNr?: number;

  /**  */
  DependentParameter?: string;

  /**  */
  OptionItem?: number;
}

export interface Amwins_SeaSys_Domain_Models_UnderwritingItem {
  /**  */
  UnderwritingCode?: string;

  /**  */
  UnderwritingCode_Group?: string;

  /**  */
  UnderwritingCodeDescription?: string;

  /**  */
  UnderwritingCodeStatus?: number;

  /**  */
  UnderwritingCodeStatusDescription?: string;

  /**  */
  UnderwritingString_OfficeApproval_ContractCode?: string;

  /**  */
  UnderwritingString_OfficeApproval_Value?: string;
}

export interface Any {}

export interface Attestation {
  /**  */
  Text?: string;

  /**  */
  UserId?: string;

  /**  */
  UserEmail?: string;

  /**  */
  UserName?: string;

  /**  */
  DateTime?: Date;
}

export interface EFileDocument {
  /**  */
  Key?: string;

  /**  */
  RecordInsert?: Date;

  /**  */
  Name?: string;
}

export interface EndorsementV1 {
  /**  */
  Endorsement?: Amwins_Orbits_Contracts_MessageContracts_Endorsements_EndorsementStateUpdatedV1;

  /**  */
  id?: string;
}

export interface FloodCancellationForm {
  /**  */
  AccountId?: string;

  /**  */
  CancellationReason?: string;

  /**  */
  NoLongerNeededReason?: string;

  /**  */
  NewFloodProvider?: string;

  /**  */
  MailingAddress?: SubmissionRiskData;

  /**  */
  EffectiveDate?: Date;

  /**  */
  Attestation?: Attestation;
}

export interface FloodReinstatementForm {
  /**  */
  AccountId?: string;

  /**  */
  ReinstatementReason?: string;

  /**  */
  ReinstatementReasonOther?: string;

  /**  */
  TermEndDate?: Date;
}

export interface FormState {
  /**  */
  ResultId?: string;

  /**  */
  TemplateType?: FormTemplateType;

  /**  */
  status?: FormStateStatus;

  /**  */
  CorrelationId?: string;

  /**  */
  steps?: Any;

  /**  */
  data?: Any;

  /**  */
  id?: string;
}

export interface FormStateApiRequest {
  /**  */
  FormState?: FormState;

  /**  */
  FormStateRequest?: FormStateRequest;
}

export interface FormStateRequest {
  /**  */
  resultData?: Any;

  /**  */
  data?: Any;
}

export interface GateRules_PersistenceModelsGateDefinition {
  /**  */
  SalesChannel?: SalesChannel;

  /**  */
  DefaultVerdict?: GateRules_EnumsDefaultSalesChannelVerdict;

  /**  */
  Rules?: GateRulesRule[];

  /**  */
  _etag?: string;

  /**  */
  ExclusionMessage?: string;

  /**  */
  id?: string;
}

export interface GateRules_PersistenceModelsGateRunPersistence {
  /**  */
  ExecutedRules?: GateRulesExecutedRule[];

  /**  */
  Submission?: any | null;

  /**  */
  SalesChannelGateDefinition?: GateRules_PersistenceModelsGateDefinition;

  /**  */
  BasicExecutedSalesChannelGateResponse?: GateRules_ResponsesLimitedGateResponse;

  /**  */
  id?: string;
}

export interface GateRules_RequestModelsTestAGateRequestBody {
  /**  */
  Submission?: Any;

  /**  */
  GateDefinition?: GateRules_PersistenceModelsGateDefinition;
}

export interface GateRules_ResponsesAdminGateResponse {
  /**  */
  BasicResponse?: GateRules_ResponsesLimitedGateResponse;

  /**  */
  FullResult?: GateRules_PersistenceModelsGateRunPersistence;
}

export interface GateRules_ResponsesLimitedGateResponse {
  /**  */
  SubmissionId?: string;

  /**  */
  SalesChannel?: SalesChannel;

  /**  */
  Include?: boolean;

  /**  */
  RunCorrelationId?: string;

  /**  */
  ExclusionMessage?: string;
}

export interface GateRulesCheck {
  /**  */
  Order?: number;

  /**  */
  ComparatorType?: GateRuleComparatorType;

  /**  */
  Comparator?: string;

  /**  */
  OptionalDefaultValueWhenNull?: string;

  /**  */
  Path?: string;

  /**  */
  ExpectedValue?: string[];
}

export interface GateRulesExecutedCheck {
  /**  */
  Verdict?: GateRules_EnumsCheckVerdict;

  /**  */
  ActualValue?: any | null;

  /**  */
  Order?: number;

  /**  */
  ComparatorType?: GateRuleComparatorType;

  /**  */
  Comparator?: string;

  /**  */
  OptionalDefaultValueWhenNull?: string;

  /**  */
  Path?: string;

  /**  */
  ExpectedValue?: string[];
}

export interface GateRulesExecutedRule {
  /**  */
  Checks?: GateRulesExecutedCheck[];

  /**  */
  Name?: string;

  /**  */
  Verdict?: GateRules_EnumsRuleVerdict;

  /**  */
  ExclusionMessage?: string;
}

export interface GateRulesRule {
  /**  */
  Order?: number;

  /**  */
  Name?: string;

  /**  */
  VerdictOnMatch?: GateRules_EnumsRuleVerdict;

  /**  */
  Checks?: GateRulesCheck[];

  /**  */
  ExclusionMessage?: string;
}

export interface Insured {
  /**  */
  Id?: string;

  /**  */
  Type?: string;

  /**  */
  Active?: boolean;

  /**  */
  Name?: string;

  /**  */
  Salutation?: string;

  /**  */
  MiddleName?: string;

  /**  */
  Suffix?: string;

  /**  */
  OrgName?: string;

  /**  */
  BranchName?: string;

  /**  */
  Title?: string;

  /**  */
  Street1?: string;

  /**  */
  Street2?: string;

  /**  */
  City?: string;

  /**  */
  State?: string;

  /**  */
  Postal?: string;

  /**  */
  County?: string;

  /**  */
  Country?: string;

  /**  */
  GeoLatitude?: number;

  /**  */
  GeoLongitude?: number;

  /**  */
  Phone?: string;

  /**  */
  Mobile?: string;

  /**  */
  Updated?: Date;

  /**  */
  Email?: string;

  /**  */
  FirstName?: string;

  /**  */
  LastName?: string;

  /**  */
  HumanId?: number;

  /**  */
  OrgBranchId?: number;

  /**  */
  OrgId?: number;

  /**  */
  BranchId?: number;

  /**  */
  Role?: string;

  /**  */
  id?: string;
}

export interface IntegrationResult {
  /**  */
  success?: boolean;

  /**  */
  body?: Any;
}

export interface License {
  /**  */
  State?: string;

  /**  */
  Number?: string;
}

export interface LogicGridRequestEvent {
  /**  */
  EventType?: LogicGridRequestEventType;

  /**  */
  Class?: LogicGridRequestClass;

  /**  */
  CorrelationId?: string;

  /**  */
  LinkId?: string;

  /**  */
  WorkflowName?: string;

  /**  */
  RunName?: string;

  /**  */
  RunPath?: string[];

  /**  */
  Timestamp?: Date;

  /**  */
  Message?: string;

  /**  */
  UserEmail?: string;

  /**  */
  id?: string;
}

export interface LogicGridRequestStatus {
  /**  */
  Class?: LogicGridRequestClass;

  /**  */
  CorrelationId?: string;

  /**  */
  LinkId?: string;

  /**  */
  Status?: LogicGridStepStatus;

  /**  */
  Active?: boolean;

  /**  */
  Message?: string;

  /**  */
  Runs?: LogicGridStep[];

  /**  */
  Events?: LogicGridRequestEvent[];

  /**  */
  id?: string;
}

export interface LogicGridStep {
  /**  */
  Status?: LogicGridStepStatus;

  /**  */
  RunName?: string;

  /**  */
  Level?: number;

  /**  */
  Message?: string;

  /**  */
  Children?: LogicGridStep[];
}

export interface Market {
  /**  */
  Key?: string;

  /**  */
  Name?: string;

  /**  */
  id?: string;
}

export interface MessageQueuesMessageQueueMessage {
  /**  */
  Id?: string;

  /**  */
  SequenceNumber?: number;

  /**  */
  Topic?: string;

  /**  */
  Subscription?: string;

  /**  */
  Message?: string;
}

export interface MessageQueuesMessageQueueTopology {
  /**  */
  SubscribingApplications?: MessageQueuesSubscribingApplication[];
}

export interface MessageQueuesSubscribingApplication {
  /**  */
  ApplicationName?: string;

  /**  */
  SubscriptionTopics?: MessageQueuesSubscriptionTopic[];

  /**  */
  DeadLetterQueueCount?: number;
}

export interface MessageQueuesSubscriptionTopic {
  /**  */
  Topic?: string;

  /**  */
  DeadLetterQueueCount?: number;
}

export interface Microsoft_AspNetCore_Mvc_ProblemDetails {
  /**  */
  Type?: string;

  /**  */
  Title?: string;

  /**  */
  Status?: number;

  /**  */
  Detail?: string;

  /**  */
  Instance?: string;
}

export interface MoratoriumsMoratoriumBanner {
  /**  */
  MoratoriumInEffect?: boolean;

  /**  */
  BannerDisplayText?: string;
}

export interface PersistedModelBase {
  /**  */
  id?: string;
}

export interface PolicyTerm {
  /**  */
  Updated?: Date;

  /**  */
  Policies?: Quote[];

  /**  */
  TermStatus?: string;

  /**  */
  TPA?: string;

  /**  */
  PolicyDocuments?: EFileDocument[];

  /**  */
  AmlinkSubmissionFileDetailsUrl?: string;

  /**  */
  TransactionsHistory?: AccountViewTransaction[];

  /**  */
  Number?: string;

  /**  */
  Status?: PolicyStatus;

  /**  */
  PolicyTermStatus?: PolicyStatus;

  /**  */
  Type?: PolicyType;

  /**  */
  IsNewBusiness?: boolean;

  /**  */
  Product?: string;

  /**  */
  Risk?: Risk;

  /**  */
  SalesChannel?: string;

  /**  */
  SalesChannelModel?: Amwins_Orbits_Contracts_Models_SalesChannels_SalesChannel;

  /**  */
  Market?: Market;

  /**  */
  Insured?: Insured;

  /**  */
  AgentId?: number;

  /**  */
  Agent?: Agent;

  /**  */
  AgencyId?: number;

  /**  */
  Agency?: Agency;

  /**  */
  UnderwriterId?: number;

  /**  */
  Underwriter?: Underwriter;

  /**  */
  QuoteKey?: string;

  /**  */
  TermBegin?: Date;

  /**  */
  TermEnd?: Date;

  /**  */
  ProductType?: FormTemplateType;

  /**  */
  Balance?: number;

  /**  */
  SubmissionId?: string;

  /**  */
  SubmissionIdGuid?: string;

  /**  */
  SubmissionNumber?: string;

  /**  */
  HasOpenInspection?: boolean;

  /**  */
  guid?: string;

  /**  */
  HumanIdTags?: string;

  /**  */
  HumanIds?: number[];

  /**  */
  OrgBranchId?: number;

  /**  */
  OrgId?: number;

  /**  */
  key?: string;

  /**  */
  id?: string;
}

export interface PolicyV1 {
  /**  */
  Policy?: Amwins_Orbits_Contracts_Models_v1_Policies_PolicyV1;

  /**  */
  VersionNumber?: number;

  /**  */
  id?: string;
}

export interface Quote {
  /**  */
  Number?: string;

  /**  */
  SubmissionId?: string;

  /**  */
  GroupingId?: string;

  /**  */
  SalesChannel?: SalesChannel;

  /**  */
  UpdatedAt?: Date;

  /**  */
  Status?: QuoteStatus;

  /**  */
  DeclineType?: DeclineType;

  /**  */
  ErrorText?: string;

  /**  */
  DebugText?: string;

  /**  */
  Premium?: string;

  /**  */
  ServiceFee?: number;

  /**  */
  ServiceFeeMin?: number;

  /**  */
  ServiceFeeMax?: number;

  /**  */
  InspectionFee?: number;

  /**  */
  Taxes?: number;

  /**  */
  PriceStatus?: PriceStatus;

  /**  */
  PriceExpiration?: Date;

  /**  */
  Expiration?: Date;

  /**  */
  Total?: number;

  /**  */
  EffectiveDate?: QuoteValue;

  /**  */
  ExpirationDate?: QuoteValue;

  /**  */
  FormType?: QuoteValue;

  /**  */
  ContractFormType?: QuoteValue;

  /**  */
  CovA?: QuoteValue;

  /**  */
  CovB?: QuoteValue;

  /**  */
  CovC?: QuoteValue;

  /**  */
  CovD?: QuoteValue;

  /**  */
  LossAssessment?: QuoteValue;

  /**  */
  Liability?: QuoteValue;

  /**  */
  MedPay?: QuoteValue;

  /**  */
  PersonalInjury?: QuoteValue;

  /**  */
  ReplacementCostDwelling?: QuoteValue;

  /**  */
  ReplacementCostStructures?: QuoteValue;

  /**  */
  ReplacementCostContents?: QuoteValue;

  /**  */
  InflationGuard?: QuoteValue;

  /**  */
  BuildingOrdinanceOrLaw?: QuoteValue;

  /**  */
  WindDrivenRain?: QuoteValue;

  /**  */
  Theft?: QuoteValue;

  /**  */
  Mold?: QuoteValue;

  /**  */
  DomesticAnimalLiability?: QuoteValue;

  /**  */
  WaterBackup?: QuoteValue;

  /**  */
  WaterDamageSublimit?: QuoteValue;

  /**  */
  TotalPeril?: QuoteValue;

  /**  */
  WindHailPeril?: QuoteValue;

  /**  */
  WindHailFlatAmount?: QuoteValue;

  /**  */
  EarthquakePeril?: QuoteValue;

  /**  */
  EarthquakeFlatAmount?: QuoteValue;

  /**  */
  FloodPeril?: QuoteValue;

  /**  */
  FloodFlatAmount?: QuoteValue;

  /**  */
  DifferenceInConditions?: QuoteValue;

  /**  */
  WaterDamagePeril?: QuoteValue;

  /**  */
  WaterDamageFlatAmount?: QuoteValue;

  /**  */
  AdditionalTheft?: QuoteValue;

  /**  */
  BroadTheft?: QuoteValue;

  /**  */
  LimitedTheft?: QuoteValue;

  /**  */
  GolfCartPhysicalDamage?: QuoteValue;

  /**  */
  GreenUpgrades?: QuoteValue;

  /**  */
  IdentityFraudExp?: QuoteValue;

  /**  */
  RefrigeratedGoods?: QuoteValue;

  /**  */
  SpecialComputerCover?: QuoteValue;

  /**  */
  MechanicalBreakdownCover?: QuoteValue;

  /**  */
  HO6ExtensionCoverage?: QuoteValue;

  /**  */
  LossAssessmentEQ?: QuoteValue;

  /**  */
  RecommendedCoverageOption?: QuoteValue;

  /**  */
  CoverageModelType?: Amwins_Orbits_Contracts_Models_v2_CoverageModelType;

  /**  */
  Footnote?: string;

  /**  */
  QuoteApplicationFileRef?: string;

  /**  */
  FormBundleFileRef?: string;

  /**  */
  QuoteEstimateFileRef?: string;

  /**  */
  VersionNumber?: number;

  /**  */
  IsLatestQuote?: boolean;

  /**  */
  QuoteBindRequirements?: Amwins_Orbits_Contracts_Models_v2_Quotes_QuoteBindRequirementsV2;

  /**  */
  id?: string;
}

export interface QuoteError {
  /**  */
  ttl?: number;

  /**  */
  Number?: string;

  /**  */
  SubmissionId?: string;

  /**  */
  GroupingId?: string;

  /**  */
  SalesChannel?: SalesChannel;

  /**  */
  UpdatedAt?: Date;

  /**  */
  Status?: QuoteStatus;

  /**  */
  DeclineType?: DeclineType;

  /**  */
  ErrorText?: string;

  /**  */
  DebugText?: string;

  /**  */
  Premium?: string;

  /**  */
  ServiceFee?: number;

  /**  */
  ServiceFeeMin?: number;

  /**  */
  ServiceFeeMax?: number;

  /**  */
  InspectionFee?: number;

  /**  */
  Taxes?: number;

  /**  */
  PriceStatus?: PriceStatus;

  /**  */
  PriceExpiration?: Date;

  /**  */
  Expiration?: Date;

  /**  */
  Total?: number;

  /**  */
  EffectiveDate?: QuoteValue;

  /**  */
  ExpirationDate?: QuoteValue;

  /**  */
  FormType?: QuoteValue;

  /**  */
  ContractFormType?: QuoteValue;

  /**  */
  CovA?: QuoteValue;

  /**  */
  CovB?: QuoteValue;

  /**  */
  CovC?: QuoteValue;

  /**  */
  CovD?: QuoteValue;

  /**  */
  LossAssessment?: QuoteValue;

  /**  */
  Liability?: QuoteValue;

  /**  */
  MedPay?: QuoteValue;

  /**  */
  PersonalInjury?: QuoteValue;

  /**  */
  ReplacementCostDwelling?: QuoteValue;

  /**  */
  ReplacementCostStructures?: QuoteValue;

  /**  */
  ReplacementCostContents?: QuoteValue;

  /**  */
  InflationGuard?: QuoteValue;

  /**  */
  BuildingOrdinanceOrLaw?: QuoteValue;

  /**  */
  WindDrivenRain?: QuoteValue;

  /**  */
  Theft?: QuoteValue;

  /**  */
  Mold?: QuoteValue;

  /**  */
  DomesticAnimalLiability?: QuoteValue;

  /**  */
  WaterBackup?: QuoteValue;

  /**  */
  WaterDamageSublimit?: QuoteValue;

  /**  */
  TotalPeril?: QuoteValue;

  /**  */
  WindHailPeril?: QuoteValue;

  /**  */
  WindHailFlatAmount?: QuoteValue;

  /**  */
  EarthquakePeril?: QuoteValue;

  /**  */
  EarthquakeFlatAmount?: QuoteValue;

  /**  */
  FloodPeril?: QuoteValue;

  /**  */
  FloodFlatAmount?: QuoteValue;

  /**  */
  DifferenceInConditions?: QuoteValue;

  /**  */
  WaterDamagePeril?: QuoteValue;

  /**  */
  WaterDamageFlatAmount?: QuoteValue;

  /**  */
  AdditionalTheft?: QuoteValue;

  /**  */
  BroadTheft?: QuoteValue;

  /**  */
  LimitedTheft?: QuoteValue;

  /**  */
  GolfCartPhysicalDamage?: QuoteValue;

  /**  */
  GreenUpgrades?: QuoteValue;

  /**  */
  IdentityFraudExp?: QuoteValue;

  /**  */
  RefrigeratedGoods?: QuoteValue;

  /**  */
  SpecialComputerCover?: QuoteValue;

  /**  */
  MechanicalBreakdownCover?: QuoteValue;

  /**  */
  HO6ExtensionCoverage?: QuoteValue;

  /**  */
  LossAssessmentEQ?: QuoteValue;

  /**  */
  RecommendedCoverageOption?: QuoteValue;

  /**  */
  CoverageModelType?: Amwins_Orbits_Contracts_Models_v2_CoverageModelType;

  /**  */
  Footnote?: string;

  /**  */
  QuoteApplicationFileRef?: string;

  /**  */
  FormBundleFileRef?: string;

  /**  */
  QuoteEstimateFileRef?: string;

  /**  */
  VersionNumber?: number;

  /**  */
  IsLatestQuote?: boolean;

  /**  */
  QuoteBindRequirements?: Amwins_Orbits_Contracts_Models_v2_Quotes_QuoteBindRequirementsV2;

  /**  */
  id?: string;
}

export interface QuoteV1 {
  /**  */
  Quote?: Amwins_Orbits_Contracts_Models_v1_Quotes_QuoteV1;

  /**  */
  VersionNumber?: number;

  /**  */
  id?: string;
}

export interface QuoteValue {
  /**  */
  value?: any | null;

  /**  */
  Altered?: boolean;
}

export interface RequirementItem {
  /**  */
  Type?: RequirementType;

  /**  */
  Text?: string;

  /**  */
  Value?: Attestation;

  /**  */
  Files?: TempFileHeader[];
}

export interface RestrictedFeature {
  /**  */
  id?: string;

  /**  */
  key?: string;

  /**  */
  name?: string;

  /**  */
  isAdminManaged?: boolean;
}

export interface RestrictedFeatureUser {
  /**  */
  email?: string;

  /**  */
  restrictedFeatureIds?: string[];
}

export interface Risk {
  /**  */
  Type?: RiskType;

  /**  */
  Active?: boolean;

  /**  */
  Name?: string;

  /**  */
  Salutation?: string;

  /**  */
  MiddleName?: string;

  /**  */
  Suffix?: string;

  /**  */
  OrgName?: string;

  /**  */
  BranchName?: string;

  /**  */
  Title?: string;

  /**  */
  Street1?: string;

  /**  */
  Street2?: string;

  /**  */
  City?: string;

  /**  */
  State?: string;

  /**  */
  Postal?: string;

  /**  */
  County?: string;

  /**  */
  Country?: string;

  /**  */
  GeoLatitude?: number;

  /**  */
  GeoLongitude?: number;

  /**  */
  Phone?: string;

  /**  */
  Mobile?: string;

  /**  */
  Updated?: Date;

  /**  */
  Email?: string;

  /**  */
  FirstName?: string;

  /**  */
  LastName?: string;

  /**  */
  HumanId?: number;

  /**  */
  OrgBranchId?: number;

  /**  */
  OrgId?: number;

  /**  */
  BranchId?: number;

  /**  */
  Role?: string;

  /**  */
  id?: string;
}

export interface SBIXCityStateZip {
  /**  */
  Key?: string;

  /**  */
  Name?: string;

  /**  */
  AddressCityName?: string;

  /**  */
  AddressStateName?: string;

  /**  */
  AddressStateKey?: string;

  /**  */
  AddressPostalCodeName?: string;
}

export interface SBIXCountry {
  /**  */
  CountryCode?: string;

  /**  */
  CountryName?: string;

  /**  */
  CountryOrderNr?: number;

  /**  */
  IsEuropeanUnion?: boolean;
}

export interface SBIXPostalAddress {
  /**  */
  Street?: string;

  /**  */
  City?: string;

  /**  */
  State?: string;

  /**  */
  PostalCode?: string;

  /**  */
  CountryCode?: string;

  /**  */
  IsVerified?: boolean;
}

export interface SBIXRisk {
  /**  */
  Key?: string;

  /**  */
  Parent?: SBIXRisk;

  /**  */
  New?: SBIXRisk;

  /**  */
  Type?: SBIXRiskType;

  /**  */
  IsVerified?: boolean;

  /**  */
  Street1?: string;

  /**  */
  Street2?: string;

  /**  */
  Address?: string;

  /**  */
  StreetNumber?: string;

  /**  */
  StreetName?: string;

  /**  */
  UnitTypeKey?: string;

  /**  */
  UnitNumber?: string;

  /**  */
  City?: string;

  /**  */
  County?: string;

  /**  */
  State?: string;

  /**  */
  PostalCode?: string;

  /**  */
  CountryCode?: string;

  /**  */
  StreetNumberSource?: SBIXRiskDataSource;

  /**  */
  StreetNameSource?: SBIXRiskDataSource;

  /**  */
  UnitSource?: SBIXRiskDataSource;

  /**  */
  CountySource?: SBIXRiskDataSource;

  /**  */
  GeolocationLat?: number;

  /**  */
  GeolocationLng?: number;

  /**  */
  GeolocationVariance?: number;

  /**  */
  GeolocationSource?: SBIXRiskDataSource;

  /**  */
  ProtectionClass?: string;

  /**  */
  ProtectionClassSource?: SBIXRiskDataSource;

  /**  */
  ProtectionClassTimestamp?: Date;

  /**  */
  ProtectionClassInt?: number;

  /**  */
  ProtectionClassSuffix?: string;

  /**  */
  DistanceToCoast?: number;

  /**  */
  DistanceToCoastSource?: SBIXRiskDataSource;

  /**  */
  DistanceToCoastTimestamp?: Date;

  /**  */
  InWindPool?: boolean;

  /**  */
  InWindPoolSource?: SBIXRiskDataSource;

  /**  */
  InWindPoolTimestamp?: Date;

  /**  */
  LavaZone?: string;

  /**  */
  LavaZoneSource?: SBIXRiskDataSource;

  /**  */
  LavaZoneTimestamp?: Date;

  /**  */
  Details?: any | null[];
}

export interface SBIXRiskType {
  /**  */
  Key?: string;

  /**  */
  Name?: string;
}

export interface SeaSysMortgagee {
  /**  */
  MortgageeID?: number;

  /**  */
  MortgageeName?: string;

  /**  */
  MortgageeSuffixCode?: string;

  /**  */
  MortgageeAddress1?: string;

  /**  */
  MortgageeCity?: string;

  /**  */
  MortgageeState?: string;

  /**  */
  MortgageeZip?: string;

  /**  */
  MortgageeCountry?: string;

  /**  */
  MortgageeEffectiveDate?: Date;

  /**  */
  MortgageeExpirationDate?: Date;

  /**  */
  MortgageeOrgID?: number;

  /**  */
  MortgageeOrgBranchID?: number;

  /**  */
  MortgageeModifiedDate?: Date;

  /**  */
  MortgageeOrgBranchIsTrusted?: boolean;

  /**  */
  MortgageeOrgIsTrusted?: boolean;

  /**  */
  Active?: boolean;

  /**  */
  SeekDisplay?: string;
}

export interface SeaSysMortgageeOrg {
  /**  */
  ID?: number;

  /**  */
  Name?: string;

  /**  */
  SuffixCode?: string;

  /**  */
  Type?: string;

  /**  */
  SetupDate?: Date;

  /**  */
  EnteredBy?: string;

  /**  */
  ModifiedDate?: Date;

  /**  */
  Comments?: string;

  /**  */
  ActiveInUseCount?: number;

  /**  */
  EffectiveDate?: Date;

  /**  */
  ExpirationDate?: Date;

  /**  */
  ID_New?: number;

  /**  */
  IsTrusted?: boolean;

  /**  */
  Score?: number;

  /**  */
  Active?: boolean;
}

export interface SeaSysSysInsuranceCarriers {
  /**  */
  InsuranceCarrierCode?: string;

  /**  */
  InsuranceCarrierName?: string;

  /**  */
  InsuranceEntityTypeCode?: string;

  /**  */
  InsuranceRegulatoryTypeCode?: string;

  /**  */
  EffectiveDate?: Date;

  /**  */
  ExpirationDate?: Date;

  /**  */
  OrderGroupNr?: number;
}

export interface SeaSysSysState {
  /**  */
  StateID?: number;

  /**  */
  StateCode?: string;

  /**  */
  StateName?: string;

  /**  */
  CountryCode?: string;

  /**  */
  RequiredAuthorizedMarketRejections?: number;

  /**  */
  EffectiveDate?: Date;

  /**  */
  ExpirationDate?: Date;

  /**  */
  ShowDiligentEfforts?: boolean;
}

export interface SubmissionRiskData {
  /**  */
  RiskKey?: string;

  /**  */
  StreetAddress?: string;

  /**  */
  StreetNumber?: string;

  /**  */
  StreetName?: string;

  /**  */
  UnitType?: string;

  /**  */
  UnitNumber?: string;

  /**  */
  City?: string;

  /**  */
  State?: string;

  /**  */
  PostalCode?: string;

  /**  */
  County?: string;

  /**  */
  CountryCode?: string;

  /**  */
  Formatted?: string;

  /**  */
  Locked?: boolean;
}

export interface System_Security_Claims_Claim {
  /**  */
  Issuer?: string;

  /**  */
  OriginalIssuer?: string;

  /**  */
  Properties?: object;

  /**  */
  Subject?: System_Security_Claims_ClaimsIdentity;

  /**  */
  Type?: string;

  /**  */
  Value?: string;

  /**  */
  ValueType?: string;
}

export interface System_Security_Claims_ClaimsIdentity {
  /**  */
  AuthenticationType?: string;

  /**  */
  IsAuthenticated?: boolean;

  /**  */
  Actor?: System_Security_Claims_ClaimsIdentity;

  /**  */
  BootstrapContext?: any | null;

  /**  */
  Claims?: System_Security_Claims_Claim[];

  /**  */
  Label?: string;

  /**  */
  Name?: string;

  /**  */
  NameClaimType?: string;

  /**  */
  RoleClaimType?: string;
}

export interface System_Text_Json_JsonElement {
  /**  */
  ValueKind?: System_Text_Json_JsonValueKind;
}

export interface TempFileHeader {
  /**  */
  DisplayName?: string;

  /**  */
  FileName?: string;

  /**  */
  FileSize?: number;

  /**  */
  OrbitsTempFileId?: string;
}

export interface Toast {
  /**  */
  Class?: ToastClass;

  /**  */
  Message?: string;

  /**  */
  Link?: string;
}

export interface Underwriter {
  /**  */
  SeekDisplay?: string;

  /**  */
  AutocompleteDisplay?: string;

  /**  */
  key?: string;

  /**  */
  NotificationEmail?: string;

  /**  */
  Active?: boolean;

  /**  */
  Name?: string;

  /**  */
  Salutation?: string;

  /**  */
  MiddleName?: string;

  /**  */
  Suffix?: string;

  /**  */
  OrgName?: string;

  /**  */
  BranchName?: string;

  /**  */
  Title?: string;

  /**  */
  Street1?: string;

  /**  */
  Street2?: string;

  /**  */
  City?: string;

  /**  */
  State?: string;

  /**  */
  Postal?: string;

  /**  */
  County?: string;

  /**  */
  Country?: string;

  /**  */
  GeoLatitude?: number;

  /**  */
  GeoLongitude?: number;

  /**  */
  Phone?: string;

  /**  */
  Mobile?: string;

  /**  */
  Updated?: Date;

  /**  */
  Email?: string;

  /**  */
  FirstName?: string;

  /**  */
  LastName?: string;

  /**  */
  HumanId?: number;

  /**  */
  OrgBranchId?: number;

  /**  */
  OrgId?: number;

  /**  */
  BranchId?: number;

  /**  */
  Role?: string;

  /**  */
  id?: string;
}

export interface v2CancellationFormV2 {
  /**  */
  AccountId?: string;

  /**  */
  CancellationReason?: Amwins_Orbits_Contracts_Models_v1_PolicyCancellations_CancellationReason;

  /**  */
  CancellationSubReason?: Amwins_Orbits_Contracts_Models_v1_PolicyCancellations_CancellationSubReason;

  /**  */
  MissingRequiredDocument?: string;

  /**  */
  CancellationEffectiveDate?: Date;

  /**  */
  MailingAddress?: Amwins_Orbits_Contracts_Models_v1_InsuredInformation_MailingAddressV1;
}

export interface v2EndorsementV2 {
  /**  */
  Endorsement?: Amwins_Orbits_Contracts_MessageContracts_Endorsements_v2_EndorsementStateUpdatedV2_System_Nullable_System_Text_Json_JsonElement__System_Nullable_System_Text_Json_JsonElement;

  /**  */
  id?: string;
}

export interface v2PolicyV2 {
  /**  */
  Policy?: Amwins_Orbits_Contracts_Models_v2_Policies_PolicyV2_System_Nullable_System_Text_Json_JsonElement__System_Nullable_System_Text_Json_JsonElement;

  /**  */
  VersionNumber?: number;

  /**  */
  id?: string;
}

export interface v2QuoteV2 {
  /**  */
  Quote?: Amwins_Orbits_Contracts_Models_v2_Quotes_QuoteV2_System_Nullable_System_Text_Json_JsonElement__System_Nullable_System_Text_Json_JsonElement;

  /**  */
  VersionNumber?: number;

  /**  */
  id?: string;
}

export interface v2ReinstatementFormV2 {
  /**  */
  AccountId?: string;

  /**  */
  ReinstatementReason?: Amwins_Orbits_Contracts_Models_Reinstatements_ReinstatementReason;

  /**  */
  ReinstatementReasonOther?: string;

  /**  */
  TermEndDate?: Date;
}

export enum AccountDashboardMode {
  'Incomplete' = 'Incomplete',
  'Pending' = 'Pending'
}

export enum AccountPending {
  'Underwriter_Quote' = 'Underwriter_Quote',
  'Processing_Issuance' = 'Processing_Issuance',
  'Processing_Endorsement' = 'Processing_Endorsement',
  'Processing_Cancel' = 'Processing_Cancel',
  'Processing_Requirement' = 'Processing_Requirement',
  'Required_Document' = 'Required_Document',
  'Required_Payment' = 'Required_Payment'
}

export enum Amwins_Orbits_Contracts_Models_v1_CoverageInformation_WindDeductibleType {
  'Wind' = 'Wind',
  'NamedStorm' = 'NamedStorm',
  'Hurricane' = 'Hurricane'
}

export enum Amwins_PersonalLines_Moratorium_AdminUI_Generated_PerilType {
  'Flood' = 'Flood',
  'WindHurricane' = 'WindHurricane',
  'Wildfire' = 'Wildfire',
  'Earthquake' = 'Earthquake',
  'VolcanoLavaFlow' = 'VolcanoLavaFlow',
  'StrikesRiotsCivilCommotion' = 'StrikesRiotsCivilCommotion',
  'AllOtherPerils' = 'AllOtherPerils'
}

export enum Amwins_Portal_Api_ApiClients_Munich_Models_CoverageDwellingValuation {
  'INVALID' = 'INVALID',
  'v_dwv_rcv' = 'v_dwv_rcv',
  'v_dwv_acv' = 'v_dwv_acv',
  'v_dwv_frc' = 'v_dwv_frc'
}

export enum Amwins_Portal_Api_ApiClients_Munich_Models_CoveragePersonalPropertyValuation {
  'INVALID' = 'INVALID',
  'v_ppv_acv' = 'v_ppv_acv',
  'v_ppv_rcv' = 'v_ppv_rcv'
}

export enum Amwins_Portal_Api_ApiClients_Munich_Models_MunichClaimTypeOfLoss {
  'invalid' = 'invalid',
  'v_ctl_prop_fire' = 'v_ctl_prop_fire',
  'v_ctl_prop_hurricane_tropical_storm' = 'v_ctl_prop_hurricane_tropical_storm',
  'v_ctl_prop_hail' = 'v_ctl_prop_hail',
  'v_ctl_prop_wind' = 'v_ctl_prop_wind',
  'v_ctl_prop_water' = 'v_ctl_prop_water',
  'v_ctl_prop_sinkhole' = 'v_ctl_prop_sinkhole',
  'v_ctl_prop_mold' = 'v_ctl_prop_mold',
  'v_ctl_prop_theft' = 'v_ctl_prop_theft',
  'v_ctl_prop_ordinance_or_law' = 'v_ctl_prop_ordinance_or_law',
  'v_ctl_prop_water_backup_or_sump' = 'v_ctl_prop_water_backup_or_sump',
  'v_ctl_prop_quake' = 'v_ctl_prop_quake',
  'v_ctl_prop_flood' = 'v_ctl_prop_flood',
  'v_ctl_prop_freeze' = 'v_ctl_prop_freeze',
  'v_ctl_prop_wildfire' = 'v_ctl_prop_wildfire',
  'v_ctl_prop_other' = 'v_ctl_prop_other',
  'v_ctl_liab' = 'v_ctl_liab'
}

export enum Amwins_Portal_Api_ApiClients_Munich_Models_PolicyClass {
  'INVALID' = 'INVALID',
  'HO3' = 'HO3',
  'HO6' = 'HO6',
  'DP3' = 'DP3'
}

export enum Amwins_Portal_Api_ApiClients_Munich_Models_PolicyInsuredType {
  'INVALID' = 'INVALID',
  'v_it_individual' = 'v_it_individual',
  'v_it_llc_llp_corporation' = 'v_it_llc_llp_corporation',
  'v_it_trust_estate' = 'v_it_trust_estate',
  'v_it_condo_association' = 'v_it_condo_association'
}

export enum Amwins_Portal_Api_ApiClients_Munich_Models_PropertyConstructionType {
  'INVALID' = 'INVALID',
  'v_ct_fire_resistive' = 'v_ct_fire_resistive',
  'v_ct_frame' = 'v_ct_frame',
  'v_ct_joisted_masonry' = 'v_ct_joisted_masonry',
  'v_ct_masonry_non_combustible' = 'v_ct_masonry_non_combustible',
  'v_ct_non_combustible' = 'v_ct_non_combustible',
  'v_ct_semifire_resistive' = 'v_ct_semifire_resistive'
}

export enum Amwins_Portal_Api_ApiClients_Munich_Models_PropertyHeatSourceAlternate {
  'INVALID' = 'INVALID',
  'v_hsa_fireplace' = 'v_hsa_fireplace',
  'v_hsa_woodstove' = 'v_hsa_woodstove',
  'v_hsa_none' = 'v_hsa_none'
}

export enum Amwins_Portal_Api_ApiClients_Munich_Models_PropertyProtectionClass {
  'INVALID' = 'INVALID',
  'v_pc_1' = 'v_pc_1',
  'v_pc_2' = 'v_pc_2',
  'v_pc_3' = 'v_pc_3',
  'v_pc_4' = 'v_pc_4',
  'v_pc_5' = 'v_pc_5',
  'v_pc_6' = 'v_pc_6',
  'v_pc_7' = 'v_pc_7',
  'v_pc_8' = 'v_pc_8',
  'v_pc_8b' = 'v_pc_8b',
  'v_pc_9' = 'v_pc_9',
  'v_pc_9a' = 'v_pc_9a',
  'v_pc_10' = 'v_pc_10'
}

export enum Amwins_Portal_Api_ApiClients_Munich_Models_PropertyRoofConstructionType {
  'INVALID' = 'INVALID',
  'v_rct_unknown' = 'v_rct_unknown',
  'v_rct_metal_exposed_fasteners' = 'v_rct_metal_exposed_fasteners',
  'v_rct_metal_concealed_fasteners' = 'v_rct_metal_concealed_fasteners',
  'v_rct_builtup_with_gutters' = 'v_rct_builtup_with_gutters',
  'v_rct_builtup_without_gutters' = 'v_rct_builtup_without_gutters',
  'v_rct_concrete_tiles' = 'v_rct_concrete_tiles',
  'v_rct_wood_shakes' = 'v_rct_wood_shakes',
  'v_rct_normal_shingle' = 'v_rct_normal_shingle',
  'v_rct_normal_shingle_swr' = 'v_rct_normal_shingle_swr',
  'v_rct_shingle_hws' = 'v_rct_shingle_hws',
  'v_rct_shingle_hws_swr' = 'v_rct_shingle_hws_swr',
  'v_rct_concrete_roof' = 'v_rct_concrete_roof',
  'v_rct_bermuda' = 'v_rct_bermuda'
}

export enum Amwins_Portal_Api_ApiClients_Munich_Models_PropertyRoofShapeType {
  'INVALID' = 'INVALID',
  'v_rst_unknown' = 'v_rst_unknown',
  'v_rst_gable_roof_unknown' = 'v_rst_gable_roof_unknown',
  'v_rst_gable_roof_greater' = 'v_rst_gable_roof_greater',
  'v_rst_gable_roof_less' = 'v_rst_gable_roof_less',
  'v_rst_braced_gable_roof_less' = 'v_rst_braced_gable_roof_less',
  'v_rst_hip_roof_greater' = 'v_rst_hip_roof_greater',
  'v_rst_hip_roof_less' = 'v_rst_hip_roof_less',
  'v_rst_flat_roof_unknown' = 'v_rst_flat_roof_unknown',
  'v_rst_flat_roof_without' = 'v_rst_flat_roof_without',
  'v_rst_flat_roof_with' = 'v_rst_flat_roof_with'
}

export enum Amwins_Portal_Api_ApiClients_Munich_Models_RiskOccupancyType {
  'INVALID' = 'INVALID',
  'v_ot_owner' = 'v_ot_owner',
  'v_ot_secondary_residence' = 'v_ot_secondary_residence',
  'v_ot_tenant' = 'v_ot_tenant',
  'v_ot_3_4_family_not_owner' = 'v_ot_3_4_family_not_owner',
  'v_ot_builders_risk' = 'v_ot_builders_risk',
  'v_ot_vacant' = 'v_ot_vacant'
}

export enum Amwins_Portal_Api_ApiClients_Munich_Models_UpdateType {
  'v_updatetype_full' = 'v_updatetype_full',
  'v_updatetype_partial' = 'v_updatetype_partial'
}

export enum Amwins_Portal_Api_Services_ClarionDoorEnv {
  'Production' = 'Production',
  'Staging' = 'Staging'
}

export enum Amwins_Portal_Api_Services_LockService_Domain {
  'Account' = 'Account',
  'AutoSequence' = 'AutoSequence',
  'JsonDocument' = 'JsonDocument',
  'CacheService' = 'CacheService',
  'Test' = 'Test',
  'Partition' = 'Partition',
  'FormTemplate' = 'FormTemplate',
  'FormState' = 'FormState',
  'Email' = 'Email',
  'Migration' = 'Migration',
  'Etc' = 'Etc',
  'Agency' = 'Agency',
  'LegacyMessageQuorum' = 'LegacyMessageQuorum',
  'MessageQueue' = 'MessageQueue'
}

export enum DeclineType {
  'Not_Eligible' = 'Not_Eligible',
  'Sold_Out' = 'Sold_Out',
  'Unavailable' = 'Unavailable'
}

export enum FormStateEvent {
  'Submit' = 'Submit',
  'PreGate' = 'PreGate',
  'RiskAddress' = 'RiskAddress',
  'InsuredStep' = 'InsuredStep',
  'RiskStep' = 'RiskStep',
  'Reject_Digital' = 'Reject_Digital',
  'Save' = 'Save',
  'RenewalOffer' = 'RenewalOffer',
  'Refine' = 'Refine',
  'Recalc' = 'Recalc'
}

export enum FormStateStatus {
  'Pending' = 'Pending',
  'Active' = 'Active',
  'Complete' = 'Complete'
}

export enum FormTemplateType {
  'HO' = 'HO',
  'Human' = 'Human',
  'Digital' = 'Digital',
  'Munich' = 'Munich',
  'Flood' = 'Flood',
  'BindV1' = 'BindV1',
  'BindV2' = 'BindV2',
  'EndorsementV1' = 'EndorsementV1',
  'EndorsementV2' = 'EndorsementV2'
}

export enum GateRuleComparatorType {
  'String' = 'String',
  'Numeric' = 'Numeric'
}

export enum GateRuleNumericComparator {
  'EqualTo' = 'EqualTo',
  'LessThan' = 'LessThan',
  'LessThanOrEqualTo' = 'LessThanOrEqualTo',
  'GreaterThan' = 'GreaterThan',
  'GreaterThanOrEqualTo' = 'GreaterThanOrEqualTo'
}

export enum GateRuleStringComparator {
  'EqualTo' = 'EqualTo',
  'InList' = 'InList',
  'NotInList' = 'NotInList',
  'Matches' = 'Matches'
}

export enum GateRules_EnumsCheckVerdict {
  'Matched' = 'Matched',
  'NotMatched' = 'NotMatched',
  'Error' = 'Error'
}

export enum GateRules_EnumsDefaultSalesChannelVerdict {
  'Include' = 'Include',
  'Exclude' = 'Exclude'
}

export enum GateRules_EnumsRuleVerdict {
  'None' = 'None',
  'Include' = 'Include',
  'Exclude' = 'Exclude',
  'Error' = 'Error'
}

export enum LogicGridRequestClass {
  'Unknown' = 'Unknown',
  'SubmissionHuman' = 'SubmissionHuman',
  'SubmissionDigital' = 'SubmissionDigital',
  'HomeownerOcr' = 'HomeownerOcr',
  'RequirementsDigital' = 'RequirementsDigital',
  'AmlinkRequest' = 'AmlinkRequest',
  'DeadLetter' = 'DeadLetter'
}

export enum LogicGridRequestEventType {
  'Create' = 'Create',
  'Start' = 'Start',
  'Info' = 'Info',
  'Wait' = 'Wait',
  'End' = 'End',
  'Error' = 'Error',
  'Override' = 'Override'
}

export enum LogicGridStepStatus {
  'Running' = 'Running',
  'Waiting' = 'Waiting',
  'Complete' = 'Complete',
  'Error' = 'Error',
  'Override' = 'Override'
}

export enum MessageHubMethod {
  'DataService' = 'DataService',
  'Patch' = 'Patch',
  'Toast' = 'Toast',
  'Search' = 'Search',
  'Quote' = 'Quote',
  'LockContest' = 'LockContest',
  'FileDownload' = 'FileDownload',
  'Account' = 'Account',
  'Promise' = 'Promise',
  'Health' = 'Health',
  'Endorsement' = 'Endorsement'
}

export enum PolicyStatus {
  'Incomplete' = 'Incomplete',
  'Declined' = 'Declined',
  'Expired' = 'Expired',
  'Submitted' = 'Submitted',
  'Quoted' = 'Quoted',
  'Rejected' = 'Rejected',
  'BindRequest' = 'BindRequest',
  'Bound' = 'Bound',
  'Issued' = 'Issued',
  'RenewalOffered' = 'RenewalOffered',
  'NonRenewed' = 'NonRenewed',
  'Canceled' = 'Canceled',
  'Lapsed' = 'Lapsed'
}

export enum PolicyType {
  'Quote' = 'Quote',
  'Policy' = 'Policy'
}

export enum PriceStatus {
  'Pending' = 'Pending',
  'Indication' = 'Indication',
  'Firm' = 'Firm'
}

export enum QuoteStatus {
  'Ok' = 'Ok',
  'Error' = 'Error',
  'NotAvailable' = 'NotAvailable'
}

export enum RequirementType {
  'File' = 'File',
  'Attestation' = 'Attestation',
  'Payment' = 'Payment'
}

export enum RiskType {
  'Home' = 'Home',
  'Condo' = 'Condo'
}

export enum SBIXRiskDataSource {
  'Human' = 'Human',
  'Default' = 'Default',
  'Risk' = 'Risk',
  'AreaSample' = 'AreaSample',
  'RiskUnitRange' = 'RiskUnitRange',
  'Google' = 'Google',
  'USPS' = 'USPS',
  'BAMS' = 'BAMS',
  'MapRisk' = 'MapRisk',
  'TigerCQ' = 'TigerCQ',
  'Verikai' = 'Verikai'
}

export enum SalesChannel {
  'TestV0' = 'TestV0',
  'TestV1' = 'TestV1',
  'Digital' = 'Digital',
  'Human' = 'Human',
  'Munich' = 'Munich',
  'Vave' = 'Vave',
  'Hiscox' = 'Hiscox',
  'Chubb' = 'Chubb',
  'TFIA' = 'TFIA',
  'VavePersonalLines' = 'VavePersonalLines'
}

export enum System_Text_Json_JsonValueKind {
  'Undefined' = 'Undefined',
  'Object' = 'Object',
  'Array' = 'Array',
  'String' = 'String',
  'Number' = 'Number',
  'True' = 'True',
  'False' = 'False',
  'Null' = 'Null'
}

export enum ToastClass {
  'Information' = 'Information',
  'Error' = 'Error',
  'Alert' = 'Alert'
}
