import Vue from 'vue'
import Vuex, { StoreOptions } from 'vuex'
import { RootState, UpdateFormControlPayload, SetCurrentStepPayload } from './types';

import { user } from './user'
import { startForm } from './start'
import { quoteForm } from './quote'
import { additionalForm } from './additional'
import { endorsementForm } from './endorsement'
import { resource } from './resource'
import { lock } from './lock'
import { account } from './account'
import { health } from './health'

import { getNonNullAliasValue, getControlObj, getControlObjByNameOrAlias, setControlDataServiceValue, getControlObjFromV2DataPath } from '@/scripts/helper'
import { showControlHelper } from '@/scripts/show'
import { Toast } from '@/api/index.defs';
import ToastContent from '@/components/shared/ToastContent.vue'
import { FeatureFlagsService } from '@/api/FeatureFlagsService';
import Environment from '@/scripts/environment';

export function setControlOnFormData(formData: any, controlObject: any) {
    Vue.set(formData, controlObject.name, {
        "value": controlObject.value,
        "DataServiceValue": controlObject.DataServiceValue,
        "DataServiceType": controlObject.DataServiceType,
        "SourceValue": controlObject.SourceValue,
        "metadata": controlObject.metadata
    });
}
function processV2ContractPath(state: any, payload: any, controlObj: any) {
    const nameParts = controlObj.v2DataPath?.split('.') ?? payload.v2DataPath?.split('.') ?? [];
    if (nameParts.length >= 1) {
        //see if the contract control already exists
        const existingControl = getControlObj(state[payload.module].form, nameParts[0]);
        let useExistingControl = false
        if (existingControl !== null && existingControl !== undefined) {
            useExistingControl = true;
        }

        //does the contract control already exist, if so lets use it if not we make a new hidden control for the contract model
        let newControlObj = existingControl
        if (!newControlObj) {
            newControlObj = {}
            newControlObj.name = nameParts[0];
            newControlObj.type = "Hidden";
            newControlObj.v2DataPath = nameParts[0];
        }

        let newControlValue = useExistingControl && newControlObj.value ? newControlObj.value : {}

        //build the contract model
        if (nameParts.length === 1) {
            newControlValue = payload.value;
        }
        else {
            nameParts.slice(1).reduce((node: any, namePart: any, i: number) => {
                const nodeValue = (nameParts.length - 1) === ++i ? payload.value : node[namePart] || {};
                node[namePart] = nodeValue;
                return nodeValue;
                }, newControlValue);
        }

                                
        Vue.set(newControlObj, 'value', newControlValue)
        // set the value then add it the new control control to the section to persist the state the same as the other controls
        if (!useExistingControl) {
            let foundSection = false
            for (const step of state[payload.module].form.steps) {
                for (const section of step.sections) {
                    foundSection = section.controls.find((x: any) => x.name === controlObj.name);
                    if (foundSection) {
                        section.controls.push(newControlObj);
                        break;
                    }
                }
                break;
            }
            if (!foundSection) {
                state[payload.module].form.steps[0].sections[0].controls.push(newControlObj);
            }
        }
        
        // ensure the data node exists and set the new contract data on form data
        state[payload.module].form.data = state[payload.module].form.data ?? {}
        setControlOnFormData(state[payload.module].form.data, newControlObj);
    }
}

Vue.use(Vuex)
const store: StoreOptions<RootState> = {
    state: {
        blockIncrementer: 0,
        statuses: {},
        currentStep: null,
        messageHubMessageIds: [],
        formStateEvents: {},
        featureFlags: {}
    },
    mutations: {
        blockStart(state) {
            state.blockIncrementer++;
        },
        blockComplete(state) {
            if (state.blockIncrementer > 0) {
                state.blockIncrementer--;
            } else {
                state.blockIncrementer = 0;
            }
        },
        updateFormFromContract(state: any, payload: any) {
            let controlObj = getControlObjFromV2DataPath(state[payload.module].form, payload.v2DataPath);
            if (controlObj) {
                Vue.set(controlObj, 'value', payload.value);
                processV2ContractPath(state, payload, controlObj);
                setControlOnFormData(state[payload.module].form.data, controlObj);
            }
        },
        updateFormData(state: any, payload: any) {
            if (!state[payload.module]?.form) {
                return;
            }
            const controlObj = getControlObj(state[payload.module].form, payload.name);
            processV2ContractPath(state, payload, controlObj);
            setControlOnFormData(state[payload.module].form.data, controlObj);
        },
        updateFormControl(state: any, payload: UpdateFormControlPayload) {
            const controlObj = getControlObj(state[payload.module].form, payload.name);
            if (controlObj) {
                if (controlObj.alias) {
                    const aliasControlObject = getControlObj(state[payload.module].form, controlObj.alias);
                    if (controlObj.value !== payload.value) {
                        Vue.set(aliasControlObject, 'value', payload.value)
                        setControlOnFormData(state[payload.module].form.data, aliasControlObject);
                    }
                }
                const aliasValue = getNonNullAliasValue(state[payload.module].form, payload.name);
                if (!aliasValue && controlObj.value !== payload.value) {
                    Vue.set(controlObj, 'value', payload.value)
                }
                if (aliasValue && (controlObj.value == null || controlObj.value !== aliasValue)) {
                    Vue.set(controlObj, 'value', aliasValue)
                }

                processV2ContractPath(state, payload, controlObj);

                state[payload.module].form.data = state[payload.module].form.data ?? {}
                setControlOnFormData(state[payload.module].form.data, controlObj);
            } else {
                if (state[payload.module].form) {
                  const newControlObj = { name: payload.name, type: "Hidden", value: payload.value } as any
                  state[payload.module].form.steps[0].sections[0].controls.push(newControlObj);
                  state[payload.module].form.data = state[payload.module].form.data ?? {}
                  processV2ContractPath(state, payload, newControlObj);
                  setControlOnFormData(state[payload.module].form.data, newControlObj);
                }
            }
        },
        updateFormControlMetadata(state: any, payload: any) {
            const controlObj = getControlObj(state[payload.module].form, payload.name);
            if (controlObj) {
                Vue.set(controlObj, 'metadata', payload.metadata);
            }
        },
        clearFormControlMetadata(state: any, payload: any) {
            const controlObj = getControlObj(state[payload.module].form, payload.name);
            if (controlObj) {
                controlObj.metadata = null;
            }
        },
        updateFormControlDataServiceValues(state: any, payload: any) {
            if (!payload.Data ||
                !state['quoteForm'] || !state['quoteForm'].form ||
                payload.SubmissionId != state['quoteForm'].form.ResultId) 
            {
                console.debug(`updateFormControlDataServiceValues: Ignored update, current submissionId:${payload.SubmissionId}`, payload);
                return;
            }

            const keys = Object.keys(payload.Data);
            keys.forEach((k: string) => {
                const o = payload.Data[k];
                if (o && o.DataServiceType) {
                    setControlDataServiceValue('quoteForm', k, o.DataServiceValue, o.DataServiceType);
                }
            })
        },
        setCurrentStepNumber(state: any, payload: SetCurrentStepPayload) {
            state[payload.module].currentStepNumber = payload.stepNumber;
        },
        addMessageHubMessageId(state: any, id: any) {
            state.messageHubMessageIds.push(id);
        },
        updateFeatureFlag(state: any, payload: any) {
            Vue.set(state.featureFlags, payload.featureName, payload.isEnabled);
        },
        //keep track of the most recent form state events to ensure responding data service matches local state version
        registerFormStateEvent: (state: any, payload: any) => { //{contextId: string, eventType: string, eventId: string}
            if (!state.formStateEvents[payload.contextId]) state.formStateEvents[payload.contextId] = {};
            state.formStateEvents[payload.contextId][payload.eventType] = payload.eventId;
        }
    },
    actions: {
        toast({commit}, data: Toast) {
            Vue.$toast({component: ToastContent, props: {Message: data.Message, Link: data.Link}});
        },
        toastError({commit}, data: Toast) {
            Vue.$toast.error(data.Message);
        },
        toastSuccess({commit}, data: Toast) {
            Vue.$toast.success(data.Message, { timeout: 5000 });
        },
        openFive9({commit}) {
            document.getElementById('five9-maximize-button').click();
        },
        getFeatureFlags(context) {
            const featureFlags = Environment.FEATURE_FLAGS || [];
            featureFlags.forEach((featureName: string) => {
                FeatureFlagsService.isEnabled({featureName})
                .then(isEnabled => {
                    context.commit('updateFeatureFlag', {featureName, isEnabled});                    
                })
                .catch((e) => {
                    console.log(e)
                });
            })

            //refetch after 30 minutes
            setTimeout(() => {
                context.dispatch('getFeatureFlags');
            }, 1800000)
        }
    },
    getters: {
        showUWMenu: (state: any) => {
            return true;
            //return this.$router.name != 'Dashboard';
        },
        showBlockUI: (state: any) => {
            return state.httpCount > 0;
        },
        getMessageHubMessageAlreadyReceived: (state: any) => (id: any) => {
            const result = state.messageHubMessageIds.find((o: string) => o == id);
            if (result) return true;
            return false;
        },
        //determine if the passed event matches the current local state version
        formStateEventIsActive: (state: any) => (contextId: string, eventType: string, eventId: string) => {

            if (!state.formStateEvents[contextId]) return false;
            if (!state.formStateEvents[contextId][eventType]) return false;
            if (state.formStateEvents[contextId][eventType] == eventId) return true;

            return false;

        },
        showStep: (state: any, getters: any, rootState: any, rootGetters: any) => (module: string, step: any) => {
            if (!rootState[module]) return false;
            if (!step) return false;
            if (!step['show']) return true;
            const show = showControlHelper(module, step['show']);
            return show;
        },
        showSection: (state: any, getters: any, rootState: any, rootGetters: any) => (module: string, section: any) => {
            if (!rootState[module]) return false;
            if (!section) return false;

            if (section['show']) {
                const show = showControlHelper(module, section['show']);
                if (show == false) return show;
            }
            
            //hide if no controls in section are visible
            let visibleControl = false;

            if (!section.controls) return true;

            section.controls.forEach((c: any) => {
                const controlObj = getControlObj(rootState[module].form, c.name);
                if (controlObj && (!controlObj.config || !controlObj.config['show'])) {
                    visibleControl = true;
                }
                else if (controlObj && controlObj.config['show']) {
                    const showC = showControlHelper(module, controlObj.config['show']);
                    if (showC) visibleControl = true;
                }
            })

            return visibleControl;
        },
        showControl: (state: any, getters: any, rootState: any, rootGetters: any) => (module: string, name: string) => {
            if (!rootState[module]) return false;
            const controlObj = getControlObj(rootState[module].form, name);
            if (!controlObj) return false;
            if (!controlObj.config || !controlObj.config['show']) return true;
            const show = showControlHelper(module, controlObj.config['show']);
            return show;
        },
        getControlValue: (state: any, getters: any, rootState: any, rootGetters: any) => (module: string, name: string) => {
            if (!rootState[module]) return null;

            let value = null;
            const form = rootState[module].form;
            if (!form) {
                return null;
            }
            const controlObj = getControlObjByNameOrAlias(rootState[module].form, name);
            
            if (controlObj) {
                value = controlObj['value'];
            } else if (form && form.data && form.data[name]) {
                value = form.data[name].value;
            }

            return value;
        },
        getControl: (state: any, getters: any, rootState: any, rootGetters: any) => (module: string, name: string, property: string) => {
            if (!rootState[module]) return null;          
            const controlObj = getControlObj(rootState[module].form, name);
            return controlObj;
        },
        getNonNullAliasValue: (state: any, getters: any, rootState: any, rootGetters: any) => (module: string, aliasName: string) => {
            return getNonNullAliasValue(rootState[module].form, aliasName);
        },
        getFormDataValue: (state: any, getters: any, rootState: any, rootGetters: any) => (module: string, name: string): any => {
            if (!rootState[module] || !rootState[module].form) return null;
            if (!rootState[module].form.data) return null;
            return rootState[module].form.data[name];            
        },
        getCurrentStepNumber: (state: any, getters: any, rootState: any, rootGetters: any) => (module: string) => {
            if (!rootState[module]) return false;
            if (!rootState[module].currentStepNumber) {return 0;}
            return rootState[module].currentStepNumber;
        },
        getCurrentStep: (state: any, getters: any, rootState: any, rootGetters: any) => (module: string): any => {
            if (!rootState[module] || !rootState[module].form) return null;
            return rootState[module].form.steps[rootState[module].currentStepNumber];            
        },
        getForm: (state: any, getters: any, rootState: any, rootGetters: any) => (module: string) => {
            if (!rootState[module]) return null;
            return rootState[module].form;
        },
        isFeatureFlagEnabled: (state: any) => (featureName: string) => {
            return state.featureFlags[featureName] || false;
        }
    },
    modules: {
        user,
        startForm,
        quoteForm,
        additionalForm,
        endorsementForm,
        resource,
        lock,
        account,
        health
    }
}

export default new Vuex.Store<RootState>(store);