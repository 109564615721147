<template>
  <div>

    <v-btn x-small color="secondary" @click="openSubmissionJsonModal" v-if="isAdminOrSupport">
        Submission JSON
    </v-btn>   

    <Dialog :title="submissionId" :value="showSubmissionJsonModal" @close-dialog="closeSubmissionJsonModal" width="90%">
      <template v-slot:card-text>
          <div v-if="submission">
            <SubmissionMonaco 
              :submission="submission"
            ></SubmissionMonaco>
          </div>
          <div v-else>
            <Loading :size="64" class="text-center" /> 
          </div>
      </template>
      <template v-slot:card-actions>
          <v-btn color="secondary" text type="button" @click="closeSubmissionJsonModal">Close</v-btn>
      </template>
    </Dialog>
  </div>
</template>

<script>
import Dialog from '@/components/shared/Dialog.vue'
import SubmissionMonaco from '@/components/shared/SubmissionMonaco.vue'
import { SubmissionService } from '@/api/SubmissionService'

export default {
  name: 'SubmissionJson',
  components: {
    Dialog,
    SubmissionMonaco
  },
  data() {
    return {
      submission: null,
      showSubmissionJsonModal: false
    }
  },
  props: {
    submissionId: String
  },
  computed: {
    isAdminOrSupport() {
      return (this.$store.getters.getUserRole == 'admin' || this.$store.getters.getUserRole == 'support');
    }
  },
  methods: {
    openSubmissionJsonModal() {
      this.submission = null;
      this.showSubmissionJsonModal = true;

      SubmissionService.get({ 
          id: this.submissionId
      }).then((data) => {
        this.submission = data;
      })
      
    },
    closeSubmissionJsonModal() {
      this.showSubmissionJsonModal = false;
    }
  }
}
</script>
