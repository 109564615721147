<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12">
          <h1 class="mb-2">Catastrophe Closings</h1>
          <h3 class="mb-5 pl-5">Digital States Only - <em>see list in <a :href="host + '/documents/AmwinsIQ_PersonalLines_Overview.pdf'" target="_blank" style="text-decoration: underline;">Overview</a></em></h3>
          <v-card class="mb-4">
            <ResultsTable :tableHeaders="tableHeaders"
                          :results="results"
                          :loading="loadingCatastropheClosings"
                          rowkey="CatastropheClosingID"
                          sortBy="ExpirationDateTime"
                          :sortDesc="sortDesc"
                          show-expand>

              <template v-slot:EffectiveDateTime="{item}">
                {{ formatDateTimeForDisplay(item.EffectiveDateTime) }}
              </template>

              <template v-slot:ExpirationDateTime="{item}">
                {{ formatExpirationDate(item) }}
              </template>

              <template v-slot:expansion-panel="{item}">
                <v-container class="px-6">
                  <v-row>
                    <v-col cols="12">
                      <v-row>
                        <h4>Affected Areas</h4>
                      </v-row>
                      <v-row>
                        Counties: {{ item.FormattedCounties }}
                      </v-row>
                      <v-row>
                        Zip Codes:  {{ item.FormattedZipCodes }}
                      </v-row>
                    </v-col>
                  </v-row>
                </v-container>
              </template>

            </ResultsTable>
          </v-card>
        </v-col>

      </v-row>
    </v-container>

  </div>
</template>

<script>
import ResultsTable from '@/components/shared/ResultsTable.vue'
import { formatDateTimeForDisplay } from '@/scripts/helper'
import Environment from '@/scripts/environment'

  export default {
    name: 'CatastropheClosings',
    components: {
      ResultsTable,
    },
    data() {
      return {
        tableHeaders: [
          { text: "States", value: "FormattedStates", sortable: true, filterable: true },
          { text: "Catastrophe Name", value: "CatastropheName", sortable: true, filterable: false },
          { text: "Type", value: "CatastropheTypeDescription", sortable: true, filterable: false },
          { text: "Closing Date", value: "EffectiveDateTime", sortable: true, filterable: false },
          { text: "Opening Date", value: "ExpirationDateTime", sortable: true, filterable: false },
        ],
        sortDesc: true,
        host: Environment.API_HOST
      }
    },
    computed: {
      results() {
        return this.$store.getters['resource/getItems']
      },
      loadingCatastropheClosings() {
        return this.$store.getters['resource/loading']
      }
    },
    methods: {
      formatDateTimeForDisplay,
      formatExpirationDate(item) {
        if (item.IsCurrent) {
          return 'Current'
        } else {
          return formatDateTimeForDisplay(item.ExpirationDateTime)
        }
      }
    }
  }
</script>

<style scoped lang="scss">
  @import '@/style.scss';

  // a {
  //   text-decoration: none;
  //   color: $amwins-secondary-medium-blue !important;
  // }
</style>
