<template>
  <div>
    <v-container>

      <v-btn outlined class="go-back" @click="back">
        <i class="fas fa-chevron-left"></i>&nbsp;Go Back
      </v-btn>

      <h1>
        {{header}}
      </h1>

      <EndorsementHeader :account="account"
                         :policy="policy"
                         :premium="existingPremium"
                         :page="page"
                         @document="getDocument" />

      <EndorsementForm v-if="page == 1"
                       :form="endorsementForm"
                       :validateForm="validateForm"
                       :policy="policy"
                       :loading="loading"
                       :rating="rating"
                       :module="module"
                       :isV2SalesChannel="isV2SalesChannel"
                       :salesChannel="this.account.SalesChannel"
                       :premiumBearingEndorsement="isPremiumBearing"
                       :deductibleValues="deductibleValues"
                       :windHailPerilValueHolder="windHailPerilValueHolder"
                       :floodPerilValueHolder="floodPerilValueHolder"
                       :earthquakePerilValueHolder="earthquakePerilValueHolder"
                       :canNotEditCoverages="canNotEditCoverages"
                       @change="controlUpdated"
                       @valid="handleValidation" />
      <EndorsementReview v-else
                         :premium="existingPremium"
                         :newPremium="newPremium"
                         :endorsementEffectiveDate="endorsementEffectiveDate"
                         :changeset="changeset"
                         :transactionPricing="transactionPricing"
                         :annualPricing="annualPricing"
                         :loading="loading"
                         :validateForm="validateForm"
                         :policy="policy"
                         :isV2SalesChannel="isV2SalesChannel"
                         @valid="handleValidation" />

      <EndorsementFooter :page="page"
                         :premium="existingPremium"
                         :newPremium="newPremium"
                         :rated="rated"
                         :loading="loading"
                         :rating="rating"
                         :endorsement="endorsement"
                         :blockRating="blockRating"
                         :policyId="policyId"
                         :isV2SalesChannel="isV2SalesChannel"
                         @next="next"
                         @rate="rate"
                         @save="save"
                         @submit="submit" />

      <GenericDialog :value="reRateModal"
                     title="Update Endorsement Rating"
                     persistent
                     @close-dialog="reRateModal = false">

        <template v-slot:card-text>
          <div cols="12">
            This endorsement's rating must be updated to ensure accuracy when processing.
          </div>
        </template>

        <template v-slot:card-actions>
          <v-btn color="secondary" type="submit" outlined @click="checkRedirect">
            Go Back
          </v-btn>&nbsp;
          <v-btn color="secondary" type="submit" @click="reRate">
            Rate
          </v-btn>
        </template>

      </GenericDialog>

      <GenericDialog title="Endorsement Summary" :value="showSummary" width="1000px" @close-dialog="showSummary = false;">
        <template v-slot:card-text>
          <DocumentPreview v-bind="previewConfig" />
        </template>
        <template v-slot:card-actions>
          <v-btn color="secondary" text type="button" @click="showSummary = false">Close</v-btn>
        </template>
      </GenericDialog>
    </v-container>
  </div>
</template>

<script>
  import { FeatureFlagsService } from '@/api/FeatureFlagsService';
  import { AccountService } from '@/api/AccountService'
  import { PolicyService } from '@/api/PolicyService'
  import { SubmissionService } from '@/api/SubmissionService'
  import { FormStateService } from '@/api/FormStateService'
  import {
    mergeFormStates,
    updateFormStateV1,
    updateFormStateV2,
    filterSection,
    replaceCoverages,
    replaceCoveragesV2,
    replaceDeductiblesV2,
    addEndorsementDateV1,
    addEndorsementDateV2,
    mapPolicyToFormStateV1,
    mapPolicyToFormStateV2,
    addPaymentAndCommunicationData,
    updateFormStateValues,
    isPremiumBearingEndorsement,
    setEditRiskAndCoveragesPermissions
  } from '@/scripts/endorsements.js'  
  import { updateDeductibleValues, initializeDeductibles } from '@/scripts/sales-channel-deductible'
  import EndorsementHeader from '@/components/views/Account/EndorsementHeader.vue'
  import EndorsementFooter from '@/components/views/Account/EndorsementFooter.vue'
  import EndorsementReview from '@/components/views/Account/EndorsementReview.vue'
  import EndorsementForm from '@/components/views/Account/EndorsementForm.vue'
  import GenericDialog from '@/components/shared/Dialog.vue'
  import DocumentPreview from '@/components/views/Quote/DocumentPreview.vue'
  import { formatDateForDisplay, getControlObj, calculateTotalPremium } from '@/scripts/helper'
  import hub from '@/plugins/webapp-hub.js'
  import { createEndorsementRequestV2 } from '@/scripts/endorsementBuilder'
  import { UserProfileService } from '@/api/UserProfileService';

  export default {
    name: 'Endorsement',
    components: {
      EndorsementHeader,
      EndorsementFooter,
      EndorsementReview,
      EndorsementForm,
      GenericDialog,
      DocumentPreview
    },
    data() {
      return {
        account: {},
        submission: {},
        accountId: '',
        correctDiscrepancies: false,
        v1EndorsementsFeatureFlagEnabled: false,
        loading: false,
        page: 1,
        policy: {},
        readyForm: {},
        validateForm: false,
        formAction: '',
        rated: false,
        rating: false,
        module: 'endorsementForm',
        reRateModal: false,
        previewConfig: {},
        showSummary: false,
        blockRating: false,
        policyId: '',
        inspectionsReviewTeam: ['denise.read@amwins.com', 'missy.klor@amwins.com'],
        v2SalesChannels: ['VavePersonalLines'],
        deductibleValues: {
          windHailPerilValues: {
            flat: 0,
            percent: 0
          },
          floodPerilValues: {
            flat: 0,
            percent: 0
          },
          earthquakePerilValues: {
            flat: 0,
            percent: 0
          }
        },
        windHailPerilValueHolder: 0,
        floodPerilValueHolder: 0,
        earthquakePerilValueHolder: 0,
        localControls: [
          'WindPerilDeductible',
          'WindHailPerilIncrementer',
          'WindHailFlatAmount',

          'EarthquakePerilDeductible',
          'EarthquakePerilIncrementer',
          'EarthquakeFlatAmount',

          'FloodPerilDeductible',
          'FloodPerilIncrementer',
          'FloodFlatAmount'
        ]
      }
    },
    async created() {
      this.accountId = this.$route.query.accountId;
      this.correctDiscrepancies = (this.$route.query.correctDiscrepancies && (this.userIsOnInspectionsReviewTeam || this.isSupportOrHigher)) || false;
      if (this.$route.query.correctDiscrepancies && this.correctDiscrepancies === false) {
         // if the user is not on the inspections review team or an admin, redirect to endorsement page without correcting discrepancies query param
        this.$router.push({ path: '/account/endorsement', query: { accountId: this.storeAccount.id } })
      }
      this.loading = true

      if (this.accountId) {
        await this.getAccount();
      }

      this.$store.commit('account/clearEndorsement');
      this.$store.commit('endorsementForm/clearEndorsementForm');

      if (this.account.SalesChannel === 'VavePersonalLines') {
        await PolicyService.getV2({ submissionId: this.accountId })
          .then((response) => {
            if (response) {
              this.policyId = response.id
              this.policy = response
            }
          })
          .catch(async () => {
            await this.checkRedirect()
              .then(() => {
                this.$store.dispatch('toastError', { Message: 'Failed to retrieve policy data' })
              })
          })

        // wrap getSubmission with a check to the store to see if the endorsement is there
        // calling this even when there's a saved endorsement to get the original form values
        let submission = await this.getSubmission();
        if (submission) {
          if (this.role !== 'admin') {
            await this.getUserOnUnderwriterTeam()
          }  

          // Initialize the deductible percent and flat controls that were manually added to the formState
          this.initializeDeductibles({
            formType: this.policy.Policy.FormType.Value,
            deductibleValues: this.deductibleValues,
            WindHailFlatAmount: {
              value: this.policy.Policy.Coverages?.WindPerilDeductible?.value?.Flat?.Amount
            },
            FloodFlatAmount: {
              value: this.policy.Policy.Coverages?.FloodPerilDeductible?.value?.Flat?.Amount
            },
            EarthquakeFlatAmount: {
              value: this.policy.Policy.Coverages?.EarthquakePerilDeductible?.value?.Flat?.Amount
            },
            CovA: this.policy.Policy.Coverages.CovA,
            CovC: this.policy.Policy.Coverages.CovC,
          }, this.policy.Policy.SalesChannel.Value)

          setEditRiskAndCoveragesPermissions(this.role, (this.userIsOnInspectionsReviewTeam || this.isSupportOrHigher), this.correctDiscrepancies, this.policy.Policy.HasOpenInspection)
          let quoteFormState = await this.getFormStateObject(submission.FormState[submission.InitialForm]);
          let addlFormState = await this.getFormStateObject(submission.FormState.BindV2);
          let mergedForms = mergeFormStates(quoteFormState, addlFormState);
          mergedForms = addEndorsementDateV2(mergedForms, (this.userIsOnInspectionsReviewTeam || this.userIsUnderwriterOrUwTeamOrAdmin), this.policy);
          mergedForms = updateFormStateV2(this.policy.Policy, mergedForms, this.role, (this.userIsOnInspectionsReviewTeam || this.isSupportOrHigher));
          mergedForms = replaceCoveragesV2(this.policy.Policy, mergedForms, this.account.SalesChannel, submission.Data.CoverageOption.value.toUpperCase());
          mergedForms = replaceDeductiblesV2(mergedForms, this.account.SalesChannel, submission.Data.CoverageOption.value.toUpperCase(), this.deductibleValues, this.policy.Policy);
          mergedForms = filterSection(mergedForms, this.policy.Policy.CoverageModelType);
          mergedForms = addPaymentAndCommunicationData(mergedForms);
          this.readyForm = { steps: mergedForms };
          this.policy = mapPolicyToFormStateV2(this.policy);
          if (this.account.EndorsementFormStateId == undefined || this.account.EndorsementFormStateId == null) {
            this.$store.commit('endorsementForm/initEndorsementForm', { ...this.readyForm });
            this.readyForm = updateFormStateValues(this.readyForm, this.policy);
          }
          this.$store.commit('endorsementForm/initUnmodifiedEndorsementForm', { ...this.readyForm });
        }
      } else {
        await FeatureFlagsService.isEnabled({ featureName: "V1Endorsements" })
          .then((response) => {
            this.v1EndorsementsFeatureFlagEnabled = response;
          });

        // Automatically navigate away if the feature flag is disabled
        if (!this.v1EndorsementsFeatureFlagEnabled) {
          this.checkRedirect()
        }

        await PolicyService.get({ submissionId: this.accountId })
          .then((response) => {
            if (response) {
              this.policyId = response.id
              this.policy = response
            }
          })
          .catch(async () => {
            await this.checkRedirect()
              .then(() => {
                this.$store.dispatch('toastError', { Message: 'Failed to retrieve policy data' })
              })
          })

        // wrap getSubmission with a check to the store to see if the endorsement is there
        // calling this even when there's a saved endorsement to get the original form values
        let submission = await this.getSubmission();

        if (submission) {
          let quoteFormState = await this.getFormStateObject(submission.FormState[submission.InitialForm]);
          let addlFormState = await this.getFormStateObject(submission.FormState.BindV1);
          let mergedForms = mergeFormStates(quoteFormState, addlFormState);
          mergedForms = updateFormStateV1(mergedForms);
          mergedForms = replaceCoverages(mergedForms, this.policy.Policy.Header.SalesChannel.Value);
          mergedForms = addEndorsementDateV1(mergedForms);
          mergedForms = filterSection(mergedForms, 1);
          mergedForms = addPaymentAndCommunicationData(mergedForms);
          this.readyForm = { steps: mergedForms };
          this.policy = mapPolicyToFormStateV1(this.policy);
          this.readyForm = updateFormStateValues(this.readyForm, this.policy);
          this.$store.commit('endorsementForm/initUnmodifiedEndorsementForm', { ...this.readyForm });
          if (this.account.EndorsementFormStateId == undefined || this.account.EndorsementFormStateId == null) {
            this.$store.commit('endorsementForm/initEndorsementForm', { ...this.readyForm });
          }
        }       
      }

      if (this.account.EndorsementFormStateId != undefined && this.account.EndorsementFormStateId != null) {
        FormStateService.get({ formStateId: this.account.EndorsementFormStateId })
          .then(async (response) => {
            if (response) {
              this.readyForm = response.data
              this.$store.commit('endorsementForm/initEndorsementForm', { ...this.readyForm });

              // When returning to an endorsement, if there's Premium Bearing Changes and the endorsement was already rated, update EndorsementEffectiveDate to today and re-rate
              if (this.isPremiumBearing) {

                this.$store.commit('updateFormControl', {
                  module: this.module,
                  name: 'EndorsementEffectiveDate',
                  value: this.formatDateForDisplay(new Date(), true),
                });

                if (this.account.EndorsementId != undefined && this.account.EndorsementId != null) {
                  this.reRateModal = true
                }
              }
            }
          })
          .catch((err) => {
            this.$store.dispatch('toastError', { Message: 'Failed to retrieve FormState data' })
          })

        if (this.account.EndorsementId != undefined && this.account.EndorsementId != null) {
          await this.getEndorsement()
          this.rated = true
        }
      }

      this.loading = false
    },
    computed: {
      isSupportOrHigher() {
        return this.$store.getters.userHasRole('support') ||
          this.$store.getters.userHasRole('admin');
      },
      userIsOnInspectionsReviewTeamOrAdmin () {
        return this.userIsOnInspectionsReviewTeam || this.isSupportOrHigher;
      },
      canNotEditCoverages() {
        const canNotEditCoverages = (this.role != 'agent' && this.role != 'employee' && !this.userIsOnInspectionsReviewTeamOrAdmin) || this.hasOpenInspection
        return canNotEditCoverages
      },
      canNotEditRisk() {
        const canNotEditRisk = (this.role != 'agent' && this.role != 'employee' && !this.userIsOnInspectionsReviewTeamOrAdmin) || (!this.correctDiscrepancies && this.hasOpenInspection)
        return canNotEditRisk
      },
      hasOpenInspection() {
        return this.policy?.Policy?.HasOpenInspection ?? false;
      },
      header() {
        if (this.page == 1) return 'Here\'s a look at this policy.'

        return 'Finalize the Endorsement'
      },
      existingPremium() {
        if (!this.policy || !this.policy.Policy) {
          return 0
        }

        return this.calculateTotalPremium(this.policy.Policy.AnnualizedPricing)
      },
      newPremium() {
        if (!this.endorsement || this.endorsement == undefined) {
          return 0
        }

        return this.calculateTotalPremium(this.endorsement.Endorsement.AnnualPricing)
      },
      endorsementEffectiveDate() {
        if (!this.endorsement || this.endorsement == undefined) {
          return null
        }

        return this.formatDateForDisplay(this.endorsement.Endorsement.EndorsementEffectiveDate, true)
      },
      changeset() {
        if (!this.endorsement || this.endorsement == undefined) {
          return null
        }

        return this.endorsement.Endorsement.ChangedElements
      },
      annualPricing() {
        if (!this.endorsement || this.endorsement == undefined) {
          return null
        }

        return this.endorsement.Endorsement.AnnualPricing
      },
      transactionPricing() {
        if (!this.endorsement || this.endorsement == undefined) {
          return null
        }

        return this.endorsement.Endorsement.TransactionPricing
      },
      endorsement() {
        return this.$store.getters['account/getEndorsement'];
      },
      endorsementForm() {      
        return this.$store.getters['endorsementForm/getEndorsementForm'];
      },
      isPremiumBearing() {
        return isPremiumBearingEndorsement(this.endorsement)
      },
      role() {
        return this.$store.getters.getUserRole;
      },
      userEmail() {
        return this.$store.getters.getUserEmail;
      },
      userIsUnderwriterOrUwTeamOrAdmin() {
        if (this.role === 'admin') {
          return true
        }

        if (this.role === 'employee' && this.userOnUnderwriterTeam != null) {
          return this.submission?.Data?.Underwriter?.value?.Email === this.userEmail || this.userOnUnderwriterTeam
        }

        return false
      },
      userIsOnInspectionsReviewTeam() {
        const userEmail = this.$store.getters.getUserEmail
        return userEmail && this.inspectionsReviewTeam.includes(userEmail)
      },
      isV2SalesChannel() {
        return this.v2SalesChannels.includes(this.account.SalesChannel)
      },
      covA() {
        return this.$store.getters.getControl(this.module, 'CovA')
      },
      covC() {
        return this.$store.getters.getControl(this.module, 'CovC')
      },
    },
    methods: {
      getControlObj,
      formatDateForDisplay,
      updateDeductibleValues,
      initializeDeductibles,
      calculateTotalPremium,
      async getEndorsementFormState() {
        await FormStateService.get({ formStateId: this.account.EndorsementFormStateId })
          .then(async (response) => {
            if (response) {
              this.readyForm = response.data
              this.$store.commit('endorsementForm/initEndorsementForm', { ...this.readyForm });

              // When returning to an endorsement, if there's Premium Bearing Changes and the endorsement was already rated, update EndorsementEffectiveDate to today and re-rate
              if (this.isPremiumBearing) {

                this.$store.commit('updateFormControl', {
                  module: this.module,
                  name: 'EndorsementEffectiveDate',
                  value: this.formatDateForDisplay(new Date(), true),
                });

                if (this.account.EndorsementId != undefined && this.account.EndorsementId != null) {
                  this.reRateModal = true
                }
              }
            }
          })
          .catch((err) => {
            this.$store.dispatch('toastError', { Message: 'Failed to retrieve FormState data' })
          })
      },
      async getAccount() {
        await AccountService.get({ id: this.accountId })
          .then((data) => {
            if (data) {
              hub.attach('account', `${data.key.slice(1)}`);
              hub.attach('endorsement', `${data.key.slice(1)}`);
              this.account = data;
              this.$store.commit('account/updateAccount', data);
            }
          })
      },
      async checkRedirect() {
        if (this.account.id) {
          await this.returnToAccount();
        }
        else {
          this.$router.push({ path: '/dashboard' })
        }
      },
      async returnToAccount() {
        await this.$router.push({ path: '/account', query: { id: this.account.id } })
      },
      next() {
        this.loading = true
        this.validateForm = true
        this.formAction = 'next'
      },
      reRate() {
        this.reRateModal = false
        this.rate()
      },
      rate() {
        this.loading = true
        this.validateForm = true
        this.formAction = 'rate'
      },
      back() {
        if (this.page == 1) {
          this.returnToAccount()
        } else {
          window.scrollTo(0, 0);
          this.blockRating = true
          this.page = 1
        }
      },
      save() {
        this.validateForm = true
        this.formAction = 'save'
      },
      submit() {
        this.validateForm = true
        this.formAction = 'submit'
      },
      async saveFormState(formStateEvent, suppressToast = false) {
        const templateType = this.v2SalesChannels.includes(this.account.SalesChannel) ? 'EndorsementV2' : 'EndorsementV1';
        await FormStateService.put({
          accountId: this.accountId,
          templateType: templateType,
          formStateEvent: formStateEvent,
          body: this.endorsementForm
        })
          .then(async () => {
            if (!suppressToast) {
              this.$store.dispatch('toastSuccess', { Message: 'Endorsement saved successfully' })
            }
            // get account immediately after saving formState to get the new endorsement formState Id
            await this.getAccount();
          })
          .catch(() => {
            this.$store.dispatch('toastError', { Message: 'Failed to save endorsement' })
          })
      },
      async getSubmission() {
        let submission = null;
        await SubmissionService.get({ id: this.accountId })
          .then((data) => {
            submission = data;
            this.submission = data;
          })
        return submission;
      },
      async getFormStateObject(id) {
        let newData = null;
        await FormStateService.get({ resultId: null, formStateId: id })
          .then((data) => {
            newData = data;
          })
        return newData;
      },
      controlUpdated(control) {
        // This block is to manage controls manually added to the formState on endorsement creation
        let updatedControlType = ''
        if (this.isV2SalesChannel && this.localControls.includes(control.name)) {
          if (control.name === 'WindHailPerilIncrementer') {
            updatedControlType = 'windHail'
            this.updateDeductibles({peril: 'windHail', type:'percent',  value: control.value})
          }     
          if (control.name === 'WindHailFlatAmount') {
            updatedControlType = 'windHail'
            this.updateDeductibles({peril: 'windHail', type: 'flat',  value: control.value})
          }

          if (control.name === 'EarthquakePerilIncrementer') {
            updatedControlType = 'earthquake'
            this.updateDeductibles({peril: 'earthquake', type: 'percent',  value: control.value})
          }
          if (control.name === 'EarthquakeFlatAmount') {
            updatedControlType = 'earthquake'
            this.updateDeductibles({peril: 'earthquake', type: 'flat',  value: control.value})
          }

          if (control.name === 'FloodPerilIncrementer') {
            updatedControlType = 'flood'
            this.updateDeductibles({peril: 'flood', type: 'percent',  value: control.value})
          }
          if (control.name === 'FloodPerilFlatAmount') {
            updatedControlType = 'flood'
            this.updateDeductibles({peril: 'flood', type: 'flat',  value: control.value})
          }

          this.updateDeductibleFormStateValues(updatedControlType)

          // Allow re-ratings if the incrementor values change
          if (control.name === 'WindHailPerilIncrementer' || control.name === 'EarthquakePerilIncrementer' || control.name === 'FloodPerilIncrementer') {
            this.blockRating = false
            this.rated = false
          }

          return
        }

        const ctrl = this.getControlObj(this.endorsementForm, control.name)

        // The AdditionalInterestsList uses the SubFormTable control, so whenever the change event is triggered, we know to allow a re-rating
        if (control.name === 'AdditionalInterestsList') {
          this.blockRating = false
          this.rated = false
        }

        if (ctrl && ctrl.value != control.value) {
          this.blockRating = false
          this.rated = false
        }

        this.$store.commit('updateFormControl', {
          module: this.module,
          name: control.name,
          value: control.value
        });
      },
      updateDeductibleFormStateValues(controlName) {
        if (controlName === 'windHail') {
          this.$store.commit('updateFormControl', {
            module: this.module,
            name: 'WindPerilDeductible',
            v2DataPath: "Coverages.WindPerilDeductible.value",
            value: { 
              Flat: { Amount: this.deductibleValues.windHailPerilValues.flat },
              Percentage: { Percent: this.deductibleValues.windHailPerilValues.percent }
            }
          });
          this.$store.commit('updateFormControl', {
            module: this.module,
            name: 'WindHailFlatAmount',
            v2DataPath: 'Coverages.WindPerilDeductible.value.Flat.Amount',
            value: this.deductibleValues.windHailPerilValues.flat
          });
          this.$store.commit('updateFormControl', {
            module: this.module,
            name: 'WindHailPerilIncrementer',
            value: this.deductibleValues.windHailPerilValues.percent
          });
        }
        if (controlName === 'earthquake') {
          this.$store.commit('updateFormControl', {
            module: this.module,
            name: 'EarthquakePerilDeductible',
            v2DataPath: "Coverages.EarthquakePerilDeductible.value",
            value: { 
              Flat: { Amount: this.deductibleValues.earthquakePerilValues.flat },
              Percentage: { Percent: this.deductibleValues.earthquakePerilValues.percent }
            }
          });
          this.$store.commit('updateFormControl', {
            module: this.module,
            name: 'EarthquakeFlatAmount',
            v2DataPath: 'Coverages.EarthquakePerilDeductible.value.Flat.Amount',
            value: this.deductibleValues.earthquakePerilValues.flat
          });
          this.$store.commit('updateFormControl', {
            module: this.module,
            name: 'EarthquakePerilIncrementer',
            value: this.deductibleValues.earthquakePerilValues.percent
          });
        }

        if (controlName === 'flood') {
          this.$store.commit('updateFormControl', {
            module: this.module,
            name: 'FloodPerilDeductible',
            v2DataPath: "Coverages.FloodPerilDeductible.value",
            value: {
              Flat: { Amount: this.deductibleValues.floodPerilValues.flat },
              Percentage: { Percent: this.deductibleValues.floodPerilValues.percent }
            }
          });
          this.$store.commit('updateFormControl', {
            module: this.module,
            name: 'FloodFlatAmount',
            v2DataPath: 'Coverages.FloodPerilDeductible.value',
            value: this.deductibleValues.floodPerilValues.flat
          });
          this.$store.commit('updateFormControl', {
            module: this.module,
            name: 'FloodPerilIncrementer',
            value: this.deductibleValues.floodPerilValues.percent
          });
        }
      },
      updateDeductibles(event) {
        this.updateDeductibleValues({
          type: event.type,
          peril: event.peril,
          value: event.value,
          formType: this.policy.Policy.FormType.Value,
          windHailPerilValueHolder: this.windHailPerilValueHolder,
          floodPerilValueHolder: this.floodPerilValueHolder,
          earthquakePerilValueHolder: this.earthquakePerilValueHolder,
          CovA: this.covA,
          CovC: this.covC,
          deductibleValues: this.deductibleValues
        }, this.account.SalesChannel)
      },
      async sendEndorsementRequest() {
        this.$store.commit('account/clearEndorsement');

        let action = {}
        if (this.v2SalesChannels.includes(this.account.SalesChannel)) {
          const endorementEffectiveDateControl = this.getControlObj(this.endorsementForm, 'EndorsementEffectiveDate')
          if (!endorementEffectiveDateControl.value) {
            this.$store.commit('updateFormControl', {
              module: this.module,
              name: 'EndorsementEffectiveDate',
              value: this.formatDateForDisplay(new Date(), true),
            });
          
          }
          const newEndorsementRequest = createEndorsementRequestV2(this.policy.Policy, this.endorsementForm, this.correctDiscrepancies, this.canNotEditRisk, this.canNotEditCoverages, this.isSupportOrHigher)
          action = PolicyService.endorsementRequestV2({ body: newEndorsementRequest })
        } else {
          action = PolicyService.endorsementRequest({ formStateId: this.account.EndorsementFormStateId, submissionId: this.accountId })
        }

        await action
          .then(() => {
            this.rated = true;
          })
          .catch(() => {
            this.$store.dispatch('toastError', { Message: 'Failed to rate endorsement' })
          })
      },
      async submitEndorsement() {
        if (this.v2SalesChannels.includes(this.account.SalesChannel)) {
          await PolicyService.submitEndorsementV2({ accountId: this.accountId })
        } else {
          await PolicyService.submitEndorsement({ accountId: this.accountId })
        }
      },
      async getEndorsement() {
        let action = {}
        if (this.v2SalesChannels.includes(this.account.SalesChannel)){
          action = PolicyService.getEndorsementV2({ id: this.account.id})
        } else {
          action = PolicyService.getEndorsement({ id: this.account.id })
        }
        await action
          .then((response) => {
            if (response) {
              this.$store.commit('account/updateEndorsement', response);
            }
          })
          .catch((err) => {
            this.$store.dispatch('toastError', { Message: 'Failed to retrieve Endorsement data' })
          })
      },
      async getUserOnUnderwriterTeam() {
        await UserProfileService.userOnUnderwriterTeam({ email: this.submission?.Data?.Underwriter?.value?.Email })
          .then((data) => {
            this.userOnUnderwriterTeam = data;
        });
      },
      async handleValidation(valid) {
        if (valid) {
          this.loading = true
          switch (this.formAction) {
            case 'next':
              if (!this.blockRating) {
                await this.saveFormState('Save')
                  .then(async () => {
                    if (!this.rated) {
                      await this.sendEndorsementRequest()
                        .then(() => {
                          if (this.page < 2) {
                            this.page = 2
                            window.scrollTo(0, 0);
                          }
                        })
                    } else {
                      if (this.page < 2) {
                        this.page = 2
                        window.scrollTo(0, 0);
                      }
                    }
                  })
                  .finally(() => {
                    this.loading = false
                  })
              } else {
                this.loading = false
                if (this.page < 2) {
                  this.page = 2
                  window.scrollTo(0, 0);
                }
              }
              break;
            case 'rate':
              this.rating = true
              await this.saveFormState('Save')
                .then(async () => {
                  await this.sendEndorsementRequest()
                })
                .finally(() => {
                  this.loading = false
                  this.rating = false
                  this.blockRating = true
                })
              break;
            case 'save':
              await this.saveFormState('Save')
                .finally(() => {
                  this.loading = false
                })
              break;
            case 'submit':
              await this.saveFormState('Save')
                .then(async () => {
                  await this.submitEndorsement()
                    .then(async () => {
                      // save again after successful submission to clear out EndorsementId and EndorsementFormStateId from the account.
                      // we may need to clear these values out in a different part of the process, for example, after the result of the
                      // submission message is received successfully.
                      await this.saveFormState('Submit', true)
                        .then(async () => {
                          await this.checkRedirect()
                            .then(() => {
                              hub.detach('endorsement', `${this.account?.key.slice(1)}`);
                              this.$store.dispatch('toastSuccess', { Message: 'Endorsement submitted successfully' })
                            })
                        })
                    })
                    .catch(() => {
                      this.$store.dispatch('toastError', { Message: 'Failed to submit endorsement' })
                    })
                })
                .finally(() => {
                  this.loading = false
                })
              break;
          }
        }
        this.formAction = ''
        this.validateForm = false;
        this.loading = false
      },
      getDocument() {
        // v1 policies used to have an uppercase 'Value' for sales channel and now use a lowercase 'value'. Something must've changed in Core.
        // Keeping both so older policies can still endorse if their stored policy json is uppercase.
        this.previewConfig = {
          mode: this.policy.Policy.Header?.SalesChannel?.Value || this.policy.Policy.Header?.SalesChannel?.value || this.policy.Policy.SalesChannel?.Value
        };
                
        this.previewConfig.fileRefId = this.endorsement.Endorsement.EndorsementQuoteDocument.FileReferenceId;
        this.showSummary = true;
      },
    }
  }
</script>