import {
  Account,
  PolicyTerm,
  Quote,
  SalesChannel,
  QuoteStatus,
  DeclineType,
  PriceStatus,
  QuoteValue,
  Amwins_Orbits_Contracts_Models_v2_CoverageModelType,
  Amwins_Orbits_Contracts_Models_v2_Quotes_QuoteBindRequirementsV2,
  Amwins_Orbits_Contracts_Models_Inspections_InspectionType,
  EFileDocument,
  AccountViewTransaction,
  PolicyStatus,
  PolicyType,
  Risk,
  RiskType,
  Amwins_Orbits_Contracts_Models_SalesChannels_SalesChannel,
  Market,
  Insured,
  Agent,
  License,
  Agency,
  Underwriter,
  FormTemplateType,
  IList,
  List,
  IListResult,
  ListResultDto,
  IPagedResult,
  PagedResultDto,
  Dictionary,
  IDictionary,
  IRequestOptions,
  IRequestConfig,
  getConfigs,
  axios,
  basePath
} from './index.defs';

export class AccountService {
  /** Generate by swagger-axios-codegen */
  // @ts-nocheck
  /* eslint-disable */

  /**
   *
   */
  static get(
    params: {
      /**  */
      id?: string;
      /**  */
      fullPolicyTermHistory?: boolean;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Account> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Account';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { id: params['id'], fullPolicyTermHistory: params['fullPolicyTermHistory'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static addPending(
    params: {
      /**  */
      id?: string;
      /**  */
      tag?: any | null;
      /**  */
      key?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Account/Pending';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { id: params['id'], tag: params['tag'], key: params['key'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static delPending(
    params: {
      /**  */
      id?: string;
      /**  */
      tag?: any | null;
      /**  */
      key?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Account/Pending';

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);
      configs.params = { id: params['id'], tag: params['tag'], key: params['key'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updateStatus(
    params: {
      /**  */
      id?: string;
      /**  */
      status?: any | null;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Account/Status';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);
      configs.params = { id: params['id'], status: params['status'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updateUnderwriter(
    params: {
      /**  */
      id?: string;
      /**  */
      underwriterId?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Account/Underwriter';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);
      configs.params = { id: params['id'], underwriterId: params['underwriterId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static seek(
    params: {
      /**  */
      q?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Account[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Account/Seek';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { q: params['q'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static search(
    params: {
      /**  */
      query?: string;
      /**  */
      orgBranchId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Account[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Account/Search';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { query: params['query'], orgBranchId: params['orgBranchId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static dashboard(
    params: {
      /**  */
      mode?: any | null;
      /**  */
      scopeToUser?: boolean;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Account[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Account/Dashboard';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { mode: params['mode'], scopeToUser: params['scopeToUser'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static sync(
    params: {
      /**  */
      accountId?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Account/Sync';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { accountId: params['accountId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static teleport(
    params: {
      /**  */
      id?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Account/Teleport';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}
