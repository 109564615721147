<template>
  <span :style="{'color': color }" style="white-space: nowrap"><i v-if="icon" :class="icon"></i>&nbsp;{{text}}<template v-if="Type"> {{Type}}</template></span>
</template>

<script>

export default {
  name: 'PolicyStatus',
  data() {
    return {
    }
  },
  props: {
    Status: {
      type: String,
      default: ""
    },
    Type: {
      type: String,
      default: null
    }
  },
  computed: {
    icon() {
      let icon = null;
      switch (this.Status) {
        case "Incomplete": icon = 'fas fa-exclamation'; break;
        case "Bound": icon = "fas fa-check"; break;
        case "UpcomingTerm": icon = "fas fa-file-alt"; break;
        case "Renewed": icon = "fas fa-file-alt"; break;
        case "Active": icon = "fas fa-file-alt"; break;
        case "RenewalOffered": icon = "fas fa-clock"; break;
        case "Lapsed": icon = "fas fa-history"; break;
        case "NonRenewed": icon = "fas fa-history"; break;
        case "Canceled": icon = "fas fa-history"; break;
        case "Expired": icon = "fas fa-times"; break;
        case "Declined": icon = "fas fa-times"; break;
        case "Submitted": icon = "fas fa-check"; break;
        case "Quoted": icon = "fas fa-check"; break;
        case "Rejected": icon = "fas fa-times"; break;
        case "Issued": icon = "fas fa-check"; break;
        case "BindRequest": icon = "fas fa-clock"; break;
        default: break;
      }
      return icon;
    },
    color() {
      let color = null;
      switch (this.Status) {
        case "Incomplete": color = "#333333"; break;
        case "Bound": color = "#215479"; break;
        case "UpcomingTerm": color = "#3778A2"; break;
        case "Renewed": color = "#fdb714"; break;
        case "Active": color = "#00953B"; break;
        case "RenewalOffered": color = "#fdb714"; break;
        case "Lapsed": color = "#333333"; break;
        case "NonRenewed": color = "#333333"; break;
        case "Canceled": color = "#333333"; break;
        case "Expired": color = "#333333"; break;
        case "Declined": color = "#333333"; break;
        case "Submitted": color = "#215479"; break;
        case "Quoted": color = "#215479"; break;
        case "Rejected": color = "##9F2242"; break;
        case "Issued": color = "#00953B"; break;
        case "BindRequest": color = "#333333"; break;
        default: break;
      }
      return color;
    },
    text() {
      let text = this.Status;
      switch (this.Status) {
        case "UpcomingTerm": text = "Upcoming Term"; break;
        case "RenewalOffered": text = "Renewal Offered"; break;
        case "NonRenewed": text = "Non-Renewed"; break;
        case "BindRequest": text = "Bind Request"; break;
        default: break;
      }
      return text;
    }
  }
}
</script>
