<template>
  <div>
    <template v-if="invalidQuoteForm">
      <v-container>
        <a class="go-back" @click="backToQuote">
          <i class="fas fa-arrow-left"></i>&nbsp;BACK TO QUOTE
        </a>

        <v-alert type="error" color="#9F2242" dense>
          Invalid submission form.
        </v-alert>
      </v-container>
      
    </template>
   

    <AdditionalForm :formInit="form" v-if="form && additionalFormSubmission && additionalFormSubmission.id && !submissionIsExpired(submission)" :disabled="disabled || (submission && submission.Complete)" />
    <SubmissionExpirationDialog v-if="submission" :submission="submission" :form="form" />
  </div>
</template>

<script>
import AdditionalForm from '@/components/views/Quote/AdditionalForm.vue'
import { FormStateService } from '@/api/FormStateService'
import { SubmissionService } from '@/api/SubmissionService'
import { Amwins_Portal_Api_Services_LockService_Domain as Domain, FormTemplateType } from '@/api/index.defs'
import SubmissionExpirationDialog from '@/components/views/Quote/SubmissionExpirationDialog.vue'
import { submissionIsExpired } from '@/scripts/helper'
import Environment from '@/scripts/environment';
import hub from '@/plugins/webapp-hub.js'

export default {
  name: 'Additional',
  components: {
    AdditionalForm,
    SubmissionExpirationDialog
  },
  data() {
    return {
      form: null,
      submissionId: null,
      initialFormStateId: null,
      formStateId: null,
      lock: null,
      invalidQuoteForm: false,
      submission: null,
    }
  },

  computed: {
    disabled() {
      return this.lock == null && this.hoFormStateId != null;
    },
    additionalFormSubmission() {
      return this.$store.getters["additionalForm/getSubmission"];
    },
  },
  methods: {
    submissionIsExpired,
    getFormState(id) {
      return FormStateService.get({
        formStateId: id
      });
    },
    backToQuote() {
      this.$router.push({ path: '/quote/quote', query: { id: this.submissionId } })
    },
    checkLock() {
      const lock = this.$store.getters.getActiveLock;
      this.lock = (lock && lock.domain == Domain.FormState && lock.key == this.hoFormStateId) ? lock : null;
    }
  },
  created() {
    window.onbeforeunload = function() {
      this.$store.dispatch('deleteActiveLock')
    }
  },
  async mounted() {
    this.$store.commit('additionalForm/clearAdditionalForm');
    this.submissionId = this.$route.query.id;

    this.$store.watch(() => {
      this.checkLock();
    }, () => {},
    {
        immediate: true
    });
    if (this.submissionId) {

      const submission = await SubmissionService.get({ id: this.submissionId });      
      this.$store.commit('additionalForm/setSubmission', submission);
      this.submission = submission;
      hub.attach("account", `${this.submission.OrgId}/${this.submission.OrgBranchId}/${this.submission.id}`);

      if (submission.CurrentForm == FormTemplateType.HO) {
        this.backToQuote();
      }

      const quotes = this.submission.Quotes || [];
      let selectedQuote = null;
      if (this.submission.SelectedQuoteId) {
        selectedQuote = quotes.find(i => i.id == this.submission.SelectedQuoteId);
      } else {
        selectedQuote = quotes.find(i => i.SalesChannel == this.submission.CurrentForm);
      }

      if (selectedQuote) {
        this.$store.commit('additionalForm/setSelectedQuote', selectedQuote);
      } else {
        if (Environment.environment != 'localhost' && Environment.environment != 'development') {
          return;
        }
      }

      const initialForm = this.submission.InitialForm || 'HO';
      this.initialFormStateId = this.submission.FormState[initialForm];

      if (!this.lock) {
        this.$store.dispatch('requestLock', {
          domain: Domain.FormState,
          key: this.initialFormStateId
        })
      }

      FormStateService.get({
        resultId: this.submissionId,
        formStateId: submission.FormState[submission.CurrentForm]
      }).then((result) => {
        this.$store.commit('quoteForm/initQuoteForm', result);
        this.form = result;
        });

    } else {
      this.$router.push({ path: '/dashboard' })
    }
  },
  beforeRouteLeave(to, from, next) {
      if (to.path != '/quote/quote') {
        this.$store.dispatch('deleteActiveLock').then(() => {
          next();
        })
      } else {
        next();
      }
  }
  

}
</script>
