<template>
  <div>
    <v-container>
      <v-card class="mb-4">
        <v-card-text>
          <ResultsTable :tableHeaders="tableHeaders"
            :results="messageQueueDeadLetters"
            :loading="messageQueueDeadLettersLoading">
          


            <template v-slot:Actions="{ item }">
  <v-checkbox
    v-model="selectedItems"
    :value="item.Id"
    color="primary"
    hide-details
  ></v-checkbox>
</template>

            <template v-slot:Id="{item}">
                <span>{{ item.Id }}</span>
            </template>

            <template v-slot:Message="{item}">
                <!-- TODO: Why is this component getting wider with each row?  -->
                    <MonacoEditor
                    language="json"
                    width="750px"

                    :value="item.Message"
                    :options="monacoOptions" />
            </template>
          </ResultsTable>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import MonacoEditor from 'monaco-editor-vue'
import ResultsTable from '@/components/shared/ResultsTable.vue'

export default {
  name: 'MessageQueueDeadLettersTable',
  components: {
    MonacoEditor,
    ResultsTable
  },
  props: {
    messageQueueDeadLettersLoading: Boolean,
    messageQueueDeadLetters: [],
    initialSelectedItems: {
      type: Array,
      default: () => [] // Default value if not provided by parent
    }
   // checkedItems: []
   
  },
  data() {
    return {
      tableHeaders: [
        { text: "Actions", value: "Actions", sortable: false, filterable: false },
        { text: "Id", value: "Id", sortable: true, filterable: true, key: 'Id' },
        { text: "Message Contents", value: "Message", sortable: true, filterable: true, key: 'Message' },
      ],
      monacoOptions: {
        colorDecorators: true,
        readOnly: true
      },
      selectedItems: this.initialSelectedItems
    }
  },
  watch: {
    messageQueueDeadLetters(newVal) {
      // Reset selectedItems when items prop changes
      this.selectedItems = this.initialSelectedItems;
    },
    selectedItems(newVal) {
      // Emit event to parent when selected items change
      this.$emit('update:selectedItems', newVal);
    }
  },
  methods: {
    requeue(message) {
        this.$emit('requeueEvent', message);
    },
    remove(message) {
        this.$emit('removeEvent', message);
    }
  }
}
</script>