import {
  Amwins_SeaSys_Domain_Models_ApplicationView,
  Amwins_SeaSys_Domain_Models_DebitCreditOption,
  Amwins_SeaSys_Domain_Interfaces_IApplicant,
  Amwins_SeaSys_Domain_Interfaces_IClaimsHistoryItem,
  Amwins_SeaSys_Domain_Interfaces_IAddtionalInterest,
  Amwins_SeaSys_Domain_Interfaces_IDiligentEffortContact,
  Amwins_SeaSys_Domain_Interfaces_IDataServiceRoot,
  Amwins_SeaSys_Domain_Interfaces_IAttestationModel,
  Amwins_Orbits_Contracts_Models_Submissions_IAttestationV0,
  Amwins_SeaSys_Domain_Interfaces_IOtherRisksAssociated,
  Amwins_SeaSys_Domain_Interfaces_IRiskModel,
  Amwins_SeaSys_Domain_Interfaces_IUnderwritingItem,
  Quote,
  SalesChannel,
  QuoteStatus,
  DeclineType,
  PriceStatus,
  QuoteValue,
  Amwins_Orbits_Contracts_Models_v2_CoverageModelType,
  Amwins_Orbits_Contracts_Models_v2_Quotes_QuoteBindRequirementsV2,
  Amwins_Orbits_Contracts_Models_Inspections_InspectionType,
  Any,
  SBIXRisk,
  SBIXRiskType,
  SBIXRiskDataSource,
  SBIXPostalAddress,
  Amwins_SeaSys_Domain_Models_UnderwritingItem,
  LogicGridRequestStatus,
  LogicGridRequestClass,
  LogicGridStep,
  LogicGridStepStatus,
  LogicGridRequestEvent,
  LogicGridRequestEventType,
  Amwins_SeaSys_Domain_Models_ApplicationViewLog,
  IList,
  List,
  IListResult,
  ListResultDto,
  IPagedResult,
  PagedResultDto,
  Dictionary,
  IDictionary,
  IRequestOptions,
  IRequestConfig,
  getConfigs,
  axios,
  basePath
} from './index.defs';

export class SbixService {
  /** Generate by swagger-axios-codegen */
  // @ts-nocheck
  /* eslint-disable */

  /**
   *
   */
  static getCatastrophies(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/SBIX/Catastrophe';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getPolicyTransactionHistory(
    params: {
      /**  */
      policyKey?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/SBIX/PolicyTransactionHistory';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { policyKey: params['policyKey'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static saveWebApplication(
    params: {
      /**  */
      body?: Amwins_SeaSys_Domain_Models_ApplicationView;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Quote> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/SBIX/SaveWebApplication';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAgencyReport(
    params: {
      /**  */
      key: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/SBIX/Agency/Report/{key}';
      url = url.replace('{key}', params['key'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static risk(
    params: {
      /**  */
      body?: Any;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SBIXRisk> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/SBIX/Risk';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static riskCheck(
    params: {
      /**  */
      healZipOrCity?: boolean;
      /**  */
      body?: Any;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SBIXRisk> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/SBIX/Risk/Check';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { healZipOrCity: params['healZipOrCity'] };
      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static postalAddress(
    params: {
      /**  */
      body?: SBIXPostalAddress;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SBIXPostalAddress> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/SBIX/PostalAddress';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static underwritingItemList(
    params: {
      /**  */
      policyKey?: string;
      /**  */
      head?: boolean;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Amwins_SeaSys_Domain_Models_UnderwritingItem[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/SBIX/UnderwritingItemList';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { PolicyKey: params['policyKey'], head: params['head'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static requirements(
    params: {
      /**  */
      accountId?: string;
      /**  */
      policyKey?: string;
      /**  */
      body?: object;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<LogicGridRequestStatus> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/SBIX/Requirements';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { AccountId: params['accountId'], PolicyKey: params['policyKey'] };
      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static calc(
    params: {
      /**  */
      skipView?: boolean;
      /**  */
      body?: Amwins_SeaSys_Domain_Models_ApplicationViewLog;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Amwins_SeaSys_Domain_Models_ApplicationView> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/SBIX/Calc';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { skipView: params['skipView'] };
      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static webApplication(
    params: {
      /**  */
      salesChannel?: any | null;
      /**  */
      submit?: boolean;
      /**  */
      body?: Any;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Amwins_SeaSys_Domain_Models_ApplicationView> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/SBIX/WebApplication';

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);
      configs.params = { salesChannel: params['salesChannel'], submit: params['submit'] };
      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}
