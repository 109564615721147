var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-container',[_c('h1',[_vm._v("Agency")]),(_vm.isSupport || _vm.isAdmin)?_c('v-card',{staticClass:"pa-4"},[_c('div',{staticClass:"d-flex"},[_c('DynamicFormControl',{attrs:{"control":{
                      value: _vm.selectedAgency,
                      name: 'Agency',
                      type: 'AgencyAutocomplete',
                      config: {
                          label: 'Search for Agency',
                          col: 12,
                          short: true,
                          clearable: true
                      }
                  }},on:{"change":function($event){return _vm.selectedAgencyChanged($event.value)}}})],1)]):_vm._e(),(_vm.showMyAgencies)?_c('v-card',{staticClass:"pa-4"},[_c('div',{staticClass:"d-flex"},[_c('DynamicFormControl',{attrs:{"control":{
                      value: _vm.selectedAgency,
                      name: 'Agency',
                      type: 'Dropdown',
                      config: {
                          label: 'Selected Agency Branch',
                          col: 12,
                          short: true,
                          clearable: false,
                          options: _vm.agencies,
                          itemText: 'BranchName',
                          itemValue: 'id',
                          returnObject: true
                      }
                  }},on:{"change":function($event){return _vm.selectedAgencyChanged($event.value)}}})],1)]):_vm._e(),(_vm.selectedAgency)?_c('v-row',{staticClass:"mt-4"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"d-flex align-content-stretch flex-grow-1"},[_c('div',{staticClass:"insured-details pa-6 pb-4 flex-grow-1"},[_c('h3',{staticClass:"mb-3 text-bold"},[_vm._v(_vm._s(_vm.selectedAgency.OrgName))]),_c('p',{staticClass:"mb-1"},[_vm._v(_vm._s(_vm.selectedAgency.Street1))]),_c('p',{staticClass:"mb-1"},[_vm._v(_vm._s(_vm.selectedAgency.City)+", "+_vm._s(_vm.selectedAgency.State)+" "+_vm._s(_vm.selectedAgency.Postal)+" "+_vm._s(_vm.selectedAgency.Country))])])])],1)],1):_vm._e(),(_vm.selectedAgency)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"pa-6"},[_c('h2',[_vm._v("Reports")]),_c('AgencyReports',{attrs:{"orgBranchId":_vm.selectedAgency.OrgBranchId}})],1)],1)],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }