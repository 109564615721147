<template>
  <div v-if="slots.content || val">
    <label :class="['display-label', 'mb-2', { hasError: errors && errors.length }]">{{label}}</label>
    <FormLabel v-if="val" :label="val" bold />
    <!--slot to be used to inject customized display text-->
    <slot name="content" v-else></slot>

    <!--Read-only values shouldn't normally have errors, but it's possible if there's custom validations on the form-->
    <ErrorMessages :errors="errors"></ErrorMessages>
  </div>
</template>

<script>
  import FormLabel from '@/components/form/FormLabel.vue'
  import { useSlots } from 'vue'

  export default {
    name: 'ReadOnlyDisplay',
    components: {
      FormLabel
    },
    props: {
      label: String,
      val: String,
      errors: {
        type: Array,
        default: () => []
      },
    },
    data() {
      return {
      }
    },
    computed: {
      slots() {
        return useSlots()
      }
    }
  }
</script>

<style scoped>
  .display-label {
    display: block;
    color: rgba(0, 0, 0, 0.6);
    font-size: 14px;
  }

  .hasError {
    color: #9F2242 !important
  }
</style>
