<template>
    <div>
    </div>
</template>
  
<script>

import {getTokenAsync} from '@/plugins/auth'

export default {
    props: {
        version: String
    },
    mounted() {
        this.executeOnLoad();
    },
    methods: {
        async executeOnLoad() {
            let requestURL = '/api/Epay?';
            if (this.version === 'v2') {
                requestURL = '/api/Epay/v2?';
            }
            let tokenId = this.$route.query.tokenId;
            const params = new URLSearchParams();
            params.append('tokenId', tokenId);
            const options = {
                headers: {
                    "Authorization": "Bearer " + await getTokenAsync(),
                },
                handleError: false
            };
            const response = await this.$http.post(requestURL + params.toString(), options);
            window.close();  
        }
    }
};
</script>