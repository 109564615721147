<template>
  <v-menu close-on-content-click open-on-click offset-y left v-model="showMenuUW" nudge-bottom="10" v-if="underwriter">
    <template v-slot:activator="{on,attrs}">
      <v-btn v-bind="attrs" v-on="on" icon width="56" height="56">
        <Avatar :src="getUserAvatarPath(underwriter.Email)"
                :alt="underwriter.Name" class="avatar" color="white" />
      </v-btn>
    </template>

    <v-list style="width: 300px;">
      <v-list-item style="width: 100%;">
        <div class="d-flex mb-2" style="width: 100%;">
          <h4>
            Your Amwins Underwriter

            <v-btn icon router-link to="/profile" title="My Profile" class="ml-1" small color="secondary" style="position: relative; top: -3px;" v-if="showProfileLink">
              <i class="fas fa-pen"></i>
            </v-btn>
          </h4>
          <v-spacer></v-spacer>
          <v-btn icon @click="showMenuUW=false" style="float: right;" x-small>
            <v-icon size="16" color="black">fa-times</v-icon>
          </v-btn>
        </div>
      </v-list-item>
      <v-list-item style="display: block;" class="pb-2">
        <p class="ma-0">{{underwriter.Name}}</p>
        <p class="ma-0">{{underwriter.Phone}}</p>
        <p class="ma-0"><a :href="'mailto:' + underwriter.Email" style="word-break: break-all;">{{underwriter.Email}}</a></p>
      </v-list-item>

    </v-list>

  </v-menu>
</template>

<script>
import Avatar from '@/components/shared/Avatar.vue'
import { getUserAvatarPath } from '@/scripts/helper'

export default {
  name: 'UnderwriterMenu',
  components: {
    Avatar
  },
  data() {
    return {
      showMenuUW: false,
      showProfileLink: true,
      underwriter: null
    }
  },
  methods: {
    getUserAvatarPath
  },
  mounted() {
    this.$store.watch(
        () => {
            this.underwriter = this.$store.getters.getDefaultUnderwriter
        },
        () =>  { },
        {
            deep: true,
            immediate: true
        }
    );

    if (this.$router.currentRoute.name == 'Profile')
      this.showProfileLink = false;
    else
      this.showProfileLink = true;

    this.$router.afterEach((to) => {
      if (to.name == 'Profile')
        this.showProfileLink = false;
      else
        this.showProfileLink = true;
    });  
  },
}
</script>
