<template>
    <div class="timeline">
        
        <div v-for="(step, index) in steps" :key="step.id" class="step" 
            :class="{
                'active': index <= currentStepNumber,
            }" >
            <div v-show="showStep(step)">
                <div>
                    <div class="step-left">
                        <div class="circle" @click.prevent="stepClick(index)">
                            <i :class="step.icon"></i>
                        </div>
                        <span class="timeline-line" 
                            :class="{
                                'short': currentStepNumber == index && step.sections.length
                                }">
                            &nbsp;
                        </span>
                    </div>
                    <div class="step-right" @click.prevent="stepClick(index)">
                        <span>{{step.label}}</span>
                    </div>
                </div>

                <template v-if="currentStepNumber == index">
                    <div class="section" :class="{'active': index2 <= activeSectionNumber}"
                        v-for="(section, index2) in step.sections" :key="section.label">

                        <div class="section-left" v-if="showSection(section)">
                            <div class="dot">&nbsp;</div>
                            <span class="timeline-line">&nbsp;</span>
                        </div>
                        <div class="section-right" v-if="showSection(section)">
                            <span>{{section.label}}</span>
                        </div>
                    </div>
                </template>
            </div>
            
            
        </div>
    </div>
</template>

<script>
import Environment from '@/scripts/environment'
import { showSection } from '@/scripts/helper'

export default {
    data() {
        return {
            steps: []
        }
    },
    props: {
        form: Object,
        module: String,
        currentStepNumber: {
            type: Number,
            default: 0
        },
        activeSectionNumber: {
            type: Number,
            default: 0,
        },
    },
    emits: [
        'stepClick'
    ],
    methods: {
        stepClick(val) {
            //alert(val);
            //alert(this.activeSectionNumber);
            if (
                Environment.environment == 'localhost' || 
                Environment.environment == 'development' ||
                val < this.currentStepNumber
            ) 
            {
                this.$emit('stepClick', val);
            }
            
        },
        showStep(step) {
            return this.$store.getters.showStep(this.module, step);
        },
        showSection(section) {
            return showSection(this.module, section);
        }
    },
    mounted() {
        // this.$store.watch(() => {
        //     this.steps = this.form.steps.filter(step => {
        //         return this.$store.getters.showStep(this.module, step)
        //     });
        // });

        this.$store.watch(() => {
            this.steps = this.form.steps;
        });

    }
}
</script>

<style scoped lang="scss">
@import '@/style.scss';

.timeline {
    position: sticky;
    top: 120px;

    .step {
        display: block;

        &.active {

            .step-left {
                .circle {
                    background-color: $amwins-light-blue;
                    cursor: pointer;
                    color: white;
                }
            }

            .step-right {
                span {
                    color: $amwins-blue;
                }
            }
        }

        .step-left {
            text-align: center;
            width: 70px;
            display: inline-block;
            vertical-align: top;

            .circle {
                width: 60px;
                height: 60px;
                text-align: center;
                display: block;
                border: 1px solid $amwins-blue;
                border-radius: 100%;
                background-color: #F7FAFF;
                margin: 0 auto;
                color: $amwins-blue;
                font-size: 34px;
                line-height: 60px;
            }

            .timeline-line {
                background-color: $amwins-blue;
                display: block;
                width: 1px;
                height: 20px;
                margin: 5px auto;

                &.short {
                    height: 10px;
                }
            }
        }

        .step-right {
            display: inline-block;
            vertical-align: top;
            width: calc(100% - 70px);

            span {
                font-weight: bold;
                display: block;
                color: $amwins-blue;
                font-size: 16px;
                line-height: 20px;
                padding-top: 20px;
                padding-left: 10px;
                white-space: normal;
            }
        }

    }

    .section {

        &.active {
            .section-left {
                .dot {
                    background-color: $amwins-blue;
                }
            }
            .section-right {
                span {
                    color: $amwins-blue;
                }
            }
        }

        .section-left {
            text-align: center;
            width: 70px;
            display: inline-block;
            vertical-align: top;

            .dot {
                display: block;
                margin: 0 auto;
                width: 10px;
                height: 10px;
                background-color: $gray3;
                border-radius: 100%;
            }

            .timeline-line {
                background-color: $amwins-blue;
                display: block;
                width: 1px;
                height: 10px;
                margin: 5px auto;

            }
        }

        .section-right {
            display: inline-block;
            vertical-align: top;
            width: calc(100% - 70px);

            span {
                padding-left: 10px;
                font-size: 12px;
                line-height: 14px;
                display: block;
                font-weight: bold;
                color: $gray3;
                position: relative;
                top: -2px;
            }
        }
    }
}

.timeline > .step:not(.active):last-child .timeline-line {
    display: none;
}





</style>
