<template>

  <Dialog :title="title" :persistent="true" :value="value" :scrollable="true" :width="`700px`" :loading="loading">
    <template v-slot:card-text>
      <ValidationObserver tag="span" v-slot="{ }" ref="observer">
        <v-form ref="form" @submit.prevent="impersonate">
          <div class="d-flex">
            <DynamicFormControl class="d-inline-block"
                                :control="{
                                value: email,
                                name: 'Search',
                                type: 'TextInput',
                                  config: {
                                      label: 'Email of User to Impersonate',
                                      col: 12,
                                      short: true,
                                      rules: emailRules,
                                      autofocus: true
                                  }
                                }"
                                :disabled="loading"
                                style="flex-grow: 2; margin-top: 0px"
                                @change="email = $event.value" />
          </div>
        </v-form>
      </ValidationObserver>
      <v-alert outlined text dense type="warning">
        By clicking "Impersonate," I confirm that I have been given the authority to act on this user's behalf.
      </v-alert>
    </template>
    <template v-slot:card-actions>
      <v-btn text color="secondary" @click="close" large>
        Close
      </v-btn>
      <v-btn color="secondary" type="submit" @click="impersonate" @keypress.enter="impersonate" large>
        Impersonate
      </v-btn>
    </template>
  </Dialog>
  
</template>

<script>
import Dialog from '@/components/shared/Dialog.vue'
import DynamicFormControl from '@/components/form/DynamicFormControl.vue'
import { beginImpersonation } from '@/plugins/auth';

export default {
  name: 'UserImpersonationDialog',
  components: {
    Dialog,
    DynamicFormControl
  },
  data() {
    return {
      title: "Confirm Impersonation Request",
      email: '',
      loading: false,
      emailRules: 'required|email',
      tableHeaders: [
        { text: "Email", value: "Email", sortable: false },
        { text: "Select", value: "Select", sortable: false }
      ]
    }
  },
  props: {
    value: Boolean
  },
  emits: [
    'close-dialog'
  ],
  computed: {
    user() {
      return this.$store.getters.getUser
    }
  },
  methods: {
    close() {
      this.email = ''
      this.loading = false
      this.$emit('close-dialog')
    },
    deselectUser() {
      this.email = null
    },
    impersonate() {
      this.$refs.observer.validate()
        .then(async (valid) => {
          if (valid) {
            if (!this.email) {
              return
            }

            this.loading = true

            await beginImpersonation(this.email);
            this.close();
            }
          })
          .finally(() => {
            this.loading = false
          });
    }
  }
}
</script>

<style>
  .white-text > span {
    color: white!important;
  }
</style>
