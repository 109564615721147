<template>
  <div>
    <v-container>
      <h1>
        Cancellation
      </h1>

      <v-row>
        <v-col cols="12" md="8" class="text-right">
          <PolicyServicingHeader :account="account" :initalPolicy="policy" />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="8">
          <v-card class="py-8 px-8 mb-8">
            <ValidationObserver tag="span" ref="observer">
              <form @submit.prevent="validate()">

                <DynamicFormControl :control="{
                                      name: 'CancellationReason',
                                      type: 'Dropdown',
                                      value: cancellationReason,
                                      config: {
                                          label:'What is the primary reason for canceling the policy?',
                                          col: 12,
                                          small: true,
                                          options: compiledCancelationReasons,
                                          rules: 'required',
                                          itemText: 'text',
                                          itemValue: 'value',
                                      }
                                    }"
                                    @change="cancellationReason = $event.value" />

                <DynamicFormControl v-if="compiledCancelationSubReasons && compiledCancelationSubReasons.length > 0"
                                    :control="{
                                      name: 'subreason',
                                      type: 'Dropdown',
                                      value: cancellationSubReason,
                                      config: {
                                          label:'Please Select?',
                                          col: 12,
                                          small: true,
                                          options: compiledCancelationSubReasons,
                                          rules: 'required',
                                          short: true,
                                          itemText: 'text',
                                          itemValue: 'value',
                                          indented: true
                                      }
                                    }"
                                    @change="cancellationSubReason = $event.value" />

                <DynamicFormControl v-if="cancellationReason"
                                    :control="{
                                      name: 'CancellationDate',
                                      type: 'DateInput',
                                      value: cancellationDate,
                                      indented: true,
                                      config: {
                                          label:'Cancellation Date',
                                          col: 12,
                                          rules: 'required|validDate',
                                          small: true,
                                          short: true,
                                          allowedDates: allowedDates
                                      }
                                    }"
                                    @change="cancellationDate = $event.value" />

                <DynamicFormControl :control="{
                                      name: 'MailingAddressChanged',
                                      type: 'RadioButtonGroup',
                                      value: mailingAddressChanged,
                                      name: 'MailingAddressChanged',
                                      config: {
                                          options: [
                                              {value: true, text: 'Yes'},
                                              {value: false, text: 'No'}
                                          ],
                                          label: 'Has the Mailing Address changed?',
                                          small: true,
                                          short: true,
                                          disabled: loading,
                                          mandatory: true,
                                          required: true,
                                          rules: 'required',
                                          description: mailingAddressText,
                                          footnote: mailingAddressText,
                                          footnoteClasses: 'footnote-bold'
                                      }
                                  }"
                                    @change="mailingAddressChanged = $event.value" />

                <DynamicFormControl v-if="mailingAddressChanged"
                                    :control="{
                                      name: 'MailingAddress',
                                      type: 'Address',
                                      value: mailingAddress,
                                      indented: true,
                                      config: {
                                          label: 'Mailing Address',
                                          col: 12,
                                          rules: 'required',
                                          short: true,
                                          addressType: 'MAILING'
                                      }
                                    }"
                                    @change="mailingAddress = $event.value" />
                <div class="footnote-bold">
                  <span>Please note that we will be mailing a copy of the cancellation document to the insured and (if applicable) any mortgagees listed on the policy.</span>
                </div>

                <DynamicFormControl :control="{
                                      name: 'ElectronicSignature',
                                      type: 'Attestation',
                                      value: attestation,
                                      config: {
                                          label:'',
                                          col: 12,
                                          useGlobalAgentAttestation: true,
                                          rules: 'required',
                                      }
                                    }"
                                    @change="attestation = $event.value" />

                <div class="text-right">
                  <v-btn color="white" large :disabled="loading" @click="returnToAccount" class="ml-4">
                    <Loading v-if="loading" :size="24" class="mr-2" />
                    Cancel
                  </v-btn>
                  <v-btn color="secondary" large type="submit" :disabled="loading" class="ml-4">
                    <Loading v-if="loading" :size="24" class="mr-2" />
                    Submit
                  </v-btn>
                </div>
              </form>
            </ValidationObserver>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  import DynamicFormControl from '@/components/form/DynamicFormControl.vue'
  import PolicyServicingHeader from '@/components/views/Account/PolicyServicingHeader.vue'
  import { AccountService } from '@/api/AccountService'
  import { PolicyService } from '@/api/PolicyService'
  import dateformat from 'dateformat'

  export default {
    name: 'Cancellation',
    components: {
      DynamicFormControl,
      PolicyServicingHeader
    },
    data() {
      return {
        account: null,
        accountId: '',
        loading: false,
        cancellationReason: '',
        cancellationSubReason: '',
        cancellationDate: '',
        mailingAddress: null,
        mailingAddressChanged: null,
        attestation: null,
        cancellationReasons: null,
        policy: null,
        allowedDates: (val) => {
          const termBeginDate = this.account.TermBegin.split('T')[0]
          const today = new Date()

          // If today is earlier than the Term Begin Date, date value must be Term Begin date
          if (new Date(termBeginDate) >= today) {
            return new Date(val).toUTCString() === new Date(termBeginDate).toUTCString()
          }
          if(this.cancellationReason.Value == 'No longer need the coverage') {
            return new Date(val).toUTCString() === new Date(termBeginDate).toUTCString()

          }
          // Cancellation date can be up to 30 days backdated and cannot be a future date
          if (this.cancellationReason.Value == 'Property Sold') {
            return (new Date(termBeginDate) <= new Date(val)) && (new Date(val) < today) && (new Date(val) > this.addDays(today, -30))
          }

          // Cancellation date can be backdated to TermBegin date if it's within 10 days of the current date and cannot be a future date
          if (this.cancellationReason.Value == 'Closing fell through') {
            if (new Date(termBeginDate) >= this.addDays(today, -11)) {
              // Allow 10 days to back date
              return (new Date(termBeginDate) <= new Date(val)) && (new Date(val) < today)
            } else {
              // Date must be today
              return new Date(val) <= this.getDateWithoutTime(today) && new Date(val) >= this.getDateWithoutTime(this.addDays(today, -1))
            }
          }

          // Cancellation date can be backdated to TermBegin date if it's within 30 days of the current date and cannot be a future date
          if (this.cancellationSubReason.Value == 'Policy is not acceptable by Mortgagee') {
            if (new Date(termBeginDate) >= this.addDays(today, -31)) {
              // Allow 30 days to back date
              return (new Date(termBeginDate) <= new Date(val)) && (new Date(val) < today)
            } else {
              // Date must be today
              return new Date(val) <= this.getDateWithoutTime(today) && new Date(val) >= this.getDateWithoutTime(this.addDays(today, -1))
            }
          }

          // Cancellation date must be policy effective date
          if (this.cancellationSubReason.Value == 'Void prior to effective date') {

            return new Date(val).toUTCString() === new Date(termBeginDate).toUTCString()
          }
          if(this.cancellationReason.Value == 'Non-Payment') {
            return new Date(val) >= new Date(termBeginDate) && new Date(val) <= this.getDateWithoutTime(today)
          }
          // Date must be today
          return new Date(val) <= this.getDateWithoutTime(today) && new Date(val) >= this.getDateWithoutTime(this.addDays(today, -1))
        },
      }
    },
    watch: {
      cancellationReason(val) {
        const termBeginDate = this.account.TermBegin.split('T')[0]
        const today = new Date()
        if (new Date(termBeginDate) >= today) {
          this.cancellationDate = this.addDays(termBeginDate, 1);
        }

        if (val.Value == 'Non-Payment') {
          this.cancellationDate = this.addDays(termBeginDate, 1);
        }

        if ((val.Value == 'Moved policy to another Insurance Company' || val.Value == 'Mortgage Paid Off' || val.Value == 'Cancel to Rewrite') && new Date(termBeginDate) < today) {
          // Date must be today
          const dformat = dateformat(this.getDateWithoutTime(today), 'mm/dd/yyyy');
          this.cancellationDate = dformat;
        }

        if (val.Value == 'Closing fell through') {
          if (new Date(termBeginDate) >= this.addDays(today, -11)) {
            // Set date to Term Begin Date. User may change it.
            this.cancellationDate = this.addDays(termBeginDate, 1);
          } else {
            // Date must be today
            const dformat = dateformat(this.getDateWithoutTime(today), 'mm/dd/yyyy');
            this.cancellationDate = dformat;
          }
        }

        this.newFloodProvider = ''
      },
      cancellationSubReason(val) {
        const termBeginDate = this.account.TermBegin.split('T')[0]
        const today = new Date()
        if (val.Value == 'Void prior to policy effective date') {
          // Date must be Term Begin date
          this.cancellationDate = this.addDays(termBeginDate, 1);
        }

        if (val.Value == 'Policy is not acceptable by Mortgagee') {
          if (new Date(termBeginDate) >= this.addDays(today, -31)) {
            // Set date to Term Begin Date. User may change it.
            this.cancellationDate = this.addDays(termBeginDate, 1);
          } else {
            // Date must be today
            const dformat = dateformat(this.getDateWithoutTime(today), 'mm/dd/yyyy');
            this.cancellationDate = dformat;
          }
        }
      }
    },
    mounted() {
      this.accountId = this.$route.query.accountId
      //Lets load in our current poicy
      PolicyService.getV2({ submissionId: this.accountId })
        .then((response) => {
          if (response) {
            this.policy = response
            PolicyService.getCancellationReasons({id: this.policy.Policy.ProductType.Id, value:  this.policy.Policy.ProductType.Value.toLowerCase()}).then((data) => {
              this.cancellationReasons = data.CancellationReasons;
            })
          }
        })
        .catch(() => {
          this.$store.dispatch('toastError', { Message: 'Failed to retrieve policy data' })
        })

      
      if (this.accountId) {
        this.loading = true;
        AccountService.get({ id: this.accountId })
          .then((data) => {
            this.account = data;
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    computed: { 
      compiledCancelationReasons (){
        let reasons = [];
        for (let i = 0; i < this.cancellationReasons?.length; i++) {
          reasons.push({text: this.cancellationReasons[i].CancellationReason.Value, value: this.cancellationReasons[i].CancellationReason});
        }
        return reasons;
      },
      compiledCancelationSubReasons (){
        let subreasons = [];
        var reason = this.cancellationReasons?.find(x => x.CancellationReason == this.cancellationReason);
        if(reason?.CancellationSubReasons.length > 0){
          subreasons = reason.CancellationSubReasons.map(x => {return {text: x.Value, value: x}});
        } else if(reason?.MissingRequiredDocument.length > 0){
          subreasons = reason.MissingRequiredDocument.map(x => {return {text: x, value: x}});
        }
        return subreasons;
      },
      mailingAddressText() {
          return 'If you require the Cancellation Notice to be sent to an alternate address, please enter a new mailing address.';
      },
    },
    methods: {
      returnToAccount() {
        this.$router.push({ path: '/account', query: { id: this.account.id } })
      },
      addDays(date, days) {
        var result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
      },
      getDateWithoutTime(date) {
        var dateString = date.toDateString();
        return new Date(dateString);
      },
      validate() {
        this.loading = true;
        this.$refs.observer.validate()
          .then(valid => {
            if (valid) {
              this.submitV2Cancellation();
            }

            this.loading = false;
          });
      },
      submitV2Cancellation() {
        let cancellationRequest = {};
        cancellationRequest.AccountId = this.accountId;
        cancellationRequest.CancellationReason = this.cancellationReason
        if(this.cancellationReason == 'Missing Required Document'){
          cancellationRequest.MissingRequiredDocument = this.cancellationSubReason
        }else {
          cancellationRequest.CancellationSubReason = this.cancellationSubReason
        }
        cancellationRequest.CancellationEffectiveDate = this.cancellationDate;
        cancellationRequest.MailingAddress = this.mailingAddress;

        PolicyService.cancelRequestV2({ body: cancellationRequest })
          .then(() => {
            this.$router.push({ path: '/account', query: { id: this.account.id } }).then(() => {
              this.$store.dispatch('toastSuccess', { Message: 'Successfully submitted cancellation request' })
            })
          })
          .catch((err) => {
            this.$store.dispatch('toastError', { Message: 'Failed to process cancellation request' })
          })
      }
      },
  }
</script>

<style scoped lang="scss">
.footnote-bold {
  font-weight: bold;
  margin-bottom: 0;
  margin-top: 10px;
}
</style>