<template>
  <div>
    <v-container>
      <h1>
        Flood Cancellation
      </h1>

      <v-row>
        <v-col cols="12" md="8" class="text-right">
          <PolicyServicingHeader :account="account" />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="8">
          <v-card class="py-8 px-8 mb-8">
            <ValidationObserver tag="span" ref="observer">
              <form @submit.prevent="validate()">

                <DynamicFormControl :control="{
                                      name: 'CancellationReason',
                                      type: 'Dropdown',
                                      value: cancellationReason,
                                      config: {
                                          label:'What is the primary reason for canceling the policy?',
                                          col: 12,
                                          small: true,
                                          options: cancellationReasons,
                                          rules: 'required',
                                          itemText: 'text',
                                          itemValue: 'value',
                                      }
                                    }"
                                    @change="cancellationReason = $event.value" />

                <DynamicFormControl v-if="cancellationReason == 'Moved policy to another insurance company'"
                                    :control="{
                                      name: 'NewFloodProvider',
                                      type: 'Dropdown',
                                      value: newFloodProvider,
                                      config: {
                                          label:'Who is the new provider?',
                                          col: 12,
                                          small: true,
                                          options: newFloodProviders,
                                          rules: 'required',
                                          short: true,
                                          itemText: 'text',
                                          itemValue: 'value',
                                          indented: true
                                      }
                                    }"
                                    @change="newFloodProvider = $event.value" />

                <DynamicFormControl v-if="cancellationReason == 'No longer need the coverage'"
                                    :control="{
                                      name: 'NoLongerNeededReason',
                                      type: 'Dropdown',
                                      value: noLongerNeededReason,
                                      indented: true,
                                      config: {
                                          label:'Why is coverge no longer needed?',
                                          col: 12,
                                          small: true,
                                          options: noLongerNeededReasons,
                                          rules: 'required',
                                          short: true,
                                          itemText: 'text',
                                          itemValue: 'value'
                                      }
                                    }"
                                    @change="noLongerNeededReason = $event.value" />

                <DynamicFormControl v-if="cancellationReason"
                                    :control="{
                                      name: 'CancellationDate',
                                      type: 'DateInput',
                                      value: cancellationDate,
                                      indented: true,
                                      config: {
                                          label:'Cancellation Date',
                                          col: 12,
                                          rules: 'required|validDate',
                                          small: true,
                                          short: true,
                                          allowedDates: allowedDates
                                      }
                                    }"
                                    @change="cancellationDate = $event.value" />

                <DynamicFormControl :control="{
                                      name: 'MailingAddressChanged',
                                      type: 'RadioButtonGroup',
                                      value: mailingAddressChanged,
                                      name: 'MailingAddressChanged',
                                      config: {
                                          options: [
                                              {value: true, text: 'Yes'},
                                              {value: false, text: 'No'}
                                          ],
                                          label: 'Has the Mailing Address changed?',
                                          small: true,
                                          short: true,
                                          disabled: loading,
                                          mandatory: true,
                                          required: true,
                                          rules: 'required',
                                          description: mailingAddressDescription,
                                          footnote: mailingAddressFootnote,
                                          footnoteClasses: paymentV2Enabled ? 'footnote-bold' : null
                                      }
                                  }"
                                    @change="mailingAddressChanged = $event.value" />

                <DynamicFormControl v-if="mailingAddressChanged"
                                    :control="{
                                      name: 'MailingAddress',
                                      type: 'Address',
                                      value: mailingAddress,
                                      indented: true,
                                      config: {
                                          label: 'Mailing Address',
                                          col: 12,
                                          rules: 'required',
                                          short: true,
                                          addressType: 'MAILING'
                                      }
                                    }"
                                    @change="mailingAddress = $event.value" />
                <div v-if="paymentV2Enabled" class="footnote-bold">
                  <span>Please note that we will be mailing a copy of the cancellation document to the insured and (if applicable) any mortgagees listed on the policy.</span>
                </div>

                <DynamicFormControl :control="{
                                      name: 'ElectronicSignature',
                                      type: 'Attestation',
                                      value: attestation,
                                      config: {
                                          label:'',
                                          col: 12,
                                          useGlobalAgentAttestation: true,
                                          rules: 'required',
                                      }
                                    }"
                                    @change="attestation = $event.value" />

                <div class="text-right">
                  <v-btn color="white" large :disabled="loading" @click="returnToAccount" class="ml-4">
                    <Loading v-if="loading" :size="24" class="mr-2" />
                    Cancel
                  </v-btn>
                  <v-btn color="secondary" large type="submit" :disabled="loading" class="ml-4">
                    <Loading v-if="loading" :size="24" class="mr-2" />
                    Submit
                  </v-btn>
                </div>
              </form>
            </ValidationObserver>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  import DynamicFormControl from '@/components/form/DynamicFormControl.vue'
  import PolicyServicingHeader from '@/components/views/Account/PolicyServicingHeader.vue'
  import { AccountService } from '@/api/AccountService'
  import { PolicyService } from '@/api/PolicyService'
  import dateformat from 'dateformat'

  export default {
    name: 'FloodCancellation',
    components: {
      DynamicFormControl,
      PolicyServicingHeader
    },
    data() {
      return {
        account: null,
        accountId: '',
        loading: false,
        cancellationReason: '',
        newFloodProvider: '',
        noLongerNeededReason: '',
        cancellationDate: '',
        mailingAddress: null,
        mailingAddressChanged: null,
        attestation: null,
        cancellationReasons: [
          {
            'value': 'Sold the insured property',
            'text': 'Sold the insured property'
          },
          {
            'value': 'Moved policy to another insurance company',
            'text': 'Moved policy to another insurance company'
          },
          {
            'value': 'No longer need the coverage',
            'text': 'No longer need the coverage'
          },
          {
            'value': 'Mortgage paid off',
            'text': 'Mortgage paid off'
          },
          {
            'value': 'Property closing did not occur',
            'text': 'Property closing did not occur'
          },
          {
            'value': 'Cancel to Rewrite',
            'text': 'Cancel to Rewrite'
          }
        ],
        newFloodProviders: [
          {
            'value': 'Private Flood Provider',
            'text': 'Private Flood Provider'
          },
          {
            'value': 'NFIP',
            'text': 'NFIP'
          }
        ],
        noLongerNeededReasons: [
          {
            'value': 'Void prior to effective date',
            'text': 'Void prior to effective date'
          },
          {
            'value': 'Policy is not acceptable by mortgagee',
            'text': 'Policy is not acceptable by mortgagee'
          }
        ],
        allowedDates: (val) => {
          const termBeginDate = this.account.TermBegin.split('T')[0]
          const today = new Date()

          // If today is earlier than the Term Begin Date, date value must be Term Begin date
          if (new Date(termBeginDate) >= today) {
            return new Date(val).toUTCString() === new Date(termBeginDate).toUTCString()
          }

          // Cancellation date can be up to 30 days backdated and cannot be a future date
          if (this.cancellationReason == 'Sold the insured property') {
            return (new Date(termBeginDate) <= new Date(val)) && (new Date(val) < today) && (new Date(val) > this.addDays(today, -30))
          }

          // Cancellation date can be backdated to TermBegin date if it's within 10 days of the current date and cannot be a future date
          if (this.cancellationReason == 'Property closing did not occur') {
            if (new Date(termBeginDate) >= this.addDays(today, -11)) {
              // Allow 10 days to back date
              return (new Date(termBeginDate) <= new Date(val)) && (new Date(val) < today)
            } else {
              // Date must be today
              return new Date(val) <= this.getDateWithoutTime(today) && new Date(val) >= this.getDateWithoutTime(this.addDays(today, -1))
            }
          }

          // Cancellation date can be backdated to TermBegin date if it's within 30 days of the current date and cannot be a future date
          if (this.noLongerNeededReason == 'Policy is not acceptable by mortgagee') {
            if (new Date(termBeginDate) >= this.addDays(today, -31)) {
              // Allow 30 days to back date
              return (new Date(termBeginDate) <= new Date(val)) && (new Date(val) < today)
            } else {
              // Date must be today
              return new Date(val) <= this.getDateWithoutTime(today) && new Date(val) >= this.getDateWithoutTime(this.addDays(today, -1))
            }
          }
          if(this.cancellationReason == 'Nonpayment') {
            return new Date(val) >= new Date(termBeginDate) && new Date(val) <= this.getDateWithoutTime(today)
          }
          // Cancellation date must be policy effective date
          if (this.noLongerNeededReason == 'Void prior to effective date') {
            return new Date(val).toUTCString() === new Date(termBeginDate).toUTCString()
          }
          
          // Date must be today
          return new Date(val) <= this.getDateWithoutTime(today) && new Date(val) >= this.getDateWithoutTime(this.addDays(today, -1))
        },
      }
    },
    watch: {
      cancellationReason(val) {
        const termBeginDate = this.account.TermBegin.split('T')[0]
        const today = new Date()

        if (new Date(termBeginDate) >= today) {
          this.cancellationDate = this.addDays(termBeginDate, 1);
        }

        if (val == 'Nonpayment') {
          // Date must be Term Begin date
          this.cancellationDate = this.addDays(termBeginDate, 1);
        }

        if ((val == 'Moved policy to another insurance company' || val == 'Mortgage paid off' || val == 'Cancel to Rewrite') && new Date(termBeginDate) < today) {
          // Date must be today
          const dformat = dateformat(this.getDateWithoutTime(today), 'mm/dd/yyyy');
          this.cancellationDate = dformat;
        }

        if (val == 'Property closing did not occur') {
          if (new Date(termBeginDate) >= this.addDays(today, -11)) {
            // Set date to Term Begin Date. User may change it.
            this.cancellationDate = this.addDays(termBeginDate, 1);
          } else {
            // Date must be today
            const dformat = dateformat(this.getDateWithoutTime(today), 'mm/dd/yyyy');
            this.cancellationDate = dformat;
          }
        }

        this.newFloodProvider = ''
        this.noLongerNeededReason = ''
      },
      noLongerNeededReason(val) {
        const termBeginDate = this.account.TermBegin.split('T')[0]
        const today = new Date()
        if (val == 'Void prior to effective date') {
          // Date must be Term Begin date
          this.cancellationDate = this.addDays(termBeginDate, 1);
        }

        if (val == 'Policy is not acceptable by mortgagee') {
          if (new Date(termBeginDate) >= this.addDays(today, -31)) {
            // Set date to Term Begin Date. User may change it.
            this.cancellationDate = this.addDays(termBeginDate, 1);
          } else {
            // Date must be today
            const dformat = dateformat(this.getDateWithoutTime(today), 'mm/dd/yyyy');
            this.cancellationDate = dformat;
          }
        }
      }
    },
    mounted() {
      this.accountId = this.$route.query.accountId
      if (this.accountId) {
        this.loading = true;
        AccountService.get({ id: this.accountId })
          .then((data) => {
            this.account = data;
          })
          .finally(() => {
            this.loading = false;
          });
      }

      if (this.showNonpaymentItem) {
        this.cancellationReasons.push({
          'value': 'Nonpayment',
          'text': 'Nonpayment'
        })
      }
    },
    computed: {
      paymentV2Enabled() {
        let isV2Enabled = this.$store.getters.isFeatureFlagEnabled("ePayPaymentOptions");
        return isV2Enabled;
      },
      mailingAddressDescription() {
        if (this.paymentV2Enabled) {
          return 'If you require the Cancellation Notice to be sent to an alternate address, please enter a new mailing address.';
        }
        return null;
      },
      mailingAddressFootnote() {
        if (this.paymentV2Enabled) {
          return null;
        }
        return 'If you require the Cancellation Notice to be sent to an alternate address, please enter a new mailing address.';
      },
      showNonpaymentItem() {
        return this.$store.getters.userHasRestrictedFeature('Account_FloodNonpayment');
      },
    },
    methods: {
      returnToAccount() {
        this.$router.push({ path: '/account', query: { id: this.account.id } })
      },
      addDays(date, days) {
        var result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
      },
      getDateWithoutTime(date) {
        var dateString = date.toDateString();
        return new Date(dateString);
      },
      validate() {
        this.loading = true;
        this.$refs.observer.validate()
          .then(valid => {
            if (valid) {
              let cancellationForm = {};
              cancellationForm.AccountId = this.accountId;
              cancellationForm.CancellationReason = this.cancellationReason;
              cancellationForm.NoLongerNeededReason = this.noLongerNeededReason;
              cancellationForm.NewFloodProvider = this.newFloodProvider;
              cancellationForm.MailingAddress = this.mailingAddress;
              cancellationForm.EffectiveDate = this.cancellationDate;
              cancellationForm.Attestation = this.attestation;

              PolicyService.cancelRequest({ body: cancellationForm })
                .then(() => {
                  this.$router.push({ path: '/account', query: { id: this.account.id } }).then(() => {
                    this.$store.dispatch('toastSuccess', { Message: 'Successfully submitted cancellation request' })
                  })
                })
                .catch((err) => {
                  this.$store.dispatch('toastError', { Message: 'Failed to process cancellation request' })
                })
            }

            this.loading = false;
          });
      }
    }
  }
</script>

<style scoped lang="scss">
.footnote-bold {
  font-weight: bold;
  margin-bottom: 0;
  margin-top: 10px;
}
</style>