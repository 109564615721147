<template>
  <div>
    <v-container>
      <h1>Link Amlink Submission </h1>

      <v-card class="mb-4"
              :loading="loading">
        <v-card-title>
          Submission {{ submissionId }}
        </v-card-title>
        <v-card-text>
          <ValidationObserver tag="span" v-slot="{}" ref="observer1">
            <form @submit.prevent="setFileId">
              <v-row>
                <v-col md="6"
                       lg="4">
                  <DynamicFormControl :control=controlConfig
                                      :disabled="locked"
                                      style="margin-top: 0px;"
                                      @change="submissionFileId = $event.value" />
                </v-col>
                <v-col md="6"
                       lg="8">
                  <FormButton label="Submit" size="x-large" @click="setFileId" :disabled="loading" />
                </v-col>
              </v-row>
            </form>
          </ValidationObserver>
        </v-card-text>
      </v-card>

      

    </v-container>
  </div>
</template>

<script>
  import DynamicFormControl from '@/components/form/DynamicFormControl.vue'
  import FormButton from '@/components/form/FormButton.vue'
  import { AmlinkSubmissionFileService } from '@/api/AmlinkSubmissionFileService'

  export default {
    name: 'AmlinkSubmissionFile',
    props:{
      submissionId: {
        type: String,
        required: true,
      }
    },
    components: {
      DynamicFormControl,
      FormButton
    },
    data() {
      return {
        submissionFileId: "",
        loading: true,
        locked: false,
        controlConfig:{
            name: 'Submission File',
            type: 'TextInput',
            value: "",
            ///this dosn't work disabled: true,
            config: {
                label: 'Amlink Submission File Id',
                rules: 'required|integer|min_value:1',
                col: 12,
                short: true,
                clearable: true,
                ignoreDataServiceValue: true
            }
          }
      }
    },
    created() {

      AmlinkSubmissionFileService.getSubmissionFileId({ submissionId: this.submissionId })
                .then((response) => {
                  console.log(response);
                  this.controlConfig.value = response.submissionFileId ?? "";
                  if(response.submissionFileId) this.locked = true;
                })
                .finally(() => {
                  this.loading = false
                })

    },
    methods: {
      setFileId() {
        this.$refs.observer1.validate()
          .then((valid) => {
            if (valid) {
              this.loading = true
              AmlinkSubmissionFileService.putSubmissionFileId({ submissionId: this.submissionId, body: { submissionFileId: this.submissionFileId } })
                .then((response) => {
                  if (response) {
                    this.locked = true;
                    this.$store.dispatch('toastSuccess', { Message: 'Submission Link Complete' })
                  } else {
                    this.$store.dispatch('toastError', { Message: 'Error Linking Submission' })
                  }
                })
                .finally(() => {
                  this.loading = false
                })
            }
          });
      }
    }
  }
</script>
