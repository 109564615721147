<template>

        <v-row class="dfc" :class="{
            'dfc-indented': indented, 
            'dfc-short': short,
            'dfc-inline': inline, 
            'dfc-hidden': hidden,
            'dfc-matchesDataServiceValue': matchesDataServiceValue && !hideDataServiceValue
            }" 
            :style="{'width': inlineWidth}" v-if="control.type">
            <div class="indented-wrapper dfc-col" v-if="indented">
                <div class="indented"></div>
            </div>
            <v-col v-if="isBetaFeature" xl="1" cols="2" class="pr-0">
                <BetaFeatureTag />
            </v-col>
            <v-col :md="md" :cols="cols" class="dfc-col" :class="{'py-0': short}" :key="componentKey">
                <p class="headnote" v-if="headnote" v-html="headnote"></p>
                <ValidationProvider :rules="rules" v-slot="{ errors, provider }" ref="provider" :vid="control.name" mode="eager" :name="control.name">
                    <component
                        :key="control.name"
                        :data-name="control.name" 
                        v-model="value" 
                        v-if="component" 
                        :is="component"
                        v-bind="{
                            ...control.config,
                            required, 
                            errors,
                            value,
                            module,
                            salesChannel,
                            name: control.name,
                            disabled: controlDisabled
                        }"
                        @validate="validate"
                        @change="emitValue"
                        @keydown="emitKeydown"
                        @keypress="emitKeypress"
                        @blur="emitBlur"
                    ></component>
                    <span v-if="false">{{provider}}</span>

                    <span v-if="hasDataServiceValue && !hidden && !hideDataServiceValue" class="data-service-value-label">
                        <span v-if="control.config && control.config.dataServiceValueLabel">
                            {{control.config.dataServiceValueLabel}}:
                        </span>
                        <span v-else>
                            Recommended Value:
                        </span>
                        {{dataServiceValue}}
                    </span>

                </ValidationProvider>
                <p :class="footnoteClasses" v-if="footnote" v-html="footnote"></p>

                <div v-if="control.config && control.config.tips" class="dfc-tips">
                    <div v-for="(tip, index) in control.config.tips" :key="index">
                        <div v-if="showTipHelper(module, tip)">

                            <!-- <p class="mb-0 mt-2 footnote"><span class="mdi mdi-information"></span> <em><span v-html="tip.message"></span></em></p> -->

                            <!-- <v-alert dense type="info" elevation="1" class="mt-2 mb-0"><span v-html="tip.message" style="font-size: 12px;"></span></v-alert> -->

                            <v-alert dense type="warning" outlined border="left" icon="mdi mdi-alert-outline" class="mt-2 mb-0"><span v-html="tip.message" style="font-size: 14px; line-height: 16px; display: inline-block; padding-top: 3px;"></span></v-alert>

                        </div>
                    </div>
                </div>
            </v-col>
            <v-col md="1" cols="12" v-if="control.config && control.config.modal">
                <MoreInfoDialog v-bind="control.config.modal" />
            </v-col>
            <v-col md="1" cols="1" v-if="control.config && control.config.tooltip" class="d-flex align-center py-0">
                <v-tooltip right :max-width="300" color="#555555">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            class="tooltip"
                            :class="{'text-tooltip': control.type == 'Dropdown' || control.type == 'TextInput'}"
                            color="#888888"
                            dark
                            icon
                            v-bind="attrs"
                            v-on="on"
                        >
                            <i class="fas fa-question-circle" style="font-size: 20px;"></i>
                        </v-btn>
                    </template>
                    <span class="dfc-tooltip-content" v-html="control.config.tooltip"></span>
                </v-tooltip>
            </v-col>
        </v-row>        

</template>

<script>

import {isRequired} from '@/scripts/helper'
import MoreInfoDialog from '@/components/shared/MoreInfoDialog.vue'
import BetaFeatureTag from '@/components/shared/BetaFeatureTag.vue'
import dateformat from 'dateformat';
import { formatMoney } from '@/scripts/helper'
import { newGuid } from '@/scripts/helper';
import { showTipHelper } from '@/scripts/tip';

export default {
    name: 'DynamicFormControl',
    components: {
        MoreInfoDialog,
        BetaFeatureTag
    },
    props: {
        control: Object,
        module: String,
        salesChannel: String,
        disabled: {
            type: Boolean,
            default: false
        },
        isBetaFeature: {
            type: Boolean,
            default: false
        }
    },
    emits: [
        'change',
        'keydown',
        'keypress',
        'blur'
    ],
    data() {
        return {
            value: null,
            component: null,
            componentKey: newGuid(),
        }
    },
    computed: {
        footnoteClasses() {
            if (this.control.config?.footnoteClasses) {
                return this.control.config.footnoteClasses;
            }
            return 'footnote';
        },
        loader() {
            if (!this.control || !this.control.type) {return null;}
            return () => import(`./${this.control.type}.vue`);

        },
        footnote() {
            if (this.control.config && this.control.config.footnote) {
                return this.control.config.footnote;
            }
            return null;
        },
        headnote() {
            if (this.control.config && this.control.config.headnote) {
                return this.control.config.headnote;
            }
            return null;
        },
        rules() {

            if (!this.control.config) return '';

            let rules = this.control.config.rules || '';

            if (
                this.control.type == 'Dropdown' || 
                this.control.type == 'RadioButtonGroup' || 
                (this.control.type == 'RadioItemGroup' && !this.control.config.multiple)) {
                if (
                    this.control.config.options.length > 0 && 
                    (this.control.value || this.control.value === 0 || this.control.value === false) && 
                    !this.control.config.returnObject
                ) {
                    let newRule = 'availableOption:' + this.control.config.options.map(o => o.value + "").join("@@@");
                    const rulesSplit = rules.split("|");
                    rulesSplit.push(newRule);
                    rules = rulesSplit.join("|"); 
                }
            }
            if (this.control.type === 'TextInput' && this.control.config.currency) {
                const rulesSplit = rules.split("|");
                rulesSplit.push('validateNumberRange');
                rules = rulesSplit.join("|"); 
            }

            return rules;
        },
        required() {
            return isRequired(this.rules);
        },
        indented() {
            return this.control.config && this.control.config.indented || false;
        },
        short() {
            return this.control.config && this.control.config.short || false;
        },
        md() {
            let md = 12;
            if (this.control.config && this.control.config.col) {
                md =  this.control.config.col;
            }
            if (md == 12) {
                md = this.limitCols(md);
            }
            return md;
        },
        cols() {
            let cols = 12;
            cols = this.limitCols(cols);
            return cols;
        },
        inline() {
            return this.control.config && this.control.config.inline || false;
        },
        inlineWidth() {
            return this.control.config && this.control.config.width || null;
        },
        hidden() {
            return this.control.type == 'Hidden';
        },
        controlDisabled() { 
            return this.disabled || (this.control.config && (this.control.config.disabled || this.control.config.controlDisabled));
        },
        matchesDataServiceValue() {
            if (!this.control.DataServiceValue) return false;
            if (this.control.DataServiceValue == this.value) return true;
            return false;
        },
        hasDataServiceValue() {
            if (!this.control) return false;
            if (this.control && this.control.DataServiceValue !== undefined && this.control.DataServiceValue !== null) return true;
            return false;
        },
        dataServiceValue() {
            if (!this.control) return null;
            else if (this.control.config && this.control.config.dataServiceValueFormat == 'currency') {
                return formatMoney(this.control.DataServiceValue);
            } 
            else {
                return this.control.DataServiceValue;
            }
        },
        hideDataServiceValue() {
            if (!this.control) return true;
            else if (this.control.config && this.control.config.hideDataServiceValue == true) {
                return true;
            } else {
                return false;
            }

        }
    },
    methods: {
        newGuid,
        formatMoney,
        showTipHelper,
        emitValue(value) {
            this.value = value;
            const event = {
                name: this.control.name,
                value
            }
            this.$emit('change', event) 
        },
        validate() {
            this.$refs.provider.validate();
        },
        emitKeydown(event) {
            this.$emit('keydown', event);
        },
        emitKeypress(event) {
            this.$emit('keypress', event);
        },
        emitBlur() {
            this.$emit('blur');
        },
      checkForDefault() {
            if (this.value && this.control.name !== 'InspectionContactName') return;

            if ((this.value === null || this.value === undefined || this.control.name === 'InspectionContactName') && this.control.config && 
                (this.control.config.default || this.control.config.default === false || this.control.config.default === 0 || this.control.config.default === '')) {

                let d = this.control.config.default;
                let INSUREDNAME = "";

                const todaysDate = new Date();
                const SevenDaysFromToday = new Date(todaysDate).setDate(todaysDate.getDate() + 7);

                if (d == '$TodaysDate') {
                    d = dateformat(todaysDate, 'mm/dd/yyyy');
                } else if (d == '$OneYearFromToday') {
                    const OneYearFromToday = new Date(todaysDate.setFullYear(todaysDate.getFullYear() + 1));
                    d = dateformat(OneYearFromToday, 'mm/dd/yyyy')
                }else if (d == '$SevenDaysFromToday') {
                    d = dateformat(SevenDaysFromToday, 'mm/dd/yyyy')
                }
                if (d == '%%INSUREDNAME%%'){
                    let firstName = this.$store.getters.getControlValue('quoteForm','FirstName') || "";
                    let lastName = this.$store.getters.getControlValue('quoteForm', 'LastName') || "";
                    INSUREDNAME = firstName + ' ' + lastName;
                    d = INSUREDNAME;
                }
                if (d == '%%INSPECTIONCONTACTPHONE%%'){
                    d = this.$store.getters.getControlValue('additionalForm','INSPECTIONCONTACTPHONE') || "";
                }

                this.emitValue(d);
            } 
            else {
                this.emitValue(this.value);
            }

            this.$forceUpdate();            
        
        },
        limitCols(cols) {
            if (this.isBetaFeature) {
                if (this.$vuetify.breakpoint.name === 'xl') {
                    cols -= 1;
                } else {
                    cols -= 2;
                }
            }

            if (this.control.config && this.control.config.tooltip) {                  
                 cols -= 1;
            }
            return cols;
        }
    },
    watch: {
        control: {
            handler(newVal) {         
                if (this.value !== newVal.value && newVal.value !== undefined) {
                    this.value = newVal.value;
                    this.$store.commit('updateFormData', {
                        module: this.module,
                        name: this.control.name,
                        value: this.value
                    });
                }
            },
            deep: true,
            immediate: true
        },
    },
    mounted() {
        this.value = this.control.value;
        if (this.control.config && this.control.config.dependsOn) {
            this.validationDependenciesUnsubscribe = this.$store.subscribe((mutation) => {
                if (
                    mutation.type === 'updateFormControl' &&
                    this.control.config.dependsOn.indexOf(mutation.payload.name) !== -1
                ) {
                    if (this.$refs.provider) {
                        if (this.value !== null && this.value !== undefined) {
                            this.$refs.provider.validate(this.value)
                        }
                    }
                }
            })
        }

        if (this.control.type) {
            this.loader()
            .then(() => {
                this.component = () => this.loader();
            })
        }

        this.checkForDefault();

    },
    destroyed() {
        if (this.validationDependenciesUnsubscribe) {
            this.validationDependenciesUnsubscribe()
        }
    }

}

</script>

<style scoped lang="scss">
@import '@/style.scss';

    .dfc {
        margin-top: 10px;
        margin-bottom: 10px;

        &.dfc-indented {
            margin-top: 0;
            margin-bottom: 0;
        }

        &.dfc-short > .dfc-col{
            padding-top: 0;
            padding-bottom: 0;
        }

        &.dfc-hidden {
            margin: 0!important;
        }

        &.dfc-hidden .dfc-col {
            padding: 0;
        }
    }

    .indented-wrapper {
        padding: 0 12px;
        padding-left: 0;
        margin-left: 12px;
    }

    .indented {
        border-left: 2px solid #A2D6E7;
        height: 100%;
    }

    .dfc-inline {
        display: inline-block;
        padding-right: 20px;
        vertical-align: top;
    }

    .data-service-value-label {
        color: green;
        text-align: right;
        display: block;
        font-size: 12px;
    }

    .tooltip {
        margin-top: 26px;
    }

    .tooltip.text-tooltip {
        margin-top: 0;
    }

    @media screen and (max-width: 960px) {
        .tooltip {
            margin-top: 0;
        }
    }


    ::v-deep .dfc-tips {
        .v-alert {
            padding: 3px;
        }

        /*Blue Option*/
        // .info {
        //     .v-alert__icon.v-icon {
        //         font-size: 14px;
        //     }
        //     .v-alert__icon {
        //         margin-right: 2px!important;
        //     }
        // }
        
        /*Gold Option*/
        .warning--text {
            .v-alert__icon.v-icon {
                font-size: 18px;
                margin-left: 5px;
                margin-right: 2px;
                display: inline-block;
            }
        }
    }
</style>