var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-container',[_c('h1',[_vm._v(" Agency Non-Prod Access ")]),_c('v-card',{staticClass:"mb-4"},[_c('v-card-title',[_vm._v(" Agency ")]),_c('v-card-text',[_c('v-row',[_c('v-col',{staticClass:"py-0 mb-3",attrs:{"cols":"12"}},[_c('DynamicFormControl',{attrs:{"control":{
                                      value: _vm.selectedAgency,
                                      name: 'Agency',
                                      type: 'AgencyAutocomplete',
                                      config: {
                                          label: 'Agency',
                                          col: 12,
                                          short: true,
                                          clearable: true,
                                          ignoreCache: true
                                      }
                                  }},on:{"change":function($event){return _vm.selectedAgencyChanged($event.value)}}})],1)],1),(_vm.selectedAgency)?_c('v-row',[_c('v-col',{staticClass:"py-0 mb-3",attrs:{"cols":"12"}},[_c('DynamicFormControl',{attrs:{"control":{
                                  type: 'Checkbox',
                                  name: 'NonProdAccess',
                                  value: _vm.hasNonProdAccess,
                                  config: {
                                    label: 'Allow Access to Non-Prod Environments?',
                                    short: true
                                  }
                                }},on:{"change":function($event){return _vm.hasNonProdAccessChanged($event.value)}}})],1)],1):_vm._e()],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }