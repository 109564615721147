<template>
    <div>
        <label :class="{'text-bold': bold}">{{label}}</label>
    </div>
</template>

<script>

export default {
    name: 'Label',
    props: {
        label: String,
        bold: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {}
    }
}
</script>

<style scoped>
    label {
        display: block;
        margin-bottom: 0px;
    }
</style>
