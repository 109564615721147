// @ts-nocheck 

import Vue from 'vue';
import Store from './vuex/store'
import { ValidationProvider, extend, ValidationObserver } from 'vee-validate';
import ErrorMessages from '@/components/form/ErrorMessages.vue'
import {
  required,
  email,
  min_value,
  max_value,
  min,
  max
} from 'vee-validate/dist/rules'
import { min } from 'moment';


extend('required', {
  validate(value) {
    return {
      required: true,
      valid: ['', null, undefined].indexOf(value) === -1
    };
  },
  computesRequired: true,
  message: "Required"
});

extend('requiredByState', {
  params: ['target'],
  validate: (value, { target }) => {
    const riskAddress = Store.getters.getControlValue('quoteForm', 'Risk')

    if (riskAddress.State === target) {
      return { 
        required: true,
        valid: ['', null, undefined].indexOf(value) === -1
      }
    } else {
      return true
    }
  },
  computesRequired: true,
  message: `Required by your state`
})

// Add a rule.
extend('secret', {
  validate: value => value === 'example',
  message: 'This is not the magic word.'
});

// extend('email', {
//   validate(value) {
//     if (!value) return true;
//     const re = /\S+@\S+\.\S+/;
//     return re.test(value);
//   },
//   message: 'Please enter a valid email address.'
// });

extend('email', {
  ...email,
  message: 'The email address is not valid.'
})

extend('emailDomain', {
  params: ['domain'],
  validate: (value, {domain}) => {
    if (!value) return true;
    return value.indexOf('@' + domain) > -1;
  },
  computesRequired: true,
  message: (field, placeholders) => {
    return 'Please enter an email address that ends in ' + placeholders.domain + '.'
  }
})

extend('emailNotMyself', {
  validate: (value) => {
    if (!value) return true;
    const email = Store.getters.getUserEmail;
    return email.toLowerCase() != value.toLowerCase();
  },
  computesRequired: true,
  message: (field, placeholders) => {
    return 'Please do not enter your own email address.'
  }
})

extend('noDuplicateTextEntries', {
  validate: (value) => {
    if (!value || !value.length) return true;

    let hasDuplicate = false;
    const uniqueItems = [];
    value.forEach(v => {
      if (uniqueItems.indexOf(v) > -1) hasDuplicate = true;
      else uniqueItems.push(v);
    });

    return !hasDuplicate;

  },
  computesRequired: true,
  message: (field, placeholders) => {
    return 'Please do not enter any duplicates.'
  }
})

extend('phone', {
  validate(value) {
    if (!value) return true;
    return value.split('-').join('').length == 10;
  },
  message: 'Please enter a valid phone number.'
});

extend('dateAfterToday', {
  validate: value => {
    const date = (new Date(value)).setHours(0,0,0,0);
    const today = (new Date()).setHours(0,0,0,0);
    return date >= today;
  }, 
  message: 'Date must be after today.'
});

extend('dateMustBeToday', {
  validate: value => {
    const date = (new Date(value)).setHours(0,0,0,0);
    const today = (new Date()).setHours(0,0,0,0);
    return date == today;
  }, 
  message: 'Date must be today.'
});

extend('dateBeforeToday', {
  validate: value => {
    const date = (new Date(value)).setHours(0,0,0,0);
    const today = (new Date()).setHours(23,59,59,0);
    return date <= today;
  }, 
  message: 'Date must be today or earlier.'
});

extend('validDate', {
  validate: value => {

    const isValidDate = Date.parse(value);
    if (isNaN(isValidDate)) return false;

    const date = (new Date(value)).setHours(0,0,0,0);
    const valid = (new Date('1/1/1890')).setHours(0,0,0,0);
    return date >= valid;
  }, 
  message: 'Please enter a valid date.'
})

extend('effectiveDateAfterCreateDate', {
  validate: value => {
    let module = 'quoteForm';
    if (window.location.href.indexOf('additional') > -1) {
      module = 'additionalForm';
    }

    if (Store.getters.userHasRestrictedFeature('Submission_BackdateEffectiveDate')) return true;

    const effectiveDate = new Date(value);
    effectiveDate.setHours(0,0,0,0);
    const createDate = new Date(Store.getters.getControlValue('quoteForm', 'CreateDate'))
    createDate.setHours(0,0,0,0);
    
    return (effectiveDate >= createDate)
  },
  message: `Effective Date cannot be before the date the Submission was started.`
})

extend('validBackdate', {
  validate: value => {
    if (!value) return true;
    //if (Store.getters.getUserRole == 'admin') return true;
    if (Store.getters.userHasRestrictedFeature('Submission_BackdateEffectiveDate')) return true;
    
    const effectiveDate = new Date(value)
    effectiveDate.setHours(0,0,0,0);

    const valid = new Date();
    valid.setDate((new Date()).getDate() - parseInt(14));
    valid.setHours(0,0,0,0);

    return (effectiveDate >= valid)
  },
  message: `Date must be less than 14 days old.`
})

extend('validBackdateFlood', {
  validate: value => {
    if (!value) return true;
    //if (Store.getters.getUserRole == 'admin') return true;
    //if (Store.getters.userHasRestrictedFeature('Submission_BackdateEffectiveDate')) return true;
    const effectiveDate = new Date(value)
    effectiveDate.setHours(0,0,0,0);

    const todaysDate = new Date()
    todaysDate.setHours(0,0,0,0);

    return (effectiveDate >= todaysDate)
  },
  message: `Date can't be before today.`
})

extend('greaterThanBuildingValuation', {
  params: ['target'],
  validate: (value, { target }) => {
    if (!value) return true;
    const valuationAmount = !target ? Store.getters.getControlValue('quoteForm', 'BuildingValuationAmount') : target

    return (valuationAmount != null && valuationAmount >= value)
  },
  message: `Dwelling Coverage should be less than or equal to Building Estimated Valuation Amount`
})

extend('greaterThanContentsValuation', {
  params: ['target'],
  validate: (value, { target }) => {
    if (!value) return true;
    const valuationAmount = !target ? Store.getters.getControlValue('quoteForm', 'ContentsValuationAmount') : target

    return (valuationAmount != null && valuationAmount >= value)
  },
  message: `Personal Property Coverage should be less than or equal to Personal Property Valuation Amount`
})

extend('effectiveDateBeforeExpirationDate', {
  params: ['target'],
  validate: (value, {target}) => {
    const effectiveDate = new Date(value)
    const expirationDate = new Date(target)
    return (effectiveDate < expirationDate)
  },
  message: `Effective Date must be before Expiration Date.`
})

extend('expirationDateAfterEffectiveDate', {
  params: ['target'],
  validate: (value, {target}) => {
    const effectiveDate = new Date(target)
    const expirationDate = new Date(value)
    return (expirationDate > effectiveDate)
  },
  message: `Expiration Date must be after Effective Date.`
})

extend('endorsementDateOnOrAfterEffectiveDate', {
  params: ['target'],
  validate: (value, {target}) => {
    const effectiveDate = new Date(target)
    const expirationDate = new Date(value)
    return (expirationDate >= effectiveDate)
  },
  message: `Date must be on or after Effective Date.`
})

extend('endorsementDateOnOrBeforeExpirationDate', {
  params: ['target'],
  validate: (value, {target}) => {
    const effectiveDate = new Date(value)
    const expirationDate = new Date(target)
    return (effectiveDate <= expirationDate)
  },
  message: `Date must be on or before Expiration Date.`
})

extend('endorsementDateOnOrAfterLatestTransactionDate', {
  params: ['target'],
  validate: (value, { target }) => {
    const effectiveDate = new Date(value)
    const expirationDate = new Date(target)
    return (effectiveDate >= expirationDate)
  },
  message: `Date must be on or after latest Endorsement Transaction Date.`
})

extend('expirationDateNotMoreThan18MonthsFromEffective', {
  params: ['target'],
  validate: (value, {target}) => {
    const effectiveDate = new Date(target) // new Date(value)
    const expirationDate = new Date(value)
    const eighteenMonthsFromEffective = new Date((effectiveDate.setMonth(effectiveDate.getMonth() + 18)))
    return (expirationDate <= eighteenMonthsFromEffective)
  },
  message: `Expiration Date must be no greater than 18 months from Effective Date.`
})
extend('expirationDateNotMoreThan1YearEffective', {
  params: ['target'],
  validate: (value, {target}) => {
    const effectiveDate = new Date(target) // new Date(value)
    const expirationDate = new Date(value)
    const eighteenMonthsFromEffective = new Date((effectiveDate.setYear(effectiveDate.getFullYear() + 1)))
    return (expirationDate <= eighteenMonthsFromEffective)
  },
  message: `Expiration Date must be no greater than 1 year from Effective Date.`
})
extend('dateNotMoreThan30DaysFromToday', {
  validate: value => {
    if (!value) return true;
    
    const dateEntered = new Date(value)
    const ThirtyDaysFromToday = new Date()
    ThirtyDaysFromToday.setDate(ThirtyDaysFromToday.getDate() + 30)
    return (dateEntered <= ThirtyDaysFromToday)
  },
  message: `Date must be no greater than 30 days from today.`
})
extend('backdateNotMoreThanDaysFromToday', {
  params: ['days'],
  validate: (value, { days }) => {
    if (!value) return true;
    
    const dateEntered = new Date(value)
    const daysFromToday = new Date()
    daysFromToday.setDate(daysFromToday.getDate() - parseInt(days))
    return (dateEntered >= daysFromToday)
  },
  message: (field, placeholders) => {
    return 'Backdate must be no greater than ' + placeholders.days + ' days from today.'
  }
})

extend('dateNotMoreThan3MonthsFromToday', {
  validate: value => {
    if (!value) return true;
    
    const dateEntered = new Date(value)
    const todaysDate = new Date()
    const threeMonthsFromToday = new Date((todaysDate.setMonth(todaysDate.getMonth() + 3)))
    return (dateEntered <= threeMonthsFromToday)
  },
  message: `Date must be no greater than 3 months from today.`
})

extend('stepMaxValue', {
  validate: value => {    
    if (!value) return true;

    const foundationType = Store.getters.getControlValue('quoteForm', 'FoundationType');

    switch (foundationType) {
      case 'Elevated on Crawlspace':
      case 'Slab On Fill':
      case 'Sub Grade Crawlspace':
        return value <= 8
      case 'Piers / Posts / Pilings':
        return value <= 26
      default:
        return true;
    }
  },
  message: (fieldName, placeHolders) => {
    const foundationType = Store.getters.getControlValue('quoteForm', 'FoundationType');
    let maxSteps = '8'
    switch (foundationType) {
      case 'Elevated on Crawlspace':
      case 'Slab On Fill':
      case 'Sub Grade Crawlspace':
        maxSteps = '8'
        break;
      case 'Piers / Posts / Pilings':
        maxSteps = '26'
        break;
      default:
        break;
    }
    return 'Maximum Number of Steps cannot exceed ' + maxSteps
  }
})

extend('needToAbideBy7DayWaitingPeriod', {
  validate: value => {
    
    if (!value) return true;
    const dateEntered = new Date(value)

    const sevenDaysFromNow = new Date();
    sevenDaysFromNow.setDate(sevenDaysFromNow.getDate() +7);
    sevenDaysFromNow.setHours(0,0,0,0);

    const ActiveInsurancePolicy = Store.getters.getControl('additionalForm', 'ActiveInsurancePolicy');
    const LoanClosingSituation = Store.getters.getControl('additionalForm', 'LoanClosingSituation');
    const NewlyClassified = Store.getters.getControl('additionalForm', 'NewlyClassified');

    if(!(ActiveInsurancePolicy.value || LoanClosingSituation.value || NewlyClassified.value))
      return (dateEntered >= sevenDaysFromNow);
    return true;
  },
  message: `Effective Date needs to be after 7 day waiting period.`
})

extend('dateLessThanDaysOld', {
  params: ['days'],
  validate: (value, {days}) => {
    if (!value) return true;

    const date = new Date(value);
    date.setHours(0,0,0,0);

    const valid = new Date();
    valid.setDate((new Date()).getDate() - parseInt(days));
    valid.setHours(0,0,0,0);

    return date >= valid;
  },
  computesRequired: true,
  message: (field, placeholders) => {
    return 'Date must be less than ' + placeholders.days + ' days old.'
  }
})

extend('dateLessThanYearsOld', {
  params: ['years'],
  validate: (value, {years}) => {
    if (!value) return true;

    const date = new Date(value);
    date.setHours(0,0,0,0);

    const valid = new Date();
    valid.setFullYear((new Date()).getFullYear() - parseInt(years));
    valid.setHours(0,0,0,0);

    valid.setHours(0,0,0,0);
    return date >= valid;
  },
  computesRequired: true,
  message: (field, placeholders) => {
    return 'Date must be less than ' + placeholders.years + ' years old.'
  }
})

extend('notZero', {
  validate: value => {
    return value !== 0;
  }, 
  message: 'Value cannot be 0.'
})

extend('subFormTableOpen', {
  validate: () => {
    return false;
  }, 
  message: 'Please click Save to finish editing this entry.'
})

extend('min_value', {
  ...min_value,
  params: ['min'],
  castValue: value => parseFloat(value),
  message: (fieldName, placeHolders) => {
    let min = placeHolders.min;
    if (fieldName.toLowerCase().indexOf('year') == -1) {
      min = parseInt(min).toLocaleString()
    }
    if (placeHolders._min_) {
      return 'This value must be ' + placeHolders._min_ + ' or higher.'
    } else {
      return 'This value must be ' + min + ' or higher.'
    }
  }
})

extend('max_value', {
  ...max_value,
  params: ['max', 'useValueForErrorMsg'],
  castValue: value => parseFloat(value),
  message: (fieldName, placeHolders) => {
    let max = placeHolders.max;
    const useValueForErrorMsg = placeHolders.useValueForErrorMsg;
    if (fieldName.toLowerCase().indexOf('year') == -1) {
      max = parseInt(max).toLocaleString()
    }

    if (useValueForErrorMsg) {
      return 'This value must be ' + placeHolders._max_ + ' or lower.'
    } else {
      return 'This value must be ' + max + ' or lower.'
    }
  }
})

extend('percentBetweenRangeForValue', {
  params: ['minPercent', 'maxPercent', 'target'],
  validate: (value, { minPercent, maxPercent, target }) => {
    const minAmount = parseFloat(target) * (parseFloat(minPercent) / 100)
    const maxAmount = parseFloat(target) * (parseFloat(maxPercent) / 100)
    return (value || value === 0) && value >= minAmount && value <= maxAmount;
  },
  computesRequired: true,
  message: (field, placeholders) => {
    const minAmount = parseFloat(placeholders._target_) * (parseFloat(placeholders.minPercent) / 100)
    const maxAmount = parseFloat(placeholders._target_) * (parseFloat(placeholders.maxPercent) / 100)
    return `Value must be between ${minAmount} and ${maxAmount}`
  }
})

extend('betweenRangeForValue', {
  params: ['minValue', 'maxPercent', 'target'],
  validate: (value, { minPercent, maxPercent, target }) => {
    const minAmount = parseFloat(target) * (parseFloat(minPercent) / 100)
    const maxAmount = parseFloat(target) * (parseFloat(maxPercent) / 100)
    return (value || value === 0) && value >= minAmount && value <= maxAmount;
  },
  computesRequired: true,
  message: (field, placeholders) => {
    const minAmount = parseFloat(placeholders._target_) * (parseFloat(placeholders.minPercent) / 100)
    const maxAmount = parseFloat(placeholders._target_) * (parseFloat(placeholders.maxPercent) / 100)
    return `Value must be between ${minAmount} and ${maxAmount}`
  }
})

extend('invalidValues', {
  params: ['invalidValues'],
  validate: (value, { invalidValues }) => {
    return !invalidValues.includes(value.toString())
  },
  computesRequired: true,
  message: () => {
    return `Value is invalid`
  }
})

extend('greater_than', {
  params: ["min"],
  validate: (value, {min}) => {
    return (value && parseFloat(value) > parseFloat(min));
  },
  computesRequired: true,
  message: (field, placeholders) => {
    return 'Value must be greater than ' + placeholders.min + '.'
  }
})

extend('less_than', {
  params: ["max"],
  validate: (value, { max }) => {
    return (value != null && parseFloat(value) < parseFloat(max));
  },
  computesRequired: true,
  message: (field, placeholders) => {
    return 'Value must be less than ' + placeholders.max + '.'
  }
})

extend('step_value', {
  params: ['step'],
  castValue: value => parseFloat(value),
  validate: (value, {step}) => {
    return value % step === 0;
  },
  message: (fieldName, placeHolders) => {
    const step = placeHolders.step
    return 'Please round to nearest ' + parseInt(step).toLocaleString() + '.'
  }
})

extend('availableOption', {
  params: ["options"],
  validate: (value, {options}) => {
    try {
      if (typeof(options) == 'object') options = options.join(',');
      const optionsArray = options.split("@@@");
      return optionsArray.indexOf(value + "") > -1;
    } catch(err) {
      console.error('rule error', typeof(options), options);
      return true;
    }
  },
  computesRequired: true,
  message: (field, placeholders) => {
    return 'Please select an available option.'
  }
})

extend('addressOpen', {
  params: ['mode'],
  validate: () => {
    return false;
  }, 
  //message: 'Please Accept Address'
  message: (fieldName, placeHolders) => {
    const mode = placeHolders.mode;
    if (mode == 'GOOGLE') return "Address cannot be found using Google Lookup, please use Advanced Entry.";
    else return 'Please Accept Address';
  }
})

extend('mortgageeOpen', {
  validate: () => {
    return false;
  }, 
  message: 'Please finish editing this mortgagee.'
})

// extend('min', {
//   ...min,
//   params: ['length'],
//   computesRequired: true,
//   message: (fieldName, placeHolders) => {
//     const min = placeHolders.length;
//     return 'Minimum Length: ${length}.'
//   }
// })

// extend('max', {
//   ...max,
//   params: ['length'],
//   computesRequired: true,
//   message: (fieldName, placeHolders) => {
//     const min = placeHolders.length;
//     return 'Maximum Length: ${length}.'
//   }
// })

extend('min_length', {
  params: ["min"],
  validate: (value, {min}) => {
    return (value && value.length >= min);
  },
  computesRequired: true,
  message: (field, placeholders) => {
    return 'Minimum Length: ' + placeholders.min
  }
})

extend('max_length', {
  params: ["max"],
  validate: (value, {max}) => {
    return (value && value.length <= max);
  },
  computesRequired: true,
  message: (field, placeholders) => {
    return 'Maximum Length: ' + placeholders.max
  }
})

extend('min_count', {
  params: ["min"],
  validate: (value, {min}) => {
    return (value && value.length >= min);
  },
  computesRequired: true,
  message: (field, placeholders) => {
    return 'Please add at least ' + placeholders.min + ' entry.'
  }
})

extend('max_count', {
  params: ["max"],
  validate: (value, {max}) => {
    return (value && value.length <= max);
  },
  computesRequired: true,
  message: (field, placeholders) => {
    return 'Only ' + placeholders.max + ' entries are allowed.'
  }
})

extend('addressLocked', {
  validate: value => {
    return value.Locked;
  }, 
  message: (fieldName, placeHolders) => {
    return 'Please finish editing the address.'
  }
})

extend('wholeNumber', {
  validate: value => {
    return /^\d+$/.test(value)
  },
  message: 'This value must be a whole number.'
})

extend('notAfterCurrentYear', {
  validate: value => {
    const currentYear = (new Date()).getFullYear();
    return parseInt(value) <= currentYear;
  }, 
  message: (fieldName, placeHolders) => {
    const max = (new Date()).getFullYear();
    return 'This value cannot be greater than ' + max + '.'
  }
})

export const allowedYearBuilt: number = () => {
  const currentYear = (new Date()).getFullYear();
  const ed = Store.getters.getControlValue('quoteForm', 'EffectiveDate');
  const effectiveDate = new Date(ed);
  const effectiveDateYear = effectiveDate.getFullYear();

  //Per PJ - if effective date is after 7/1 of current year, allow Year Built to be next year
  if (effectiveDateYear == currentYear) {
    if (effectiveDate.getMonth() >= 6) {
      return currentYear + 1;
    }
  }

  return currentYear;
}

extend('validYearBuilt', {
  validate: value => {
    if (!value) return true;
    return parseInt(value) <= allowedYearBuilt();
  }, 
  message: (fieldName, placeHolders) => {
    const max = allowedYearBuilt();
    return 'This value cannot be greater than ' + max + '.';
  }
})

extend('afterYearBuilt', {
  validate: value => {
    if (!value) return true;
    const yearBuilt = Store.getters.getControlValue('quoteForm', 'YearBuilt')
    if (!yearBuilt) return true;
    return parseInt(value) >= parseInt(yearBuilt)
  },
  message: 'Value cannot be before Original Year Built.'
})

extend('afterEffectiveYearBuilt', {
  validate: value => {
    const effectiveYearBuilt = Store.getters.getControlValue('quoteForm', 'EffectiveYearBuilt')
    if (!effectiveYearBuilt) {
      return true
    } else {
      return value >= effectiveYearBuilt
    }
  },
  message: 'Value cannot be before Effective Year Built.'
})

extend('requiredTrue', {
  validate: value => {
    return value === true;
  }, 
  computesRequired: true,
  message: 'Please accept these conditions.'
})

extend('minDocuments', {
  params: ["min"],
  validate: (value, {min}) => {
    return (value && value.length >= min);
  },
  computesRequired: true,
  message: (field, placeholders) => {
    return placeholders.min == 1 ? 'Please upload at least one document.' : 'Please upload at least ' + placeholders.min + ' documents.'
  }
})

extend('watercrafts', {
  validate: value => {
    const total = (
      parseInt(Store.getters.getControlValue('additionalForm', 'NumberOfWatercrafts0100') || 0) +
      parseInt(Store.getters.getControlValue('additionalForm', 'NumberOfWatercrafts101150') || 0) +
      parseInt(Store.getters.getControlValue('additionalForm', 'NumberOfWatercrafts151200') || 0) +
      parseInt(Store.getters.getControlValue('additionalForm', 'NumberOfWatercrafts201350') || 0) +
      parseInt(Store.getters.getControlValue('additionalForm', 'NumberOfWatercraftsAbove350') || 0)
    )

    return parseInt(value) === total
  },
  message: (fieldName, placeHolders) => {
    return `The total number of watercrafts below must equal ${placeHolders._value_}.`
  }
})

extend('windPerilDeductibleAmount', {
  validate: value => {
    if (value.toLowerCase() === 'aop') return true

    const percentage = parseFloat(value)
    const allPerilDeductible = Store.getters.getControlValue('quoteForm','AllPerilDeductible')

    // If all peril deductible is a percentage, compare it directly with the wind peril percentage
    if (allPerilDeductible && (allPerilDeductible + '').indexOf('%') !== -1) {
      return percentage >= parseFloat(allPerilDeductible)
    }

    const coverageForm = Store.getters.getControlValue('quoteForm','CoverageOption')
    // RENTERS ONLY get Coverage C since Coverage A does not exist
    const coverageTotal = coverageForm === 'ho4'
      ? Store.getters.getControlValue('quoteForm','CovC')
      : Store.getters.getControlValue('quoteForm','CovA')
    // The value is calculated as a percentage of coverage
    return coverageTotal * (percentage / 100) >= parseFloat(allPerilDeductible)
  },
  message: 'Please select a wind deductible that is equal to or greater than the AOP deductible.'
})

extend('over18', {
  validate: value => {
    const todaysDate = new Date()
    const eighteenYearsAgo = new Date(todaysDate.setFullYear(todaysDate.getFullYear() - 18))
    return (new Date(value) <= eighteenYearsAgo)
  },
  message: `Individual must be over 18 years old.`
})

extend('humanCombinedFileSize', {
  validate: i => {
    let totalSize = 0;
    const controls = [
      'ShortTermRentalDoc',
      'VacantQuestionnaire',
      'CorpQuestionnaireDoc',
      'BuildersRiskSupDoc',
      'LogHomeQuestionnaireDoc',
      'UnprotectedRatingQuestionnaire',
      'SecondModifiersSupplementalDoc',
      'SupplementalHeatingQuestionnaire',
      'Attachments'
    ];

    controls.forEach(control => {
      const value = Store.getters.getControlValue('additionalForm', control);
      if (value && value.length > 0) {
        value.forEach(v => {
          totalSize += v.FileSize;
        });
      }
    });

    return totalSize / 1000000 < 10;

  },
  message: 'Combined size for all files cannot exceed 10 Mb.'
})

extend('notEUCountry', {
  validate: value => {
    if (!value) return true;
    if (value && !value.CountryCode) return true;
    const customerType = Store.getters.getControlValue('quoteForm', 'CustomerType');
    if (customerType != 'individual') return true;
  
    if (value.IsEuropeanUnion) {
      return false;
    }
    return true;

  },
  message: `If the Insured Entity Domicile Country is a member of the EU, we are unable to accept this risk via the Portal.<br>Please contact your Amwins underwriter directly to discuss further and exit out of this submission.`
})
extend('min5000IfSelected', {
  validate: value => {
    if (!value) return true;
    if (value === 0 || value >=5000) return true;

  },
  message: `Minimum value for this coverage is 5000`
})

extend('hasLossesInPast5Years', {
  validate: value => {
    if (!value || value != 'Claim History') return true
    const hasLossHistory = Store.getters.getControlValue('additionalForm', 'LossesInPast5Years');
    if (hasLossHistory) return true;
    return false;
  },
  message: 'No Claim History reported in the previous form.'
})

extend('hasLapseInCoverage', {
  validate: value => {
    if (!value || value != 'Lapse in Coverage/No Prior Coverage') return true
    const otherRisksAssociated = Store.getters.getControlValue('additionalForm', 'OtherRisksAssociated');
    if (otherRisksAssociated && !otherRisksAssociated.isCoverageLapse) return false;
    return true;
  },
  message: 'Lapse in Coverage not reported in the previous form.'
})

extend('hasOtherRiskAssociated', {
  params: ['target'],
  validate: (value, {target}) => {
    const AnyOtherExposures = target;
    const OtherRisksAssociated = value;
    if (!AnyOtherExposures || !OtherRisksAssociated) return true;
    let checked = false;
    Object.keys(OtherRisksAssociated).forEach((key: string) => {
      if (OtherRisksAssociated[key]) checked = true;
    });

    return (checked)
  },
  message: `Please select at least one exposure.`
})


extend('validMortgageeOrgName', {
  validate: value => {

    if (!value) return true;
    if (value.ID) return true;
    if (!value.Name) return true;

    const invalidTerms = [
      'c/o',
      'c / o',
      'care of',
      'isaoa',
      'atima',
      'attn',
      'attention'
    ];

    const v = ' ' + value.Name.toLowerCase() + ' ';

    const invalid = invalidTerms.some(i => 
      v.indexOf(' ' + i + ' ') > -1 || 
      v.indexOf(' ' + i + ': ') > -1);

    return !invalid;

  },
  message: 'Please remove extra details from the Mortgagee Organization name.'
})

extend('validAOR', {
  validate: value => {
    if (!value) return true;
    return value.Producer || false;
  },
  message: 'Please select a valid licensed agent from the list.'
})

extend('validUnitStory', {
  params: ['BuildingTypeCondo'],
  validate: (value, {BuildingTypeCondo}) => {
    if (!value) return true;
    const v = parseInt(value);
    if (BuildingTypeCondo == 'Low Rise') return v >=1 && v <=4;
    else if (BuildingTypeCondo == 'Mid Rise') return v >=1 && v <=9;
    else return true;
  },
  message: (field, placeholders) => {
    if (placeholders._BuildingTypeCondo_ == 'Low Rise') return 'This value must be 1-4 for Low Rise buildings.';
    else return 'This value must be 1-9 for Mid Rise buildings.';
  }
})


extend('munichValidDeductible', {
  params: ['CoverageOption', 'CovI', 'CovII'],
  validate: (value, {CoverageOption, CovI, CovII}) => {
    if (!value) return true;

    if (['dp3', 'ho3'].indexOf(CoverageOption) > -1) {
      return parseInt(value) < parseInt(CovI);
    } else if (['ho6'].indexOf(CoverageOption) > -1) {
      return parseInt(value) < parseInt(CovI) + parseInt(CovII);
    }

  },
  message: (field, placeholders) => {
    return 'Enter a valid deductible.'
  }
})

extend('validDigitalAIType', {
  validate: value => {
    if (!value) return true;

    const invalidDigitalAITypes = ['Builder/GC –Insured & Licensed'];
    if (invalidDigitalAITypes.indexOf(value) > -1) return false;

    return true;
  },
  message: 'Not Eligible for Digital Submission'
})

extend('validateNumberRange', {
  validate: enteredNumber => {
    if (enteredNumber < -2147483647 || enteredNumber > 2147483647) {
        return false;
    }
    return true;
  },
  message: 'Enter a valid amount.'
})

extend('payment', {
  validate: value => {
    if (Store.getters.isFeatureFlagEnabled("ePayPaymentOptions")) {
      return value && !!value.isValid;
    }
    return {
      required: true,
      valid: ['', null, undefined].indexOf(value) === -1
    };
  },
  message: 'Required',
  computesRequired: true
})

extend('requireInsuredEmailOptions', {
  validate: value => {
    return value && !!value.isValid;
  },
  computesRequired: true
})

//Globally registered components for validation
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ErrorMessages', ErrorMessages);